import userflow from 'userflow.js';
import jwtDecode from 'jwt-decode';

import i18next from 'i18next';

export const setupUserflow = (customer, user, userTenants, userflowSignatures) => {
  // Do not update if there is no user object, customer.progress value, or the identity has already been run.
  if (customer === undefined) customer = {};
  if (userTenants === undefined) userTenants = {};
  if (
    !userflow.isIdentified() &&
    Object.keys(customer).length !== 0 &&
    Object.keys(user) !== 0 &&
    Object.keys(userflowSignatures).length !== 0 &&
    Object.keys(userTenants).length !== 0
  ) {
    const accessToken = JSON.parse(localStorage.getItem('@irecommend:credentials')).accessToken;
    const activeTenantFromToken = jwtDecode(accessToken).tenantId;
    const activeTenant = userTenants.tenants?.filter(
      (item) => item.id === activeTenantFromToken,
    )[0];

    try {
      //Initiate Userflow
      let infoObject = {};

      infoObject = {
        customer: customer,
        user: user,
        tid: userTenants.userId,
        activeTenant: activeTenant.name,
      };

      setUserflowIdentity(infoObject, userflow, userflowSignatures);
    } catch (error) {
      console.log(error);
    }
  }
};

export const setUserflowIdentity = (props, userflow, userflowSignatures) => {
  // Identify the user and connect user to Userflow
  userflow.identify(
    props.tid,
    {
      current_product_id: process.env.REACT_APP_TALENTECH_IRECOMMEND_ID,
      user_agent: navigator.userAgent,
      name: props.user.user.givenname + ' ' + props.user.user.familyname,
      signed_up_at: props.user.user.created,
      email: props.user.user.mail,
      irec_usertype: props.user.user.usertype,
      irec_tenant_name: props.activeTenant,

      // USERFLOW LOCALE
      locale_code: getLanguageForUserflow(),
    },
    {
      signature: userflowSignatures.UserSignature,
    },
  );

  // Try to identify if the customer is part of any group
  userflow.group(
    props.customer.idcustomer,
    {
      customer_type: props.customer.customer_type,
    },
    {
      signature: userflowSignatures.CustomerSignature,
    },
  );
};
export const updateUserflowGroup = (updateObj, userflow) => {
  // Update userflow when new props are loaded
  // example updateObj {launch_date: Date, customer_type: 1}
  userflow.updateGroup(updateObj);
};

export const getLanguageForUserflow = () => {
  const languageCode = i18next.language;
  const languageMap = {
    en: 'en-US',
    sv: 'sv-SE',
    nb: 'nb-NO',
    fi: 'fi-FI',
    da: 'da-DK',
  };

  return languageMap[languageCode];
};

import React, { Component } from 'react';
import { css, StyleSheet } from 'aphrodite';
import moment from 'moment';
import PropTypes from 'prop-types';
import userflow from 'userflow.js';
import { Button, Select, Chip, ActionButton, Toast, Box } from '@talentech/components';
import DatePicker from 'react-datepicker';
import { withRouter } from 'react-router';
import Modal from '../../components/Modal';
import { ModalForm } from '../../components/admin/ModalForm';
import CategoryListItem from '../../components/admin/CategoryListItem';
import { TALENTECH_ICONS } from '../../components/Icons';
import CategoryFormFields from '../../components/admin/CategoryFormFields';
import AdminImage from '../../components/admin/AdminImage';
import AdminWrapper from '../../components/admin/AdminWrapper';
import PageHeader from '../../components/admin/PageHeader';
import EmptyState from '../../components/EmptyState';

import { Color } from '../../config/styles';

import { GMTToLocaleString } from '../../helpers/date';
import { setupUserflow, updateUserflowGroup } from '../../helpers/userflow';
import { createBreadcrumb, sortCategories } from '../../helpers/utils';
import { withTranslation } from 'react-i18next';

const languages = [
  {
    key: 'SV',
    label: 'Svenska',
  },
  {
    key: 'DA',
    label: 'Dansk',
  },
  {
    key: 'NO',
    label: 'Norsk',
  },
  {
    key: 'FI',
    label: 'Suomi',
  },
  {
    key: 'EN',
    label: 'English',
  },
];

class SettingsPage extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    intervals: PropTypes.array,
    onStartGuide: PropTypes.func.isRequired,
    progress: PropTypes.number.isRequired,
  };

  timer;

  constructor(props) {
    super(props);
    //Initiate Userflow
    userflow.init(process.env.REACT_APP_USERFLOW_TOKEN);

    this.state = {
      CareerSite: '',
      DefaultSender: '',
      ShortName: '',
      finished: false,
      language: '',
      launchDate: null,
      launchStatus: '',
      competitions: true,
      Picture: '', // Loaded image for presentation
      PictureRaw: null, // input File object
      showLaunchModal: '',
      intervalSendOff: '',
      showSuccessToast: false,
      showErrorToast: false,
      showCategoryModal: false,
      updateCategoryForm: false,
      deleteCategoryForm: false,
      toggleDropdown: false,
      category: {
        name: '',
      },
      categoryToBeInteracted: '',
    };
    this.setState = this.setState.bind(this);
    this.handleCancelForm = this.handleCancelForm.bind(this);
    this.handleSubmitForm = this.handleSubmitForm.bind(this);
    this.handleUpdateForm = this.handleUpdateForm.bind(this);
    this.handleDeleteForm = this.handleDeleteForm.bind(this);
    this.handleEditCategory = this.handleEditCategory.bind(this);
    this.handleDeleteCategory = this.handleDeleteCategory.bind(this);
  }

  componentDidMount() {
    if (this.props.userCustomer.shortname !== this.state.ShortName) {
      this.setInitialStateFromSQL();
    }
    if (this.props.customer.default_sender !== this.state.DefaultSender) {
      this.setInitialStateFromMongoDB();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  setInitialStateFromMongoDB() {
    const newState = { ...this.state };
    if (this.props.customer.default_sender && this.state.DefaultSender === '') {
      newState.DefaultSender = this.props.customer.default_sender;
    }
    this.setState(newState);
  }

  setInitialStateFromSQL() {
    const newState = { ...this.state };
    if (this.props.userCustomer.shortname && this.state.ShortName === '') {
      newState.ShortName = this.props.userCustomer.shortname;
    }
    if (this.props.userCustomer.homepage && this.state.CareerSite === '') {
      newState.CareerSite = this.props.userCustomer.homepage;
    }
    if (this.props.userCustomer.image && this.state.Picture === '') {
      newState.Picture = this.props.userCustomer.image;
    }
    if (this.props.userCustomer.language && this.state.language === '') {
      newState.language = this.props.userCustomer.language;
    }
    if (this.props.userCustomer.golivedate !== 0 && this.state.launchDate === null) {
      //convert epoch time to UTC DATE
      var utcSeconds = this.props.userCustomer.golivedate;
      var d = new Date(0);
      d.setUTCSeconds(utcSeconds);
      newState.launchDate = d;

      var today = new Date();
      if (d.getTime() > today.getTime()) {
        newState.launchStatus = 'launch_pending';
      } else {
        newState.launchStatus = 'launched';
      }
    }
    this.setState(newState);
  }

  setLaunchStatus = () => {
    const newState = { ...this.state };
    newState.showLaunchModal = '';
    newState.intervalSendOff = '';

    if (!this.state.launchDate) {
      newState.launchStatus = '';
    }
    var today = new Date();
    if (this.state.launchDate.getTime() > today.getTime()) {
      newState.launchStatus = 'launch_pending';
    } else {
      newState.launchStatus = 'launched';
    }
    this.setState(newState);
  };

  render() {
    const {
      Picture,
      CareerSite,
      launchStatus,
      launchDate,
      DefaultSender,
      ShortName,
      language,
      showLaunchModal,
      showSuccessToast,
      showErrorToast,
    } = this.state;
    const { t, progress, userCustomer, customerCategories, history } = this.props;
    const navigateTo = (path) => history.push(path);
    const sortedCustomerCategories = sortCategories(customerCategories);

    const breadCrumb = createBreadcrumb(
      [
        {
          labelKey: 'admin-header-dashboard',
          onClick: () => navigateTo('/admin'),
        },
        {
          labelKey: 'admin-header-settings',
          isActive: true,
        },
      ],
      t,
    );

    return (
      <>
        <PageHeader title={t('admin-header-settings')} breadcrumbs={breadCrumb} />
        <AdminWrapper>
          <div className={css(styles.container)}>
            <div className={css(styles.content)}></div>
          </div>
          <div className={css(styles.settingsContent)}>
            <div className={css(styles.settingsBoxProfile)}>
              <div className={css(styles.settingsItemStatus)}>
                <div className={css(styles.statusElement)}>
                  <AdminImage name={'Company Logo'} picture={Picture} src={Picture} />
                  <div className={css(styles.addPicButton)}>
                    <ActionButton
                      icon={
                        userCustomer.image
                          ? 't-icon regular-01_interface_essentials-edit-pencil'
                          : 't-icon regular-01_interface_essentials-remove_add-add_circle'
                      }
                      size="lg"
                      variant="icon"
                      onClick={this.triggerInputFile}
                      aria-label="edit"
                    />
                    {/* This input is handling the file picker and triggers when clicking the action button.
                      The input tag is referencing the onClick in the ActionButton*/}
                    <input
                      hidden
                      id="image-upload"
                      type="file"
                      name="pic"
                      accept="image/*"
                      ref={(fileInput) => (this.fileInput = fileInput)}
                      onChange={(e) => this.onChangeFiles(e.target.files)}
                      data-testid="image-upload"
                      aria-label="image upload"
                    ></input>
                  </div>
                </div>
                <div className={css(styles.statusElement)}>
                  <h2 className={css(styles.accountText)}>{userCustomer.name}</h2>
                  {/* <p>some text</p> */}
                </div>
                <div className={css(styles.statusElement)}>
                  <h3>{t('settings-status-heading')}</h3>
                  <Chip
                    label={this.getLaunchStatusText(launchStatus)}
                    color={this.getLaunchStatusStyle(launchStatus)}
                  />
                </div>
                <div className={css(styles.statusElement)}>
                  <p>{t('settings-launch-date-heading')}</p>
                  <DatePicker
                    dateFormat="yyyy-MM-dd"
                    className={css(styles.datePicker)}
                    selected={launchDate ? launchDate : null}
                    placeholderText={t('settings-launch-date-placeholder')}
                    onChange={(e) => this.onSelectLaunchDate(e)}
                  />
                </div>
                <div className={css(styles.statusElement)}>
                  <Button
                    label={
                      launchStatus
                        ? t('settings-button-launch-save-date')
                        : t('settings-button-launch-save-date')
                    }
                    disabled={
                      moment(launchDate).format('YYYY-MM-DD') === this.props.launchDate ||
                      launchDate === null
                        ? true
                        : false
                    }
                    color="primary"
                    size="lg"
                    onClick={(e) => this.onSetLaunchDateClick(e)}
                  />
                  {showLaunchModal && (
                    <Modal
                      title={t('get-started-launch-date-title')}
                      description={`${t('get-started-launch-date-validate', {
                        launchDate: GMTToLocaleString(this.state.launchDate),
                      })}
                       ${
                         this.state.intervalSendOff
                           ? GMTToLocaleString(this.state.intervalSendOff)
                           : t('generic-text-this-moment')
                       }`}
                      type="confirm"
                      confirmCallback={(e) => {
                        if (showLaunchModal === 'send') {
                          this.onSetLaunchDate(progress, 'POST');
                        } else if (showLaunchModal === 'edit') {
                          this.onSetLaunchDate(progress, 'PUT');
                        }
                        this.setLaunchStatus();
                      }}
                      cancelCallback={() =>
                        this.setState({ showLaunchModal: '', intervalSendOff: '' })
                      }
                    />
                  )}
                </div>
              </div>
            </div>

            <ul className={css(styles.settingsBoxList)}>
              <li className={css(styles.settingsBox)}>
                <div className={css(styles.settingsItem)}>
                  <h3 className={css(styles.settingsBoxHeading)}>
                    {t('settings-career-site-heading')}
                  </h3>
                  <p>{t('settings-career-site-info')}</p>
                  <div className={css(styles.inputDiv)}>
                    <p className={css(styles.inputHeading)}>
                      {t('settings-career-site-input-heading')}
                    </p>
                    <input
                      className={css(styles.width100)}
                      placeholder="http://example.com/career-site"
                      maxLength="200"
                      defaultValue={CareerSite}
                      onChange={(e) => this.onFieldChange(e, 'CareerSite')}
                      data-testid="career-site"
                      aria-label="career-site"
                    />
                  </div>
                  <div className={css(styles.settingsEditButton)} data-testid="save-career-site">
                    <Button
                      label={t('generic-text-save').toUpperCase()}
                      outline="true"
                      color="primary"
                      size="md"
                      onClick={(e) => this.onEditCareerSite(e)}
                      aria-label="Save text button"
                    />
                  </div>
                </div>
              </li>
              <li className={css(styles.settingsBox)}>
                <div className={css(styles.settingsItem)}>
                  <h3 className={css(styles.settingsBoxHeading)}>
                    {t('settings-communication-heading')}
                  </h3>
                  <p>{t('settings-communication-info')}</p>
                  <div className={css(styles.inputDiv)}>
                    <p className={css(styles.inputHeading)}>
                      {t('settings-communication-language-input-heading')}
                    </p>
                    <Select
                      placeholder={languages.find((obj) => obj.key === language)?.label}
                      options={languages}
                      selectedValue={languages.find((obj) => obj.key === language)?.label}
                      onChange={(e) => this.onLanguageChange(e)}
                      aria-label="Language selector"
                    />
                    <p className={css(styles.smallText)}>
                      {t('settings-communication-language-info')}
                    </p>
                  </div>
                  <hr />
                  <div className={css(styles.inputDiv)}>
                    <p className={css(styles.inputHeading)}>
                      {t('settings-communication-email-sender-input-heading')}
                      <span className={css(styles.asterix)}> *</span>
                    </p>
                    <input
                      type="text"
                      className={css(styles.width100)}
                      placeholder={t('settings-communication-email-sender-placeholder')}
                      defaultValue={DefaultSender}
                      onChange={(e) => this.onFieldChange(e, 'DefaultSender')}
                      data-testid="email-sender"
                      aria-label="email-sender"
                    />
                    <p className={css(styles.smallText)}>
                      {t('settings-communication-email-sender-info')}
                    </p>
                  </div>
                  <div className={css(styles.inputDiv)}>
                    <p className={css(styles.inputHeading)}>
                      {t('settings-communication-sms-sender-input-heading')}
                      <span className={css(styles.asterix)}> *</span>
                    </p>
                    <input
                      className={css(styles.width100)}
                      placeholder={t('settings-communication-sms-sender-placeholder')}
                      defaultValue={ShortName}
                      onChange={(e) => this.onFieldChange(e, 'ShortName')}
                      data-testid="sms-sender"
                      aria-label="sms-sender"
                    />
                    <p className={css(styles.smallText)}>
                      {t('settings-communication-sms-sender-info')}
                    </p>
                  </div>
                  <div className={css(styles.settingsEditButton)} data-testid="save-communication">
                    <Button
                      label={t('generic-text-save').toUpperCase()}
                      outline="true"
                      color="primary"
                      size="md"
                      onClick={(e) => this.onEditCommunication(e)}
                    />
                  </div>
                </div>
              </li>
              <ModalForm
                showModalForm={this.state.showCategoryModal}
                title={
                  this.state.updateCategoryForm
                    ? t('settings-category-update')
                    : this.state.deleteCategoryForm
                    ? t('settings-category-delete-header')
                    : t('settings-category-new')
                }
                content={<CategoryFormFields state={this.state} setState={this.setState} />}
                labelCancel={t('generic-button-cancel')}
                labelSubmit={
                  this.state.deleteCategoryForm
                    ? t('settings-category-delete')
                    : t('generic-text-save')
                }
                handleCancel={this.handleCancelForm}
                handleSubmit={
                  this.state.updateCategoryForm
                    ? this.handleUpdateForm
                    : this.state.deleteCategoryForm
                    ? this.handleDeleteForm
                    : this.handleSubmitForm
                }
              />

              <li className={css(styles.settingsCategoryBox)}>
                <div className={css(styles.settingsItem)}>
                  <div className={css(styles.settingsHeader)}>
                    <h3 id="categorySection" className={css(styles.settingsBoxHeading)}>
                      {t('settings-category-header')}
                    </h3>
                    <ActionButton
                      icon={TALENTECH_ICONS.plus}
                      label={t('settings-category-new').toUpperCase()}
                      size="sm"
                      color="secondary"
                      onClick={() => this.setState({ ...this.state, showCategoryModal: true })}
                    />
                  </div>
                </div>
              </li>
              <li className={css(styles.settingsBox)}>
                <div className={css(styles.settingsItem)}>
                  {customerCategories.length === 0 ? (
                    <EmptyState
                      img="https://storybook.talentech.io/images/svg/logistics/package-attention.svg"
                      width="170"
                      alt="Empty box"
                      heading={t('settings-category-nothing')}
                      description={t('settings-category-nothing-description')}
                      callToActionType="button"
                      buttonLabel={t('settings-category-new').toUpperCase()}
                      onSubmit={() => this.setState({ ...this.state, showCategoryModal: true })}
                    />
                  ) : (
                    <>
                      <p>{t('settings-category-description')}</p>
                      <div className={css(styles.settingsCategoriesWrapper)}>
                        <p className={css(styles.settingsCategoryHeader)}>
                          {t('settings-category-list-header')}
                        </p>
                        {sortedCustomerCategories?.map((category) => (
                          <CategoryListItem
                            key={category.idcategories}
                            category={category}
                            handleEditCategory={this.handleEditCategory}
                            handleDeleteCategory={this.handleDeleteCategory}
                            state={this.state}
                            setState={this.setState}
                          />
                        ))}
                      </div>
                    </>
                  )}
                </div>
              </li>
            </ul>
            <Box className="d-flex justify-content-center w-75 position-fixed bottom-0 p-3 z-index-3">
              <Box className="w-50">
                <Toast
                  showToast={showSuccessToast}
                  color={'success'}
                  size={'small'}
                  onClose={() => {
                    this.setState({ ...this.state, showSuccessToast: false });
                  }}
                  data-testid="success-toast"
                >
                  {t('generic-alert-success-save')}
                </Toast>
                <Toast
                  showToast={showErrorToast}
                  color={'danger'}
                  size={'small'}
                  onClose={() => {
                    this.setState({ ...this.state, showErrorToast: false });
                  }}
                >
                  {t('generic-alert-fail-save')}
                </Toast>
              </Box>
            </Box>
          </div>
        </AdminWrapper>
      </>
    );
  }

  componentDidUpdate(prevProps) {
    // Set the initial state based on customer props when customer data has loaded and differs from previous props
    if (this.props.userCustomer !== prevProps.userCustomer) {
      this.setInitialStateFromSQL();
    }
    if (this.props.customer.default_sender !== prevProps.customer.default_sender) {
      this.setInitialStateFromMongoDB();
    }
    //Userflow setup
    setupUserflow(
      this.props.customer,
      this.props.user,
      this.props.userTenants,
      this.props.userflowSignatures,
    );
  }

  getLaunchStatusText = (launchStatus) => {
    const { t } = this.props;
    if (launchStatus === 'launched') {
      return t('settings-status-value-launched');
    } else if (launchStatus === 'launch_pending') {
      return t('settings-status-value-launch-set');
    } else {
      return t('settings-status-value-not-launched');
    }
  };

  getLaunchStatusStyle = (launchStatus) => {
    if (launchStatus === 'launched') {
      return 'success';
    } else if (launchStatus === 'launch_pending') {
      return 'warning';
    } else {
      return 'secondary';
    }
  };

  onSelectLaunchDate = (e) => {
    const { t } = this.props;
    let today = new Date();
    let selectedDate = e;
    if (selectedDate < today.getTime()) {
      alert(t('get-started-launch-date-invalid'));
      return;
    }

    this.setState({ launchDate: selectedDate });
  };

  onSetLaunchDateClick = (e) => {
    e.preventDefault();
    this.setState({ showLaunchModal: 'edit' });
    const { t } = this.props;
    let today = new Date();
    const pickedLaunchDate = new Date(this.state.launchDate);
    if (pickedLaunchDate.getTime() < today.getTime()) {
      alert(t('get-started-launch-date-invalid'));
      return;
    } else if (pickedLaunchDate.getTime() >= today.getTime()) {
      // Checks if launch date is in the future and set the interval send off based on if the launch is over a week from today or not.
      this.setState({ showLaunchModal: 'edit' });
      pickedLaunchDate.getTime() < today.setDate(today.getDate() + 7)
        ? this.setState({
            intervalSendOff: moment(
              new Date(pickedLaunchDate.setDate(pickedLaunchDate.getDate())),
            ).format('YYYY-MM-DD'),
          })
        : this.setState({
            intervalSendOff: moment(
              new Date(pickedLaunchDate.setDate(pickedLaunchDate.getDate() - 7)),
            ).format('YYYY-MM-DD'),
          });
    } else if (this.state.launchDate === '') {
      alert(t('date-not-validated'));
    }
  };

  onSetLaunchDate = async (progress, method) => {
    this.setState({ showLaunchModal: '', intervalSendOff: '' });
    const postData = {
      LaunchDate: moment(this.state.launchDate).format('YYYY-MM-DD'),
      progress: progress,
      idcustomer: this.props.customer.idcustomer,
      competitions: this.state.competitions,
      launch_date_set: true,
    };
    this.setLaunchStatus();

    const userflowData = {
      launch_date: moment(this.state.launchDate).format('YYYY-MM-DD'),
    };
    updateUserflowGroup(userflowData, userflow);

    let response = await this.props.onSetLaunchDate(postData, method);
    this.checkSaveSuccess(response);
  };

  updateLaunchStatus = () => {};

  onLanguageChange = (e) => {
    this.setState({ language: e.key });
  };

  onFieldChange = (e, type) => {
    const newState = { ...this.state };

    newState[type] = e.target.value;

    this.setState(newState);
  };

  onEditCareerSite = async (e) => {
    e.preventDefault();

    const data = {
      HomePage: this.state.CareerSite,
    };

    let response = await this.props.onEditCareerSite(data);
    this.checkSaveSuccess(response);
  };

  onEditCommunication = async (e) => {
    e.preventDefault();
    const { t } = this.props;

    if (this.state.ShortName.length > 10) {
      alert(t('settings-error-shortname-to-long'));
      return;
    }

    const data = {
      default_sender: this.state.DefaultSender,
      shortname: this.state.ShortName,
      language: this.state.language.toUpperCase(),
    };

    let response = await this.props.onEditCommunication(data);
    this.checkSaveSuccess(response);
  };

  triggerInputFile = () => this.fileInput.click();

  // Handles the check to see that image is ok and then updates the state
  onChangeFiles = (files) => {
    const { t } = this.props;
    if (files && files[0]) {
      const reader = new FileReader();
      const img = files[0];
      const size = img.size / 1024; // KB size
      const type = img.type; // image/png
      if (size > 3000) {
        alert(t('generic-error-image-size', { maxSize: '3MB' }));
        return;
      }
      if (!(type === 'image/png' || type === 'image/jpeg')) {
        alert(t('generic-error-image-size', { imgType: type.replace('image/', '') }));
        return;
      }
      reader.onload = (e) => {
        const newState = {
          ...this.state,
          Picture: e.target.result, // Loaded image for presentation
          PictureRaw: files[0], // input File object
        };
        this.setState(newState);
      };

      reader.readAsDataURL(files[0]);

      this.timer = setTimeout(() => this.onSubmitLogo(), 500);
    }
  };

  onSubmitLogo = () => {
    const data = {
      Picture: this.state.Picture,
      PictureRaw: this.state.PictureRaw,
    };

    this.props.onSubmitLogo(data);
  };

  checkSaveSuccess = (response) => {
    if (response === true) {
      setTimeout(() => {
        if (this.props.company.request.status === 200) {
          const newState = {
            ...this.state,
            showSuccessToast: true,
            showErrorToast: false,
          };
          this.setState(newState);
        } else {
          const newState = {
            ...this.state,
            showSuccessToast: false,
            showErrorToast: true,
          };
          this.setState(newState);
        }
        setTimeout(() => {
          this.setState({ ...this.state, showSuccessToast: false, showErrorToast: false });
        }, 4000);
      }, 500);
    }
  };

  handleSubmitForm() {
    //
    if (this.state.category.name === '') {
      alert('You should enter a category');
      return false;
    }

    this.props.onSubmitCreateCategory(this.state.category);
    this.setState({ ...this.state, showCategoryModal: false, category: { name: '' } });
  }

  handleUpdateForm() {
    //
    if (this.state.category.name === '') {
      alert('You should enter a category');
      return false;
    }
    this.props.onSubmitUpdateCategory(this.state.categoryToBeInteracted, this.state.category);

    this.setState({
      ...this.state,
      showCategoryModal: false,
      category: { name: '' },
      updateCategoryForm: false,
    });
  }

  handleDeleteForm() {
    this.props.onSubmitDeleteCategory(this.state.categoryToBeInteracted);

    this.setState({
      ...this.state,
      showCategoryModal: false,
      category: { name: '' },
      deleteCategoryForm: false,
    });
  }
  handleCancelForm() {
    this.setState({
      ...this.state,
      showCategoryModal: false,
      category: { name: '' },
      updateCategoryForm: false,
      deleteCategoryForm: false,
    });
  }

  handleEditCategory(e, category) {
    this.setState({
      ...this.state,
      category: { name: category.name },
      categoryToBeInteracted: category.idcategories,
      showCategoryModal: true,
      updateCategoryForm: true,
    });
  }

  handleDeleteCategory(e, category) {
    this.setState({
      ...this.state,
      categoryToBeInteracted: category.idcategories,
      showCategoryModal: true,
      deleteCategoryForm: true,
    });
  }
}

export default withRouter(SettingsPage);

const styles = StyleSheet.create({
  settingsContent: {
    display: 'grid',
    margin: 0,
    padding: 0,
    position: 'relative',
    listStyle: 'none',
    gridTemplateColumns: '30% 70%',
  },
  settingsBoxList: {
    width: '100%',
    listStyle: 'none',
    paddingLeft: '.5em',
  },
  settingsBox: {
    padding: '1em',
    backgroundColor: Color.white,
    marginBottom: '1rem',
    display: 'block',
    width: '100%',

    boxShadow: '0 .125rem .125rem rgba(21,21,23,.04)',
    boxSizing: 'border-box',
    borderRadius: '4px',
  },
  settingsCategoryBox: {
    padding: '1em',
    backgroundColor: Color.white,
    display: 'block',
    width: '100%',
    marginBottom: 2,
    boxSizing: 'border-box',
    borderRadius: '4px',
  },
  settingsBoxProfile: {
    padding: '1em',
    backgroundColor: Color.white,

    display: 'flex',
    flexDirection: 'column',
    height: '28em',

    boxShadow: '0 .125rem .125rem rgba(21,21,23,.04)',
    boxSizing: 'border-box',
    borderRadius: '4px',
  },
  settingsHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  settingsItem: {
    padding: '.5em',
    fontSize: 20,
    backgroundColor: Color.white,

    display: 'block',
    width: 'auto',
    textAlign: 'left',
  },
  settingsItemStatus: {
    fontSize: 20,
    backgroundColor: Color.white,
    flex: 1,
  },
  width100: {
    width: '100%',
    padding: '.3em',
    fontSize: '.8em',
  },
  statusElement: {
    display: 'table',
    margin: '0 auto',
    paddingTop: '0.25em',
    paddingBottom: '.5em',
    textAlign: 'center',
  },
  datePicker: {
    display: 'table',
    margin: '0 auto',
    marginTop: '-.4em',
    paddingTop: '.1em',
    paddingBottom: '.1em',
    paddingLeft: '1em',
    paddingRight: '1em',
    textAlign: 'center',
    maxWidth: '60%',
  },
  paddingTopBottomDiv: {
    paddingTop: '.5em',
    paddingBottom: '.5em',
  },
  startDate: {
    marginTop: '-0.8em',
    marginBottom: '0.5em',
  },
  h1: {
    fontSize: '1.6875em',
    fontWeight: '600',
    lineHeight: '30px',
    color: Color.dark,
    minWidth: '90px',
  },
  accountText: {
    fontSize: '1.5em',
    fontWeight: 'bold',
    marginBottom: '.5em',
  },
  smallText: {
    marginTop: '0.2em',
    fontSize: 12,
  },
  asterix: {
    color: 'red',
  },
  em: {
    fontWeight: 'normal',
    fontStyle: 'normal',
    opacity: 0.4,
  },
  settingsBoxHeading: {
    fontSize: '1.1em',
    fontWeight: 'bold',
    textAlign: 'left',
  },
  settingsEditButton: {
    marginRight: 0,
    marginTop: '.8em',
    marginLeft: 'auto',
    textAlign: 'right',
  },
  inputDiv: {
    padding: 'auto',
    marginBottom: '2em',
  },
  inputHeading: {
    fontSize: '.7em',
    fontWeight: 'bold',
    marginBottom: '.5em',
  },
  addPicButton: {
    display: 'flex',
    marginTop: '-40%',
    marginLeft: '40%',
    marginBottom: 0,
  },
  legendContainer: {
    marginLeft: '30%',
    display: 'flex',
  },

  settingsCategoriesWrapper: {
    border: 'rgba(214, 214, 220, 1) solid 1px',
    borderBottom: 'none',
    borderRadius: '0.25rem',
    marginTop: '2rem',
  },

  settingsCategoryHeader: {
    fontSize: '.9rem',
    paddingTop: '.7rem',
    paddingBottom: '.7rem',
    paddingLeft: '.7rem',
    margin: 0,
    fontWeight: '600',
    borderBottom: 'rgba(214, 214, 220, 1) solid 1px',
  },
});

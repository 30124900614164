import { CALL_API } from '../middleware/api';

/**
 * POST referrals/single/:id
 */
export const UPDATE_REFERRAL_REQUEST = 'UPDATE_REFERRAL_REQUEST';
export const UPDATE_REFERRAL_SUCCESS = 'UPDATE_REFERRAL_SUCCESS';
export const UPDATE_REFERRAL_FAILURE = 'UPDATE_REFERRAL_FAILURE';

export function updateReferral(id, referralData) {
  return {
    [CALL_API]: {
      endpoint: `referrals/single/${id}`,
      authenticated: false,
      types: [UPDATE_REFERRAL_REQUEST, UPDATE_REFERRAL_SUCCESS, UPDATE_REFERRAL_FAILURE],
      method: 'POST',
      data: referralData,
    },
  };
}

/**
 * GET referrals/single/:id/documet
 */
export const GET_REFERRAL_DOCUMENT_REQUEST = 'GET_REFERRAL_DOCUMENT_REQUEST';
export const GET_REFERRAL_DOCUMENT_SUCCESS = 'GET_REFERRAL_DOCUMENT_SUCCESS';
export const GET_REFERRAL_DOCUMENT_FAILURE = 'GET_REFERRAL_DOCUMENT_FAILURE';

export function getReferralDocument(id) {
  return {
    [CALL_API]: {
      endpoint: `referrals/single/${id}/document`,
      authenticated: true,
      types: [
        GET_REFERRAL_DOCUMENT_REQUEST,
        GET_REFERRAL_DOCUMENT_SUCCESS,
        GET_REFERRAL_DOCUMENT_FAILURE,
      ],
      method: 'GET',
    },
  };
}

/**
 * POST referrals/single/:id/documet
 */
export const POST_REFERRAL_DOCUMENT_REQUEST = 'POST_REFERRAL_DOCUMENT_REQUEST';
export const POST_REFERRAL_DOCUMENT_SUCCESS = 'POST_REFERRAL_DOCUMENT_SUCCESS';
export const POST_REFERRAL_DOCUMENT_FAILURE = 'POST_REFERRAL_DOCUMENT_FAILURE';

export function postReferralDocument(id, data) {
  return {
    [CALL_API]: {
      endpoint: `referrals/single/${id}/document`,
      authenticated: false,
      types: [
        POST_REFERRAL_DOCUMENT_REQUEST,
        POST_REFERRAL_DOCUMENT_SUCCESS,
        POST_REFERRAL_DOCUMENT_FAILURE,
      ],
      method: 'POST',
      data: data,
    },
  };
}

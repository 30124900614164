import { Box, Button, Icon } from '@talentech/components';
import { StyleSheet, css } from 'aphrodite';
import { useState } from 'react';

import { TALENTECH_ICONS } from '../Icons';

import Loader from '../Loader';

import useFetchEmailPreview from '../../hooks/communication/useFetchEmailPreview';
import { formatVariablesForPreview } from '../../helpers/communication';
import { useTranslation } from 'react-i18next';

const TabContentMail = (props) => {
  const { handleOnClickEditMail, email, handlePreviewDefaultContent, isButtonVisible } = props;
  const { htmlContent, preview, isLoading } = useFetchEmailPreview(email);
  const [replacedContent, setReplacedContent] = useState('');
  const [contentHeight, setContentHeight] = useState('');
  const { t } = useTranslation();

  const previewFetched =
    Object.keys(preview.response).length > 0 &&
    preview.response &&
    !preview.error &&
    !preview.isLoading;

  const fetchContent = () => {
    let replacedContent = htmlContent.replace('{{content}}', preview.response.template_HTML);
    const formattedContent = formatVariablesForPreview(replacedContent, email.template_unique_name);

    return formattedContent;
  };

  const handleFrameLoaded = () => {
    let iframe = document.getElementById('preview');
    let iframeDoc = iframe.contentDocument || iframe.contentWindow;
    if (iframeDoc.document) {
      iframeDoc = iframeDoc.document;
    }
    let height = iframeDoc.body.scrollHeight;
    setContentHeight(height);
    setReplacedContent(fetchContent());
  };

  const setContentWrapperHeight = () => {
    return StyleSheet.create({
      sectionHeight: {
        height: contentHeight + 50 + 'px',
      },
    }).sectionHeight;
  };

  return (
    <Loader isLoading={isLoading}>
      <Box className="row m-0">
        <Box className="col d-flex justify-content-between align-items-center ps-4 pe-4">
          <div className={css(styles.tabContentHeader)}>
            {t('communication-item-channel-email')}
          </div>
          <div className="flex-shrink-1">
            {isButtonVisible && (
              <Button
                icon={<Icon icon={TALENTECH_ICONS.view} iconSize="sm" />}
                label={t('communication-view-original')}
                color="primary"
                size="sm"
                outline={true}
                onClick={handlePreviewDefaultContent}
              />
            )}
          </div>
        </Box>
      </Box>
      <Box className="row m-0">
        <Box className="col ps-4 pe-4">
          <div className={css(styles.tabContentBodyWrapper)}>
            <div className={css(styles.tabContentSubjectWrapper)}>
              <div className={css(styles.tabContentSubjectTitle)}>
                {t('generic-text-subject').toUpperCase()}
              </div>
              <div className={css(styles.tabContentSubjectText)}>{email.subject}</div>
            </div>
            <div className={css([styles.tabContentContentWrapper, setContentWrapperHeight()])}>
              {previewFetched && (
                <iframe
                  title="Preview Email"
                  id="preview"
                  src="/file/template.html" // Fallback URL
                  width="100%"
                  height="100%"
                  srcDoc={replacedContent}
                  onLoad={handleFrameLoaded}
                ></iframe>
              )}
            </div>
          </div>
        </Box>
      </Box>
      <Box className="row">
        <Box className="col ps-4 pb-3">
          <Button
            className={css(styles.editButton)}
            label={t('generic-text-edit')}
            color="primary"
            size="sm"
            onClick={handleOnClickEditMail}
          />
        </Box>
      </Box>
    </Loader>
  );
};

export default TabContentMail;

const styles = StyleSheet.create({
  tabContentHeader: {
    fontWeight: 'bold',
    fontSize: '1.5rem',
    lineHeight: '1.5rem',
    margin: '1.5rem 0',
  },
  tabContentBodyWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    backgroundColor: 'rgba(241, 241, 243, 0.50)',
    borderRadius: '0.25rem',
    border: `1px solid #D6D6DC`,
  },
  tabContentSubjectWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '1rem',
    borderBottom: `1px solid #D6D6DC`,
  },
  tabContentSubjectTitle: {
    fontSize: '0.75rem',
    color: '#60606C',
    fontWeight: 'lighter',
  },
  tabContentSubjectText: {
    fontSize: '1rem',
    lineHeight: '1.5rem',
  },
  tabContentContentWrapper: {
    height: '100%',
    padding: '1rem',
  },
});

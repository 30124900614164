import { Box, Button } from '@talentech/components';
import { StyleSheet, css } from 'aphrodite';
import { useEffect, useState } from 'react';

import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import {
  getSinglePushNotification,
  togglePushNotification,
} from '../../irecommend-lib/actions/communicationActions';
import { useTranslation } from 'react-i18next';

const TabContentPush = (props) => {
  const { handleOnClickEditPush, pushNotification } = props;
  const [toggled, setToggled] = useState(false);
  const { response } = useSelector(
    (state) => state.communicationState.pushNotificationActiveState,
    shallowEqual,
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    setToggled(pushNotification?.active);
  }, [pushNotification]);

  useEffect(() => {
    if (Object.keys(pushNotification).length > 0 && response.active !== undefined)
      dispatch(getSinglePushNotification(pushNotification?._id));

    return () => dispatch({ type: 'PUT_SINGLE_PUSH_NOTIFICATION_ACTIVE_RESET' });
  }, [response?.active, pushNotification, dispatch]);

  const handleToggleActive = (e, notificationId) =>
    dispatch(togglePushNotification(notificationId));

  return (
    <>
      <Box className="row m-0">
        <Box className="col ps-4 pe-4 d-flex justify-content-between align-items-center">
          <div className={css(styles.tabContentHeader)}>{t('communication-item-channel-push')}</div>
          <div className={css(styles.tabContentToggle)}>
            <div className={css(styles.tabContentToggleText)}>
              {t(`communication-dropdown-turn-${toggled ? 'on' : 'off'}`)}
            </div>
            <div className={css(styles.tabContentToggleSwitch)}>
              <span className="form-check form-switch d-inline">
                <input
                  onChange={(e) => handleToggleActive(e, pushNotification?._id)}
                  className="form-check-input"
                  style={{ cursor: 'pointer' }}
                  type="checkbox"
                  id="toggleCommunication"
                  checked={toggled}
                />
              </span>
            </div>
          </div>
        </Box>
      </Box>
      <Box className="row m-0">
        <Box className="col ps-4 pe-4">
          <div className={css(styles.tabContentBodyWrapper)}>
            <div className={css(styles.tabContentContentWrapper)}>
              <h6>{pushNotification?.subject}</h6>
              <p>{pushNotification?.template_body}</p>
            </div>
          </div>
        </Box>
      </Box>
      <Box className="row">
        <Box className="col ps-4 pb-3">
          <Button
            className={css(styles.editButton)}
            label={t('generic-text-edit')}
            color="primary"
            size="sm"
            onClick={handleOnClickEditPush}
          />
        </Box>
      </Box>
    </>
  );
};

export default TabContentPush;

const styles = StyleSheet.create({
  tabContentHeader: {
    fontWeight: 'bold',
    fontSize: '1.5rem',
    lineHeight: '1.5rem',
    margin: '1.5rem 0',
  },
  tabContentBodyWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    backgroundColor: 'rgba(241, 241, 243, 0.50)',
    borderRadius: '0.25rem',
    border: `1px solid #D6D6DC`,
  },
  tabContentContentWrapper: {
    padding: '1rem',
  },
  tabContentToggle: {
    display: 'flex',
    alignItems: 'center',
  },
  tabContentToggleText: {
    fontSize: '0.75rem',
    color: '#60606C',
    fontWeight: 'lighter',
    marginRight: '0.5rem',
  },
});

import appState from './appState';
import authState from './authState';
import commentsState from './commentsState';
import communicationState from './communicationState';
import companyState from './companyState';
import competitionState from './competitionState';
import customerState from './customerState';
import goalState from './goalState';
import inviteState from './inviteState';
import integrationState from './integrationState';
import mediaState from './mediaState';
import pointsState from './pointsState';
import positionState from './positionState';
import policyState from './policyState';
import recommendationState from './recommendationState';
import referralState from './referralState';
import ruleState from './ruleState';
import teamState from './teamState';
import transactionState from './transactionState';
import userState from './userState';
import userflowState from './userflowState';
import { reducer as oidcReducer } from 'redux-oidc';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  appState,
  authState,
  commentsState,
  communicationState,
  companyState,
  competitionState,
  customerState,
  goalState,
  inviteState,
  integrationState,
  mediaState,
  positionState,
  pointsState,
  policyState,
  recommendationState,
  referralState,
  ruleState,
  teamState,
  transactionState,
  userState,
  userflowState,
  oidc: oidcReducer,
};

import React, { PureComponent } from 'react';
import { css, StyleSheet } from 'aphrodite';
import PropTypes from 'prop-types';

import Input from '../components/Input';
import ProfilePicture from '../components/ProfilePicture';
import Button from '../components/Button';

import { Color } from '../config/styles';
import { withTranslation } from 'react-i18next';

/**
 * Custom FlatList item specific for displaying highscore lists.
 *
 * Example usage:
 *
 * ```
 * <HighscoreGoalTipListItem
 *   id=1
 *   onPress={onPressLearnMore}
 *   name="John Doe"
 * />
 * ```
 *
 */
class HighscoreGoalTipListItem extends PureComponent {
  static propTypes = {
    /**
     * Handler to be called when the user taps the button
     */
    onPressSubmitForm: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      Goal: '',
      isLoading: false,
    };
  }

  render() {
    const { isLoading } = this.state;
    const { t } = this.props;

    return (
      <div onClick={this._onPressItem} className={css(styles.wrap)}>
        <span className={css(styles.line)} />

        <ProfilePicture picture="" name="?" size="large" style={styles.picture} />

        <div>
          <h2 className={css(styles.header)}>{t('highscore-goal-tip-list-item-header')}</h2>

          <Input
            value={this.state.Goal}
            type="text"
            name="Goal"
            placeholder={t('highscore-goal-tip-list-item-placeholder')}
            onChange={(event) => this.setState({ Goal: event.target.value })}
          />

          <Button
            isLoading={isLoading}
            title={t('highscore-goal-tip-list-item-submit')}
            type="primary"
            onPress={this.onPressSubmitForm}
          />
        </div>
      </div>
    );
  }

  onPressSubmitForm = (e) => {
    e.preventDefault();

    if (this.props.isLoading) {
      return;
    }

    this.props.onPressSubmitForm(this.state.Goal);

    this.setState({
      Goal: '',
    });
  };
}

export default withTranslation()(HighscoreGoalTipListItem);

const styles = StyleSheet.create({
  line: {
    position: 'absolute',
    height: 1,
    width: '.8125em',
    left: 25,
    top: 52,
    backgroundColor: Color.placeholderGray,
  },
  input: {
    marginBottom: 5,
  },
  header: {
    fontSize: '1.0625em',
    fontWeight: 500,
    lineHeight: '1.5rem',
    margin: 0,
  },
  picture: {
    marginLeft: 16,
    marginRight: 16,
    flex: '0 0 auto',
  },
  wrap: {
    display: 'flex',
    flexDirection: 'row',
    padding: 18,
    paddingLeft: 33,
    paddingRight: 25,
    position: 'relative',
  },
});

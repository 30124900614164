import React, { memo } from 'react';
import { Box, Icon } from '@talentech/components';

import { TALENTECH_ICONS } from '../Icons';
import { useTranslation } from 'react-i18next';

const InvitedMemberHeader = (props) => {
  const { t } = useTranslation();
  return (
    <Box className="d-none d-md-flex row mx-auto my-4 px-3 align-middle">
      <Box className="d-flex col-5 bg-gray-50 p-3 border-end border-2 ">
        <span className="fw-bold px-2">{t('generic-text-name').toUpperCase()}</span>
        <Icon icon={TALENTECH_ICONS.carrotDown} />
      </Box>
      <Box className="d-flex col-3 bg-gray-50 p-3">
        <span className="fw-bold px-2">{t('generic-text-created-on').toUpperCase()}</span>
        <Icon icon={TALENTECH_ICONS.carrotDown} />
      </Box>
      <Box className="d-flex col-3 bg-gray-50 p-3 border-end border-2">
        <span className="fw-bold px-2">{t('generic-text-administrator').toUpperCase()}</span>
        <Icon icon={TALENTECH_ICONS.carrotDown} />
      </Box>
      <Box className="d-flex justify-content-center col-1 bg-gray-50 p-3 ">
        <Icon icon={TALENTECH_ICONS.carrotLeft} />
        <Icon icon={TALENTECH_ICONS.carrotRight} />
      </Box>
    </Box>
  );
};

export default memo(InvitedMemberHeader);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getUser } from '../../irecommend-lib/actions/userActions';
import ClientMain from './ClientMain';

class App extends Component {
  componentDidMount() {
    this.props.getUser();
  }
  render() {
    return <ClientMain {...this.props} />;
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getUser: () => dispatch(getUser()),
  };
}

export default connect(null, mapDispatchToProps)(App);

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Color, Font, getStatusList } from '../config/styles';
import { css, StyleSheet } from 'aphrodite';

import Select from '../components/Select';

import {
  IconPush,
  IconEdit,
  IconArchive,
  IconDelete,
  IconXThin,
  IconCheckmarkThin,
  IconPlus,
} from './Icons';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';

class JobsListNav extends Component {
  static propTypes = {
    menu: PropTypes.string,
    position: PropTypes.object,
    showNav: PropTypes.bool,
    /**
     * Handle direct status changes. Will not show select if not provided.
     */
    onChangeStatus: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      status: '',
    };
  }

  componentDidMount() {
    const { showActionNav } = this.props;

    this.setState({
      showActionNav: showActionNav === true,
    });

    // Update select value when recommendation state changes
    if (this.props.recommendation) {
      this.setState({
        status: this.props.recommendation.state,
      });
    }
  }

  render() {
    const {
      t,
      menu,
      position,
      recommendation,
      onPressDeleteItem,
      onPressArchiveItem,
      handleShowForm,
      onPressPushPosition,
      onPressToggleNav,
      onPressVerifyContact,
      onPressVerifyInterview,
      onPressVerifyHire,
      onPressFailedContact,
      onPressNotInterested,
      onPressFailedAfterInterview,
      onPressFailedInterview,
      onPressFailedHire,
      showActionNav,
    } = this.props;

    const statusSelect = getStatusList().map((item) => {
      return {
        title: t(item.key),
        value: item.id,
      };
    });

    let items = {
      archived: [
        {
          icon: <IconEdit />,
          action: null,
          href: `/admin/jobs/edit/${position.idpositions}`,
          text: t('generic-text-edit'),
          extraStyles: null,
        },
        {
          icon: <IconDelete />,
          action: (e) => {
            e.preventDefault();
            onPressToggleNav(e);
            onPressDeleteItem(position);
          },
          href: '#',
          text: t('generic-text-delete'),
          extraStyles: styles.listItemDeleted,
        },
      ],
      candidateContact: [
        {
          icon: <IconCheckmarkThin />,
          action: (e) => {
            e.preventDefault();
            onPressToggleNav(e);
            onPressVerifyContact(recommendation);
          },
          href: '#',
          text: t('generic-text-contacted'),
          extraStyles: null,
        },
        {
          icon: <IconXThin />,
          action: (e) => {
            e.preventDefault();
            onPressToggleNav(e);
            onPressFailedContact(recommendation);
          },
          href: '#',
          text: t('generic-text-failed-contact'),
          extraStyles: styles.listItemDeleted,
        },
        {
          icon: <IconXThin />,
          action: (e) => {
            e.preventDefault();
            onPressToggleNav(e);
            onPressNotInterested(recommendation);
          },
          href: '#',
          text: t('generic-text-not-interested'),
          extraStyles: styles.listItemDeleted,
        },
      ],
      candidateInterview: [
        {
          icon: <IconCheckmarkThin />,
          action: (e) => {
            e.preventDefault();
            onPressToggleNav(e);
            onPressVerifyInterview(recommendation);
          },
          href: '#',
          text: t('generic-text-interviewed'),
          extraStyles: null,
        },
        {
          icon: <IconXThin />,
          action: (e) => {
            e.preventDefault();
            onPressToggleNav(e);
            onPressFailedAfterInterview(recommendation);
          },
          href: '#',
          text: t('generic-text-failed-after-interview'),
          extraStyles: styles.listItemDeleted,
        },
        {
          icon: <IconXThin />,
          action: (e) => {
            e.preventDefault();
            onPressToggleNav(e);
            onPressFailedInterview(recommendation);
          },
          href: '#',
          text: t('generic-text-failed-interview'),
          extraStyles: styles.listItemDeleted,
        },
      ],
      candidateHire: [
        {
          icon: <IconCheckmarkThin />,
          action: (e) => {
            e.preventDefault();
            onPressToggleNav(e);
            onPressVerifyHire(recommendation);
          },
          href: '#',
          text: t('generic-text-hired'),
          extraStyles: null,
        },
        {
          icon: <IconXThin />,
          action: (e) => {
            e.preventDefault();
            onPressToggleNav(e);
            onPressFailedHire(recommendation);
          },
          href: '#',
          text: t('generic-text-not-hired'),
          extraStyles: styles.listItemDeleted,
        },
      ],
      default: [
        {
          icon: <IconEdit />,
          action: (e) => {
            e.preventDefault();
            this.props.history.push(`/admin/jobs/edit/${position.idpositions}`);
          },
          href: '#',
          text: t('generic-text-edit'),
          extraStyles: null,
        },
        {
          icon: <IconPush />,
          action: (e) => {
            e.preventDefault();
            onPressToggleNav(e);
            onPressPushPosition(position);
          },
          href: '#',
          text: t('generic-text-push-again'),
          extraStyles: null,
        },
        {
          icon: <IconPlus />,
          action: (e) => {
            e.preventDefault();
            onPressToggleNav(e);
            handleShowForm('manual');
          },
          href: '#',
          text: t('candidate-manual-form-title'),
          extraStyles: null,
        },
        {
          icon: <IconArchive />,
          action: (e) => {
            e.preventDefault();
            onPressToggleNav(e);
            onPressArchiveItem(position);
          },
          href: '#',
          text: t('generic-text-archive'),
          extraStyles: styles.seperateItems,
        },
        {
          icon: <IconDelete />,
          action: (e) => {
            e.preventDefault();
            onPressToggleNav(e);
            onPressDeleteItem(position);
          },
          href: '#',
          text: t('generic-text-delete'),
          extraStyles: styles.listItemDeleted,
        },
      ],
    };

    // Just show manual form menu link on jobs page
    if (this.props.location.pathname.match(new RegExp('jobs')) === null) {
      delete items.default[2];
    }

    return (
      <nav className={css(styles.navWrapper, showActionNav && styles.show)}>
        <div onClick={onPressToggleNav} className={css(styles.navBackground)} />
        <ul className={css(styles.navInner, !items[menu] && styles.navInnerNoMenu)}>
          {items[menu] &&
            items[menu].map((item, index) => (
              <li key={`jobsNavItem-${index}`} className={css(styles.listItem, item.extraStyles)}>
                <a className={css(styles.link)} href={item.href} onClick={item.action}>
                  <i className={css(styles.icon)}>{item.icon}</i>
                  <span className={css(styles.span)}>{item.text}</span>
                </a>
              </li>
            ))}
          {this.props.onChangeStatus && (
            <li>
              {items[menu] && <hr className={css(styles.changeStatusHr)} />}

              <div className={css(styles.changeStatus)}>
                <Select
                  optionsData={statusSelect}
                  selected={this.state.status.toString()}
                  onChange={this.onChangeStatus}
                  placeholder={t('jobslistnav-change-state-placeholder')}
                />
              </div>
            </li>
          )}
        </ul>
      </nav>
    );
  }

  onChangeStatus = (status) => {
    this.setState({
      status: status,
    });

    this.props.onChangeStatus(status);
  };
}

export default withRouter(withTranslation()(JobsListNav));

const styles = StyleSheet.create({
  changeStatus: {
    padding: '1rem 1.5625rem',
  },
  changeStatusHr: {
    border: 'none',
    borderTop: '1px solid rgba(0, 0, 0, .06)',
  },
  navWrapper: {
    position: 'absolute',
    top: '110%',
    right: '0%',
    width: '13.5rem',
    zIndex: 10,
    display: 'none',
  },
  navBackground: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, .05)',
    cursor: 'default',
    zIndex: 10,
  },
  show: {
    display: 'block',
  },
  navInner: {
    backgroundColor: Color.white,
    boxShadow: '0 5px 17px rgba(0, 0, 0, .03)',
    borderRadius: '2px',
    margin: 0,
    padding: '1.625rem 0 .25rem',
    listStyle: 'none',
    zIndex: 11,
    position: 'relative',
  },
  navInnerNoMenu: {
    padding: '1em 0 .5625em',
  },
  listItem: {
    color: Color.primary,
    fill: Color.primary,
    marginBottom: '1.375rem',
  },
  seperateItems: {
    borderTop: '2px solid #EDEDED',
    paddingTop: '.5rem',
  },
  listItemDeleted: {
    color: Color.warning,
    fill: Color.warning,
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    color: 'inherit',
    fill: 'inherit',
    padding: '0 1.5625rem',
    textDecoration: 'none',
    fontFamily: Font.defaultFont,
    fontWeight: 600,
  },
  icon: {
    display: 'inline-block',
    verticalAlign: 'top',
    width: '1.125rem',
    height: '1.125rem',
    flex: '0 0 1.125rem',
    marginRight: '.5rem',
  },
  span: {
    display: 'inline-block',
    verticalAlign: 'top',
    fontSize: '.8125rem',
    paddingTop: '.25rem',
  },
});

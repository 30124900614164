import {
  UPLOAD_PICTURE_REQUEST,
  UPLOAD_PICTURE_SUCCESS,
  UPLOAD_PICTURE_FAILURE,
} from '../actions/mediaActions';

const initialState = {
  uploadPicture: {
    error: false,
    isLoading: false,
    response: {},
  },
};

export default function mediaState(state = initialState, { type, payload }) {
  switch (type) {
    case UPLOAD_PICTURE_REQUEST:
      return {
        ...state,
        uploadPicture: {
          ...state.uploadPicture,
          isLoading: true,
        },
      };
    case UPLOAD_PICTURE_SUCCESS:
      return {
        ...state,
        uploadPicture: {
          ...state.uploadPicture,
          isLoading: false,
          response: payload.response,
        },
      };
    case UPLOAD_PICTURE_FAILURE:
      return {
        ...state,
        uploadPicture: {
          ...state.uploadPicture,
          isLoading: false,
          response: payload.error,
        },
      };
    default:
      return state;
  }
}

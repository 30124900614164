import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getIntegrationType, getToken } from '../../irecommend-lib/actions/integrationActions';

const useFetchIntegration = () => {
  const { token, integrationType } = useSelector((state) => state.integrationState);
  const dispatch = useDispatch();

  const tokenExist = Object.keys(token.response).length !== 0 ? token.response.tokenexist : null;
  // Make sure that "Fetch jobs" button is visible/invisible depending on integration status
  const hasRMOrTRIntegration =
    integrationType.response.length > 0 &&
    (integrationType.response[0] === 20 || integrationType.response[0] === 30);

  useEffect(() => {
    dispatch(getToken());
    dispatch(getIntegrationType());
  }, [dispatch]);

  return { tokenExist, hasRMOrTRIntegration };
};

export default useFetchIntegration;

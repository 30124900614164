import { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  getSingleSMSNotification,
  updateSmsNotification,
} from '../../irecommend-lib/actions/communicationActions';

const useUpdateSmsNotification = (id, setIsEditSms) => {
  const { customSmsNotification } = useSelector((state) => state.communicationState, shallowEqual);
  const isLoading = customSmsNotification.isLoading;
  const dispatch = useDispatch();

  useEffect(() => {
    if (Object.keys(customSmsNotification.response).length > 0) {
      dispatch(getSingleSMSNotification(id));
      setIsEditSms(false);
    }
  }, [customSmsNotification.response, id, setIsEditSms, dispatch]);

  const updateSms = (data) => {
    dispatch(updateSmsNotification(id, data));
  };

  return { updateSms, updatePushIsLoading: isLoading };
};

export default useUpdateSmsNotification;

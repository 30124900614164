import MainWrapper from '../components/MainWrapper';
import React, { Component } from 'react';

import { Color, Variables, Font } from '../config/styles';
import { css, StyleSheet } from 'aphrodite';
import { withTranslation } from 'react-i18next';

class NotFoundContainer extends Component {
  render() {
    const { t } = this.props;
    return (
      <MainWrapper>
        <div className={css(styles.container)}>
          <h1 className={css(styles.container)}>{t('notfoundcontainer-404')}</h1>
        </div>
      </MainWrapper>
    );
  }
}

export default withTranslation(NotFoundContainer);

const styles = StyleSheet.create({
  container: {
    minHeight: Variables.MainWrapperHeight,
    padding: Variables.BubbleSectionPadding,
    background: 'white',
  },
  h1: {
    fontFamily: Font.defaultFont,
    fontWeight: 600,
    fontSize: '1.5em',
    lineHeight: '30px',
    color: Color.dark,
  },
});

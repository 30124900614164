import React, { Component } from 'react';

export class IconArchive extends Component {
  render() {
    return (
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="96px"
        height="96px"
        viewBox="0 0 96 96"
        xmlSpace="preserve"
      >
        <rect x="29.838" y="40.216" width="36.324" height="10.378" />
        <path
          d="M90.811,9.081C90.811,4.074,86.736,0,81.73,0H14.27C9.264,0,5.189,4.074,5.189,9.081v19.459h5.189v58.378
					c0,5.007,4.074,9.081,9.081,9.081h57.081c5.007,0,9.081-4.074,9.081-9.081V28.541h5.189V9.081z M12.973,9.081
					c0-0.715,0.583-1.297,1.297-1.297H81.73c0.715,0,1.297,0.583,1.297,1.297v11.676H12.973V9.081z M77.838,86.919
					c0,0.715-0.583,1.297-1.297,1.297H19.459c-0.715,0-1.297-0.583-1.297-1.297V28.541h59.676V86.919z"
        />
      </svg>
    );
  }
}

export class IconArrowDown extends Component {
  render() {
    const style = this.props.style;
    return (
      <svg
        style={style}
        id="icon--arrow-down"
        viewBox="0 0 20 20"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g stroke="none" strokeWidth="1" fillRule="evenodd">
          <g id="regular-arrows-down" transform="translate(0.000000, 4.000000)">
            <path d="M9.99999752,8.22212 L16.4762,0.51226 C16.9999975,-0.1112 17.9098,-0.17436 18.5082,0.37116 C19.1068,0.91668 19.1674,1.86432 18.6436,2.48778 L11.0837,11.4878 C10.51,12.1708 9.49,12.1708 8.9163,11.4878 L1.3563,2.48778 C0.8326,1.86432 0.89326,0.91668 1.49178,0.37116 C2.09028,-0.17436 3.00002,-0.1112 3.52372,0.51226 L9.99999752,8.22212 Z" />
          </g>
        </g>
      </svg>
    );
  }
}

export class IconCheckmark extends Component {
  render() {
    const className = this.props.className;
    return (
      <svg
        className={className}
        viewBox="0 0 96 96"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <polygon points="46.481,90.71 0,50.293 10.082,38.704 43.15,67.456 83.068,5.29 96,13.594" />
      </svg>
    );
  }
}

export class IconCheckmarkThin extends Component {
  render() {
    const className = this.props.className;
    return (
      <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        width="96"
        height="96"
        viewBox="0 0 96 96"
      >
        <path d="M41.4235753,88.4488868 L41.1928418,88.9074738 L1,59.6385898 L3.47022244,56.2386203 L40.2699709,82.9752026 L91.5158921,7 L95,9.35006042 L41.5772513,88.5525425 L41.4235753,88.4488868 Z" />
      </svg>
    );
  }
}

export class IconDelete extends Component {
  render() {
    return (
      <svg
        viewBox="0 0 96 96"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g>
          <path
            d="M81.951,14.049H67.902V8.195C67.902,3.677,64.226,0,59.707,0H36.293c-4.518,0-8.195,3.677-8.195,8.195v5.854
						H14.049H8.195v7.024h5.854v66.732c0,4.518,3.677,8.195,8.195,8.195h51.512c4.518,0,8.195-3.677,8.195-8.195V21.073h5.854v-7.024
						H81.951z M35.122,8.195c0-0.645,0.526-1.171,1.171-1.171h23.415c0.645,0,1.171,0.526,1.171,1.171v5.854H35.122V8.195z
						 M74.927,87.805c0,0.645-0.526,1.171-1.171,1.171H22.244c-0.645,0-1.171-0.526-1.171-1.171V21.073h7.024h39.805h7.024V87.805z"
          />
          <rect x="31.61" y="36.293" width="4.683" height="37.463" />
          <rect x="45.659" y="36.293" width="4.683" height="37.463" />
          <rect x="59.707" y="36.293" width="4.683" height="37.463" />
        </g>
      </svg>
    );
  }
}

export class IconEdit extends Component {
  render() {
    return (
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="96px"
        height="96px"
        viewBox="0 0 96 96"
        xmlSpace="preserve"
      >
        <g>
          <path d="M93.09,2.913c-3.888-3.886-10.183-3.882-14.068,0c0,0-1.696,1.696-1.686,1.686l14.065,14.064c-0.01,0.008,1.684-1.686,1.684-1.686C96.97,13.092,96.97,6.795,93.09,2.913z" />
          <path d="M17.259,84.201l-7.463,2.002l2.002-7.458L80.22,10.323l-5.736-5.736L5.293,73.775c-0.504,0.502-0.866,1.13-1.051,1.817L0.138,90.893c-0.375,1.4,0.026,2.894,1.051,3.919C1.961,95.583,2.996,96,4.057,96c0.351,0,0.705-0.048,1.051-0.137l15.301-4.109c0.687-0.185,1.315-0.549,1.817-1.051l69.19-69.185l-5.736-5.736L17.259,84.201z" />
        </g>
      </svg>
    );
  }
}

export class IconPush extends Component {
  render() {
    return (
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="96px"
        height="96px"
        viewBox="0 0 96 96"
        xmlSpace="preserve"
      >
        <polygon points="73.737,25.737 48,0 22.263,25.737 27.982,31.456 43.956,15.482 43.956,96 52.044,96 52.044,15.482 68.018,31.456 " />
      </svg>
    );
  }
}

export class IconBubble extends Component {
  render() {
    const { className } = this.props;
    return (
      <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        width="96"
        height="96"
        viewBox="0 0 96 96"
      >
        <path d="M48.00032,6.00042667 C21.5457436,6.00042667 0,22.7461383 0,43.2252082 C0,63.8130788 21.5457436,80.4499897 48.00032,80.4499897 C52.4794965,80.4499897 56.8545657,80.0249842 61.1214741,79.0624604 L82.346149,90.3713358 L80.5336569,70.6378709 C81.2794965,70.104534 82.0252935,69.5711971 82.7753785,68.9294862 C91.3087687,62.1086407 96,52.9336462 96,43.3335822 C96,22.7499783 74.4542564,6 47.99968,6 L48.00032,6.00042667 Z" />
      </svg>
    );
  }
}

export class IconCaretDown extends Component {
  render() {
    const { className } = this.props;
    return (
      <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        width="96"
        height="96"
        viewBox="0 0 96 96"
      >
        <polygon fill="#000000" points="48 61.38 88.38 21 95 27.62 48 74.62 1 27.62 7.62 21" />
      </svg>
    );
  }
}

export class IconCaretRight extends Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 96 96">
        <polygon
          fill="#000000"
          points="88.19 21.19 94.81 27.81 47.81 74.81 .81 27.81 7.43 21.19 47.81 61.57"
          transform="rotate(-90 47.81 48)"
        />
      </svg>
    );
  }
}

export class IconPlus extends Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 96 96">
        <g fill="#000000" transform="translate(1 1)">
          <polygon points="43.104 0 51.398 0 51.398 94 43.104 94" />
          <polygon points="94 43.104 94 51.398 0 51.398 0 43.104" />
        </g>
      </svg>
    );
  }
}

export class IconX extends Component {
  render() {
    const className = this.props.className;
    return (
      <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        width="96"
        height="96"
        viewBox="0 0 96 96"
      >
        <path d="M47.9999742,57.2530742 L10.8175615,94.4354868 L0.564513199,84.1824385 L37.7469258,47.0000258 L2.11581891,11.3689189 L12.3689189,1.11581891 L48.0000258,36.7469258 L83.6311328,1.11581891 L93.8841811,11.3688672 L58.2530742,46.9999742 L95.4368867,84.1837867 L85.1837867,94.4368867 L47.9999742,57.2530742 Z" />
      </svg>
    );
  }
}

export class IconXThin extends Component {
  render() {
    const className = this.props.className;
    return (
      <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        width="96"
        height="96"
        viewBox="0 0 96 96"
      >
        <path d="M48,44.8180195 L85.5756764,7.24234308 L87.1666667,5.65135282 L90.3486472,8.83333333 L88.7576569,10.4243236 L51.1819805,48 L90.3089626,87.1269821 L91.8999529,88.7179724 L88.7179724,91.8999529 L87.1269821,90.3089626 L48,51.1819805 L8.87301788,90.3089626 L7.28202762,91.8999529 L4.10004711,88.7179724 L5.69103736,87.1269821 L44.8180195,48 L7.24234308,10.4243236 L5.65135282,8.83333333 L8.83333333,5.65135282 L10.4243236,7.24234308 L48,44.8180195 Z" />
      </svg>
    );
  }
}

export class IconEye extends Component {
  render() {
    const className = this.props.className;

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        width="96"
        height="96"
        viewBox="0 0 96 96"
      >
        <g fill="#000000" transform="translate(8 20)">
          <path d="M81.1632653,23.7714286 C80.4195011,22.6571429 63.3129252,0 41,0 C18.6870748,0 1.58049887,22.6571429 0.836734694,23.7714286 C-0.278911565,25.2571429 -0.278911565,26.7428571 0.836734694,28.2285714 C1.58049887,29.3428571 18.6870748,52 41,52 C63.3129252,52 80.4195011,29.3428571 81.1632653,28.2285714 C82.2789116,26.7428571 82.2789116,25.2571429 81.1632653,23.7714286 Z M41,44.5714286 C30.5873016,44.5714286 22.4058957,36.4 22.4058957,26 C22.4058957,15.6 30.5873016,7.42857143 41,7.42857143 C51.4126984,7.42857143 59.5941043,15.6 59.5941043,26 C59.5941043,36.4 51.4126984,44.5714286 41,44.5714286 Z" />
          <circle cx="40" cy="25" r="9" />
        </g>
      </svg>
    );
  }
}

export class IconEyeStroked extends Component {
  render() {
    const className = this.props.className;

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        width="96"
        height="96"
        viewBox="0 0 96 96"
      >
        <g fill="none" transform="translate(5 3)">
          <path
            fill="#000000"
            d="M84.0342857,40.7885714 C83.2971429,39.6828571 66.3428571,17.2 44.2285714,17.2 C22.1142857,17.2 5.16,39.6828571 4.42285714,40.7885714 C3.31714286,42.2628571 3.31714286,43.7371429 4.42285714,45.2114286 C5.16,46.3171429 22.1142857,68.8 44.2285714,68.8 C66.3428571,68.8 83.2971429,46.3171429 84.0342857,45.2114286 C85.14,43.7371429 85.14,42.2628571 84.0342857,40.7885714 Z M44.2285714,61.4285714 C33.9085714,61.4285714 25.8,53.32 25.8,43 C25.8,32.68 33.9085714,24.5714286 44.2285714,24.5714286 C54.5485714,24.5714286 62.6571429,32.68 62.6571429,43 C62.6571429,53.32 54.5485714,61.4285714 44.2285714,61.4285714 Z"
          />
          <circle cx="44.229" cy="43" r="9.214" fill="#000000" />
          <polygon fill="#E3E3E3" points="-4.053 2.793 77.326 87.187 81.453 83.207 .074 -1.187" />
          <polygon fill="#000000" points="1.68 2.793 83.06 87.187 87.187 83.207 5.807 -1.187" />
        </g>
      </svg>
    );
  }
}

export const TALENTECH_ICONS = {
  arrowUp: 'light-52_arrows_diagrams-arrows-arrow_up',
  calender: 'light-01_interface_essentials-date_calendar-calendar me-2',
  carrotDown: 't-icon regular-01_interface_essentials-navigate-carrot_down',
  carrotLeft: 't-icon regular-01_interface_essentials-navigate-carrot_left',
  carrotRight: 't-icon regular-01_interface_essentials-navigate-carrot_right',
  changeLanguage: 't-icon bold-48_maps_navigation-earth-earth_3',
  changeTeam:
    't-icon regular-17_users-geomertric_close_up_multiple_users_actions-multiple_actions_refresh',
  checkMark: 't-icon regular-01_interface_essentials-form_validation-check_bold',
  checkMarkWithCircle: 't-icon light-01_interface_essentials-alerts-checkmark',
  communicationChannel: 'light-21_messages_chat_smileys-conversation-conversation_chat_1 me-2',
  delete: 'light-01_interface_essentials-delete-bin',
  file: 't-icon light-16_files_folders-common_files-common_file_text',
  fileDownload: 't-icon light-16_files_folders-common_files-common_file_text_download',
  editLight: 'light-01_interface_essentials-edit-pencil',
  editRegular: 'regular-01_interface_essentials-edit-pencil',
  editPencil: 'light-01_interface_essentials-edit-pencil_write',
  fileUpload: 't-icon regular-16_files_folders-common_files-common_file_text_upload',
  filter: 'light-01_interface_essentials-filter-filter_2',
  filter2: '"t-icon regular-01_interface_essentials-filter-filter_2"',
  left: 't-icon light-52_arrows_diagrams-arrows-arrow_thick_left_2',
  manageAccount: 't-icon regular-01_interface_essentials-setting-cog',
  more: 't-icon light-01_interface_essentials-menu-more_horizontal',
  multipleKey:
    't-icon light-17_users-geomertric_close_up_multiple_users_actions-multiple_actions_key',
  multipleRemove:
    't-icon light-17_users-geomertric_close_up_multiple_users_actions-multiple_actions_remove',
  multipleUsers: 'light-17_users-geomertric_close_up_multiple_users-multiple_neutral_1 me-2',
  magnifier: 'light-01_interface_essentials-search-search',
  plus: 't-icon regular-01_interface_essentials-remove_add-add',
  plusCircled: 't-icon regular-01_interface_essentials-alerts-plus',
  power: 'light-01_interface_essentials-on_off-power_button',
  recurring: 'light-01_interface_essentials-synchronize-synchronize_arrows_1  me-2',
  remove: 't-icon light-01_interface_essentials-alerts-stop',
  removeCircle: 'light-01_interface_essentials-remove_add-remove_circle',
  signOut: 't-icon bold-01_interface_essentials-login_logout-logout',
  switchWorkSpace:
    't-icon bold-17_users-geometric_full_body_single_user_neutral-single_neutral_rotate',
  squareArrows: 'regular-01_interface_essentials-synchronize-arrows_square_1',
  view: 'light-01_interface_essentials-view-view_1 me-2',
  stars: 'light-22_social_medias_rewards_ratings-mood-reward_stars_3',
};

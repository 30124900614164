import Banner from 'react-smartbanner';
import 'react-smartbanner/dist/main.css';
import './style.css';
function SmartBanner() {
  // Screen width threshold to consider as a mobile device
  const maxMobileScreenWidth = 760;

  // Checking if the device is a mobile device based on screen width
  const isMobileDevice = window.innerWidth <= maxMobileScreenWidth;

  return isMobileDevice ? <Banner title="irecommend" button="View" /> : null;
}

export default SmartBanner;

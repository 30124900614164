import React, { Component } from 'react';
import { connect } from 'react-redux';

import LineChart from '../LineChart';
import BarDiagram from '../BarDiagram';

class GraphView extends Component {
  //create a bar chart for every data

  renderBarcharts() {
    // return this.props.positions.map(bar => {
    //   return (
    //     <div className="idv-bar" key={bar.id}>
    //       <BarDiagram data={bar} />
    //     </div>
    //   );
    // });
    return <BarDiagram style={{ height: '500px' }} data={this.props.positions[0]} />;
  }

  render() {
    return (
      <div style={{ maxWidth: '95vw' }}>
        <div className="row">
          {/* set required props for hourchart */}
          <LineChart
            chartData={this.props.time.chartData}
            options={this.props.time.options}
            size={this.props.time.size}
            classes="graphView-lineChart"
          />
          <div spacer style={{ width: 60 }} />
          {/* set required props for weekChart */}
          <LineChart
            chartData={this.props.time.weekChart}
            options={this.props.time.weekOptions}
            size={this.props.time.size}
            classes="graphView-lineChart"
          />
        </div>
        <div style={{ display: 'flex', marginTop: '50px' }}>{this.renderBarcharts()}</div>
      </div>
    );
  }
}

//map application state to component
function mapStateToProps(state) {
  return {
    time: state.BIDashboardState.time,
    positions: state.BIDashboardState.idvBar,
  };
}

export default connect(mapStateToProps)(GraphView);

import React from 'react';
import AdminPositionContainer from './containers/admin/PositionContainer';
import AuthenticationContainer from './containers/AuthenticationContainer';
import BIDashboard from './bi-dashboard/components/App';
import CompetitionContainer from './containers/admin/CompetitionContainer';
import CreateCompetitionContainer from './containers/admin/CreateCompetitionContainer';
import CompetitionDetailContainer from './containers/admin/CompetitionDetailContainer';
import CommunicationPage from './pages/admin/CommunicationPage';
import IrecDashboardContainer from './containers/admin/IrecDashboardContainer';
import TeamContainer from './containers/admin/TeamContainer';
import TeamEditContainer from './containers/admin/TeamEditContainer';
import CustomerSelectContainer from './containers/admin/CustomerSelectContainer';
import ConfirmationContainer from './containers/ConfirmationContainer';
import ExternalReferralContainer from './containers/ExternalReferralContainer';
import HighscoreContainer from './containers/HighscoreContainer';
import HomeContainer from './containers/HomeContainer';
import InterestedContainer from './containers/InterestedContainer';
import InterestedSupplementContainer from './containers/InterestedSupplementContainer';
import NotFoundContainer from './containers/NotFoundContainer';
import NotInterestedContainer from './containers/NotInterestedContainer';
import PositionEditContainer from './containers/admin/PositionEditContainer';
import ProfileEditContainer from './containers/ProfileEditContainer';
import RecommendedContainer from './containers/RecommendedContainer';
import RouterHandlerContainer from './containers/RouterHandlerContainer';
import RulesContainer from './containers/RulesContainer';
import TermsAndConditionsContainer from './containers/TermsAndConditionsContainer';
import TidLoginPage from './pages/admin/TidLoginPage';
import TidCallbackPage from './pages/admin/TidCallbackPage';
import TidRenewCallbackPage from './pages/admin/TidRenewCallbackPage';
import TidLogoutCallbackPage from './pages/admin/TidLogoutCallbackPage';
import AppSwitcherWrapper from './containers/AppSwitcherWrapper';
import AccessDeniedPage from './pages/AccessDeniedPage';
import RegisterPrivacyPolicyPage from './pages/RegisterPrivacyPolicyPage';
import ProfileSettingsPage from './pages/ProfileSettingsPage';
import CommunicationEditPage from './pages/admin/CommunicationEditPage';
import InvitationPage from './pages/admin/InvitationPage';
import InvitationImportPage from './pages/admin/InvitationImportPage';
import SmartBanner from './components/SmartBanner/SmartBanner';

import { Color } from './config/styles';
import { Switch, Route } from 'react-router';

import SettingsContainer from './containers/admin/SettingsContainer';
import JobsPage from './pages/admin/JobsPage';
import ProfilePage from './pages/ProfilePage';
import RecommendPage from './pages/RecommendPage';
import RecommendationsPage from './pages/RecommendationsPage';
import PositionPage from './pages/PositionPage';
import FaqPage from './pages/admin/FaqPage';

const containerStyle = {
  minHeight: '100vh',
  minWidth: '100vw',
  overflowX: 'none',
  overflowY: 'scroll',
  background: Color.backgroundBlue,
};

const App = () => (
  <div style={containerStyle}>
    <AppSwitcherWrapper>
      <SmartBanner />
      <RouterHandlerContainer>
        <Switch>
          <Route exact path="/" component={HomeContainer} />

          <Route exact path="/admin" component={JobsPage} />
          <Route exact path="/admin/jobs/position/:id" component={AdminPositionContainer} />
          <Route exact path="/admin/jobs/create" component={PositionEditContainer} />
          <Route exact path="/admin/jobs/edit/:id" component={PositionEditContainer} />
          <Route
            exact
            path="/admin/jobs/publish-external/:externalid"
            component={PositionEditContainer}
          />
          <Route exact path="/admin/invites" component={InvitationPage} />
          <Route exact path="/admin/invites/import" component={InvitationImportPage} />
          <Route exact path="/admin/teams" component={TeamContainer} />
          <Route exact path="/admin/teams/create" component={TeamEditContainer} />
          <Route exact path="/admin/teams/edit/:id" component={TeamEditContainer} />
          <Route exact path="/admin/bi-dashboard" component={BIDashboard} />

          <Route exact path="/admin/competitions" component={CompetitionContainer} />
          <Route exact path="/admin/competitions/create" component={CreateCompetitionContainer} />
          <Route exact path="/admin/competitions/edit/:id" component={CreateCompetitionContainer} />
          <Route exact path="/admin/competitions/:id" component={CompetitionDetailContainer} />
          <Route exact path="/admin/communication" component={CommunicationPage} />
          <Route exact path="/admin/communication/edit/:id" component={CommunicationEditPage} />

          <Route exact path="/admin/irecdashboard" component={IrecDashboardContainer} />

          <Route exact path="/admin/faq" component={FaqPage} />

          <Route exact path="/admin/customerselect" component={CustomerSelectContainer} />

          <Route exact path="/callback/recommended" component={AuthenticationContainer} />

          <Route exact path="/register-privacy-policy" component={RegisterPrivacyPolicyPage} />

          <Route exact path="/highscore" component={HighscoreContainer} />
          <Route exact path="/highscore/team" component={HighscoreContainer} />

          <Route exact path="/login" component={AuthenticationContainer} />

          <Route exact path="/recommendations" component={RecommendationsPage} />

          <Route exact path="/recommended" component={RecommendedContainer} />
          <Route exact path="/recommended/:id" component={RecommendedContainer} />
          <Route exact path="/recommended/:id/readall" component={RecommendedContainer} />
          <Route exact path="/recommended/:id/interested/" component={InterestedContainer} />

          <Route exact path={`/${process.env.REACT_APP_TID_LOGIN_PATH}`} component={TidLoginPage} />
          <Route exact path="/auth/signin-oidc" component={TidCallbackPage} />
          <Route exact path="/auth/renew-tid-token" component={TidRenewCallbackPage} />
          <Route exact path="/auth/logout-oidc" component={TidLogoutCallbackPage} />

          <Route exact path="/admin/settings" component={SettingsContainer} />

          <Route
            exact
            path="/recommended/:id/interested/terms"
            component={TermsAndConditionsContainer}
          />
          <Route
            exact
            path="/recommended/:id/interested/read-more"
            component={InterestedContainer}
          />
          <Route
            exact
            path="/recommended/:id/interested/supplement"
            component={InterestedSupplementContainer}
          />
          <Route
            exact
            path="/recommended/:id/interested/confirmation"
            component={ConfirmationContainer}
          />
          <Route exact path="/recommended/:id/not-interested" component={NotInterestedContainer} />
          <Route
            exact
            path="/recommended/:id/not-interested/confirmation"
            component={ConfirmationContainer}
          />
          <Route
            exact
            path="/recommended/:id/externalreferral"
            component={ExternalReferralContainer}
          />

          <Route exact path="/positions/:id" component={PositionPage} />
          <Route exact path="/positions/:id/recommend" component={RecommendPage} />

          <Route exact path="/profile" component={ProfilePage} />
          <Route exact path="/profile/edit" component={ProfileEditContainer} />
          <Route exact path="/profile/rules" component={RulesContainer} />
          <Route exact path="/profile-settings" component={ProfileSettingsPage} />

          <Route exact path="/access-denied" component={AccessDeniedPage} />

          <Route exact path="/admin/settings" component={SettingsContainer} />
          <Route component={NotFoundContainer} />
        </Switch>
      </RouterHandlerContainer>
    </AppSwitcherWrapper>
  </div>
);

export default App;

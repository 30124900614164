import React from 'react';
import { css, StyleSheet } from 'aphrodite';

import CompetitionLeaderboardItem from './CompetitionLeaderboardItem';

export default function CompetitionLeaderboard(props) {
  const competitionItems = props.users
    .slice(0, 3)
    .map((user, id) => (
      <CompetitionLeaderboardItem
        key={id}
        name={user.name}
        points={user.points}
        picture={user.picture}
      />
    ));

  return (
    <div className={css(styles.container)}>
      <h3 className={css(styles.h3)}>Leaderboard</h3>
      {competitionItems}
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  h3: {
    fontSize: '1.6975em',
    fontWeight: '600',
    lineHeight: '30px',
    color: '#4A4A4A',
    paddingLeft: '25px',
  },
});

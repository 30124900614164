import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import userflow from 'userflow.js';

import { setupUserflow, updateUserflowGroup } from '../../helpers/userflow';
import { UTCToDate } from '../../helpers/date';
import { getUserflowSignatures } from '../../irecommend-lib/actions/userflowActions';
import { getUserTenants } from '../../irecommend-lib/actions/userActions';
import { getCustomer } from '../../irecommend-lib/actions/communicationActions';

const useSetupUserflow = (fromJobsPage = false) => {
  const { customer } = useSelector((state) => state.communicationState);
  const { user } = useSelector((state) => state.userState);
  const { userTenants } = useSelector((state) => state.userState);
  const { userflowSignatures } = useSelector((state) => state.userflowState);
  const isLoading =
    customer.isLoading || user.isLoading || userTenants.isLoading || userflowSignatures.isLoading;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCustomer());
    dispatch(getUserTenants());
    dispatch(getUserflowSignatures());
  }, [dispatch, fromJobsPage]);

  useEffect(() => {
    userflow.init(process.env.REACT_APP_USERFLOW_TOKEN);
    //Userflow setup
    setupUserflow(
      customer?.response.customer,
      user?.response,
      userTenants?.response,
      userflowSignatures?.response,
    );
  }, [customer?.response, userflowSignatures?.response, user?.response, userTenants?.response]);

  useEffect(() => {
    if (
      userflow.isIdentified() &&
      Object.keys(customer?.response.customer).length !== 0 &&
      fromJobsPage
    ) {
      updateUserflowGroup(
        { launch_date: UTCToDate(customer?.response.customer.launch_date) },
        userflow,
      );
    }
  }, [customer?.response, fromJobsPage]);

  return { userflowIsLoading: isLoading };
};

export default useSetupUserflow;

import React, { Component } from 'react';
import { css, StyleSheet } from 'aphrodite';
import PropTypes from 'prop-types';

import CloseImage from '../assets/images/close@2x.png';
import InfoImage from '../assets/icons/icon-info.png';

/**
 * A basic button component with a specified number of icons.
 *
 * Example usage:
 *
 * ```
 * <ButtonIcon
 *   icon="close"
 *   onPress={onPressLearnMore}
 * />
 * ```
 *
 */
export default class ButtonIcon extends Component {
  static propTypes = {
    /**
     * Disables the button
     */
    disabled: PropTypes.bool,
    /**
     * Handler to be called when the user taps the button
     */
    onPress: PropTypes.func.isRequired,
    /**
     * Control which icon to show. Valid values: close, info, infoLight
     */
    icon: PropTypes.string.isRequired,
    /**
     * Custom styles to be applied to the button.
     */
    style: PropTypes.object,
  };

  static defaultProps = {
    disabled: false,
  };

  render() {
    const { disabled, icon, onPress, style, ariaLabel } = this.props;

    // Cannot require dynamically
    let source = null;

    if (icon === 'close') {
      source = CloseImage;
    } else if (icon === 'infoLight') {
      source = InfoImage;
    } else if (icon === 'infoIrec') {
      source = InfoImage;
    } else {
      source = InfoImage;
    }

    return (
      <a
        href="#buttonicon"
        disabled={disabled}
        onClick={onPress}
        className={css(style)}
        aria-label={ariaLabel}
      >
        <img src={source} alt="Icon" className={css(styles.icon)} />
      </a>
    );
  }
}

const styles = StyleSheet.create({
  icon: {
    width: 18,
    height: 18,
  },
});

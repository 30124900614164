import React, { Component } from 'react';
import { connect } from 'react-redux';

import TeamEditPage from '../../pages/admin/TeamEditPage';

import { uploadPicture } from '../../irecommend-lib/actions/mediaActions';
import { postTeam, getTeam, getTeamMembers } from '../../irecommend-lib/actions/teamActions';
import { getUser } from '../../irecommend-lib/actions/userActions';
import { withTranslation } from 'react-i18next';

class TeamEditContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitForm: false,
    };
  }

  componentDidMount() {
    this.props.getUser();
    if (this.props.match.params.id) {
      this.props.getTeam(this.props.match.params.id);
      this.props.getTeamMembers(this.props.match.params.id);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // Check for errors on form submission
    if (
      this.state.isSubmitForm === true &&
      this.props.team !== nextProps.team &&
      nextProps.team.isLoading === false
    ) {
      this.setState({
        isSubmitForm: false,
      });

      if (nextProps.team.error) {
        const { t } = this.props;
        alert(t('generic-error-default-message'));

        return;
      }

      // Successful create/update
      this.props.history.push('/admin/teams');
    }
  }

  render() {
    const team = this.props.team.response;
    const isLoading = this.props.team.isLoading || this.props.picture.isLoading;
    const isEdit = this.props.match.params.id !== undefined;
    const teamMembers = this.props.teamMembers.response ? this.props.teamMembers.response : [];

    return (
      <div>
        <TeamEditPage
          onPressSubmitForm={this.onPressSubmit}
          team={team}
          teamMembers={teamMembers}
          isEdit={isEdit}
          isLoading={isLoading}
        />
      </div>
    );
  }

  onPressSubmit = (data) => {
    const { t, uploadPicture } = this.props;

    // Form validation
    if (data.Name === '') {
      alert(t('teameditcontainer-validation-required'));
      return false;
    }

    if (data.PictureRaw) {
      // Upload image to Cloudinary
      uploadPicture(data.Picture).then(() => {
        data.Picture = this.props.picture.response.data.secure_url;
        this.postData(data);
      });
    } else {
      // Create/edit team without uploading a picture
      this.postData(data);
    }

    return true;
  };

  postData = (data) => {
    // Used to know when to look for request errors in componentWillReceiveProps
    this.setState(
      {
        isSubmitForm: true,
      },
      () => {
        // Create or update?
        const id = this.props.match.params.id !== undefined ? this.props.match.params.id : null;

        this.props.postTeam(data, id);
      },
    );
  };
}

function mapStateToProps(state) {
  return {
    picture: state.mediaState.uploadPicture,
    team: state.teamState.team,
    teamMembers: state.teamState.teamMembers,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getTeam: (id) => dispatch(getTeam(id)),
    getTeamMembers: (id) => dispatch(getTeamMembers(id)),
    getUser: () => dispatch(getUser()),
    postTeam: (data, id) => dispatch(postTeam(data, id)),
    uploadPicture: (file) => dispatch(uploadPicture(file)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TeamEditContainer));

import React from 'react';
import { useHistory } from 'react-router-dom';
import { Badge, Tooltip } from '@talentech/components';
import { ActionsMenu } from '@talentech/components';
import { css, StyleSheet } from 'aphrodite';

const Card = (props) => {
  const {
    title,
    subtitle,
    badgeColor,
    badgeLabel,
    badgeSize,
    actions,
    recTitle,
    hiredTitle,
    recNumber,
    hiredNumber,
    style,
    showStats,
    navigateTo,
  } = props;

  const history = useHistory();
  const badge = <Badge color={badgeColor} label={badgeLabel} size={badgeSize} />;
  const actionsMenu = <ActionsMenu actions={actions} />;
  const isSubtitleTooLong = subtitle && subtitle.length > 33;
  const handleCardClick = (e) => {
    if (e.target.closest('[data-action-menu]')) {
      return;
    }
    history.push(navigateTo);
  };

  return (
    <div
      className={css(styles.card, style)}
      onClick={handleCardClick}
      style={{ cursor: 'pointer' }}
    >
      <div className={css(styles.cardHeader)}>
        <div>
          <div className={css(styles.cardTitle)}>{title}</div>
          {subtitle &&
            (isSubtitleTooLong ? (
              <Tooltip content={subtitle}>
                <div className={css(styles.cardSubtitle)}>{subtitle}</div>
              </Tooltip>
            ) : (
              <div className={css(styles.cardSubtitle)}>{subtitle}</div>
            ))}
        </div>
      </div>

      <div className={css(styles.actionMenu)} data-action-menu>
        {actionsMenu}
      </div>
      <div className={css(styles.wrapper)}>
        <div className={css(styles.badge)}>{badge}</div>
        {showStats && (
          <div className={css(styles.statsWrapper)}>
            <div className={css(styles.statsContainer)}>
              <div className={css(styles.statBlock)}>
                <span className={css(styles.statLabel)}>{recTitle}</span>
                <span className={css(styles.statNumber)}>{recNumber}</span>
              </div>
              <div className={css(styles.hiredStatBlock)}>
                <span className={css(styles.statLabel)}>{hiredTitle}</span>
                <span className={css(styles.statNumber)}>{hiredNumber}</span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Card;

const styles = StyleSheet.create({
  card: {
    backgroundColor: 'white',
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.1)',
    borderRadius: '4px',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    maxWidth: '100%',
    position: 'relative',

    '@media (min-width: 580px)': {
      maxWidth: 'calc(50% - 20px)',
    },

    '@media (min-width: 960px)': {
      maxWidth: 'calc(33.5% - 20px)',
    },

    '@media (min-width: 1680px)': {
      maxWidth: 'calc(25% - 20px)',
    },
  },
  cardHeader: {
    maxWidth: '200px',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  cardTitle: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#333',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
    lineHeight: '18px',
    flexDirection: 'column',
  },
  cardSubtitle: {
    fontSize: '13px',
    color: '#666',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  wrapper: {
    marginTop: '1rem',
  },
  actionMenu: {
    position: 'absolute',
    top: '20px',
    right: '18px',
    transform: 'translateY(-8px)',
  },
  statsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '1rem',
    justifyContent: 'flex-end',
  },
  statsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  statBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  hiredStatBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
  },
  statNumber: {
    fontSize: '20px',
    fontWeight: 'bold',
    marginTop: '5px',
  },
  statLabel: {
    fontSize: '16px',
  },
});

import {
  POST_TOKEN_REQUEST,
  POST_TOKEN_SUCCESS,
  POST_TOKEN_FAILURE,
  GET_TOKEN_REQUEST,
  GET_TOKEN_SUCCESS,
  GET_TOKEN_FAILURE,
  VERIFY_TOKEN_REQUEST,
  VERIFY_TOKEN_SUCCESS,
  VERIFY_TOKEN_FAILURE,
  GET_INTEGRATIONTYPE_REQUEST,
  GET_INTEGRATIONTYPE_SUCCESS,
  GET_INTEGRATIONTYPE_FAILURE,
} from '../actions/integrationActions';

const initialState = {
  token: {
    didInvalidate: false,
    error: false,
    isLoading: false,
    response: {},
  },
  verifiedToken: {
    didInvalidate: false,
    error: false,
    isLoading: false,
    response: {},
  },
  tokenPost: {
    didInvalidate: false,
    error: false,
    isLoading: false,
    response: {},
    tokenSubmit: false,
  },
  integrationType: {
    didInvalidate: false,
    error: false,
    isLoading: false,
    response: {},
  },
};

export default function integrationState(
  state = initialState,
  { authenticated, error, response, type },
) {
  switch (type) {
    case POST_TOKEN_REQUEST:
      return {
        ...state,
        tokenPost: {
          ...state.tokenPost,
          isLoading: true,
        },
      };
    case POST_TOKEN_SUCCESS:
      return {
        ...state,
        verifiedToken: {
          ...state.verifiedToken,
          error: false,
          response: { tokenverified: true },
        },
        tokenPost: {
          ...state.tokenPost,
          error: false,
          isLoading: false,
          response: response,
          tokenSubmit: true,
        },
      };
    case POST_TOKEN_FAILURE:
      return {
        ...state,
        verifiedToken: {
          ...state.verifiedToken,
          error: false,
          response: {},
        },
        tokenPost: {
          ...state.tokenPost,
          isLoading: false,
          error: true,
          response: error,
        },
      };
    case VERIFY_TOKEN_REQUEST:
      return {
        ...state,
        verifiedToken: {
          ...state.verifiedToken,
          isLoading: true,
        },
      };
    case VERIFY_TOKEN_SUCCESS:
      return {
        ...state,
        verifiedToken: {
          ...state.verifiedToken,
          isLoading: false,
          response: response,
        },
      };
    case VERIFY_TOKEN_FAILURE:
      return {
        ...state,
        verifiedToken: {
          ...state.verifiedToken,
          isLoading: false,
          error: true,
          response: error,
        },
      };
    case GET_TOKEN_REQUEST:
      return {
        ...state,
        token: {
          ...state.token,
          isLoading: true,
        },
      };
    case GET_TOKEN_SUCCESS:
      return {
        ...state,
        token: {
          ...state.token,
          isLoading: false,
          response: response,
        },
      };
    case GET_TOKEN_FAILURE:
      return {
        ...state,
        token: {
          ...state.token,
          isLoading: false,
          error: true,
          response: error,
        },
      };
    case GET_INTEGRATIONTYPE_REQUEST:
      return {
        ...state,
        integrationType: {
          ...state.integrationType,
          isLoading: true,
        },
      };
    case GET_INTEGRATIONTYPE_SUCCESS:
      return {
        ...state,
        integrationType: {
          ...state.integrationType,
          isLoading: false,
          response: response,
        },
      };
    case GET_INTEGRATIONTYPE_FAILURE:
      return {
        ...state,
        integrationType: {
          ...state.integrationType,
          isLoading: false,
          error: true,
          response: error,
        },
      };
    default:
      return state;
  }
}

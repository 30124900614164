import React, { Component } from 'react';
import { css, StyleSheet } from 'aphrodite';
import 'react-datepicker/dist/react-datepicker.css';

import DatePicker from 'react-datepicker';
import moment from 'moment';
import { Button, Icon } from '@talentech/components';

import ButtonNative from '../../../components/Button';
import Keyword from '../../../components/admin/Keyword';
import DraftForm from '../../../components/admin/DraftForm';
import { TALENTECH_ICONS } from '../../../components/Icons';

class PositionEditRoleFields extends Component {
  render() {
    const {
      handleDateChange,
      t,
      inheritedStyles,
      isEdit,
      isLoading,
      Title,
      JobLocation,
      Reward,
      RewardType,
      Skills,
      Keywords,
      onInputChange,
      onInputChangeFromDraft,
      onFieldAddition,
      onListChange,
      onPressShowModal,
      onListRemove,
      onPressSubmit,
      onPressLinkedinSuggest,
      editorState,
    } = this.props;

    let endDateSelected = this.props.Enddate ? moment(this.props.Enddate).toDate() : null;
    let endDateValue = '';

    if (this.props.Enddate) {
      endDateValue = moment(this.props.Enddate).toDate();
    }

    return (
      <fieldset className={css(inheritedStyles.formFieldset)}>
        <label className={css(inheritedStyles.formControl)}>
          <span className={`form-label ${css(inheritedStyles.formLabel)}`}>
            {t('generic-text-title')} *
          </span>
          <input
            type="text"
            value={Title}
            className={css(inheritedStyles.formInput)}
            onChange={(e) => onInputChange(e, 'Title')}
          />
        </label>
        <div className={css(styles.formSplitFields)}>
          <label className={css(inheritedStyles.formControl)}>
            <span className="form-label">{t('generic-text-location')} *</span>
            <input
              type="text"
              maxLength="200"
              value={JobLocation}
              className={css(inheritedStyles.formInput)}
              onChange={(e) => onInputChange(e, 'JobLocation')}
            />
          </label>
          <label className={css(inheritedStyles.formControl)}>
            <span className="form-label">{t('generic-text-expiry-date')}</span>
            <DatePicker
              showYearDropdown
              scrollableYearDropdown
              dateFormat="yyyy-MM-dd"
              selected={endDateSelected}
              value={endDateValue}
              onChange={handleDateChange}
              className={css(inheritedStyles.formInput)}
            />
          </label>
        </div>
        <div className={css(styles.formSplitFields)}>
          <label className={css(inheritedStyles.formControl)}>
            <span className="form-label">{t('generic-text-reward')}</span>
            <input
              type="text"
              maxLength="45"
              value={Reward}
              className={css(inheritedStyles.formInput)}
              onChange={(e) => onInputChange(e, 'Reward')}
              placeholder={t('generic-text-reward-explanation')}
            />
          </label>
          <label className={css(inheritedStyles.formControl)}>
            <span className="form-label">{t('positioneditpage-rewardtype-label')}</span>
            <input
              type="text"
              maxLength="45"
              value={RewardType}
              className={css(inheritedStyles.formInput)}
              onChange={(e) => onInputChange(e, 'RewardType')}
              placeholder={t('positioneditpage-rewardtype-placeholder')}
            />
          </label>
        </div>
        <div className={css(styles.formSplitFields)}>
          <span className={css(inheritedStyles.formControl)}>
            <span className="form-label competence-label">
              {t('admin-add-job-looking-for')}*
              <a
                href="#info-job-looking-for"
                className={css(styles.infoIcon)}
                onClick={(e) => {
                  e.preventDefault();
                  onPressShowModal(t('generic-text-modal-qualifications'));
                }}
              >
                i
              </a>
            </span>

            {Skills.map((i, index) => {
              return (
                <Keyword
                  key={index}
                  value={Skills[index]}
                  type="text"
                  className={css(inheritedStyles.formInput)}
                  changed={(e) => onListChange(e, 'Skills', index)}
                  clicked={(e) => onListRemove(e, index, 'Skills')}
                />
              );
            })}
            <a href="#add-another-skill-field" onClick={(e) => onFieldAddition(e, 'Skills')}>
              + {t('generic-text-add-another')}
            </a>
          </span>
          <span className={css(inheritedStyles.formControl)}>
            <span className="form-label form-label-ai">
              <span>
                {t('admin-add-job-linkedin-keywords')}*
                <a
                  href="#info-linkedin-keywords"
                  className={css(styles.infoIcon)}
                  onClick={(e) => {
                    e.preventDefault();
                    onPressShowModal(
                      null,
                      <LinkedinModal
                        textFirstPart={t('generic-text-modal-linkedin-keywords-first-part')}
                        textSecondPart={t('generic-text-modal-linkedin-keywords-second-part')}
                        href={t('generic-text-modal-linkedin-keywords-link')}
                      />,
                    );
                  }}
                >
                  i
                </a>
              </span>
              <Button
                color="primary"
                label={t('profileeditpage-linkedin-suggestion-button-name')}
                icon={<Icon icon={TALENTECH_ICONS.stars} iconSize="small" />}
                size="sm"
                type="button"
                className={css(styles.suggestButton)}
                onClick={onPressLinkedinSuggest}
                aria-label="Get ai suggestions for linkedin"
              />
            </span>

            {Keywords.map((i, index) => {
              return (
                <Keyword
                  key={index}
                  value={Keywords[index]}
                  type="text"
                  className={css(inheritedStyles.formInput)}
                  changed={(e) => onListChange(e, 'Keywords', index)}
                  clicked={(e) => onListRemove(e, index, 'Keywords')}
                />
              );
            })}
          </span>
        </div>

        {editorState && (
          <div>
            <DraftForm
              editorState={editorState}
              formLabel={t('admin-add-job-full-ad')}
              change={onInputChangeFromDraft}
            />
          </div>
        )}

        <div className={css(styles.formButton)}>
          <ButtonNative
            isLoading={isLoading}
            disabled={isLoading}
            title={t(isEdit ? 'generic-text-save-push' : 'generic-text-publish')}
            onPress={onPressSubmit}
          />
        </div>
      </fieldset>
    );
  }
}
class LinkedinModal extends Component {
  render() {
    return (
      <>
        {this.props.textFirstPart}
        <a className={css(styles.textLink)} href={this.props.href} target="_blank" rel="noreferrer">
          {this.props.href}
        </a>
        {this.props.textSecondPart}
      </>
    );
  }
}
export default PositionEditRoleFields;
const styles = StyleSheet.create({
  infoIcon: {
    display: 'inline-flex',
    width: '1.7em',
    height: '1.7em',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#eee',
    textTransform: 'none',
    textDecoration: 'none',
    marginLeft: '.5em',
    border: '1px solid #ccc',
    fontWeight: 'bold',
    transition: 'all .2s ease-in-out',

    ':hover': {
      backgroundColor: '#ddd',
    },
  },
  formButton: {
    marginTop: '10px',
  },
  formTextarea: {
    minHeight: '299px',
  },
  formSplitFields: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: '1em',
    marginBottom: '1.15em',
  },
  textLink: {
    color: 'blue',
    margin: '1em 0',
  },
  suggestButton: {
    marginLeft: 'auto',
    // position: 'absolute',
    top: '0',
    right: '0',
    zIndex: '1',
    transform: 'translateY(-5px)',
  },
});

import LoaderIcon from '../components/icons/Loader';
import MainWrapper from '../components/MainWrapper';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Color, Variables } from '../config/styles';
import { connect } from 'react-redux';
import { css, StyleSheet } from 'aphrodite';
import { withTranslation } from 'react-i18next';

class ConfirmationNotInterestedPage extends Component {
  static propTypes = {
    /*
     * State of the recommendation
     */
    recommendation: PropTypes.object,
  };

  render() {
    const { t, recommendation } = this.props;

    if (recommendation.isLoading || !recommendation.response) {
      return (
        <MainWrapper>
          <LoaderIcon />
        </MainWrapper>
      );
    }

    return (
      <div className={css(styles.container)}>
        <h1 className={css(styles.h1)}>{t('confirmationnotinterestedpage-title')}</h1>
        <p>
          {t('confirmationnotinterestedpage-lead')}{' '}
          <a href={recommendation.response.companypage}>{recommendation.response.companypage}</a>
        </p>
        <p>/{recommendation.response.companyname}</p>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    recommendation: state.recommendationState.recommendation,
  };
}

export default connect(mapStateToProps)(withTranslation()(ConfirmationNotInterestedPage));

const styles = StyleSheet.create({
  container: {
    minHeight: Variables.MainWrapperHeight,
    padding: Variables.BubbleSectionPadding,
    background: 'white',
  },
  h1: {
    fontSize: '1.5em',
    fontWeight: '600',
    lineHeight: '30px',
    color: Color.dark,
  },
});

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Color } from '../../config/styles';
import { css, StyleSheet } from 'aphrodite';
import ProfilePicture from '../../components/ProfilePicture';
import { withTranslation } from 'react-i18next';

class TeamMemberItem extends Component {
  static propTypes = {
    firstname: PropTypes.string.isRequired,
    lastname: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    lastActive: PropTypes.string.isRequired,
  };

  render() {
    const { firstname, lastname, email, lastActive, t } = this.props;

    return (
      <div className={css(styles.listItem)}>
        <div className={css(styles.listSection)}>
          <ProfilePicture
            name={firstname + ' ' + lastname}
            picture=""
            style={styles.profileImage}
            size="medium"
          />
          <div>
            <h4 className={css(styles.title)}>
              {firstname} {lastname}
              <span className={css(styles.email)}>{email}</span>
            </h4>
            <span className={css(styles.date)}>
              <strong>{t('generic-text-last-active')}:</strong> {lastActive}
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default TeamMemberItem;

const styles = StyleSheet.create({
  listItem: {
    borderRadius: '2px',
    boxShadow: '0 5px 17px 0 rgba(0, 0, 0, .03)',
    backgroundColor: Color.white,
    margin: '0 0 1.5625rem',
    display: 'grid',
    gridTemplateColumns: '1fr',
    padding: '0.5em',
  },
  profileImage: {
    borderRadius: '50%',
    marginRight: '.75em',
  },
  listSection: {
    display: 'flex',
    alignItems: 'center',
    padding: '1em',
  },
  title: {
    margin: '0 0 .2em',
    fontSize: '1.05em',
    fontWeight: 'bold',
  },
  email: {
    color: '#aaa',
    fontSize: '1.05em',
    marginLeft: '.25em',
    fontWeight: 'normal',

    ':before': {
      content: '"–"',
      display: 'inline-block',
      marginRight: '.25em',
    },
  },
});

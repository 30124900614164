import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { postBulkInvite } from '../../irecommend-lib/actions/inviteActions';
import useShowToast from '../helper/useShowToast';

const useCreateBulkInvite = (setState, setInvites, t) => {
  const { request, error, isLoading } = useSelector((state) => state.inviteState.bulkInvite);
  const dispatch = useDispatch();
  const { showToastMessage } = useShowToast(setState, t, 'POST_BULK_INVITE_RESET');

  useEffect(() => {
    handleResponse(request);
  }, [request]);

  // SEND BULK INVITES
  const createBulkInvite = (formData) => {
    setState((state) => ({ ...state, uploading: true }));
    dispatch(postBulkInvite(formData));
  };

  // HANDLE API RESPONSES
  const handleResponse = (response) => {
    setState((state) => ({ ...state, uploading: false }));
    if (response && response.status === 200) {
      handleSuccessResponse(response.data);
      showToastMessage('invitationpage-bulk-sent-message');
    }
    if (response && error && response.status === 400)
      showToastMessage('invitationpage-bulk-internal-error-dialogue', true);

    if (response && error && response.status === 500)
      showToastMessage('invitationpage-bulk-internal-error-dialogue', true);
  };

  // HANDLE SUCCESS RESPONSE SEPERATELY
  const handleSuccessResponse = (responseData) => {
    const duplicateInvites = [];
    const failedInvites = [];
    const successfulInvites = [];

    responseData.forEach((invite) => {
      invite.errortype === 'duplicate entry'
        ? duplicateInvites.push(invite.email)
        : invite.errortype === 'internal server error'
        ? failedInvites.push(invite.email)
        : successfulInvites.push(invite.email);
    });

    setInvites((invites) => ({
      ...invites,
      duplicateInvites,
      failedInvites,
      successfulInvites,
    }));

    setState((state) => ({ ...state, showLog: true }));
  };

  return { createBulkInvite, bulkInviteIsLoading: isLoading };
};
export default useCreateBulkInvite;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite';

import MinimalDownImage from '../assets/images/minimalDown@1x.png';

/**
 * A basic indicator that used to indicate wether or not something is collapsed or expanded.
 *
 * Example usage:
 *
 * ```
 * <ArrowIndicator
 *   direction="up"
 * />
 * ```
 *
 */
export default class ArrowIndicator extends Component {
  static propTypes = {
    /**
     * Which way the arrow should point. Up or down.
     */
    direction: PropTypes.string,
    /**
     * Custom styles to be applied to the component.
     */
    style: PropTypes.object,
  };

  static defaultProps = {
    direction: 'up',
  };

  // componentWillReceiveProps(nextProps) {
  //   if (this.props.direction !== nextProps.direction) {
  //     const rotate = nextProps.direction === 'up' ? 0 : 1;

  //     Animated.timing(
  //       this.state.animation,
  //       {
  //         toValue: rotate,
  //         duration: 200,
  //       }
  //     ).start();
  //   }
  // }

  render() {
    const { direction, style } = this.props;

    const rotate = direction === 'up' ? '0deg' : '180deg';

    return (
      <div className={css(style)}>
        <img
          src={MinimalDownImage}
          style={{ transition: '.2s ease', transform: `rotate(${rotate})` }}
          alt=""
        />
      </div>
    );
  }
}

// const styles = StyleSheet.create({
//   image: {
//     height: 16,
//     width: 16,
//   }
// });

import { CALL_API } from '../middleware/api';

/**
 * GET teams
 */
export const GET_TEAMS_REQUEST = 'GET_TEAMS_REQUEST';
export const GET_TEAMS_SUCCESS = 'GET_TEAMS_SUCCESS';
export const GET_TEAMS_FAILURE = 'GET_TEAMS_FAILURE';
export const GET_TEAMS_INVALIDATE = 'GET_TEAMS_INVALIDATE';

export function getTeams() {
  return {
    [CALL_API]: {
      endpoint: 'teams',
      authenticated: true,
      types: [GET_TEAMS_REQUEST, GET_TEAMS_SUCCESS, GET_TEAMS_FAILURE],
    },
  };
}

/**
 * GET team
 */
export const GET_TEAM_REQUEST = 'GET_TEAM_REQUEST';
export const GET_TEAM_SUCCESS = 'GET_TEAM_SUCCESS';
export const GET_TEAM_FAILURE = 'GET_TEAM_FAILURE';
export const GET_TEAM_INVALIDATE = 'GET_TEAM_INVALIDATE';

export function getTeam(id) {
  const endpoint = `teams/single/${id}`;
  return {
    [CALL_API]: {
      endpoint: endpoint,
      authenticated: true,
      types: [GET_TEAM_REQUEST, GET_TEAM_SUCCESS, GET_TEAM_FAILURE],
    },
  };
}

/**
 *  Invalidate teams content to make sure it get fetched again when the view appears
 */
export const getTeamsInvalidate = () => {
  return {
    type: GET_TEAMS_INVALIDATE,
    payload: {},
  };
};

/**
 *  Only dispatches getTeams if data is invalidated or empty
 */
const shouldGetTeams = (state) => {
  const teams = state.teamState;

  if (!teams.response || teams.response.length === 0) {
    return true;
  }

  return teams.didInvalidate;
};

export const getTeamsIfNeeded = () => (dispatch, getState) => {
  if (shouldGetTeams(getState())) {
    return dispatch(getTeams());
  }
};

export const POST_TEAM_REQUEST = 'POST_TEAM_REQUEST';
export const POST_TEAM_SUCCESS = 'POST_TEAM_SUCCESS';
export const POST_TEAM_FAILURE = 'POST_TEAM_FAILURE';

/**
 * Create or update team
 */
export function postTeam(data, id = null) {
  let endpoint = 'teams';

  // Update team
  if (id) {
    endpoint += `/single/${id}`;
  }

  return {
    [CALL_API]: {
      endpoint: endpoint,
      authenticated: true,
      types: [POST_TEAM_REQUEST, POST_TEAM_SUCCESS, POST_TEAM_FAILURE],
      method: 'POST',
      data: data,
    },
  };
}

export const DELETE_TEAM_REQUEST = 'DELETE_TEAM_REQUEST';
export const DELETE_TEAM_SUCCESS = 'DELETE_TEAM_SUCCESS';
export const DELETE_TEAM_FAILURE = 'DELETE_TEAM_FAILURE';

/**
 * Delete team
 */
export function deleteTeam(id) {
  const endpoint = `teams/single/${id}`;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      authenticated: true,
      types: [DELETE_TEAM_REQUEST, DELETE_TEAM_SUCCESS, DELETE_TEAM_FAILURE],
      method: 'DELETE',
    },
  };
}

/**
 * GET team members
 */
export const GET_TEAM_MEMBERS_REQUEST = 'GET_TEAM_MEMBERS_REQUEST';
export const GET_TEAM_MEMBERS_SUCCESS = 'GET_TEAM_MEMBERS_SUCCESS';
export const GET_TEAM_MEMBERS_FAILURE = 'GET_TEAM_MEMBERS_FAILURE';

export function getTeamMembers(id) {
  const endpoint = `teams/single/members/${id}`;
  return {
    [CALL_API]: {
      endpoint: endpoint,
      authenticated: true,
      types: [GET_TEAM_MEMBERS_REQUEST, GET_TEAM_MEMBERS_SUCCESS, GET_TEAM_MEMBERS_FAILURE],
    },
  };
}

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Color, Font } from '../../config/styles';
import { css, StyleSheet } from 'aphrodite';

/**
 * A basic button component with specific appearance for iRecommend.
 *
 * Example usage:
 *
 * ```
 * <Input
 *   onPress={onPressLearnMore}
 *   placeholder="First- and lastname"
 *   type="text"
 * />
 * ```
 *
 */
export default class Input extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.value || '',
    };
  }
  static propTypes = {
    /*
     * Content for labeling - children
     */
    placeholder: PropTypes.node,
    /**
     * Custom styles from parent.
     */
    style: PropTypes.object,
    /**
     * Controls the input appearance. Valid values: Standard HTML input (not all are handled)
     */
    type: PropTypes.string,
  };

  render() {
    const { changed, clicked, value } = this.props;
    return (
      <div>
        <input
          value={value}
          className={css(styles.formInput)}
          type="text"
          maxLength="200"
          onChange={changed}
        />
        <a href="#remove-skill-field" onClick={clicked}>
          <svg className={css(styles.removeKeyword)} viewBox="0 0 96 96">
            <path
              fill={this.props.color}
              fillRule="evenodd"
              d="M48,44.8180195 L85.5756764,7.24234308 L87.1666667,5.65135282 L90.3486472,8.83333333 L88.7576569,10.4243236 L51.1819805,48 L90.3089626,87.1269821 L91.8999529,88.7179724 L88.7179724,91.8999529 L87.1269821,90.3089626 L48,51.1819805 L8.87301788,90.3089626 L7.28202762,91.8999529 L4.10004711,88.7179724 L5.69103736,87.1269821 L44.8180195,48 L7.24234308,10.4243236 L5.65135282,8.83333333 L8.83333333,5.65135282 L10.4243236,7.24234308 L48,44.8180195 Z"
            />
          </svg>
        </a>
      </div>
    );
  }
}

const styles = StyleSheet.create({
  label: {},
  disabled: {
    opacity: 0.6,
  },
  input: {
    width: '100%',
    padding: '22px 17px 17px 4px',
    borderTop: 'none',
    borderRight: 'none',
    borderLeft: 'none',
    borderBottom: '1px solid rgba(0,0,0,0.3)',
    color: Color.dark,
    fontFamily: Font.defaultFont,
    borderRadius: 0,
  },
  formInput: {
    borderRadius: '5px',
    border: '1px solid #ccc',
    padding: '.75em .8em',
    margin: '.5em 0',
    fontSize: '1.1em',
    width: '90%',
    display: 'inline-block',
  },
  removeKeyword: {
    display: 'inline-block',
    width: '15px',
    height: '15px',
    marginLeft: '12px',
    fill: '#c8c8c8',
    ':hover': {
      fill: '#777777',
    },
  },
});

import ConfirmationInterestedPage from '../pages/ConfirmationInterestedPage';
import ConfirmationNotInterestedPage from '../pages/ConfirmationNotInterestedPage';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getRecommendation } from '../irecommend-lib/actions/recommendationActions';

class ConfirmationContainer extends Component {
  componentDidMount() {
    if (!this.props.recommendation.response.referral) {
      this.props.getRecommendation(this.props.match.params.id);
    }
  }

  render() {
    const interested = this.props.match.path.indexOf('not-interested') === -1;

    if (interested) {
      return <ConfirmationInterestedPage />;
    }

    return <ConfirmationNotInterestedPage />;
  }
}

function mapStateToProps(state) {
  return {
    recommendation: state.recommendationState.recommendation,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getRecommendation: (recommendation) => dispatch(getRecommendation(recommendation)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationContainer);

import axios from 'axios';

// get credential function

// https://api.irecommend.se/api/v1/bi/positions - Returnerar alla positioner
// https://api.irecommend.se/api/v1/bi/recommendations - Returnerar alla rekommendationer
// https://api.irecommend.se/api/v1/bi/transactions/sum/users - Returnerar den individuella high-score listan
// https://api.irecommend.se/api/v1/bi/transactions/sum/teams - Returnerar high-score för teams
// https://api.irecommend.se/api/v1/bi/transactions/sum/users - Returnerar det totala antalet rekommendationer (state representerar vilket läge rekommendationen är i,

const TRANSACTIONS = 'transactions/sum/';

const baseURL = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_ENVIRONMENT}${process.env.REACT_APP_API_BASE_URL}/`;

//set up variables for easier error handling
export const FETCH_NUMBERS = 'FETCH_NUMBERS';
export const FETCH_POSITIONS = 'FETCH_POSITIONS';
export const FETCH_TEAMSCORE = 'FETCH_TEAMSCORE';
export const FETCH_USERSCORE = 'FETCH_USERSCORE';
export const FETCH_WEEK = 'FETCH_WEEK';
export const FETCH_IDV_BAR = 'FETCH_IDV_BAR';
export const FETCH_TIPS = 'FETCH_TIPS';
export const FETCH_GOALS = 'FETCH_GOALS';
export const FETCH_TIME = 'FETCH_TIME';

const numbersFetched = (res) => ({
  type: FETCH_NUMBERS,
  payload: res,
});

export const fetchNumbers = (accessToken) => (dispatch) =>
  axios
    .get(`${baseURL}recommendations`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
    .then((res) => {
      dispatch(numbersFetched(res));
    })
    .catch(console.log);

const positionsFetched = (res) => ({
  type: FETCH_POSITIONS,
  payload: res,
});

export const fetchPositions = (accessToken) => (dispatch) =>
  axios
    .get(`${baseURL}positions`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
    .then((res) => dispatch(positionsFetched(res)));

const userScoreFetched = (res) => ({
  type: FETCH_USERSCORE,
  payload: res,
});

export const fetchUserScore = (accessToken) => (dispatch) =>
  axios
    .get(`${baseURL}users/stats`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
    .then((res) => dispatch(userScoreFetched(res)));

const teamScoreFetched = (res) => ({
  type: FETCH_TEAMSCORE,
  payload: res,
});

export const fetchTeamScore = (accessToken) => (dispatch) =>
  axios
    .get(`${baseURL}transactions/sum/teams`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
    .then((res) => dispatch(teamScoreFetched(res)));

const weekFetched = (res) => ({
  type: FETCH_WEEK,
  payload: res,
});

export const fetchWeek = (accessToken) => (dispatch) =>
  axios
    .get(`${baseURL}recommendations`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
    .then((res) => dispatch(weekFetched(res)));

const idvBarFetched = (res) => ({
  type: FETCH_IDV_BAR,
  payload: res,
});

export const fetchIdvBar = (accessToken) => (dispatch) =>
  axios
    .get(`${baseURL}positions`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
    .then((res) => dispatch(idvBarFetched(res)));

const goalsFetched = (res) => ({
  type: FETCH_GOALS,
  payload: res,
});

export const fetchGoals = (accessToken) => (dispatch) =>
  axios
    .get(`${baseURL}goals`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
    .then((res) => dispatch(goalsFetched(res)));

const timeFetched = (res) => ({
  type: FETCH_TIME,
  payload: res,
});

export const fetchTime = (accessToken) => (dispatch) =>
  axios
    .get(`${baseURL}recommendations`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
    .then((res) => dispatch(timeFetched(res)));

// TODO: 401 error when trying to fetch all users recommendations
const tipsFetched = (res) => ({
  type: FETCH_TIPS,
  payload: res,
});

export const fetchTips = (accessToken) => (dispatch) =>
  axios
    .get(`${baseURL}${TRANSACTIONS}users`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
    .then((response) => {
      response.data.forEach((user) => {
        return axios.get(`${baseURL}recommendations/user/${user.user.iduser}`, {
          headers: { Authorization: `Bearer ${accessToken}` },
        });
      });
    })
    .then((res) => dispatch(tipsFetched));

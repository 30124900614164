import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite';

import { Color, Font } from '../config/styles';

import RecommendationStatusCompleteImage from '../assets/images/recommendationStatusComplete.png';
import RecommendationStatusIncompleteImage from '../assets/images/recommendationStatusIncomplete.png';
import { withTranslation } from 'react-i18next';
import { IntlNetwork } from '../helpers/language';

/**
 * Status presentation for recommendations.
 *
 * Example usage:
 *
 * ```
 * <RecommendationStatus
 *   points=450
 *.  status=3
 * />
 * ```
 *
 */
class RecommendationStatus extends Component {
  static propTypes = {
    /**
     * Unfold or not: up/down
     */
    direction: PropTypes.string,
    /**
     * Points that the user has acquired for a specific recommendation
     */
    points: PropTypes.number,
    /**
     * Referral status
     */
    status: PropTypes.number,
    /**
     * Custom styles to be applied to the component.
     */
    style: PropTypes.object,
  };

  // componentWillReceiveProps(nextProps) {
  //   if (this.props.direction !== nextProps.direction) {
  //     const height = nextProps.direction === 'up' ? 0 : this.defaultHeight;

  //     Animated.timing(
  //       this.state.animation,
  //       {
  //         toValue: height,
  //         duration: 200,
  //       }
  //     ).start();
  //   }
  // }

  render() {
    const { direction, points, style } = this.props;

    const height = direction === 'up' ? 0 : 114;

    return (
      <div
        style={{ transition: '.2s ease', height: height, overflow: 'hidden' }}
        className={css(style)}
      >
        <div className={css(styles.wrap)}>
          <div className={css(styles.statusBubble)}>
            <span className={css(styles.statusBubbleText)}>+ {points}p</span>
          </div>

          {this.progress()}
        </div>
      </div>
    );
  }

  progress = () => {
    const status = this.props.status;
    const { t } = this.props;

    let progress = [
      {
        label: t('recommendationstatus-sent'),
        points: 5,
        accomplished: status >= 10,
      },
      {
        label: t('recommendationstatus-interested'),
        points: 50,
        accomplished: status >= 50,
      },
      {
        label: t('recommendationstatus-interviewed'),
        points: 400,
        accomplished: status >= 100,
      },
      {
        label: IntlNetwork(t, 'recommendationstatus-hired'),
        points: 1000,
        accomplished: status >= 130,
      },
    ];

    let elements = progress.map((item, key) => {
      const lastItemStyle = key === progress.length - 1 ? styles.progressLast : null;

      return (
        <div key={key} className={css(styles.progress, lastItemStyle)}>
          <span className={css(styles.progressPoints)}>{item.points} p</span>
          <span className={css(styles.progressLabel)}>{item.label}</span>

          {item.accomplished ? (
            <img
              src={RecommendationStatusCompleteImage}
              className={css(styles.progressImage)}
              alt=""
            />
          ) : (
            <img
              src={RecommendationStatusIncompleteImage}
              className={css(styles.progressImage)}
              alt=""
            />
          )}
        </div>
      );
    });

    return <div className={css(styles.progressWrap)}>{elements}</div>;
  };
}

export default withTranslation()(RecommendationStatus);

const styles = StyleSheet.create({
  progress: {
    alignItems: 'center',
    borderRight: `1px solid ${Color.border}`,
    display: 'flex',
    flexDirection: 'column',
    minWidth: 65,
    paddingLeft: 6,
    paddingRight: 6,
    paddingBottom: 17,
  },
  progressLast: {
    borderRightWidth: 0,
  },
  progressLabel: {
    color: Color.dark,
    display: 'block',
    backgroundColor: 'transparent',
    fontFamily: Font.defaultFont,
    fontWeight: 600,
    fontSize: 10,
    marginBottom: 19,
    textAlign: 'center',
  },
  progressPoints: {
    color: Color.success,
    display: 'block',
    backgroundColor: 'transparent',
    fontFamily: Font.defaultFont,
    fontWeight: 600,
    fontSize: 11,
    marginBottom: 3,
    textAlign: 'center',
  },
  progressWrap: {
    display: 'flex',
    flexDirection: 'row',
  },
  statusBubble: {
    backgroundColor: Color.white,
    borderRadius: 25,
    borderWidth: 0,
    padding: 16,
    paddingLeft: 14,
    paddingRight: 14,
  },
  statusBubbleText: {
    color: Color.success,
    fontFamily: Font.defaultFont,
    fontWeight: 600,
    fontSize: 11,
  },
  wrap: {
    backgroundColor: Color.backgroundDark,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 17,
    paddingLeft: 24,
    paddingRight: 21,
    // minHeight: 119,
  },
});

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite';

import { Color } from '../../config/styles';
import { withTranslation } from 'react-i18next';

class CandidateDropdown extends Component {
  static propTypes = {
    tel: PropTypes.string,
    email: PropTypes.string,
    linkedin: PropTypes.string,
  };

  render() {
    const {
      tel,
      email,
      linkedin,
      document,
      idreferral,
      showCandidateInfo,
      onPressToggleCandidateInfo,
      onPressGetDocument,
      t,
    } = this.props;

    return (
      <div className={css(styles.candidateDropdownWrap, showCandidateInfo && styles.show)}>
        <div
          onClick={onPressToggleCandidateInfo}
          className={css(styles.candidateDropdownBackground)}
        />
        <ul className={css(styles.candidateDropdown)}>
          {tel && (
            <li className={css(styles.candidateDropdownItemBig)}>
              <span className={css(styles.candidateDropdownTitle)}>
                {t('generic-input-Telephone')}:
              </span>
              <span className={css(styles.candidateDropdownValue)}>{tel}</span>
            </li>
          )}
          {email && (
            <li className={css(styles.candidateDropdownItemBig)}>
              <span className={css(styles.candidateDropdownTitle)}>{t('generic-input-Mail')}:</span>
              <span className={css(styles.candidateDropdownValue)}>{email}</span>
            </li>
          )}

          {document && (
            <li className={css(styles.candidateDropdownItem)}>
              <button
                className={css(styles.candidateDropdownButton)}
                onClick={(e) => {
                  e.preventDefault();
                  onPressGetDocument(idreferral, document);
                }}
              >
                {t('candidateitem-document-link')}
              </button>
            </li>
          )}

          {linkedin && (
            <li className={css(styles.candidateDropdownItem)}>
              <a target="_blank" rel="noopener noreferrer" href={linkedin}>
                {t('generic-text-linkedin-link')}
              </a>
            </li>
          )}
        </ul>
      </div>
    );
  }
}

export default withTranslation()(CandidateDropdown);

const styles = StyleSheet.create({
  candidateDropdownWrap: {
    display: 'none',
  },

  show: {
    display: 'block',
  },

  candidateDropdownBackground: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, .05)',
    cursor: 'default',
    zIndex: 10,
  },

  candidateDropdown: {
    borderRadius: '2px',
    boxShadow: '0 3px 17px 0 rgba(0, 0, 0, .05)',
    backgroundColor: Color.white,
    position: 'absolute',
    top: '1.75em',
    zIndex: '10',
    minWidth: '18em',
    maxWidth: '50em',
    padding: '.75em 0 1.25em',
    listStyle: 'none',
    margin: '0',
  },

  candidateDropdownItem: {
    margin: '0',
    padding: '.25em 2.5em .25em 1.5em',

    ':first-child': {
      marginTop: '2.5em',
    },
  },
  candidateDropdownItemBig: {
    margin: '0',
    padding: '.5em 2.5em .5em 1.5em',
  },
  candidateDropdownTitle: {
    color: '#999',
    textTransform: 'uppercase',
    fontSize: '.8em',
  },
  candidateDropdownValue: {
    margin: '.05em 0',
    display: 'block',
    fontSize: '1.3em',
  },
  candidateDropdownButton: {
    background: 'none',
    border: 'none',
    padding: '0',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
});

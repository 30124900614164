import { css, StyleSheet } from 'aphrodite';
import Filter from '../Filter';
import { Button } from '@talentech/components';
import { useTranslation } from 'react-i18next';
import Search from '../Search';
import { FILTER_TYPES, TAB_NAMES } from '../../helpers/position';

const SearchAndFilter = ({
  tokenExist,
  selectedTab,
  onPressFetchJobs,
  searchTerm,
  onSearchChange,
  selectedFilter,
  onFilterChange,
  isExternalPositionsLoading,
}) => {
  const { t } = useTranslation();

  const dropdownMenuList = [
    {
      title: `${t('generic-text-all')}`,
      onClick: () => {
        onFilterChange({
          name: `${t('generic-text-all')}`,
          value: FILTER_TYPES.all,
        });
      },
    },
    {
      title: `${t('positionpage-filter-published')}`,
      onClick: () => {
        onFilterChange({
          name: `${t('positionpage-filter-published')}`,
          value: FILTER_TYPES.published,
        });
      },
    },
    {
      title: `${t('positionpage-filter-unpublished')}`,
      onClick: () => {
        onFilterChange({
          name: `${t('positionpage-filter-unpublished')}`,
          value: FILTER_TYPES.unpublished,
        });
      },
    },
  ];

  const handleSearchChange = (e) => {
    onSearchChange(e.target.value);
  };

  return (
    <div className={css(styles.filterAndSearchWrapper)}>
      {selectedTab === TAB_NAMES.positions && (
        <Filter dropdownMenuList={dropdownMenuList} currentFilterName={selectedFilter.name} />
      )}
      <div className={css(styles.search)}>
        <Search onChange={handleSearchChange} value={searchTerm} />
      </div>
      {tokenExist && selectedTab === TAB_NAMES.external && (
        <Button
          color="primary"
          label={t('admin-get-external-jobs')}
          size="sm"
          className={css(styles.fetchButton)}
          onClick={onPressFetchJobs}
          disabled={isExternalPositionsLoading}
          aria-label="Get external jobs button"
        />
      )}

      {/* )} */}
      {/* {!(selectedTab === '2' && emptyFetchedContent) && (
        <>
          <div className={css(styles.search)}>
            <Search onChange={handleSearchChange} value={searchTerm} />
          </div>
          {tokenExist && selectedTab === '2' && (
            <Button
              color="primary"
              label={t('admin-get-external-jobs')}
              size="sm"
              className={css(styles.fetchButton)}
              onClick={onPressFetchJobs}
              aria-label="Get external jobs button"
            />
          )}
        </>
      )} */}
    </div>
  );
};

export default SearchAndFilter;

const styles = StyleSheet.create({
  filterAndSearchWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '20px',
    padding: '20px 20px 0px 20px',

    '@media (max-width: 580px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  search: {
    flexGrow: 1,
    '@media (max-width: 580px)': {
      width: '100%',
    },
  },
});

import React, { useEffect, useState } from 'react';
import { LayoutGrid } from '@talentech/components';
import AppBar from '../pages/AppBar';
import AppSidebar from '../pages/AppSidebar';
import { useHistory, useLocation } from 'react-router';

const AppSwitcherWrapper = (props) => {
  const [renderMenu, setRenderMenu] = useState();
  const location = useLocation();
  const history = useHistory();
  const credentials = localStorage.getItem('@irecommend:credentials');

  useEffect(() => {
    // Don't render menu/appswitcher when in admin or register flow
    if (
      location.pathname.match(new RegExp('^/login')) !== null ||
      location.pathname.match(new RegExp('^/register-profile')) !== null ||
      location.pathname.match(new RegExp(`^/${process.env.REACT_APP_TID_LOGIN_PATH}`)) !== null ||
      location.pathname.match(new RegExp('^/logout-oidc')) !== null ||
      location.pathname.match(new RegExp('^/access-denied')) !== null ||
      location.pathname.match(new RegExp('^/profile-settings')) !== null ||
      location.pathname.match(new RegExp('^/register-privacy-policy')) !== null ||
      location.pathname.match(new RegExp('^/recommended')) !== null ||
      location.pathname.match(new RegExp('^/auth/signin-oidc')) !== null
    ) {
      setRenderMenu(false);
    } else if (credentials) {
      setRenderMenu(true);
    }
  }, [credentials, location]);

  const onPressMenuItem = (e, item) => {
    e.preventDefault();
    history.push(item.link);
  };

  return (
    <>
      {renderMenu && credentials ? (
        <LayoutGrid header={<AppBar />} sidebar={<AppSidebar onPressMenuItem={onPressMenuItem} />}>
          {props.children}
        </LayoutGrid>
      ) : (
        <>{props.children}</>
      )}
    </>
  );
};

export default AppSwitcherWrapper;

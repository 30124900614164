import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { css, StyleSheet } from 'aphrodite';

import CirclePlusImage from '../assets/images/circlePlus@1x.png';
import GrayFadeImage from '../assets/images/grayFade@1x.png';

import { Color, Font } from '../config/styles';

/**
 * Button to be used in lists.
 *
 * Example usage:
 *
 * ```
 * <ListButton
 *   onPress={onPressButton}
 *   title="Tipsa om fler"
 * />
 * ```
 *
 */
export default class ListButton extends Component {
  static propTypes = {
    /**
     * Handler to be called when the user taps the button
     */
    onPress: PropTypes.func.isRequired,
    /**
     * Custom styles to be applied to the component.
     */
    style: PropTypes.number,
    /**
     * Button title
     */
    title: PropTypes.string.isRequired,
  };

  render() {
    const { onPress, style, title } = this.props;

    return (
      <a href="#list-button" onClick={onPress} className={css(styles.wrap, style)}>
        <div className={css(styles.background)}>
          <div className={css(styles.titleWrap)}>
            <span className={css(styles.title)}>{title}</span>
            <img src={CirclePlusImage} alt="" />
          </div>
        </div>
      </a>
    );
  }
}

const styles = StyleSheet.create({
  background: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: 34,
    backgroundImage: `url(${GrayFadeImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  title: {
    color: Color.primary,
    fontFamily: Font.defaultFont,
    fontWeight: 600,
    fontSize: 14,
    marginRight: 10,
    paddingTop: 2,
  },
  titleWrap: {
    display: 'flex',
    flexDirection: 'row',
  },
  wrap: {
    backgroundColor: Color.baseGray,
    textDecoration: 'none',
  },
});

import React, { useState } from 'react';
import { ActionButton } from '@talentech/components';
import { css, StyleSheet } from 'aphrodite';
import DropdownMenu from './DropdownMenu';
import { TALENTECH_ICONS } from '../Icons';
import { useTranslation } from 'react-i18next';

const CategoryListItem = (props) => {
  const [toggleState, setToggleState] = useState({});
  const { category, handleEditCategory, handleDeleteCategory } = props;
  const { t } = useTranslation();

  const dropdownMenuList = [
    {
      title: t('settings-category-edit'),
      icon: TALENTECH_ICONS.editLight,
      onClick: (e) => handleEditCategory(e, category),
    },
    {
      title: t('generic-text-delete'),
      icon: TALENTECH_ICONS.delete,
      onClick: (e) => handleDeleteCategory(e, category),
    },
  ];

  const handleOpenDropdown = (id) => {
    setToggleState((open) => ({
      ...open,
      [id]: !open[id],
    }));
  };

  return (
    <div className={css(styles.settingsCategoryItem)}>
      <span className={css(styles.settingsCategoryText)}>{category.name}</span>

      <ActionButton
        icon={TALENTECH_ICONS.more}
        variant="vertical"
        size="lg"
        className={css(styles.settingsCategoryOptionsButton)}
        onClick={() => handleOpenDropdown(category?.idcategories)}
        aria-label="Options Dropdown"
      />
      {toggleState[category?.idcategories] && (
        <DropdownMenu
          toggleState={toggleState.toggleDropdown}
          setToggleState={setToggleState}
          dropdownMenuList={dropdownMenuList}
        />
      )}
    </div>
  );
};

export default CategoryListItem;

const styles = StyleSheet.create({
  settingsCategoryItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    borderBottom: 'rgba(214, 214, 220, 1) solid 1px',
    // paddingBottom: \rem',
    padding: '.5rem',
  },
  settingsCategoryOptionsButton: {
    padding: 0,
    paddingTop: '1rem',
    paddingBottom: '.55rem',
    paddingLeft: '.45rem',
    paddingRight: '.45rem',
    height: '35px',
    width: '35px',
    borderRadius: '50%',
  },
  userOptionsDropdown: {
    position: 'absolute',
    width: '200px',
    zIndex: '1',
    cursor: 'pointer',
    right: 0,
    top: 40,
    fontSize: '.9rem',
  },
  settingsCategoryText: {
    fontSize: '.9rem',
    margin: '.3rem',
  },
});

import {
  GET_RULES_REQUEST,
  GET_RULES_SUCCESS,
  GET_RULES_FAILURE,
  GET_RULES_INVALIDATE,
} from '../actions/ruleActions';

const initialState = {
  didInvalidate: false,
  error: false,
  isLoading: false,
  response: '',
};

export default function ruleState(state = initialState, { authenticated, error, response, type }) {
  switch (type) {
    case GET_RULES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_RULES_SUCCESS:
      return {
        ...state,
        didInvalidate: false,
        isLoading: false,
        response: response,
      };
    case GET_RULES_FAILURE:
      return {
        ...state,
        isLoading: false,
        response: error,
      };
    case GET_RULES_INVALIDATE:
      return {
        ...state,
        didInvalidate: true,
      };
    default:
      return state;
  }
}

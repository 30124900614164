import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  getUserIfNeeded,
  getUserInvalidate,
  postUser,
} from '../irecommend-lib/actions/userActions';
import { getTeamsIfNeeded } from '../irecommend-lib/actions/teamActions';
import { uploadPicture } from '../irecommend-lib/actions/mediaActions';
import Utils from '../irecommend-lib/utils';

import ProfileEditPage from '../pages/ProfileEditPage';

class ProfileEditContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.onPressSubmit = this.onPressSubmit.bind(this);
    this.onPressChangePicture = this.onPressChangePicture.bind(this);
  }

  /*
   * Get user data. Update if necessary.
   */
  componentDidMount() {
    this.props.getUserIfNeeded();
    this.props.getTeamsIfNeeded();
  }

  render() {
    const teams =
      !this.props.teams.response || this.props.teams.response.constructor !== Array
        ? []
        : this.props.teams.response;
    const user = this.props.user.response;
    const isLoading = this.props.user.isLoading || this.props.picture.isLoading;

    return (
      <ProfileEditPage
        isLoading={isLoading}
        onPressChangePicture={this.onPressChangePicture}
        onPressSubmitForm={this.onPressSubmit}
        teams={teams}
        user={user}
      />
    );
  }

  /**
   * POST user to API
   * @param {object} currentState - State object from TabRecommend component. Holds form data.
   */
  onPressSubmit = (data) => {
    const { t, uploadPicture } = this.props;
    const nameRegex = /^[\w- \xC0-\xFF]+$/i;
    // Form validation
    if (
      data.Familyname.trim() === '' ||
      !nameRegex.test(data.Familyname) ||
      data.Givenname.trim() === '' ||
      !nameRegex.test(data.Givenname)
    ) {
      alert(t('registerprofilecontainer-validation-names-required'));

      return false;
    }
    if (data.Mail === '') {
      alert(t('profileeditcontainer-validation-required'));

      return false;
    }

    // Validate email
    if (!Utils.validateEmail(data.Mail)) {
      alert(t('generic-validation-email'));

      return false;
    }

    if (data.PictureRaw) {
      // Upload image to Cloudinary
      uploadPicture(data.PictureRaw).then(() => {
        data.Picture = this.props.picture.response.data.secure_url;
        this.postData(data);
      });
    } else {
      // Create/edit without uploading a picture
      this.postData(data);
    }

    return true;
  };

  onPressChangePicture = (e) => {
    e.preventDefault();
  };

  postData = (data) => {
    delete data.PictureRaw; // No need for the presentational version

    this.props.postUserData({ ...data, Registered: true }, data.iduser);
    this.props.getUserInvalidate();
  };
}

function mapStateToProps(state) {
  return {
    picture: state.mediaState.uploadPicture,
    teams: state.teamState.teams,
    user: state.userState.user,
    postUser: state.userState.postUser,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getTeamsIfNeeded: () => dispatch(getTeamsIfNeeded()),
    getUserIfNeeded: () => dispatch(getUserIfNeeded()),
    getUserInvalidate: () => dispatch(getUserInvalidate()),
    postUserData: (data, id) => dispatch(postUser(data, id, true)), // true for authenticated call
    uploadPicture: (file) => dispatch(uploadPicture(file)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileEditContainer);

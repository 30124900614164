import React from 'react';
import NumberWidgetItem from './NumberWidgetItem';

const NumberWidget = ({ data, users }) => {
  return (
    <div className=" number-widget" style={{ padding: '0 5%' }}>
      {data.map((num, index) => (
        <NumberWidgetItem key={num.title} number={num} nOfUsers={users.length} />
      ))}
    </div>
  );
};

export default NumberWidget;

import axios from 'axios';

/**
 * Verify that the token exists
 * @param {object} credentials - Credentials - as received from Auth0
 * @return {boolean}
 */
export const tokenExists = (credentials) => {
  return credentials !== undefined && credentials.accessToken !== undefined;
};

/**
 * Verify that the token exists
 * @param {object} credentials - Credentials - as received from Auth0
 * @return {boolean}
 */
export const tokenExpired = (credentials) => {
  if (!tokenExists(credentials)) {
    return true;
  }

  // console.log('expires in:', (credentials.expiresAt - new Date().getTime()) / 1000 / 60)

  return credentials.expiresAt <= new Date().getTime();
};

/**
 * Perform an unsigned upload to Cloudinary
 * @return {Promise} - Axios request promise
 */
export const uploadFile = (file, options = null) => {
  let url = `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/upload`;

  let data = new FormData();
  data.append('upload_preset', process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET);
  data.append('cloud_name', process.env.REACT_APP_CLOUDINARY_CLOUD_NAME);
  data.append('file', file);

  return axios.post(url, data);
};

/**
 * Builds the right API url
 * @return {string} - API type
 */
export const baseApiUrl = (type = 'main') => {
  return type === 'main'
    ? `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_ENVIRONMENT}${process.env.REACT_APP_API_BASE_URL}/`
    : `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_COMMUNICATION_API_ENVIRONMENT}/`;
};

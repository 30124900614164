import {
  POST_INVITE_REQUEST,
  POST_INVITE_SUCCESS,
  POST_INVITE_FAILURE,
  POST_INVITE_RESET,
  POST_BULK_INVITE_REQUEST,
  POST_BULK_INVITE_SUCCESS,
  POST_BULK_INVITE_FAILURE,
  POST_BULK_INVITE_RESET,
  DELETE_INVITE_REQUEST,
  DELETE_INVITE_SUCCESS,
  DELETE_INVITE_FAILURE,
} from '../actions/inviteActions';

const initialState = {
  invite: {
    error: false,
    isLoading: false,
    response: {},
    request: null, // axios request response object
    requestSuccess: false,
  },
  bulkInvite: {
    error: false,
    isLoading: false,
    response: {},
    request: null, // axios request response object
    requestSuccess: false,
  },
  inviteDelete: {
    error: false,
    isLoading: false,
    response: {},
    request: null, // axios request response object
  },
};

export default function inviteState(
  state = initialState,
  { authenticated, error, response, type, request },
) {
  switch (type) {
    case POST_INVITE_REQUEST:
      return {
        ...state,
        invite: {
          ...state.invite,
          error: false,
          isLoading: true,
        },
      };
    case POST_INVITE_SUCCESS:
      return {
        ...state,
        invite: {
          ...state.invite,
          isLoading: false,
          response: response,
          request: request,
        },
      };
    case POST_INVITE_FAILURE:
      return {
        ...state,
        invite: {
          ...state.invite,
          isLoading: false,
          error: true,
          response: error,
          request: request,
        },
      };
    case POST_INVITE_RESET:
      return {
        ...state,
        invite: {
          ...state.invite,
          isLoading: false,
          error: false,
          response: {},
          request: null,
        },
      };
    case POST_BULK_INVITE_REQUEST:
      return {
        ...state,
        bulkInvite: {
          ...state.bulkInvite,
          error: false,
          isLoading: true,
        },
      };
    case POST_BULK_INVITE_SUCCESS:
      return {
        ...state,
        bulkInvite: {
          ...state.bulkInvite,
          isLoading: false,
          response: response,
          request: request,
        },
      };
    case POST_BULK_INVITE_FAILURE:
      return {
        ...state,
        bulkInvite: {
          ...state.bulkInvite,
          isLoading: false,
          error: true,
          response: error,
          request: request,
        },
      };
    case POST_BULK_INVITE_RESET:
      return {
        ...state,
        bulkInvite: {
          ...state.bulkInvite,
          isLoading: false,
          error: false,
          response: {},
          request: null,
        },
      };
    case DELETE_INVITE_REQUEST:
      return {
        ...state,
        inviteDelete: {
          ...state.inviteDelete,
          error: false,
          isLoading: true,
        },
      };
    case DELETE_INVITE_SUCCESS:
      return {
        ...state,
        inviteDelete: {
          ...state.inviteDelete,
          isLoading: false,
          response: response,
          request: request,
        },
      };
    case DELETE_INVITE_FAILURE:
      return {
        ...state,
        inviteDelete: {
          ...state.inviteDelete,
          isLoading: false,
          error: true,
          response: error,
          request: request,
        },
      };
    default:
      return state;
  }
}

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { css, StyleSheet } from 'aphrodite';
import moment from 'moment';
import TeamMemberItem from '../../components/admin/TeamMemberItem';
import { withTranslation } from 'react-i18next';

class TeamMemberList extends Component {
  static propTypes = {
    /**
     * List status - controls when to show the delete button
     */
    title: PropTypes.string.isRequired,
    /**
     * Items that populate the list
     */
    items: PropTypes.array.isRequired,
    /**
     * Delete button callback
     */
  };

  render() {
    const { items, title, t } = this.props;

    const users = items.map((user, index) => (
      <TeamMemberItem
        key={index}
        firstname={user.givenname}
        lastname={user.familyname}
        email={user.mail}
        lastActive={moment(user.lastactivity).format('YYYY-MM-DD')}
        t={t}
      />
    ));

    return (
      <div className={css(styles.container)}>
        <h3 className={css(styles.title)}>{title}</h3>
        {users}
      </div>
    );
  }
}

export default withTranslation()(TeamMemberList);
const styles = StyleSheet.create({
  container: {
    maxWidth: '60em',
    margin: '0 auto',
  },
  title: {
    margin: '2em 0 .5em',
  },
});

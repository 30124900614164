import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUsers, postUser } from '../../irecommend-lib/actions/userActions';
import useShowToast from '../helper/useShowToast';

const useRemoveInvite = (setState, t) => {
  const { response, error, isLoading } = useSelector((state) => state.userState.postUser);
  const dispatch = useDispatch();
  const { showToastMessage } = useShowToast(setState, t, 'POST_USER_RESET');

  useEffect(() => {
    handleResponse(response);
  }, [response]);

  const removeInvite = (data, userId) => dispatch(postUser(data, userId, true));

  const handleResponse = (response) => {
    if (response && response.status === 200) {
      showToastMessage('invitationpage-user-removed');
      dispatch(getUsers());
    }
    if (response && error && response.status === 401)
      showToastMessage('invitationpage-user-exists', error, response);
  };

  return { removeInvite, removeIsloading: isLoading };
};

export default useRemoveInvite;

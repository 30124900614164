import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Color } from '../../config/styles';
import { css, StyleSheet } from 'aphrodite';

import moment from 'moment';

import { IconCaretDown, IconBubble } from '../../components/Icons';
import JobsListNav from '../../components/JobsListNav';
import ProfilePicture from '../ProfilePicture';
import ProgressBar from '../../components/admin/ProgressBar';
import CandidateDropdown from '../../components/admin/CandidateDropdown';
import CommentsDropdown from '../../components/admin/CommentsDropdown';
import { withTranslation } from 'react-i18next';

class AdminJobsListItem extends Component {
  static propTypes = {
    position: PropTypes.object,
    candidate: PropTypes.object,
    recommendation: PropTypes.object,
    user: PropTypes.object,
    expired: PropTypes.bool.isRequired,
    onPressShowComment: PropTypes.func,
    onPressGetDocument: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      showActionNav: false,
      showCandidateInfo: false,
      commentFromCandidate: props.commentFromCandidate,
      showComments: false,
    };
  }

  toggleNav = (e) => {
    e.preventDefault();

    this.setState({
      showActionNav: !this.state.showActionNav,
    });
  };

  toggleCandidateInfo = (e) => {
    e.preventDefault();

    this.setState({
      showCandidateInfo: !this.state.showCandidateInfo,
    });
  };

  toggleComments = (e) => {
    e.preventDefault();

    this.setState({
      showComments: !this.state.showComments,
    });
  };

  render() {
    const {
      t,
      candidate,
      user,
      position,
      recommendation,
      expired,
      commentToHR,
      onChangeStatus,
      onPressShowComment,
      onPressGetDocument,
      onPressVerifyContact,
      onPressVerifyInterview,
      onPressVerifyHire,
      onPressFailedContact,
      onPressNotInterested,
      onPressFailedAfterInterview,
      onPressFailedInterview,
      onPressFailedHire,
    } = this.props;

    // Get date when recommendation was made
    const recommendationDate = new Date(candidate.created);

    // Show a countdown from 60 days until the candidate is archived
    const milliSeconds = Math.abs(new Date() - recommendationDate);
    const difference = new moment.duration(milliSeconds);
    const daysToArchive = Math.floor(60 - difference.asDays());

    const { state, integrationstatus, externalurl } = recommendation;

    // Set correct menu in the action dropdown
    let candidateMenu;
    if (state === 10 || state === 20 || state === 50 || state === 40) {
      candidateMenu = 'candidateContact';
    } else if (state === 70) {
      candidateMenu = 'candidateInterview';
    } else if (state === 100 || state === 120) {
      candidateMenu = 'candidateHire';
    }

    const anonymous = recommendation.anonymous ? ` (${t('generic-text-anonymous')})` : '';
    const userName = `${user.givenname} ${user.familyname}${anonymous}`;

    let anonymizedMessage = null;

    if (state === 110 || state === 120) {
      anonymizedMessage = t('generic-anonymized-not-interested');
    } else if (state === 10) {
      anonymizedMessage = t('generic-anonymized-sent');
    } else {
      anonymizedMessage = t('generic-anonymized-not-in-question');
    }

    return (
      <div className={css(styles.listItem)}>
        {expired && <div className={css(styles.anonymousLabel)}>{anonymizedMessage}</div>}

        <header className={css(styles.candidateHeader, expired ? styles.anonymous : null)}>
          <div className={css(styles.profileImage)}>
            <ProfilePicture
              name={candidate.name}
              picture={candidate.picture === null ? '' : candidate.picture}
              src={candidate.picture}
              size="large"
            />
          </div>
          <ul className={css(styles.candidateMetaList)}>
            <li className={css(styles.candidateMetaItem)}>
              <span className={css(styles.candidateMetaLabel)}>
                {t('candidateitem-recommended')}:
              </span>
              <span className={css(styles.candidateMetaValue)}>
                {recommendationDate.toLocaleDateString('sv-SE')}
              </span>
            </li>
            <li className={css(styles.candidateMetaItem)}>
              <span className={css(styles.candidateMetaLabel)}>
                {t('generic-text-recommended-by')}:
              </span>
              <span className={css(styles.candidateMetaValue)}>{userName}</span>
            </li>
            {(state === 10 || state === 20) && !expired && (
              <li className={css(styles.candidateMetaItem)}>
                <span className={css(styles.candidateMetaLabel)}>
                  {t('candidateitem-archives-in')}:
                </span>
                <span className={css(styles.candidateMetaValue)}>
                  {daysToArchive}{' '}
                  {daysToArchive === 1 ? t('generic-text-day') : t('generic-text-days')}
                </span>
              </li>
            )}
            {integrationstatus !== 0 && !expired && (
              <li className={css(styles.candidateMetaItem)}>
                <span className={css(styles.candidateMetaLabel)}>
                  {integrationstatus === 1 && externalurl ? (
                    <a
                      className={css([
                        styles.candidateExternalLink,
                        styles.candidateExternalLinkWithUrl,
                      ])}
                      target="blank"
                      href={externalurl}
                    >
                      {t('candidate-integration-connected')}
                    </a>
                  ) : integrationstatus === 1 && !externalurl ? (
                    <span className={css([styles.candidateExternalLink])}>
                      {t('candidate-integration-connected')}
                    </span>
                  ) : integrationstatus === 2 ? (
                    <span className={css(styles.candidateExternalError)}>
                      {t('candidate-integration-already-exists')}
                    </span>
                  ) : integrationstatus === 3 ? (
                    <span className={css(styles.candidateExternalError)}>
                      {t('candidate-integration-error')}
                    </span>
                  ) : integrationstatus === 4 ? (
                    <span className={css(styles.candidateExternalError)}>
                      {t('candidate-integration-error')}
                    </span>
                  ) : (
                    <span className={css(styles.candidateExternalError)}>
                      {t('candidate-integration-not-fetched')}
                    </span>
                  )}
                </span>
              </li>
            )}
          </ul>
          <div className={css(styles.candidateActions)}>
            <button
              className={css(styles.candidateActionButton)}
              onClick={() => {
                this.setState({ showActionNav: !this.state.showActionNav });
              }}
            >
              {t('generic-text-actions')}
              <IconCaretDown className="candidate-action-button-icon" />
            </button>
            <JobsListNav
              menu={candidateMenu}
              position={position}
              recommendation={recommendation}
              showActionNav={this.state.showActionNav}
              onPressToggleNav={this.toggleNav}
              onPressVerifyContact={onPressVerifyContact}
              onPressFailedContact={onPressFailedContact}
              onPressNotInterested={onPressNotInterested}
              onPressVerifyInterview={onPressVerifyInterview}
              onPressFailedInterview={onPressFailedInterview}
              onPressFailedAfterInterview={onPressFailedAfterInterview}
              onPressVerifyHire={onPressVerifyHire}
              onPressFailedHire={onPressFailedHire}
              onChangeStatus={(status) => {
                onChangeStatus(recommendation.idrecommendations, status, recommendation);
                this.setState({ showActionNav: !this.state.showActionNav });
              }}
            />
          </div>
        </header>

        <div className={css(styles.candidateBody, expired ? styles.anonymous : null)}>
          <div className={css(styles.candidateInfo)}>
            <h2 className={css(styles.candidateTitle)}>{candidate.name}</h2>
            {candidate.headline && (
              <span className={css(styles.candidateSlogan)}>{candidate.headline}</span>
            )}
            <div className={css(styles.candidateCredentials)}>
              <button
                className={css(styles.candidateLink)}
                onClick={() => {
                  this.setState({
                    showCandidateInfo: !this.state.showCandidateInfo,
                  });
                }}
              >
                {t('candidateitem-info')}
                <IconCaretDown className="candidate-action-button-icon" />
              </button>
              <CandidateDropdown
                tel={candidate.telephone}
                email={candidate.mail}
                linkedin={candidate.profileURL}
                document={recommendation.referral.document}
                idreferral={recommendation.referral.idreferral}
                showCandidateInfo={this.state.showCandidateInfo}
                onPressToggleCandidateInfo={this.toggleCandidateInfo}
                onPressGetDocument={onPressGetDocument}
              />
            </div>
            <div className={css(styles.candidateCredentials)}>
              <button
                className={css(styles.candidateLink)}
                onClick={() => {
                  this.setState({ showComments: !this.state.showComments });
                }}
              >
                {t('comment-generic-text')}
                <IconCaretDown className="candidate-action-button-icon" />
              </button>
              {this.state.showComments ? (
                <CommentsDropdown
                  showComments={this.state.showComments}
                  onPressToggleComments={this.toggleComments}
                  recommendation={this.props.recommendation}
                />
              ) : null}
            </div>
            {commentToHR && (
              <button
                href="#"
                className={css(styles.candidateLink)}
                onClick={(e) => {
                  e.preventDefault();
                  onPressShowComment(t('generic-text-comment-to-hr'), commentToHR);
                }}
              >
                <IconBubble className="icon__bubble" />
                {t('generic-text-comment-to-hr')}
              </button>
            )}
            {this.state.commentFromCandidate && (
              <button
                href="#"
                className={css(styles.candidateLink)}
                onClick={(e) => {
                  e.preventDefault();
                  onPressShowComment(
                    t('generic-text-comment-from-candidate'),
                    this.state.commentFromCandidate,
                  );
                }}
              >
                <IconBubble className="icon__bubble" />
                {t('generic-text-comment-from-candidate')}
              </button>
            )}
          </div>
          <div className={css(styles.progressBar)}>
            {expired === false && (
              <ProgressBar
                position={position}
                recommendation={recommendation}
                onPressVerifyContact={onPressVerifyContact}
                onPressVerifyInterview={onPressVerifyInterview}
                onPressVerifyHire={onPressVerifyHire}
                onPressFailedContact={onPressFailedContact}
                onPressNotInterested={onPressNotInterested}
                onPressFailedAfterInterview={onPressFailedAfterInterview}
                onPressFailedInterview={onPressFailedInterview}
                onPressFailedHire={onPressFailedHire}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(AdminJobsListItem);

const styles = StyleSheet.create({
  listItem: {
    borderRadius: '2px',
    boxShadow: '0 3px 17px 0 rgba(0, 0, 0, .05)',
    backgroundColor: Color.white,
    margin: '2.5rem 0 2.5rem 0',
    position: 'relative',
  },
  candidateHeader: {
    backgroundColor: '#fafafa',
    padding: '.4em 0 .4em 1.5em',
    display: 'flex',
  },
  profileImage: {
    borderRadius: '50%',
    marginRight: '.75em',
    float: 'left',
    margin: '-1.5em 0',
  },
  candidateMetaList: {
    listStyle: 'none',
    padding: '0',
    flex: '1',
    margin: '0',
  },
  candidateMetaItem: {
    display: 'inline-block',
    padding: '0 0 0 .75rem',
    fontSize: '.875rem',
    margin: '0',

    ':after': {
      content: '"|"',
      display: 'inline-block',
      margin: '0 0 0 .75rem',
    },

    ':last-child:after': {
      display: 'none',
    },
  },
  candidateMetaLabel: {
    color: '#b7b7b7',
    marginRight: '.25em',
  },
  candidateExternalLink: {
    fontSize: '.875rem',
    color: Color.clearBlue,
  },
  candidateExternalLinkWithUrl: {
    ':hover': {
      textDecoration: 'underline',
    },
    ':focus': {
      border: 0,
    },
  },
  candidateExternalError: {
    color: 'red',
  },
  candidateActions: {
    float: 'right',
    padding: '0',
    position: 'relative',
    alignSelf: 'flex-end',
  },
  candidateActionButton: {
    background: 'transparent',
    border: '0',
    cursor: 'pointer',
    padding: '.2em 1.25em',
    fontSize: '.875rem',
  },
  candidateBody: {
    padding: '1.25em 8em 1.30em 1.7em',
    display: 'grid',
    gridTemplateColumns: '20rem 1fr',
    alignItems: 'center',
  },
  progressBar: {
    flex: '1 1 60rem',
  },
  candidateInfo: {
    paddingRight: '2em',
    flex: '0 0 20rem',
  },
  candidateTitle: {
    margin: '0 0 .6rem',
    fontSize: '1.375em',
    fontWeight: 600,
  },
  candidateSlogan: {
    color: '#b7b7b7',
    fontSize: '.875em',
    display: 'block',
    marginBottom: '.6rem',
    marginTop: '-.3rem',
  },
  candidateCredentials: {
    position: 'relative',
    marginBottom: '.5em',
  },
  candidateLink: {
    display: 'block',
    padding: '.2rem 0',
    margin: '.2rem 0',
    textDecoration: 'none',
    background: 'none',
    border: '0',
    fontSize: '.875rem',
    cursor: 'pointer',
    textAlign: 'left',
  },
  anonymousLabel: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    fontWeight: 600,
    transform: 'translate3d(-50%, -50%, 0)',
    zIndex: 10,
  },
  anonymous: {
    filter: 'blur(5px)',
  },
});

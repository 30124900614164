import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import CrowdinBackend from './crowdin-backend';
import { normalizeLanguage, supportedLanguages } from '../helpers/language';
import i18nextBrowserLanguagedetector from 'i18next-browser-languagedetector';

const customLanguageDetector = {
  type: 'languageDetector',
  async: false,
  init: Function.prototype,
  detect: () => {
    const languageDetector = new i18nextBrowserLanguagedetector();
    const detectedLanguage = languageDetector.detect();
    // Normalize the detected language to its primary code (e.g., en, sv)
    if (detectedLanguage) {
      const language = normalizeLanguage(detectedLanguage);
      if (supportedLanguages.indexOf(language) !== -1) {
        localStorage.setItem('i18nextLng', language);
        return language;
      }
    }
    localStorage.setItem('i18nextLng', 'en');
    return 'en'; // default language if none detected
  },
  cacheUserLanguage: Function.prototype,
};

i18n
  .use(customLanguageDetector)
  .use(initReactI18next)
  .use(Backend)
  .init({
    backend: {
      backends: [LocalStorageBackend, CrowdinBackend],
      backendOptions: [
        {
          defaultVersion: localStorage.getItem('crowdin-irecommend-manifest-timestamp'),
        },
        {
          distributionHash: process.env.REACT_APP_CROWDIN_HASH,
        },
      ],
    },
    detection: {
      order: ['customLanguageDetector', 'localStorage', 'navigator'],
      caches: ['localStorage'],
    },

    debug: process.env.REACT_APP_APP_ENVIRONMENT !== 'app',
    fallbackLng: 'en',
  });

export default i18n;

import { useHistory } from 'react-router';
import { createBreadcrumb } from '../../helpers/utils';

const useFetchBreadcrumb = (page, t, titleKey = null) => {
  const history = useHistory();
  const subBreadcrumbs = [];

  const homeBreadcrumb = { labelKey: 'admin-header-dashboard', onClick: () => navigateToPage('') };

  switch (page) {
    case 'users':
      subBreadcrumbs.push({ labelKey: 'admin-header-invites', isActive: true });
      break;
    case 'communication':
      subBreadcrumbs.push({ labelKey: 'admin-header-communication', isActive: true });
      break;
    case 'userImport':
      subBreadcrumbs.push(
        {
          labelKey: 'admin-header-invites',
          onClick: () => navigateToPage('invites'),
        },
        { labelKey: 'invitationpage-import-users', isActive: true },
      );
      break;
    case 'communicationEdit':
      subBreadcrumbs.push(
        {
          labelKey: 'admin-header-communication',
          onClick: () => navigateToPage('communication'),
        },
        { labelKey: titleKey, isActive: true },
      );
      break;
    default:
      return subBreadcrumbs;
  }

  const navigateToPage = (subPage) => {
    if (subPage) return history.push(`/admin/${subPage}`);

    history.push(`/admin`);
  };

  return createBreadcrumb([homeBreadcrumb, ...subBreadcrumbs], t);
};

export default useFetchBreadcrumb;

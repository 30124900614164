import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite';
import { Color, Font } from '../config/styles';
import { IconXThin, IconCheckmark } from './Icons';
import { withTranslation } from 'react-i18next';

class CommunicationModal extends Component {
  static propTypes = {
    title: PropTypes.string,
    description: typeof description === 'string' ? PropTypes.string : PropTypes.element,
    confirm: PropTypes.bool,
    confirmCallback: PropTypes.func,
    cancelCallback: PropTypes.func.isRequired,
    confirmTitle: PropTypes.string,
  };

  render() {
    const {
      title,
      description,
      type,
      confirmCallback,
      cancelCallback,
      t,
      confirmTitle,
      toggleActive,
      confirmChangeDate,
    } = this.props;

    const ct = confirmTitle ? confirmTitle : t('communication-item-modal-send');

    const toggle = toggleActive ? (
      <button
        className={css(styles.modalWindowButton, styles.modalWindowButtonGreen)}
        onClick={confirmChangeDate}
      >
        <span className={css(styles.modalWindowButtonIcon)}>
          <IconCheckmark />
        </span>{' '}
        {t('generic-intervall-toggle-text')}
      </button>
    ) : null;

    return (
      <div className={css(styles.modalWrapper)}>
        <a
          href="#cancel-action"
          onClick={(e) => {
            e.preventDefault();
            cancelCallback(e);
          }}
          className={css(styles.modalBackground)}
        >
          &nbsp;
        </a>
        <div className={css(styles.modalWindow)}>
          <a
            href="#close-modal"
            className={css(styles.modalWindowClose)}
            onClick={(e) => {
              e.preventDefault();
              cancelCallback(e);
            }}
          >
            <IconXThin />
          </a>
          <div className={css(styles.modalWindowInner)}>
            <h3 className="title">{title}</h3>
            <p className="description">{description}</p>
          </div>
          <div className={css(styles.modalWindowButtons)}>
            {toggle}
            <button
              className={css(
                styles.modalWindowButton,
                type === 'remove' ? styles.modalWindowButtonRed : styles.modalWindowButtonGreen,
              )}
              onClick={confirmCallback}
            >
              <span className={css(styles.modalWindowButtonIcon)}>
                <IconCheckmark />
              </span>{' '}
              {ct}
            </button>
            <button className={css(styles.modalWindowButton)} onClick={cancelCallback}>
              {t('generic-text-modal-cancel')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(CommunicationModal);

const styles = StyleSheet.create({
  modalWrapper: {
    backgroundColor: `${Color.primary}40`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    zIndex: 20,
  },
  modalBackground: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    cursor: 'default',
  },
  modalWindow: {
    backgroundColor: '#fff',
    maxWidth: '28em',
    margin: '0 1.5em',
    boxShadow: '0 5px 17px 0 rgba(0, 0, 0, .03)',
    textAlign: 'center',
    transform: 'translateY(-2em)',
    position: 'relative',
  },
  modalWindowInner: {
    padding: '2em 3em 3em',
  },
  modalWindowClose: {
    width: '1.2em',
    height: '1.2em',
    position: 'absolute',
    top: '1.2em',
    right: '1em',
  },
  modalWindowButtons: {
    display: 'flex',
    alignItems: 'stretch',
    marginTop: '-2em',
  },
  modalWindowButton: {
    fontFamily: Font.defaultFont,
    flex: '50%',
    padding: '1.8em',
    border: '0',
    borderRadius: '0',
    backgroundColor: '#fff',
    cursor: 'pointer',
    transition: 'all .2s ease-in-out',
    outline: 'none',

    ':hover': {
      backgroundColor: '#f1f1f1',
    },
  },
  modalWindowButtonIcon: {
    display: 'inline-block',
    width: '.8em',
    height: '.8em',
    marginRight: '.25em',
  },
  modalWindowButtonGreen: {
    color: Color.success,
    fill: Color.success,
  },
  modalWindowButtonRed: {
    color: Color.warning,
    fill: Color.warning,
  },
});

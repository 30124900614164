import React from 'react';
import { css } from 'aphrodite';

import AdminImage from './AdminImage';
import CompetitionLeaderboard from './CompetitionLeaderboard';
import DataPoint from './DataPoint';

function CompetitionDetails(props) {
  const {
    competition,
    competitionType,
    leaderboardUsers,
    daysLeft,
    competitionStarted,
    leaderPoints,
    t,
    inheritedStyles,
  } = props;

  return (
    <div>
      <div className={css(inheritedStyles.container)}>
        <div className={css(inheritedStyles.heading)}>
          <AdminImage picture={competition.image} src={competition.image} />
          <div>
            <h1 className={css(inheritedStyles.title)}>{competition.title}</h1>
            <h3 className={css(inheritedStyles.title, inheritedStyles.subtitle)}>
              {competitionType}
            </h3>
          </div>
        </div>
      </div>
      <div className={css(inheritedStyles.container)}>
        <div className={css(inheritedStyles.box, inheritedStyles.leaderboard)}>
          <CompetitionLeaderboard users={leaderboardUsers} />
        </div>
        <div className={css(inheritedStyles.box)}>
          <DataPoint data={daysLeft} unit={competitionStarted ? t('competition-days-left') : ''} />
        </div>
        <div className={css(inheritedStyles.box)}>
          <DataPoint data={leaderPoints} unit={t('competition-leader-points')} />
        </div>
        <div className={css(inheritedStyles.box, inheritedStyles.rewards)}>
          <div className={css(inheritedStyles.boldFont)}>{t('generic-text-reward')}</div>
          <div>{competition.reward}</div>
        </div>
      </div>
    </div>
  );
}

export default CompetitionDetails;

import { useState, useMemo } from 'react';
import { css, StyleSheet } from 'aphrodite';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Pagination } from '@talentech/components';

import Loader from '../../components/Loader';
import JobsPageHeader from '../../components/admin/JobsPageHeader';
import SearchAndFilter from '../../components/admin/SearchAndFilter';
import EmptyState from '../../components/EmptyState';
import PositionsList from '../../components/admin/PositionsList';
import FetchedPositionsList from '../../components/admin/FetchedPositionsList';

import useDebounce from '../../hooks/helper/useDebounce';
import useFetchIntegration from '../../hooks/position/useFetchIntegration';
import useSetSelectedTab from '../../hooks/position/useSetSelectedTab';
import useFetchPositionsByFilter from '../../hooks/position/useFetchPositionsByFilter';
import useFetchExternalPositions from '../../hooks/position/useFetchExternalPositions';
import useFetchPositionRecommendations from '../../hooks/position/useFetchPositionRecommendations';
import useRemovePosition from '../../hooks/position/useRemovePosition';
import useArchivePosition from '../../hooks/position/useArchivePosition';
import useSetupUserflow from '../../hooks/helper/useSetupUserflow';

import { FILTER_TYPES, isMatchingSearch, ITEMS_PER_PAGE, TAB_NAMES } from '../../helpers/position';
import { calculatePageCount } from '../../helpers/utils';
import { pushPosition } from '../../irecommend-lib/actions/positionActions';

const JobsPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedFilter, setSelectedFilter] = useState({
    name: `${t('positionpage-filter-published')}`,
    value: FILTER_TYPES.published,
  });
  const { selectedTab } = useSetSelectedTab(TAB_NAMES.positions);
  const debouncedSearchTerm = useDebounce(searchTerm, 350);

  const { tokenExist, hasRMOrTRIntegration } = useFetchIntegration();
  const { isPositionsLoading, positions } = useFetchPositionsByFilter(selectedFilter.value);
  const { externalPositions, isExternalPositionsLoading, onPressFetchJobs } =
    useFetchExternalPositions();
  const { recommendations, isRecommendationsLoading } = useFetchPositionRecommendations(positions);
  const { onDeleteExternalPosition, onDeletePosition } = useRemovePosition(selectedFilter.value);
  const { archiveIsLoading, onArchivePosition } = useArchivePosition(selectedFilter.value);
  const { userflowIsLoading } = useSetupUserflow(true);

  const isLoading =
    isPositionsLoading ||
    isExternalPositionsLoading ||
    isRecommendationsLoading ||
    archiveIsLoading ||
    userflowIsLoading;

  const filteredPositions = useMemo(
    () =>
      positions.length > 0
        ? positions.filter((position) => isMatchingSearch(position, debouncedSearchTerm))
        : [],
    [positions, debouncedSearchTerm],
  );

  const filteredExternalPositions = useMemo(
    () =>
      externalPositions
        ? externalPositions.filter((position) => isMatchingSearch(position, debouncedSearchTerm))
        : [],
    [externalPositions, debouncedSearchTerm],
  );

  const isEmptyResult =
    debouncedSearchTerm &&
    ((selectedTab === TAB_NAMES.positions && filteredPositions.length === 0) ||
      (selectedTab !== TAB_NAMES.positions && filteredExternalPositions.length === 0));

  const positionsPageCount = calculatePageCount(ITEMS_PER_PAGE, filteredPositions.length);

  const externalPositionsPageCount = calculatePageCount(
    ITEMS_PER_PAGE,
    filteredExternalPositions.length,
  );

  const displayedExternalPositions = filteredExternalPositions
    ? filteredExternalPositions.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        currentPage * ITEMS_PER_PAGE,
      )
    : [];

  const displayedPositions = filteredPositions
    ? filteredPositions.slice((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE)
    : [];

  const handlePageChange = (data) => {
    const selectedPage = data.selected + 1;
    setCurrentPage(selectedPage);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const onPushPosition = (id, pushTypes) => {
    dispatch(pushPosition(id, { Push: pushTypes }));
  };

  return (
    <Loader isLoading={isLoading}>
      <JobsPageHeader hasRMOrTRIntegration={hasRMOrTRIntegration} tokenExist={tokenExist} />
      <main aria-label="Main content">
        <SearchAndFilter
          tokenExist={tokenExist}
          selectedTab={selectedTab}
          searchTerm={searchTerm}
          onSearchChange={setSearchTerm}
          onFilterChange={setSelectedFilter}
          selectedFilter={selectedFilter}
          onPressFetchJobs={onPressFetchJobs}
          isExternalPositionsLoading={isExternalPositionsLoading}
        />
        {debouncedSearchTerm && (
          <div className={css(styles.results)}>
            {t('positionpage-matching-results')}:
            <strong className={css(styles.number)}>
              {selectedTab === TAB_NAMES.positions
                ? filteredPositions.length
                : filteredExternalPositions.length}
            </strong>
          </div>
        )}
        {isEmptyResult ? (
          <EmptyState
            img="https://storybook.talentech.io/images/svg/marketing-advertising/view-count-magnify.svg"
            alt="Magnifying glass"
            width="110"
            heading={t('positionpage-no-matching-results')}
            description={t('positionpage-no-matching-search')}
          />
        ) : selectedTab === TAB_NAMES.positions || !tokenExist ? (
          <PositionsList
            displayedPositions={displayedPositions}
            recommendations={recommendations}
            onDeletePosition={onDeletePosition}
            onArchivePosition={onArchivePosition}
            onPushPosition={onPushPosition}
          />
        ) : (
          <FetchedPositionsList
            displayedExternalPositions={displayedExternalPositions}
            onPressFetchJobs={onPressFetchJobs}
            onDeleteExternalPosition={onDeleteExternalPosition}
          />
        )}
      </main>
      <footer aria-label="Footer">
        <div className={css(styles.pagination)}>
          {(displayedExternalPositions.length > 0 || displayedPositions.length > 0) && (
            <Pagination
              marginPagesDisplayed={2}
              onPageChange={handlePageChange}
              pageCount={
                selectedTab === TAB_NAMES.positions
                  ? positionsPageCount
                  : externalPositionsPageCount
              }
              pageRangeDisplayed={2}
              variant="text"
            />
          )}
        </div>
      </footer>
    </Loader>
  );
};

export default JobsPage;

const styles = StyleSheet.create({
  number: {
    margin: '5px',
  },
  results: {
    padding: '20px 0px 0px 20px',
  },
  pagination: {
    marginLeft: '10px',
  },
});

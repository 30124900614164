import {
  GET_POSITION_REQUEST,
  GET_POSITION_SUCCESS,
  GET_POSITION_FAILURE,
  POST_POSITION_REQUEST,
  POST_POSITION_SUCCESS,
  POST_POSITION_FAILURE,
  // POST_POSITION_UPDATE_REQUEST,
  // POST_POSITION_UPDATE_SUCCESS,
  // POST_POSITION_UPDATE_FAILURE,
  GET_EXTERNAL_POSITIONS_REQUEST,
  GET_EXTERNAL_POSITIONS_SUCCESS,
  GET_EXTERNAL_POSITIONS_FAILURE,
  PUSH_POSITION_REQUEST,
  PUSH_POSITION_SUCCESS,
  PUSH_POSITION_FAILURE,
  DELETE_POSITION_REQUEST,
  DELETE_POSITION_SUCCESS,
  DELETE_POSITION_FAILURE,
  GET_POSITIONS_REQUEST,
  GET_POSITIONS_SUCCESS,
  GET_POSITIONS_FAILURE,
  GET_POSITIONS_ADMIN_REQUEST,
  GET_POSITIONS_ADMIN_SUCCESS,
  GET_POSITIONS_ADMIN_FAILURE,
  GET_READ_POSITIONS,
  READ_POSITIONS_INVALIDATE,
  POST_POSITION_ARCHIVE_REQUEST,
  POST_POSITION_ARCHIVE_SUCCESS,
  POST_POSITION_ARCHIVE_FAILURE,
  POST_POSITION_ARCHIVE_RESET,
  REMOVE_EXTERNAL_POSITION,
  GET_POSITION_CATEGORIES_REQUEST,
  GET_POSITION_CATEGORIES_SUCCESS,
  GET_POSITION_CATEGORIES_FAILURE,
  POST_POSITION_CATEGORIES_REQUEST,
  POST_POSITION_CATEGORIES_SUCCESS,
  POST_POSITION_CATEGORIES_FAILURE,
} from '../actions/positionActions';

const initialState = {
  position: {
    didInvalidate: false,
    error: false,
    isLoading: false,
    response: {},
  },
  positions: {
    error: false,
    isLoading: false,
    response: [],
  },
  positionsAdmin: {
    error: false,
    isLoading: false,
    response: [],
  },
  externalPositions: {
    error: false,
    isLoading: false,
    response: [],
  },
  readPositions: {
    didInvalidate: false,
    ids: [],
  },
  positionDelete: {
    didInvalidate: false,
    error: false,
    isLoading: false,
    response: {},
  },
  positionPost: {
    didInvalidate: false,
    error: false,
    isLoading: false,
    response: {},
  },
  positionPush: {
    didInvalidate: false,
    error: false,
    isLoading: false,
    response: {},
  },
  positionArchive: {
    didInvalidate: false,
    error: false,
    isLoading: false,
    response: {},
  },
  positionCategories: {
    didInvalidate: false,
    error: false,
    isLoading: false,
    response: {},
  },
  postPositionCategories: {
    didInvalidate: false,
    error: false,
    isLoading: false,
    response: {},
  },
};

export default function positionState(
  state = initialState,
  { authenticated, error, response, type, request },
) {
  switch (type) {
    case GET_POSITION_REQUEST:
      return {
        ...state,
        position: {
          ...state.position,
          isLoading: true,
        },
      };
    case GET_POSITION_SUCCESS:
      return {
        ...state,
        position: {
          ...state.position,
          isLoading: false,
          response: response,
        },
      };
    case GET_POSITION_FAILURE:
      return {
        ...state,
        position: {
          ...state.position,
          isLoading: false,
          error: true,
          response: error,
        },
      };

    case POST_POSITION_REQUEST:
      return {
        ...state,
        positionPost: {
          ...state.positionPost,
          isLoading: true,
        },
      };
    case POST_POSITION_SUCCESS:
      return {
        ...state,
        positionPost: {
          ...state.positionPost,
          isLoading: false,
          response: response,
          //request: request,
        },
      };
    case POST_POSITION_FAILURE:
      return {
        ...state,
        positionPost: {
          ...state.positionPost,
          isLoading: false,
          error: true,
          response: error,
          //request: request,
        },
      };

    case GET_EXTERNAL_POSITIONS_REQUEST:
      return {
        ...state,
        externalPositions: {
          ...state.externalPositions,
          isLoading: true,
        },
      };
    case GET_EXTERNAL_POSITIONS_SUCCESS:
      return {
        ...state,
        externalPositions: {
          ...state.externalPositions,
          isLoading: false,
          response: response,
        },
      };
    case GET_EXTERNAL_POSITIONS_FAILURE:
      return {
        ...state,
        externalPositions: {
          ...state.externalPositions,
          isLoading: false,
          error: true,
          response: error,
        },
      };

    case PUSH_POSITION_REQUEST:
      return {
        ...state,
        positionPush: {
          ...state.positionPush,
          isLoading: true,
        },
      };
    case PUSH_POSITION_SUCCESS:
      return {
        ...state,
        positionPush: {
          ...state.positionPush,
          isLoading: false,
          response: response,
          //request: request,
        },
      };
    case PUSH_POSITION_FAILURE:
      return {
        ...state,
        positionPush: {
          ...state.positionPush,
          isLoading: false,
          error: true,
          response: error,
          //request: request,
        },
      };

    case DELETE_POSITION_REQUEST:
      return {
        ...state,
        positionDelete: {
          ...state.positionDelete,
          isLoading: true,
        },
      };
    case DELETE_POSITION_SUCCESS:
      return {
        ...state,
        positionDelete: {
          ...state.positionDelete,
          isLoading: false,
          response: response,
        },
      };
    case DELETE_POSITION_FAILURE:
      return {
        ...state,
        positionDelete: {
          ...state.positionDelete,
          isLoading: false,
          error: true,
          response: error,
        },
      };

    case REMOVE_EXTERNAL_POSITION:
      return {
        ...state,
        externalPositions: {
          ...state.externalPositions,
          response,
        },
      };

    case GET_POSITIONS_REQUEST:
      return {
        ...state,
        positions: {
          ...state.positions,
          isLoading: true,
        },
      };
    case GET_POSITIONS_SUCCESS:
      return {
        ...state,
        positions: {
          ...state.positions,
          isLoading: false,
          response: response,
        },
      };
    case GET_POSITIONS_FAILURE:
      return {
        ...state,
        positions: {
          ...state.positions,
          isLoading: false,
          error: true,
          response: error,
        },
      };
    case GET_POSITIONS_ADMIN_REQUEST:
      return {
        ...state,
        positionsAdmin: {
          ...state.positionsAdmin,
          isLoading: true,
        },
      };
    case GET_POSITIONS_ADMIN_SUCCESS:
      return {
        ...state,
        positionsAdmin: {
          ...state.positionsAdmin,
          isLoading: false,
          response: response,
        },
      };
    case GET_POSITIONS_ADMIN_FAILURE:
      return {
        ...state,
        positionsAdmin: {
          ...state.positionsAdmin,
          isLoading: false,
          error: true,
          response: error,
        },
      };
    case GET_READ_POSITIONS:
      return {
        ...state,
        readPositions: {
          ...state.readPositions,
          didInvalidate: false,
          ids: response,
        },
      };
    case READ_POSITIONS_INVALIDATE:
      return {
        ...state,
        readPositions: {
          ...state.readPositions,
          didInvalidate: true,
        },
      };

    case POST_POSITION_ARCHIVE_REQUEST:
      return {
        ...state,
        positionPost: {
          ...state.positionPost,
          isLoading: true,
        },
      };
    case POST_POSITION_ARCHIVE_SUCCESS:
      return {
        ...state,
        positionPost: {
          ...state.positionPost,
          isLoading: false,
          response: response,
          //request: request,
        },
      };
    case POST_POSITION_ARCHIVE_FAILURE:
      return {
        ...state,
        positionPost: {
          ...state.positionPost,
          isLoading: false,
          error: true,
          response: error,
          //request: request,
        },
      };
    case POST_POSITION_ARCHIVE_RESET:
      return {
        ...state,
        positionPost: {
          ...state.positionPost,
          isLoading: false,
          error: false,
          response: {},
          //request: request,
        },
      };
    case GET_POSITION_CATEGORIES_REQUEST:
      return {
        ...state,
        positionCategories: {
          ...state.positionCategories,
          isLoading: true,
        },
      };
    case GET_POSITION_CATEGORIES_SUCCESS:
      return {
        ...state,
        positionCategories: {
          ...state.positionCategories,
          isLoading: false,
          response: response,
          request: request,
        },
      };
    case GET_POSITION_CATEGORIES_FAILURE:
      return {
        ...state,
        positionCategories: {
          ...state.positionCategories,
          isLoading: false,
          error: true,
          response: error,
          request: request,
        },
      };
    case POST_POSITION_CATEGORIES_REQUEST:
      return {
        ...state,
        postPositionCategories: {
          ...state.postPositionCategories,
          isLoading: true,
        },
      };
    case POST_POSITION_CATEGORIES_SUCCESS:
      return {
        ...state,
        postPositionCategories: {
          ...state.postPositionCategories,
          isLoading: false,
          response: response,
          request: request,
        },
      };
    case POST_POSITION_CATEGORIES_FAILURE:
      return {
        ...state,
        postPositionCategories: {
          ...state.postPositionCategories,
          isLoading: false,
          error: true,
          response: error,
          request: request,
        },
      };
    default:
      return state;
  }
}

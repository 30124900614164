import React, { Component } from 'react';
import { StyleSheet } from 'aphrodite';
import PropTypes from 'prop-types';

import { Color } from '../config/styles';

import Button from '../components/Button';
import { withTranslation } from 'react-i18next';

/**
 * A tab component that presents information about how Linkedin is used.
 *
 * Example usage:
 *
 * ```
 * <TabLinkedin
 *      onPressSearch={onPressLinkedin}
 * />
 * ```
 *
 */
class TabLinkedin extends Component {
  static propTypes = {
    /**
     * Open Linkedin search URL
     */
    onPressSearch: PropTypes.func.isRequired,
  };

  render() {
    const { t, onPressSearch } = this.props;

    return (
      <div>
        <p style={styles.linkedinText}>{t('tablinkedin-description')}</p>

        <Button onPress={onPressSearch} title={t('tablinkedin-button-search')} />
      </div>
    );
  }
}

export default withTranslation()(TabLinkedin);

const styles = StyleSheet.create({
  linkedinText: {
    color: Color.dark,
    fontSize: 15,
    lineHeight: 20,
    marginBottom: 30,
  },
});

import { createUserManager } from 'redux-oidc';
import {
  authenticateUser as apiAuthenticateUser,
  parseAuth0Hash as apiParseAuth0Hash,
  removeCredentials,
  storeCredentials as apiStoreCredentials,
  getCredentials as apiGetCredentials,
  removeReadPositions,
  removeTidCredentials,
} from '../../api';
import userManager, {
  processTidUserResponse,
  renewAppToken,
  userManagerConfig,
} from '../../config/tidUserManager';
import { CALL_API } from '../middleware/api';

/**
 * Authenticate user
 */
export const AUTHENTICATE_USER_REQUEST = 'AUTHENTICATE_USER_REQUEST';
export const AUTHENTICATE_USER_SUCCESS = 'AUTHENTICATE_USER_SUCCESS';
export const AUTHENTICATE_USER_FAILURE = 'AUTHENTICATE_USER_FAILURE';

export const authenticateUser =
  (
    callbackURL,
    scope,
    connection,
    state = {},
    merge = false,
    waitStoredCredentials = false,
    provider = 'auth0',
  ) =>
  async (dispatch) => {
    await dispatch(
      authenticateAuth0User(callbackURL, scope, connection, state, merge, waitStoredCredentials),
    );
  };

export const authenticateAuth0User =
  (callbackURL, scope, connection, state = {}, merge = false, waitStoredCredentials = false) =>
  async (dispatch) => {
    try {
      dispatch({ type: AUTHENTICATE_USER_REQUEST });

      let credentials = await apiAuthenticateUser(callbackURL, scope, connection, state);
      credentials.expiresAt =
        (credentials.expiresIn || credentials.expires_in) * 1000 + new Date().getTime();

      // Store credentials for later use
      // iOS doesn't have to wait until credentials has been stored. Quicker login.
      if (waitStoredCredentials) {
        await apiStoreCredentials(credentials, merge);
      } else {
        apiStoreCredentials(credentials, merge);
      }

      dispatch(authenticateUserSuccess(credentials));
    } catch (error) {
      dispatch(authenticateUserFailure(error));
    }
  };

export function authenticateUserSuccess(response) {
  return {
    type: AUTHENTICATE_USER_SUCCESS,
    payload: {
      response: response,
    },
  };
}

export function authenticateUserFailure(error) {
  return {
    type: AUTHENTICATE_USER_FAILURE,
    error: true,
    payload: {
      error: error,
    },
  };
}

/**
 * Renew token authentication. Uses Auth0 SSO to get a new token without a refresh token.
 */
export const RENEW_AUTH_REQUEST = 'RENEW_AUTH_REQUEST';
export const RENEW_AUTH_SUCCESS = 'RENEW_AUTH_SUCCESS';
export const RENEW_AUTH_FAILURE = 'RENEW_AUTH_FAILURE';

export const renewAuth = () => async (dispatch) => {
  await dispatch(renewTidAuth());
};

export function renewAuthSuccess(response) {
  return {
    type: RENEW_AUTH_SUCCESS,
    payload: {
      response: response,
    },
  };
}

export function renewAuthFailure(error) {
  return {
    type: RENEW_AUTH_FAILURE,
    error: true,
    payload: {
      error: error,
    },
  };
}

/**
 * Logout user
 */
export const LOGOUT_USER = 'LOGOUT_USER';
export const logoutUser = (props) => async (dispatch) => {
  try {
    dispatch(removeTidCredentials());
    setTimeout(() => {
      removeCredentials();
      removeReadPositions();
    }, 800);

    return {
      type: LOGOUT_USER,
    };
  } catch (error) {
    console.log('logout error', error);
  }
};

/**
 * Fetch the token from hash URL, parse and store it.
 */
export const STORE_CREDENTIALS = 'STORE_CREDENTIALS';
export const storeCredentials = () => async (dispatch) => {
  try {
    await apiParseAuth0Hash().then((credentials) => {
      credentials.expiresAt = credentials.expiresIn * 1000 + new Date().getTime();

      // This needs to be dynamic and come from getUser()
      //credentials.userType = 1;

      apiStoreCredentials(credentials);

      dispatch({
        type: STORE_CREDENTIALS,
        payload: {
          response: credentials,
        },
      });
    });
  } catch (error) {
    console.warn('Error', error);
  }
};

/**
 * get the credentials from storage
 */
export const GET_CREDENTIALS = 'GET_CREDENTIALS';
export const getCredentials = () => async (dispatch) => {
  try {
    let credentials = await apiGetCredentials();

    dispatch({
      type: GET_CREDENTIALS,
      payload: {
        response: credentials,
      },
    });
  } catch (error) {
    console.warn('Error', error);
  }
};

/**
 * Queue redux action API calls while trying to refresh token
 */
export const ADD_CALL_QUEUE = 'ADD_CALL_QUEUE';
export const addCallQueue = (call) => {
  return {
    type: ADD_CALL_QUEUE,
    payload: {
      call: call,
    },
  };
};

/**
 * Empty the queue when token has refreshed and all queued API calls completed
 */
export const CLEAR_CALL_QUEUE = 'CLEAR_CALL_QUEUE';
export const clearCallQueue = () => {
  return {
    type: CLEAR_CALL_QUEUE,
    payload: {},
  };
};

export const authenticateTidUser =
  (email = null, tenantId = null, merge = false, waitStoredCredentials = false) =>
  async (dispatch) => {
    dispatch({ type: AUTHENTICATE_USER_REQUEST });

    try {
      const credentials = processTidUserResponse(await userManager.signinRedirect());
      credentials.expiresAt =
        (credentials.expiresIn || credentials.expires_in) * 1000 + new Date().getTime();
      // Store credentials for later use
      // iOS doesn't have to wait until credentials has been stored. Quicker login.
      if (waitStoredCredentials) {
        await apiStoreCredentials(credentials, merge);
      } else {
        apiStoreCredentials(credentials, merge);
      }

      dispatch(authenticateUserSuccess(credentials));
    } catch (error) {
      //If user comes from the link in the email, set tenantId first before sending
      //to TID login
      console.log(error);
      if (email) {
        if (tenantId) {
          userManagerConfig.acr_values = `userType:Customer tenantId:${tenantId}`;
        }
        const userManager = createUserManager(userManagerConfig);
        userManager.signinRedirect({ login_hint: email });
        return;
      }
      userManager.signinRedirect();
    }
  };

export const renewTidAuth = () => async (dispatch) => {
  try {
    dispatch({ type: RENEW_AUTH_REQUEST });

    const credentials = await renewAppToken();
    if (credentials === undefined) {
      return;
    }
    await apiStoreCredentials(credentials);

    dispatch(renewAuthSuccess(credentials));
  } catch (error) {
    console.log(error);
    dispatch(renewAuthFailure(error));
  }
};

export const FETCH_TALENT_AI_TOKEN_REQUEST = 'FETCH_TALENT_AI_TOKEN_REQUEST';
export const FETCH_TALENT_AI_TOKEN_SUCCESS = 'FETCH_TALENT_AI_TOKEN_SUCCESS';
export const FETCH_TALENT_AI_TOKEN_FAILURE = 'FETCH_TALENT_AI_TOKEN_FAILURE';

export const fetchTalentAIToken = () => {
  return {
    [CALL_API]: {
      endpoint: '/talentai/token',
      method: 'POST',
      authenticated: true,
      types: [
        FETCH_TALENT_AI_TOKEN_REQUEST,
        FETCH_TALENT_AI_TOKEN_SUCCESS,
        FETCH_TALENT_AI_TOKEN_FAILURE,
      ],
    },
  };
};

import BubbleSection from '../components/BubbleSection';
import Button from '../components/Button';
import Footer from '../components/Footer';
import Loader from '../components/Loader';
import LoaderIcon from '../components/icons/Loader';
import MainWrapper from '../components/MainWrapper';
import ProfilePicture from '../components/ProfilePicture';
import EmptyState from '../components/EmptyState';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Color, defaultText, Variables } from '../config/styles';
import { css, StyleSheet } from 'aphrodite';

import ReactHtmlParser from 'react-html-parser';
import iconInfo from '../assets/icons/icon-info.png';
import { withTranslation } from 'react-i18next';

class RecommendedPage extends Component {
  static propTypes = {
    /*
     * Status of loading e.g. getRecommendation
     */
    isLoading: PropTypes.bool.isRequired,
    /*
     * Button: Handles changing status of recommendation and going to next page
     */
    onNotInterested: PropTypes.func.isRequired,
    /*
     * Button: Handles changing status of recommendation and going to next page
     */
    onPressContinue: PropTypes.func.isRequired,
    /*
     * Button: Read all recommendation
     */
    onReadAll: PropTypes.func.isRequired,
    /*
     * Button Handles changing status of recommendation and going to the companypage
     */
    onReadMore: PropTypes.func.isRequired,
    /*
     * Button Handles changing status of recommendation and going to recommend other page
     */
    onRecommendOther: PropTypes.func.isRequired,
    /*
     * Boolean that says to show all content or not
     */
    readAllPosition: PropTypes.bool,
    /*
     * An object containing recommendation about the recommended person
     */
    recommendation: PropTypes.object.isRequired,
  };

  render() {
    const {
      t,
      isLoading,
      onNotInterested,
      onPressContinue,
      onReadAll,
      onReadMore,
      onRecommendOther,
      readAllPosition,
      recommendation,
      isPositionArchived,
    } = this.props;

    if (Object.keys(recommendation).length === 0) {
      return <LoaderIcon />;
    }

    // Set recommendation message to the correct one depending on type of recommendation
    let recommendationMessage;
    if (recommendation.user) {
      if (recommendation.user.usertype === 3) {
        recommendationMessage = t('recommendedpage-section1-external-header');
      } else {
        recommendationMessage = t('recommendedpage-section1-header', {
          name: recommendation.user.givenname,
        });
      }
    } else {
      recommendationMessage = t('recommendedpage-section1-headerAnonymous');
    }

    return (
      <Loader isLoading={isLoading}>
        {isPositionArchived ? (
          <EmptyState
            img="https://storybook.talentech.io/images/svg/logistics/package-attention.svg"
            width="170"
            heading={t('recommendedpage-position-not-found-title')}
            description={t('recommendedpage-position-not-found-description')}
          />
        ) : (
          <MainWrapper className={css(styles.container)}>
            {!readAllPosition ? (
              <div className={css(styles.responsiveWrap)}>
                <header className={css(styles.header)}>
                  <ProfilePicture
                    name={
                      recommendation.user
                        ? recommendation.user.givenname + ' ' + recommendation.user.familyname
                        : null
                    }
                    picture={
                      recommendation.user
                        ? recommendation.user.picture
                        : 'https://res.cloudinary.com/divprvxbc/image/upload/v1533900785/app-icon-round_bn9zp6.png'
                    }
                    imgStyle={styles.profilePicture}
                  />
                </header>
                <BubbleSection
                  displayArrow={true}
                  style={[styles.centerContent, styles.noBottomPadding]}
                >
                  <p>{recommendationMessage}:</p>
                  <h1 className={css(defaultText.h1, styles.h1)}>
                    {recommendation.position.title}
                  </h1>

                  {recommendation.companyimage && (
                    <img src={recommendation.companyimage} className={css(styles.img)} alt="" />
                  )}

                  {recommendation.text && (
                    <p className={css(styles.greeting)}>"{recommendation.text}"</p>
                  )}
                  <Button
                    title={t('recommendedpage-section1-button-onReadAll')}
                    type="linkInline"
                    style={styles.readMoreButton}
                    onPress={onReadAll}
                  />
                </BubbleSection>
              </div>
            ) : (
              // Readall
              <div className={css(styles.responsiveWrap)}>
                <header className={css(styles.header)}>
                  <h1 className={css(defaultText.h1, styles.readAllH1)}>
                    {recommendation.position.title}
                  </h1>
                </header>
                <BubbleSection style={styles.readallContent}>
                  {recommendation.companyimage && (
                    <img
                      src={recommendation.companyimage}
                      className={css(styles.logoType)}
                      alt=""
                    />
                  )}
                  <h3 className={css([styles.h3, styles.h3margin])}>
                    {t('recommendedpage-section1-onReadAll-description')}
                  </h3>
                  {ReactHtmlParser(recommendation.position.text)}

                  <hr className={css(styles.hr)} />
                </BubbleSection>
              </div>
            )}
            <div className={css(styles.responsiveWrap)}>
              <BubbleSection style={styles.centerContent}>
                <p>{t('recommendedpage-section2-header')}</p>
                <Button
                  type="primary"
                  title={t('recommendedpage-section2-button-onPressContinue')}
                  onPress={onPressContinue}
                  style={styles.continueButton}
                />
                <Button
                  type="secondary"
                  title={t('recommendedpage-section2-button-onRecommendOther')}
                  onPress={onRecommendOther}
                  style={styles.buttonRecommendOther}
                />
                <Button
                  type="secondary"
                  title={t('recommendedpage-section2-button-onNotInterested')}
                  onPress={onNotInterested}
                  style={styles.continueButton}
                />
                <Button
                  type="linkInline"
                  title={t('recommendedpage-section2-button-onReadMore', {
                    name: recommendation.companyname,
                  })}
                  onPress={onReadMore}
                  style={styles.buttonReadMore}
                />
                <p className={css(styles.subText)}>{t('recommendedpage-section2-footer')}</p>
                <div className={css(styles.contactSection)}>
                  <img src={iconInfo} className={css(styles.i)} alt="" />
                  <p>{t('recommendedpage-section1-onReadAll-footer-knowMore')}</p>
                  <p>
                    {t('recommendedpage-section1-onReadAll-footer-description', {
                      name: recommendation.position.contactname,
                    })}
                  </p>
                  <p>
                    {t('recommendedpage-section1-onReadAll-footer-info', {
                      email: recommendation.position.contactmail,
                      phone: recommendation.position.contacttelephone,
                    })}
                  </p>
                </div>
              </BubbleSection>
            </div>
            <Footer text={t('footer-text')} />
          </MainWrapper>
        )}
      </Loader>
    );
  }
}

export default withTranslation()(RecommendedPage);

const styles = StyleSheet.create({
  container: {
    minHeight: 'calc(100vh - 9.8rem)',
  },
  greeting: {
    fontStyle: 'italic',
  },
  subText: {
    fontSize: '.75em',
    lineHeight: '1.5',
    margin: '1.5em 0 1em',
  },
  readAllPosition: {
    display: 'none',
  },
  readAllPositionTrue: {
    display: 'block',
  },
  responsiveWrap: {
    maxWidth: 700,
    margin: '0 auto',
  },
  header: {
    minHeight: '7.5625em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  h1: {
    color: Color.primary,
    margin: '0.3em 0 0.8em',
  },
  img: {
    height: 32,
    marginBottom: 10,
  },
  readAllH1: {
    color: Color.primary,
    marginBottom: 0,
  },
  h3: {
    background: Color.purple,
    color: Color.primary,
    fontSize: '.6875em',
    padding: '1.272727273em',
    textTransform: 'uppercase',
    fontWeight: 'normal',
    textAlign: 'center',
    width:
      'calc(100% + ' +
      Variables.BubbleSectionPadding +
      ' + ' +
      Variables.BubbleSectionPadding +
      ')',
    marginLeft: '-' + Variables.BubbleSectionPadding,
    marginRight: '-' + Variables.BubbleSectionPadding,
  },
  h3margin: {
    marginTop: 16,
    marginBottom: 16,
  },
  h4: {
    fontSize: '.8125em',
    color: Color.darkestBlue,
    fontWeight: 'normal',
    margin: '0.5em 0',
    width: '100%',
    textTransform: 'uppercase',
    letterSpacing: 1.86,
  },
  ul: {
    width: '100%',
  },
  hr: {
    width:
      'calc(100% + ' +
      Variables.BubbleSectionPadding +
      ' + ' +
      Variables.BubbleSectionPadding +
      ')',
    marginLeft: '-' + Variables.BubbleSectionPadding,
    marginRight: '-' + Variables.BubbleSectionPadding,
    borderColor: '#F5F8FA',
    borderWidth: 1,
    borderStyle: 'solid',
  },
  contactSection: {
    textAlign: 'center',
    fontSize: '.8em',
    marginTop: '2em',
  },
  i: {
    width: '2em',
    height: '2em',
  },
  centerContent: {
    textAlign: 'center',
    padding: Variables.BubbleSectionPadding,
  },
  readallContent: {
    padding: Variables.BubbleSectionPadding,
    whiteSpace: 'pre-line',
  },
  logoType: {
    height: 70,
  },
  buttonReadMore: {
    fontSize: '14px',
    margin: '1.75em 0 1.34375em 0',
  },
  profilePicture: {
    marginTop: '1.5em',
  },
  readMoreButton: {
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
    marginTop: 16,
    padding: '15px 0',
    borderTop: `1px solid ${Color.border}`,
    width: 'calc(100% + 3rem)',
  },
  noBottomPadding: {
    paddingBottom: 0,
  },
});

/* eslint-disable no-template-curly-in-string */
import moment from 'moment';
import { ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';

import { copyDeep } from './utils';

// Add templates that will be shown in the entire communication page
const VALID_TEMPLATE_TYPES = {
  teaserEmail: 'teaser-email',
  inviteUser: 'invite-user',
  firstUpdate: 'first-update',
  registrationFollowUp: 'registration-follow-up',
  update: 'update',
  individualCompetitionStart: 'individual-competition-start',
  individualCompetitionEnd: 'individual-competition-end',
  teamCompetitionStart: 'team-competition-start',
  teamCompetitionEnd: 'team-competition-end',
  adminCompetitionReminder: 'admin-competition-reminder',
  adminHireReminder: 'admin-hire-reminder',
  adminTips: 'admin-tips',
  adminCollectiveGoalReached: 'admin-collective-goal-reached',
  adminNoContactInfo: 'admin-no-contact-info',
  adminNewReferral: 'admin-new-referral',
  adminNewReferralInterest: 'admin-new-referral-interest',
  referringUserUpdateInterest: 'referring-user-update-interest',
  referringUserUpdateInterview: 'referring-user-update-interview',
  referringUserUpdateHire: 'referring-user-update-hire',
  inviteAdmin: 'invite-admin',
  userHintsAndAdvice: 'user-hints-and-advice',
  vacantPositionUpdate: 'vacant-position-update',
  vacantPositionReminder: 'vacant-position-reminder',
  highScoreUpdate: 'high-score-update',
  referralContact: 'referral-contact',
  firstReferralHire: 'first-referral-hire',
  linkedinInspiration: 'linkedin-inspiration',
  referralNewRecommendation: 'referral-new-recommendation',
  referralNewRecommendationReminder: 'referral-new-recommendation-reminder',
  referralNewRecommendationAnonymous: 'referral-new-recommendation-anonymous',
  referralNewRecommendationAnonymousReminder: 'referral-new-recommendation-anonymous-reminder',
};

const LAUNCH_TYPES = [
  VALID_TEMPLATE_TYPES.teaserEmail,
  VALID_TEMPLATE_TYPES.inviteUser,
  VALID_TEMPLATE_TYPES.firstUpdate,
];

const RECURRING_TYPES = [
  VALID_TEMPLATE_TYPES.update,
  VALID_TEMPLATE_TYPES.adminTips,
  VALID_TEMPLATE_TYPES.highScoreUpdate,
];
export const TEMPLATES_HAS_ONLY_PUSH_TYPE = [VALID_TEMPLATE_TYPES.referralContact];

export const TEMPLATES_HAS_EMAIL_AND_PUSH_TYPE = [
  VALID_TEMPLATE_TYPES.firstReferralHire,
  VALID_TEMPLATE_TYPES.vacantPositionUpdate,
  VALID_TEMPLATE_TYPES.vacantPositionReminder,
  VALID_TEMPLATE_TYPES.linkedinInspiration,
];

export const TEMPLATES_HAS_EMAIL_AND_SMS_TYPE = [
  VALID_TEMPLATE_TYPES.referralNewRecommendation,
  VALID_TEMPLATE_TYPES.referralNewRecommendationReminder,
  VALID_TEMPLATE_TYPES.referralNewRecommendationAnonymous,
  VALID_TEMPLATE_TYPES.referralNewRecommendationAnonymousReminder,
];

export const TEMPLATES_HAS_ONLY_EMAIL_TYPE = [
  VALID_TEMPLATE_TYPES.teaserEmail,
  VALID_TEMPLATE_TYPES.inviteUser,
  VALID_TEMPLATE_TYPES.firstUpdate,
  VALID_TEMPLATE_TYPES.registrationFollowUp,
  VALID_TEMPLATE_TYPES.update,
  VALID_TEMPLATE_TYPES.individualCompetitionStart,
  VALID_TEMPLATE_TYPES.individualCompetitionEnd,
  VALID_TEMPLATE_TYPES.teamCompetitionStart,
  VALID_TEMPLATE_TYPES.teamCompetitionEnd,
  VALID_TEMPLATE_TYPES.adminCompetitionReminder,
  VALID_TEMPLATE_TYPES.adminHireReminder,
  VALID_TEMPLATE_TYPES.adminTips,
  VALID_TEMPLATE_TYPES.adminCollectiveGoalReached,
  VALID_TEMPLATE_TYPES.adminNoContactInfo,
  VALID_TEMPLATE_TYPES.adminNewReferral,
  VALID_TEMPLATE_TYPES.adminNewReferralInterest,
  VALID_TEMPLATE_TYPES.referringUserUpdateInterest,
  VALID_TEMPLATE_TYPES.referringUserUpdateInterview,
  VALID_TEMPLATE_TYPES.referringUserUpdateHire,
  VALID_TEMPLATE_TYPES.inviteAdmin,
  VALID_TEMPLATE_TYPES.userHintsAndAdvice,
  VALID_TEMPLATE_TYPES.vacantPositionUpdate,
  VALID_TEMPLATE_TYPES.vacantPositionReminder,
  VALID_TEMPLATE_TYPES.highScoreUpdate,
];

// Order of templates in the communication page
const TEMPLATE_ORDER = [
  VALID_TEMPLATE_TYPES.teaserEmail,
  VALID_TEMPLATE_TYPES.inviteUser,
  VALID_TEMPLATE_TYPES.registrationFollowUp,
  VALID_TEMPLATE_TYPES.update,
  VALID_TEMPLATE_TYPES.individualCompetitionStart,
  VALID_TEMPLATE_TYPES.individualCompetitionEnd,
  VALID_TEMPLATE_TYPES.teamCompetitionStart,
  VALID_TEMPLATE_TYPES.teamCompetitionEnd,
  VALID_TEMPLATE_TYPES.adminCompetitionReminder,
  VALID_TEMPLATE_TYPES.adminCollectiveGoalReached,
  VALID_TEMPLATE_TYPES.highScoreUpdate,
  VALID_TEMPLATE_TYPES.adminHireReminder,
  VALID_TEMPLATE_TYPES.firstReferralHire,
  VALID_TEMPLATE_TYPES.referralContact,
  VALID_TEMPLATE_TYPES.referringUserUpdateInterest,
  VALID_TEMPLATE_TYPES.referringUserUpdateInterview,
  VALID_TEMPLATE_TYPES.referringUserUpdateHire,
  VALID_TEMPLATE_TYPES.referralNewRecommendation,
  VALID_TEMPLATE_TYPES.referralNewRecommendationReminder,
  VALID_TEMPLATE_TYPES.referralNewRecommendationAnonymous,
  VALID_TEMPLATE_TYPES.referralNewRecommendationAnonymousReminder,
  VALID_TEMPLATE_TYPES.adminNewReferral,
  VALID_TEMPLATE_TYPES.adminNoContactInfo,
  VALID_TEMPLATE_TYPES.adminNewReferralInterest,
  VALID_TEMPLATE_TYPES.vacantPositionUpdate,
  VALID_TEMPLATE_TYPES.vacantPositionReminder,
  VALID_TEMPLATE_TYPES.userHintsAndAdvice,
  VALID_TEMPLATE_TYPES.adminTips,
  VALID_TEMPLATE_TYPES.linkedinInspiration,
  VALID_TEMPLATE_TYPES.inviteAdmin,
];

export const extractTemplateType = (templateName) => {
  if (!templateName) return '';
  return templateName.endsWith('nw') ? templateName.slice(0, -6) : templateName.slice(0, -3);
};

export const isCustomerLaunched = (customer) => {
  const launchTimeStamp = customer?.launch_date;
  return launchTimeStamp * 1000 <= Date.now();
};

export const fetchUserInvitation = (onDemandEmails) => {
  return onDemandEmails.find((item) => item.template_type === 'invite-user');
};

export const fetchAdminInvitation = (onDemandEmails) => {
  return onDemandEmails.find((item) => item.template_type === 'invite-admin');
};

export const fetchArchivedLaunchIntervals = (intervals) => {
  return intervals
    .filter(
      (x) =>
        x.sent === true &&
        x.launch &&
        x.interval === 0 &&
        isCorrectArchivedCommunication(extractTemplateType(x.template_unique_name)) &&
        isValidCommunicationType(x.template_unique_name),
    )
    .sort((x, y) => x.timestamp - y.timestamp);
};

export const fetchOnlyPushNotificationTypes = (pushNotifications) => {
  return pushNotifications.filter((x) => isOnlyPushType(x.template_type));
};

export const fetchRecurringIntervals = (intervals) => {
  return intervals.filter(
    (x) =>
      isRecurringInterval(extractTemplateType(x.template_unique_name)) &&
      isValidCommunicationType(x.template_unique_name) &&
      !x.sent,
  );
};

export const fetchEmailsInvitesExcluded = (onDemandEmails) => {
  return onDemandEmails.filter(
    (x) =>
      x.on_demand &&
      x.template_type !== `invite-user` &&
      x.template_type !== `invite-admin` &&
      isValidCommunicationType(x.template_unique_name),
  );
};

export const addLaunchTimestampForSorting = (customer, inviteEmail) => {
  const date = new Date(customer?.launch_date * 1000);
  const newInviteEmail = { ...inviteEmail };

  newInviteEmail['timestamp'] = Number(moment(date).format('X')); // Required for sorting
  return newInviteEmail;
};

export const fetchTeaserAndFollowup = (intervals) => {
  return intervals.filter(
    (x) =>
      x.sent === false &&
      x.launch === true &&
      isValidCommunicationType(x.template_unique_name) &&
      !isRecurringInterval(extractTemplateType(x.template_unique_name)), // We should show recurring update and admin tips on other communication section
  );
};

export const isLaunchCommunicationType = (templateName) =>
  LAUNCH_TYPES.findIndex((item) => item === templateName) > -1;

export const isValidCommunicationType = (templateType) =>
  Object.values(VALID_TEMPLATE_TYPES).findIndex(
    (item) => item === extractTemplateType(templateType),
  ) > -1;

export const isEmailAndPushType = (templateType) =>
  TEMPLATES_HAS_EMAIL_AND_PUSH_TYPE.findIndex((item) => item === templateType) > -1;

export const isEmailAndSmsType = (templateType) =>
  TEMPLATES_HAS_EMAIL_AND_SMS_TYPE.findIndex((item) => item === templateType) > -1;

export const isOnlyPushType = (templateType) =>
  TEMPLATES_HAS_ONLY_PUSH_TYPE.findIndex((item) => item === templateType) > -1;

//Checks if the initial form values are equal to current form values
//Does a deep equality check i.e. checks equality of nested arrays and objects
export const isFieldChanged = (formState, newState) => {
  const initialValues = Object.values(copyDeep(formState.initialState));
  const currentValues = Object.values(copyDeep(newState));

  for (let i = 0; i < currentValues.length; i++) {
    const el = Array.isArray(currentValues[i])
      ? currentValues[i].sort().join('')
      : currentValues[i];

    const initialEl =
      initialValues[i] === undefined
        ? ''
        : Array.isArray(initialValues[i])
        ? initialValues[i].sort().join('')
        : initialValues[i];

    if (el !== initialEl) return true;
  }

  return false;
};

export const getContentState = (html) => {
  const blocksFromHtml = htmlToDraft(html);
  const { contentBlocks, entityMap } = blocksFromHtml;
  const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

  return contentState;
};

export const getSortedOtherCommunication = (templates, custLang) => {
  const orderedTemplates = [];

  TEMPLATE_ORDER.forEach((template) => {
    const index = templates.findIndex(
      (item) =>
        item.template_type === template ||
        (!Boolean(item.template_type) && item.template_unique_name === `${template}-${custLang}`),
    );
    if (index > -1) orderedTemplates.push(templates[index]);
  });

  return orderedTemplates;
};

export const isCorrectArchivedCommunication = (template_unique_name, custLang) => {
  const invalidTypesForArchivedSection = [
    `launch-email-${custLang}`,
    `launch-email-${custLang}-nw`,
    `launch-email-nocomp-${custLang}`,
    `first-update-nocomp-${custLang}-nw`,
    `update-${custLang}`,
    `update-${custLang}-nw`,
    `update-nocomp-${custLang}`,
    `update-nocomp-${custLang}-nw`,
    `high-score-update-${custLang}`,
    `admin-tips-${custLang}`,
    'Customized',
  ];

  return invalidTypesForArchivedSection.findIndex((item) => item === template_unique_name) === -1;
};

export const isRecurringInterval = (templateType) =>
  RECURRING_TYPES.findIndex((item) => item === templateType) > -1;

/**
 * If launch date is not set and communication is launch type show placeholder texts
 * Show placeholders for on demand types
 */
export const fetchActionKey = (templateType, isLaunchType, isLaunchDateSet) => {
  if (isRecurringInterval(templateType)) {
    return 'communication-action-scheduled-frequency';
  }

  if (!isLaunchDateSet && isLaunchType) {
    switch (templateType) {
      case VALID_TEMPLATE_TYPES.teaserEmail:
        return 'communication-before-launch';
      case VALID_TEMPLATE_TYPES.inviteUser:
        return 'communication-launch-day';
      case VALID_TEMPLATE_TYPES.firstUpdate:
        return 'communication-action-after-launch';
      default:
        return 'generic-text-loading';
    }
  }

  switch (templateType) {
    case VALID_TEMPLATE_TYPES.inviteUser:
    case VALID_TEMPLATE_TYPES.inviteAdmin:
      return 'communication-action-on-creation';
    case VALID_TEMPLATE_TYPES.registrationFollowUp:
      return 'communication-action-on-registration';
    case VALID_TEMPLATE_TYPES.individualCompetitionStart:
    case VALID_TEMPLATE_TYPES.teamCompetitionStart:
      return 'communication-action-competition-start';
    case VALID_TEMPLATE_TYPES.individualCompetitionEnd:
    case VALID_TEMPLATE_TYPES.teamCompetitionEnd:
      return 'communication-action-competition-end';
    case VALID_TEMPLATE_TYPES.adminCollectiveGoalReached:
      return 'communication-action-on-reach';
    case VALID_TEMPLATE_TYPES.adminCompetitionReminder:
      return 'communication-action-competition-reminder';
    case VALID_TEMPLATE_TYPES.adminHireReminder:
    case VALID_TEMPLATE_TYPES.referringUserUpdateHire:
      return 'communication-action-on-hiring';
    case VALID_TEMPLATE_TYPES.referringUserUpdateInterest:
    case VALID_TEMPLATE_TYPES.adminNewReferralInterest:
      return 'communication-action-on-response';
    case VALID_TEMPLATE_TYPES.referringUserUpdateInterview:
      return 'communication-action-after-interview';
    case VALID_TEMPLATE_TYPES.adminNoContactInfo:
    case VALID_TEMPLATE_TYPES.adminNewReferral:
    case VALID_TEMPLATE_TYPES.referralNewRecommendation:
    case VALID_TEMPLATE_TYPES.referralNewRecommendationAnonymous:
      return 'communication-action-on-referral';
    case VALID_TEMPLATE_TYPES.userHintsAndAdvice:
      return 'communication-action-two-weeks-after-activation';
    case VALID_TEMPLATE_TYPES.vacantPositionReminder:
      return 'communication-action-scheduled-for-position';
    case VALID_TEMPLATE_TYPES.vacantPositionUpdate:
      return 'communication-action-on-publishing-position';
    case VALID_TEMPLATE_TYPES.firstReferralHire:
      return 'communication-action-on-first-hire';
    case VALID_TEMPLATE_TYPES.referralContact:
      return 'communication-action-on-contact';
    case VALID_TEMPLATE_TYPES.linkedinInspiration:
      return 'communication-action-three-weeks-after-launch';
    case VALID_TEMPLATE_TYPES.referralNewRecommendationReminder:
    case VALID_TEMPLATE_TYPES.referralNewRecommendationAnonymousReminder:
      return 'communication-action-four-days-after-referral';
    // return 'communication-action-on-recommendation';

    default:
      return 'generic-text-loading';
  }
};

/**
 * If launch date is set and communication is launch type show launch date
 */
export const fetchLaunchTimes = (communicationItem, templateType) =>
  `${moment
    .unix(communicationItem.timestamp)
    .format(`YYYY-MM-DD ${templateType === 'invite-user' ? '08:45' : 'HH:mm'}`)} CET`;

export const fetchTargetKey = (templateType) => {
  switch (templateType) {
    case VALID_TEMPLATE_TYPES.inviteUser:
      return 'communication-target-regular';
    case VALID_TEMPLATE_TYPES.inviteAdmin:
      return 'communication-target-admins';
    case VALID_TEMPLATE_TYPES.registrationFollowUp:
    case VALID_TEMPLATE_TYPES.linkedinInspiration:
      return 'communication-target-registered-users';
    case VALID_TEMPLATE_TYPES.adminCompetitionReminder:
    case VALID_TEMPLATE_TYPES.adminTips:
    case VALID_TEMPLATE_TYPES.adminCollectiveGoalReached:
      return 'communication-target-admin';
    case VALID_TEMPLATE_TYPES.referringUserUpdateInterest:
    case VALID_TEMPLATE_TYPES.referringUserUpdateInterview:
    case VALID_TEMPLATE_TYPES.referringUserUpdateHire:
    case VALID_TEMPLATE_TYPES.referralContact:
      return 'communication-target-referring-user';
    case VALID_TEMPLATE_TYPES.adminNoContactInfo:
    case VALID_TEMPLATE_TYPES.adminNewReferral:
    case VALID_TEMPLATE_TYPES.adminNewReferralInterest:
    case VALID_TEMPLATE_TYPES.adminHireReminder:
      return 'communication-target-position-owner';
    case VALID_TEMPLATE_TYPES.referralNewRecommendation:
    case VALID_TEMPLATE_TYPES.referralNewRecommendationReminder:
    case VALID_TEMPLATE_TYPES.referralNewRecommendationAnonymous:
    case VALID_TEMPLATE_TYPES.referralNewRecommendationAnonymousReminder:
      return 'communication-target-referral';
    default:
      return 'communication-target-all';
  }
};

export const fetchChannelKey = (templateType) => {
  if (TEMPLATES_HAS_ONLY_PUSH_TYPE.findIndex((item) => item === templateType) > -1)
    return 'communication-item-channel-push';

  if (TEMPLATES_HAS_EMAIL_AND_PUSH_TYPE.findIndex((item) => item === templateType) > -1)
    return 'communication-item-channel-all';

  if (TEMPLATES_HAS_EMAIL_AND_SMS_TYPE.findIndex((item) => item === templateType) > -1)
    return 'communication-item-channel-email-and-sms';

  return 'communication-item-channel-email';
};

export const fetchMailVariableList = (t) => [
  { title: t('variables-positions'), text: '${positions}' },
  {
    title: t('variables-indi-reward'),
    text: '${indi_reward}',
  },
  {
    title: t('variables-team-reward'),
    text: '${team_reward}',
  },
  {
    title: t('variables-goal-reward'),
    text: '${goal_reward}',
  },
  {
    title: t('variables-goal-points'),
    text: '${goal_points}',
  },
  {
    title: t('variables-indi-end'),
    text: '${indi_end}',
  },
  {
    title: t('variables-team-end'),
    text: '${team_end}',
  },
  {
    title: t('variables-leader-team'),
    text: '${leader_team}',
  },
  {
    title: t('variables-second-team'),
    text: '${second_team}',
  },
  {
    title: t('variables-third-team'),
    text: '${third_team}',
  },
  {
    title: t('variables-indi-leader'),
    text: '${indi_leader}',
  },
  {
    title: t('variables-second-indi'),
    text: '${second_indi}',
  },
  {
    title: t('variables-third-indi'),
    text: '${third_indi}',
  },
  {
    title: t('variables-max-reward'),
    text: '${max_reward}',
  },
  {
    title: t('variables-max-reward-currency'),
    text: '${max_reward_currency}',
  },
  {
    title: t('variables-sender'),
    text: '${sender}',
  },
  {
    title: t('variables-name'),
    text: '${name}',
  },
  {
    title: t('variables-invite-link'),
    text: '${invite_link}',
  },
  {
    title: t('variables-get-started'),
    text: '${get_started_button}',
  },
  {
    title: t('variables-qr-code-link'),
    text: '${qr_link}',
  },
  {
    title: t('variables-appstore-link'),
    text: '${app_store_image_link}',
  },
  {
    title: t('variables-google-play-link'),
    text: '${google_play_image_link}',
  },
  {
    title: t('variables-organization-name'),
    text: '${organization_name}',
  },
  {
    title: t('variables-referred-candidate-name'),
    text: '${candidate_name}',
  },
  {
    title: t('variables-reward'),
    text: '${reward}',
  },
  {
    title: t('variables-no-of-referrals'),
    text: '${no_of_referrals}',
  },
  {
    title: t('variables-no-of-invites'),
    text: '${total_no_of_users}',
  },
  {
    title: t('variables-no-of-activated-users'),
    text: '${no_of_registered}',
  },
  {
    title: t('variables-activation-rate'),
    text: '${activation_rate}',
  },
  {
    title: t('variables-team-with-most-activated-users'),
    text: '${team_with_most_activated_users}',
  },
  {
    title: t('variables-go-to-irecommend'),
    text: '${go_to_irecommend}',
  },
];

export const fetchSmsVariableList = (t) => [
  {
    title: t('variables-referred-candidate-name'),
    text: '${candidate_name}',
  },
  {
    title: t('variables-referrer-name'),
    text: '${referrer_name}',
  },
  {
    title: t('variables-position-name'),
    text: '${position_name}',
  },
  {
    title: t('variables-organization-name'),
    text: '${organization_name}',
  },
  {
    title: t('variables-sender'),
    text: '${sender}',
  },
];

export const isValidSmsData = (data, t) => {
  if (data.template_body === '') return alert(t('communicationeditcontainer-validation-content'));

  return true;
};

export const isValidData = (data, t) => {
  console.log('data', data);
  if (data.subject === '') return alert(t('communicationeditcontainer-validation-subject'));

  if (data.template_HTML === '' || data.template_HTML === '<p><br></p>')
    return alert(t('communicationeditcontainer-validation-content'));

  if (
    (!data.isOnDemand && data.timestamp < Math.floor(Date.now() / 1000)) ||
    (!data.timestamp && !data.isOnDemand)
  )
    return alert(t('communicationeditcontainer-validation-timestamp'));

  return true;
};

// Show variables in preview in the format ${variable_name} when no values are available
export const formatVariablesForPreview = (content, templateName) => {
  const variablesFromCommService = [
    'leader_indi',
    'indi_reward',
    'second_indi',
    'third_indi',
    'fourth_indi',
    'fifth_indi',
    'team_name',
    'team_reward',
    'leader_team',
    'leader_team_points',
    'second_team',
    'third_team',
    'last_team',
    'team_end',
    'indi_end',
    'total_collective_points',
    'candidate_name',
    'referrer_name',
    'position_name',
  ];

  const isAdmiNoContactInfo = extractTemplateType(templateName) === 'admin-no-contact-info';
  const isAdmiNewReferralInterest =
    extractTemplateType(templateName) === 'admin-new-referral-interest';
  let modifiedText = content;

  variablesFromCommService.forEach((element) => {
    const regex = new RegExp(element, 'g');
    modifiedText = modifiedText.replace(regex, `$\{${element}}`);
  });

  // Find the get started button and and add pointer-events: none; to its style
  // TODO: Make this more generic
  const getStartedButtonLink =
    '<a href="%recipient.invitelink%" style="display: inline-block; background: white; color: #ffffff; font-family: \'Open Sans\', Frutiger, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: bold; line-height: 120%; margin: 0; text-decoration: none; text-transform: uppercase; padding: 10px 25px; mso-padding-alt: 0px; border-radius: 4px; cursor: pointer;" target="_blank">GET STARTED</a>';
  const startIndexGetStartedButton = modifiedText.indexOf(getStartedButtonLink);
  const endIndexGetStartedButton = startIndexGetStartedButton + getStartedButtonLink.length;
  modifiedText =
    startIndexGetStartedButton > -1
      ? modifiedText.substring(0, startIndexGetStartedButton) +
        '<a href="%recipient.invitelink%" style="display: inline-block; background: white; color: #ffffff; font-family: \'Open Sans\', Frutiger, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: bold; line-height: 120%; margin: 0; text-decoration: none; text-transform: uppercase; padding: 10px 25px; mso-padding-alt: 0px; border-radius: 4px; cursor: pointer; pointer-events: none;">GET STARTED</a>' +
        modifiedText.substring(endIndexGetStartedButton)
      : modifiedText;

  // Extra variable that are not standard in comm service
  // Open all links in new tab since the preview is in an iframe
  let replacedText = modifiedText
    .replace(new RegExp('%recipient.first%', 'g'), '${name}')
    .replace(new RegExp('<a ', 'g'), '<a target="_blank" ');

  // Remove the link from the text and replace it with the variable for certain templates
  // This is done because the link is not available in the preview
  if (isAdmiNoContactInfo || isAdmiNewReferralInterest) {
    const positionLink = `<a target="_blank" href="https://referrals.talentech.io/admin/jobs/position/id_position">${
      isAdmiNoContactInfo ? '(${position_name})' : '${position_name}'
    }.</a>`;

    const startIndexPositionLink = replacedText.indexOf(positionLink);
    const endIndexPositionLink = startIndexPositionLink + positionLink.length;

    replacedText =
      startIndexPositionLink > -1
        ? replacedText.substring(0, startIndexPositionLink) +
          '${position_name}' +
          replacedText.substring(endIndexPositionLink)
        : replacedText;
  }

  return replacedText;
};

export const fetchFrequencyOptions = (t) => [
  { label: t('generic-intervall-number1'), value: 1 },
  { label: t('generic-intervall-number2'), value: 2 },
  { label: t('generic-intervall-number3'), value: 3 },
  { label: t('generic-intervall-number4'), value: 4 },
  { label: t('generic-intervall-number5'), value: 5 },
  { label: t('generic-intervall-number6'), value: 6 },
  { label: t('generic-intervall-number8'), value: 8 },
  { label: t('generic-intervall-number12'), value: 12 },
  { label: t('generic-intervall-number16'), value: 16 },
  { label: t('generic-intervall-number0'), value: 0 },
];

export const hourOptions = [
  { label: '00', value: 0 },
  { label: '01', value: 1 },
  { label: '02', value: 2 },
  { label: '03', value: 3 },
  { label: '04', value: 4 },
  { label: '05', value: 5 },
  { label: '06', value: 6 },
  { label: '07', value: 7 },
  { label: '08', value: 8 },
  { label: '09', value: 9 },
  { label: '10', value: 10 },
  { label: '11', value: 11 },
  { label: '12', value: 12 },
  { label: '13', value: 13 },
  { label: '14', value: 14 },
  { label: '15', value: 15 },
  { label: '16', value: 16 },
  { label: '17', value: 17 },
  { label: '18', value: 18 },
  { label: '19', value: 19 },
  { label: '20', value: 20 },
  { label: '21', value: 21 },
  { label: '22', value: 22 },
  { label: '23', value: 23 },
];

export const minuteOptions = [
  { label: '00', value: 0 },
  { label: '15', value: 15 },
  { label: '30', value: 30 },
  { label: '45', value: 45 },
];

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleUserAdmin } from '../../irecommend-lib/actions/userActions';
import { getUsers } from '../../irecommend-lib/actions/userActions';

const useToggleUserAdmin = () => {
  const { userType } = useSelector((state) => state.userState);
  const dispatch = useDispatch();

  const handleToggleUserAdmin = (user) => {
    dispatch(toggleUserAdmin(user));
  };

  useEffect(() => {
    if (userType.response.status === 200) {
      dispatch(getUsers());
      dispatch({ type: 'MAKE_USER_ADMIN_RESET' });
    }
  }, [userType.response, dispatch]);

  return { handleToggleUserAdmin, toggleIsLoading: userType.isLoading };
};

export default useToggleUserAdmin;

import { Spinner } from '@talentech/components';
import { StyleSheet, css } from 'aphrodite';
import { useTranslation } from 'react-i18next';

const TransitionScreen = () => {
  const { t } = useTranslation();
  return (
    <div className={css(styles.settingsNoCategoryWrap)}>
      <img
        src="https://storybook.talentech.io/images/svg/sciene-technology/rocket.svg"
        alt="Almost there"
        width="150"
      />
      <h1 className={css(styles.settingsNoCategoriesHeading)}>{t('callback-almost-there')}</h1>
      <p className={css(styles.settingsNoCategoriesText)}>
        {t('callback-almost-there-description')}
      </p>
      <Spinner size="lg" />
    </div>
  );
};

export default TransitionScreen;

const styles = StyleSheet.create({
  settingsNoCategoryWrap: {
    display: 'flex',
    height: '100vh',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  settingsNoCategoriesHeading: {
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: '1rem',
    marginBottom: '1rem',
    fontSize: '2rem',
  },
  settingsNoCategoriesText: {
    textAlign: 'center',
    fontSize: '.9rem',
    marginBottom: '4rem',
  },
});

import {
  GET_USERFLOW_SIGNATURES_REQUEST,
  GET_USERFLOW_SIGNATURES_SUCCESS,
  GET_USERFLOW_SIGNATURES_FAILURE,
  GET_USERFLOW_SIGNATURES_INVALIDATE,
} from '../actions/userflowActions';

const initialState = {
  userflowCustomer: {
    didInvalidate: false,
    error: false,
    isLoading: false,
    response: {},
    request: null, // axios request response object
  },
  userflowSignatures: {
    didInvalidate: false,
    error: false,
    isLoading: false,
    response: {},
    request: null, // axios request response object
  },
};

export default function userflowState(state = initialState, { error, response, type, request }) {
  switch (type) {
    case GET_USERFLOW_SIGNATURES_REQUEST:
      return {
        ...state,
        userflowSignatures: {
          ...state.userflowSignatures,
          isLoading: true,
        },
      };
    case GET_USERFLOW_SIGNATURES_SUCCESS:
      return {
        ...state,
        userflowSignatures: {
          ...state.userflowSignatures,
          isLoading: false,
          response: response,
          request: request,
        },
      };
    case GET_USERFLOW_SIGNATURES_FAILURE:
      return {
        ...state,
        userflowSignatures: {
          ...state.userflowSignatures,
          isLoading: false,
          error: true,
          response: error,
          request: request,
        },
      };
    case GET_USERFLOW_SIGNATURES_INVALIDATE:
      return {
        ...state,
        userflowSignatures: {
          ...state.userflowSignatures,
          didInvalidate: true,
        },
      };

    default:
      return state;
  }
}

export const isMatchingFilter = (user, currentFilter) => {
  return (
    currentFilter === FILTER_TYPES.all ||
    (currentFilter === FILTER_TYPES.admins && user.usertype > 0) ||
    (currentFilter === FILTER_TYPES.activatedUsers && user.registered) ||
    (currentFilter === FILTER_TYPES.notActivatedUsers && !user.registered)
  );
};

export const isMatchingSearch = (user, searchTerm) => {
  return (
    user.givenname.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.familyname.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.mail.toLowerCase().includes(searchTerm.toLowerCase())
  );
};

export const FILTER_TYPES = {
  all: 'all',
  activatedUsers: 'activatedUsers',
  admins: 'admins',
  notActivatedUsers: 'notActivatedUsers ',
};

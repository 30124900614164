import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { css, StyleSheet } from 'aphrodite';

import Button from '../components/Button';
import Loader from '../components/Loader';
import ProfilePicture from '../components/ProfilePicture';
import TransitionScreen from '../components/TransitionScreen';

import { getUser, getUserInvalidate } from '../irecommend-lib/actions/userActions';
import { logoutUser } from '../irecommend-lib/actions/authActions';

import { Color } from '../config/styles';
import { storeCredentials } from '../api';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

const RegisterPrivacyPolicyPage = (props) => {
  const [policy, setPolicy] = useState({
    privacyPolicyUrl: 'https://communication.irecommend.se/policy/privacy-policy-en.html',
  });
  const mobile = window.innerWidth < 700;
  const { history, user } = props;
  const { t } = useTranslation();
  const isLoading = user.user?.registered;
  const credentials = JSON.parse(localStorage.getItem('@irecommend:credentials'));

  useEffect(() => {
    if (user.user?.registered && credentials.activated) {
      history.push('/');
      return;
    } else if (user.user?.registered && !credentials.activated) {
      history.push('/profile-settings');
      return;
    }
    if (Object.keys(user).length === 0) {
      props.getUser();
    }
    // setIsLoading(false);
    t && setPolicy({ privacyPolicyUrl: getPrivacyUrl() });
  }, [user.user?.registered, credentials.activated]);

  const getPrivacyUrl = () => {
    const localLanguage = i18next.language;
    switch (localLanguage) {
      case 'da':
        return 'https://communication.irecommend.se/policy/privacy-policy-da.html';
      case 'en':
        return 'https://communication.irecommend.se/policy/privacy-policy-en.html';
      case 'fi':
        return 'https://communication.irecommend.se/policy/privacy-policy-fi.html';
      case 'nb':
        return 'https://communication.irecommend.se/policy/privacy-policy-no.html';
      case 'sv':
        return 'https://communication.irecommend.se/policy/privacy-policy-sv.html';
      default:
        return 'https://communication.irecommend.se/policy/privacy-policy-en.html';
    }
  };

  const onPressAccept = () => {
    const credentials = {
      activated: true,
    };
    storeCredentials(credentials, true).then(() => {
      history.push('/profile-settings');
    });
  };

  return (
    <>
      {isLoading || isLoading === undefined ? (
        <TransitionScreen />
      ) : (
        <Loader isLoading={isLoading}>
          <div className={css(styles.wrap, mobile && styles.wrapMobile)}>
            <div className={css(styles.inner, mobile && styles.innerMobile)}>
              <div className={css(styles.content)}>
                <h1 className={css(styles.header, mobile && styles.headerMobile)}>
                  {t('profileeditpage-personal-data-header')}
                </h1>
                <div className={css(styles.userWrapper)}>
                  <ProfilePicture
                    name={`${user.user?.givenname} ${user.user?.familyname}`}
                    picture={''}
                    size="medium"
                    style={styles.picture}
                    isLoading={false}
                  />
                  <div className={css(styles.userInfo)}>
                    <p className={css(styles.nameHeading)}>
                      {user.user?.givenname}
                      <span className={css(styles.subHeading)}> {user.user?.familyname}</span>
                    </p>

                    <div className={css(styles.paragraph, mobile && styles.paragraphMobile)}>
                      {user.user?.mail}
                    </div>
                  </div>
                </div>
                <div className={css(styles.consentText)}>
                  {t('profileeditpage-input-allowpul-first')}
                  <a href={policy.privacyPolicyUrl} className={css(styles.consentLink)}>
                    {t('profileeditpage-input-allowpul-second')}
                  </a>
                </div>
                <Button
                  title={t('generic-text-accept')}
                  type="primary"
                  onPress={onPressAccept}
                  className={css(styles.continueButton)}
                  transform="upper"
                />
              </div>
            </div>
          </div>
        </Loader>
      )}
    </>
  );
};
function mapStateToProps(state) {
  return {
    user: state.userState.user.response,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getUser: () => dispatch(getUser()),
    getUserInvalidate: () => dispatch(getUserInvalidate()),
    logoutUser: () => dispatch(logoutUser()),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(RegisterPrivacyPolicyPage);

const styles = StyleSheet.create({
  wrap: {
    backgroundColor: Color.backgroundDark,
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    overflow: 'hidden',
    paddingTop: '4em',
  },
  wrapMobile: {
    // paddingTop: '4rem',
  },
  inner: {
    maxWidth: '60vw',
    flex: '1',
    color: Color.white,
  },
  innerMobile: {
    maxWidth: '80vw',
  },
  content: {
    color: Color.bgBlue,
    backgroundColor: Color.white,
    padding: 20,
    paddingBottom: 30,
    borderRadius: '8px',
    boxShadow: ' 0 1px 2px rgb(0 0 0 / 0.2)',
  },
  header: {
    margin: 0,
    fontWeight: 600,
    letterSpacing: 0.8,
  },
  headerMobile: {
    fontSize: 20,
  },
  userWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#EFEFF3',
    border: '1px solid rgba(117, 117, 129, 1)',
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
    paddingBottom: 10,
    borderRadius: 3,
    marginTop: 20,
    marginBottom: 20,
  },
  userInfo: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    paddingLeft: 20,
    justifyContent: 'flex-end',
  },
  paragraph: {
    fontSize: 13,
    color: Color.dark,
    marginBottom: 10,
    letterSpacing: 0.6,
  },
  paragraphMobile: {
    fontSize: 10,
  },
  nameHeading: {
    fontSize: 22,
    fontWeight: '600',
    marginBottom: 3,
    marginTop: 15,
    color: Color.primary,
  },
  subHeading: {
    fontWeight: '300',
  },
  consentText: {
    fontSize: 15,
    textAlign: 'center',
    color: 'rgba(38, 37, 62, 1)',
    letterSpacing: 0.3,
    paddingTop: 20,
    paddingBottom: 40,
  },
  consentLink: {
    textDecoration: 'underline',
  },
  introLink: {
    textDecorationLine: 'underline',
    textDecorationColor: 'rgba(38, 37, 62, 1)',
    textDecorationStyle: 'solid',
  },
  continueButton: {
    marginBottom: '5em',
  },
  errorMessage: {
    textAlign: 'center',
    marginBottom: '1em',
    color: Color.warning,
  },
});

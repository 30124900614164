import { FETCH_GOALS } from '../actions';

const defaultTipsState = [];
const reducerTeamGoals = (state = defaultTipsState, action) => {
  switch (action.type) {
    case FETCH_GOALS:
      return [...state, action.payload];
    default:
      return state;
  }
};

export default reducerTeamGoals;

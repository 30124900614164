import { CALL_API } from '../middleware/api';

/**
 * GET goals
 */
export const GET_GOALS_REQUEST = 'GET_GOALS_REQUEST';
export const GET_GOALS_SUCCESS = 'GET_GOALS_SUCCESS';
export const GET_GOALS_FAILURE = 'GET_GOALS_FAILURE';
export const GET_GOALS_INVALIDATE = 'GET_GOALS_INVALIDATE';

export function getGoals() {
  return {
    [CALL_API]: {
      endpoint: 'goals',
      authenticated: true,
      types: [GET_GOALS_REQUEST, GET_GOALS_SUCCESS, GET_GOALS_FAILURE],
    },
  };
}

/**
 * GET team
 */
export const GET_GOAL_REQUEST = 'GET_GOAL_REQUEST';
export const GET_GOAL_SUCCESS = 'GET_GOAL_SUCCESS';
export const GET_GOAL_FAILURE = 'GET_GOAL_FAILURE';
export const GET_GOAL_INVALIDATE = 'GET_GOAL_INVALIDATE';

export function getGoal(id) {
  const endpoint = `goals/single/${id}`;
  return {
    [CALL_API]: {
      endpoint: endpoint,
      authenticated: true,
      types: [GET_GOAL_REQUEST, GET_GOAL_SUCCESS, GET_GOAL_FAILURE],
    },
  };
}

/**
 *  Invalidate teams content to make sure it get fetched again when the view appears
 */
export const getGoalsInvalidate = () => {
  return {
    type: GET_GOALS_INVALIDATE,
    payload: {},
  };
};

/**
 *  Only dispatches getGoals if data is invalidated or empty
 */
const shouldGetGoals = (state) => {
  const goals = state.goalState;

  if (!goals.response || goals.response.length === 0) {
    return true;
  }

  return goals.didInvalidate;
};

export const getGoalsIfNeeded = () => (dispatch, getState) => {
  if (shouldGetGoals(getState())) {
    return dispatch(getGoals());
  }
};

export const POST_GOAL_REQUEST = 'POST_GOAL_REQUEST';
export const POST_GOAL_SUCCESS = 'POST_GOAL_SUCCESS';
export const POST_GOAL_FAILURE = 'POST_GOAL_FAILURE';

/**
 * Create or update goal
 */
export function postGoal(data, id = null) {
  let endpoint = 'goals';

  // Update team
  if (id) {
    endpoint += `/single/${id}`;
  }

  return {
    [CALL_API]: {
      endpoint: endpoint,
      authenticated: true,
      types: [POST_GOAL_REQUEST, POST_GOAL_SUCCESS, POST_GOAL_FAILURE],
      method: 'POST',
      data: data,
    },
  };
}

export const DELETE_GOAL_REQUEST = 'DELETE_GOAL_REQUEST';
export const DELETE_GOAL_SUCCESS = 'DELETE_GOAL_SUCCESS';
export const DELETE_GOAL_FAILURE = 'DELETE_GOAL_FAILURE';

/**
 * Delete goal
 */
export function deleteGoal(id) {
  const endpoint = `goals/single/${id}`;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      authenticated: true,
      types: [DELETE_GOAL_REQUEST, DELETE_GOAL_SUCCESS, DELETE_GOAL_FAILURE],
      method: 'DELETE',
    },
  };
}

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Color, Variables } from '../config/styles';
import { connect } from 'react-redux';
import { css, StyleSheet } from 'aphrodite';

import Button from '../components/Button';
import Input from '../components/Input';
import Loader from '../components/Loader';
import Select from '../components/Select';
import MainWrapper from '../components/MainWrapper';

import Utils from '../irecommend-lib/utils';
import ProfileSettingsPicture from '../components/ProfileSettingsPicture';
import { withTranslation } from 'react-i18next';

class ProfileEditPage extends Component {
  static propTypes = {
    /**
     * Status for data loading
     */
    isLoading: PropTypes.bool.isRequired,
    /**
     * This is how we know when the form is used to register a new user.
     */
    isRegistration: PropTypes.bool,
    /**
     * Function that handles submit
     */
    onPressSubmitForm: PropTypes.func.isRequired,
    /**
     * Handles pressing terms read more when isRegistration=true
     */
    onTerms: PropTypes.func,
    /**
     * An array of the teams the user can be in
     */
    teams: PropTypes.array.isRequired,
    /**
     * An object with user data
     */
    user: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  static defaultProps = {
    isRegistration: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      allowPUL: false,
      formData: {
        Familyname: '',
        Givenname: '',
        Mail: '',
        Picture: '', // Loaded image for presentation
        PictureRaw: null, // input File object
        Team: '',
        iduser: '',
      },
      didSubmit: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.user !== nextProps.user) {
      const user = nextProps.user.user;
      this.setUserData(user);
    }
  }

  componentDidMount() {
    if (this.props.user.user) {
      const user = this.props.user.user;
      this.setUserData(user);
    }
  }

  render() {
    const { t, isLoading, teams } = this.props;
    const mobile = window.innerWidth < 700;
    const { didSubmit } = this.state;
    const { Familyname, Givenname, Mail, Picture, Team } = this.state.formData;
    // Prepare teams data for select
    const teamsSelect = teams.map((item) => {
      return {
        title: item.name,
        value: item.idteam,
      };
    });
    return (
      <Loader isLoading={isLoading}>
        <MainWrapper>
          <div className={css(styles.container, mobile && styles.smallContainer)}>
            <form className={css(styles.form, mobile && styles.smallForm)}>
              <input
                id="image-upload"
                type="file"
                name="pic"
                accept="image/*"
                onChange={(e) => this.onChangeFiles(e.target.files)}
                className={css(styles.imageInput)}
              />
              <label htmlFor="image-upload" className={css(styles.profilePictureWrapper)}>
                <ProfileSettingsPicture
                  name={Givenname + ' ' + Familyname}
                  picture={Picture}
                  src={Picture}
                  size="large"
                />
                <p style={{ marginBottom: 40 }} className={css(styles.profilePictureText)}>
                  {t('profileeditpage-changepicture')}
                </p>
              </label>

              <Input
                value={Givenname}
                type="text"
                name="Givenname"
                maxLength="45"
                placeholder={t('generic-input-Givenname')}
                onChange={(event) =>
                  this.setState({
                    formData: {
                      ...this.state.formData,
                      Givenname: event.target.value,
                    },
                  })
                }
              />
              <Input
                value={Familyname}
                type="text"
                name="Familyname"
                maxLength="45"
                placeholder={t('generic-input-Familyname')}
                onChange={(event) =>
                  this.setState({
                    formData: {
                      ...this.state.formData,
                      Familyname: event.target.value,
                    },
                  })
                }
              />
              <Input
                disabled
                value={Mail}
                type="email"
                name="Mail"
                placeholder={t('generic-input-Mail')}
                onChange={(event) =>
                  this.setState({
                    formData: {
                      ...this.state.formData,
                      Mail: event.target.value,
                    },
                  })
                }
              />
              <Select
                style={window.innerWidth > 500 ? styles.selectDesktop : null}
                optionsData={teamsSelect}
                selected={Team}
                onChange={(value) =>
                  this.setState({
                    formData: { ...this.state.formData, Team: value },
                  })
                }
                placeholder={t('profileeditpage-team')}
              />

              <div>
                <Button
                  isLoading={isLoading}
                  title={t('profileeditpage-submit')}
                  type="primary"
                  onPress={this.onPressSubmitForm}
                />
              </div>
              {didSubmit && (
                <span className={css(styles.successField)}>
                  {t('profileeditpage-changes-saved')}
                </span>
              )}
            </form>
          </div>
        </MainWrapper>
      </Loader>
    );
  }

  setUserData = (user) => {
    user = user ? user : {};
    const newState = {
      ...this.state,
      formData: {
        Givenname: user.givenname ? user.givenname : '',
        Familyname: user.familyname ? user.familyname : '',
        Mail: user.mail ? user.mail : '',
        Picture: user.picture ? user.picture : '',
        Team: user.team && user.team.href ? Utils.getIdFromHref(user.team.href) : '',
        iduser: user.iduser,
      },
    };

    const initialState = { ...newState.formData };
    newState.initialState = initialState;
    this.setState(newState);
  };

  onPressSubmitForm = (e) => {
    e.preventDefault();

    if (this.props.isLoading) {
      // Disable button while loading.
      return;
    }

    const formChanged = !(
      JSON.stringify(this.state.formData) === JSON.stringify(this.state.initialState)
    );

    if (formChanged) {
      if (this.props.onPressSubmitForm(this.state.formData) === true) {
        // Keeps track of when the user has submitted the recommendation. Used to present errors.
        this.setState({
          didSubmit: true,
          initialState: { ...this.state.formData },
        });
      }
    }
  };

  /**
   * Handle files choosen in the file upload form. We only want one file.
   */
  onChangeFiles = (files) => {
    if (files && files[0]) {
      const reader = new FileReader();
      const img = files[0];
      const size = img.size / 1024; // KB size
      const type = img.type; // image/png
      if (size > 3000) {
        alert(this.props.t('generic-error-image-size', { maxSize: '3MB' }));
        return;
      }
      if (!(type === 'image/png' || type === 'image/jpeg')) {
        alert(this.props.t('generic-error-image-size', { imgType: type.replace('image/', '') }));
        return;
      }
      reader.onload = (e) => {
        const formData = {
          ...this.state.formData,
          Picture: e.target.result, // Loaded image for presentation
          PictureRaw: files[0], // input File object
        };
        this.setState({
          formData,
        });
      };

      reader.readAsDataURL(files[0]);
    }
  };
}

function mapStateToProps(state) {
  return {
    // recommendationState: state.recommendationState,
  };
}

export default connect(mapStateToProps)(withTranslation()(ProfileEditPage));

const styles = StyleSheet.create({
  allowPul: {
    display: 'block',
    marginBottom: 10,
    marginTop: 15,
  },
  container: {
    minHeight: Variables.MainWrapperHeight,
    padding: Variables.BubbleSectionPadding,
    animationName: {
      '0%': {
        opacity: 0,
      },
    },
    animationDuration: '1s',
    animationIterationCount: 1,
  },
  smallContainer: {
    padding: 20,
  },
  form: {
    margin: '0 30%',
    width: '40%',
    borderRadius: '4px',
    padding: 16,
    overflow: 'hidden',
    backgroundColor: Color.white,
  },
  smallForm: {
    margin: '0 3%',
    minWidth: '94%',
  },
  h1: {
    fontSize: '1.5em',
    fontWeight: '600',
    lineHeight: '30px',
    color: Color.dark,
  },
  profilePicture: {
    display: 'inline-flex',
    marginLeft: 0,
    boxShadow: 'none',
  },
  profilePictureWrapper: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginBottom: '1rem',
  },
  profilePictureContainer: {
    display: 'inline-block',
  },
  profilePictureText: {
    fontSize: '17px',
    margin: '0 0 0 15px',
  },
  imageInput: {
    display: 'none',
  },
  selectDesktop: {
    marginTop: '.5rem',
  },
  small: {
    fontSize: '.75rem',
  },
  successField: {
    float: 'right',
    marginTop: 25,
  },
});

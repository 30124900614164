import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { CallbackComponent } from 'redux-oidc';

import TransitionScreen from '../../components/TransitionScreen';

import { getRedirectPathFromLocalStorage, storeCredentials } from '../../api';
import userManager, { processTidUserResponse } from '../../config/tidUserManager';
import { getUser } from '../../irecommend-lib/actions/userActions';

const TidCallbackPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  let timer;

  useEffect(() => {
    return () => clearTimeout(timer);
  }, [timer]);

  const successCallback = async (user) => {
    timer = setTimeout(async () => {
      const credentials = processTidUserResponse(user);
      await storeCredentials(credentials);

      dispatch(getUser());

      history.push(getRedirectPathFromLocalStorage());
    }, 800);
  };

  const errorCallback = (error) => {
    console.error(error);
    history.push(getRedirectPathFromLocalStorage());
  };

  return (
    <CallbackComponent
      userManager={userManager}
      successCallback={successCallback}
      errorCallback={errorCallback}
    >
      <TransitionScreen />
    </CallbackComponent>
  );
};

export default TidCallbackPage;

export const isMatchingSearch = (position, searchTerm) => {
  return (
    position.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    position.contactname.toLowerCase().includes(searchTerm.toLowerCase())
  );
};

export const ITEMS_PER_PAGE = 24;
export const FILTER_TYPES = {
  all: 'all',
  published: 'published',
  unpublished: 'unpublished',
};

export const POSITION_STATUS = {
  published: 'published',
  unpublished: 'unpublished',
};

export const TAB_NAMES = {
  positions: 'positions',
  external: 'external',
};

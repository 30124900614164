import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { css, StyleSheet } from 'aphrodite';

import { getPositionIfNeeded } from '../irecommend-lib/actions/positionActions';
import {
  getRecommendationInvalidate,
  getRecommendationsInvalidate,
  postRecommendationPosition,
} from '../irecommend-lib/actions/recommendationActions';
import Utils from '../irecommend-lib/utils';

import ButtonIcon from '../components/ButtonIcon';
import Loader from '../components/Loader';
import TabBar from '../components/TabBar';
import TabLinkedin from '../components/TabLinkedin';
import TabRecommend from '../components/TabRecommend';

import { Color } from '../config/styles';
import { useTranslation } from 'react-i18next';

const RecommendPage = (props) => {
  const [activeTab, setActiveTab] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [status, setStatus] = useState(null);

  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const position = useSelector((state) => state.positionState.position);
  const recommendation = useSelector((state) => state.recommendationState.recommendation);
  const isLoading = recommendation.isLoading;

  useEffect(() => {
    dispatch(getPositionIfNeeded(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (Object.keys(recommendation.response).length > 0) {
      const newStatus = recommendation.error ? recommendation.response.status : 200;
      const newErrorMessage = recommendation.error ? recommendation.response.data?.Message : '';
      setStatus(newStatus);
      setErrorMessage(newErrorMessage);
    }
  }, [recommendation, recommendation.response]);

  useEffect(() => {
    return () => {
      resetStatus();
    };
  }, []);

  const onPressHome = useCallback(() => {
    resetStatus();
    history.push('/');
  }, [history, dispatch]);

  const onPressInfoButton = useCallback(() => {
    alert(t('recommendcontainer-alert-info-message'));
  }, [t]);

  const onPressLinkedin = useCallback(() => {
    if (position.response.linkedinquery) {
      window.open(position.response.linkedinquery, 'ireclinkedin');
    }
  }, [position]);

  const onPressSubmit = useCallback(
    (currentState) => {
      resetStatus();

      // Form validation
      if (currentState.noContactInfo) {
        if (currentState.name === '' || currentState.text === '') {
          alert(t('recommendcontainer-validation-namecompany'));
          return false;
        }
      } else {
        if (
          currentState.name === '' ||
          (currentState.email === '' &&
            (currentState.phone === '' || currentState.phone === currentState.dialCode))
        ) {
          alert(t('recommendcontainer-validation-emailphone'));
          return false;
        }

        // Validate email
        if (currentState.email && !Utils.validateEmail(currentState.email)) {
          alert(t('generic-validation-email'));
          return false;
        }
      }

      let Telephone = (currentState.phone ? currentState.phone : '').trim();
      const dialCode = new RegExp(`^${currentState.dialCode}`, 'i');
      if (dialCode.test(Telephone)) {
        Telephone = `+${Telephone}`;
      }

      // Validation passed - submit.
      let data = {
        Name: currentState.name,
        Telephone: Telephone !== `+${currentState.dialCode}` ? Telephone : '',
        CountryCode: currentState.countryCode ? currentState.countryCode : '',
        Mail: currentState.email ? currentState.email : '',
        Text: currentState.text ? currentState.text : '',
        Motivation: currentState.motivation ? currentState.motivation : '',
        Anonymous: currentState.anonymous,
        NoContactInfo: currentState.noContactInfo,
      };

      dispatch(postRecommendationPosition(id, data));
      dispatch(getRecommendationsInvalidate());

      return true;
    },
    [dispatch, id, t],
  );

  const resetStatus = useCallback(() => {
    setStatus(null);
    setErrorMessage('');
    dispatch(getRecommendationInvalidate());
  }, [dispatch]);

  const onChangeTab = (tab) => {
    setActiveTab(tab.key);
  };

  const tabs = [
    {
      key: 'tab-1',
      title: t('recommendpage-tab-1'),
    },
    {
      key: 'tab-2',
      title: t('recommendpage-tab-2'),
    },
  ];

  return (
    <Loader isLoading={isLoading}>
      <header aria-label="Page header">
        <div className={css(styles.container)}>
          <div className={css(styles.innerWrap)}>
            <div className={css(styles.titleWrap)}>
              <span className={css(styles.title)}>{t('recommendpage-header')}</span>

              <ButtonIcon
                icon="info"
                onPress={onPressInfoButton}
                style={styles.infoButton}
                aria-label="Info button"
              />
            </div>
          </div>

          <TabBar
            active={activeTab || 'tab-1'}
            items={tabs}
            onChange={onChangeTab}
            style={styles.tabBar}
            theme="dark"
          />
        </div>
      </header>

      <main aria-label="main content">
        <div className={css(styles.scrollView)}>
          {activeTab === 'tab-2' ? (
            <TabLinkedin onPressSearch={onPressLinkedin} />
          ) : (
            <TabRecommend
              isLoading={isLoading}
              onPressHome={onPressHome}
              onPressSubmit={onPressSubmit}
              onPressMoreRecommendations={resetStatus}
              status={status}
              errorMessage={errorMessage}
            />
          )}
        </div>
      </main>
    </Loader>
  );
};

export default RecommendPage;

const styles = StyleSheet.create({
  container: {
    marginTop: 5,
    backgroundColor: Color.white,
    borderWidth: 1,
    borderColor: 'rgba(0, 0, 0, .10)',
    borderRadius: 2,
  },
  close: {
    position: 'absolute',
    right: 0,
    top: 0,
    padding: 20,
  },
  innerWrap: {
    padding: 20,
  },
  infoButton: {
    paddingTop: 2,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 0,
  },
  scrollView: {
    padding: 20,
    paddingTop: 0,
  },
  tabBar: {
    borderBottomColor: 'rgba(0, 0, 0, .10)',
    borderBottomWidth: 1,
    paddingLeft: 20,
    paddingRight: 20,
    marginBottom: 12,
  },
  title: {
    fontWeight: 500,
    fontSize: 20,
    color: Color.dark,
  },
  titleWrap: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 4,
  },
});

import React from 'react';
import CountUp from 'react-countup';
import { useTranslation } from 'react-i18next';

const NumberWidgetItem = ({ number, nOfUsers }) => {
  // HACK: same as NumberViewBox
  const isTipsPerUser = number.stylingclass === 'number-balls grayball';
  const data = isTipsPerUser ? (number.data / (nOfUsers || 1)).toFixed(1) : number.data;
  const decimal = isTipsPerUser ? 1 : 0;
  const { t } = useTranslation();
  return (
    <div>
      <div>
        <h3>{t(number.title)}</h3>
      </div>
      <div className={number.stylingclass}>
        <h4>
          <CountUp start={0} end={data} decimals={decimal} duration={1} />
        </h4>
      </div>
    </div>
  );
};

export default NumberWidgetItem;

import React, { Component } from 'react';
import { css, StyleSheet } from 'aphrodite';
import PropTypes from 'prop-types';

import { Color } from '../config/styles';

import TaskCompleted from './icons/TaskCompleted';

/**
 * Component for showing confirmation messages after e.g. form submissions.
 *
 * Example usage:
 *
 * ```
 * <ConfirmationBox
 *   onPress={onPressLearnMore}
 *   subtitle="Your tip has been sent to Linus Andersson"
 *   title="Thanks!">
 *      <Button />
 * </ConfirmationBox>
 * ```
 *
 */
export default class ConfirmationBox extends Component {
  static propTypes = {
    /**
     * Child components
     */
    children: PropTypes.node,
    /**
     * Custom styles to be applied to the button.
     */
    style: PropTypes.number,
    /**
     * Confirmation description
     */
    subtitle: PropTypes.string.isRequired,
    /**
     * Confirmation title
     */
    title: PropTypes.string.isRequired,
  };

  render() {
    const { children, style, subtitle, title } = this.props;

    return (
      <div className={css(styles.wrap, style)}>
        <div className={css(styles.background)}>
          <div className={css(styles.icon, children && styles.hasChildren)}>
            <TaskCompleted />
          </div>

          <h3 className={css(styles.title)}>{title}</h3>
          <p className={css(styles.subtitle)}>{subtitle}</p>
          {children}
        </div>
      </div>
    );
  }
}

const styles = StyleSheet.create({
  background: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    backgroundColor: Color.primary,
  },
  hasChildren: {
    marginTop: 'auto',
  },
  icon: {
    alignSelf: 'center',
    height: 77,
    marginBottom: 18,
    width: 77,
  },
  subtitle: {
    backgroundColor: 'transparent',
    color: Color.white,
    fontSize: 17,
    lineHeight: 1.35,
    textAlign: 'center',
    paddingRight: 50,
    paddingLeft: 50,
  },
  title: {
    backgroundColor: 'transparent',
    color: Color.white,
    fontWeight: 600,
    fontSize: 25,
    lineHeight: 1.2,
    marginBottom: 8,
    textAlign: 'center',
  },
  wrap: {
    display: 'flex',
    minHeight: 386,
  },
});

import {
  GET_TRANSACTIONS_SUM_USERS_REQUEST,
  GET_TRANSACTIONS_SUM_USERS_SUCCESS,
  GET_TRANSACTIONS_SUM_USERS_FAILURE,
  GET_TRANSACTIONS_SUM_USERS_INVALIDATE,
  GET_TRANSACTIONS_SUM_TEAMS_REQUEST,
  GET_TRANSACTIONS_SUM_TEAMS_SUCCESS,
  GET_TRANSACTIONS_SUM_TEAMS_FAILURE,
  GET_TRANSACTIONS_SUM_TEAMS_INVALIDATE,
} from '../actions/transactionActions';

const initialState = {
  sumTeams: {
    didInvalidate: false,
    error: false,
    isLoading: false,
    response: [],
  },
  sumUsers: {
    didInvalidate: false,
    error: false,
    isLoading: false,
    response: [],
  },
};

export default function transactionState(
  state = initialState,
  { authenticated, error, response, type },
) {
  switch (type) {
    case GET_TRANSACTIONS_SUM_TEAMS_REQUEST:
      return {
        ...state,
        sumTeams: {
          ...state.sumTeams,
          isLoading: true,
        },
      };
    case GET_TRANSACTIONS_SUM_TEAMS_SUCCESS:
      return {
        ...state,
        sumTeams: {
          ...state.sumTeams,
          didInvalidate: false,
          isLoading: false,
          response: response,
        },
      };
    case GET_TRANSACTIONS_SUM_TEAMS_FAILURE:
      return {
        ...state,
        sumTeams: {
          ...state.sumTeams,
          isLoading: false,
          error: true,
          response: error,
        },
      };
    case GET_TRANSACTIONS_SUM_TEAMS_INVALIDATE:
      return {
        ...state,
        sumTeams: {
          ...state.sumTeams,
          didInvalidate: true,
        },
      };

    case GET_TRANSACTIONS_SUM_USERS_REQUEST:
      return {
        ...state,
        sumUsers: {
          ...state.sumTeams,
          isLoading: true,
        },
      };
    case GET_TRANSACTIONS_SUM_USERS_SUCCESS:
      return {
        ...state,
        sumUsers: {
          ...state.sumUsers,
          didInvalidate: false,
          isLoading: false,
          response: response,
        },
      };
    case GET_TRANSACTIONS_SUM_USERS_FAILURE:
      return {
        ...state,
        sumUsers: {
          ...state.sumUsers,
          isLoading: false,
          error: true,
          response: error,
        },
      };
    case GET_TRANSACTIONS_SUM_USERS_INVALIDATE:
      return {
        ...state,
        sumUsers: {
          ...state.sumUsers,
          didInvalidate: true,
        },
      };
    default:
      return state;
  }
}

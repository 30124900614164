import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { postPositionArchive, getPositions } from '../../irecommend-lib/actions/positionActions';
import { FILTER_TYPES } from '../../helpers/position';

const useArchivePosition = (selectedFilter) => {
  const { positionPost } = useSelector((state) => state.positionState);
  const dispatch = useDispatch();

  useEffect(() => {
    if (positionPost.response === '') {
      dispatch(getPositions(selectedFilter === FILTER_TYPES.all ? '' : selectedFilter));
      dispatch({ type: 'POST_POSITION_ARCHIVE_RESET' });
    }
  }, [dispatch, positionPost.response, selectedFilter]);

  const onArchivePosition = (id) => {
    dispatch(postPositionArchive(id));
  };

  return { archiveIsLoading: positionPost.isLoading, onArchivePosition };
};

export default useArchivePosition;

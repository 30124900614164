import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Color, Font, Variables } from '../config/styles';
import { css, StyleSheet } from 'aphrodite';
import { IconArrowDown } from '../components/Icons';

import flagSv from '../assets/images/flags/sv@2x.png';
import flagDa from '../assets/images/flags/da@2x.png';
import flagNo from '../assets/images/flags/no@2x.png';
import flagEn from '../assets/images/flags/en@2x.png';
import flagFi from '../assets/images/flags/fi@2x.png';
import { withTranslation } from 'react-i18next';
/**
 * A basic button component with specific appearance for iRecommend.
 *
 * Example usage:
 *
 * ```
 * <Button
 *   onPress={onPressLearnMore}
 *   title="Learn More"
 *   type="primary"
 * />
 * ```
 *
 */
class Button extends Component {
  static propTypes = {
    /*
     * If the button should be in a disabled state
     */
    disabled: PropTypes.bool,
    /*
     * Icon from passed on via Webpack
     */
    icon: PropTypes.node,
    /*
     * Icon styling
     */
    iconStyle: PropTypes.object,
    /*
     * Option for setting loading state on button
     */
    isLoading: PropTypes.bool,
    /**
     * Button: Handler to be called when the user taps the button
     */
    onPress: PropTypes.func.isRequired,
    /**
     * Custom styles to be applied to the button.
     */
    style: PropTypes.object,
    /**
     * How the text aligns. Default: center
     */
    textAlign: PropTypes.string,
    /**
     * Text to display inside the button
     */
    title: PropTypes.string.isRequired,
    /**
     * Controls the button appearance. Valid values: light, link, linkLight, linkLightUnderline, primary, secondary
     */
    type: PropTypes.string,
    /**
     * Used together with type="flag" - language code (ISO 639-1)
     */
    flag: PropTypes.string,
  };

  static defaultProps = {
    disabled: false,
    textAlign: 'center',
    type: 'primary',
  };

  render() {
    const {
      t,
      disabled,
      icon,
      iconStyle,
      isLoading,
      onPress,
      style,
      textAlign,
      title,
      type,
      transform,
    } = this.props;

    let disabledState = disabled ? styles.disabled : '';

    switch (type) {
      case 'readAll':
        return (
          <button
            className={css(styles.wrap, styles[`${type}Wrap`], style, disabledState)}
            onClick={onPress}
          >
            {isLoading ? (
              <span
                className={css(styles.text, styles[`${type}Text`], transform && styles.upperText)}
              >
                {t('generic-text-loading')}...
              </span>
            ) : (
              <span className={css(styles.container, styles[`textAlign${textAlign}`])}>
                <span
                  className={css(
                    styles.text,
                    styles[`${type}Text`],
                    transform ? styles.upperText : null,
                  )}
                >
                  {title}
                </span>
                <i className={css(styles.arrowDown)}>
                  <IconArrowDown />
                </i>
              </span>
            )}
          </button>
        );
      case 'flag':
        return (
          <button
            className={css(styles.wrap, styles[`${type}Wrap`], style, disabledState)}
            onClick={onPress}
          >
            {isLoading ? (
              <span
                className={css(styles.text, styles[`${type}Text`], transform && styles.upperText)}
              >
                {t('generic-text-loading')}...
              </span>
            ) : (
              <span className={css(styles.container, styles[`textAlign${textAlign}`])}>
                {this.getFlag()}
                <span
                  className={css(styles.text, styles[`${type}Text`], transform && styles.upperText)}
                >
                  {title}
                </span>
              </span>
            )}
          </button>
        );
      default:
        return (
          <button
            className={css(styles.wrap, styles[`${type}Wrap`], style, disabledState)}
            onClick={onPress}
          >
            {isLoading ? (
              <span
                className={css(styles.text, styles[`${type}Text`], transform && styles.upperText)}
              >
                {t('generic-text-loading')}...
              </span>
            ) : (
              <span className={css(styles.container, styles[`textAlign${textAlign}`])}>
                <span
                  className={css(styles.text, styles[`${type}Text`], transform && styles.upperText)}
                >
                  {title}
                </span>
                {icon && <i className={css(styles.icon, iconStyle)}>{icon}</i>}
              </span>
            )}
          </button>
        );
    }
  }

  getFlag() {
    const { flag } = this.props;

    switch (flag) {
      case 'sv':
        return <img src={flagSv} className={css(styles.flag)} alt="" />;
      case 'da':
        return <img src={flagDa} className={css(styles.flag)} alt="" />;
      case 'nb':
        return <img src={flagNo} className={css(styles.flag)} alt="" />;
      case 'en':
        return <img src={flagEn} className={css(styles.flag)} alt="" />;
      case 'fi':
        return <img src={flagFi} className={css(styles.flag)} alt="" />;
      default:
        return null;
    }
  }
}

const linkInlineWrap = {
  fontSize: '1em',
  width: 'auto',
  margin: 0,
};

const linkInlineText = {
  width: 'auto',
  fontFamily: Font.defaultFont,
  fontSize: '1em',
  textDecorationLine: 'underline',
  letterSpacing: Variables.DefaultLetterSpacing,
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'center',
  },
  arrowDown: {
    width: '0.875em',
    height: '0.875em',
    marginLeft: '0.25em',
    strokeWidth: '3',
    color: Color.labelGray,
    stroke: Color.labelGray,
  },
  disabled: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
  icon: {
    width: '0.875em',
    height: '0.875em',
    maxHeight: '1em',
    marginLeft: '.25em',
  },
  lightText: {
    color: Color.primary,
  },
  lightWrap: {
    backgroundColor: Color.secondary,
    padding: 13,
  },
  linkLightText: {
    color: Color.white,
    fontFamily: Font.defaultFont,
    fontSize: 13,
    textDecorationLine: 'underline',
    letterSpacing: Variables.DefaultLetterSpacing,
  },
  linkLightUnderlineText: {
    color: Color.white,
    fontFamily: Font.defaultFont,
    fontSize: 15,
    textDecorationLine: 'underline',
    letterSpacing: 0.65,
  },
  linkWrap: {
    paddingTop: 13,
  },
  linkText: {
    color: Color.primary,
    lineHeight: 1.375,
    fontFamily: Font.defaultFont,
    fontSize: 17,
    textDecorationLine: 'underline',
    letterSpacing: Variables.DefaultLetterSpacing,
  },
  linkUnderlineText: {
    color: Color.primary,
    lineHeight: 1.375,
    fontFamily: Font.defaultFont,
    fontSize: 17,
    textDecorationLine: 'underline',
    letterSpacing: Variables.DefaultLetterSpacing,
  },
  linkInlineWrap,
  linkInlineText,
  flagWrap: {
    ...linkInlineWrap,
    display: 'block',
  },
  flagText: {
    ...linkInlineText,
    textDecorationLine: 'none',
  },
  actionsWrap: {
    appearance: 'none',
    height: '5.6875rem',
    borderLeft: `1px solid rgba(0, 0, 0, .06)`,
    margin: 0,
    paddingLeft: '1em',
    paddingRight: '1em',
  },
  cleanActionsWrap: {
    appearance: 'none',
    padding: '1.8em 2em',
    borderLeft: `1px solid rgba(0, 0, 0, .06)`,
    margin: 0,
    borderRadius: '0',
    transition: 'color .2s ease-in-out',

    ':hover': {
      color: '#999',
    },
  },
  primaryWrap: {
    backgroundColor: Color.primary,
    padding: '0.944rem 1rem',
    // minHeight: '45px',
  },
  primaryText: {
    color: Color.white,
  },
  secondaryWrap: {
    borderWidth: 2,
    borderColor: Color.primary,
    borderStyle: 'solid',
    backgroundColor: Color.white,
    padding: 13,
  },
  secondaryText: {
    color: Color.primary,
  },
  thirdWrap: {
    borderWidth: 2,
    borderColor: Color.warning,
    borderStyle: 'solid',
    padding: 13,
  },
  thirdText: {
    color: Color.warning,
  },
  jobsListItemWrap: {
    borderRadius: '0',
    backgroundColor: Color.white,
    boxShadow: '0 5px 17px 0 rgba(0, 0, 0, .03)',
    margin: '0 0 1.5625rem 0',
    padding: '2rem 1rem',
  },
  readAllWrap: {
    width:
      'calc(100% + ' +
      Variables.BubbleSectionPadding +
      ' + ' +
      Variables.BubbleSectionPadding +
      ')',
    marginLeft: '-' + Variables.BubbleSectionPadding,
    marginRight: '-' + Variables.BubbleSectionPadding,
    marginBottom: '-' + Variables.BubbleSectionPadding,
    padding: '16px 0 18px 0',
    borderTop: '2px solid #F5F8FA',
  },
  readAllText: {
    color: Color.labelGray,
    fontSize: '12px',
    letterSpacing: '1px',
    textTransform: 'uppercase',
  },
  text: {
    backgroundColor: 'transparent',
    fontFamily: Font.defaultFont,
    fontWeight: 600,
    // textAlign: 'center',
  },
  textAligncenter: {
    justifyContent: 'center',
  },
  wrap: {
    fontSize: 17,
    display: 'inline-block',
    width: '100%',
    borderRadius: 2,
    cursor: 'pointer',
    marginTop: '0.5em',
    // textAlign: 'center',
    background: 'none',
    border: 'none',
    padding: '0',
  },
  smallWrap: {
    backgroundColor: Color.primary,
    padding: '0.944rem 1rem',
    // minHeight: '45px',
  },
  smallText: {
    color: Color.white,
    fontSize: '14px',
  },
  flag: {
    width: 20,
    height: 20,
    marginRight: 8,
  },
  upperText: {
    textTransform: 'uppercase',
  },
});

export default withTranslation()(Button);

import i18next from 'i18next';
import { getCustomerType } from '../api';

export const supportedLanguages = ['en', 'sv', 'nb', 'fi', 'da'];

export const IntlNetwork = (t, string, extra) => {
  const isNetwork = getCustomerType() === 'Network';
  let id = string;

  if (isNetwork) {
    id = `${id}-nw`;
  }

  const message = t(id, extra);

  return message !== id ? message : '';
};

export const fetchCustomerLanguage = (customer) => {
  return Object.keys(customer.response).length > 0
    ? customer.response.customer?.language
    : i18next.language;
};

export const normalizeLanguage = (lng) => {
  const normalizedLng = lng && lng.split('-')[0];
  return normalizedLng;
};

export const phoneLangaugeMap = {
  en: 'us',
  sv: 'se',
  nb: 'no',
  fi: 'fi',
  da: 'dk',
};

export const phonePlaceholderMap = {
  en: '+1',
  sv: '+46',
  nb: '+47',
  fi: '+358',
  da: '+45',
};

export const userMenuLanguageMap = [
  {
    key: 'sv',
    label: 'Svenska',
  },
  {
    key: 'da',
    label: 'Dansk',
  },
  {
    key: 'nb',
    label: 'Norsk',
  },
  {
    key: 'fi',
    label: 'Suomi',
  },
  {
    key: 'en',
    label: 'English',
  },
];

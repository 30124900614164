import { css, StyleSheet } from 'aphrodite';
import { BsListOl, BsListUl, BsTypeBold, BsTypeItalic, BsTypeUnderline } from 'react-icons/bs';
import { LuHeading1, LuHeading2, LuHeading3 } from 'react-icons/lu';
const DraftFormControlButton = (props) => {
  const setStyle = (e) => {
    props.changeInline(e, props);
  };

  const getIcon = (label) => {
    let icon;
    switch (label) {
      case 'Bullet list':
        icon = <BsListUl />;
        break;
      case 'Ordered list':
        icon = <BsListOl />;
        break;
      case 'B':
        icon = <BsTypeBold />;
        break;
      case 'I':
        icon = <BsTypeItalic />;
        break;
      case 'U':
        icon = <BsTypeUnderline />;
        break;
      case 'H1':
        icon = <LuHeading1 />;
        break;
      case 'H2':
        icon = <LuHeading2 />;
        break;
      case 'H3':
        icon = <LuHeading3 />;
        break;
      default:
        icon = label;
        break;
    }
    return icon;
  };

  return (
    <button
      type="button"
      className={css(props.clicked ? styles.clicked : styles.draftControl)}
      onMouseDown={setStyle}
      name={props.name}
      value={props.label}
    >
      {getIcon(props.label)}
    </button>
  );
};

export default DraftFormControlButton;

const styles = StyleSheet.create({
  draftControl: {
    margin: '3px',
    background: '#fff',
    border: 'none',
    borderRadius: '8px',
    padding: '5px',
    transition: 'all 0.1s ease-in-out',
    ':hover': {
      background: '#F1F1F3',
      outline: 'none',
      boxShadow: 'inset 0 3px 5px rgba(0,0,0,.125)',
    },
  },
  clicked: {
    margin: '3px',
    background: '#e5e5e5',
    outline: 'none',
    border: 'none',
    borderRadius: '8px',
    padding: '5px',
    boxShadow: 'inset 0 3px 5px rgba(0,0,0,.125)',
  },
});

import { SignoutCallbackComponent } from 'redux-oidc';

import TransitionScreen from '../../components/TransitionScreen';

import userManager from '../../config/tidUserManager';

const TidLogoutCallbackPage = () => {
  return (
    <SignoutCallbackComponent
      userManager={userManager}
      /* eslint-disable */
      errorCallback={(error) => console.log(`Something went wrong: ${error}`)}
      successCallback={() => {
        setTimeout(() => {
          userManager.signoutRedirect();
          userManager.removeUser();
        }, 800);
      }}
    >
      <TransitionScreen />
    </SignoutCallbackComponent>
  );
};

export default TidLogoutCallbackPage;

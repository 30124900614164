import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Variables, Color } from '../config/styles';

export default class MainWrapper extends Component {
  static propTypes = {
    /*
     * What ever wanted to be wrapped. Used as HTML <main>
     */
    children: PropTypes.node.isRequired,
    /*
     * Custom styling
     */
    style: PropTypes.object,
    /*
     * Bg Type (optional)
     */
    lightBg: PropTypes.bool,
  };

  render() {
    const { children, style, lightBg } = this.props;

    return (
      <main role="main" className={css([style, styles.container, lightBg && styles.lightBg])}>
        {children}
      </main>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    minHeight: Variables.MainWrapperHeight,
    position: 'relative',
    backgroundColor: Color.lightBlue,
  },
  lightBg: {
    backgroundColor: Color.baseGray,
  },
});

import { Component } from 'react';
import { connect } from 'react-redux';
import { postReferralRecommendationPosition } from '../irecommend-lib/actions/recommendationActions';

import Utils from '../irecommend-lib/utils';

import ExternalReferralPage from '../pages/ExternalReferralPage';
import { withTranslation } from 'react-i18next';

class ExternalReferralContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: null,
      isSubmit: false,
    };
  }

  componentDidUpdate() {
    // Alert if there is an error in the request and reset the errors.
    if (this.props.recommendation.error && this.state.isSubmit) {
      alert(this.props.t('generic-error-default-message'));
      this.props.recommendation.error = false;
      this.setState({ isSubmit: false });
    }
  }

  render() {
    const isLoading = this.props.recommendation.isLoading;
    const status = this.props.recommendation.requestSuccess ? 200 : null;

    return (
      <ExternalReferralPage
        isLoading={isLoading}
        onPressHome={this.onPressHome}
        onPressInfoButton={this.onPressInfoButton}
        onPressLinkedin={this.onPressLinkedin}
        onPressSubmit={this.onPressSubmit}
        status={status}
      />
    );
  }

  /**
   * POST recommendation to API
   * @param {object} currentState - State object from TabRecommendExternal component. Holds form data.
   */
  onPressSubmit = (currentState) => {
    const { t } = this.props;

    // Form validation
    if (currentState.referrer === '') {
      alert(t('external-referrer-name-validation'));

      return false;
    }
    if (currentState.noContactInfo) {
      if (currentState.name === '' || currentState.text === '') {
        alert(t('recommendcontainer-validation-namecompany'));

        return false;
      }
    } else {
      if (currentState.name === '' || (currentState.email === '' && currentState.phone === '')) {
        alert(t('recommendcontainer-validation-emailphone'));

        return false;
      }

      // Validate email
      if (currentState.email && !Utils.validateEmail(currentState.email)) {
        alert(t('generic-validation-email'));

        return false;
      }
    }

    let Telephone = (currentState.phone ? currentState.phone : '').trim();
    const dialCode = new RegExp(`^${currentState.dialCode}`, 'i');

    if (dialCode.test(Telephone)) {
      Telephone = `+${Telephone}`;
    }

    const id = this.props.match.params.id;

    // Validation passed - submit.
    let data = {
      Referrer: currentState.referrer,
      Name: currentState.name,
      Telephone: Telephone !== `+${currentState.dialCode}` ? Telephone : '',
      CountryCode: currentState.countryCode ? currentState.countryCode : '',
      Mail: currentState.email ? currentState.email : '',
      Text: currentState.text ? currentState.text : '',
      Motivation: currentState.motivation ? currentState.motivation : '',
      Anonymous: currentState.anonymous,
      NoContactInfo: currentState.noContactInfo,
    };

    this.setState({ isSubmit: true }, () =>
      this.props.postReferralRecommendationPosition(id, data),
    );

    return true;
  };

  onPressInfoButton = () => {
    const { t } = this.props;
    alert(t('recommendcontainer-alert-info-message'));
  };
}

function mapStateToProps(state) {
  return {
    recommendation: state.recommendationState.recommendation,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    postReferralRecommendationPosition: (id, recommendation) =>
      dispatch(postReferralRecommendationPosition(id, recommendation)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(ExternalReferralContainer));

import React, { Component } from 'react';
import i18next from 'i18next';
import ReactHtmlParser from 'react-html-parser';
import { connect } from 'react-redux';

import { getPolicy } from '../irecommend-lib/actions/policyActions';
import ArticlePage from '../pages/ArticlePage';
import { withTranslation } from 'react-i18next';

const language = i18next.language;

class TermsAndConditionsContainer extends Component {
  componentDidMount() {
    this.props.getPolicy(this.props.match.params.id, language);
  }

  render() {
    const isLoading = this.props.policy.isLoading;
    const policy = this.props.policy.response;
    const { t } = this.props;
    const content = <div>{ReactHtmlParser(policy.text)}</div>;

    return (
      <ArticlePage
        content={content}
        header={t('termsandconditionspage-header')}
        isLoading={isLoading}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    recommendation: state.recommendationState.recommendation,
    policy: state.policyState.policy,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getPolicy: (recommendation, language) => dispatch(getPolicy(recommendation, language)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(TermsAndConditionsContainer));

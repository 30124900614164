import { useState, useEffect } from 'react';
import { Button, Icon } from '@talentech/components';
import { css, StyleSheet } from 'aphrodite';
import { TALENTECH_ICONS } from '../Icons';

const OptionalInvite = ({ t }) => {
  const [copied, setCopied] = useState(false);
  const inviteText = `${t('communication-optional-invite')}`;

  useEffect(() => {
    const timer = setTimeout(() => {
      setCopied(false);
    }, 1500);

    return () => {
      clearTimeout(timer);
    };
  }, [copied]);

  useEffect(() => {
    const linksHtmlIds = ['apple-link', 'google-link', 'app-link', 'company-link'];

    linksHtmlIds.forEach((id) => (document.getElementById(id).style.textDecoration = 'underline'));
  }, []);

  const buttonIcon = copied ? (
    <Icon icon={TALENTECH_ICONS.checkMark} iconSize="sm" />
  ) : (
    <Icon icon={TALENTECH_ICONS.filter} iconSize="sm" />
  );

  const buttonLabel = copied
    ? t('communicationedit-copied-variable')
    : t('communication-optional-invite-copy');

  const handleCopy = async () => {
    const typeRich = 'text/html';
    const blobRich = new Blob([inviteText], { type: typeRich });

    const typePlain = 'text/plain';
    const blobPlain = new Blob([inviteText], { type: typePlain });
    const data = [new ClipboardItem({ [typeRich]: blobRich, [typePlain]: blobPlain })];

    await navigator.clipboard.write(data);
    setCopied(true);
  };

  return (
    <>
      <p>{t('communication-optional-invite-desc')}</p>
      <div className={css(styles.optionalInvite)}>
        <div dangerouslySetInnerHTML={{ __html: inviteText }} />
        <Button
          icon={buttonIcon}
          className={css(styles.copyText)}
          label={buttonLabel}
          color="primary"
          size="sm"
          onClick={handleCopy}
        />
      </div>
    </>
  );
};

export default OptionalInvite;

const styles = StyleSheet.create({
  optionalInvite: {
    backgroundColor: '#D6D6E4',
    border: '1px solid #26253E',
    display: 'flex',
    flexDirection: 'column',
    padding: '1.5rem',
  },
  copyText: {
    alignSelf: 'flex-end',
    width: 'auto',
  },
});

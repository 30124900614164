import React, { Component } from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Variables, Color } from '../../config/styles';

import PropTypes from 'prop-types';
import moment from 'moment';

import AdminImage from '../../components/admin/AdminImage';
import AdminWrapper from '../../components/admin/AdminWrapper';
import Button from '../../components/Button';
import CompetitionFields from '../../components/admin/CompetitionFields';
import Loader from '../../components/Loader';
import Selector from '../../components/admin/Selector';
import { withTranslation } from 'react-i18next';

class CreateCompetitionPage extends Component {
  static propTypes = {
    /**
     * Indicate if it is a new or existing competition
     */
    isLoading: PropTypes.bool.isRequired,
    /**
     * Indicate if it is a new or existing competition
     */
    isEdit: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    const { t } = props;

    this.state = {
      isSubmit: false,
      competitionTypes: [
        {
          key: 'Individual',
          text: t('competition-individual'),
        },
        {
          key: 'Team',
          text: t('competition-team'),
        },
        {
          key: 'Company',
          text: t('competition-goals'),
        },
      ],
      Type: 0,
      Title: '',
      StartDate: '',
      EndDate: '',
      Image: '',
      ImageRaw: '',
      individualState: {
        Reward: '',
        Points: '0',
        Description: '',
      },
      teamState: {
        Reward: '',
        Points: '0',
        Description: '',
      },
      companyState: {
        Points: '',
        Description: '',
      },
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.competition !== prevProps.competition && !this.state.isSubmit) {
      this.setFormData(this.props.competition);
    }
  }

  render() {
    const { competitionTypes, Type, Title, StartDate, EndDate, Image, isSubmit } = this.state;
    const { t, isLoading, isEdit } = this.props;
    return (
      <Loader isLoading={isLoading}>
        <AdminWrapper>
          <div className={css(styles.container)}>
            <header className={css(styles.header)}>
              <h1 className={css(styles.h1)}>
                {isEdit ? t('competition-edit-competition') : t('competition-create-new')}
              </h1>
            </header>
            {Type === 0 && <h2 className={css(styles.h2)}>{t('competition-choose-category')}</h2>}
            {/* TODO: INTL-anpassa Selector */}
            {!isEdit && (
              <Selector
                changeCategory={this.onChangeCompetitionType}
                categories={competitionTypes}
                activeCategory={Type}
              />
            )}
            {Type !== 0 && (
              <fieldset className={css(styles.formFieldset)}>
                <div className={css(styles.formSplitFields)}>
                  <label className={css(styles.formControl)}>
                    <span className={`form-label ${css(styles.formLabel)}`}>
                      {t('generic-text-title')} *
                    </span>
                    <input
                      type="text"
                      value={Title}
                      maxLength="200"
                      className={
                        isSubmit && Title === ''
                          ? css(styles.formInputInvalid)
                          : css(styles.formInput)
                      }
                      onChange={(e) => this.onTitleChange(e, 'Title')}
                    />
                  </label>
                  <input
                    id="image-upload"
                    type="file"
                    name="pic"
                    accept="image/*"
                    onChange={(e) => this.onChangeFiles(e.target.files)}
                    className={css(styles.imageInput)}
                  />
                  <label htmlFor="image-upload" className={css(styles.profilePictureWrapper)}>
                    <AdminImage
                      picture={Image}
                      src={Image}
                      containerStyle={styles.profilePictureContainer}
                      imgStyle={styles.profilePicture}
                    />
                    <p style={{ marginBottom: 40 }} className={css(styles.profilePictureText)}>
                      {t('competition-choose-image')}
                    </p>
                  </label>
                </div>

                {/** Fields specific to competition type */}
                <CompetitionFields
                  type={Type}
                  startDate={StartDate}
                  endDate={EndDate}
                  data={this.state[`${competitionTypes[Type - 1].key.toLowerCase()}State`]}
                  isSubmit={isSubmit}
                  inheritedStyles={styles}
                  t={t}
                  onInputChange={this.onInputChange}
                  onStartDateChange={this.onStartDateChange}
                  onEndDateChange={this.onEndDateChange}
                />
                <div className={css(styles.formButton)}>
                  <Button
                    isLoading={isLoading}
                    disabled={isLoading}
                    title={t('generic-text-save')}
                    onPress={this.onShowModal}
                  />
                </div>
              </fieldset>
            )}
          </div>
        </AdminWrapper>
      </Loader>
    );
  }

  setFormData = (data) => {
    if (this.state.isSubmit) {
      return;
    }
    const newState = {
      ...this.state,
      Type: data.type,
      Title: data.title,
      StartDate: moment(data.startdate).format('YYYY-MM-DD'),
      EndDate: moment(data.enddate).format('YYYY-MM-DD'),
      Image: data.image,
    };

    if (data.type === 1) {
      newState.individualState = {
        Reward: data.reward,
        Points: '0',
        Description: data.description,
      };
    } else if (data.type === 2) {
      newState.teamState = {
        Reward: data.reward,
        Points: '0',
        Description: data.description,
      };
    } else {
      newState.companyState = {
        Points: data.points,
        Description: data.description,
      };
    }

    this.setState(newState);
  };
  onShowModal = (e) => {
    e.preventDefault();
    const { t } = this.props;

    const postData = { ...this.state };

    // Form validation
    if (postData.Title === '') {
      alert(t('competition-fields-not-validated'));
      return;
    }
    if (postData.Points === '') {
      alert(t('competition-fields-not-validated'));
      return;
    }
    if (postData.Description === '') {
      alert(t('competition-fields-not-validated'));
      return;
    }

    // Check if points provided to company competition is a number
    if (postData.Type === 3 && isNaN(postData.companyState.Points)) {
      alert(t('competition-not-valid-points'));
      return;
    }

    // Only check date postData if team or individual competition
    if (postData.Type !== 3) {
      if (postData.Reward === '') {
        alert(t('competition-fields-not-validated'));
        return;
      }
      if (postData.StartDate === '') {
        alert(t('competition-fields-not-validated'));
        return;
      }
      if (postData.EndDate === '') {
        alert(t('competition-fields-not-validated'));
        return;
      }
      if (postData.StartDate > postData.EndDate) {
        alert(t('competition-invalid-dates'));
        return;
      }
    }
    this.onSubmitForm();
  };

  onSubmitForm = (e) => {
    if (e) {
      e.preventDefault();
    }
    // Prepare the correct state to post
    const postData = { ...this.state };
    const subState = {
      ...this.state[`${this.state.competitionTypes[this.state.Type - 1].key.toLowerCase()}State`],
    };
    postData.Reward = subState.Reward ? subState.Reward : '';
    postData.Points = subState.Points;
    postData.Description = subState.Description;

    // Format dates …
    postData.StartDate =
      postData.StartDate instanceof Date
        ? moment(postData.StartDate).format('YYYY-MM-DD')
        : postData.StartDate;
    postData.EndDate =
      postData.EndDate instanceof Date
        ? moment(postData.EndDate).format('YYYY-MM-DD')
        : postData.EndDate;

    // Remove all substates and unneccesary content for a cleaner request
    delete postData.individualState;
    delete postData.teamState;
    delete postData.companyState;
    delete postData.isSubmit;
    delete postData.competitionTypes;

    this.setState({ isSubmit: true }, () => {
      this.props.onPressSubmitForm(postData);
    });
  };

  onChangeCompetitionType = (index) => {
    this.setState({ Type: index });
  };

  onTitleChange = (e, type) => {
    const updatedForm = { ...this.state };
    updatedForm[type] = e.target.value;

    this.setState(updatedForm);
  };

  onInputChange = (e, index, inputType) => {
    const stateType = this.state.competitionTypes[index - 1].key.toLowerCase();

    const nestedState = { ...this.state[`${stateType}State`] };
    nestedState[inputType] = e.target.value;

    const updatedState = { ...this.state };
    updatedState[`${stateType}State`] = nestedState;

    this.setState(updatedState);
  };

  onStartDateChange = (date) => {
    this.setState({ StartDate: date ?? null });
  };

  onEndDateChange = (date) => {
    this.setState({ EndDate: date ?? null });
  };

  onChangeFiles = (files) => {
    const { t } = this.props;
    if (files && files[0]) {
      const reader = new FileReader();
      const img = files[0];
      const size = img.size / 1024; // KB size
      const type = img.type; // image/png
      if (size > 3000) {
        alert(t('generic-error-image-size', { maxSize: '3MB' }));
        return;
      }
      if (!(type === 'image/png' || type === 'image/jpeg')) {
        alert(t('generic-error-image-size', { imgType: type.replace('image/', '') }));
        return;
      }
      if (files[0]) {
      }
      reader.onload = (e) => {
        const newState = {
          ...this.state,
          Image: e.target.result, // Loaded image for presentation
          ImageRaw: files[0], // input File object
        };
        this.setState(newState);
      };

      reader.readAsDataURL(files[0]);
    }
  };
}

export default withTranslation()(CreateCompetitionPage);

const styles = StyleSheet.create({
  container: {
    minHeight: Variables.MainWrapperHeight,
    position: 'relative',
    backgroundColor: Color.baseGray,
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.67em 0',
  },
  header: {
    textAlign: 'center',
    marginBottom: '2.5em',
    display: 'flex',
    alignItems: 'center',
  },
  header__emphasize: {
    fontWeight: 'bold',
  },
  h1: {
    margin: '0 0.6em 0 0',
  },
  h2: {
    marginBottom: '0.5em',
  },
  addForm: {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr',
    gridTemplateRows: 'min-content min-content',
    gridGap: '2em',
  },
  formFieldset: {
    borderRadius: '2px',
    border: '0',
    boxShadow: '0 5px 17px 0 rgba(0, 0, 0, .03)',
    backgroundColor: Color.white,
    padding: '1.5em 2.5em 2em',
    margin: '1em 0 .5em',
    width: '80%',
  },
  formControl: {
    margin: '0 0 1.5em',
    display: 'block',
    ':last-of-type': {
      marginBottom: '0',
    },
  },
  formInput: {
    borderRadius: '5px',
    border: '1px solid #ccc',
    padding: '.75em .8em',
    margin: '.5em 0',
    fontSize: '1.1em',
    width: '100%',
  },
  formInputInvalid: {
    borderRadius: '5px',
    border: `1px solid ${Color.irecRed}`,
    padding: '.75em .8em',
    margin: '.5em 0',
    fontSize: '1.1em',
    width: '100%',
  },
  formSplitFields: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: '2em',
    marginBottom: '1.2em',
  },
  formField: {
    marginBottom: '1.2em',
  },
  imageInput: {
    display: 'none',
  },
  profilePicture: {
    display: 'inline-flex',
    marginLeft: 0,
    boxShadow: 'none',
  },
  profilePictureWrapper: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  profilePictureContainer: {
    display: 'inline-block',
  },
  profilePictureText: {
    fontSize: '17px',
    margin: '1em 0 1em 15px',
  },
  formTextarea: {
    minHeight: '299px',
  },
});

import { Accordion, Box, Button } from '@talentech/components';
import { ContentState, EditorState, Modifier } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { StyleSheet, css } from 'aphrodite';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CommunicationVariables from './CommunicationVariables';
import DraftForm from './DraftForm';

import { getContentState, isFieldChanged } from '../../helpers/communication';

const TabContentEditSms = (props) => {
  const { smsNotification, handleOnClickCancelEditSms, handleOnSubmitSms } = props;
  const { t } = useTranslation();
  const [formState, setFormState] = useState({
    dataChanged: false,
    templateBodyChanged: false,
    editorState: EditorState.createWithContent(ContentState.createFromText('')),
    initialState: { template_body: '' },
    currentState: { template_body: '' },
  });

  const maxCharacters = 450;
  const currentCharacterCount = formState.currentState.template_body.length;

  const setInitialData = useCallback(() => {
    if (smsNotification.template_body) {
      const contentState = getContentState(smsNotification.template_body);
      setFormState((prev) => ({
        ...prev,
        editorState: EditorState.createWithContent(contentState),
        initialState: {
          ...prev.initialState,
          template_body: smsNotification.template_body,
        },
        currentState: {
          ...prev.currentState,
          template_body: smsNotification.template_body,
        },
      }));
    }
  }, [smsNotification.template_body, setFormState]);

  useEffect(() => {
    setInitialData();
  }, [setInitialData]);

  const handleOnSubmit = () => {
    setFormState((prev) => ({
      ...prev,
      templateBodyChanged: false,
    }));
    const formattedBody = formState.currentState.template_body
      .replace(/<br>/g, '')
      .replace(/<p>/g, '')
      .replace(/<\/p>/g, '');

    let postData = formState.currentState;
    postData.template_id = smsNotification.template_id;
    postData.active = true;
    postData.template_body = formattedBody;
    postData.template_changed = formState.templateBodyChanged;
    postData.isOnDemand = true;

    handleOnSubmitSms(formState.currentState);
  };

  // Handles changes to draft
  const onInputChangeFromDraft = (editorState) => {
    let html = stateToHTML(editorState.getCurrentContent());

    const dataChanged = fieldChangeHandler({ formState });
    const templateChanged = html !== formState.initialState.template_body;

    setFormState((prev) => ({
      ...prev,
      dataChanged,
      editorState,
      templateBodyChanged: templateChanged,
      currentState: { ...prev.currentState, template_body: html },
    }));
  };

  const onInsertTextToDraft = (e) => {
    const text = e.target.parentElement.name;
    const selection = formState.editorState.getSelection();
    const contentState = formState.editorState.getCurrentContent();
    let ncs;
    if (selection.isCollapsed()) {
      ncs = Modifier.insertText(contentState, selection, text);
    } else {
      ncs = Modifier.replaceText(contentState, selection, text);
    }
    const es = EditorState.push(formState.editorState, ncs, 'insert-fragment');

    let html = stateToHTML(es.getCurrentContent());
    const dataChanged = fieldChangeHandler({ html });
    setFormState((prev) => ({
      ...prev,
      dataChanged,
      editorState: es,
      currentState: { ...prev.currentState, template_body: html },
    }));
  };

  const fieldChangeHandler = (newState) => {
    const fieldChanged = isFieldChanged(formState, newState);
    return fieldChanged;
  };

  const accordionItems = [
    {
      content: <CommunicationVariables type="sms" onInsertTextToDraft={onInsertTextToDraft} />,
      id: 'optional-invite',
      title: t('communicationedit-variables'),
    },
  ];

  return (
    <>
      <Box className="row m-0">
        <Box className="col ps-4 pe-4">
          <div className={css(styles.tabContentHeader)}>SMS</div>
        </Box>
      </Box>
      <Box className="row">
        <Box className="col ps-4 pe-4">
          <label className={css(styles.label)} htmlFor="text">
            {t('communication-preview-message').toUpperCase()}
          </label>
          <DraftForm
            editorState={formState.editorState}
            formLabel={t('communication-email-content')}
            change={onInputChangeFromDraft}
            readOnly={false}
            placeholder=""
            toolboxHidden
          />
          <p className={css(styles.characterCount)}>
            {t('communication-edit-character-count', { currentCharacterCount, maxCharacters })}
          </p>
        </Box>
      </Box>
      <Box className="row">
        <Box className="col ps-4 pe-4">
          <Accordion accordionItems={accordionItems} />
        </Box>
      </Box>
      <Box className="row">
        <Box className="col ps-4 pb-3">
          <Button
            className={css(styles.editButton)}
            label={t('generic-text-save')}
            color="primary"
            size="sm"
            onClick={handleOnSubmit}
          />
          <Button
            className={css(styles.cancelButton)}
            label={t('generic-button-cancel')}
            color="primary"
            size="sm"
            outline
            onClick={handleOnClickCancelEditSms}
          />
        </Box>
      </Box>
    </>
  );
};

export default TabContentEditSms;

const styles = StyleSheet.create({
  tabContentHeader: {
    fontWeight: 'bold',
    fontSize: '1.5rem',
    lineHeight: '1.5rem',
    marginTop: '1.5rem',
  },
  label: {
    marginBottom: '8px',
    fontWeight: 600,
    fontSize: '0.75rem',
  },
  characterCount: {
    marginTop: '0.5rem',
    fontSize: '0.625rem',
  },
  cancelButton: {
    marginLeft: '0.5rem',
  },
});

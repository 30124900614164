import { CALL_API } from '../middleware/api';

/**
 * GET company
 */
export const GET_COMPANY_REQUEST = 'GET_COMPANY_REQUEST';
export const GET_COMPANY_SUCCESS = 'GET_COMPANY_SUCCESS';
export const GET_COMPANY_FAILURE = 'GET_COMPANY_FAILURE';
export const GET_COMPANY_INVALIDATE = 'GET_COMPANY_INVALIDATE';

export function getCompany() {
  return {
    [CALL_API]: {
      endpoint: `company`,
      authenticated: true,
      types: [GET_COMPANY_REQUEST, GET_COMPANY_SUCCESS, GET_COMPANY_FAILURE],
    },
  };
}

/**
 *  Invalidate read positions to make sure they get fetched again when the view appears
 */
export const getCompanyInvalidate = () => {
  return {
    type: GET_COMPANY_INVALIDATE,
    payload: {},
  };
};

/**
 *  Only dispatches getCompany if data is invalidated or empty
 */
const shouldGetCompany = (state) => {
  const company = state.companyState.company;

  if (!Object.keys(company.response).length) {
    return true;
  }

  return company.didInvalidate;
};

export const getCompanyIfNeeded = (id) => (dispatch, getState) => {
  if (shouldGetCompany(getState())) {
    return dispatch(getCompany(id));
  }
};

/**
 *  POST information about customer (logo and career site)
 * TODO: This should probably be async as it needs to finish before we update comm service progress
 */
export const POST_CUSTOMER_INFO_REQUEST = 'POST_CUSTOMER_INFO_REQUEST';
export const POST_CUSTOMER_INFO_SUCCESS = 'POST_CUSTOMER_INFO_SUCCESS';
export const POST_CUSTOMER_INFO_FAILURE = 'POST_CUSTOMER_INFO_FAILURE';

export function postCustomerInfo(data) {
  return {
    [CALL_API]: {
      endpoint: '/customers/info',
      method: 'POST',
      authenticated: true,
      types: [POST_CUSTOMER_INFO_REQUEST, POST_CUSTOMER_INFO_SUCCESS, POST_CUSTOMER_INFO_FAILURE],
      data: data,
    },
  };
}

export const UPDATE_CUSTOMER_LAUNCH_DATE_REQUEST = 'UPDATE_CUSTOMER_LAUNCH_DATE_REQUEST';
export const UPDATE_CUSTOMER_LAUNCH_DATE_SUCCESS = 'UPDATE_CUSTOMER_LAUNCH_DATE_SUCCESS';
export const UPDATE_CUSTOMER_LAUNCH_DATE_FAILURE = 'UPDATE_CUSTOMER_LAUNCH_DATE_FAILURE';

export function updateLaunchDate(data) {
  return {
    [CALL_API]: {
      endpoint: '/customers/reset/golive',
      method: 'POST',
      authenticated: true,
      types: [
        UPDATE_CUSTOMER_LAUNCH_DATE_REQUEST,
        UPDATE_CUSTOMER_LAUNCH_DATE_SUCCESS,
        UPDATE_CUSTOMER_LAUNCH_DATE_FAILURE,
      ],
      data: data,
    },
  };
}

export const GET_CUSTOMER_INSIGHTS_REQUEST = 'GET_CUSTOMER_INSIGHTS_REQUEST';
export const GET_CUSTOMER_INSIGHTS_SUCCESS = 'GET_CUSTOMER_INSIGHTS_SUCCESS';
export const GET_CUSTOMER_INSIGHTS_FAILURE = 'GET_CUSTOMER_INSIGHTS_FAILURE';

export function getInsights() {
  return {
    [CALL_API]: {
      endpoint: '/customers/insights',
      authenticated: true,
      types: [
        GET_CUSTOMER_INSIGHTS_REQUEST,
        GET_CUSTOMER_INSIGHTS_SUCCESS,
        GET_CUSTOMER_INSIGHTS_FAILURE,
      ],
    },
  };
}

import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMultipleRecommendationsForPosition } from '../../irecommend-lib/actions/recommendationActions';

const useFetchPositionRecommendations = (positions) => {
  const { response: recommendationsForPositions, isLoading: isRecommendationsLoading } =
    useSelector((state) => state.recommendationState.recommendationsForPositions);
  const dispatch = useDispatch();

  useEffect(() => {
    if (positions.length > 0) {
      for (const position of positions) {
        dispatch(getMultipleRecommendationsForPosition(position.idpositions));
      }
    }
  }, [dispatch, positions]);

  const recommendations = useMemo(() => {
    return Object.keys(recommendationsForPositions).length > 0 ? recommendationsForPositions : [];
  }, [recommendationsForPositions]);

  return { recommendations, isRecommendationsLoading };
};

export default useFetchPositionRecommendations;

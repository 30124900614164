import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { storeCredentials } from '../../api';
import { uploadPicture as uploadPictureAction } from '../../irecommend-lib/actions/mediaActions';
import {
  postUser as postUserData,
  postUserCategories as postCategories,
} from '../../irecommend-lib/actions/userActions';

const useSubmitProfileSettings = (state, setState, user) => {
  const { postUser, postUserCategories, uploadPicture } = useSelector((state) => ({
    postUser: state.userState.postUser,
    postUserCategories: state.userState.postUserCategories,
    uploadPicture: state.mediaState.uploadPicture,
  }));
  const history = useHistory();
  const dispatch = useDispatch();
  const isPostDataLoading = Boolean(
    postUser.isLoading || postUserCategories.isLoading || uploadPicture.isLoading,
  );

  useEffect(() => {
    if (
      (Object.keys(postUser.response).length > 0 && postUser.response?.status !== 200) ||
      (postUserCategories.request && postUserCategories.request.status !== 200)
    )
      setState((prev) => ({ ...prev, error: true }));

    if (Object.keys(postUser.response).length > 0 && postUser.response?.status === 200) {
      const isAdminUser = user.response.user?.usertype !== 0;
      try {
        storeCredentials({ registered: true }, true).then(() =>
          history.push(isAdminUser ? '/admin' : '/'),
        );
      } catch (error) {
        console.log(error);
      }
    }
  }, [postUser.response, user.response, postUserCategories.request, setState, history]);

  useEffect(() => {
    // If there is a picture, post user data after the picture is uploaded
    if (uploadPicture.response && uploadPicture.response?.status === 200) {
      // Remove the raw picture from the formData
      delete state.formData.PictureRaw;
      dispatch(
        postUserData(
          { ...state.formData, Picture: uploadPicture.response.data.secure_url, Registered: true },
          user.response.user.iduser,
          true,
        ),
      );
    }
  }, [setState, uploadPicture.response, dispatch, state.formData, user.response, uploadPicture]);

  const postData = () => {
    // If there is a picture, upload it
    if (state.formData.PictureRaw) dispatch(uploadPictureAction(state.formData.PictureRaw));
    else
      dispatch(
        postUserData({ ...state.formData, Registered: true }, user.response.user.iduser, true),
      );

    // If there is no categories in the env, dont make the call
    if (state.selectedCategories.length > 0)
      dispatch(postCategories(user.response.user.iduser, state.selectedCategories));
  };

  return { isPostDataLoading, postData };
};

export default useSubmitProfileSettings;

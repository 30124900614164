import { StyleSheet, css } from 'aphrodite';
import { Box, Tabs } from '@talentech/components';

import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { ContentState, EditorState } from 'draft-js';
import { getSinglePremadeTemplate } from '../../irecommend-lib/actions/communicationActions';
import { ModalForm } from '../../components/admin/ModalForm';
import CommunicationPreviewFields from './CommunicationPreviewFields';

import PageHeader from '../../components/admin/PageHeader';
import TabContentMail from '../../components/admin/TabContentMail';
import TabContentPush from '../../components/admin/TabContentPush';
import TabContentEditMail from '../../components/admin/TabContentEditMail';
import TabContentEditPush from '../../components/admin/TabContentEditPush';
import CommunicationEditScheduleFields from '../../components/admin/CommunicationEditScheduleFields';
import Loader from '../../components/Loader';

import useSetupUserflow from '../../hooks/helper/useSetupUserflow';
import useFetchCommunication from '../../hooks/communication/useFetchCommunication';
import useUpdateEmail from '../../hooks/communication/useUpdateEmail';
import useUpdatePushNotification from '../../hooks/communication/useUpdatePushNotification';
import useFetchBreadcrumb from '../../hooks/helper/useFetchBreadcrumb';

import { isValidData, isValidSmsData } from '../../helpers/communication';
import { getDateAndTimeFromTimestamp } from '../../helpers/date';
import { useTranslation } from 'react-i18next';
import useUpdateSmsNotification from '../../hooks/communication/useUpdateSmsNotification';
import TabContentSms from '../../components/admin/TabContentSms';
import TabContentEditSms from '../../components/admin/TabContentEditSms';

const CommunicationEditPage = (props) => {
  const [isEditMail, setIsEditMail] = useState(false);
  const [isEditPush, setIsEditPush] = useState(false);
  const [isEditSms, setIsEditSms] = useState(false);
  const [formState, setFormState] = useState({
    emailState: {
      dataChanged: false,
      templateChanged: false,
      editorState: EditorState.createWithContent(ContentState.createFromText('')),
      initialState: { template_HTML: '', subject: '' },
      currentState: { template_HTML: '', subject: '' },
      date: '',
      hours: '',
      minutes: '',
      interval: 0,
    },
  });

  const [isDefaultContentModalOpen, setIsDefaultContentModalOpen] = useState(false);
  const singlePremadeTemplate = useSelector(
    (state) => state.communicationState.singlePremadeTemplate,
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { match } = props;
  const { userflowIsLoading } = useSetupUserflow();
  const { updateEmail, updateIsLoading } = useUpdateEmail(match.params.id, setIsEditMail);
  const { communicationData, isLoading, isOnDemand } = useFetchCommunication(match, t);
  const {
    emailResponse,
    pushNotificationResponse,
    smsNotificationResponse,
    onlyPushType,
    onlyEmailType,
    isLaunchDateSet,
    titleKey,
    recipientKey,
    isLaunchType,
    isInvitationEmail,
    isRecurringType,
    sendTimeKey,
    emailAndPushType,
    emailAndSmsType,
    isLaunched,
  } = communicationData;

  const { updatePush, updatePushIsLoading } = useUpdatePushNotification(
    pushNotificationResponse._id,
    setIsEditPush,
  );

  const { updateSms, updateSmsIsLoading } = useUpdateSmsNotification(
    smsNotificationResponse._id,
    setIsEditSms,
  );

  const breadcrumbs = useFetchBreadcrumb('communicationEdit', t, titleKey);

  const isPageLoading =
    userflowIsLoading || isLoading || updateIsLoading || updatePushIsLoading || updateSmsIsLoading;
  const preventCustomization =
    !isEditMail || ((emailAndSmsType || emailAndPushType) && isOnDemand) || isInvitationEmail;
  const isButtonVisible =
    emailResponse.hasOwnProperty('template_default') && !emailResponse.template_default;

  const handleOnClickEditMail = () => {
    setIsEditMail(true);
    scrollToTop();
  };

  const handleOnClickEditPush = () => {
    setIsEditPush(true);
    scrollToTop();
  };

  const handleOnClickEditSms = () => {
    setIsEditSms(true);
    scrollToTop();
  };

  const handleOnSubmitMail = (data) => {
    if (!isOnDemand && isLaunchDateSet) data.timestamp = calculatedDate(); // Calculates NaN if date is not set
    if (isValidData(data, t)) updateEmail(data, isOnDemand);
    scrollToTop();
  };

  const handleOnSubmitPush = (data) => {
    if (isValidData(data, t)) updatePush(data);
    scrollToTop();
  };

  const handleOnSubmitSms = (data) => {
    isValidSmsData(data, t) && updateSms(data);
    scrollToTop();
  };

  const handleOnClickCancelEditMail = () => {
    setIsEditMail(false);
    scrollToTop();
  };

  const handleOnClickCancelEditPush = () => {
    setIsEditPush(false);
    scrollToTop();
  };

  const handleOnClickCancelEditSms = () => {
    setIsEditSms(false);
    scrollToTop();
  };

  const handlePreviewDefaultContent = (e) => {
    e.preventDefault();
    const customerID = emailResponse.idcustomer;
    dispatch(
      getSinglePremadeTemplate({
        template_unique_name: emailResponse.template_unique_name,
        idcustomer: customerID,
      }),
    );
    setIsDefaultContentModalOpen(true);
  };

  const handleSubmitForm = () => {
    setIsDefaultContentModalOpen(false);
  };

  const calculatedDate = () => {
    const dateStr = formState.emailState.date;
    const timeStr = `${formState.emailState.hours}:${formState.emailState.minutes}`;

    // Combine date and time into a Date object
    const combinedDateTime = new Date(`${dateStr} ${timeStr}`);

    // Convert the result datetime to seconds since epoch
    const resultSecondsSinceEpoch = Math.floor(combinedDateTime.getTime() / 1000);

    return resultSecondsSinceEpoch;
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const createTabs = () => {
    let tabs = [
      {
        title: t('communication-item-channel-email'),
        content: !isEditMail ? (
          <TabContentMail
            email={emailResponse}
            handleOnClickEditMail={handleOnClickEditMail}
            handlePreviewDefaultContent={handlePreviewDefaultContent}
            isButtonVisible={isButtonVisible}
          />
        ) : (
          <TabContentEditMail
            email={emailResponse}
            handleOnSubmitMail={handleOnSubmitMail}
            setFormState={setFormState}
            emailFormState={formState.emailState}
            handleOnClickCancelEditMail={handleOnClickCancelEditMail}
          />
        ),
      },
      {
        title: t('communication-item-channel-push'),
        content: !isEditPush ? (
          <TabContentPush
            pushNotification={pushNotificationResponse}
            isEditPush={isEditPush}
            handleOnClickEditPush={handleOnClickEditPush}
          />
        ) : (
          <TabContentEditPush
            pushNotification={pushNotificationResponse}
            handleOnClickCancelEditPush={handleOnClickCancelEditPush}
            handleOnSubmitPush={handleOnSubmitPush}
          />
        ),
      },
      {
        title: 'SMS',
        content: !isEditSms ? (
          <TabContentSms
            smsNotification={smsNotificationResponse}
            isEditSms={isEditSms}
            handleOnClickEditSms={handleOnClickEditSms}
          />
        ) : (
          <TabContentEditSms
            smsNotification={smsNotificationResponse}
            handleOnClickCancelEditSms={handleOnClickCancelEditSms}
            handleOnSubmitSms={handleOnSubmitSms}
          />
        ),
      },
    ];

    if (onlyPushType) {
      tabs = [tabs[1]];
    }
    if (onlyEmailType) {
      tabs = [tabs[0]];
    }
    if (emailAndPushType) {
      tabs = [tabs[0], tabs[1]];
    }
    if (emailAndSmsType) {
      tabs = [tabs[0], tabs[2]];
    }

    return tabs;
  };

  return (
    <Loader isLoading={isPageLoading}>
      <PageHeader title={t(titleKey)} breadcrumbs={breadcrumbs} />
      {isDefaultContentModalOpen && (
        <ModalForm
          showModalForm={true}
          title={t('communication-original-template')}
          isVisible={isDefaultContentModalOpen}
          content={
            <CommunicationPreviewFields
              defaultContent={singlePremadeTemplate.response.template?.html}
              defaultSubject={singlePremadeTemplate.response?.subject}
              t={t}
            />
          }
          labelSubmit={t('generic-button-close')}
          handleSubmit={handleSubmitForm}
        />
      )}
      <div className={css(styles.pageWrapper)}>
        <Box className="col bg-gray-50 shadow-sm rounded-1 p-4">
          <Box className="col">
            <p className={css(styles.emailPropertiesHeader)}>
              {t('communication-edit-recipients')}
            </p>
            <p className={css(styles.emailPropertiesText)}>{recipientKey && t(recipientKey)}</p>
          </Box>
          <Box className="col mt-4">
            <Box className="row my-2">
              {!isOnDemand && isLaunchDateSet ? (
                <CommunicationEditScheduleFields
                  t={t}
                  preventCustomization={preventCustomization}
                  emailAndPushType={emailAndPushType}
                  emailResponse={emailResponse}
                  isInvitationEmail={isInvitationEmail}
                  isRecurringType={isRecurringType}
                  timestamp={emailResponse.timestamp}
                  emailState={formState.emailState}
                  setFormState={setFormState}
                />
              ) : (
                <Box className="col p-0">
                  <p className={css(styles.emailPropertiesHeader)}>
                    {t('communication-edit-when')}
                  </p>
                  <p className={css(styles.emailPropertiesDateText)}>
                    {/* TODO: Fix trigger name */}
                    {isLaunchDateSet && !isLaunched && isLaunchType
                      ? `${getDateAndTimeFromTimestamp(emailResponse.timestamp)?.date}`
                      : t(sendTimeKey)}
                  </p>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        <Box className="col-sm-12 col-md-8 bg-gray-50 shadow-sm rounded-1">
          <Tabs id="communication-channels" tabs={createTabs()} />
        </Box>
      </div>
    </Loader>
  );
};

export default withRouter(CommunicationEditPage);

const styles = StyleSheet.create({
  pageWrapper: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    margin: '2rem',
    alignItems: 'flex-start',

    '@media (max-width: 768px)': {
      flexDirection: 'column',
      justifyContent: 'center',

      '& > .col': {
        width: '100%',
      },
    },
  },
  emailPropertiesHeader: {
    fontWeight: 'lighter',
    fontSize: '0.75rem',
    color: '#60606C',
    margin: 0,
    lineHeight: '2rem',
  },
  emailPropertiesText: {
    fontWeight: 'bold',
    margin: 0,
  },
  emailPropertiesDateText: {
    fontWeight: 600,
    margin: 0,
  },
  tabContentwrapper: {
    padding: '1.5rem',
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
  },
});

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ApplicationBar, getAvatarUrlBySha256 } from '@talentech/components';

import { TALENTECH_ICONS } from '../components/Icons';

import { logoutUser } from '../irecommend-lib/actions/authActions';
import { getTeams } from '../irecommend-lib/actions/teamActions';
import Utils from '../irecommend-lib/utils';
import {
  getUser,
  getUserTenants,
  postUser as postUserData,
} from '../irecommend-lib/actions/userActions';

import { getCredentialsSync } from '../api';
import { fetchUserMenu } from '../helpers/data';
import { userMenuLanguageMap, supportedLanguages } from '../helpers/language';
import { getActiveTenantFromToken } from '../helpers/utils';
import i18next from 'i18next';

const AppBar = (props) => {
  const { user, postUser, userTenants } = useSelector((state) => state.userState);
  const { teams } = useSelector((state) => state.teamState);
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const selectedLanguage = userMenuLanguageMap.filter((lang) => lang.key === i18next.language);
  const credentials = getCredentialsSync();
  const activeTenant = getActiveTenantFromToken(credentials);

  const workspaceDescription =
    Object.keys(userTenants?.response).length > 0
      ? userTenants.response.tenants
          ?.filter((item) => item.id === activeTenant)
          .map((item) => item.name)
      : [];

  const teamDescription =
    teams.response.length > 0 && Object.keys(user.response).length > 0
      ? teams.response?.filter(
          (item) => item.idteam === Utils.getIdFromHref(user.response.user?.team.href),
        )[0]?.name
      : null;

  const teamList =
    teams.response.length > 0 && Object.keys(user.response).length > 0
      ? teams.response?.map((item) => ({
          ...item,
          label: item.name,
          active: item.idteam === Utils.getIdFromHref(user.response.user?.team.href),
          onClick: (e) => changeTeam(e, item.idteam),
        }))
      : [];

  const tenantList = userTenants.response.tenants?.map((item) => ({
    ...item,
    label: item.name,
    active: item.name === workspaceDescription[0],
    onClick: (e) => {
      e.preventDefault();
      window.location = `/${process.env.REACT_APP_TID_LOGIN_PATH}?user=${user.response.user?.mail}&tenant=${item.id}`;
    },
  }));

  const languageList = userMenuLanguageMap.map((item) => ({
    ...item,
    label: item.label,
    active: item.label === selectedLanguage[0]?.label,
    onClick: () => onChangeLanguage(item.key),
  }));

  useEffect(() => {
    dispatch(getTeams());
    dispatch(getUserTenants());
  }, [dispatch]);

  useEffect(() => {
    if (postUser.request && postUser.request.status === 200) dispatch(getUser());
  }, [postUser.request, dispatch]);

  let menuItems = [
    {
      icon: TALENTECH_ICONS.switchWorkSpace,
      title: t('user-menu-switch-workspace'),
      subtitle: workspaceDescription,
      submenu: tenantList,
    },
    {
      icon: TALENTECH_ICONS.manageAccount,
      title: t('user-menu-manage-account'),
      subtitle: user.response.user?.mail,
      onClick: () => history.push('/profile/edit'),
    },
    {
      icon: TALENTECH_ICONS.changeTeam,
      title: t('profileeditpage-team'),
      subtitle: teamDescription,
      submenu: teamList,
    },
    {
      icon: TALENTECH_ICONS.changeLanguage,
      title: t('user-menu-manage-language'),
      subtitle: selectedLanguage.length > 0 && selectedLanguage[0].label,
      submenu: languageList,
    },
    {
      icon: TALENTECH_ICONS.signOut,
      title: t('user-menu-manage-signout'),
      onClick: () => dispatch(logoutUser()),
    },
  ];
  //if tenant request fails, hide 'Switch Workspace' part
  menuItems =
    userTenants.request && userTenants.request.status !== 500 ? menuItems : [...menuItems.slice(1)];

  const onChangeLanguage = (language) => {
    if (supportedLanguages.indexOf(language) === -1) return;
    localStorage.setItem('i18nextLng', language);
    i18next.changeLanguage(language);
    window.location.reload();
  };

  const changeTeam = (e, href) => {
    e.preventDefault();
    dispatch(
      postUserData(
        {
          Familyname: user.response.user.familyname,
          Givenname: user.response.user.givenname,
          Mail: user.response.user.mail,
          Picture: user.response.user.picture,
          Team: href,
          Registered: true,
        },
        user.response.user?.iduser,
        true,
      ),
    );
  };

  return (
    <ApplicationBar
      firstName={user.response.user?.givenname}
      lastName={user.response.user?.familyname}
      menu={fetchUserMenu(menuItems)}
      toolboxItems={[]}
      toggleSidebar={props.toggleSidebar}
      isCollapsed={props.isCollapsed}
      activeWorkspace={workspaceDescription}
      avatarUrl={getAvatarUrlBySha256(`${credentials.idTokenPayload?.sub}`, false)}
    />
  );
};

export default AppBar;

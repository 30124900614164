import { CHANGE_APP_ROOT, INITIALIZE_APP } from '../actions/appActions';

const initialState = {
  isInitialized: false,
  root: null,
};

export default function appState(state = initialState, { payload, type }) {
  switch (type) {
    case CHANGE_APP_ROOT:
      return {
        ...state,
        root: payload.root,
      };
    case INITIALIZE_APP:
      return {
        ...state,
        isInitialized: payload.initialized,
      };
    default:
      return state;
  }
}

import React, { Component } from 'react';
import { StyleSheet } from 'aphrodite';
import { Color } from '../../config/styles';

import AdminWrapper from '../../components/admin/AdminWrapper';
import CompanyCompetitionDetails from '../../components/admin/CompanyCompetitionDetails';
import CompetitionDetails from '../../components/admin/CompetitionDetails';
import Loader from '../../components/Loader';

class CompetitionDetailPage extends Component {
  render() {
    const { t, competition, companyPoints, leaderboardUsers, isLoading } = this.props;

    const competitionStarted =
      Math.round((Date.parse(competition.startdate) - new Date()) / 86400000) < 0;
    const daysLeft = competitionStarted
      ? Math.round((Date.parse(competition.enddate) - new Date()) / 86400000)
      : t('competition-not-started');

    const leaderPoints = this.props.leaderboardUsers[0] ? this.props.leaderboardUsers[0].points : 0;

    let competitionType;
    switch (competition.type) {
      case 1:
        competitionType = t('competition-detail-individual-competition');
        break;
      case 2:
        competitionType = t('competition-detail-team-competition');
        break;
      case 3:
        competitionType = t('competition-detail-company-goal');
        break;
      default:
        competitionType = t('competition-detail-individual-competition');
    }

    return (
      <Loader isLoading={isLoading}>
        <AdminWrapper>
          {competition.type === 3 ? (
            <CompanyCompetitionDetails
              competition={competition}
              competitionType={competitionType}
              companyPoints={companyPoints}
              t={t}
            />
          ) : (
            <CompetitionDetails
              competition={competition}
              competitionType={competitionType}
              leaderboardUsers={leaderboardUsers}
              daysLeft={daysLeft}
              competitionStarted={competitionStarted}
              leaderPoints={leaderPoints}
              t={t}
              inheritedStyles={styles}
            />
          )}
        </AdminWrapper>
      </Loader>
    );
  }
}

export default CompetitionDetailPage;

const styles = StyleSheet.create({
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 0.8fr 0.8fr',
    gridGap: '2em 2em',
    marginBottom: '1rem',
  },
  heading: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    margin: '0 0 0 0.875rem',
  },
  subtitle: {
    marginTop: '0.375rem',
  },
  boldFont: {
    fontSize: '1.0625rem',
    fontWeight: '600',
    lineHeight: '1.352941176',
    flexBasis: '100%',
  },
  boldHeading: {
    paddingBottom: '0.5em',
    display: 'block',
  },
  leaderboard: {
    gridColumnStart: '1',
    gridColumnEnd: '1',
    gridRowStart: '1',
    gridRowEnd: '3',
  },
  rewards: {
    gridColumnStart: '2',
    gridColumnEnd: '4',
    gridRowStart: '2',
    gridRowEnd: '2',
  },
  description: {
    gridColumnStart: '1',
    gridColumnEnd: '4',
    borderRadius: '2px',
    border: '0',
    boxShadow: '0 5px 17px 0 rgba(0, 0, 0, .03)',
    backgroundColor: Color.white,
    padding: '1.5em 2.5em 2em',
  },
  box: {
    borderRadius: '2px',
    border: '0',
    boxShadow: '0 5px 17px 0 rgba(0, 0, 0, .03)',
    backgroundColor: Color.white,
    padding: '1.5em 2.5em 2em',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'left',
    alignItems: 'center',
  },
});

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Color, Font } from '../config/styles';
import { css, StyleSheet } from 'aphrodite';

/**
 * A basic button component with specific appearance for iRecommend.
 *
 * Example usage:
 *
 * ```
 * <Select
 *   onPress={onPressLearnMore}
 *   placeholder="Choose team"
 *   data="[{value: '124123', title: 'Team1'}, {value: '123123', title: 'Team2'}]"
 * />
 * ```
 *
 */
export default class Select extends Component {
  constructor(props) {
    super(props);

    this.state = { value: 'select' };
  }

  static propTypes = {
    /**
     * Select options
     */
    optionsData: PropTypes.array,
    /**
     * Text as placeholder
     */
    placeholder: PropTypes.string,
    /**
     * Theme: light or dark. default: dark
     */
    theme: PropTypes.string,
    /**
     * Value for the option that should be selected. "placeholder" triggers placeholder-text to be selected as default
     */
    selected: PropTypes.string,
    /**
     * Custom styles to be applied to the button.
     */
    style: PropTypes.object,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.selected !== nextProps.selected) {
      this.setState({
        value: nextProps.selected,
      });
    }
  }

  render() {
    const { theme } = this.props;

    let placeholder;

    if (this.props.placeholder) {
      placeholder = (
        <option key={-1} value="placeholder">
          {this.props.placeholder}
        </option>
      );
    }

    const listOptions = this.props.optionsData.map((item, index) => (
      <option key={index} value={item.value}>
        {item.title}
      </option>
    ));

    return (
      <select
        value={this.state.value}
        onChange={(e) => {
          this.setState({ value: e.target.value });
          this.props.onChange(e.target.value);
        }}
        className={css(
          window.innerWidth > 500 ? styles.selectDesktop : styles.select,
          theme === 'light' ? styles.light : null,
          this.props.style,
        )}
      >
        {placeholder}
        {listOptions}
      </select>
    );
  }
}

const styles = StyleSheet.create({
  select: {
    backgroundColor: 'transparent',
    width: '100%',
    padding: 17,
    borderTop: 'none',
    borderRight: 'none',
    borderLeft: 'none',
    borderBottom: '1px solid rgba(0,0,0,0.3)',
    color: Color.dark,
    paddingLeft: 0,
    fontFamily: Font.defaultFont,
    borderRadius: 0,
  },
  selectDesktop: {
    width: '100%',
  },
  light: {
    color: Color.dark,
    fontFamily: Font.defaultFont,
  },
});

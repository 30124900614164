import { StyleSheet } from 'aphrodite';
import {
  Color,
  webFont as Font,
  webDefaultText as defaultTextRaw,
  Variables,
  getStatus,
  getStatusList,
} from '../irecommend-lib/styles';

let defaultText = StyleSheet.create(defaultTextRaw);

const sharedStyles = StyleSheet.create({
  logoImage: {
    width: '238px',
    display: 'block',
    margin: '0 auto 1.5em',
  },
});

export { Color, Font, defaultText, Variables, getStatus, getStatusList, sharedStyles };

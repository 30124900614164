import { css, StyleSheet } from 'aphrodite';
import React from 'react';

const PositionsList = (props) => {
  const { positions } = props;

  return (
    <div className={css(styles.positionsInnerWrapper)}>
      {positions.map((pos) => (
        <div
          key={pos.idpositions}
          onClick={() => props.onPressListItem(pos.idpositions)}
          className={css(styles.positionsListItem)}
        >
          <div className={css(styles.positionsListItemHeader)}>
            <p className={css(styles.positionsListItemTitle)}>{pos.title}</p>
          </div>
          <p className={css(styles.positionsListItemLocation)}>{pos.joblocation}</p>
          <p className={css(styles.positionsListItemReward)}>Reward: {pos.reward}</p>
        </div>
      ))}
    </div>
  );
};

export default PositionsList;

const styles = StyleSheet.create({
  positionsInnerWrapper: {
    margin: '1.2rem',
    border: '1px rgba(214, 214, 220, 1) solid',
    borderBottom: 'none',
    borderRadius: '4px',
  },
  positionsListItem: {
    borderBottom: '1px rgba(214, 214, 220, 1) solid',
    padding: '1.2rem',
    cursor: 'pointer',
  },
  positionsListItemHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  positionsListItemTitle: {
    fontSize: '1rem',
    fontWeight: 600,
    marginBottom: 0,
    lineHeight: 1.5,
  },
  positionsListItemBadge: {
    fontSize: '0.725rem',
    background: '#E1F6E9',
    padding: '4px 8px',
    borderRadius: '4px',
    fontWeight: 600,
  },
  positionsListItemLocation: {
    fontSize: '0.8rem',
  },
  positionsListItemReward: {
    marginBottom: 0,
  },
});

import { useHistory } from 'react-router-dom';
import { css, StyleSheet } from 'aphrodite';
import { useTranslation } from 'react-i18next';

import { Color, Font } from '../../config/styles';
import AdminWrapper from '../../components/admin/AdminWrapper';
import FaqItem from '../../components/admin/FaqItem';
import Link from '../../components/admin/GetStartedLink';
import { IntlNetwork } from '../../helpers/language';
import PageHeader from '../../components/admin/PageHeader';

const FaqPage = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const navigateToAdmin = () => {
    history.push('/admin');
  };

  const createBreadcrumb = () => {
    return [
      {
        label: t('admin-header-dashboard'),
        onClick: () => navigateToAdmin(),
      },
      {
        label: t('admin-header-FAQ'),
        isActive: true,
      },
    ];
  };

  return (
    <>
      <PageHeader title={t('admin-header-FAQ')} breadcrumbs={createBreadcrumb()} />
      <AdminWrapper>
        <div className={css(styles.pageWrap)}>
          <div className={css(styles.centered)}>
            <p className={css(styles.p)}>{t('faq-intro')}</p>
          </div>
          <FaqItem title={t('faq-irec-included-title')} styles={styles} tag="included">
            <ul className={css(styles.list)}>
              <li>{IntlNetwork(t, 'faq-irec-included-1')}</li>
              <li>{t('faq-irec-included-2')}</li>
              <li>{t('faq-irec-included-3')}</li>
              <li>{IntlNetwork(t, 'faq-irec-included-4')}</li>
              <li>{t('faq-irec-included-5')}</li>
              <li>{t('faq-irec-included-6')}</li>
              <li>{IntlNetwork(t, 'faq-irec-included-7')}</li>
              <li>{IntlNetwork(t, 'faq-irec-included-8')}</li>
            </ul>
          </FaqItem>
          <FaqItem title={t('faq-irec-greeting-title')} styles={styles} tag="greeting">
            <p>{IntlNetwork(t, 'faq-irec-greeting-1')}</p>
            <p>{IntlNetwork(t, 'faq-irec-greeting-2')}</p>
            <p>{IntlNetwork(t, 'faq-irec-greeting-3')}</p>
            <p>{t('faq-irec-greeting-end')}</p>
            <div className={css(styles.doubleCol)}>
              <div>
                <h2 className={css(styles.name)}>Linus Andersson</h2>
                <p>{t('faq-irec-founder')} irecommend</p>
              </div>
              <div>
                <h2 className={css(styles.name)}>Fabian Widén</h2>
                <p>{t('faq-irec-founder')} irecommend</p>
              </div>
            </div>
          </FaqItem>
          <FaqItem title={t('faq-startmeeting-title')} styles={styles} tag="agenda">
            <div className={css(styles.faqitem)}>
              <ol className={css(styles.list)}>
                <li className={css(styles.olItem)}>{t('faq-startmeeting-1')}</li>
                <li className={css(styles.olItem)}>{t('faq-startmeeting-2')}</li>
                <li className={css(styles.olItem)}>{t('faq-startmeeting-3')}</li>
                <li className={css(styles.olItem)}>{IntlNetwork(t, 'faq-startmeeting-4')}</li>
                <li className={css(styles.olItem)}>{IntlNetwork(t, 'faq-startmeeting-5')}</li>
              </ol>
              <ol start="6" className={css(styles.list)}>
                <li className={css(styles.olItem)}>{IntlNetwork(t, 'faq-startmeeting-6')}</li>
                <li className={css(styles.olItem)}>{t('faq-startmeeting-7')}</li>
                <li className={css(styles.olItem)}>{t('faq-startmeeting-8')}</li>
                <li className={css(styles.olItem)}>{t('faq-startmeeting-9')}</li>
                <li className={css(styles.olItem)}>{t('faq-startmeeting-10')}</li>
                <li className={css(styles.olItem)}>{t('faq-startmeeting-11')}</li>
              </ol>
            </div>
            <Link to="/admin/faq#presentations">{t('get-started-managers-pres')}</Link>
          </FaqItem>
          <FaqItem title={IntlNetwork(t, 'faq-info-email1-title')} styles={styles} tag="email">
            <p>{IntlNetwork(t, 'faq-info-email1-bread')}</p>
            <p>{t('faq-info-email1-communication')}</p>
            <ul>
              <li>{IntlNetwork(t, 'faq-info-email1-board')}</li>
              <li>{IntlNetwork(t, 'faq-info-email1-mgmt')}</li>
              <li>{t('faq-info-email1-ambas')}</li>
            </ul>
            <hr />
            <div className={css(styles.email)}>
              <p>{t('faq-info-email1-subject')}</p>
              <p>{t('faq-info-email1-1')}</p>
              <p>{IntlNetwork(t, 'faq-info-email1-2')}</p>
              <p>{t('faq-info-email1-3')}</p>
              <ul>
                <li>{t('faq-info-emaillist-1')}</li>
                <li>{IntlNetwork(t, 'faq-info-emaillist-2')}</li>
                <li>{IntlNetwork(t, 'faq-info-emaillist-3')}</li>
                <li>{t('faq-info-emaillist-4')}</li>
              </ul>
              <p>{IntlNetwork(t, 'faq-info-email1-4')}</p>
              <ul>
                <li>{t('faq-info-emaillist-5')}</li>
                <li>{t('faq-info-emaillist-6')}</li>
                <li>{t('faq-info-emaillist-7')}</li>
                <li>{IntlNetwork(t, 'faq-info-emaillist-8')}</li>
                <li>{IntlNetwork(t, 'faq-info-emaillist-9')}</li>
              </ul>
              <p>{t('faq-info-email1-5')}</p>
              <p>{t('faq-info-email1-6')}</p>
            </div>
          </FaqItem>
          <FaqItem title={t('faq-competition-title')} styles={styles} tag="competitions">
            <p className={css(styles.p)}>{t('faq-competition-bread')}</p>
            <p>{t('faq-competition-tooltip1')}</p>
            <p>{t('faq-competition-tooltip2')}</p>
            <div className={css(styles.tripleCol)}>
              <div>
                <h2 className={css(styles.competitionTitle)}>{t('faq-competition-indi-title')}</h2>
                <ul>
                  <li>{t('faq-competition-indi-item1')}</li>
                  <li>{t('faq-competition-indi-item2')}</li>
                  <li>{t('faq-competition-indi-item3')}</li>
                  <li>{t('faq-competition-indi-item4')}</li>
                  <li>{t('faq-competition-indi-item5')}</li>
                  <li>{t('faq-competition-indi-item6')}</li>
                  <li>{t('faq-competition-indi-item7')}</li>
                  <li>{t('faq-competition-indi-item8')}</li>
                  <li>{t('faq-competition-indi-item9')}</li>
                </ul>
              </div>
              <div>
                <h2 className={css(styles.competitionTitle)}>{t('faq-competition-team-title')}</h2>
                <ul>
                  <li>{t('faq-competition-team-item1')}</li>
                  <li>{t('faq-competition-team-item2')}</li>
                  <li>{t('faq-competition-team-item3')}</li>
                  <li>{IntlNetwork(t, 'faq-competition-team-item4')}</li>
                  <li>{t('faq-competition-team-item5')}</li>
                  <li>{t('faq-competition-team-item6')}</li>
                </ul>
              </div>
              <div>
                <h2 className={css(styles.competitionTitle)}>{t('faq-competition-comp-title')}</h2>
                <ul>
                  <li>{t('faq-competition-comp-item1')}</li>
                  <li>{t('faq-competition-comp-item2')}</li>
                  <li>{t('faq-competition-comp-item3')}</li>
                  <li>{t('faq-competition-comp-item4')}</li>
                  <li>{t('faq-competition-comp-item5')}</li>
                </ul>
              </div>
            </div>
          </FaqItem>
          <FaqItem
            title={IntlNetwork(t, 'faq-engage-managers-title')}
            styles={styles}
            tag="managers"
          >
            <ul className={css(styles.list)}>
              <li>{IntlNetwork(t, 'faq-engage-managers-item1')}</li>
              <li>{IntlNetwork(t, 'faq-engage-managers-item2')}</li>
              <li>{IntlNetwork(t, 'faq-engage-managers-item3')}</li>
            </ul>
            <Link to="/admin/faq#presentations">{t('faq-appendix')} 2</Link>
            <div className={css(styles.imageWrap)}>
              <img
                className={css(styles.img)}
                src={t('faq-engage-managers-img')}
                alt="app-mockup"
              />
            </div>
          </FaqItem>
          <FaqItem title={IntlNetwork(t, 'faq-launch-title')} styles={styles} tag="launch">
            <p className={css(styles.p)}>{IntlNetwork(t, 'faq-launch-bread')}</p>
            <p className={css(styles.p)}>{IntlNetwork(t, 'faq-launch-structure')}</p>
            <ul className={css(styles.list)}>
              <li>{t('faq-launch-structure-item1')}</li>
              <li>{t('faq-launch-structure-item2')}</li>
              <li>{IntlNetwork(t, 'faq-launch-structure-item3')}</li>
            </ul>
            <Link to="/admin/faq#presentations">{t('get-started-managers-pres')}</Link>
          </FaqItem>
          <FaqItem title={t('faq-sms-title')} styles={styles} tag="sms">
            <p className={css(styles.p)}>{t('faq-sms-tooltip')}</p>
            <div className={css(styles.doubleImageWrap)}>
              <img className={css(styles.imageSms)} src={t('faq-sms-image-2')} alt="sms-example" />
              <img
                className={css(styles.imageEmail)}
                styles="minHeight: 464px"
                src={t('faq-sms-image-1')}
                alt="email-example"
              />
            </div>
          </FaqItem>
          <FaqItem title={t('faq-remind-roles-title')} styles={styles} tag="reminder">
            <p className={css(styles.p)}>{IntlNetwork(t, 'faq-remind-roles-bread')}</p>
            <p className={css(styles.p)}>{t('faq-remind-roles-tooltip')}</p>
            <div className={css(styles.imageWrap)}>
              <img src={t('get-started-update-img')} alt="admin-screen-positions" />
            </div>
          </FaqItem>
          <FaqItem title={t('faq-evaluation-title')} styles={styles} tag="evaluation">
            <p className={css(styles.p)}>{IntlNetwork(t, 'faq-evaluation-bread')}</p>
            <p className={css(styles.p)}>{t('faq-evaluation-list-title')}</p>
            <ul className={css(styles.list, styles.doubleCol)}>
              <li>{IntlNetwork(t, 'faq-evaluation-item1')}</li>
              <li>{t('faq-evaluation-item2')}</li>
              <li>{t('faq-evaluation-item3')}</li>
              <li>{t('faq-evaluation-item4')}</li>
              <li>{t('faq-evaluation-item5')}</li>
              <li>{IntlNetwork(t, 'faq-evaluation-item6')}</li>
              <li>{t('faq-evaluation-item7')}</li>
              <li>{t('faq-evaluation-item8')}</li>
              <li>{t('faq-evaluation-item9')}</li>
            </ul>
          </FaqItem>
          <FaqItem title={t('get-started-managers-pres')} styles={styles} tag="presentations">
            <div className={css(styles.presContainer)}>
              <p>
                <a className={css(styles.pres)} href={t('faq-appendix-1')}>
                  {t('faq-appendix')} 1 {t('faq-appendix-language')}
                </a>{' '}
                - {t('faq-appendix-1-legend')}
              </p>
              <p>
                <a className={css(styles.pres)} href={t('faq-appendix-2')}>
                  {t('faq-appendix')} 2 {t('faq-appendix-language')}
                </a>{' '}
                - {IntlNetwork(t, 'faq-appendix-2-legend')}
              </p>
              <p>
                <a className={css(styles.pres)} href={t('faq-appendix-1-en')}>
                  {t('faq-appendix')} 1 (EN)
                </a>{' '}
                - {t('faq-appendix-1-legend')}
              </p>
              <p>
                <a className={css(styles.pres)} href={t('faq-appendix-2-en')}>
                  {t('faq-appendix')} 2 (EN)
                </a>{' '}
                - {IntlNetwork(t, 'faq-appendix-2-legend')}
              </p>
            </div>
          </FaqItem>
          <div className={css(styles.centered)}>
            <h2 className={css(styles.h2)}>{t('faq-thankyou-title')}</h2>
            <p className={css(styles.p)}>{IntlNetwork(t, 'faq-thankyou-message')}</p>
          </div>
        </div>
      </AdminWrapper>
    </>
  );
};

export default FaqPage;

const styles = StyleSheet.create({
  pageWrap: {
    width: '85%',
    margin: 'auto',
  },
  centered: {
    textAlign: 'center',
  },
  faqitem: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: '2.5em',
  },
  wrap: {
    boxShadow: '0 5px 17px 0 rgba(0, 0, 0, .03)',
    borderRadius: 2,
    backgroundColor: Color.white,
    margin: '5em auto',
    position: 'relative',
    paddingTop: '3em',
    paddingRight: '6em',
    paddingBottom: '3em',
    paddingLeft: '6em',
  },
  list: {
    fontFamily: Font.defaultFont,
    fontSize: '1.175em',
    paddingInlineStart: 0,
  },
  olItem: {
    marginBottom: '20px',
  },
  h2: {
    fontWeight: '600',
  },
  competitionTitle: {
    fontWeight: '600',
    fontSize: '1.5rem',
    marginBottom: '10px',
  },
  p: {
    fontSize: '1.175em',
    fontFamily: Font.defaultFont,
    margin: '0 0 1em 0',
  },
  doubleCol: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridRowGap: '15px',
    gridColumnGap: '2em',
  },
  tripleCol: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridRowGap: '15px',
    gridColumnGap: '2em',
  },
  email: {
    width: '80%',
    margin: '2em auto',
  },
  imageWrap: {
    width: '95%',
    margin: '2em auto',
    textAlign: 'center',
  },
  imageEmail: {
    width: '90%',
    margin: '2em auto',
    maxHeight: '700px',
  },
  imageSms: {
    margin: '2em auto',
    maxHeight: '700px',
  },
  doubleImageWrap: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '2fr 3fr',
    backgroundSize: '100%',
    minHeight: '600px',
  },
  img: {
    boxShadow: '0 5px 17px 0 rgba(0, 0, 0, .1)',
    width: '40%',
  },
  pres: {
    fontWeight: '600',
    fontSize: '1.175em',
    color: Color.primary,
  },
  presContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr',
  },
  name: {
    fontSize: '1.75rem',
  },
});

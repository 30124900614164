import React, { Component } from 'react';
import { css, StyleSheet } from 'aphrodite';

import { connect } from 'react-redux';
import { Prompt } from 'react-router';
import { Copilot } from '@talentech/copilot';
import { EditorState, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import { stateToHTML } from 'draft-js-export-html';

import AdminWrapper from '../../components/admin/AdminWrapper';
import Loader from '../../components/Loader';
import Modal from '../../components/Modal';
import PositionEditContactFields from './components/PositionEditContactFields';
import ToastMessage from '../../components/ToastMessage';
import PositionEditRoleFields from './components/PositionEditRoleFields';

import { fetchTalentAIToken } from '../../irecommend-lib/actions/authActions';

import { Color } from '../../config/styles';
import { getTalentechAIToken, setTalentechAIToken } from '../../api';
import { copyDeep } from '../../helpers/utils';
import { withTranslation } from 'react-i18next';

class PositionEditPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      showToast: false,
      toastMessage: '',
      toastType: '',
      formChanged: false,
      isSubmit: false,
      modalDesc: null,
      editorState: EditorState.createWithContent(ContentState.createFromText('')),
      selectedCategories: [],
      formData: {
        Enddate: '',
        Title: '',
        Skills: ['', '', ''],
        Keywords: ['', '', ''],
        Text: '',
        Reward: '',
        RewardType: '',
        ContactName: '',
        ContactTelephone: '',
        ContactMail: '',
        JobLocation: '',
        Push: ['email', 'push'],
        Interval: 3,
        Time: '',
      },
      initialState: {
        Enddate: '',
        Title: '',
        Skills: ['', '', ''],
        Keywords: ['', '', ''],
        Text: '',
        Reward: '',
        RewardType: '',
        ContactName: '',
        ContactTelephone: '',
        ContactMail: '',
        JobLocation: '',
        Push: ['email', 'push'],
        Interval: 3,
        Time: '',
      },
    };

    this.setState = this.setState.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.position !== nextProps.position && !this.state.isSubmit) {
      const position = nextProps.position;
      this.setFormData(position);
    }
    if (this.props.talentAIToken.response !== nextProps.talentAIToken.response) {
      setTalentechAIToken(nextProps.talentAIToken.response.token);
    }
  }

  componentDidMount = () => {
    this.setFormData(this.props.position);
    this.fetchTalentAIToken();
  };

  componentWillUnmount() {
    this.closeWindowListener(false);
  }

  render() {
    const { formChanged, editorState } = this.state;
    const { userCustomer, customerCategories, t, isLoading, isEdit, isExternal } = this.props;
    const isPageLoading = isLoading || this.state.isLinkedinSuggestionLoading;

    const {
      Enddate,
      ContactName,
      ContactMail,
      ContactTelephone,
      Push,
      Interval,
      Time,
      Title,
      JobLocation,
      Reward,
      RewardType,
      Skills,
      Keywords,
      Text,
    } = this.state.formData;

    return (
      <Loader isLoading={isPageLoading}>
        <AdminWrapper>
          <ToastMessage
            isShowToast={this.state.showToast}
            message={this.state.toastMessage}
            closeToast={this.onPressCloseToast}
            type={this.state.toastType}
          />
          {this.state.showModal && (
            <Modal
              description={this.state.modalDesc}
              type="default"
              cancelCallback={this.onPressHideModal}
            />
          )}

          <div className={css(styles.container)}>
            <header className={css(styles.header)}>
              <h1 className={css(styles.h1)}>
                {isEdit ? t('admin-edit-job') : t('admin-add-job')}
              </h1>
            </header>
            <form onSubmit={this.onPressSubmitForm} className={css(styles.addForm)}>
              <PositionEditRoleFields
                Enddate={Enddate}
                handleDateChange={this.handleDateChange}
                t={t}
                inheritedStyles={styles}
                isEdit={isEdit}
                isExternal={isExternal}
                Title={Title}
                JobLocation={JobLocation}
                Reward={Reward}
                RewardType={RewardType}
                Skills={Skills}
                Keywords={Keywords}
                Text={Text}
                onInputChangeFromDraft={this.onInputChangeFromDraft}
                onInputChange={this.onInputChange}
                onFieldAddition={this.onFieldAddition}
                onListChange={this.onListChange}
                onPressShowModal={this.onPressShowModal}
                onListRemove={this.removeKeywordHandler}
                onPressSubmit={this.setFormChangedFalse}
                onPressLinkedinSuggest={this.onPressLinkedinSuggest}
                isLoading={isLoading}
                editorState={editorState}
              />
              <PositionEditContactFields
                userCustomer={userCustomer}
                customerCategories={customerCategories}
                state={this.state}
                setState={this.setState}
                t={t}
                inheritedStyles={styles}
                Time={Time}
                Interval={Interval}
                Push={Push}
                ContactTelephone={ContactTelephone}
                ContactMail={ContactMail}
                ContactName={ContactName}
                onInputChange={this.onInputChange}
                onChangeCheckbox={this.onChangeCheckbox}
                onChangeInterval={this.onChangeInterval}
                onChangeTime={this.onChangeTime}
              />
            </form>
          </div>
          <Prompt when={formChanged} message={t('positioneditpage-prompt-message')} />
        </AdminWrapper>
      </Loader>
    );
  }

  setFormData = (data) => {
    const { isEdit, isExternal } = this.props;

    if (this.state.isSubmit) {
      return;
    }
    let contentState;
    let editorState;
    if (data.text || data.body) {
      const text = data.text ? data.text : data.body;
      const blocksFromHtml = htmlToDraft(text);
      const { contentBlocks, entityMap } = blocksFromHtml;
      contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    } else {
      contentState = ContentState.createFromText('');
    }
    editorState = EditorState.createWithContent(contentState);

    const newState = isExternal
      ? {
          ...this.state,
          editorState: editorState,
          formData: {
            Enddate: data['end-date'] ? data['end-date'].substr(0, 10) : '',
            Title: data.title ? data.title : '',
            Skills: data.skills ? data.skills.split(';') : ['', '', ''],
            Keywords: data.keywords ? data.keywords.split(';') : ['', '', ''],
            Text: data.body ? data.body : '',
            Reward: data.reward ? data.reward : '',
            Interval: data.reminderinterval ? data.reminderinterval : 0,
            RewardType: data.rewardtype ? data.rewardtype : '',
            ContactName: data.contactname ? data.contactname : '',
            ContactTelephone: data.contacttelephone ? data.contacttelephone : '',
            ContactMail: data.contactmail ? data.contactmail : '',
            JobLocation: data.joblocation ? data.joblocation : '',
            Push: ['email', 'push'],
          },
        }
      : {
          ...this.state,
          editorState: editorState,
          formData: {
            Enddate: data.enddate ? data.enddate : '',
            Title: data.title ? data.title : '',
            Skills: data.skills ? data.skills.split(';') : ['', '', ''],
            Keywords: data.keywords ? data.keywords.split(';') : ['', '', ''],
            Text: data.text ? data.text : '',
            Reward: data.reward ? data.reward : '',
            Interval: data.reminderinterval ? data.reminderinterval : 0,
            RewardType: data.rewardtype ? data.rewardtype : '',
            ContactName: data.contactname ? data.contactname : '',
            ContactTelephone: data.contacttelephone ? data.contacttelephone : '',
            ContactMail: data.contactmail ? data.contactmail : '',
            JobLocation: data.joblocation ? data.joblocation : '',
            Push: isEdit ? (data.push ? data.push.split(';') : []) : ['email', 'push'],
            // Time: data.Time ? data.Time : '',
          },
        };
    //Check if the data is the default value, set it to an empty string if that's the case.
    if (newState.formData.Enddate.includes('1970-01-01')) {
      newState.formData.Enddate = '';
    }
    newState.initialState = copyDeep(newState.formData);
    this.setState(newState);
  };

  onPressShowModal = (text, Link = null) => {
    this.setState({
      showModal: true,
      modalDesc: Link ? Link : text,
    });
  };

  onPressHideModal = () => {
    this.setState({
      showModal: false,
    });
  };

  onPressCloseToast = () => {
    this.setState({
      showToast: false,
      toastMessage: '',
      toastType: '',
    });
  };

  handleDateChange = (date) => {
    const newState = {
      ...this.state.formData,
      Enddate: date,
    };
    let formStatus = this.fieldChangeHandler(newState);
    this.setState({
      formChanged: formStatus,
      formData: newState,
    });
  };

  onInputChange = (e, inputType) => {
    const formData = {
      ...this.state.formData,
    };
    formData[inputType] = e.target.value;

    const formChanged = this.fieldChangeHandler(formData);

    this.setState({
      formChanged,
      formData: formData,
    });
  };

  onInputChangeFromDraft = (editorState) => {
    const newState = {
      ...this.state.formData,
    };
    let html = stateToHTML(editorState.getCurrentContent());
    newState['Text'] = html.replace(/&nbsp;/g, ' ');
    const formChanged = this.fieldChangeHandler(newState);

    this.setState({
      formData: newState,
      formChanged,
      editorState: editorState,
    });
  };

  onListChange = (e, inputType, index) => {
    e.persist(); // For accessing the event in the async setState call below
    const formData = { ...this.state.formData };
    formData[inputType][index] = e.target.value;

    let formChanged = this.fieldChangeHandler(formData);

    this.setState({
      formChanged,
      formData,
    });
  };

  fetchTalentAIToken = () => {
    const aiToken = getTalentechAIToken();
    const isTokenExpired = aiToken && new Date(aiToken.exp) < new Date();

    if (isTokenExpired || !aiToken) return this.props.fetchTalentAIToken();

    this.props.talentAIToken.response = { token: aiToken };
  };

  onPressLinkedinSuggest = async () => {
    const { t } = this.props;
    const { Title: adTitle, Text: adText } = this.state.formData;

    if (!adTitle || !adText)
      return this.setErrorToast(t('profileeditpage-linkedin-suggestion-missing-input'));

    try {
      const copilot = new Copilot({
        baseUrl: process.env.REACT_APP_TALENT_AI_ENDPOINT,
        authToken: this.props.talentAIToken.response.token,
      });

      this.setState({ isLinkedinSuggestionLoading: true });
      const searchResults = await copilot.linkedInSearch({ adText, adTitle });
      if (searchResults === undefined)
        return this.setErrorToast(t('profileeditpage-linkedin-suggestion-missing-input'));

      this.setState((prevState) => ({
        isLinkedinSuggestionLoading: false,
        formData: {
          ...prevState.formData,
          Keywords: [searchResults.suggestion],
        },
      }));
    } catch (error) {
      this.setErrorToast(t('profileeditpage-linkedin-suggestion-generic-error'));
    }
  };

  setErrorToast = (message) => {
    this.setState({
      isLinkedinSuggestionLoading: false,
      showToast: true,
      toastMessage: message,
      toastType: 'danger',
    });
  };

  onPressSubmitForm = (e) => {
    e.preventDefault();

    this.setState({ isSubmit: true }, () => {
      // Make sure we clone the state object (Object.assign)
      const data = Object.assign({}, this.state.formData);
      this.props.onPressSubmitForm(data, this.state.selectedCategories);
    });
  };

  // For some reason you could not do this in "onPressSubmitForm" above when creating a new job.
  // Why is unknown, this solution works with both new and edit jobs but there is a slight delay on
  // creating a new job where all forms go blank before you are redirected to start page.
  setFormChangedFalse = () => {
    this.setState({ formChanged: false });
  };

  onChangeCheckbox = (type, checked) => {
    const formData = { ...this.state.formData };
    const typeIndex = formData.Push.indexOf(type);

    if (checked) {
      //Add type if it doesn't exist
      formData.Push.push(type);
    } else {
      //Remove type if it does exist
      formData.Push.splice(typeIndex, 1);
    }

    let formChanged = this.fieldChangeHandler(formData);

    this.setState({
      formChanged,
      formData,
    });
  };

  //Checks if the initial form values are equal to current form values
  //Does a deep equality check i.e. checks equality of nested arrays and objects
  fieldChangeHandler = (newState) => {
    const initialValues = Object.values(copyDeep(this.state.initialState));
    const currentValues = Object.values(copyDeep(newState));

    for (let i = 0; i < currentValues.length; i++) {
      const el = Array.isArray(currentValues[i])
        ? currentValues[i].sort().join('')
        : currentValues[i];

      const initialEl =
        initialValues[i] === undefined
          ? ''
          : Array.isArray(initialValues[i])
          ? initialValues[i].sort().join('')
          : initialValues[i];

      if (el !== initialEl) {
        this.closeWindowListener(true);
        return true;
      }
    }
    this.closeWindowListener(false);
    return false;
  };

  //Prevents user from closing tab or changing URL if changes have been made to form.
  closeWindowListener = (value) => {
    window.onbeforeunload = () => {
      if (value) return '';
    };
  };

  //Commented out until this functionality has been added.
  onChangeInterval = (e) => {
    e.persist();
    const newState = {
      ...this.state.formData,
      Interval: e.target.checked ? Number(e.target.value) : this.state.formData.Interval,
    };

    this.setState({
      formData: newState,
      formChanged: this.fieldChangeHandler(newState),
    });
  };

  // Commented out until interval functionality is added.
  onChangeTime = (e) => {
    e.persist();
    const newState = {
      ...this.state.formData,
      Time: e.target.value,
    };
    this.setState({
      formData: newState,
      formChanged: this.fieldChangeHandler(newState),
    });
  };

  onFieldAddition = (e, fieldType) => {
    e.preventDefault();
    const newState = {
      ...this.state.formData,
      [fieldType]: [...this.state.formData[fieldType], ''],
    };
    this.setState({ formData: newState });
  };

  removeKeywordHandler = (e, index, type) => {
    e.preventDefault();
    const formData = copyDeep(this.state.formData);
    const updatedSkills = [...this.state.formData[type]];
    updatedSkills.splice(index, 1);
    formData[type] = updatedSkills;
    this.setState({
      formData,
      formChanged: this.fieldChangeHandler(formData),
    });
  };
}

function mapStateToProps(state) {
  return {
    talentAIToken: state.authState.talentAIToken,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchTalentAIToken: () => dispatch(fetchTalentAIToken()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PositionEditPage));

const styles = StyleSheet.create({
  addForm: {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr',
    gridTemplateRows: 'min-content min-content',
    gridGap: '2em',
  },
  formFieldset: {
    borderRadius: '2px',
    border: '0',
    boxShadow: '0 5px 17px 0 rgba(0, 0, 0, .03)',
    backgroundColor: Color.white,
    padding: '1.5em 2.5em 2em',
    margin: '0 0 .5em',
  },
  formControl: {
    margin: '0 0 1.5em',
    display: 'block',
    ':last-of-type': {
      marginBottom: '0',
    },
  },
  formInput: {
    borderRadius: '5px',
    border: '1px solid #ccc',
    padding: '.75em .8em',
    margin: '.5em 0',
    fontSize: '1.1em',
    width: '100%',
  },
});

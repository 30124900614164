import {
  GET_USER_FAILURE,
  GET_USER_INVALIDATE,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  POST_USER_FAILURE,
  POST_USER_REQUEST,
  POST_USER_SUCCESS,
  POST_USER_RESET,
  POST_USER_DEVICETOKEN_REQUEST,
  POST_USER_DEVICETOKEN_FAILURE,
  POST_USER_DEVICETOKEN_SUCCESS,
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  GET_USERS_FAILURE,
  MAKE_USER_ADMIN_REQUEST,
  MAKE_USER_ADMIN_SUCCESS,
  MAKE_USER_ADMIN_FAILURE,
  MAKE_USER_ADMIN_RESET,
  GET_USER_APPS_REQUEST,
  GET_USER_APPS_SUCCESS,
  GET_USER_APPS_FAILURE,
  GET_USER_TENANTS_REQUEST,
  GET_USER_TENANTS_SUCCESS,
  GET_USER_TENANTS_FAILURE,
  GET_USER_CATEGORIES_REQUEST,
  GET_USER_CATEGORIES_SUCCESS,
  GET_USER_CATEGORIES_FAILURE,
  POST_USER_CATEGORIES_REQUEST,
  POST_USER_CATEGORIES_SUCCESS,
  POST_USER_CATEGORIES_FAILURE,
} from '../actions/userActions';

const initialState = {
  user: {
    didInvalidate: false,
    error: false,
    isLoading: false,
    response: {},
    request: null, // axios request response object
  },
  postUser: {
    didInvalidate: false,
    error: false,
    isLoading: false,
    response: {},
    request: null, // axios request response object
  },
  userDevicetoken: {
    error: false,
    isLoading: false,
    response: {},
  },
  users: {
    error: false,
    isLoading: false,
    response: {},
    request: null,
  },
  userType: {
    error: false,
    isLoading: false,
    response: {},
    success: false,
  },
  userApps: {
    error: false,
    isLoading: false,
    response: {},
    request: null,
  },
  userTenants: {
    error: false,
    isLoading: false,
    response: {},
    request: null,
  },
  userCategories: {
    error: false,
    isLoading: false,
    response: {},
    request: null,
  },
  postUserCategories: {
    error: false,
    isLoading: false,
    response: {},
    request: null,
  },
};

export default function userState(
  state = initialState,
  { authenticated, error, response, type, request },
) {
  switch (type) {
    case GET_USER_REQUEST:
      return {
        ...state,
        user: {
          ...state.user,
          isLoading: true,
        },
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          didInvalidate: false,
          isLoading: false,
          response: response,
          request: request,
        },
      };
    case GET_USER_FAILURE:
      return {
        ...state,
        user: {
          ...state.user,
          isLoading: false,
          error: true,
          response: error,
          request: request,
        },
      };
    case GET_USER_INVALIDATE:
      return {
        ...state,
        user: {
          ...state.user,
          didInvalidate: true,
        },
      };

    case POST_USER_REQUEST:
      return {
        ...state,
        postUser: {
          ...state.postUser,
          isLoading: true,
        },
      };
    case POST_USER_SUCCESS:
      return {
        ...state,
        postUser: {
          ...state.postUser,
          isLoading: false,
          response: request, // Crashed profile edit since this response doesn't match getUser
          request: request,
        },
      };
    case POST_USER_FAILURE:
      return {
        ...state,
        postUser: {
          ...state.postUser,
          isLoading: false,
          error: true,
          response: error,
          request: request,
        },
      };
    case POST_USER_RESET:
      return {
        ...state,
        postUser: {
          ...state.postUser,
          isLoading: false,
          error: false,
          response: {},
          request: null,
        },
      };
    case POST_USER_DEVICETOKEN_REQUEST:
      return {
        ...state,
        userDevicetoken: {
          ...state.userDevicetoken,
          isLoading: true,
        },
      };
    case POST_USER_DEVICETOKEN_SUCCESS:
      return {
        ...state,
        userDevicetoken: {
          ...state.userDevicetoken,
          isLoading: false,
          response: request,
        },
      };
    case POST_USER_DEVICETOKEN_FAILURE:
      return {
        ...state,
        userDevicetoken: {
          ...state.userDevicetoken,
          isLoading: false,
          error: true,
          response: error,
        },
      };
    case GET_USERS_REQUEST:
      return {
        ...state,
        users: {
          ...state.users,
          error: false,
          isLoading: true,
        },
      };
    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          isLoading: false,
          response: response,
          request: request,
        },
      };
    case GET_USERS_FAILURE:
      return {
        ...state,
        users: {
          ...state.users,
          isLoading: false,
          error: true,
          response: error,
          request: request,
        },
      };
    case MAKE_USER_ADMIN_REQUEST:
      return {
        ...state,
        userType: {
          ...state.userType,
          isLoading: true,
          error: false,
        },
      };
    case MAKE_USER_ADMIN_SUCCESS:
      return {
        ...state,
        userType: {
          ...state.userType,
          isLoading: false,
          response: request,
          error: false,
        },
      };
    case MAKE_USER_ADMIN_FAILURE:
      return {
        ...state,
        userType: {
          ...state.userType,
          isLoading: false,
          error: true,
          response: error,
        },
      };
    case MAKE_USER_ADMIN_RESET:
      return {
        ...state,
        userType: {
          ...state.userType,
          isLoading: false,
          error: false,
          response: {},
        },
      };
    case GET_USER_APPS_REQUEST:
      return {
        ...state,
        userApps: {
          ...state.userApps,
          isLoading: true,
        },
      };
    case GET_USER_APPS_SUCCESS:
      return {
        ...state,
        userApps: {
          ...state.userApps,
          isLoading: false,
          response: response,
          request: request,
        },
      };
    case GET_USER_APPS_FAILURE:
      return {
        ...state,
        userApps: {
          ...state.userApps,
          isLoading: false,
          error: true,
          response: error,
          request: request,
        },
      };
    case GET_USER_TENANTS_REQUEST:
      return {
        ...state,
        userTenants: {
          ...state.userTenants,
          isLoading: true,
        },
      };
    case GET_USER_TENANTS_SUCCESS:
      return {
        ...state,
        userTenants: {
          ...state.userTenants,
          isLoading: false,
          response: response,
          request: request,
        },
      };
    case GET_USER_TENANTS_FAILURE:
      return {
        ...state,
        userTenants: {
          ...state.userTenants,
          isLoading: false,
          error: true,
          response: error,
          request: request,
        },
      };
    case GET_USER_CATEGORIES_REQUEST:
      return {
        ...state,
        userCategories: {
          ...state.userCategories,
          isLoading: true,
          error: false,
        },
      };
    case GET_USER_CATEGORIES_SUCCESS:
      return {
        ...state,
        userCategories: {
          ...state.userCategories,
          isLoading: false,
          request: request,
          response: response,
          error: false,
        },
      };
    case GET_USER_CATEGORIES_FAILURE:
      return {
        ...state,
        userCategories: {
          ...state.userCategories,
          isLoading: false,
          request: request,
          response: error,
          error: true,
        },
      };
    case POST_USER_CATEGORIES_REQUEST:
      return {
        ...state,
        postUserCategories: {
          ...state.postUserCategories,
          isLoading: true,
          error: false,
        },
      };
    case POST_USER_CATEGORIES_SUCCESS:
      return {
        ...state,
        postUserCategories: {
          ...state.postUserCategories,
          error: false,
          isLoading: false,
          request: request,
          response: response,
        },
      };
    case POST_USER_CATEGORIES_FAILURE:
      return {
        ...state,
        postUserCategories: {
          ...state.postUserCategories,
          isLoading: false,
          request: request,
          response: error,
          error: true,
        },
      };

    default:
      return state;
  }
}

import Button from '../components/Button';
import Input from '../components/Input';
import Loader from '../components/Loader';
import MainWrapper from '../components/MainWrapper';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Color, Variables, Font } from '../config/styles';
import { connect } from 'react-redux';
import { css, StyleSheet } from 'aphrodite';
import { withTranslation } from 'react-i18next';

class InterestedSupplementPage extends Component {
  static propTypes = {
    /*
     * Status of waiting for recommendationState
     */
    isLoading: PropTypes.bool.isRequired,
    /*
     * Button: Handles sending interest request
     */
    onPressSubmit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      Comment: '',
      Document: null,
    };
  }

  render() {
    const { t, isLoading, company } = this.props;
    //Disable cv upload for Folksam
    const isFolksam = company?.match(new RegExp(/folksam/gi)) !== null;

    return (
      <Loader isLoading={isLoading}>
        <MainWrapper>
          <div className={css(styles.container)}>
            <div className={css(styles.responsiveWrap)}>
              <h1 className={css(styles.h1)}>{t('interestedsupplementpage-title')}</h1>
              {isFolksam ? (
                <p>{t('interestedsupplementpage-lead-folksam')}</p>
              ) : (
                <p>{t('interestedsupplementpage-lead')}</p>
              )}

              <form onSubmit={this.onSubmitForm}>
                <Input
                  value={this.state.Comment}
                  type="text"
                  name="Comment"
                  placeholder={t('interestedsupplementpage-input-comment')}
                  onChange={(event) => this.setState({ Comment: event.target.value })}
                />
                {!isFolksam && (
                  <input
                    type="file"
                    onChange={(e) => this.onChangeFiles(e.target.files)}
                    className={css(styles.fileInput)}
                  />
                )}
                <div className={css(isFolksam ? styles.interestButtonWrapper : [])}>
                  <Button
                    disabled={isLoading}
                    title={t('interestedsupplementpage-button-onPressSubmitForm')}
                    onPress={this.onPressSubmitForm}
                  />
                </div>
              </form>
            </div>
          </div>
        </MainWrapper>
      </Loader>
    );
  }

  /**
   * Prevent form submission. Handle on button press instead.
   */
  onSubmitForm = (e) => {
    e.preventDefault();
    return false;
  };

  onPressSubmitForm = () => {
    if (this.props.isLoading) {
      // Disable button while loading.
      return;
    }

    this.props.onPressSubmit(this.state);
  };

  /**
   * Handle files choosen in the file upload form. We only want one file.
   */
  onChangeFiles = (files) => {
    if (files && files[0]) {
      const reader = new FileReader();

      reader.onload = (e) => {
        this.setState({
          Document: e.target.result,
        });
      };

      reader.readAsDataURL(files[0]);
    }
  };
}

function mapStateToProps(state) {
  return {
    // recommendation: state.recommendationState.recommendation,
  };
}

export default connect(mapStateToProps)(withTranslation()(InterestedSupplementPage));

const styles = StyleSheet.create({
  readMoreButton: {
    marginTop: 40,
  },
  interestButtonWrapper: {
    marginTop: '3rem',
  },
  checkbox: {
    display: 'block',
  },
  container: {
    minHeight: Variables.MainWrapperHeight,
    padding: Variables.BubbleSectionPadding,
    background: 'white',
  },
  h1: {
    fontFamily: Font.defaultFont,
    fontWeight: 600,
    fontSize: '1.5em',
    lineHeight: '30px',
    color: Color.dark,
  },
  h2: {
    margin: '2em 0 0',
    textAlign: 'center',
    fontSize: '.875em',
    lineHeight: '30px',
    color: Color.dark,
  },
  iconLinkedIn: {
    color: Color.white,
    fill: Color.white,
  },
  responsiveWrap: {
    maxWidth: 700,
    margin: '0 auto',
  },
  small: {
    fontSize: '.75rem',
  },
  fileInput: {
    margin: '1.5em 0',
  },
});

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite';

import AdminWrapper from '../../components/admin/AdminWrapper';
import Button from '../../components/Button';
import Loader from '../../components/Loader';
import MainWrapper from '../../components/MainWrapper';
import TeamMemberList from '../../components/admin/TeamMemberList';
import { withTranslation } from 'react-i18next';

class TeamEditPage extends Component {
  static propTypes = {
    onPressSubmitForm: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    team: PropTypes.any,
  };

  constructor(props) {
    super(props);

    this.state = {
      Name: '',
      Picture: '', // Loaded image for presentation
      PictureRaw: null, // input File object
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.team !== nextProps.team) {
      const team = nextProps.team;
      this.setTeamData(team);
    }
  }

  render() {
    const { t, isLoading, isEdit, teamMembers } = this.props;

    return (
      <Loader isLoading={isLoading}>
        <MainWrapper>
          <AdminWrapper>
            <div className={css(styles.container)}>
              <header className={css(styles.header)}>
                <h1 className={css(styles.h1)}>
                  {isEdit ? t('generic-text-update-team') : t('generic-text-new-team')}
                </h1>
              </header>
              <form onSubmit={this.onPressSubmitForm} className={css(styles.form)}>
                <fieldset className={css(styles.formFieldset)}>
                  <label className={css(styles.formControl)}>
                    <span className="form-label">{t('generic-text-name')}</span>
                    <input
                      value={this.state.Name}
                      type="text"
                      maxLength="45"
                      className={css(styles.formInput)}
                      name="Name"
                      onChange={(e) => this.setState({ Name: e.target.value })}
                    />
                  </label>
                  <div className={css(styles.formSplitFields)}>
                    <label className={css(styles.formControl)}>
                      <span className="form-label">{t('generic-text-image')}</span>
                      {this.state.Picture && (
                        <img src={this.state.Picture} alt="" className={css(styles.picture)} />
                      )}
                      <input
                        type="file"
                        className={css(styles.formInput)}
                        onChange={(e) => this.onChangeFiles(e.target.files)}
                      />
                    </label>
                  </div>
                </fieldset>
                <div className={css(styles.formButton)}>
                  <Button title={t('generic-text-save')} onPress={() => void 0} />
                </div>
              </form>

              <div>
                <TeamMemberList items={teamMembers} t={t} title={t('team-members-title')} />
              </div>
            </div>
          </AdminWrapper>
        </MainWrapper>
      </Loader>
    );
  }

  setTeamData = (team) => {
    this.setState({
      Name: team.name ? team.name : '',
      Picture: team.picture ? team.picture : null,
    });
  };

  onPressSubmitForm = (e) => {
    e.preventDefault();

    if (this.props.onPressSubmitForm(this.state) === true) {
    }
  };

  /**
   * Handle files choosen in the file upload form. We only want one file.
   */
  onChangeFiles = (files) => {
    const { t } = this.props;
    if (files && files[0]) {
      const reader = new FileReader();
      const img = files[0];
      const size = img.size / 1024; // KB size
      const type = img.type; // image/png
      if (size > 3000) {
        alert(t('generic-error-image-size', { maxSize: '3MB' }));
        return;
      }
      if (!(type === 'image/png' || type === 'image/jpeg')) {
        alert(t('generic-error-image-size', { imgType: type.replace('image/', '') }));
        return;
      }

      reader.onload = (e) => {
        this.setState({
          Picture: e.target.result, // Loaded image for presentation
          PictureRaw: files[0], // input File object
        });
      };

      reader.readAsDataURL(files[0]);
    }
  };
}

export default withTranslation()(TeamEditPage);

const styles = StyleSheet.create({
  container: {
    maxWidth: '50%',
    margin: '0 auto',
  },
  h1: {
    textAlign: 'center',
  },
  formFieldset: {
    border: '0',
    padding: '0',
  },
  formInput: {
    borderRadius: '5px',
    border: '1px solid #ccc',
    padding: '.75em .8em',
    margin: '.5em 0',
    fontSize: '1.1em',
    width: '100%',
  },
  formControl: {
    margin: '0 0 1.5em',
    display: 'block',
  },
  picture: {
    width: 68,
    // height: 68,
    marginTop: 8,
  },
});

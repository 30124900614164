import React, { Component } from 'react';
import { Color } from '../../config/styles';
import { css, StyleSheet } from 'aphrodite';

import PropTypes from 'prop-types';

import Button from '../../components/Button';
import Select from '../../components/Select';
import logoLogin from '../../assets/images/logoLogin@2x.png';

class CustomerSelectPage extends Component {
  static propTypes = {
    /**
     * Button callback
     */
    onPressSubmitForm: PropTypes.func.isRequired,
    customers: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      formData: {
        customer: '',
      },
      didSubmit: false,
    };
  }

  render() {
    const { customer } = this.state.formData;

    const teamsSelect = this.props.customers.map((item) => {
      return {
        title: item.name,
        value: item.idcustomer,
      };
    });

    return (
      <div className={css(styles.wrap)}>
        <div className={css(styles.inner)}>
          <img src={logoLogin} alt="" className={css(styles.logoImage)} />
          <h1 className={css(styles.title)}>Choose customer to browse</h1>
          <div>
            <Select
              style={window.innerWidth > 500 ? styles.selectDesktop : null}
              optionsData={teamsSelect}
              selected={customer}
              onChange={(value) =>
                this.setState({
                  formData: { ...this.state.formData, customer: value },
                })
              }
            />
          </div>
          <div className={css(styles.buttonWrap)}>
            <Button onPress={this.onPressSubmitForm} title={'Start browsing'} type="light" />
          </div>
        </div>
      </div>
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.customers.length > 0 &&
      this.props.customers.length !== nextProps.customers.length
    ) {
      this.setState({
        ...this.state,
        formData: {
          customer: nextProps.customers[0].idcustomer,
        },
      });
    }
  }

  onPressSubmitForm = (e) => {
    e.preventDefault();
    const { formData } = this.state;

    if (this.props.isLoading) {
      // Disable button while loading.
      return;
    }

    if (this.props.onPressSubmitForm(formData) === true) {
      // Keeps track of when the user has submitted the recommendation. Used to present errors.
      this.setState({
        didSubmit: true,
      });
    }
  };
}

export default CustomerSelectPage;

const styles = StyleSheet.create({
  wrap: {
    backgroundColor: Color.primary,
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '2em',
  },
  inner: {
    maxWidth: '20em',
    flex: '1 1 20em',
    textAlign: 'center',
    color: Color.white,
    marginTop: '-1em',
  },
  logoImage: {
    width: '119px',
    display: 'inline-block',
    margin: '0 auto 1.5em',
  },
  title: {
    fontSize: '1.4em',
    margin: '0 0 .5em',
  },
  description: {
    margin: '0 auto 1em',
    maxWidth: '20em',
    fontSize: '1em',
  },
  buttonWrap: {
    maxWidth: '15em',
    margin: '1em auto',
  },
});

import { AddonTextfield } from '@talentech/components';
import { Icon } from '@talentech/components';
import { TALENTECH_ICONS } from './Icons';
import { useTranslation } from 'react-i18next';

const Search = (props) => {
  const { onChange, value } = props;
  const { t } = useTranslation();
  return (
    <AddonTextfield
      addonContent={<Icon iconSize="small" icon={TALENTECH_ICONS.magnifier} />}
      textfieldProps={{
        placeholder: t('positionpage-search'),
        size: 'sm',
        onChange: onChange,
        value: value,
      }}
    />
  );
};

export default Search;

import { FETCH_IDV_BAR } from '../actions';

const idvBar = (state = [], action) => {
  switch (action.type) {
    case FETCH_IDV_BAR:
      const data = action.payload.data || [];
      function compare(a, b) {
        const recommendationsA = a.recommendations;
        const recommendationsB = b.recommendations;

        let comparison = 0;
        if (recommendationsA > recommendationsB) {
          comparison = 1;
        } else if (recommendationsA < recommendationsB) {
          comparison = -1;
        }
        return comparison * -1;
      }

      data.sort(compare);
      data.splice(8);

      const title = data.map((title) => title.title);
      const recommendations = data.map((rec) => rec.recommendations);
      //we don't have the data for when the position is filled
      //   const startDate = data.map(start => start.created);
      //   const endDate = data.map(end => end.enddate);

      const idvData = [
        {
          labels: title,
          id: 1,
          datasets: [
            {
              label: 'line-chart-total-tips',
              backgroundColor: 'rgba(139, 212, 206, 0.6)',
              data: recommendations,
            },
          ],
        },
        {
          labels: title,
          id: 2,
          datasets: [
            {
              label: 'Days',
              backgroundColor: 'rgba(98, 58, 162, 0.6)',
              data: [3, 5],
            },
          ],
        },
        {
          labels: title,
          id: 3,
          datasets: [
            {
              label: 'Yellow',
              backgroundColor: 'rgba(255, 97, 88, 0.6)',
              data: [1, 5],
            },
          ],
        },
      ];

      return idvData;
    default:
      return state;
  }
};

export default idvBar;

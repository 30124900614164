import { ActionButton, PageTitle } from '@talentech/components';

const PageHeader = (props) => {
  const { title, breadcrumbs, buttons, tabsOptions } = props;

  const actionButtons = buttons
    ? buttons.map((button, index) => (
        <ActionButton
          key={index}
          label={button.label}
          icon={button.icon}
          variant="vertical"
          onClick={button.onclick}
        />
      ))
    : [];

  return (
    <PageTitle
      title={title}
      fluid
      breadcrumbs={breadcrumbs}
      additionalContent={actionButtons}
      tabsOptions={tabsOptions}
    />
  );
};
export default PageHeader;

import React from 'react';
import { css } from 'aphrodite';
import moment from 'moment';

import DatePicker from 'react-datepicker';

export default function DatePickers(props) {
  const { isSubmit, t, inheritedStyles } = props;

  const startDateSelected = props.startDate ? moment(props.startDate).toDate() : null;
  const endDateSelected = props.endDate ? moment(props.endDate).toDate() : null;

  return (
    <div className={css(inheritedStyles.formSplitFields)}>
      <label className={css(inheritedStyles.formControl)}>
        <span className="form-label">{t('competition-text-start-date')} *</span>
        <DatePicker
          dateFormat="yyyy-MM-dd"
          selected={startDateSelected}
          value={props.startDate}
          onChange={props.onStartDateChange}
          className={
            isSubmit && props.startDate === ''
              ? css(inheritedStyles.formInputInvalid)
              : css(inheritedStyles.formInput)
          }
        />
      </label>
      <label className={css(inheritedStyles.formControl)}>
        <span className="form-label">{t('competition-text-end-date')} *</span>
        <DatePicker
          dateFormat="yyyy-MM-dd"
          selected={endDateSelected}
          value={props.endDate}
          onChange={props.onEndDateChange}
          className={
            isSubmit && props.endDate === ''
              ? css(inheritedStyles.formInputInvalid)
              : css(inheritedStyles.formInput)
          }
        />
      </label>
    </div>
  );
}

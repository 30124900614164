import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { previewEmail } from '../../irecommend-lib/actions/communicationActions';
import useSetupUserflow from '../helper/useSetupUserflow';

const useFetchEmailPreview = (email) => {
  const [htmlContent, setHtmlContent] = useState('');
  const { preview } = useSelector((state) => state.communicationState);
  const { userflowIsLoading } = useSetupUserflow();
  const isLoading = userflowIsLoading || preview.isLoading;

  const dispatch = useDispatch();

  useEffect(() => {
    // Fetch the HTML content from a file or URL
    let isMounted = true;
    fetch('/file/template.html')
      .then((response) => response.text())
      .then((data) => {
        isMounted && setHtmlContent(data);
      })
      .catch((error) => {
        console.error('Error fetching HTML content:', error);
      });
    return () => (isMounted = false);
  }, []);

  useEffect(() => {
    if (email.template_html) {
      dispatch(
        previewEmail({
          template_HTML: email.template_html,
          template_id: email.template_id,
          isOnDemand: email.isOnDemand,
          timestamp: email.timestamp,
        }),
      );
    }

    return () => dispatch({ type: 'PUT_PREVIEW_EMAIL_RESET' });
  }, [email.template_html, dispatch, email.template_id, email.isOnDemand, email.timestamp]);

  return { htmlContent, preview, isLoading };
};

export default useFetchEmailPreview;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite';

import { Color } from '../../config/styles';
import AddedComment from './AddedComment';
import Modal from '../../components/Modal';
import Loader from '../../components/Loader';

class CommentsDropdown extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    comments: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      deleteID: null,
    };
  }

  render() {
    const { t, comments, isLoading } = this.props;

    // Maps incoming comments from props to individual components.
    const allComments = comments.map((comment) => {
      const commenter = comment.givenname + ' ' + comment.familyname;
      return (
        <AddedComment
          key={comment.idcomments}
          comment={comment.comment}
          date={comment.created}
          deleteComment={(e) => this.onPressDeleteComment(e, comment.idcomments)}
          commenter={commenter}
        />
      );
    });

    return (
      <Loader isLoading={isLoading}>
        {/*Modal is only shown when a comment is pressed for deleting */}
        {this.state.showModal && (
          <Modal
            title={t('comment-delete-prompt')}
            description=""
            confirmCallback={(e) => {
              e.preventDefault();
              this.props.onDeleteConfirm(this.state.deleteID);
              this.hideModal(e);
            }}
            cancelCallback={this.hideModal}
          />
        )}
        <ul className={css(styles.ul)}>{allComments}</ul>
      </Loader>
    );
  }

  onPressDeleteComment = (e, id) => {
    e.preventDefault();

    this.setState({
      deleteID: id,
      showModal: true,
    });
  };

  /**
   * Hide modal after cancel/confirm
   */
  hideModal = (e) => {
    e.preventDefault();

    this.setState({ showModal: false });
  };
}

export default CommentsDropdown;

const styles = StyleSheet.create({
  candidateDropdownWrap: {
    display: 'none',
  },

  show: {
    display: 'block',
  },

  ul: {
    paddingLeft: 0,
    marginTop: '10px',
    marginBottom: '20px',
  },

  candidateDropdownBackground: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, .05)',
    cursor: 'default',
    zIndex: 10,
  },

  candidateDropdown: {
    borderRadius: '2px',
    boxShadow: '0 3px 17px 0 rgba(0, 0, 0, .05)',
    backgroundColor: Color.white,
    position: 'absolute',
    top: '1.75em',
    zIndex: '10',
    minWidth: '40em',
    maxWidth: '60em',
    padding: '1.25em',
    // listStyle: 'none',
    margin: '0',
  },
});

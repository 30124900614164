import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Color } from '../config/styles';
import { css, StyleSheet } from 'aphrodite';

export default class BubbleSection extends Component {
  static propTypes = {
    /*
     * Children that should be contained in this component
     */
    children: PropTypes.node.isRequired,
    /*
     * If the section should have a chat bubble arrow or not
     */
    displayArrow: PropTypes.bool,
    /*
     * Style from parent
     */
    style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  };

  static defaultProps = {
    displayArrow: false,
  };

  render() {
    const { children, displayArrow, style } = this.props;

    return (
      <div className={css(styles.container, style)}>
        {displayArrow && <div className={css(styles.arrow)} />}
        {children}
      </div>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    background: 'white',
    margin: '1.25em',
    borderRadius: '1rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    color: Color.dark,
  },
  arrow: {
    position: 'absolute',
    left: 0,
    right: 0,
    margin: 'auto',
    top: '-15px',
    width: 0,
    height: 0,
    borderLeft: '15px solid transparent',
    borderRight: '15px solid transparent',
    borderBottom: '15px solid white',
  },
});

import { CALL_API } from '../middleware/api';
import { getCredentials } from '../../api';

/**
 * GET Userflow customer signature
 */
export const GET_USERFLOW_SIGNATURES_REQUEST = 'GET_USERFLOW_SIGNATURES_REQUEST';
export const GET_USERFLOW_SIGNATURES_SUCCESS = 'GET_USERFLOW_SIGNATURES_SUCCESS';
export const GET_USERFLOW_SIGNATURES_FAILURE = 'GET_USERFLOW_SIGNATURES_FAILURE';
export const GET_USERFLOW_SIGNATURES_INVALIDATE = 'GET_USERFLOW_SIGNATURES_INVALIDATE';

export const getUserflowSignatures =
  (iduser = null) =>
  async (dispatch) => {
    if (iduser === null) {
      let credentials = await getCredentials();
      iduser = credentials.idTokenPayload.sub;
    }
    return dispatch({
      [CALL_API]: {
        endpoint: `userflow/signatures/${iduser}`,
        authenticated: true,
        types: [
          GET_USERFLOW_SIGNATURES_REQUEST,
          GET_USERFLOW_SIGNATURES_SUCCESS,
          GET_USERFLOW_SIGNATURES_FAILURE,
        ],
      },
    });
  };

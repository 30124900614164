import React from 'react';
import { Accordion, Alert, Box } from '@talentech/components';
import { css, StyleSheet } from 'aphrodite';
import { useTranslation } from 'react-i18next';

const LaunchCommunication = (props) => {
  const { sortedLaunchCommunications, optionalInviteContent } = props;
  const { t } = useTranslation();
  return (
    <Box className="col bg-gray-50 shadow-sm m-4 pb-4 rounded-3">
      <h2 className={css(styles.launchSectionHeading)}>{t('communication-launch-title')}</h2>
      <div className={css(styles.alertWrapper)}>
        <Alert
          color="primary"
          title={t('communication-launch-info-title')}
          message={t('communication-launch-info-desc')}
        />
      </div>

      <div className={css(styles.communicationItemsWrapper)}>
        {sortedLaunchCommunications}
        <Accordion accordionItems={optionalInviteContent} key="optional-invite" flush={true} />
      </div>
    </Box>
  );
};

export default LaunchCommunication;

const styles = StyleSheet.create({
  launchSectionHeading: {
    padding: '1.5rem',
    fontWeight: 'bold',
    borderBottom: '1px solid rgba(214, 214, 220, 1)',
    marginBottom: '1.5rem',
    fontSize: '1.75rem',
    letterSpacing: 0,
  },
  alertWrapper: {
    margin: '1.5rem',
  },
  communicationItemsWrapper: {
    border: '1px solid rgba(214, 214, 220, 1)',
    borderRadius: '0.25rem',
    margin: '0 1.5rem',
  },
});

import React, { Component } from 'react';
import { connect } from 'react-redux';

import UserPanel from '../Leaderboard/UserLeaderboard/UserPanel';
import TeamPanel from '../Leaderboard/TeamLeaderboard/TeamPanel';
import TopThree from '../../../components/TopThree';

class LeaderboardView extends Component {
  render() {
    const { userscore, teamscore } = this.props;
    const topThree = userscore.slice(0, 3);

    // Concat names to be valid input for TopThree-component
    const topThreeConcatNames = topThree.map((user, index) => {
      return {
        name: user.firstname + ' ' + user.lastname,
        picture: user.picture,
        number: index + 1,
      };
    });

    return (
      <div className="leaderboard-view">
        <div>
          <TopThree users={topThreeConcatNames} />
        </div>
        <div className="leaderboard-wrapper">
          <div className="leaderboard-view-content">
            <UserPanel data={userscore} />
          </div>
          <div className="leaderboard-view-content">
            <TeamPanel data={teamscore} />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userscore: state.BIDashboardState.userscore,
    teamscore: state.BIDashboardState.teamscore,
    // user: state.BIDashboardState.user,
  };
}

export default connect(mapStateToProps)(LeaderboardView);

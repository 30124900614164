import React, { useEffect, useRef } from 'react';
import { Icon } from '@talentech/components';
import { css, StyleSheet } from 'aphrodite';

const DropdownMenu = (props) => {
  const { toggleState, setToggleState, dropdownMenuList, style } = props;
  const dropdown = useRef();

  useEffect(() => {
    const handleClickOutsideDropdown = (event) => {
      if (dropdown.current && !dropdown.current.contains(event.target)) {
        setToggleState({ ...toggleState, toggleDropdown: false });
      }
    };
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutsideDropdown);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideDropdown);
    };
  }, [dropdown, setToggleState, toggleState]);

  const handleItemClick = (item) => {
    if (typeof item.onClick === 'function') {
      item.onClick();
    }
    setToggleState({ ...toggleState, toggleDropdown: false });
  };

  return (
    <>
      <div ref={dropdown} className={css([styles.dropdownOptions, style])}>
        {dropdownMenuList.map((item) => (
          <div
            key={item.title}
            className={css(styles.menuItem)}
            onClick={() => handleItemClick(item)}
          >
            <span className="list-group-item list-group-item-action py-2 px-3 d-flex align-items-center">
              <Icon iconSize="small" icon={item.icon} />
              <span className="px-2">{item.title}</span>
            </span>
          </div>
        ))}
      </div>
    </>
  );
};

export default DropdownMenu;

const styles = StyleSheet.create({
  dropdownOptions: {
    position: 'absolute',
    width: '200px',
    zIndex: '1',
    cursor: 'pointer',
    right: 0,
    top: 40,
    fontSize: '.9rem',
    border: '1px solid rgb(214, 214, 220)',
    backgroundColor: 'white',
    ':last-child': {
      borderBottom: 'none',
    },
  },
  menuItem: {
    ':hover': {
      backgroundColor: '#F8F9FA',
    },
    borderBottom: '1px solid rgb(214, 214, 220)',
  },
});

import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';
import { withTranslation } from 'react-i18next';

class LineChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chartData: props.chartData,
      options: props.options,
      size: props.size,
    };
  }
  render() {
    const { t } = this.props;
    return (
      <div
        className={this.props.classes}
        style={{ borderRadius: 3, padding: '0 50px', minHeight: 300 }}
      >
        <Line
          data={{
            labels: this.state.chartData.labels,
            datasets: [
              {
                label: t(this.state.chartData.datasets[0].label),
                backgroundColor: this.state.chartData.datasets[0].backgroundColor,
                borderColor: this.state.chartData.datasets[0].borderColor,
                data: this.state.chartData.datasets[0].data,
              },
            ],
          }}
          width={this.state.size.width}
          height={this.state.size.height}
          options={{
            maintainAspectRatio: false,
            barValueSpacing: 60,
            title: {
              display: true,
              text: t(this.state.options.title.text),
              fontSize: this.state.options.title.fontSize,
            },
            legend: {
              display: true,
              position: 'top',
            },
          }}
        />
      </div>
    );
  }
}

export default withTranslation()(LineChart);

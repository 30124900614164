import OtaClient from '@crowdin/ota-client';

class Backend {
  constructor() {
    this.type = 'backend';
    this.scope = '';
    this.prevTimestamp = localStorage.getItem('crowdin-irecommend-manifest-timestamp');
  }

  init(_services, { distributionHash, ...options }) {
    this.client = new OtaClient(distributionHash, options);
    this.client.getManifestTimestamp().then((timestamp) => {
      this.timestamp = timestamp.toString();
      if (this.shouldRefreshTranslations(timestamp.toString())) {
        localStorage.setItem('crowdin-irecommend-manifest-timestamp', timestamp.toString());
      }
    });
  }
  read(language, _namespace, callback) {
    const translationsFromStorage = this.getTranslationFromLocalStorage(language);

    if (!translationsFromStorage || this.shouldRefreshTranslations(this.timestamp)) {
      this.client
        .getLanguageTranslations(language)
        .then((response) =>
          response.find((i) => i.file === `/content/${language}/translation.json`),
        )
        .then((r) => {
          callback(null, r.content);
        });
    } else {
      callback(null, translationsFromStorage);
    }
  }

  shouldRefreshTranslations(currentTimestamp) {
    return this.prevTimestamp !== currentTimestamp;
  }

  checkIfTranslationExistsInLocalStorage(language) {
    return localStorage.getItem(this.getTranslationKey(language)) !== null;
  }

  getTranslationFromLocalStorage(language) {
    return JSON.parse(localStorage.getItem(this.getTranslationKey(language)));
  }

  getTranslationKey(language) {
    return `i18next_res_${language}-translation`;
  }
}

export default Backend;

import React, { Component } from 'react';
import { connect } from 'react-redux';

import userflow from 'userflow.js';

import TeamPage from '../../pages/admin/TeamPage';

import { getTeams, deleteTeam } from '../../irecommend-lib/actions/teamActions';
import { getUser, getUserTenants } from '../../irecommend-lib/actions/userActions';
import { getCustomer } from '../../irecommend-lib/actions/communicationActions';
import { getUserflowSignatures } from '../../irecommend-lib/actions/userflowActions';
import { setupUserflow } from '../../helpers/userflow';

class TeamContainer extends Component {
  constructor(props) {
    super(props);
    //Initiate Userflow

    this.state = {};
  }

  componentDidMount() {
    userflow.init(process.env.REACT_APP_USERFLOW_TOKEN);
    this.props.getTeams();
    this.props.getUserTenants();
    this.props.getUser();
    this.props.getCustomer();
    this.props.getUserflowSignatures();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // Check for errors on form submission
    if (
      this.props.teamDelete !== nextProps.teamDelete &&
      nextProps.teamDelete.isLoading === false
    ) {
      this.props.getTeams();
    }
  }

  componentDidUpdate() {
    //Userflow setup
    setupUserflow(
      this.props.customer.response.customer,
      this.props.user.response,
      this.props.userTenants.response,
      this.props.userflowSignatures.response,
    );
  }
  render() {
    const { response } = this.props.customer;
    const user = this.props.user.response;
    const customer = response.customer ? response.customer : {};
    const teamResp = this.props.teams.response;
    const teams = !teamResp || teamResp.constructor !== Array ? [] : teamResp;
    const isLoading = this.props.teams.isLoading || this.props.teamDelete.isLoading;
    const userflowSignatures = this.props.userflowSignatures.response;

    return (
      <div>
        <TeamPage
          user={user}
          customer={customer}
          isLoading={isLoading}
          teams={teams}
          onPressConfirmDelete={this.onPressConfirmDelete}
          onPressGoToEdit={this.onPressGoToEdit}
          onPressCreateTeam={this.onPressCreateTeam}
          navigateToAdmin={this.navigateToAdmin}
          userflowSignatures={userflowSignatures}
        />
      </div>
    );
  }

  onPressConfirmDelete = (id) => {
    // Delete team item
    this.props.deleteTeam(id);
  };

  onPressGoToEdit = (id) => {
    this.props.history.push(`/admin/teams/edit/${id}`);
  };

  onPressCreateTeam = () => {
    this.props.history.push(`/admin/teams/create`);
  };

  navigateToAdmin = () => {
    this.props.history.push(`/admin`);
  };
}

function mapStateToProps(state) {
  return {
    customer: state.communicationState.customer,
    user: state.userState.user,
    teams: state.teamState.teams,
    teamDelete: state.teamState.teamDelete,
    userflowSignatures: state.userflowState.userflowSignatures,
    userTenants: state.userState.userTenants,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getCustomer: () => dispatch(getCustomer()),
    getTeams: () => dispatch(getTeams()),
    getUser: () => dispatch(getUser()),
    deleteTeam: (id) => dispatch(deleteTeam(id)),
    getUserflowSignatures: () => dispatch(getUserflowSignatures()),
    getUserTenants: () => dispatch(getUserTenants()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TeamContainer);

import { getLanguage } from '../api';

export default class Utils {
  /**
   * Validate email
   * @param {string} email
   * @return {bool}
   */
  static validateEmail(email) {
    var re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  /**
   * Extract subdomain from current host
   */
  static getSubdomain() {
    // let host = window.location.host.split('.');
    // return process.env.REACT_APP_ENV === 'dev' ? 'api' : host[0];
    // Temporary fix for fabian. Static tenant in production.
    return process.env.REACT_APP_ENV === 'dev' ? 'api' : 'headagent';
  }

  /**
   * Make sure the user device language is supported.
   * Will fall back to a supported language.
   */
  static fallbackLanguage() {
    // Current device language
    const language = getLanguage();

    // Supported app languages
    const languages = ['en-us', 'sv-se', 'nb-no', 'fi-fi', 'da-dk'];

    const languageSupported = languages.filter((l) => l.indexOf(language.toLowerCase()) > -1);

    return languageSupported.length === 0 ? process.env.REACT_APP_FALLBACK_LANGUAGE : language;
  }

  /**
   * Extract subdomain from current host
   */
  static getIdFromHref(href) {
    href = href.split('/');
    return href[href.length - 1];
  }

  static random(stringLength = 10) {
    var text = '';
    var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (var i = 0; i < stringLength; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  }
}

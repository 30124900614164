import { CALL_API } from '../middleware/api';

/**
 * GET transactions/sum/teams
 */
export const GET_TRANSACTIONS_SUM_TEAMS_REQUEST = 'GET_TRANSACTIONS_SUM_TEAMS_REQUEST';
export const GET_TRANSACTIONS_SUM_TEAMS_SUCCESS = 'GET_TRANSACTIONS_SUM_TEAMS_SUCCESS';
export const GET_TRANSACTIONS_SUM_TEAMS_FAILURE = 'GET_TRANSACTIONS_SUM_TEAMS_FAILURE';
export const GET_TRANSACTIONS_SUM_TEAMS_INVALIDATE = 'GET_TRANSACTIONS_SUM_TEAMS_INVALIDATE';

export function getTransactionsSumTeams() {
  return {
    [CALL_API]: {
      endpoint: 'transactions/sum/teams',
      authenticated: true,
      types: [
        GET_TRANSACTIONS_SUM_TEAMS_REQUEST,
        GET_TRANSACTIONS_SUM_TEAMS_SUCCESS,
        GET_TRANSACTIONS_SUM_TEAMS_FAILURE,
      ],
    },
  };
}

/**
 *  Invalidate team highscore to make sure they get fetched again when the view appears
 */
export const getTransactionsSumTeamsInvalidate = () => {
  return {
    type: GET_TRANSACTIONS_SUM_TEAMS_INVALIDATE,
    payload: {},
  };
};

/**
 *  Only dispatches getTransactionsSumTeams if data is invalidated or empty
 */
const shoudGetTransactionsSumTeams = (state) => {
  const sumTeams = state.transactionState.sumTeams;

  if (!sumTeams.response || sumTeams.response.length === 0) {
    return true;
  }

  return sumTeams.didInvalidate;
};

export const getTransactionsSumTeamsIfNeeded = () => (dispatch, getState) => {
  if (shoudGetTransactionsSumTeams(getState())) {
    return dispatch(getTransactionsSumTeams());
  }
};

/**
 * GET transactions/sum/users
 */

export const GET_TRANSACTIONS_SUM_USERS_REQUEST = 'GET_TRANSACTIONS_SUM_USERS_REQUEST';
export const GET_TRANSACTIONS_SUM_USERS_SUCCESS = 'GET_TRANSACTIONS_SUM_USERS_SUCCESS';
export const GET_TRANSACTIONS_SUM_USERS_FAILURE = 'GET_TRANSACTIONS_SUM_USERS_FAILURE';

export function getTransactionsSumUsers() {
  return {
    [CALL_API]: {
      endpoint: 'transactions/sum/users',
      authenticated: true,
      types: [
        GET_TRANSACTIONS_SUM_USERS_REQUEST,
        GET_TRANSACTIONS_SUM_USERS_SUCCESS,
        GET_TRANSACTIONS_SUM_USERS_FAILURE,
      ],
    },
  };
}

/**
 *  Invalidate individual users highscore to make sure they get fetched again when the view appears
 */
export const GET_TRANSACTIONS_SUM_USERS_INVALIDATE = 'GET_TRANSACTIONS_SUM_USERS_INVALIDATE';
export const getTransactionsSumUsersInvalidate = () => {
  return {
    type: GET_TRANSACTIONS_SUM_USERS_INVALIDATE,
    payload: {},
  };
};

/**
 *  Only dispatches getTransactionsSumUsers if data is invalidated or empty
 */
const shoudGetTransactionsSumUsers = (state) => {
  const sumUsers = state.transactionState.sumUsers;

  if (!sumUsers.response || sumUsers.response.length === 0) {
    return true;
  }

  return sumUsers.didInvalidate;
};

export const getTransactionsSumUsersIfNeeded = () => (dispatch, getState) => {
  if (shoudGetTransactionsSumUsers(getState())) {
    return dispatch(getTransactionsSumUsers());
  }
};

import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Checkbox } from '@talentech/components';

import { getUserCategories, getUserIfNeeded } from '../irecommend-lib/actions/userActions';
import { getCustomerCategories } from '../irecommend-lib/actions/customerActions';

import { checkIfCategorySelected } from '../helpers/category';
import { sortCategories } from '../helpers/utils';

const CategoryFilterFields = (props) => {
  const { user, userCategories } = useSelector((state) => state.userState);
  const { customerCategories } = useSelector((state) => state.customerState);
  const { state, setState } = props;
  const dispatch = useDispatch();

  const userCategoriesArr = useMemo(
    () => (Array.isArray(userCategories.response) ? userCategories.response : []),
    [userCategories.response],
  );
  const customerCategoriesArr = useMemo(
    () => (Array.isArray(customerCategories.response) ? customerCategories.response : []),
    [customerCategories.response],
  );

  const sortedCustomerCategories = sortCategories(customerCategoriesArr);

  useEffect(() => {
    dispatch(getUserIfNeeded());
    dispatch(getCustomerCategories());
  }, [dispatch]);

  useEffect(() => {
    if (Object.keys(user.response).length > 0)
      dispatch(getUserCategories(user.response.user?.iduser));
  }, [user.response, dispatch, setState]);

  useEffect(() => {
    if (userCategoriesArr.length > 0)
      setState((prev) => ({
        ...prev,
        selectedCategories: userCategoriesArr,
      }));
  }, [userCategoriesArr, setState]);

  const handleSetFilter = (category) => {
    const categoryClicked = category.idcategories;
    const checkIfCategorySelected =
      state.selectedCategories.filter((item) => item.idcategories === categoryClicked).length > 0;

    if (checkIfCategorySelected) {
      setState((prev) => ({
        ...prev,
        selectedCategories: state.selectedCategories.filter(
          (item) => item.idcategories !== categoryClicked,
        ),
      }));
    } else {
      setState((prev) => ({
        ...prev,
        selectedCategories: [...state.selectedCategories, category],
      }));
    }
  };

  return (
    <Box className="row m-0 flex-column">
      {sortedCustomerCategories.map((category) => (
        <Box key={category.idcategories} className="col-4 d-flex">
          <Checkbox
            onChange={() => handleSetFilter(category)}
            id={`checkbox-${category.idcategories}`}
            checked={checkIfCategorySelected(state, category)}
          />
          <label className={css(styles.label)} htmlFor={`checkbox-${category.idcategories}`}>
            {category.name}
          </label>
        </Box>
      ))}
    </Box>
  );
};

CategoryFilterFields.propTypes = {
  /**
   * State handler
   */
  state: PropTypes.object.isRequired,
  /**
   * Customer categories handler
   */
  customerCategories: PropTypes.array.isRequired,
  /**
   * User categories
   */
  userCategories: PropTypes.array.isRequired,
  /**
   * User categories
   */
  user: PropTypes.object.isRequired,
};

export default CategoryFilterFields;

const styles = StyleSheet.create({
  label: {
    marginBottom: '1rem',
  },
});

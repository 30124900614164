import {
  GET_POLICY_REQUEST,
  GET_POLICY_SUCCESS,
  GET_POLICY_FAILURE,
} from '../actions/policyActions';

const initialState = {
  policy: {
    didInvalidate: false,
    error: false,
    isLoading: false,
    response: [],
  },
};

export default function policyState(
  state = initialState,
  { authenticated, error, response, type, request },
) {
  switch (type) {
    case GET_POLICY_REQUEST:
      return {
        ...state,
        policy: {
          ...state.policy,
          isLoading: true,
        },
      };
    case GET_POLICY_SUCCESS:
      return {
        ...state,
        policy: {
          ...state.policy,
          isLoading: false,
          response: response,
        },
      };
    case GET_POLICY_FAILURE:
      return {
        ...state,
        policy: {
          ...state.policy,
          isLoading: false,
          error: true,
          response: error,
        },
      };
    default:
      return state;
  }
}

import React, { PureComponent } from 'react';
import { css, StyleSheet } from 'aphrodite';
import PropTypes from 'prop-types';

import ProfilePicture from '../components/ProfilePicture';

import { Color } from '../config/styles';
import { withTranslation } from 'react-i18next';

/**
 * Custom FlatList item specific for displaying highscore lists.
 *
 * Example usage:
 *
 * ```
 * <HighscoreListItem
 *   id=1
 *   onPress={onPressLearnMore}
 *   name="John Doe"
 * />
 * ```
 *
 */
class HighscoreListItem extends PureComponent {
  static propTypes = {
    /**
     * Unique identifier for item
     */
    id: PropTypes.string.isRequired,
    /**
     * User level
     */
    level: PropTypes.number,
    /**
     * User full name
     */
    name: PropTypes.string.isRequired,
    /**
     * User profile image. If no image provided we will use initials from full name (name).
     */
    picture: PropTypes.string,
    /**
     * User highscore position
     */
    position: PropTypes.number.isRequired,
    /**
     * Handler to be called when the user taps the button
     */
    onPress: PropTypes.func.isRequired,
    /**
     * How many points the user has earned
     */
    points: PropTypes.number.isRequired,
    /**
     * Marks the row with a different background color to stand out
     */
    selected: PropTypes.bool,
  };

  render() {
    const { t, level, name, picture, position, points } = this.props;

    const formattedPoints = new Intl.NumberFormat().format(points);
    const wrapStyles = styles.wrap;

    return (
      <div className={css(wrapStyles)}>
        <div className={css(styles.position)}>{position}</div>

        <ProfilePicture picture={picture} name={name} size="medium" style={styles.picture} />

        <div className={css(styles.middleColumn)}>
          <span className={css(styles.name)}>{name}</span>

          {(level === 0 || level) && (
            <span className={css(styles.level)}>
              {t('generic-level')} {level}
            </span>
          )}
        </div>

        <span className={css(styles.points)}>
          {t('generic-points-short-intl', { points: formattedPoints })}
        </span>
      </div>
    );
  }

  onPressItem = () => {
    this.props.onPress(this.props.id);
  };
}

export default withTranslation()(HighscoreListItem);

const styles = StyleSheet.create({
  level: {
    display: 'block',
    fontWeight: 500,
    fontSize: 13,
    color: '#747270',
  },
  name: {
    display: 'block',
    fontSize: 17,
    color: Color.dark,
    marginBottom: 3,
  },
  middleColumn: {
    flex: 1,
    maxWidth: 200,
  },
  points: {
    fontWeight: 500,
    fontSize: 13,
    color: Color.dark,
  },
  picture: {
    marginLeft: 16,
    marginRight: 16,
  },
  position: {
    fontSize: 17,
    color: Color.dark,
  },
  wrap: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    padding: 20,
    paddingLeft: 25,
    paddingRight: 25,
  },
});

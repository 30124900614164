import React, { Component } from 'react';
import i18next from 'i18next';
import { css, StyleSheet } from 'aphrodite';
import PropTypes from 'prop-types';
import ReactPhoneInput from 'react-phone-input-2';

import { Color } from '../config/styles';

import Button from '../components/Button';
import ConfirmationBox from '../components/ConfirmationBox';
import Input from '../components/Input';
import { IntlNetwork, phoneLangaugeMap, phonePlaceholderMap } from '../helpers/language';

import 'react-phone-input-2/lib/style.css';
import { withTranslation } from 'react-i18next';

/**
 * A tab component that presents the recommendation form.
 *
 * Example usage:
 *
 * ```
 * <TabRecommend
 * />
 * ```
 *
 */
class TabRecommend extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    /**
     * Reset the http status when pressing "more recommendations"
     */
    onPressMoreRecommendations: PropTypes.func.isRequired,
    /**
     * POST recommendation to API
     */
    onPressSubmit: PropTypes.func.isRequired,
    /**
     * Home button callback
     */
    onPressHome: PropTypes.func.isRequired,
    /**
     * HTTP request status
     */
    status: PropTypes.number,
  };

  constructor(props) {
    super(props);

    this.onPressNoContactInfo = this.onPressNoContactInfo.bind(this);
    this.onPressMoreRecommendations = this.onPressMoreRecommendations.bind(this);
    this.onPressSubmitForm = this.onPressSubmitForm.bind(this);

    // So we can reset the form
    this.defaultState = {
      anonymous: false, // Form field
      actionSheetTitle: '', // Either show email or phone title
      actionSheetType: '', // How we know what index the action sheet returns on select (phone / email)
      didSubmit: false, // Keeps track of when the user has submitted the recommendation. Used to present errors.
      email: '', // Form field
      givenName: '', // Split from full name. Used in form presentation when refering to recommended person.
      isSearching: false, // ContactInput search
      motivation: '', // Form field
      name: '', // Form field
      noContactInfo: false, // Toggle between different fields in the form
      phone: '', // Form field
      countryCode: '', //form field
      dialCode: '', // Form field
      resultCount: 0, // Number of search results
      selectedContact: null, // Selected contact object
      selectedContactOptions: [this.props.t('generic-button-cancel')], // Data for action sheet
      selectedPhoneIndex: -1,
      selectedEmailIndex: -1,
      selected: '',
      text: '', // Form field
    };

    this.state = this.defaultState;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { t } = this.props;

    // Check for status changes
    if (this.props.status === nextProps.status && !this.state.didSubmit) {
      return;
    }

    // Keeps track of when the user has submitted the recommendation. Used to present errors.
    this.setState({
      didSubmit: false,
    });

    if (nextProps.status === 409) {
      // Recommendation already exists
      alert(
        IntlNetwork(t, 'tabrecommend-validation-alreadyexists-intl', {
          name: this.state.givenName,
        }),
      );
    } else if (
      nextProps.status === 500 &&
      nextProps.errorMessage.includes('invalid phone number supplied')
    ) {
      // Invalid phone number
      alert(t('generic-validation-phone'));
    } else if (nextProps.status === 500) {
      // Other errors
      alert(t('generic-error-default'));
    }
  }

  render() {
    const {
      anonymous,
      email,
      isSearching,
      noContactInfo,
      phone,
      resultCount,
      selectedContact,
      selectedEmailIndex,
    } = this.state;

    const { t, onPressHome, status } = this.props;

    // The form has been successfully submitted - show confirmation
    if (status === 200) {
      let message = this.state.noContactInfo
        ? t('tabrecommend-message-recommendation-sent')
        : t('tabrecommend-message-recommendation-sentto-intl', { name: this.state.givenName });

      if (anonymous) {
        message += ' (' + t('tabrecommend-message-anonymous') + ')';
      }

      message += IntlNetwork(t, 'tabrecommend-message-confirm');

      return (
        <div className={css(styles.confirmationBoxWrap)}>
          <ConfirmationBox subtitle={message} title={t('generic-label-thanks')}>
            <Button
              onPress={this.onPressMoreRecommendations}
              title={t('tabrecommend-button-more-recommendations')}
              type="linkLightUnderline"
              style={styles.moreRecommendationsButton}
            />
            <Button
              onPress={onPressHome}
              title={t('tabrecommend-button-close')}
              type="linkLightUnderline"
              style={styles.moreRecommendationsButton}
            />
          </ConfirmationBox>
        </div>
      );
    }

    const emailLabel =
      selectedContact && selectedEmailIndex > -1
        ? selectedContact.emailAddresses[selectedEmailIndex].label
        : '';
    const textPlaceholder = this.state.givenName
      ? t('tabrecommend-placeholder-greetingto-intl', { name: this.state.givenName })
      : t('tabrecommend-placeholder-greeting');
    const anonymousButtonTitle = this.state.givenName
      ? t('tabrecommend-button-send-anonymous-name-intl', { name: this.state.givenName })
      : t('tabrecommend-button-send-anonymous');

    const mobile = window.innerWidth < 700;
    const phoneDefaultLanguage = phoneLangaugeMap[i18next.language] || 'se';
    const phonePlaceholder = phonePlaceholderMap[i18next.language] || '+46';

    return (
      <div className={css(styles.formContainer, !mobile && styles.bigFormContainer)}>
        <div className={css(styles.form)}>
          <Input
            autoFocus
            onChange={(event) => {
              let name = event.target.value;
              this.setState({
                givenName: name.split(' ')[0],
                name: name,
                isSearching: name.length > 0,
              });
            }}
            placeholder={t('generic-input-Name')}
            value={this.state.name}
          />

          {noContactInfo ? (
            <Input
              onChange={(event) => this.setState({ text: event.target.value })}
              placeholder={IntlNetwork(t, 'tabrecommend-placeholder-text')}
              value={this.state.text}
            />
          ) : (
            <div>
              <Input
                autoCapitalize="none"
                label={emailLabel}
                onChange={(event) => this.setState({ email: event.target.value })}
                placeholder={t('generic-input-Mail')}
                type="email"
                name="email"
                maxLength="55"
                autoComplete="email"
                value={this.state.email}
              />
              <div className={css(styles.spacer)}>
                <ReactPhoneInput
                  value={phone}
                  country={phoneDefaultLanguage}
                  placeholder={phonePlaceholder}
                  masks={{ se: '..-... .. ..' }}
                  regions={['europe', 'america']}
                  preferredCountries={['se', 'gb', 'no', 'fi', 'dk', 'us'].reverse()}
                  onChange={(phone, { countryCode, dialCode }) => {
                    this.setState({ phone, countryCode, dialCode });
                  }}
                  inputStyle={{
                    width: '100%',
                    borderRadius: 0,
                    border: 'none',
                    borderBottom: '1px solid rgba(0,0,0,0.3)',
                    fontSize: 16,
                    height: 40,
                  }}
                  buttonStyle={{
                    background: '#FFF',
                    borderRadius: 0,
                    border: 'none',
                    borderBottom: '1px solid rgba(0,0,0,0.3)',
                    borderRight: '1px solid rgba(0,0,0,0.3)',
                  }}
                />
              </div>
            </div>
          )}

          {!(email || phone) && (
            <Input
              style={styles.checkboxSmall}
              checked={this.state.noContactInfo}
              type="checkbox"
              name="noContactInfo"
              placeholder={t('tabrecommend-placeholder-nocontactinfo')}
              onChange={(event) => this.setState({ noContactInfo: event.target.checked })}
            />
          )}

          {!noContactInfo && (
            <Input
              onChange={(event) => this.setState({ text: event.target.value })}
              placeholder={textPlaceholder}
              value={this.state.text}
            />
          )}
          <Input
            onChange={(event) => this.setState({ motivation: event.target.value })}
            placeholder={IntlNetwork(t, 'tabrecommend-placeholder-motivation')}
            value={this.state.motivation}
          />
        </div>

        {(!isSearching || resultCount === 0) && (
          <div className={css(styles.buttonContainer)}>
            <div className={css(styles.primarySubmit)}>
              <Button
                onPress={(e) => this.onPressSubmitForm(e, false)}
                title={t('tabrecommend-button-send')}
              />
            </div>

            {!noContactInfo ? (
              <div className={css(styles.secondarySubmit)}>
                <Button
                  onPress={(e) => this.onPressSubmitForm(e, true)}
                  title={anonymousButtonTitle}
                  type="secondary"
                />
              </div>
            ) : (
              ''
            )}
          </div>
        )}
      </div>
    );
  }

  /**
   * The user doesn't have email/phone. Hide thoose fields.
   */
  onPressNoContactInfo = () => {
    this.setState((prevState) => ({
      noContactInfo: !prevState.noContactInfo,
      text: '', // Text is used for both greeting and HR department tip. Clear to avoid confusing the user.
    }));
  };

  /**
   * Submit recommendation. Validate fields in the container.
   */
  onPressSubmitForm = (e, anonymous) => {
    e.preventDefault();

    this.setState({ anonymous }, () => {
      if (this.props.isLoading) {
        return;
      }

      if (this.props.onPressSubmit(this.state) === true) {
        // Keeps track of when the user has submitted the recommendation. Used to present errors.
        this.setState({
          didSubmit: true,
        });
      }
    });
  };

  /**
   * Reset form state when the user press the "more recommendations"-button
   */
  onPressMoreRecommendations = () => {
    // Reset to default state
    this.setState(this.defaultState, () => {
      // Notify the container that we want to reset the status
      this.props.onPressMoreRecommendations();
    });
  };
}

export default withTranslation()(TabRecommend);

const styles = StyleSheet.create({
  checkbox: {
    marginTop: 20,
    marginBottom: 14,
  },
  checkboxSmall: {
    display: 'block',
  },
  changeButton: {
    position: 'absolute',
    right: 5,
    top: 15,
  },
  confirmationBoxWrap: {
    height: '90%',
  },
  formContainer: {
    borderRadius: 4,
    width: '90%',
    marginLeft: '5%',
    padding: 20,
    backgroundColor: Color.white,
  },
  bigFormContainer: {
    width: '60%',
    marginLeft: '20%',
  },
  form: {
    paddingBottom: 10,
  },
  phoneInputContainer: {
    width: 60,
  },
  moreRecommendationsButton: {
    marginBottom: 34,
    marginTop: 'auto',
  },
  spacer: {
    marginTop: 16,
  },
  noContactInfoButton: {
    paddingTop: 16,
    paddingBottom: 16,
  },
  buttonContainer: {
    display: 'grid',
    justifyContent: 'center',
    gridGap: window.innerWidth < 700 ? 10 : 30,
    gridTemplateColumns: '1fr 1fr',
  },
  primarySubmit: {
    gridColumn: `1 / span ${window.innerWidth < 700 ? 2 : 1}`,
  },
  secondarySubmit: {
    gridColumn: `${window.innerWidth < 700 ? 1 : 2} / span ${window.innerWidth < 700 ? 2 : 1}`,
  },
});

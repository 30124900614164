import jwtDecode from 'jwt-decode';

export const copyDeep = (value) => {
  if (Array.isArray(value)) {
    return value.map(copyDeep);
  }
  if (value && typeof value === 'object') {
    const copy = {};
    for (let k in value) {
      copy[k] = copyDeep(value[k]);
    }
    return copy;
  }
  return value;
};

export const splitString = (string, delimeter) => {
  return string.split(delimeter)[0][0]?.toUpperCase();
};

export const sortCategories = (data = []) => {
  const sorted = data.sort(function (a, b) {
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1;
    }
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1;
    }
    return 0;
  });

  return sorted;
};

export const getActiveTenantFromToken = (credentials) => {
  const accessToken = credentials.accessToken;
  return jwtDecode(accessToken).tenantId;
};

export const createBreadcrumb = (breadCrumbArr, t) => {
  return breadCrumbArr.map((item) => {
    return {
      label: t(item.labelKey),
      onClick: item.onClick,
      isActive: item.isActive,
    };
  });
};

export function calculatePageCount(itemsPerPage, totalItems) {
  return totalItems <= itemsPerPage ? 1 : Math.ceil(totalItems / itemsPerPage);
}

import { ActionButton, Box } from '@talentech/components';
import { StyleSheet, css } from 'aphrodite';
import { TALENTECH_ICONS } from '../Icons';
import { Color } from '../../irecommend-lib/styles';

const InvitationUpload = (props) => {
  const { t, readExcel, fileInput, handleUpload } = props;
  return (
    <>
      <Box className={css(styles.innerWrap)}>
        <Box className="row m-0 mx-auto">
          <Box className="col d-flex align-items-center flex-column">
            <h6 className="mb-3 text-center">{t('invitationpage-bulk-upload-description')}</h6>
          </Box>
        </Box>
        <Box className="row flex-column align-items-center flex-sm-row justify-content-center">
          <Box className="col-md-4 col-sm-12 text-center">
            <ActionButton
              label={t('invitationpage-bulk-download-template')}
              icon={TALENTECH_ICONS.fileDownload}
              variant="horizontal"
              size="lg"
              onClick={() =>
                window.location.assign(
                  'https://communication.irecommend.se/templates/import_users.xlsx',
                )
              }
            />
          </Box>
          <Box className="col-md-4 col-sm-12 text-center">
            <input onChange={(e) => readExcel(e.target.files)} type="file" hidden ref={fileInput} />
            <ActionButton
              label={t('invitationpage-bulk-upload-file')}
              icon={TALENTECH_ICONS.fileUpload}
              variant="horizontal"
              size="lg"
              onClick={handleUpload}
            />
          </Box>
        </Box>
      </Box>
      <Box className="row">
        <Box className="col">
          <h4>{t('invitationpage-bulk-imports')}</h4>
        </Box>
      </Box>
    </>
  );
};

export default InvitationUpload;

const styles = StyleSheet.create({
  wrap: {
    backgroundColor: Color.white,
    margin: '1rem',
    padding: '1.5rem',
  },
  innerWrap: {
    backgroundColor: Color.lightGray,
    padding: '1.5rem',
  },
  progressBarWrapper: {
    minWidth: '100%',
    minHeight: '.5rem !important',
    backgroundColor: Color.backgroundDark,
    borderRadius: '10px',
  },
  progressBar: {
    width: '70%',
    minHeight: '.5rem !important',
    backgroundColor: Color.primary,
    borderRadius: '10px',
  },
});

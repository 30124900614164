// Colors
const Color = {
  primary: '#26253e',
  secondary: '#ffcfcd',
  success: '#39837a',
  backgroundDark: '#eeeff3',
  border: '#d6d6dc',
  purple: '#d6d6e4',

  aquaBlue: '#A5DADA',
  badge1: '#FFC870',
  badge2: '#D6D6DC',
  badge3: '#994F1E',
  baseGray: '#F8F8F8',
  dark: '#737387FC',
  dark2: '#6E6E81',
  defaultBlue: '#1F9095',
  gray: '#a4a4a4',
  halfbakedBlue: '#85C4C7',
  irecBlue: '#21949B',
  newIrecBlue: '#007185',
  lightBlue: '#EFF4F4',
  onahauBlue: '#D6D6E4',
  turquoise: '#DFEFF1',
  lightGray: '#F5F8FA',
  labelGray: '#455763',
  warning: '#CE3B40FC',
  white: '#ffffff',
  placeholderGray: '#C7C7CD',
  clearBlue: '#0077b5',
  clearGreen: '#00BF49',
  red: '#fc243e',
  irecRed: '#FF6158',
  achievementBlue: '#45BCB7',
  teamTailorPink: '#F33F86',
  bgBlue: '#26253E',
  lightPink: '#FFCFCD',
  lightGray2: '#60606C',
  lightPurple: '#C7C7DC',
  darkGray: '#444450',
  lightestGray: '#F1F1F3',
  itemBorder: '#D6D6DC',
  screenBg1: '#F1F1F3',
  // Status colors
  unread: '#D04348',
  read: '#C6ECCF',
  interested: '#C6ECCF',
  sent: '#00857A',
  notinquestion: '#FEA480',
  interview: '#C6ECCF',
  hired: '#45BCB7',
};

const Font = {
  defaultFont: 'OpenSans-Regular',
  defaultFontMedium: 'OpenSans-SemiBold',
};

const webFont = {
  defaultFont: 'Open Sans',
};

const Variables = {
  BubbleSectionPadding: '1.5rem',
  HeaderHeight: '4rem',
  FooterHeight: '6.4rem',
  MainWrapperHeight: 'calc(100vh - 3.4rem)',
  DefaultLetterSpacing: 0.65,
};

// Shared styles

let defaultText = {
  regular: {
    fontFamily: Font.defaultFont,
    fontSize: 17,
    color: Color.primary,
    backgroundColor: 'transparent',
  },
  medium: {
    fontFamily: Font.defaultFontMedium,
    fontSize: 17,
    color: Color.primary,
    backgroundColor: 'transparent',
  },
  h1: {
    fontFamily: Font.defaultFontMedium,
    fontSize: 27,
    color: Color.white,
    backgroundColor: 'transparent',
  },
  h2: {
    fontFamily: Font.defaultFontMedium,
    fontSize: 28,
    color: Color.white,
    backgroundColor: 'transparent',
  },
  h3: {
    fontFamily: Font.defaultFont,
    fontSize: 17,
    color: Color.white,
    backgroundColor: 'transparent',
  },
};

let webDefaultText = {
  regular: {
    fontFamily: webFont.defaultFont,
    fontSize: 17,
    color: Color.primary,
    backgroundColor: 'transparent',
  },
  medium: {
    fontFamily: webFont.defaultFont,
    fontWeight: 600,
    fontSize: 17,
    color: Color.primary,
    backgroundColor: 'transparent',
  },
  h1: {
    fontFamily: webFont.defaultFont,
    fontWeight: 600,
    fontSize: 27,
    color: Color.primary,
    backgroundColor: 'transparent',
  },
  h2: {
    fontFamily: webFont.defaultFont,
    fontWeight: 600,
    fontSize: 28,
    color: Color.bgBlue,
    backgroundColor: 'transparent',
  },
  h3: {
    fontFamily: webFont.defaultFont,
    fontSize: 17,
    color: Color.primary,
    backgroundColor: 'transparent',
  },
};

/**
 * Return a object with label name and color for a specific status
 * @param {number} status - Status number
 * @return {object}
 */
const getStatus = (status) => {
  if (status === 0) {
    return {
      label: 'generic-unread',
      color: Color.unread, // Unread post
    };
  } else if (status === 10) {
    return {
      label: `status-sent`,
      color: Color.sent, // Sent
    };
  } else if (status === 20) {
    return {
      label: `status-read`,
      color: Color.read, // Read
    };
  } else if ([50, 70].includes(status)) {
    return {
      label: `status-interested`,
      color: Color.interested, // Interested - Color checked against UI design
    };
  } else if ([30, 40, 60, 80, 90, 110, 120].includes(status)) {
    return {
      label: `status-notinquestion`,
      color: Color.notinquestion, // Not in question - Color checked against UI design
    };
  } else if (status === 100) {
    return {
      label: `status-interview`,
      color: Color.interview, // Interview
    };
  } else if (status === 130) {
    return {
      label: `status-hired`,
      color: Color.hired, // Hired - Color checked against UI design
    };
  }

  // Fallback
  return {
    label: 'status-unknown',
    color: 'lightgray',
  };
};

/**
 * Return a list of all statuses
 * @return {array}
 */
const getStatusList = () => {
  return [
    {
      key: 'status-reset',
      id: 5,
    },
    {
      key: 'status-sent',
      id: 10,
    },
    {
      key: 'status-read',
      id: 20, // prev: 50
    },
    {
      key: 'status-recommended-other',
      id: 30, // prev: 60
    },
    {
      key: 'status-not-interested',
      id: 40, // prev: 20
    },
    {
      key: 'status-interested',
      id: 50, // prev: 70
    },
    {
      key: 'status-not-interested-in-this-role',
      id: 60, // prev: 80
    },
    {
      key: 'status-contacted',
      id: 70, // prev: 90
    },
    {
      key: 'status-not-relevant-after-contact',
      id: 80,
    },
    {
      key: 'status-not-interested-after-contact',
      id: 90,
    },
    {
      key: 'status-interview',
      id: 100,
    },
    {
      key: 'status-not-relevant-after-interview',
      id: 110, // prev: 40
    },
    {
      key: 'status-not-interested-after-interview',
      id: 120, // prev: 30
    },
    {
      key: 'status-hired',
      id: 130, // prev: 110
    },
  ];
};

export { Color, webDefaultText, defaultText, webFont, Font, Variables, getStatus, getStatusList };

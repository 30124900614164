import React, { Component } from 'react';
import { css } from 'aphrodite';

export default class Loader extends Component {
  render() {
    const { style } = this.props;

    return (
      <div className={'loader ' + css(style)}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    );
  }
}

/*
return (
      <div className="loader-wrapper">
        <div className={ 'loader ' + css( style ) }><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
      </div>
    );
*/

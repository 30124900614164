import { css, StyleSheet } from 'aphrodite';

import { Box, Checkbox } from '@talentech/components';
import { useTranslation } from 'react-i18next';

const InvitationFields = (props) => {
  const { setFormData } = props;
  const { t } = useTranslation();

  return (
    <>
      <Box className="row">
        <Box className="col">
          <label className={css(styles.label)} htmlFor="firstname">
            {t('generic-input-Givenname').toUpperCase()}
            <span className={css(styles.required)}>*</span>
          </label>
          <input
            type="text"
            maxLength="45"
            className="form-control"
            onChange={(e) =>
              setFormData((formData) => ({ ...formData, givenname: e.target.value.trim() }))
            }
            placeholder={t('generic-input-Givenname')}
            id="firstname"
          />
        </Box>
        <Box className="col">
          <label className={css(styles.label)} htmlFor="lastname">
            {t('generic-input-Lastname').toUpperCase()}
            <span className={css(styles.required)}>*</span>
          </label>
          <input
            type="text"
            maxLength="45"
            className="form-control"
            onChange={(e) =>
              setFormData((formData) => ({ ...formData, familyname: e.target.value.trim() }))
            }
            placeholder={t('generic-input-Lastname')}
            id="lastname"
          />
        </Box>
      </Box>
      <Box className="row">
        <Box className="col">
          <label className={css(styles.label)} htmlFor="email">
            {t('generic-input-Mail').toUpperCase()}
            <span className={css(styles.required)}>*</span>
          </label>
          <input
            type="email"
            maxLength="55"
            className="form-control"
            onChange={(e) =>
              setFormData((formData) => ({ ...formData, email: e.target.value.trim() }))
            }
            placeholder={t('generic-input-Mail')}
            id="email"
          />
        </Box>
      </Box>
      <Box className="row">
        <Box className="col d-flex">
          <Checkbox
            onClick={(e) => setFormData((formData) => ({ ...formData, admin: e.target.checked }))}
            id="checkbox"
          />
          <label className={css(styles.label)} htmlFor="checkbox">
            {t('invitationpage-make-admin')}
          </label>
        </Box>
      </Box>
    </>
  );
};

export default InvitationFields;

const styles = StyleSheet.create({
  required: {
    color: 'red',
  },
  label: {
    marginBottom: '8px',
  },
});

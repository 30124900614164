import Button from '../components/Button';
import Input from '../components/Input';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { css, StyleSheet } from 'aphrodite';

import { Variables } from '../config/styles';
import { IntlNetwork } from '../helpers/language';

import Loader from '../components/Loader';
import { withTranslation } from 'react-i18next';

class NotInterestedPage extends Component {
  static propTypes = {
    /**
     * Loading data
     */
    isLoading: PropTypes.bool.isRequired,
    /*
     * Button: Handles sending feedback to the company
     */
    onSendFeedback: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      comment: null,
    };
  }

  render() {
    const { t, isLoading, onSendFeedback } = this.props;

    return (
      <Loader isLoading={isLoading}>
        <div className={css(styles.container)}>
          <div className={css(styles.responsiveWrap)}>
            <p>{IntlNetwork(t, 'notinterestedpage-lead')}</p>
            <form>
              <Input
                type="text"
                name="comment"
                placeholder={t('notinterestedpage-input-comment')}
                onChange={(event) => {
                  this.setState({ comment: event.target.value });
                }}
              />
              <Button
                title={t('notinterestedpage-button-send')}
                type="primary"
                onPress={(event) => {
                  onSendFeedback(event, this.state.comment);
                }}
                style={styles.sendFeedbackButton}
              />
            </form>
          </div>
        </div>
      </Loader>
    );
  }
}

export default withTranslation()(NotInterestedPage);

const styles = StyleSheet.create({
  container: {
    minHeight: Variables.MainWrapperHeight,
    padding: Variables.BubbleSectionPadding,
    background: 'white',
  },
  sendFeedbackButton: {
    margin: '2em 0 1em',
  },
  responsiveWrap: {
    maxWidth: 700,
    margin: '0 auto',
  },
});

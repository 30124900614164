import {
  GET_RECOMMENDATION_REQUEST,
  GET_RECOMMENDATION_SUCCESS,
  GET_RECOMMENDATION_FAILURE,
  GET_RECOMMENDATIONS_INVALIDATE,
  GET_RECOMMENDATION_INVALIDATE,
  GET_RECOMMENDATIONS_REQUEST,
  GET_RECOMMENDATIONS_SUCCESS,
  GET_RECOMMENDATIONS_FAILURE,
  GET_RECOMMENDATIONS_USER_INVALIDATE,
  GET_RECOMMENDATIONS_USER_REQUEST,
  GET_RECOMMENDATIONS_USER_SUCCESS,
  GET_RECOMMENDATIONS_USER_FAILURE,
  GET_RECOMMENDATION_FOR_POSITION_REQUEST,
  GET_RECOMMENDATION_FOR_POSITION_SUCCESS,
  GET_RECOMMENDATION_FOR_POSITION_FAILURE,
  GET_MULTIPLE_RECOMMENDATION_FOR_POSITION_REQUEST,
  GET_MULTIPLE_RECOMMENDATION_FOR_POSITION_SUCCESS,
  GET_MULTIPLE_RECOMMENDATION_FOR_POSITION_FAILURE,
  GET_RECOMMENDATION_STAGES_FOR_POSITION_REQUEST,
  GET_RECOMMENDATION_STAGES_FOR_POSITION_SUCCESS,
  GET_RECOMMENDATION_STAGES_FOR_POSITION_FAILURE,
  UPDATE_RECOMMENDATION_FOR_POSITION_REQUEST,
  UPDATE_RECOMMENDATION_FOR_POSITION_SUCCESS,
  UPDATE_RECOMMENDATION_FOR_POSITION_FAILURE,
  EMPTY_RECOMMENDATION_FOR_POSITION,
  POST_RECOMMENDATION_POSITION_REQUEST,
  POST_RECOMMENDATION_POSITION_SUCCESS,
  POST_RECOMMENDATION_POSITION_FAILURE,
  POST_RECOMMENDATION_MANUAL_REQUEST,
  POST_RECOMMENDATION_MANUAL_SUCCESS,
  POST_RECOMMENDATION_MANUAL_FAILURE,
  POST_REFERRAL_RECOMMENDATION_REQUEST,
  POST_REFERRAL_RECOMMENDATION_SUCCESS,
  POST_REFERRAL_RECOMMENDATION_FAILURE,
  POST_RECOMMENDATION_INTEREST_REQUEST,
  POST_RECOMMENDATION_INTEREST_SUCCESS,
  POST_RECOMMENDATION_INTEREST_FAILURE,
  POST_RECOMMENDATION_CONFIRM_REQUEST,
  POST_RECOMMENDATION_CONFIRM_SUCCESS,
  POST_RECOMMENDATION_CONFIRM_FAILURE,
} from '../actions/recommendationActions';

const initialState = {
  recommendation: {
    error: false,
    isLoading: false,
    response: {},
    request: null, // axios request response object
    requestSuccess: false,
  },
  recommendations: {
    didInvalidate: false,
    error: false,
    isLoading: false,
    response: [],
  },
  positionRecommendations: {
    didInvalidate: false,
    error: false,
    isLoading: false,
    response: [],
    request: null,
  },
  recommendationPost: {
    error: false,
    isLoading: false,
    response: [],
    request: null,
  },
  recommendationInterest: {
    error: false,
    isLoading: false,
    response: {},
    request: null,
  },
  recommendationConfirm: {
    error: false,
    isLoading: false,
    response: {},
    request: null, // axios request response object
  },
  recommendationsForPositions: {
    response: {},
    isLoading: false,
  },
  recommendationStages: {
    error: false,
    isLoading: false,
    response: [],
  },
};

export default function recommendationState(
  state = initialState,
  { authenticated, error, response, type, request, meta },
) {
  switch (type) {
    case GET_RECOMMENDATION_REQUEST:
      return {
        ...state,
        recommendation: {
          ...state.recommendation,
          error: false,
          isLoading: true,
        },
      };
    case GET_RECOMMENDATION_SUCCESS:
      return {
        ...state,
        recommendation: {
          ...state.recommendation,
          isLoading: false,
          response: response,
        },
      };
    case GET_RECOMMENDATION_FAILURE:
      return {
        ...state,
        recommendation: {
          ...state.recommendation,
          isLoading: false,
          error: true,
          response: error,
          request: request,
        },
      };

    case EMPTY_RECOMMENDATION_FOR_POSITION:
      return {
        ...state,
        positionRecommendations: {
          error: false,
          isLoading: false,
          response: [],
          request: null,
        },
      };

    case UPDATE_RECOMMENDATION_FOR_POSITION_REQUEST:
      return {
        ...state,
        recommendationPost: {
          ...state.recommendationPost,
          error: false,
          isLoading: true,
        },
      };
    case UPDATE_RECOMMENDATION_FOR_POSITION_SUCCESS:
      return {
        ...state,
        recommendationPost: {
          ...state.recommendationPost,
          isLoading: false,
          response: response,
          request: request,
        },
      };
    case UPDATE_RECOMMENDATION_FOR_POSITION_FAILURE:
      return {
        ...state,
        recommendationPost: {
          ...state.recommendationPost,
          isLoading: false,
          error: true,
          response: error,
        },
      };

    case GET_RECOMMENDATION_FOR_POSITION_REQUEST:
      return {
        ...state,
        positionRecommendations: {
          ...state.positionRecommendations,
          error: false,
          isLoading: true,
        },
      };
    case GET_RECOMMENDATION_FOR_POSITION_SUCCESS:
      return {
        ...state,
        positionRecommendations: {
          ...state.positionRecommendations,
          isLoading: false,
          response: response,
          request: request,
        },
      };
    case GET_RECOMMENDATION_FOR_POSITION_FAILURE:
      return {
        ...state,
        positionRecommendations: {
          ...state.positionRecommendations,
          isLoading: false,
          error: true,
          response: error,
        },
      };

    case GET_MULTIPLE_RECOMMENDATION_FOR_POSITION_REQUEST:
      return {
        ...state,
        recommendationsForPositions: {
          ...state.recommendationsForPositions,
          isLoading: true,
        },
      };
    case GET_MULTIPLE_RECOMMENDATION_FOR_POSITION_SUCCESS:
      return {
        ...state,
        recommendationsForPositions: {
          ...state.recommendationsForPositions,
          isLoading: false,
          response: {
            ...state.recommendationsForPositions.response,
            [meta.id]: response,
          },
        },
      };
    case GET_MULTIPLE_RECOMMENDATION_FOR_POSITION_FAILURE:
      return {
        ...state,
        recommendationsForPositions: {
          ...state.recommendationsForPositions,
        },
      };

    case GET_RECOMMENDATIONS_INVALIDATE:
      return {
        ...state,
        recommendations: {
          ...state.recommendations,
          didInvalidate: true,
        },
      };
    case GET_RECOMMENDATION_INVALIDATE:
      return {
        ...state,
        recommendation: {
          ...state.recommendation,
          didInvalidate: true,
          response: {},
          error: false,
        },
      };
    case GET_RECOMMENDATIONS_REQUEST:
      return {
        ...state,
        recommendations: {
          ...state.recommendations,
          error: false,
          isLoading: true,
        },
      };
    case GET_RECOMMENDATIONS_SUCCESS:
      return {
        ...state,
        recommendations: {
          ...state.recommendations,
          didInvalidate: false,
          isLoading: false,
          response: response,
        },
      };
    case GET_RECOMMENDATIONS_FAILURE:
      return {
        ...state,
        recommendations: {
          ...state.recommendations,
          isLoading: false,
          error: true,
          response: error,
        },
      };

    case GET_RECOMMENDATIONS_USER_INVALIDATE:
      return {
        ...state,
        recommendations: {
          ...state.recommendations,
          didInvalidate: true,
        },
      };
    case GET_RECOMMENDATIONS_USER_REQUEST:
      return {
        ...state,
        recommendations: {
          ...state.recommendations,
          error: false,
          isLoading: true,
        },
      };
    case GET_RECOMMENDATIONS_USER_SUCCESS:
      return {
        ...state,
        recommendations: {
          ...state.recommendations,
          didInvalidate: false,
          isLoading: false,
          response: response,
        },
      };
    case GET_RECOMMENDATIONS_USER_FAILURE:
      return {
        ...state,
        recommendations: {
          ...state.recommendations,
          isLoading: false,
          error: true,
          response: error,
        },
      };

    case POST_RECOMMENDATION_POSITION_REQUEST:
      return {
        ...state,
        recommendation: {
          ...state.recommendation,
          error: false,
          isLoading: true,
        },
      };
    case POST_RECOMMENDATION_POSITION_SUCCESS:
      return {
        ...state,
        recommendation: {
          ...state.recommendation,
          isLoading: false,
          response: response,
        },
      };
    case POST_RECOMMENDATION_POSITION_FAILURE:
      return {
        ...state,
        recommendation: {
          ...state.recommendation,
          isLoading: false,
          error: true,
          response: request,
        },
      };
    case POST_RECOMMENDATION_MANUAL_REQUEST:
      return {
        ...state,
        recommendation: {
          ...state.recommendation,
          error: false,
          isLoading: true,
        },
      };
    case POST_RECOMMENDATION_MANUAL_SUCCESS:
      return {
        ...state,
        recommendation: {
          ...state.recommendation,
          isLoading: false,
          response: response,
          request: request,
        },
      };
    case POST_RECOMMENDATION_MANUAL_FAILURE:
      return {
        ...state,
        recommendation: {
          ...state.recommendation,
          isLoading: false,
          error: true,
          response: request,
          request: request,
        },
      };

    case POST_REFERRAL_RECOMMENDATION_REQUEST:
      return {
        ...state,
        recommendation: {
          ...state.recommendation,
          error: false,
          isLoading: true,
        },
      };
    case POST_REFERRAL_RECOMMENDATION_SUCCESS:
      return {
        ...state,
        recommendation: {
          ...state.recommendation,
          isLoading: false,
          response: response,
          requestSuccess: true,
        },
      };
    case POST_REFERRAL_RECOMMENDATION_FAILURE:
      return {
        ...state,
        recommendation: {
          ...state.recommendation,
          isLoading: false,
          error: true,
          response: request,
        },
      };

    case POST_RECOMMENDATION_INTEREST_REQUEST:
      return {
        ...state,
        recommendationInterest: {
          ...state.recommendation,
          error: false,
          isLoading: true,
        },
      };
    case POST_RECOMMENDATION_INTEREST_SUCCESS:
      return {
        ...state,
        recommendationInterest: {
          ...state.recommendation,
          isLoading: false,
          response: response,
          request: request,
        },
      };
    case POST_RECOMMENDATION_INTEREST_FAILURE:
      return {
        ...state,
        recommendationInterest: {
          ...state.recommendation,
          isLoading: false,
          error: true,
          response: error,
          request: request,
        },
      };

    case POST_RECOMMENDATION_CONFIRM_REQUEST:
      return {
        ...state,
        recommendationConfirm: {
          ...state.recommendationConfirm,
          error: false,
          isLoading: true,
        },
      };
    case POST_RECOMMENDATION_CONFIRM_SUCCESS:
      return {
        ...state,
        recommendationConfirm: {
          ...state.recommendationConfirm,
          isLoading: false,
          response: response,
          request: request,
        },
      };
    case POST_RECOMMENDATION_CONFIRM_FAILURE:
      return {
        ...state,
        recommendationConfirm: {
          ...state.recommendationConfirm,
          isLoading: false,
          error: true,
          response: response,
          request: request,
        },
      };
    case GET_RECOMMENDATION_STAGES_FOR_POSITION_REQUEST:
      return {
        ...state,
        positionRecommendations: {
          ...state.positionRecommendations,
          isLoading: true,
          error: false,
        },
      };
    case GET_RECOMMENDATION_STAGES_FOR_POSITION_SUCCESS:
      return {
        ...state,
        positionRecommendations: {
          ...state.positionRecommendations,
          isLoading: false,
          response: response,
        },
      };
    case GET_RECOMMENDATION_STAGES_FOR_POSITION_FAILURE:
      return {
        ...state,
        positionRecommendations: {
          ...state.positionRecommendations,
          isLoading: false,
          error: true,
          response: error,
        },
      };
    default:
      return state;
  }
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite';

import { Color, Font } from '../config/styles';

/**
 * Presentation of profile picture. Use name to fallback to picture with initials. Either picture or name accepted, not both.
 *
 * Example usage:
 *
 * ```
 * <ProfilePicture name="John Doe" />
 * ```
 *
 */
class ProfilePicture extends Component {
  static propTypes = {
    /**
     * Child components
     */
    children: PropTypes.node,
    /**
     * Custom styles to be applied to the image.
     */
    imageStyle: PropTypes.number,
    /**
     * User name
     */
    name: PropTypes.string.isRequired,
    /**
     * Source of image
     */
    picture: PropTypes.string.isRequired,
    /**
     * Profile picture sizes (small, medium, large=default, xlarge)
     */
    size: PropTypes.string.isRequired,
    /**
     * Custom styles to be applied to the component.
     */
    style: PropTypes.object,
  };

  static defaultProps = {
    size: 'large',
  };

  state = {
    imgError: false,
  };

  render() {
    const { children, imageStyle, name, picture, style } = this.props;

    let initials = name
      ? name
          .split(' ')
          .map((n) => n[0])
          .join('')
          .toUpperCase()
      : '--';
    initials = initials.substr(0, 2); // max two chars

    if (this.state.imgError) {
      return (
        <div className={css(styles.wrap, this.sizeStyle(), styles.placeholder, style)}>
          <span className={css(styles.text, this.textSizeStyle())}>{initials}</span>
          {children}
        </div>
      );
    }

    return (
      <div className={css(styles.wrap, this.sizeStyle(), style)}>
        <img
          src={picture}
          className={css(styles.wrap, this.sizeStyle(), styles.image, imageStyle)}
          alt=""
          onError={this.replaceIMG}
        />
        {children}
      </div>
    );
  }

  /**
   * Replaces image with initials if it cannot be found.
   */
  replaceIMG = () => {
    this.setState({
      imgError: true,
    });
  };

  /**
   * Helps find the right styles for this size
   */
  sizeStyle = () => {
    if (styles[`size${this.props.size}`] === undefined) {
      return {};
    }

    return styles[`size${this.props.size}`];
  };

  textSizeStyle = () => {
    if (styles[`textSize${this.props.size}`] === undefined) {
      return {};
    }

    return styles[`textSize${this.props.size}`];
  };
}

export default ProfilePicture;

const styles = StyleSheet.create({
  image: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
  },
  placeholder: {
    backgroundColor: Color.white,
    boxShadow: `inset 0 0 0 1px ${Color.primary}`,
  },
  sizesmall: {
    borderRadius: 13,
    height: 26,
    width: 26,
  },
  sizemedium: {
    borderRadius: 26,
    height: 52,
    width: 52,
  },
  sizelarge: {
    borderRadius: 34,
    height: 68,
    width: 68,
  },
  sizexlarge: {
    borderRadius: 39,
    height: 78,
    width: 78,
  },
  text: {
    backgroundColor: 'transparent',
    color: Color.primary,
    fontFamily: Font.defaultFont,
    letterSpacing: 1.5,
    textDecoration: 'none',
  },
  textSizesmall: {
    fontSize: 12,
  },
  textSizemedium: {
    fontSize: 26,
  },
  textSizelarge: {
    fontSize: 32,
  },
  textSizexlarge: {
    fontSize: 38,
  },
  wrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
  },
});

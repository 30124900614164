import React, { Component } from 'react';

import { connect } from 'react-redux';
import moment from 'moment';
import PositionEditPage from '../../pages/admin/PositionEditPage';
import Utils from '../../irecommend-lib/utils';
import { postPositionCategories } from '../../irecommend-lib/actions/positionActions';
import {
  getPosition,
  postPosition,
  removeExternalPosition,
} from '../../irecommend-lib/actions/positionActions';
import {
  getCustomerCategories,
  getUserCustomer,
} from '../../irecommend-lib/actions/customerActions';
import { withTranslation } from 'react-i18next';

class PositionEditContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitForm: false,
      postCategories: [],
    };
  }

  componentDidMount() {
    this.props.getUserCustomer();
    // Load the position to be edited
    if (this.props.match.params.id !== undefined) {
      this.props.getPosition(this.props.match.params.id);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // Check for errors on form submission
    if (
      this.props.positionPost !== nextProps.positionPost &&
      nextProps.positionPost.isLoading === false
    ) {
      if (nextProps.positionPost.error) {
        const { t } = this.props;
        alert(t('generic-error-default-message'));

        return;
      }

      const positionID =
        this.props.match.params.id !== undefined
          ? this.props.match.params.id
          : nextProps.positionPost.response.idpositions;
      const customerCategories = this.props.customerCategories.response
        ? this.props.customerCategories.response
        : [];
      customerCategories.length > 0 &&
        this.props.postPositionCategories(positionID, this.state.postCategories);
      // Successful create/update
      this.props.history.push('/admin');
    }
  }

  render() {
    const isEdit = this.props.match.params.id !== undefined;
    const isExternal = this.props.match.params.externalid !== undefined;
    const isLoading = this.props.position.isLoading || this.props.positionPost.isLoading;
    const userCustomer = this.props.userCustomer.response;
    const customerCategories = this.props.customerCategories.response;

    // Getting position depending on if it's a new, external, or existing position
    let position = {};
    if (isExternal) {
      const externalPositions = this.props.externalPositions.response;

      for (let i = 0; i < externalPositions.length; i++) {
        if (externalPositions[i].ID === this.props.match.params.externalid) {
          position = { ...externalPositions[i] };
          position.body = this.stripHtml(position.body);
        }
      }
    } else if (isEdit && this.props.position.response) {
      position = this.props.position.response;
    }

    return (
      <div>
        <PositionEditPage
          userCustomer={userCustomer}
          customerCategories={customerCategories}
          onPressSubmitForm={this.onPressSubmitForm}
          isEdit={isEdit}
          isExternal={isExternal}
          isLoading={isLoading}
          position={position}
        />
      </div>
    );
  }

  /**
   * POST to API
   */
  onPressSubmitForm = (data, categories) => {
    const { t, customerCategories } = this.props;

    // Form validation
    if (customerCategories.response.length > 0 && categories.length === 0) {
      alert(this.props.t('category-page-alert-description'));
      return false;
    }
    if (data.Title === '') {
      alert(this.props.t('positioneditcontainer-validation-title'));
      return false;
    }
    if (data.JobLocation === '') {
      alert(t('positioneditcontainer-validation-joblocation'));
      return false;
    }
    if (data.Skills.length === 0 || data.Skills[0] === '') {
      alert(t('positioneditcontainer-validation-skills'));
      return false;
    }
    if (data.Keywords.length === 0 || data.Keywords[0] === '') {
      alert(t('positioneditcontainer-validation-keywords'));
      return false;
    }
    if (data.Text === '' || data.Text === '<p><br></p>') {
      alert(t('positioneditcontainer-validation-text'));
      return false;
    }
    if (data.ContactName === '') {
      alert(t('positioneditcontainer-validation-contactname'));
      return false;
    }
    if (data.ContactMail === '') {
      alert(t('positioneditcontainer-validation-contactmail'));
      return false;
    }

    if (!Utils.validateEmail(data.ContactMail)) {
      alert(t('generic-validation-email'));
      return false;
    }

    const categoryIDs = categories.map((cat) => cat.idcategories);
    //API expects the format "2020-20-05" and it being named EndDate instead of Enddate, momentjs helps with formatting.
    //If it's an empty string, send an emptry string to API. Otherwise, attempt to format the date and send it back.
    data.Enddate = data.Enddate === '' ? '' : moment(data.Enddate).format('YYYY-MM-DD');
    data.EndDate = data.Enddate;
    delete data.Enddate;

    // Remove empty entries
    data.Skills = data.Skills.filter((value) => value !== '');
    data.Keywords = data.Keywords.filter((value) => value !== '');

    // Prepare data for submission
    data.Skills = data.Skills.join(';');
    data.Keywords = data.Keywords.filter((keyword) => keyword !== '').join(';');
    data.Push = data.Push.length ? data.Push.join(';') : '';
    data.Interval = typeof data.Interval === 'number' ? data.Interval : 0;
    data.Time = data.Time || '08:00';
    data.Categories = categoryIDs.join(';');

    delete data.showModal;

    // Used to know when to look for request errors in componentWillReceiveProps
    this.setState(
      {
        isSubmitForm: true,
      },
      () => {
        let id = null;

        // Check if it is creating, external or updating position
        if (this.props.match.params.externalid !== undefined) {
          // Remove external position from redux state and add external ID to store in DB
          data.ExternalID = this.props.match.params.externalid;
          this.removePosition(this.props.match.params.externalid);
        } else if (this.props.match.params.id !== undefined) {
          id = this.props.match.params.id;
        }

        this.props.postPosition(data, id);
        this.setState(
          { postCategories: categories.map((cat) => ({ idcategories: cat.idcategories })) },
          () => console.log('posted'),
        );
      },
    );

    return true;
  };

  // Removes external position from redux state
  removePosition = (id) => {
    const externalPositions = this.props.externalPositions.response;

    for (let i = 0; i < externalPositions.length; i++) {
      if (externalPositions[i].ID === id) {
        externalPositions.splice(i, 1);
      }
    }

    this.props.removeExternalPosition(externalPositions);
  };

  /**
   * Returns the text from a HTML string
   *
   */
  stripHtml = (html) => {
    let lineBreaked = html.replace(new RegExp('<br>', 'g'), '\n\n');
    lineBreaked = lineBreaked.replace(new RegExp('</p>', 'g'), '\n\n');
    // Create a new div element
    var temporalDivElement = document.createElement('div');
    // Set the HTML content with the providen
    temporalDivElement.innerHTML = lineBreaked;
    // Retrieve the text property of the element (cross-browser support)
    return temporalDivElement.textContent || temporalDivElement.innerText || '';
  };
}

function mapStateToProps(state) {
  return {
    userCustomer: state.customerState.userCustomer,
    externalPositions: state.positionState.externalPositions,
    position: state.positionState.position,
    positionPost: state.positionState.positionPost,
    customerCategories: state.customerState.customerCategories,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getUserCustomer: () => dispatch(getUserCustomer()),
    getPosition: (id) => dispatch(getPosition(id)),
    postPosition: (data, id) => dispatch(postPosition(data, id)),
    postPositionCategories: (data, id) => dispatch(postPositionCategories(data, id)),
    getCustomerCategories: () => dispatch(getCustomerCategories()),
    removeExternalPosition: (positions) => dispatch(removeExternalPosition(positions)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(PositionEditContainer));

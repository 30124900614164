import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import LineChart from './LineChart';
import { withTranslation } from 'react-i18next';

class LineCharts extends Component {
  state = {
    size: {
      width: 800,
      height: 200,
    },
  };
  render() {
    const data = this.props.lineChartDate;
    const { t } = this.props;

    return (
      <Tabs>
        <TabList>
          <Tab>{t('line-chart-tab-weekly')}</Tab>
          <Tab>{t('line-chart-tab-hourly')}</Tab>
        </TabList>
        <TabPanel>
          <LineChart
            chartData={data.weekChart}
            options={data.weekOptions}
            size={this.state.size}
            classes="chart"
          />
        </TabPanel>
        <TabPanel>
          <LineChart
            chartData={data.chartData}
            options={data.options}
            size={this.state.size}
            classes="chart"
          />
        </TabPanel>
      </Tabs>
    );
  }
}

// function mapStateToProps(state){
//     return {
//         time: state.BIDashboardState.time
//     };
// }

export default withTranslation()(LineCharts);

// import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Color } from '../../config/styles';
import { css, StyleSheet } from 'aphrodite';
import CompetitionItem from './CompetitionItem';

class CompetitionIndividual extends Component {
  render() {
    const { t, competitions, onPressEdit, onPressCompetitionDetails, onPressDeleteCompetition } =
      this.props;

    const individualCompetitions = competitions.map((item, index) => (
      <CompetitionItem
        key={index}
        name={item.title}
        picture={item.image}
        prices={item.reward}
        startDate={item.startdate}
        endDate={item.enddate}
        id={item.idcompetitions}
        t={t}
        onPressDelete={() => onPressDeleteCompetition(item.idcompetitions, item.title)}
        onPressEdit={() => onPressEdit(item.idcompetitions)}
        onPressCompetitionDetails={onPressCompetitionDetails}
      />
    ));

    return (
      <div className={css(styles.listItem)}>
        {individualCompetitions.length === 0 ? (
          <span className={css(styles.span)}>{t('competition-individual-no-competitions')}</span>
        ) : (
          <ul className={css(styles.listContainer)}>{individualCompetitions}</ul>
        )}
      </div>
    );
  }
}

export default CompetitionIndividual;

const styles = StyleSheet.create({
  itemContainer: {
    display: 'flex',
  },
  listItem: {
    borderRadius: '2px',
    margin: '0 0 1.5625rem',
    display: 'flex',
    flexDirection: 'column',
  },
  listSection: {
    padding: '1em',
    width: '60%',
  },
  listSection2: {
    display: 'flex',
    width: '40%',
    justifyContent: 'flex-end',
  },
  buttonContainer: {
    padding: ' 0 1em 0 0',
    width: '48%',
  },
  title: {
    margin: '0 0 .2em',
    marginRight: '50px',
    fontSize: '1.3em',
    fontWeight: 'bold',
    color: Color.primary,
  },
  listContainer: {
    width: '100%',
    listStyle: 'none',
    paddingLeft: '0',
  },
  container: {
    margin: '0 auto',
  },
  span: {
    fontSize: '1.5em',
    fontWeight: '600',
  },
});

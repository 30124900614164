import {
  UPDATE_REFERRAL_REQUEST,
  UPDATE_REFERRAL_SUCCESS,
  UPDATE_REFERRAL_FAILURE,
  GET_REFERRAL_DOCUMENT_REQUEST,
  GET_REFERRAL_DOCUMENT_SUCCESS,
  GET_REFERRAL_DOCUMENT_FAILURE,
  POST_REFERRAL_DOCUMENT_REQUEST,
  POST_REFERRAL_DOCUMENT_SUCCESS,
  POST_REFERRAL_DOCUMENT_FAILURE,
} from '../actions/referralActions';

const initialState = {
  referral: {
    error: false,
    isLoading: false,
    response: {},
  },
  referralDocument: {
    error: false,
    isLoading: false,
    response: {},
  },
};

export default function referralState(
  state = initialState,
  { authenticated, error, response, type },
) {
  switch (type) {
    case UPDATE_REFERRAL_REQUEST:
      return {
        ...state,
        referral: {
          ...state.referral,
          isLoading: true,
        },
      };
    case UPDATE_REFERRAL_SUCCESS:
      return {
        ...state,
        referral: {
          ...state.referral,
          isLoading: false,
          error: false,
          response: response,
        },
      };
    case UPDATE_REFERRAL_FAILURE:
      return {
        ...state,
        referral: {
          ...state.referral,
          isLoading: false,
          error: true,
          response: error,
        },
      };
    case GET_REFERRAL_DOCUMENT_REQUEST:
      return {
        ...state,
        referralDocument: {
          ...state.referralDocument,
          isLoading: true,
        },
      };
    case GET_REFERRAL_DOCUMENT_SUCCESS:
      return {
        ...state,
        referralDocument: {
          ...state.referralDocument,
          isLoading: false,
          response: response,
        },
      };
    case GET_REFERRAL_DOCUMENT_FAILURE:
      return {
        ...state,
        referralDocument: {
          ...state.referralDocument,
          isLoading: false,
          error: true,
          response: error,
        },
      };
    case POST_REFERRAL_DOCUMENT_REQUEST:
      return {
        ...state,
        referralDocument: {
          ...state.referralDocument,
          isLoading: true,
        },
      };
    case POST_REFERRAL_DOCUMENT_SUCCESS:
      return {
        ...state,
        referralDocument: {
          ...state.referralDocument,
          isLoading: false,
          error: false,
          response: response,
        },
      };
    case POST_REFERRAL_DOCUMENT_FAILURE:
      return {
        ...state,
        referralDocument: {
          ...state.referralDocument,
          isLoading: false,
          error: true,
          response: error,
        },
      };
    default:
      return state;
  }
}

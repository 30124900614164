import React, { Component } from 'react';
import { connect } from 'react-redux';

import HomePage from '../pages/HomePage';
import { changeAppRoot } from '../irecommend-lib/actions/appActions';
import { logoutUser } from '../irecommend-lib/actions/authActions';
import { getCustomerCategories } from '../irecommend-lib/actions/customerActions';
import {
  getPositions,
  getPositionsAdmin,
  getReadPositionsIfNeeded,
  readPositionsInvalidate,
  setPositionRead,
} from '../irecommend-lib/actions/positionActions';
import {
  getUserCategories,
  getUserIfNeeded,
  postUserCategories as postCategories,
} from '../irecommend-lib/actions/userActions';

class HomeContainer extends Component {
  static propTypes = {};
  /**
   * Get data. Update if necessary.
   */
  componentDidMount() {
    this.props.getUserIfNeeded();
    this.props.getPositions();
    this.props.getPositionsAdmin();
    this.props.getReadPositionsIfNeeded();
  }
  UNSAFE_componentWillMount() {
    this.props.getCustomerCategories();
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    // Verify that the API response is valid. Otherwise show an error message and logout the user.
    if (
      this.props.positions.response !== nextProps.positions.response &&
      !Array.isArray(nextProps.positions.response)
    ) {
      console.log('Unexpected response from API');
    }

    if (this.props.user.response !== nextProps.user.response) {
      this.props.getUserCategories(nextProps.user.response.user.iduser);
    }

    if (this.props.postUserCategories.response !== nextProps.postUserCategories.response) {
      if (
        nextProps.postUserCategories.request.status !== 200 &&
        nextProps.postUserCategories.error
      ) {
        alert(this.props.t('generic-error-default-message'));
      }
      this.props.getUserCategories(nextProps.user.response.user.iduser);
      this.props.getPositions();
    }
  }

  render() {
    const isLoading =
      this.props.positions.isLoading ||
      this.props.user.isLoading ||
      this.props.customerCategories.isLoading ||
      this.props.userCategories.isLoading ||
      this.props.postUserCategories.isLoading;
    const positions = Array.isArray(this.props.positions.response)
      ? this.props.positions.response.filter((position) => !position.archived)
      : [];
    const positionsAdmin = Array.isArray(this.props.positionsAdmin.response)
      ? this.props.positionsAdmin.response.filter((position) => !position.archived)
      : [];
    const customerCategories = Array.isArray(this.props.customerCategories.response)
      ? this.props.customerCategories.response
      : [];
    const userCategories = Array.isArray(this.props.userCategories.response)
      ? this.props.userCategories.response
      : [];
    const readPositions = this.props.readPositions.ids;
    const user = this.props.user.response;

    return (
      <HomePage
        onPressListItem={this.onPressListItem}
        onPressProfileLink={() => {
          this.props.history.push(`/profile/edit`);
        }}
        positions={positions}
        positionsAdmin={positionsAdmin}
        customerCategories={customerCategories}
        postCategories={this.props.postCategories}
        getUserCategories={this.props.getUserCategories}
        getCustomerCategories={this.props.getCustomerCategories}
        userCategories={userCategories}
        readPositions={readPositions}
        isLoading={isLoading}
        user={user}
      />
    );
  }

  /**
   * When pressing a position in the list. Mark the position as read and navigate to PositionContainer
   * @param {number} id - Position ID
   */
  onPressListItem = (id) => {
    this.props.history.push(`/positions/${id}`);
  };
}

function mapStateToProps(state) {
  return {
    positions: state.positionState.positions,
    positionsAdmin: state.positionState.positionsAdmin,
    readPositions: state.positionState.readPositions,
    user: state.userState.user,
    customerCategories: state.customerState.customerCategories,
    userCategories: state.userState.userCategories,
    postUserCategories: state.userState.postUserCategories,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeAppRoot: (root) => dispatch(changeAppRoot(root)),
    getPositions: () => dispatch(getPositions()),
    getPositionsAdmin: () => dispatch(getPositionsAdmin()),
    getReadPositionsIfNeeded: () => dispatch(getReadPositionsIfNeeded()),
    getUserIfNeeded: () => dispatch(getUserIfNeeded()),
    logoutUser: () => dispatch(logoutUser()),
    readPositionsInvalidate: () => dispatch(readPositionsInvalidate()),
    setPositionRead: (id) => dispatch(setPositionRead(id)),
    getCustomerCategories: () => dispatch(getCustomerCategories()),
    getUserCategories: (id) => dispatch(getUserCategories(id)),
    postCategories: (id, data) => dispatch(postCategories(id, data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite';

import BubbleSection from '../components/BubbleSection';
import MainWrapper from '../components/MainWrapper';
import Loader from '../components/Loader';
import Footer from '../components/Footer';

import { Color, Font } from '../config/styles';

class ArticlePage extends Component {
  static propTypes = {
    /**
     * Article content string / jsx object
     */
    content: PropTypes.any.isRequired,
    /**
     * Article header
     */
    header: PropTypes.string.isRequired,

    /**
     * How to know when the article data has been loaded
     */
    isLoading: PropTypes.bool.isRequired,
  };

  render() {
    const { content, header, isLoading } = this.props;

    return (
      <Loader isLoading={isLoading}>
        <MainWrapper style={styles.wrap}>
          <div className={css(styles.responsiveWrap)}>
            {!isLoading && (
              <BubbleSection style={styles.bubble}>
                <div className={css(styles.content)}>
                  <h1>{header}</h1>

                  {content}
                </div>
              </BubbleSection>
            )}
          </div>
        </MainWrapper>
        <Footer />
      </Loader>
    );
  }
}

export default ArticlePage;

const styles = StyleSheet.create({
  bubble: {
    padding: 30,
    paddingTop: 0,
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
  },
  content: {
    color: Color.dark,
    fontFamily: Font.defaultFont,
    fontSize: 14,
    lineHeight: 1.5,
  },
  header: {
    color: Color.dark,
  },
  wrap: {
    padding: 20,
  },
  responsiveWrap: {
    maxWidth: 700,
    margin: '0 auto',
  },
});

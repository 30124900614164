import { useRef } from 'react';
import { useDispatch } from 'react-redux';

const useShowToast = (setState, t, resetType = '') => {
  const dispatch = useDispatch();
  const timeoutIdRef = useRef();

  const showToastMessage = (messageKey, error = false, message = '') => {
    setState((state) => ({
      ...state,
      error: error,
      showToast: true,
      message: message ? message : t(messageKey),
    }));
    closeToast();
  };

  const closeToast = (closeDirectly = false) => {
    if (resetType) dispatch({ type: resetType });

    const delay = closeDirectly ? 0 : 2500;

    clearTimeout(timeoutIdRef.current);
    timeoutIdRef.current = setTimeout(() => {
      setState((state) => ({
        ...state,
        showToast: false,
        error: false,
        message: '',
      }));
    }, delay);
  };

  return { showToastMessage, closeToast };
};

export default useShowToast;

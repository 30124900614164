import React, { Component } from 'react';
import { connect } from 'react-redux';
import AdminWrapper from '../../components/admin/AdminWrapper';
import { Redirect } from 'react-router-dom';

import {
  getCustomersIfNeeded,
  updateCustomerForUser,
} from '../../irecommend-lib/actions/customerActions';
import { getUserIfNeeded } from '../../irecommend-lib/actions/userActions';
import CustomerSelectPage from '../../pages/admin/CustomerSelectPage';

class CustomerSelectContainer extends Component {
  constructor(props) {
    super(props);

    this.onPressSubmitForm = this.onPressSubmitForm.bind(this);

    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    this.props.getCustomersIfNeeded();
    this.props.getUserIfNeeded();

    if (
      this.props.user.response &&
      this.props.user.response.user &&
      this.props.user.response.user
    ) {
      this.setState({
        loading: false,
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // Check for errors on form submission
    if (
      this.props.customersPost !== nextProps.customersPost &&
      nextProps.customersPost.isLoading === false
    ) {
      if (this.props.customersPost.error) {
        alert(this.props.t('generic-error-default-message'));
        return;
      }

      this.props.history.push('/');
    }

    if (
      this.props.user !== nextProps.user &&
      nextProps.user.response &&
      nextProps.user.response.user
    ) {
      this.setState({
        loading: false,
      });
    }
  }

  render() {
    if (this.state.loading) return null;

    if (this.props.user.response.user.usertype !== 2) {
      return <Redirect to="/" />;
    }

    const customers =
      !this.props.customers.response || this.props.customers.response.constructor !== Array
        ? []
        : this.props.customers.response;
    // This container should render a page but will for brevity and test-purposes render DOM right away
    return (
      <AdminWrapper disableInnerWrap={true}>
        <CustomerSelectPage customers={customers} onPressSubmitForm={this.onPressSubmitForm} />
      </AdminWrapper>
    );
  }

  /**
   * On continuing
   */
  async onPressSubmitForm(data) {
    // Submit selection of team and redirect
    if (this.props.match.path === '/admin/customerselect') {
      this.props.updateCustomerForUser(data);
    }
  }
}

function mapStateToProps(state) {
  return {
    authState: state.authState,
    user: state.userState.user,
    customersPost: state.customerState.customersPost,
    customers: state.customerState.customers,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getCustomersIfNeeded: () => dispatch(getCustomersIfNeeded()),
    getUserIfNeeded: () => dispatch(getUserIfNeeded()),
    updateCustomerForUser: (customer) => dispatch(updateCustomerForUser(customer)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerSelectContainer);

import React, { Component } from 'react';
import { Color } from '../../config/styles';
import { css, StyleSheet } from 'aphrodite';
import Button from '../Button';
import ProfilePicture from '../ProfilePicture';

class CompanyCompetitionItem extends Component {
  render() {
    const { title, picture, points, t, id, onPressCompetitionDetails, onPressDelete, onPressEdit } =
      this.props;

    return (
      <li className={css(styles.competitionItem)}>
        <div className={css(styles.listItem)}>
          <div className={css(styles.listSection)}>
            <ProfilePicture name={title} picture={picture} style={styles.picture} />
          </div>
          <div className={css(styles.listSection)}>
            <h2 onClick={() => onPressCompetitionDetails(id)} className={css(styles.h2)}>
              {title}
            </h2>
          </div>
          <div className={css(styles.goalItemCounter, styles.listSection)}>
            <em className={css(styles.counter, styles.counterBlue)}>{points}</em>
            <span className={css(styles.listItemSubtitle)}>{t('competition-goals-points')}</span>
          </div>
          <div className={css(styles.listSection)}>
            <div className={css(styles.btnHolder)}>
              <Button type="secondary" title={t('generic-text-remove')} onPress={onPressDelete} />
            </div>
            <div className={css(styles.btnHolder)}>
              <Button type="secondary" title={t('generic-text-edit')} onPress={onPressEdit} />
            </div>
          </div>
        </div>
      </li>
    );
  }
}

export default CompanyCompetitionItem;

const styles = StyleSheet.create({
  listItem: {
    justifyContent: 'space-between',
    display: 'grid',
    gridTemplateColumns: '0.111fr 0.222fr 0.111fr 0.277fr',
  },
  competitionItem: {
    backgroundColor: Color.white,
    boxShadow: '0 5px 17px 0 rgba(0, 0, 0, .03)',
    margin: '0 0 1.5625rem 0',
    minHeight: '5.6875rem',
  },
  text: {
    flex: '0 1 calc(100% - 40px - .75em)',
  },
  listSection: {
    padding: '0 1em',
    alignSelf: 'center',
    justifySelf: 'center',

    ':nth-child(2)': {
      justifySelf: 'start',
    },

    ':first-child': {
      padding: '1em 1.5em',
      justifyContent: 'flex-start',
      display: 'block',
    },

    ':last-child': {
      width: '100%',
    },
  },
  title: {
    margin: '0 0 .2em',
    marginRight: '50px',
    fontSize: '1.3em',
    fontWeight: 'bold',
    color: Color.primary,
  },
  h2: {
    fontSize: '1.0625rem',
    fontWeight: '600',
    lineHeight: '1.352941176',
    cursor: 'pointer',
  },
  btnHolder: {
    display: 'inline-block',
    float: 'right',
    width: '41%',
    margin: '-0.5em 0.5em 0 0.5em',
  },
  sectionValue: {
    fontSize: '.95em',
    lineHeight: '1.6',
  },
  sectionTitle: {
    fontSize: '.85em',
    textAlign: 'start',
    lineHeight: '1.2',
    fontWeight: 'bold',
  },
  blue: {
    color: Color.primary,
  },
  endDate: {
    display: 'block',
    fontStyle: 'normal',
    fontWeight: 600,
    textAlign: 'center',
  },
  listItemSubtitle: {
    color: Color.dark,
    letterSpacing: '.045454545em',
    fontSize: '.6875rem',
    lineHeight: 2,
    textTransform: 'uppercase',
    fontWeight: 600,
    display: 'block',
    textAlign: 'center',
  },
  counter: {
    fontStyle: 'normal',
    fontWeight: 600,
    display: 'block',
  },
  counterBig: {
    fontSize: '1.0625rem',
    fontStyle: 'normal',
    fontWeight: 600,
  },
  counterBlue: {
    color: Color.primary,
  },
  counterGreen: {
    color: Color.success,
  },
  goalItemCounter: {
    flexDirection: 'column',
    textAlign: 'center',
  },
});

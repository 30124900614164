import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite';

import { Box, Checkbox } from '@talentech/components';
import { useDispatch, useSelector } from 'react-redux';

import { getCustomerCategories } from '../../irecommend-lib/actions/customerActions';
import { getPositionCategories } from '../../irecommend-lib/actions/positionActions';

import { sortCategories } from '../../helpers/utils';
import { checkIfCategorySelected } from '../../helpers/category';

const PositionCategoryList = (props) => {
  const { positionCategories } = useSelector((state) => state.positionState);
  const { customerCategories } = useSelector((state) => state.customerState);
  const dispatch = useDispatch();

  const { state, setState } = props;
  const { id } = useParams();

  const positionCategoriesArr = useMemo(
    () => (Array.isArray(positionCategories.response) && id ? positionCategories.response : []),
    [positionCategories.response, id],
  );
  const customerCategoriesArr = useMemo(
    () => (Array.isArray(customerCategories.response) ? customerCategories.response : []),
    [customerCategories.response],
  );
  const sortedCustomerCategories = sortCategories(customerCategoriesArr);

  useEffect(() => {
    if (id) dispatch(getPositionCategories(id));
    dispatch(getCustomerCategories());
  }, [id, dispatch]);

  useEffect(() => {
    if (positionCategoriesArr.length > 0)
      setState((prev) => ({
        ...prev,
        selectedCategories: positionCategoriesArr,
      }));
  }, [positionCategoriesArr, setState]);

  const handleSetFilter = (category) => {
    if (checkIfCategorySelected(state, category)) {
      setState((prev) => ({
        ...prev,
        selectedCategories: state.selectedCategories.filter(
          (item) => item.idcategories !== category.idcategories,
        ),
      }));
    } else {
      setState((prev) => ({
        ...prev,
        selectedCategories: [...state.selectedCategories, category],
      }));
    }
  };

  return (
    <Box className="row m-0 flex-column">
      {sortedCustomerCategories.map((category) => (
        <Box key={category.idcategories} className="d-flex">
          <Checkbox
            onChange={() => handleSetFilter(category)}
            id={`checkbox-${category.idcategories}`}
            checked={checkIfCategorySelected(state, category)}
          />
          <label className={css(styles.label)} htmlFor={`checkbox-${category.idcategories}`}>
            {category.name}
          </label>
        </Box>
      ))}
    </Box>
  );
};

PositionCategoryList.propTypes = {
  /**
   * State handler
   */
  state: PropTypes.object.isRequired,
  /**
   * Customer categories handler
   */
  customerCategories: PropTypes.object.isRequired,
};

export default PositionCategoryList;

const styles = StyleSheet.create({
  label: {
    marginBottom: '1rem',
  },
});

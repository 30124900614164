import React from 'react';
import PropTypes from 'prop-types';

import { StyleSheet } from 'aphrodite';
import { Icon } from '@talentech/components';
import { useHistory } from 'react-router';

import { TALENTECH_ICONS } from '../../components/Icons';
import Card from './Card';
import { useTranslation } from 'react-i18next';

const CardItem = (props) => {
  const { position, recommendation, onDelete, onPush, onArchive } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const isArchived = position.archived;
  const hired = recommendation.filter((recommendation) => recommendation.state === 130).length;
  const recommendationsText = t('generic-text-recommendations');
  const hiredText = t('positionpage-hires');

  const dropdownMenu = [
    {
      icon: <Icon iconSize="small" icon={TALENTECH_ICONS.editPencil} />,
      label: t('generic-text-edit'),
      onClick: (e) => {
        e.stopPropagation();
        e.preventDefault();
        onEditPress(position.idpositions);
      },
    },
    {
      icon: <Icon iconSize="small" icon={TALENTECH_ICONS.arrowUp} />,
      label: t('generic-text-push-again'),
      onClick: (e) => {
        e.stopPropagation();
        e.preventDefault();
        onPush(position.idpositions, position.push);
      },
    },
    {
      icon: <Icon iconSize="small" icon={TALENTECH_ICONS.removeCircle} />,
      label: t('positionpage-unpublish'),
      onClick: (e) => {
        e.stopPropagation();
        e.preventDefault();
        onArchive(position.idpositions, position.archived);
      },
    },
    {
      icon: <Icon iconSize="small" icon={TALENTECH_ICONS.delete} />,
      label: t('settings-category-delete'),
      onClick: (e) => {
        e.stopPropagation();
        onDelete(position.idpositions);
      },
    },
  ];

  if (isArchived) {
    delete dropdownMenu[1];
    delete dropdownMenu[2];
  }

  const onEditPress = (positionId) => history.push(`/admin/jobs/edit/${positionId}`);

  return (
    <Card
      navigateTo={`/admin/jobs/position/${position.idpositions}`}
      actions={dropdownMenu}
      title={position.title}
      subtitle={position.contactname}
      badgeColor={isArchived ? 'danger' : 'success'}
      badgeLabel={
        isArchived ? t('positionpage-card-unpublished') : t('positionpage-card-published')
      }
      badgeSize="md"
      showStats={true}
      recTitle={recommendationsText}
      hiredTitle={hiredText}
      hiredNumber={hired}
      recNumber={position.recommendations}
      style={styles.firstParentCardStyle}
    />
  );
};

CardItem.propTypes = {
  position: PropTypes.object,
};

export default CardItem;

const styles = StyleSheet.create({
  archived: {
    width: '17px',
  },
  firstParentCardStyle: {
    minHeight: '214px',
  },
});

import React, { Component } from 'react';
import { connect } from 'react-redux';

import TopnavLinks from './Topnav/TopnavLinks';
import ClientContent from './ClientContent';

import {
  fetchNumbers,
  fetchUserScore,
  fetchTeamScore,
  fetchPositions,
  fetchGoals,
  fetchIdvBar,
  fetchTips,
  fetchWeek,
  fetchTime,
} from '../actions';
import { getCredentials } from '../../api';

class ClientMain extends Component {
  // create nested navigation
  state = {
    route: 'home',
  };
  //trigger all the fetches at login to fill the redux store.
  componentDidMount() {
    getCredentials().then(({ accessToken }) => {
      //pass the accessToken to the action creators
      this.props.fetchNumbers(accessToken);
      this.props.fetchTeamScore(accessToken);
      this.props.fetchUserScore(accessToken);
      this.props.fetchWeek(accessToken);
      this.props.fetchGoals(accessToken);
      this.props.fetchTime(accessToken);
      // TODO: connect to headagents api
      this.props.fetchPositions(accessToken);
      this.props.fetchIdvBar(accessToken);
      this.props.fetchTips(accessToken);
    });
  }

  onRouteChange = (route) => this.setState({ route });

  render() {
    const { route } = this.state;
    return (
      <React.Fragment>
        <div className="client-main">
          <div>
            <nav aria-label="Navbar">
              <TopnavLinks onRouteChange={this.onRouteChange} route={route} />
            </nav>
            <main aria-label="Main content">
              <ClientContent route={route} lineChartDate={this.props.time} />
            </main>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    time: state.BIDashboardState.time,
  };
}

const mapDispatchToProps = {
  fetchNumbers,
  fetchUserScore,
  fetchTeamScore,
  fetchPositions,
  fetchGoals,
  fetchIdvBar,
  fetchTips,
  fetchWeek,
  fetchTime,
};

// export default connect(mapDispatchToProps, mapStateToProps)(ClientMain);

export default connect(mapStateToProps, mapDispatchToProps)(ClientMain);

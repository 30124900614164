import { Box, Button, Modal } from '@talentech/components';
import React from 'react';

const Title = ({ title }) => {
  return (
    <Box className="d-flex justify-space-between align-items.center w-100">
      <span>{title}</span>
    </Box>
  );
};

export const ModalForm = (props) => {
  const {
    showModalForm,
    title,
    subtitle,
    content,
    handleCancel,
    labelCancel,
    handleSubmit,
    labelSubmit,
  } = props;

  return (
    <Modal
      showModal={showModalForm}
      size="xl"
      title={<Title title={title} />}
      subtitle={subtitle}
      buttons={[
        labelCancel && (
          <Button
            key={labelCancel}
            color="outline-primary"
            onClick={handleCancel}
            label={labelCancel}
          />
        ),
        labelSubmit && (
          <Button key={labelSubmit} color="primary" onClick={handleSubmit} label={labelSubmit} />
        ),
      ]}
    >
      {content}
    </Modal>
  );
};

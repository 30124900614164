import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite';
import { Color } from '../../config/styles';

import AdminWrapper from '../../components/admin/AdminWrapper';
import TeamItem from '../../components/admin/TeamItem';
import Loader from '../../components/Loader';
import Modal from '../../components/Modal';
import Notification from '../../components/Notification';
import PageHeader from '../../components/admin/PageHeader';
import { TALENTECH_ICONS } from '../../components/Icons';
import { withTranslation } from 'react-i18next';

class TeamPage extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    teams: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      showNotification: false,
      deleteID: '',
      teamName: 'namn',
    };
  }

  createPageHeaderButtons = () => {
    const { t, onPressCreateTeam } = this.props;
    return [
      {
        label: t('generic-text-new-team'),
        icon: TALENTECH_ICONS.plusCircled,
        onclick: () => onPressCreateTeam(),
      },
    ];
  };

  createBreadcrumb = () => {
    const { t, navigateToAdmin } = this.props;
    return [
      {
        label: t('admin-header-dashboard'),
        onClick: () => navigateToAdmin(),
      },
      {
        label: t('admin-header-teams'),
        isActive: true,
      },
    ];
  };

  render() {
    const { t, isLoading, teams, onPressGoToEdit } = this.props;
    const teamItem = teams.map((item) => {
      return (
        <TeamItem
          key={item.idteam}
          id={item.idteam}
          name={item.name}
          picture={item.picture === 'No picture' ? '' : item.picture}
          created={item.created}
          href={`/admin/teams/edit/${item.idteam}`}
          meta=""
          onPressDeleteItem={this.onPressDeleteItem}
          onPressGoToEdit={onPressGoToEdit}
        />
      );
    });

    return (
      <Loader isLoading={isLoading}>
        <PageHeader
          title={t('admin-header-teams')}
          buttons={this.createPageHeaderButtons()}
          breadcrumbs={this.createBreadcrumb()}
        />
        <AdminWrapper>
          {this.state.showNotification && (
            <Notification title="Teamet har tagits bort" type="success" />
          )}

          {this.state.showModal && (
            <Modal
              title={`${t('generic-text-remove')} ${this.state.teamName}?`}
              description={t('generic-text-remove-team-description')}
              type="remove"
              confirmCallback={(e) => {
                this.props.onPressConfirmDelete(this.state.deleteID);
                this.onPressHideModal(e);
              }}
              cancelCallback={this.onPressHideModal}
            />
          )}

          <div className={css(styles.teamContainer)}>{teamItem}</div>
        </AdminWrapper>
      </Loader>
    );
  }

  onPressDeleteItem = (e, id, name) => {
    e.preventDefault();

    this.setState({
      deleteID: id,
      showModal: true,
      teamName: name,
    });
  };

  onPressHideModal = (e) => {
    e.preventDefault();

    this.setState({
      showModal: false,
    });
  };
}

export default withTranslation()(TeamPage);

const styles = StyleSheet.create({
  header: {
    textAlign: 'center',
    marginBottom: '2.5em',
    display: 'flex',
    alignItems: 'center',
  },
  header__emphasize: {
    fontWeight: 'bold',
  },
  cleanActions: {
    backgroundColor: Color.primary,
    color: '#fff',
    appearance: 'none',
    padding: '1em 2em',
    marginLeft: 'auto',
    borderRadius: '0',
    transition: 'color .2s ease-in-out',
    fontSize: '17px',
    textAlign: 'center',
    fontWeight: 'bold',
    textDecoration: 'none',
  },
  teamContainer: {
    margin: '0 auto',
  },
});

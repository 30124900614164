import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';

class TopLinks extends Component {
  render() {
    const { onRouteChange, route } = this.props;
    return (
      <div className="topnav">
        <ul className="topnav-links">
          <li className={route === 'home' ? 'active-link' : ''}>
            <button
              style={{
                borderRadius: 3,
                padding: 5,
                border: 'none',
                backgroundColor: '#f7f8f8',
              }}
              onClick={() => onRouteChange('home')}
              className={route === 'home' ? 'active-icone' : ''}
              aria-label="Home"
            >
              <FontAwesome className="fontawesome" name="home" />
            </button>
          </li>
          <li className={route === 'numbers' ? 'active-link' : ''}>
            <button
              style={{
                borderRadius: 3,
                padding: 5,
                border: 'none',
                backgroundColor: '#f7f8f8',
              }}
              onClick={() => onRouteChange('numbers')}
              className={route === 'numbers' ? 'active-link' : ''}
              aria-label="Numbers"
            >
              <FontAwesome className="fontawesome" name="list-ol" />
            </button>
          </li>
          <li className={route === 'highscores' ? 'active-link' : ''}>
            <button
              style={{
                borderRadius: 3,
                padding: 5,
                border: 'none',
                backgroundColor: '#f7f8f8',
              }}
              onClick={() => onRouteChange('highscores')}
              className={route === 'highscores' ? 'active-link' : ''}
              aria-label="Highscores"
            >
              <FontAwesome className="fontawesome" name="trophy" />
            </button>
          </li>
          <li className={route === 'time' ? 'active-link' : ''}>
            <button
              style={{
                borderRadius: 3,
                padding: 5,
                border: 'none',
                backgroundColor: '#f7f8f8',
              }}
              onClick={() => onRouteChange('time')}
              className={route === 'time' ? 'active-link' : ''}
              aria-label="Diagram"
            >
              <FontAwesome className="fontawesome" name="clock" />
            </button>
          </li>
        </ul>
      </div>
    );
  }
}

export default TopLinks;

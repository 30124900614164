import React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Link } from 'react-router-dom';
import { Color } from '../../irecommend-lib/styles';

export default function GetStartedLink(props) {
  return (
    <div className={css(styles.linkContainer)}>
      <Link
        className={css(styles.link)}
        to={props.to}
        target="_blank"
        onClick={(e) => {
          e.preventDefault();
          window.open(props.to);
        }}
      >
        {props.children}
      </Link>
    </div>
  );
}

const styles = StyleSheet.create({
  linkContainer: {
    marginBottom: '1em',
  },
  link: {
    fontSize: '1.175em',
    color: Color.primary,
  },
});

import { Avatar, TalentechAvatarSizes } from '@talentech/components';

export const sidebarFallbackApps = [
  {
    type: 'appgroup',
    key: 'MY_APPS',
    localization: {
      da: 'Mine apps',
      en: 'My apps',
      fi: 'Minun sovellukseni',
      nb: 'Mine apper',
      sv: 'Mina appar',
    },
    apps: [
      {
        id: process.env.REACT_APP_TALENTECH_IRECOMMEND_ID,
        type: 'app',
        name: 'irecommend',
        prefix: null,
        urlTarget: 'SAME_PAGE',
        url: 'https://referrals.talentech.io',
        appIconUrl: 'https://storybook.talentech.io/images/svg/products/light/irecommend.svg',
      },
    ],
  },
  {
    type: 'appgroup',
    key: 'MORE_APPS',
    localization: {
      da: 'Flere apps og integrationer',
      en: 'More apps & integrations',
      fi: 'Lisää sovelluksia & integraatioita',
      nb: 'Flere apper & integrasjoner',
      sv: 'Fler appar & integrationer',
    },
    apps: [
      {
        id: process.env.REACT_APP_TALENTECH_MARKETPLACE_ID,
        type: 'app',
        name: 'Marketplace',
        prefix: null,
        urlTarget: 'SAME_PAGE',
        url: 'https://marketplace.talentech.com/bff/login',
        appIconUrl: 'https://storybook.talentech.io/images/svg/products/light/talentech.svg',
      },
    ],
  },
];

export const sidebarMenuLinks = [
  {
    menuItems: [
      {
        icon: 't-icon regular-01_interface_essentials-share-megaphone_1',
        label: 'tab-home',
        link: '/',
      },
      {
        icon: 't-icon regular-06_business_products-business-user_network',
        label: 'tab-recommendations',
        link: '/recommendations',
      },
      {
        icon: 't-icon regular-22_social_medias_rewards_ratings-ranking-ranking_winner_ribbon',
        label: 'tab-highscore',
        link: '/highscore',
      },
      {
        icon: 't-icon regular-17_users-geometric_close_up_single_user_actions_neutral-single_neutral_actions_edit_1',
        label: 'tab-profile',
        link: '/profile',
        userType: 'regular',
      },
      {
        icon: 't-icon regular-01_interface_essentials-home-house_1',
        label: 'admin-header-dashboard',
        link: '/admin',
      },

      {
        icon: 't-icon regular-17_users-geomertric_close_up_multiple_users-multiple_neutral_1',
        label: 'admin-header-invites',
        link: '/admin/invites',
      },
      {
        icon: 't-icon regular-17_users-geomertric_close_up_multiple_users-multiple_users_2',
        label: 'admin-header-team',
        link: '/admin/teams',
      },
      {
        icon: 't-icon regular-22_social_medias_rewards_ratings-gifts-gift_box',
        label: 'admin-header-competitions',
        link: '/admin/competitions',
      },
      {
        icon: 't-icon regular-19_emails-email_actions-email_action_share',
        label: 'admin-header-communication',
        link: '/admin/communication',
      },
      {
        icon: 't-icon regular-01_interface_essentials-setting-cog_double_2',
        label: 'admin-header-bi-dashboard',
        link: '/admin/bi-dashboard',
      },
      {
        icon: 't-icon regular-28_lamps_lights_fire-light_bubbles-light_bulb_1',
        label: 'admin-header-FAQ',
        link: '/admin/faq',
      },
      {
        icon: 't-icon regular-01_interface_essentials-setting-cog',
        label: 'admin-header-settings',
        link: '/admin/settings',
      },
    ],
  },
];

export const fetchUserMenu = (menuItems) => {
  return menuItems.map((item) => ({
    advancedProps: {
      contentImage: <Avatar outline size={`${TalentechAvatarSizes.LG}`} icon={item.icon} />,
      subtitle: item.subtitle,
    },
    title: item.title,
    submenu: item.submenu,
    onClick: item.onClick,
  }));
};

import { FETCH_NUMBERS } from '../actions/index';

const reducerNumbers = (state = [], action = { payload: { data: [] } }) => {
  switch (action.type) {
    case FETCH_NUMBERS:
      const { data } = action.payload;
      const totalRec = data !== '' ? data.filter((state) => state.state >= 10).length : 0;
      const interestedRec = data !== '' ? data.filter((state) => state.state >= 50).length : 0;
      const interviewedRec = data !== '' ? data.filter((state) => state.state >= 100).length : 0;
      const employed = data !== '' ? data.filter((state) => state.state >= 130).length : 0;

      let numberArr = [
        //this feels quite hardcoded
        {
          title: 'number-widget-total-tips',
          desc: 'number-widget-total-tips-desc',
          data: totalRec,
          stylingclass: 'number-balls greenballs',
        },
        {
          title: 'number-widget-total-interested',
          desc: 'number-widget-total-interested-desc',
          data: interestedRec,
          stylingclass: 'number-balls greenballs',
        },
        {
          title: 'number-widget-total-interviewed',
          desc: 'number-widget-total-interviewed-desc',
          data: interviewedRec,
          stylingclass: 'number-balls greenballs',
        },
        {
          title: 'number-widget-total-employed',
          desc: 'number-widget-total-employed-desc',
          data: employed,
          stylingclass: 'number-balls greenballs',
        },
        //We send total recommendations and then divide in NumberWidgetItem.js
        {
          title: 'number-widget-total-tips-per-person',
          desc: 'number-widget-total-tips-per-person-desc',
          data: totalRec,
          stylingclass: 'number-balls grayball',
        },
      ];

      return numberArr;

    default:
      return state;
  }
};

export default reducerNumbers;

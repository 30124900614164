import React, { PureComponent } from 'react';
import { css, StyleSheet } from 'aphrodite';
import PropTypes from 'prop-types';

import { Color, Font } from '../config/styles';

/**
 * Custom FlatList item for general purpose.
 *
 * Example usage:
 *
 * ```
 * <ListItem
 *   id=1
 *   onPress={onPressLearnMore}
 *   title="Learn More"
 * />
 * ```
 *
 */
export default class ListItem extends PureComponent {
  static propTypes = {
    /**
     * Unique identifier for item
     */
    id: PropTypes.number.isRequired,
    /**
     * Handler to be called when the user taps the button
     */
    onPress: PropTypes.func.isRequired,
    /**
     * Text to display inside the button
     */
    title: PropTypes.string.isRequired,
  };

  render() {
    const { id, title } = this.props;

    return (
      <a
        href="#list-item"
        role="button"
        id={id}
        onClick={this._onPressItem}
        className={css(styles.wrap)}
      >
        <span className={css(styles.title)}>{title}</span>
      </a>
    );
  }

  _onPressItem = (e) => {
    e.preventDefault();
    this.props.onPress(this.props.id);
  };
}

const styles = StyleSheet.create({
  title: {
    color: Color.dark2,
    fontFamily: Font.defaultFont,
    fontSize: 19,
    marginBottom: 4,
  },
  wrap: {
    display: 'block',
    paddingTop: 20,
    paddingRight: 25,
    paddingBottom: 20,
    paddingLeft: 25,
    textDecoration: 'none',
  },
});

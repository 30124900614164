import React, { Component } from 'react';
import { css, StyleSheet } from 'aphrodite';

import { Color } from '../../config/styles';
import CommentField from './CommentField';
import CommentsList from './CommentsList';
import { connect } from 'react-redux';
import {
  getComments,
  deleteComment,
  postComment,
} from '../../irecommend-lib/actions/commentsActions';
import { withTranslation } from 'react-i18next';

class CommentsDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newComment: '',
    };
  }

  componentDidMount() {
    this.props.getComments(this.props.recommendation.idrecommendations);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // Reloads or returns error when a comment has been deleted.
    if (
      this.props.commentDelete !== nextProps.commentDelete &&
      nextProps.commentDelete.isLoading === false
    ) {
      if (this.props.commentDelete.error) {
        alert(this.props.t('generic-error-default-message'));
        return;
      }
      this.props.getComments(this.props.recommendation.idrecommendations);
    }

    // Gets new comments or returns error when new comment is posted.
    if (
      this.props.commentPost !== nextProps.commentPost &&
      nextProps.commentPost.isLoading === false
    ) {
      if (this.props.commentPost.error) {
        alert(this.props.t('generic-error-default-message'));
        return;
      }
      this.props.getComments(this.props.recommendation.idrecommendations);
    }
  }

  render() {
    const { t, showComments, onPressToggleComments } = this.props;

    const comments = this.props.comments.response !== null ? this.props.comments.response : [];

    const isLoading = this.props.comments.isLoading;

    return (
      <div className={css(styles.candidateDropdownWrap, showComments && styles.show)}>
        <div onClick={onPressToggleComments} className={css(styles.candidateDropdownBackground)} />

        <div className={css(styles.candidateDropdown)}>
          <CommentsList
            comments={comments}
            isLoading={isLoading}
            onDeleteConfirm={this.onPressConfirmDeleteComment}
            t={t}
          />

          <CommentField
            handlerOnChange={this.handlerOnChange}
            newComment={this.state.newComment}
            handleSubmit={this.handleSubmit}
          />
        </div>
      </div>
    );
  }

  handlerOnChange = (event) => {
    this.setState({
      newComment: event.target.value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    // Check so that the comment field is no empty
    if (this.state.newComment !== '') {
      this.props.postComment(
        { Comment: this.state.newComment },
        this.props.recommendation.idrecommendations,
      );
      this.setState({
        newComment: '',
      });
    }
  };

  onPressConfirmDeleteComment = (id) => {
    this.props.deleteComment(id);
  };
}

function mapStateToProps(state) {
  return {
    comments: state.commentsState.comments,
    commentDelete: state.commentsState.commentDelete,
    commentPost: state.commentsState.commentPost,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getComments: (id) => dispatch(getComments(id)),
    postComment: (data, id) => dispatch(postComment(data, id)),
    deleteComment: (id) => dispatch(deleteComment(id)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CommentsDropdown));

const styles = StyleSheet.create({
  candidateDropdownWrap: {
    display: 'none',
  },

  show: {
    display: 'block',
  },

  ul: {
    paddingLeft: 0,
    marginTop: '10px',
    marginBottom: '20px',
  },

  candidateDropdownBackground: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, .05)',
    cursor: 'default',
    zIndex: 10,
  },

  candidateDropdown: {
    borderRadius: '2px',
    boxShadow: '0 3px 17px 0 rgba(0, 0, 0, .05)',
    backgroundColor: Color.white,
    position: 'absolute',
    top: '1.75em',
    zIndex: '10',
    minWidth: '40em',
    maxWidth: '60em',
    padding: '1.25em',
    // listStyle: 'none',
    margin: '0',
  },
});

import { CALL_API } from '../middleware/api';

export const RESET_INDIVIDUALPOINTS_REQUEST = 'RESET_INDIVIDUALPOINTS_REQUEST';
export const RESET_INDIVIDUALPOINTS_SUCCESS = 'RESET_INDIVIDUALPOINTS_SUCCESS';
export const RESET_INDIVIDUALPOINTS_FAILURE = 'RESET_INDIVIDUALPOINTS_FAILURE';
/**
 * reset Individual Points
 */
export function resetIndividualPoints() {
  const endpoint = `/customers/reset/individual`;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      authenticated: true,
      types: [
        RESET_INDIVIDUALPOINTS_REQUEST,
        RESET_INDIVIDUALPOINTS_SUCCESS,
        RESET_INDIVIDUALPOINTS_FAILURE,
      ],
      method: 'POST',
    },
  };
}

export const RESET_TEAMPOINTS_REQUEST = 'RESET_TEAMPOINTS_REQUEST';
export const RESET_TEAMPOINTS_SUCCESS = 'RESET_TEAMPOINTS_SUCCESS';
export const RESET_TEAMPOINTS_FAILURE = 'RESET_TEAMPOINTS_FAILURE';
/**
 * resets Team Points
 */
export function resetTeamPoints() {
  const endpoint = `/customers/reset/team`;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      authenticated: true,
      types: [RESET_TEAMPOINTS_REQUEST, RESET_TEAMPOINTS_SUCCESS, RESET_TEAMPOINTS_FAILURE],
      method: 'POST',
    },
  };
}

export const RESET_COMPANYPOINTS_REQUEST = 'RESET_COMPANYPOINTS_REQUEST';
export const RESET_COMPANYPOINTS_SUCCESS = 'RESET_COMPANYPOINTS_SUCCESS';
export const RESET_COMPANYPOINTS_FAILURE = 'RESET_COMPANYPOINTS_FAILURE';
/**
 * resets Company Points
 */
export function resetCompanyPoints() {
  const endpoint = `/customers/reset/company`;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      authenticated: true,
      types: [
        RESET_COMPANYPOINTS_REQUEST,
        RESET_COMPANYPOINTS_SUCCESS,
        RESET_COMPANYPOINTS_FAILURE,
      ],
      method: 'POST',
    },
  };
}

import React, { Component } from 'react';
import RecommendedPage from '../pages/RecommendedPage';
import NotFoundContainer from '../containers/NotFoundContainer';
import { connect } from 'react-redux';
import {
  getRecommendation,
  postRecommendationInterest,
} from '../irecommend-lib/actions/recommendationActions';

class RecommendedContainer extends Component {
  state = {
    positionReadAll: false,
  };

  componentDidMount() {
    if (!this.props.recommendation.response.position && this.props.match.params.id) {
      this.props.getRecommendation(this.props.match.params.id);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.recommendationInterest !== nextProps.recommendationInterest &&
      nextProps.recommendationInterest.request &&
      nextProps.recommendationInterest.request.status === 200
    ) {
      if (this.getRequestType(nextProps.recommendationInterest.request.request.responseURL) === 2) {
        // Not interested
        this.props.history.push(`/recommended/${this.props.match.params.id}/not-interested`);
      } else if (
        this.getRequestType(nextProps.recommendationInterest.request.request.responseURL) === 3
      ) {
        const url = this.props.recommendation.response.companypage;

        // Read more success

        window.location = url;
      }
    } else if (
      this.props.recommendation !== nextProps.recommendation &&
      nextProps.recommendation.isLoading === false
    ) {
    }
  }

  /**
   * Get recommendation status from request URL
   */
  getRequestType = (url) => {
    return parseInt(url.match(/interested=(\d+?)$/)[1], 10);
  };

  render() {
    const { recommendation, recommendationInterest } = this.props;
    const isPositionArchived = recommendation.response.position?.archived;

    const readAll = this.props.match.url.indexOf('readall') !== -1;

    if (
      (recommendation.error === true && recommendation.request.status === 500) ||
      (recommendationInterest.error === true && recommendationInterest.request.status === 500)
    ) {
      this.props.history.push(`/404`);
      return <NotFoundContainer />;
    }

    return (
      <RecommendedPage
        isLoading={recommendation.isLoading || recommendationInterest.isLoading}
        onNotInterested={this._onNotInterested}
        onPressContinue={this._onPressContinue}
        onReadAll={this._onReadAll}
        onReadMore={this._onReadMore}
        onRecommendOther={this._onRecommendOther}
        readAllPosition={readAll}
        recommendation={recommendation.response}
        isPositionArchived={isPositionArchived}
      />
    );
  }

  /**
   * Not interested
   */
  _onNotInterested = () => {
    this.props.postRecommendationInterest(this.props.match.params.id, 2);
  };

  /**
   * Want to be contacted
   */
  _onPressContinue = () => {
    this.props.history.push(
      `/recommended/${this.props.match.params.id}/interested` /*, {data: this.props.recommendationState.response.referral}*/,
    );
  };

  /**
   * Read all about the position
   */
  _onReadAll = () => {
    this.props.history.push(`/recommended/${this.props.match.params.id}/readall`);
  };

  /**
   * Read more about other positions
   */
  _onReadMore = () => {
    // this.props.postRecommendationInterest(this.props.match.params.id, 3)
    this.props.postRecommendationInterest(this.props.match.params.id, 3);
  };

  /**
   * Want to recommend someone else
   */
  _onRecommendOther = () => {
    this.props.postRecommendationInterest(this.props.match.params.id, 4);
    this.props.history.push(`/recommended/${this.props.match.params.id}/externalreferral`);
  };
}

function mapStateToProps(state) {
  return {
    recommendationInterest: state.recommendationState.recommendationInterest,
    recommendation: state.recommendationState.recommendation,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    // Inte anonym test
    getRecommendation: (recommendation) => dispatch(getRecommendation(recommendation)),
    // Anonym test
    // getRecommendation:() => dispatch(getRecommendation('bed8bda5-368e-437f-91d4-86b1572f0582'))
    postRecommendationInterest: (id, interested) =>
      dispatch(postRecommendationInterest(id, interested)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RecommendedContainer);

import { useEffect, useState } from 'react';
import { TAB_NAMES } from '../../helpers/position';

const useSetSelectedTab = (tabName) => {
  const [selectedTab, setSelectedTab] = useState(tabName);

  useEffect(() => {
    document.addEventListener('click', handleTabClicks);

    return () => {
      document.removeEventListener('click', handleTabClicks);
    };
  }, []);

  const handleTabClicks = (e) => {
    if (e.target.id === '1') setSelectedTab(TAB_NAMES.positions);
    else if (e.target.id === '2') setSelectedTab(TAB_NAMES.external);
  };

  return { selectedTab, handleTabClicks };
};

export default useSetSelectedTab;

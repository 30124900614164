import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { css, StyleSheet } from 'aphrodite';

import { IconX, IconEye, IconEyeStroked, IconCheckmark } from '../../components/Icons';
import JobsListNav from '../JobsListNav';
import { IntlNetwork } from '../../helpers/language';
import { Color } from '../../irecommend-lib/styles';
import { withTranslation } from 'react-i18next';

class ProgressBar extends Component {
  static propTypes = {
    position: PropTypes.object,
    recommendation: PropTypes.object.isRequired,
    onPressVerifyContact: PropTypes.func,
    onPressVerifyInterview: PropTypes.func,
    onPressVerifyHire: PropTypes.func,
    onPressFailedContact: PropTypes.func,
    onPressNotInterested: PropTypes.func,
    onPressFailedAfterInterview: PropTypes.func,
    onPressFailedInterview: PropTypes.func,
    onPressFailedHire: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      showTooltip: true,
      showTooltipContact: true,
      showTooltipInterview: true,
      showTooltipHire: true,

      showContactNav: false,
      showInterviewNav: false,
      showHireNav: false,
    };
  }

  render() {
    const {
      t,
      position,
      recommendation,
      onPressVerifyContact,
      onPressVerifyInterview,
      onPressVerifyHire,
      onPressFailedContact,
      onPressNotInterested,
      onPressFailedAfterInterview,
      onPressFailedInterview,
      onPressFailedHire,
    } = this.props;

    const { state } = recommendation;
    let stateClass = '';
    let stateClassContact = '';
    let stateClassInterview = '';
    let stateClassHire = '';

    switch (state) {
      case 5: // status-reset
        break;
      case 10: // status-sent
        break;
      case 20: // status-read
        stateClass = 'blue';
        break;
      case 30: // status-recommended-other
        stateClass = 'red';
        break;
      case 40: // status-not-interested
        stateClass = 'red';
        break;
      case 50: // status-interested
        stateClass = 'green';
        break;
      case 60: // status-not-interested-in-this-role
        stateClass = 'red';
        break;
      case 70: // status-contacted
        stateClass = 'green';
        stateClassContact = 'green';
        break;
      case 80: // status-not-relevant-after-contact
        stateClass = 'green';
        stateClassContact = 'red';
        break;
      case 90: // status-not-interested-after-contact
        stateClass = 'green';
        stateClassContact = 'red';
        break;
      case 100: // status-interview
        stateClass = 'green';
        stateClassContact = 'green';
        stateClassInterview = 'green';
        break;
      case 110: // status-not-interested-after-interview
        stateClass = 'green';
        stateClassContact = 'green';
        stateClassInterview = 'red';
        break;
      case 120: // status-not-relevant-after-interview
        stateClass = 'green';
        stateClassContact = 'green';
        stateClassInterview = 'red';
        break;
      case 130: // status-hired
        stateClass = 'green';
        stateClassContact = 'green';
        stateClassInterview = 'green';
        stateClassHire = 'green';
        break;
      default:
        break;
    }

    const showContactMenu =
      state === 10 || state === 20 || state === 50 || state === 40 ? true : false;
    const showInterviewMenu = state === 70 ? true : false;
    const showHireMenu = state === 100 || state === 120 ? true : false;

    return (
      <div className={css(styles.progress)}>
        <div className={css(styles.circle, styles[stateClass])}>
          <div
            className={css(styles.circleInner, styles[stateClass], styles.disabled)}
            onClick={(e) => {
              e.preventDefault();
            }}
            onMouseEnter={() => {
              this.setState({
                showTooltip: true,
              });
            }}
            onMouseLeave={() => {
              this.setState({
                showTooltip: false,
              });
            }}
          >
            {(state === 5 || state === 10) && <IconEyeStroked className="progress-icon big" />}

            {state === 20 && <IconEye className="progress-icon big" />}

            {state > 20 && state !== 30 && state !== 40 && state !== 60 && (
              <IconCheckmark className="progress-icon" />
            )}

            {(state === 30 || state === 40 || state === 60) && <IconX className="progress-icon" />}
          </div>
          {(state === 5 || state === 10) && (
            <span className={css(styles.tooltip)}>{t('status-not-read')}</span>
          )}

          {state === 20 && <span className={css(styles.tooltip)}>{t('status-read')}</span>}

          {(state === 50 || (state >= 50 && state !== 30 && state !== 60)) && (
            <span className={css(styles.tooltip)}>{t('status-interested')}</span>
          )}

          {state === 40 && (
            <span className={css(styles.tooltip)}>{t('status-not-interested')}</span>
          )}

          {state === 60 && (
            <span className={css(styles.tooltip)}>
              {IntlNetwork(t, 'status-not-interested-in-this-role')}
            </span>
          )}

          {state === 30 && (
            <span className={css(styles.tooltip)}>{t('status-recommended-other')}</span>
          )}
        </div>

        <div className={css(styles.circle, styles[stateClassContact])}>
          <button
            className={css(
              styles.circleInner,
              styles[stateClassContact],
              showContactMenu && styles.enabled,
            )}
            onClick={(e) => {
              e.preventDefault();
              this.setState({
                showContactNav: !this.state.showContactNav,
              });
            }}
            onMouseEnter={() => {
              this.setState({
                showTooltipContact: true,
              });
            }}
            onMouseLeave={() => {
              this.setState({
                showTooltipContact: false,
              });
            }}
          >
            {state > 60 && state !== 80 && state !== 90 && (
              <IconCheckmark className="progress-icon" />
            )}

            {(state === 80 || state === 90) && <IconX className="progress-icon" />}
          </button>

          {state > 60 && state !== 80 && state !== 90 && (
            <span className={css(styles.tooltip)}>{t('status-contacted')}</span>
          )}

          {state === 80 && (
            <span className={css(styles.tooltip)}>{t('status-not-relevant-after-contact')}</span>
          )}

          {state === 90 && (
            <span className={css(styles.tooltip)}>{t('status-not-interested-after-contact')}</span>
          )}

          {/* Show a label to help the user if the candidate hasn’t reached here yet */}
          {(state === 5 ||
            state === 10 ||
            state === 20 ||
            state === 30 ||
            state === 40 ||
            state === 50 ||
            state === 60) && (
            <span className={css(styles.tooltip, styles.tooltipInactive)}>
              {t('progressbar-status-contact')}
            </span>
          )}

          {showContactMenu && (
            <JobsListNav
              menu="candidateContact"
              position={position}
              recommendation={recommendation}
              showActionNav={this.state.showContactNav}
              onPressToggleNav={this.toggleNav}
              onPressVerifyContact={onPressVerifyContact}
              onPressFailedContact={onPressFailedContact}
              onPressNotInterested={onPressNotInterested}
            />
          )}
        </div>

        <div className={css(styles.circle, styles[stateClassInterview])}>
          <button
            className={css(
              styles.circleInner,
              styles[stateClassInterview],
              showInterviewMenu && styles.enabled,
            )}
            onClick={(e) => {
              e.preventDefault();
              this.setState({
                showInterviewNav: !this.state.showInterviewNav,
              });
            }}
            onMouseEnter={() => {
              this.setState({
                showTooltipInterview: true,
              });
            }}
            onMouseLeave={() => {
              this.setState({
                showTooltipInterview: false,
              });
            }}
          >
            {(state === 100 || state === 130) && <IconCheckmark className="progress-icon" />}

            {(state === 110 || state === 120) && <IconX className="progress-icon" />}
          </button>

          {(state === 100 || state === 130) && (
            <span className={css(styles.tooltip)}>{t('status-interviewed')}</span>
          )}

          {state === 110 && (
            <span className={css(styles.tooltip)}>
              {t('status-not-interested-after-interview')}
            </span>
          )}

          {state === 120 && (
            <span className={css(styles.tooltip)}>{t('status-not-relevant-after-interview')}</span>
          )}

          {/* Show a label to help the user if the candidate hasn’t reached here yet */}
          {(state === 5 ||
            state === 10 ||
            state === 20 ||
            state === 30 ||
            state === 40 ||
            state === 50 ||
            state === 60 ||
            state === 70 ||
            state === 80 ||
            state === 90) && (
            <span className={css(styles.tooltip, styles.tooltipInactive)}>
              {t('progressbar-status-interview')}
            </span>
          )}

          {showInterviewMenu && (
            <JobsListNav
              menu="candidateInterview"
              position={position}
              recommendation={recommendation}
              showActionNav={this.state.showInterviewNav}
              onPressToggleNav={this.toggleNav}
              onPressVerifyInterview={onPressVerifyInterview}
              onPressFailedAfterInterview={onPressFailedAfterInterview}
              onPressFailedInterview={onPressFailedInterview}
            />
          )}
        </div>

        <div className={css(styles.circle, styles[stateClassHire])}>
          <button
            className={css(
              styles.circleInner,
              styles[stateClassHire],
              showHireMenu && styles.enabled,
            )}
            onClick={(e) => {
              e.preventDefault();
              this.setState({
                showHireNav: !this.state.showHireNav,
              });
            }}
            onMouseEnter={() => {
              this.setState({
                showTooltipHire: true,
              });
            }}
            onMouseLeave={() => {
              this.setState({
                showTooltipHire: false,
              });
            }}
          >
            {state === 130 && <IconCheckmark className="progress-icon" />}
          </button>

          {state === 130 && (
            <span className={css(styles.tooltip)}>{IntlNetwork(t, 'status-hired')}</span>
          )}

          {/* Show a label to help the user if the candidate hasn’t reached here yet */}
          {(state === 5 ||
            state === 10 ||
            state === 20 ||
            state === 30 ||
            state === 40 ||
            state === 50 ||
            state === 60 ||
            state === 70 ||
            state === 80 ||
            state === 90 ||
            state === 100 ||
            state === 110 ||
            state === 120) && (
            <span className={css(styles.tooltip, styles.tooltipInactive)}>
              {t('progressbar-status-hire')}
            </span>
          )}

          {showHireMenu && (
            <JobsListNav
              menu="candidateHire"
              position={position}
              recommendation={recommendation}
              showActionNav={this.state.showHireNav}
              onPressToggleNav={this.toggleNav}
              onPressVerifyHire={onPressVerifyHire}
              onPressFailedHire={onPressFailedHire}
            />
          )}
        </div>
      </div>
    );
  }

  toggleNav = (e) => {
    e.preventDefault();
    this.setState({
      showContactNav: false,
      showInterviewNav: false,
      showHireNav: false,
    });
  };
}

export default withTranslation()(ProgressBar);

const styles = StyleSheet.create({
  progress: {
    display: 'flex',
    width: '100%',
    margin: '2.5em 2em 0 .5em',
    justifyContent: 'space-between',

    ':after': {
      content: '""',
      display: 'inline-block',
      height: '3px',
      backgroundColor: '#fff',
      width: '10%',
      position: 'absolute',
      right: '0',
      bottom: '3.135rem',
      marginTop: '-3px',
      zIndex: '2',
    },
  },
  tooltip: {
    position: 'absolute',
    bottom: '3.5em',
    textAlign: 'center',
    display: 'block',
    minWidth: '11em',
    padding: '0 .5em',
  },
  tooltipInactive: {
    color: '#999',
  },
  circle: {
    borderRadius: '50%',
    width: '2.75rem',
    height: '2.75rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#e3e3e3',
    position: 'relative',

    ':after': {
      content: '""',
      display: 'inline-block',
      height: '3px',
      backgroundColor: '#e3e3e3',
      width: '550%',
      position: 'absolute',
      left: '100%',
      top: '50%',
      marginTop: '-3px',
    },

    ':last-child:after': {
      backgroundColor: '#fff',
      width: '200%',
    },
  },
  circleInner: {
    borderRadius: '50%',
    color: '#fff',
    width: '2.75rem',
    height: '2.75rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#e3e3e3',
    zIndex: '3',
    cursor: 'default',
    outline: 'none',
    border: 0,
    transition: 'all .1s ease-in-out',
  },
  enabled: {
    cursor: 'pointer',
    outline: 'default',

    ':hover': {
      backgroundColor: '#ccc',
    },
  },
  green: {
    backgroundColor: Color.success,
    ':after': {
      backgroundColor: Color.success,
    },
  },
  red: {
    backgroundColor: Color.warning,
  },
  blue: {
    backgroundColor: Color.primary,
  },
});

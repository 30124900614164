import React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Variables } from '../config/styles';
import { Color } from '../config/styles';
import iconIrec from '../assets/icons/icon-irec2.png';

const Footer = (props) => (
  <div className={css(styles.container)}>
    <img src={iconIrec} className={css(styles.irecIcon)} alt="" />
    <a
      href="https://talentech.com/products/ats/referrals-software/"
      target="_blank"
      rel="noopener noreferrer"
      className={css(styles.link)}
    >
      {props.text}
    </a>
  </div>
);

let styles = StyleSheet.create({
  container: {
    height: Variables.FooterHeight,
    display: 'flex',
    alignItems: 'center',
    padding: '0 1.6rem',
    backgroundColor: Color.primary,
    justifyContent: 'center',
  },
  link: {
    color: 'rgba(255,255,255,0.5)',
  },
  text: {
    fontSize: '0.75em',
    color: 'red',
  },
  irecIcon: {
    width: '2em',
    margin: '1em',
  },
});

export default Footer;

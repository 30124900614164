import { useCallback, useEffect } from 'react';
import { Prompt } from 'react-router';
import { Accordion, Box, Button } from '@talentech/components';
import { EditorState, Modifier } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { StyleSheet, css } from 'aphrodite';

import CommunicationVariables from './CommunicationVariables';
import DraftForm from './DraftForm';

import { getContentState, isFieldChanged } from '../../helpers/communication';
import { useTranslation } from 'react-i18next';

const TabContentEditMail = (props) => {
  const { email, handleOnClickCancelEditMail, setFormState, emailFormState, handleOnSubmitMail } =
    props;
  const { t } = useTranslation();

  const setInitialData = useCallback(() => {
    if (email.template_html) {
      const contentState = getContentState(email.template_html);
      setFormState((prev) => ({
        ...prev,
        emailState: {
          ...prev.emailState,
          editorState: EditorState.createWithContent(contentState),
          initialState: {
            ...prev.emailState.initialState,
            template_HTML: email.template_html.replace(/  +/g, ' ').replace(/\n/g, ''),
            subject: email.subject,
          },
          currentState: {
            ...prev.emailState.currentState,
            template_HTML: email.template_html.replace(/  +/g, ' ').replace(/\n/g, ''),
            subject: email.subject,
          },
        },
      }));
    }
  }, [email, setFormState]);

  useEffect(() => {
    setInitialData();
  }, [setInitialData]);

  const handleOnChangeSubject = (e) => {
    const subject = e.target.value;
    const dataChanged = fieldChangeHandler(emailFormState.currentState);

    setFormState((prev) => ({
      ...prev,
      emailState: {
        ...prev.emailState,
        dataChanged,
        currentState: { ...prev.emailState.currentState, subject },
      },
    }));
  };

  // Handles changes to draft
  const onInputChangeFromDraft = (editorState) => {
    let html = stateToHTML(editorState.getCurrentContent());
    const htmlContent = html
      .replace(/&nbsp;/g, '')
      .replace(/\n/g, '')
      .replace(/  +/g, ' ')
      .replace(/ title="Talentech"/g, '') // Could not figure out where this comes from
      .replace(/<br>/g, '');
    const dataChanged = fieldChangeHandler({ emailFormState });
    const templateChanged = htmlContent !== emailFormState.initialState.template_HTML;

    setFormState((prev) => ({
      ...prev,
      emailState: {
        ...prev.emailState,
        dataChanged,
        templateChanged,
        editorState,
        currentState: { ...prev.emailState.currentState, template_HTML: htmlContent },
      },
    }));
  };

  // Handles inserting variables to draft
  const onInsertTextToDraft = (e) => {
    const text = e.target.parentElement.name;
    const selection = emailFormState.editorState.getSelection();
    const contentState = emailFormState.editorState.getCurrentContent();
    let ncs;
    if (selection.isCollapsed()) {
      ncs = Modifier.insertText(contentState, selection, text);
    } else {
      ncs = Modifier.replaceText(contentState, selection, text);
    }
    const es = EditorState.push(emailFormState.editorState, ncs, 'insert-fragment');

    let html = stateToHTML(es.getCurrentContent());
    const dataChanged = fieldChangeHandler({ html });
    setFormState((prev) => ({
      ...prev,
      emailState: {
        ...prev.emailState,
        dataChanged,
        editorState: es,
        currentState: { ...prev.currentState, template_HTML: html },
      },
    }));
  };

  const fieldChangeHandler = (newState) => {
    const fieldChanged = isFieldChanged(emailFormState, newState);
    return fieldChanged;
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();

    setFormState((prev) => ({
      ...prev,
      emailState: {
        ...prev.emailState,
        dataChanged: false,
      },
    }));

    let postData = emailFormState.currentState;

    postData.template_changed = emailFormState.templateChanged;
    postData.active = true;
    postData.template_id = email.template_id;
    postData.isOnDemand = email.on_demand;

    if (!email.on_demand) {
      postData.timestamp = email.timestamp;
      postData.interval = emailFormState.interval;
    }
    handleOnSubmitMail(postData);
  };

  const accordionItems = [
    {
      content: <CommunicationVariables onInsertTextToDraft={onInsertTextToDraft} />,
      id: 'optional-invite',
      title: t('communicationedit-variables'),
    },
  ];

  return (
    <>
      <Box className="row m-0">
        <Box className="col ps-4 pe-4">
          <div className={css(styles.tabContentHeader)}>
            {t('communication-item-channel-email')}
          </div>
        </Box>
      </Box>
      <Box className="row">
        <Box className="col ps-4 pe-4">
          <label className={css(styles.label)} htmlFor="text">
            {t('generic-text-subject').toUpperCase()}
          </label>
          <input
            type="text"
            maxLength="255"
            className="form-control"
            onChange={handleOnChangeSubject}
            value={emailFormState.currentState.subject}
            id="text"
          />
        </Box>
      </Box>
      <Box className="row">
        <Box className="col ps-4 pe-4">
          <DraftForm
            editorState={emailFormState.editorState}
            formLabel={t('communication-email-content')}
            change={onInputChangeFromDraft}
            readOnly={false}
            placeholder={t('interval-placeholder', { positions: '{positions}' })}
          />
        </Box>
      </Box>
      <Box className="row">
        <Box className="col ps-4 pe-4">
          <Accordion accordionItems={accordionItems} />
        </Box>
      </Box>
      <Box className="row">
        <Box className="col ps-4 pb-3">
          <Button
            className={css(styles.editButton)}
            label={t('generic-text-save')}
            color="primary"
            size="sm"
            onClick={handleOnSubmit}
          />
          <Button
            className={css(styles.cancelButton)}
            label={t('generic-button-cancel')}
            color="primary"
            size="sm"
            outline
            onClick={handleOnClickCancelEditMail}
          />
        </Box>
      </Box>
      <Prompt when={emailFormState.dataChanged} message={t('positioneditpage-prompt-message')} />
    </>
  );
};

export default TabContentEditMail;

const styles = StyleSheet.create({
  tabContentHeader: {
    fontWeight: 'bold',
    fontSize: '1.5rem',
    lineHeight: '1.5rem',
    margin: '1.5rem 0',
  },
  label: {
    marginBottom: '8px',
    fontWeight: 600,
    fontSize: '0.75rem',
  },
  cancelButton: {
    marginLeft: '0.5rem',
  },
});

import React, { Component } from 'react';
import { connect } from 'react-redux';

import CompetitionPage from '../../pages/admin/CompetitionPage';

import {
  resetIndividualPoints,
  resetTeamPoints,
  resetCompanyPoints,
} from '../../irecommend-lib/actions/pointsActions';
import {
  deleteCompetition,
  deleteCompetitionFromState,
  getCompetitions,
} from '../../irecommend-lib/actions/competitionActions';
import { getCustomer } from '../../irecommend-lib/actions/communicationActions';
import { getUser, getUserTenants } from '../../irecommend-lib/actions/userActions';
import { getUserflowSignatures } from '../../irecommend-lib/actions/userflowActions';

class CompetitionContainer extends Component {
  componentDidMount() {
    this.props.getCompetitions();
    this.props.getUser();
    this.props.getUserTenants();
    this.props.getCustomer();
    this.props.getUserflowSignatures();
    // TODO: ACTIVATE
    // this.props.getIntervals();
  }

  render() {
    const { response } = this.props.customer;
    const { t } = this.props;
    const compResp = this.props.competitions.response;
    const competitions = !compResp || compResp.constructor !== Array ? [] : compResp;
    const isLoading = this.props.competitions.isLoading || this.props.competitionDelete.isLoading;
    const isError =
      this.props.points.piontsIndividual.error ||
      this.props.points.piontsTeam.error ||
      this.props.competitions.error ||
      this.props.competitionDelete.error;
    const customer = response.customer ? response.customer : {};
    const user = this.props.user.response;
    const userTenants = this.props.userTenants.response;
    const userflowSignatures = this.props.userflowSignatures.response;

    if (isError) {
      alert(t('expire-invite-failure'));
      // Reset errors
      this.props.points.piontsIndividual.error =
        this.props.points.piontsTeam.error =
        this.props.competitions.error =
        this.props.competitionDelete.error =
          false;
    }

    if (this.props.competitionOnDemands.error) {
      alert(t('competition-interval-delete-failure'));
      this.props.competitionOnDemands.error = false;
    }

    return (
      <div>
        <CompetitionPage
          user={user}
          userTenants={userTenants}
          customer={customer}
          t={t}
          isError={isError}
          isLoading={isLoading}
          competitions={competitions}
          onPressConfirmDelete={this.onPressConfirmDelete}
          onPressConfirmResetPoints={this.onPressConfirmResetPoints}
          onPressEdit={this.onPressEdit}
          onPressAddCompetition={this.onPressAddCompetition}
          onPressCompetitionDetails={this.onPressCompetitionDetails}
          navigateToAdmin={this.navigateToAdmin}
          userflowSignatures={userflowSignatures}
        />
      </div>
    );
  }

  onPressAddCompetition = () => {
    this.props.history.push(`/admin/competitions/create`);
  };

  onPressConfirmDelete = (idComp) => {
    // Delete competition from DB
    this.props.deleteCompetition(idComp);

    // Remove deleted competition from GUI view by removing it from state without needing new request to backend
    const competitions = this.props.competitions.response;

    for (let i = 0; i < competitions.length; i++) {
      if (competitions[i].idcompetitions === idComp) {
        competitions.splice(i, 1);
      }
    }

    this.props.deleteCompetitionFromState(competitions);
  };

  onPressConfirmResetPoints = (id) => {
    if (id === 1) {
      this.props.resetIndividualPoints();
    } else if (id === 2) {
      this.props.resetTeamPoints();
    } else if (id === 3) {
      this.props.resetCompanyPoints();
    }
  };

  onPressEdit = (idComp) => {
    this.props.history.push(`/admin/competitions/edit/${idComp}`);
  };

  onPressCompetitionDetails = (idComp) => {
    this.props.history.push(`/admin/competitions/${idComp}`);
  };

  navigateToAdmin = () => {
    this.props.history.push(`/admin`);
  };
}

function mapStateToProps(state) {
  return {
    competitions: state.competitionState.competitions,
    competitionDelete: state.competitionState.competitionDelete,
    competitionOnDemands: state.communicationState.competitionOnDemands,
    points: state.pointsState,
    user: state.userState.user,
    customer: state.communicationState.customer,
    userflowSignatures: state.userflowState.userflowSignatures,
    userTenants: state.userState.userTenants,

    // TODO: ACTIVATE
    // intervals: state.communicationState.intervals.response,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getUser: () => dispatch(getUser()),
    getCustomer: () => dispatch(getCustomer()),
    getCompetitions: () => dispatch(getCompetitions()),
    deleteCompetition: (idComp) => dispatch(deleteCompetition(idComp)),
    deleteCompetitionFromState: (competitions) =>
      dispatch(deleteCompetitionFromState(competitions)),
    resetIndividualPoints: () => dispatch(resetIndividualPoints()),
    resetTeamPoints: () => dispatch(resetTeamPoints()),
    resetCompanyPoints: () => dispatch(resetCompanyPoints()),
    getUserflowSignatures: () => dispatch(getUserflowSignatures()),
    getUserTenants: () => dispatch(getUserTenants()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CompetitionContainer);

import React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Color } from '../../config/styles';

export default function DataPoint(props) {
  return (
    <div className={css(styles.container)}>
      <span className={css(styles.data)}>{props.data}</span>
      <span className={css(styles.unit)}>{props.unit}</span>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    textAlign: 'center',
    display: 'flex',
    alignItems: 'flex-end',
  },
  data: {
    color: Color.primary,
    // fontWeight: 600,
    fontSize: '1.8875rem',
    marginRight: '0.175em',
  },
  unit: {
    color: Color.dark,
    opacity: 0.6,
    letterSpacing: '.045454545em',
    fontSize: '1.2875rem',
    textTransform: 'uppercase',
    fontWeight: 600,
    marginBottom: '2px',
  },
});

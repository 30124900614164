import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import TransitionScreen from '../../components/TransitionScreen';

import { authenticateTidUser } from '../../irecommend-lib/actions/authActions';

const TidLoginPage = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { isLoadingUser, user } = useSelector((state) => state.oidc);
  const history = useHistory();
  let email, tenantId;
  /**
   * When user directly comes from link in the email
   */
  if (search !== '') {
    if (search.match(new RegExp('&')) !== null) {
      email = search.split('&')[0].split('=')[1];
      tenantId = search.split('&')[1].split('=')[1];
    } else {
      email = search.split('=')[1];
      tenantId = null;
    }
  }

  useEffect(() => {
    if (isLoadingUser) return;
    const timer = setTimeout(() => {
      dispatch(authenticateTidUser(email, tenantId));
    }, 600);

    return () => {
      clearTimeout(timer);
    };
  }, [dispatch, history, isLoadingUser, user, email, tenantId]);

  return <TransitionScreen />;
};

export default TidLoginPage;

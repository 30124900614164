import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { css, StyleSheet } from 'aphrodite';
import { withTranslation } from 'react-i18next';

class AdminProtected extends Component {
  render() {
    const { t } = this.props;
    return (
      <div className={css(styles.adminProtected)}>
        <div className={css(styles.adminProtected__text)}>
          <h1 className={css(styles.adminProtected__title)}>{t('admin-protected-title')}</h1>
          <p className={css(styles.adminProtected__description)}>
            <Link to="/">{t('admin-protected-link-text')}</Link>
          </p>
        </div>
      </div>
    );
  }
}

export default withTranslation()(AdminProtected);

const styles = StyleSheet.create({
  adminProtected: {
    display: 'flex',
    width: '100vw',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
});

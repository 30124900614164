import React, { Component } from 'react';
import UserListItem from './UserListItem';

import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

class UserPanel extends Component {
  render() {
    const { t } = this.props;
    return (
      <table className="user-panel">
        <thead>
          <tr>
            <th>{t('leaderboard-rank')}</th>
            <th>{t('leaderboard-user')}</th>
            <th>{t('leaderboard-tips')}</th>
            <th>{t('leaderboard-score')}</th>
          </tr>
        </thead>
        <tbody>
          <UserListItem data={this.props.data} />
        </tbody>
      </table>
    );
  }
}

// TODO: actually use the data from redux store
function mapStateToProps(state) {
  return {
    tips: state.BIDashboardState.tips,
  };
}

export default connect(mapStateToProps)(withTranslation()(UserPanel));

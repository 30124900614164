import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite';
// import { Color } from '../config/styles';
// import LoaderIcon from '../components/icons/Loader';

/**
 * Wrapper for content.
 *
 * Example usage:
 *
 * ```
 * <List
 *      data={data}
 *      keyExtractor={this._keyExtractor}
 *      ListHeaderComponent={this._listHeaderComponent}
 *      renderItem={this._renderItem}
 *  />
 * ```
 *
 */
export default class List extends Component {
  static propTypes = {
    /**
     * List data
     */
    data: PropTypes.array.isRequired,
    /**
     * Each row requires a unique key
     */
    keyExtractor: PropTypes.func.isRequired,
    /**
     * Display element in list header
     */
    ListFooterComponent: PropTypes.func,
    /**
     * Display element in list footer
     */
    ListHeaderComponent: PropTypes.func,
    /**
     * Render function for each row
     */
    renderItem: PropTypes.func.isRequired,
    /**
     * Set to false to remove the separator component
     */
    useItemSeparator: PropTypes.bool,
  };

  static defaultProps = {
    useItemSeparator: true,
    useListEmpty: false,
  };

  render() {
    const {
      data,
      useItemSeparator,
      keyExtractor,
      ListFooterComponent,
      ListHeaderComponent,
      renderItem,
      style,
    } = this.props;

    let footerComponent = ListFooterComponent ? ListFooterComponent() : null;
    let headerComponent = ListHeaderComponent ? ListHeaderComponent() : null;

    let list = null;

    if (!data || data.length === 0) {
      list = null;
    }

    list = data.map((item, key) => {
      return (
        <div key={keyExtractor(item)}>
          {renderItem(item)}

          {useItemSeparator && <div className={css(styles.itemSeparator)} />}
        </div>
      );
    });

    return (
      <div>
        {headerComponent}

        <div className={css(style)}>{list}</div>

        {footerComponent}
      </div>
    );
  }
}

const styles = StyleSheet.create({
  itemSeparator: {
    height: 1,
    backgroundColor: 'rgba(0, 0, 0, .1)',
  },
  loader: {
    alignItems: 'center',
    flex: 1,
    height: 100,
    justifyContent: 'center',
    padding: 20,
  },
});

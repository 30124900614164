import { CALL_API } from '../middleware/api';

/**
 * GET comments
 */
export const GET_COMMENTS_REQUEST = 'GET_COMMENTS_REQUEST';
export const GET_COMMENTS_SUCCESS = 'GET_COMMENTS_SUCCESS';
export const GET_COMMENTS_FAILURE = 'GET_COMMENTS_FAILURE';

export function getComments(id) {
  return {
    [CALL_API]: {
      endpoint: `comments/recommendation/${id}`,
      authenticated: true,
      types: [GET_COMMENTS_REQUEST, GET_COMMENTS_SUCCESS, GET_COMMENTS_FAILURE],
    },
  };
}

export const POST_COMMENT_REQUEST = 'POST_COMMENT_REQUEST';
export const POST_COMMENT_SUCCESS = 'POST_COMMENT_SUCCESS';
export const POST_COMMENT_FAILURE = 'POST_COMMENT_FAILURE';

/**
 * Create comment
 */
export function postComment(data, id = null) {
  let endpoint = `comments/recommendation/${id}`;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      authenticated: true,
      types: [POST_COMMENT_REQUEST, POST_COMMENT_SUCCESS, POST_COMMENT_FAILURE],
      method: 'POST',
      data: data,
    },
  };
}

export const DELETE_COMMENT_REQUEST = 'DELETE_COMMENT_REQUEST';
export const DELETE_COMMENT_SUCCESS = 'DELETE_COMMENT_SUCCESS';
export const DELETE_COMMENT_FAILURE = 'DELETE_COMMENT_FAILURE';

/**
 * Delete comment
 */
export function deleteComment(id) {
  const endpoint = `comments/single/${id}`;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      authenticated: true,
      types: [DELETE_COMMENT_REQUEST, DELETE_COMMENT_SUCCESS, DELETE_COMMENT_FAILURE],
      method: 'DELETE',
    },
  };
}

import React, { Component } from 'react';

import { connect } from 'react-redux';

import AdminPositionPage from '../../pages/admin/PositionPage';

import {
  getPosition,
  postPosition,
  pushPosition,
  deletePosition,
} from '../../irecommend-lib/actions/positionActions';

import {
  getRecommendationsForPosition,
  emptyRecommendationsForPosition,
  updateRecommendationForPosition,
  getRecommendationStagesForPosition,
  postRecommendationManual,
} from '../../irecommend-lib/actions/recommendationActions';

import { getReferralDocument } from '../../irecommend-lib/actions/referralActions';

import { getUser, getUsers } from '../../irecommend-lib/actions/userActions';
import { withTranslation } from 'react-i18next';

const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

class PositionContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      documentPath: '',
      showToast: false,
      message: '',
    };

    this.setState = this.setState.bind(this);
  }

  componentDidMount() {
    this.props.getUsers();
    if (this.props.match.params.id) {
      this.props.getPosition(this.props.match.params.id);
      this.props.emptyRecommendationsForPosition(this.props.match.params.id);
      this.props.getRecommendationsForPosition(this.props.match.params.id);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // Check for errors on form submission
    if (
      this.props.recommendationPost !== nextProps.recommendationPost &&
      nextProps.recommendationPost.isLoading === false
    ) {
      if (this.props.recommendationPost.error) {
        alert(this.props.t('generic-error-default-message'));
        return;
      }
      this.props.getRecommendationsForPosition(this.props.match.params.id);
    }
    if (
      this.props.recommendation !== nextProps.recommendation &&
      nextProps.recommendation.isLoading === false
    ) {
      if (nextProps.recommendation.request && nextProps.recommendation.request.status !== 201) {
        alert(this.props.t('generic-error-default-message'));
        return;
      }

      if (nextProps.recommendation.request && nextProps.recommendation.request.status === 201) {
        this.setState({
          showToast: true,
          message: this.props.t('candidate-manual-created-text'),
        });
      }

      this.props.getRecommendationsForPosition(this.props.match.params.id);
    }

    if (
      this.props.positionDelete !== nextProps.positionDelete &&
      nextProps.positionDelete.isLoading === false
    ) {
      if (this.props.positionDelete.error) {
        alert(this.props.t('generic-error-default-message'));
        return;
      }
      this.props.history.push('/admin/');
    }

    if (
      this.props.positionPost !== nextProps.positionPost &&
      nextProps.positionPost.isLoading === false
    ) {
      if (this.props.positionPost.error) {
        alert(this.props.t('generic-error-default-message'));
        return;
      }
      this.props.history.push('/admin/');
    }

    if (
      this.props.positionPush !== nextProps.positionPush &&
      nextProps.positionPush.isLoading === false
    ) {
      if (this.props.positionPush.error) {
        alert(this.props.t('generic-error-default-message'));
        return;
      }
      this.props.getPosition(this.props.match.params.id);
    }

    if (
      this.props.referralDocument !== nextProps.referralDocument &&
      nextProps.referralDocument.isLoading === false
    ) {
      if (nextProps.referralDocument.error) {
        alert(this.props.t('generic-error-default-message'));
        return;
      }

      const blob = b64toBlob(nextProps.referralDocument.response, 'application/pdf');

      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      }

      // For other browsers:
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(blob);
      var link = document.createElement('a');
      link.href = data;
      link.download = this.getDocumentName(this.state.documentPath);
      link.click();
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
      }, 100);
    }
  }

  render() {
    const position = this.props.position.response;
    const recommendations =
      this.props.positionRecommendations.request !== null &&
      this.props.positionRecommendations.request.status === 200
        ? this.props.positionRecommendations.response
        : [];
    const users =
      this.props.users.request !== null && this.props.users.request.status === 200
        ? this.props.users.response
        : [];

    const isLoading =
      this.props.position.isLoading ||
      this.props.users.isLoading ||
      this.props.positionRecommendations.isLoading ||
      this.props.referralDocument.isLoading;

    return (
      <div>
        <AdminPositionPage
          users={users}
          position={position}
          showToast={this.state.showToast}
          setState={this.setState}
          message={this.state.message}
          recommendations={recommendations}
          isLoading={isLoading}
          getStages={this.onPressGetStages}
          onChangeStatus={this.onChangeStatus}
          onPressGetDocument={this.onPressGetDocument}
          onPressConfirmDelete={this.onPressConfirmDelete}
          onPressConfirmArchiving={this.onPressConfirmArchiving}
          onPressConfirmPush={this.onPressConfirmPush}
          onPressConfirmContact={this.onPressConfirmContact}
          onPressConfirmInterview={this.onPressConfirmInterview}
          onPressConfirmHire={this.onPressConfirmHire}
          onPressFailedContact={this.onPressFailedContact}
          onPressNotInterested={this.onPressNotInterested}
          onPressFailedAfterInterview={this.onPressFailedAfterInterview}
          onPressFailedInterview={this.onPressFailedInterview}
          onPressFailedHire={this.onPressConfirmHire}
          onPressRecommendManual={this.onPressRecommendManual}
          onPressHandleIntegration={this.onPressHandleIntegration}
        />
      </div>
    );
  }

  getDocumentName = (path) => {
    const re = /^.*?\/(.*?\.pdf)$/;
    return path.match(re)[1] || 'document.pdf';
  };

  /**
   * Manually change/undo status
   */
  onChangeStatus = (id, status) => {
    this.props.updateRecommendationForPosition(id, status);
  };

  // Delete job
  onPressConfirmDelete = (id) => {
    this.props.deletePosition(id);
  };

  // Archive job
  onPressConfirmArchiving = (position) => {
    const data = {
      archived: true,
      Title: position.title,
      Skills: position.skills,
      Keywords: position.keywords,
      Text: position.text,
      Reward: position.reward,
      ContactName: position.contactname,
      ContactTelephone: position.contacttelephone,
      ContactMail: position.contactmail,
      JobLocation: position.joblocation,
      RewardType: position.rewardtype,
    };

    this.props.postPosition(data, position.idpositions);
  };

  // Push a position
  onPressConfirmPush = (position) => {
    const id = position.idpositions;

    const pushTypes = position.push;

    this.props.pushPosition(id, { Push: pushTypes });
  };

  onPressConfirmContact = (id, state) => {
    this.props.updateRecommendationForPosition(id, state);
  };

  onPressFailedContact = (id, state) => {
    state = 80; // If we failed contact, set as 'Not interested'
    this.props.updateRecommendationForPosition(id, state);
  };

  onPressNotInterested = (id, state) => {
    state = 40;
    this.props.updateRecommendationForPosition(id, state);
  };

  onPressConfirmInterview = (id, state) => {
    this.props.updateRecommendationForPosition(id, state);
  };

  onPressFailedAfterInterview = (id, state) => {
    this.props.updateRecommendationForPosition(id, state);
  };

  onPressFailedInterview = (id, state) => {
    this.props.updateRecommendationForPosition(id, state);
  };

  onPressConfirmHire = (id, state) => {
    this.props.updateRecommendationForPosition(id, state);
  };

  onPressFailedHire = (id, state) => {
    this.props.updateRecommendationForPosition(id, state);
  };

  onPressGetDocument = (idreferral, documentPath) => {
    // We need the filename when downloading the document
    this.setState(
      {
        documentPath: documentPath,
      },
      () => {
        this.props.getReferralDocument(idreferral);
      },
    );
  };

  onPressGetStages = () => {
    this.props.getRecommendationStagesForPosition(this.props.match.params.id);
  };

  onPressRecommendManual = (data) => {
    this.props.postRecommendationManual(this.props.match.params.id, JSON.stringify(data));
  };

  onPressHandleIntegration = (id, status, email, connectToATS) => {
    this.props.updateRecommendationForPosition(id, status, {
      Email: email,
      ConnectToATS: connectToATS,
    });
  };
}
function mapStateToProps(state) {
  return {
    users: state.userState.users,
    referral: state.referralState.referral,
    referralDocument: state.referralState.referralDocument,
    position: state.positionState.position,
    positionRecommendations: state.recommendationState.positionRecommendations,
    positionPost: state.positionState.positionPost,
    positionDelete: state.positionState.positionDelete,
    positionPush: state.positionState.positionPush,
    recommendation: state.recommendationState.recommendation,
    recommendationPost: state.recommendationState.recommendationPost,
    recommendationStages: state.recommendationState.recommendationStages,
    token: state.integrationState.token,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getReferralDocument: (id) => dispatch(getReferralDocument(id)),
    getPosition: (id) => dispatch(getPosition(id)),
    getUser: () => dispatch(getUser()),
    getUsers: () => dispatch(getUsers()),
    postPosition: (data, id) => dispatch(postPosition(data, id)),
    pushPosition: (id, pushTypes) => dispatch(pushPosition(id, pushTypes)),
    deletePosition: (id) => dispatch(deletePosition(id)),
    getRecommendationsForPosition: (id) => dispatch(getRecommendationsForPosition(id)),
    getRecommendationStagesForPosition: (id) => dispatch(getRecommendationStagesForPosition(id)),
    postRecommendationManual: (id, data) => dispatch(postRecommendationManual(id, data)),
    updateRecommendationForPosition: (id, state, data) =>
      dispatch(updateRecommendationForPosition(id, state, data)),
    emptyRecommendationsForPosition: (id) => dispatch(emptyRecommendationsForPosition(id)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PositionContainer));

import { CALL_API } from '../middleware/api';

/**
 * GET policy
 */
export const GET_POLICY_REQUEST = 'GET_POLICY_REQUEST';
export const GET_POLICY_SUCCESS = 'GET_POLICY_SUCCESS';
export const GET_POLICY_FAILURE = 'GET_POLICY_FAILURE';

export function getPolicy(recommendationid, language) {
  return {
    [CALL_API]: {
      endpoint: `/policy?recommendation=${recommendationid}&language=${language}`,
      authenticated: false,
      types: [GET_POLICY_REQUEST, GET_POLICY_SUCCESS, GET_POLICY_FAILURE],
    },
  };
}

import React from 'react';
import { css } from 'aphrodite';

import DatePickers from './DatePickers';
import { IntlNetwork } from '../../helpers/language';

function CompetitionFields(props) {
  const {
    type,
    data,
    isSubmit,
    inheritedStyles,
    t,
    startDate,
    endDate,
    onInputChange,
    onStartDateChange,
    onEndDateChange,
  } = props;

  if (type !== 3) {
    return (
      <div>
        {/** DatePickers for start and end date */}
        <div>
          <DatePickers
            startDate={startDate}
            endDate={endDate}
            isSubmit={isSubmit}
            onStartDateChange={onStartDateChange}
            onEndDateChange={onEndDateChange}
            inheritedStyles={inheritedStyles}
            t={t}
          />
        </div>
        <div className={css(inheritedStyles.formField)}>
          <label className={css(inheritedStyles.formControl)}>
            <span className={`form-label ${css(inheritedStyles.formLabel)}`}>
              {IntlNetwork(t, 'generic-label-reward')} *
            </span>
            <input
              type="text"
              maxLength="200"
              value={data.Reward}
              className={
                isSubmit && data.Reward === ''
                  ? css(inheritedStyles.formInputInvalid)
                  : css(inheritedStyles.formInput)
              }
              onChange={(e) => onInputChange(e, type, 'Reward')}
            />
          </label>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div className={css(inheritedStyles.formField)}>
          <label className={css(inheritedStyles.formControl)}>
            <span className={`form-label ${css(inheritedStyles.formLabel)}`}>
              {t('generic-text-points')} *
            </span>
            <input
              type="number"
              min="0"
              value={data.Points}
              className={
                isSubmit && data.Points === ''
                  ? css(inheritedStyles.formInputInvalid)
                  : css(inheritedStyles.formInput)
              }
              onChange={(e) => onInputChange(e, type, 'Points')}
            />
          </label>
        </div>
      </div>
    );
  }
}

export default CompetitionFields;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite';

import { Color, Font } from '../config/styles';
import { IntlNetwork } from '../helpers/language';

import ButtonIcon from '../components/ButtonIcon';
import HeaderWrapper from '../components/HeaderWrapper';
import HighscoreListItem from '../components/HighscoreListItem';
import HighscoreGoalListItem from '../components/HighscoreGoalListItem';
import HighscoreGoalTipListItem from '../components/HighscoreGoalTipListItem';
import List from '../components/List';
import Loader from '../components/Loader';
import ProfileLink from '../components/ProfileLink';
import TabBar from '../components/TabBar';
import TopThree from '../components/TopThree';

import { defaultText } from '../config/styles';
import { withTranslation } from 'react-i18next';

class HighscorePage extends Component {
  static propTypes = {
    /**
     * Used to know what list item to display. Goal tab uses a custom list item.
     */
    isGoal: PropTypes.bool.isRequired,
    /**
     * Handler when changing tab
     */
    onChangeTab: PropTypes.func.isRequired,
    /**
     * Handler when pressing header info button
     */
    onPressInfoButton: PropTypes.func.isRequired,
    /**
     * Handler when pressing on a recommendation in the list
     */
    onPressListItem: PropTypes.func.isRequired,
    /**
     * Handler when pressing the profile link in HeaderWrapper
     */
    onPressProfileLink: PropTypes.func.isRequired,
    /**
     * Current total points (goal)
     */
    pointsCurrent: PropTypes.number.isRequired,
    /**
     * All goals summarized points
     */
    pointsGoal: PropTypes.number.isRequired,
    /**
     * User data for ProfileLink in HeaderWrapper
     */
    user: PropTypes.object.isRequired,
    /**
     * How to know when the user data has been loaded
     */
    isLoading: PropTypes.bool.isRequired,
  };

  render() {
    const { data, isGoal, isLoading, user } = this.props;

    return (
      <main>
        <Loader isLoading={isLoading}>
          <List
            data={data}
            keyExtractor={this._keyExtractor}
            ListHeaderComponent={this._listHeaderComponent}
            renderItem={this._renderItem}
            useItemSeparator={!isGoal && user.customertype !== 'Network'}
          />
        </Loader>
      </main>
    );
  }

  /**
   * Used to extract a unique key for a given item at the specified index.
   */
  _keyExtractor = (item, index) => (this.props.isGoal ? item.idgoal : item.id);

  /**
   * FlatList header component.
   */
  _listHeaderComponent = () => {
    const {
      t,
      isGoal,
      onChangeTab,
      onPressInfoButton,
      onPressProfileLink,
      pointsCurrent,
      pointsGoal,
      user,
      isLoading,
    } = this.props;

    const activeTab = user.customertype !== 'Network' ? 0 : 2;

    const tabs = [
      {
        key: 'tab-1',
        title: t('highscorepage-tab-1'),
      },
      {
        key: 'tab-2',
        title: t('highscorepage-tab-2'),
      },
      {
        key: 'tab-3',
        title: t('highscorepage-tab-3'),
      },
    ];

    const topThree = this.getTopThree();

    const formattedPointsCurrent = new Intl.NumberFormat().format(pointsCurrent);
    const pointsPercent = Math.min(1, pointsCurrent / pointsGoal) * 100;

    // USED WHEN SHOWING TIP FORM FIRST
    // style={{height: 97 + (104 * (this.props.data.length - 1))}}

    return (
      <div>
        <header>
          <HeaderWrapper style={styles.container}>
            {!isLoading && user.user !== undefined && (
              <div className={css(styles.innerWrap)}>
                <ProfileLink
                  level={user.user.level}
                  name={user.user.givenname + ' ' + user.user.familyname}
                  onPress={onPressProfileLink}
                  picture={user.user.picture}
                  points={user.points.Int64}
                  size="tiny"
                  style={styles.profileLink}
                />

                <div className={css(styles.headerWrap)}>
                  <h1 className={css(defaultText.h1, styles.header)}>{t('highscorepage-title')}</h1>
                  <ButtonIcon
                    icon="infoLight"
                    onPress={onPressInfoButton}
                    style={styles.infoButton}
                    ariaLabel="highscorepage-info"
                  />
                </div>

                <TabBar
                  active={tabs[activeTab].key}
                  items={tabs}
                  onChange={onChangeTab}
                  style={styles.tabBar}
                />
              </div>
            )}
          </HeaderWrapper>
        </header>

        {isGoal ? (
          <div className={css(styles.barWrap)}>
            <div className={css(styles.total)}>
              {IntlNetwork(t, 'highscorepage-goal-points')}
              <strong className={css(styles.totalStrong)}>{formattedPointsCurrent} P</strong>
            </div>

            {this.props.data.length > 1 && (
              <div
                className={css(styles.bar)}
                style={{ height: 2 + 104 * (this.props.data.length - 1) }}
              >
                <div className={css(styles.barProgress)} style={{ height: `${pointsPercent}%` }} />
              </div>
            )}
          </div>
        ) : user.customertype !== 'Network' ? (
          <TopThree users={topThree} />
        ) : null}
      </div>
    );
  };

  /**
   * Prepare the top three users data to be used with <TopThree /> component.
   */
  getTopThree = () => {
    const data = this.props.data;

    let topThree = [];

    if (data) {
      topThree = Array.from(data);
      topThree.splice(3);

      topThree = topThree.map((item, key) => {
        return {
          name: item.name,
          number: item.number,
          picture: item.picture,
        };
      });
    }

    return topThree;
  };

  /**
   * Takes an item from data and renders it into the list.
   */
  _renderItem = (item) => {
    const { isGoal, onPressListItem, user, t } = this.props;

    if (isGoal) {
      // Goal tips item
      if (item.tip) {
        return <HighscoreGoalTipListItem onPressSubmitForm={item.onPressSubmitForm} />;
      }

      // Goal item
      return (
        <HighscoreGoalListItem
          achieved={item.achieved}
          description={item.description}
          fulfilled={false}
          goal={+item.points}
          id={item.idgoal}
          onPress={onPressListItem}
          picture={item.image}
          title={item.title}
          t={t}
        />
      );
    }

    return user.customertype !== 'Network' ? (
      <HighscoreListItem
        id={item.id}
        level={item.level}
        name={item.name}
        picture={item.picture}
        position={item.number}
        onPress={onPressListItem}
        points={item.points}
        selected={item.selected}
      />
    ) : null;
  };
}

export default withTranslation()(HighscorePage);

const styles = StyleSheet.create({
  bar: {
    position: 'absolute',
    left: 20,
    top: 106,
    width: 5,
    backgroundColor: Color.placeholderGray,
  },
  barProgress: {
    position: 'absolute',
    backgroundColor: Color.primary,
    width: '100%',
    top: 0,
  },
  barWrap: {
    position: 'relative',
  },
  container: {
    paddingBottom: 0,
  },
  header: {
    marginTop: 0,
    marginBottom: 0,
  },
  headerWrap: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  infoButton: {
    marginLeft: 10,
    marginTop: 7,
  },
  innerWrap: {
    flex: 1,
    justifyContent: 'flex-end',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  tabBar: {
    marginTop: 'auto',
  },
  profileLink: {
    position: 'absolute',
    right: 12,
    top: 13,
  },
  total: {
    fontSize: '.8125em',
    lineHeight: 1,
    paddingTop: 30,
    paddingLeft: 25,
    paddingRight: 25,
    paddingBottom: 12,
    textTransform: 'uppercase',
    color: Color.placeholderGray,
  },
  totalStrong: {
    fontFamily: Font.defaultFont,
    fontWeight: 600,
  },
});

import { StyleSheet, css } from 'aphrodite';
import React from 'react';

import EmptyState from '../EmptyState';
import CardItem from './CardItem';
import { useTranslation } from 'react-i18next';

function PositionsList(props) {
  const {
    displayedPositions,
    recommendations,
    onDeletePosition,
    onPushPosition,
    onArchivePosition,
  } = props;
  const { t } = useTranslation();

  if (displayedPositions.length === 0) {
    return (
      <EmptyState
        img="https://storybook.talentech.io/images/svg/logistics/package-open.svg"
        alt="Empty box"
        width="128"
        heading={t('homepage-no-jobs-no-categories-title')}
        description={t('positionpage-empty-state')}
      />
    );
  }
  return (
    <div className={css(styles.cardContainer)}>
      {displayedPositions.map((position) => (
        <CardItem
          key={position.idpositions}
          position={position}
          recommendation={
            recommendations[position.idpositions] ? recommendations[position.idpositions] : []
          }
          onDelete={onDeletePosition}
          onPush={onPushPosition}
          onArchive={onArchivePosition}
        />
      ))}
    </div>
  );
}

export default PositionsList;

const styles = StyleSheet.create({
  cardContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '20px',
    padding: '20px',
    alignItems: 'flex-start',
  },
  customAlert: {
    padding: '20px 20px 0px',
  },
});

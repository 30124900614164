import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { css, StyleSheet } from 'aphrodite';
import { getCustomerType } from '../api';

import HeaderWrapper from '../components/HeaderWrapper';
import RecommendationsListItem from '../components/RecommendationsListItem';
import List from '../components/List';
import ListButton from '../components/ListButton';
import Loader from '../components/Loader';
import ProfileLink from '../components/ProfileLink';

import { getRecommendationsUserIfNeeded } from '../irecommend-lib/actions/recommendationActions';
import { getUser } from '../irecommend-lib/actions/userActions';

import { defaultText } from '../config/styles';
import { useTranslation } from 'react-i18next';

const RecommendationsPage = (props) => {
  const { onPressProfileLink } = props;
  const { recommendations, user } = useSelector((state) => ({
    recommendations: state.recommendationState.recommendations,
    user: state.userState.user,
  }));
  const isLoading = user.isLoading || recommendations.isLoading;
  const history = useHistory();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const keyExtractor = (item) => item.idrecommendations;
  const recommendationList = Array.isArray(recommendations.response)
    ? recommendations.response
    : [];

  useEffect(() => {
    dispatch(getRecommendationsUserIfNeeded());
    dispatch(getUser());
  }, [dispatch]);

  const onPressMoreRecommendations = (e) => {
    e.preventDefault();
    history.push(`/`);
  };

  const onPressListItem = (id) => {
    return;
  };

  const listHeaderComponent = () => (
    <header>
      <HeaderWrapper>
        {!isLoading && Object.keys(user.response).length > 0 && (
          <div className={css(styles.innerWrap)}>
            <ProfileLink
              level={user.response.user.level}
              name={`${user.response.user.givenname} ${user.response.user.familyname}`}
              onPress={onPressProfileLink}
              picture={user.response.user.picture}
              points={user.response.points.Int64}
              size="tiny"
              style={styles.profileLink}
              showPoints={getCustomerType() !== 'Network'}
            />
            <h1 className={css(defaultText.h1, styles.header)}>
              {t('recommendationspage-header-text')}
            </h1>
            <h2 className={css(defaultText.h3, styles.description)}>
              {t('recommendationspage-header-sent-intl', { count: user.response.recommendations })}
            </h2>
          </div>
        )}
      </HeaderWrapper>
    </header>
  );

  const listFooterComponent = () => (
    <footer>
      <ListButton
        onPress={onPressMoreRecommendations}
        title={t('recommendationspage-footer-button')}
      />
    </footer>
  );

  const renderItem = (item) => (
    <RecommendationsListItem
      anonymized={item.expired}
      key={keyExtractor(item)}
      id={item.idrecommendations}
      image={item.image}
      name={item.referral.name}
      onPress={onPressListItem}
      picture={item.referral.picture}
      points={item.generatedpoints}
      status={item.state}
      role={item.position.title}
    />
  );
  return (
    <main>
      <Loader isLoading={isLoading}>
        <List
          data={recommendationList}
          ItemSeparatorComponent={() => <div style={{ height: '1px', backgroundColor: '#ccc' }} />}
          keyExtractor={keyExtractor}
          ListHeaderComponent={listHeaderComponent}
          ListFooterComponent={listFooterComponent}
          renderItem={renderItem}
        />
      </Loader>
    </main>
  );
};

export default RecommendationsPage;

const styles = StyleSheet.create({
  description: {
    margin: 0,
  },
  header: {
    marginBottom: 10,
  },
  innerWrap: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'flex-end',
  },
  loader: {
    flex: 1,
    justifyContent: 'center',
  },
  profileLink: {
    position: 'absolute',
    right: 12,
    top: 13,
  },
});

import React, { useState, useRef } from 'react';
import { Box } from '@talentech/components';
import { css, StyleSheet } from 'aphrodite';
import { connect } from 'react-redux';

import * as XLSX from 'xlsx';
import { useHistory } from 'react-router';

import Loader from '../../components/Loader';
import PageHeader from '../../components/admin/PageHeader';
import EmptyState from '../../components/EmptyState';
import InvitationImportResults from '../../components/admin/InvitationImportResults';
import ToastMessage from '../../components/ToastMessage';
import InvitationUpload from '../../components/admin/InvitationUpload';
import { TALENTECH_ICONS } from '../../components/Icons';

import useCreateBulkInvite from '../../hooks/invite/useCreateBulkInvite';
import useShowToast from '../../hooks/helper/useShowToast';
import useFetchBreadcrumb from '../../hooks/helper/useFetchBreadcrumb';

import { postBulkInvite } from '../../irecommend-lib/actions/inviteActions';
import { getUserIfNeeded } from '../../irecommend-lib/actions/userActions';
import { Color } from '../../irecommend-lib/styles';
import Utils from '../../irecommend-lib/utils';
import { isValidFileFormat, validateColumnIndexes } from '../../helpers/file';
import { useTranslation } from 'react-i18next';

const InvitationImportPage = (props) => {
  const [state, setState] = useState({
    error: false,
    message: '',
    showToast: false,
    showLog: false,
    uploading: false,
    fileName: '',
  });
  const [invites, setInvites] = useState({
    invalidEmails: [],
    sentInvites: [],
    duplicateInvites: [],
    invalidInvites: [],
    failedInvites: [],
  });
  const history = useHistory();
  const fileInput = useRef();
  const { user } = props;
  const { t } = useTranslation();

  const { createBulkInvite, bulkInviteIsLoading } = useCreateBulkInvite(setState, setInvites, t);
  const { showToastMessage, closeToast } = useShowToast(setState, t);
  const breadcrumbs = useFetchBreadcrumb('userImport', t);

  const loading = bulkInviteIsLoading || user.isLoading;

  const readExcel = (file) => {
    if (!isValidFileFormat(file, setState))
      return showToastMessage('invitationpage-bulk-file-format-error', true);

    const fileReader = new FileReader();
    //Parsing content of the file into array
    fileReader.onload = (e) => {
      const buffarArray = e.target.result;
      const workbook = XLSX.read(buffarArray, { type: 'buffer' });
      const sheet = workbook.SheetNames[0];
      const ws = workbook.Sheets[sheet];
      const data = XLSX.utils.sheet_to_json(ws);
      if (data.length !== 0) {
        checkFileData(data, file);
      }
    };
    fileReader.readAsArrayBuffer(file[0]);
  };

  //Handle click upload button
  const handleUpload = () => {
    fileInput.current.value = '';
    fileInput.current.click();
  };

  const checkFileData = (data, file) => {
    const columnIndexes = validateColumnIndexes(data[0]);
    if (!columnIndexes) return showToastMessage('invitationpage-bulk-file-structure-error', true);

    setState((state) => ({
      ...state,
      fileName: file[0].name,
    }));

    const formatData = data.map((invite) => {
      //Check if we have missing data. If just admin missing set it to false
      const adminField = Object.keys(invite).filter((field) =>
        field.match(new RegExp(/admin/gi)),
      ).length;

      if (Object.keys(invite).length !== 4 && adminField > 0) {
        setInvites((invites) => ({
          ...invites,
          invalidInvites: [...invites.invalidInvites, invite],
        }));
        return {};
      }
      // Check if emails are valid
      if (!Utils.validateEmail(Object.values(invite)[columnIndexes.emailColumn])) {
        setInvites((invites) => ({
          ...invites,
          invalidEmails: [
            ...invites.invalidEmails,
            Object.values(invite)[columnIndexes.emailColumn],
          ],
        }));
        return {};
      }
      //Format data to send invites
      return {
        givenname: Object.values(invite)[columnIndexes.firstNameColumn],
        familyname: Object.values(invite)[columnIndexes.lastNameColumn],
        email: Object.values(invite)[columnIndexes.emailColumn],
        admin:
          Object.keys(invite)[columnIndexes.adminColumn] !== undefined &&
          Object.keys(invite)[columnIndexes.adminColumn].match(new RegExp(/admin/gi)) !== null &&
          Object.values(invite)[columnIndexes.adminColumn].match(new RegExp(/yes/gi)) !== null &&
          adminField > 0
            ? true
            : false,
      };
    });

    sendInvite(formatData);
  };

  const sendInvite = (data) => {
    //Send data in JSON format
    createBulkInvite(JSON.stringify(data));
  };

  const createPageHeaderButtons = () => [
    {
      label: t('invitationpage-bulk-back-to-users'),
      icon: TALENTECH_ICONS.left,
      onclick: () => {
        history.push('/admin/invites');
      },
    },
  ];

  const handleShowLog = () => {
    setState((state) => ({
      ...state,
      showLog: !state.showLog,
    }));
  };

  return (
    <Loader isLoading={loading}>
      <ToastMessage isShowToast={state.showToast} message={state.message} closeToast={closeToast} />
      <PageHeader
        title={t('invitationpage-import-users')}
        buttons={createPageHeaderButtons()}
        breadcrumbs={breadcrumbs}
      />
      <Box className={css(styles.wrap)}>
        <InvitationUpload
          t={t}
          readExcel={readExcel}
          fileInput={fileInput}
          handleUpload={handleUpload}
        />
        {!state.fileName ? (
          <EmptyState
            heading={t('invitationpage-bulk-no-imports-text-first')}
            description={t('invitationpage-bulk-no-imports-text-second')}
            img="https://storybook.talentech.io/images/svg/email/mailbox-spide-web.svg"
            width="170"
          />
        ) : (
          <InvitationImportResults
            t={t}
            fileName={state.fileName}
            isUploading={state.uploading}
            isShowLog={state.showLog}
            invites={invites}
            handleShowLog={handleShowLog}
          />
        )}
      </Box>
    </Loader>
  );
};
function mapStateToProps(state) {
  return {
    bulkInvite: state.inviteState.bulkInvite,
    users: state.userState.users,
    user: state.userState.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getUser: () => dispatch(getUserIfNeeded()),
    postInvite: (data) => dispatch(postBulkInvite(data)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(InvitationImportPage);

const styles = StyleSheet.create({
  wrap: {
    backgroundColor: Color.white,
    margin: '1rem',
    padding: '1.5rem',
  },
  innerWrap: {
    backgroundColor: Color.lightGray,
    padding: '1.5rem',
  },
  progressBarWrapper: {
    minWidth: '100%',
    minHeight: '.5rem !important',
    backgroundColor: Color.backgroundDark,
    borderRadius: '10px',
  },
  progressBar: {
    width: '70%',
    minHeight: '.5rem !important',
    backgroundColor: Color.primary,
    borderRadius: '10px',
  },
});

import { TALENTECH_ICONS } from '../Icons';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from 'react-i18next';
import PageHeader from './PageHeader';

const JobsPageHeader = ({ hasRMOrTRIntegration, tokenExist }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const tabsOptions = tokenExist
    ? {
        tabs: [
          {
            title: t('generic-text-jobs'),
            id: 1,
          },
          {
            title: t('positionpage-ats-tab'),
            id: 2,
          },
        ],
      }
    : {};
  const createPageHeaderButtons = () => [
    !hasRMOrTRIntegration
      ? {
          label: <span className="add-position-button-text">{t('positionpage-new-position')}</span>,
          icon: TALENTECH_ICONS.plusCircled,
          onclick: () => history.push(`/admin/jobs/create`),
        }
      : {},
  ];
  return (
    <PageHeader
      title={t('generic-text-jobs')}
      buttons={createPageHeaderButtons()}
      tabsOptions={tabsOptions}
    />
  );
};

export default JobsPageHeader;

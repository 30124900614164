import { CALL_API } from '../middleware/api';

/**
 * GET competition
 */
export const GET_COMPETITION_REQUEST = 'GET_COMPETITION_REQUEST';
export const GET_COMPETITION_SUCCESS = 'GET_COMPETITION_SUCCESS';
export const GET_COMPETITION_FAILURE = 'GET_COMPETITION_FAILURE';

export function getCompetition(id) {
  return {
    [CALL_API]: {
      endpoint: `competitions/single/${id}`,
      authenticated: true,
      types: [GET_COMPETITION_REQUEST, GET_COMPETITION_SUCCESS, GET_COMPETITION_FAILURE],
    },
  };
}

/**
 *  Only dispatches getCompetitions if data is invalidated or empty
 */
// const shouldGetCompetition = state => {
//   const competition = state.competitionState.competition;

//   if (!Object.keys(competition.response).length) {
//     return true;
//   }

//   return competition.didInvalidate;
// };

// export const getCompetitionIfNeeded = id => (dispatch, getState) => {
//   if (shouldGetCompetition(getState())) {
//     return dispatch(shouldGetCompetition(id));
//   }
// };

/**
 * GET competitions
 */
export const GET_COMPETITIONS_REQUEST = 'GET_COMPETITIONS_REQUEST';
export const GET_COMPETITIONS_SUCCESS = 'GET_COMPETITIONS_SUCCESS';
export const GET_COMPETITIONS_FAILURE = 'GET_COMPETITIONS_FAILURE';

export function getCompetitions() {
  return {
    [CALL_API]: {
      endpoint: `competitions`,
      authenticated: true,
      types: [GET_COMPETITIONS_REQUEST, GET_COMPETITIONS_SUCCESS, GET_COMPETITIONS_FAILURE],
    },
  };
}

export const POST_COMPETITION_REQUEST = 'POST_COMPETITION_REQUEST';
export const POST_COMPETITION_SUCCESS = 'POST_COMPETITION_SUCCESS';
export const POST_COMPETITION_FAILURE = 'POST_COMPETITION_FAILURE';

/**
 * Create competition
 */
export function postCompetition(data, id = null) {
  let endpoint = 'competitions';

  if (id !== null) {
    endpoint += `/single/${id}`;
  }

  return {
    [CALL_API]: {
      endpoint: endpoint,
      authenticated: true,
      types: [POST_COMPETITION_REQUEST, POST_COMPETITION_SUCCESS, POST_COMPETITION_FAILURE],
      method: 'POST',
      data: data,
    },
  };
}

export const DELETE_COMPETITION_REQUEST = 'DELETE_COMPETITION_REQUEST';
export const DELETE_COMPETITION_SUCCESS = 'DELETE_COMPETITION_SUCCESS';
export const DELETE_COMPETITION_FAILURE = 'DELETE_COMPETITION_FAILURE';

/**
 * Delete competition
 */
export function deleteCompetition(id) {
  const endpoint = `competitions/single/${id}`;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      authenticated: true,
      types: [DELETE_COMPETITION_REQUEST, DELETE_COMPETITION_SUCCESS, DELETE_COMPETITION_FAILURE],
      method: 'DELETE',
    },
  };
}

export const UPDATE_COMPETITION_REQUEST = 'UPDATE_COMPETITION_REQUEST';
export const UPDATE_COMPETITION_SUCCESS = 'UPDATE_COMPETITION_SUCCESS';
export const UPDATE_COMPETITION_FAILURE = 'UPDATE_COMPETITION_FAILURE';

/**
 * Update competition
 */
export function updateCompetition(data, id) {
  const endpoint = `competitions/single/${id}`;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      authenticated: true,
      types: [UPDATE_COMPETITION_REQUEST, UPDATE_COMPETITION_SUCCESS, UPDATE_COMPETITION_FAILURE],
      method: 'POST',
      data: data,
    },
  };
}

export const DELETE_COMPETITION_FROM_STATE = 'DELETE_COMPETITION_FROM_STATE';

/**
 * Delete competition from current redux store
 */
export function deleteCompetitionFromState(competitions) {
  return {
    type: DELETE_COMPETITION_FROM_STATE,
    response: competitions,
  };
}

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  getInterval,
  getSingleOnDemandEmail,
  updateCustomInterval,
  updateOnDemandEmail,
} from '../../irecommend-lib/actions/communicationActions';

const useUpdateEmail = (id, setIsEditMail) => {
  const { customEmailTemplate, customInterval } = useSelector(
    (state) => state.communicationState,
    shallowEqual,
  );
  const dispatch = useDispatch();
  const isLoading = customEmailTemplate.isLoading || customInterval.isLoading;

  useEffect(() => {
    if (Object.keys(customEmailTemplate.response).length > 0) {
      dispatch(getSingleOnDemandEmail(id));
      setIsEditMail(false); // Wait for the response before setting to false
    }

    return () => dispatch({ type: 'GET_SINGLE_EMAIL_RESET' });
  }, [customEmailTemplate.response, id, setIsEditMail]);

  useEffect(() => {
    if (Object.keys(customInterval.response).length > 0) {
      dispatch(getInterval(id));
      setIsEditMail(false); // Wait for the response before setting to false
    }

    return () => dispatch({ type: 'GET_INTERVAL_RESET' });
  }, [customInterval.response, id, setIsEditMail]);

  const updateEmail = (data, isOnDemand) => {
    if (!isOnDemand) dispatch(updateCustomInterval(id, data));
    else dispatch(updateOnDemandEmail(id, data));
  };

  return { updateEmail, updateIsLoading: isLoading };
};

export default useUpdateEmail;

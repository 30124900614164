import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { css, StyleSheet } from 'aphrodite';
import LoaderIcon from './icons/Loader';

/**
 * A fullscreen activity indicator.
 *
 * Example usage:
 *
 * ```
 * <Loader
 *   isLoading=true
 * />
 * ```
 *
 */
export default class Loader extends Component {
  static propTypes = {
    /**
     * Child elements
     */
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
    /**
     * Show/hide the loader
     */
    isLoading: PropTypes.bool.isRequired,
    /**
     * Custom styles to be applied to the component.
     */
    style: PropTypes.number,
  };

  render() {
    const { children, isLoading, style, increaseZIndex } = this.props;
    return (
      <div className={css(styles.container, style)}>
        {children}

        {isLoading && (
          <div
            className={css([styles.outerWrap, increaseZIndex && styles.increased])}
            pointerEvents="none"
          >
            <div className={css(styles.wrap)}>
              <LoaderIcon />
            </div>
          </div>
        )}
      </div>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    height: '100%',
  },
  increased: {
    zIndex: 5,
  },
  outerWrap: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    zIndex: 5,
  },
  wrap: {
    borderRadius: 4,
    backgroundColor: 'rgba(0,0,0, .5)',
    padding: 15,
  },
});

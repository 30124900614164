import React, { Component } from 'react';
import { Color } from '../config/styles';
import { css, StyleSheet } from 'aphrodite';

import PropTypes from 'prop-types';

import Button from '../components/Button';
import logoLogin from '../assets/images/logoLogin@2x.png';
import { withTranslation } from 'react-i18next';

class RegisterStartPage extends Component {
  static propTypes = {
    /**
     * Button callback
     */
    onPressContinue: PropTypes.func.isRequired,
  };

  render() {
    const { onPressContinue, onPressClearCredentials, t } = this.props;

    return (
      <div className={css(styles.wrap)}>
        <div className={css(styles.inner)}>
          <img src={logoLogin} alt="" className={css(styles.logoImage)} />
          <h1 className={css(styles.title)}>{t('register-startpage-title')}</h1>
          <p className={css(styles.description)}>{t('register-startpage-description')}</p>
          <div className={css(styles.buttonWrap)}>
            <Button
              onPress={onPressContinue}
              title={t('register-startpage-button-text')}
              type="light"
            />
          </div>
          <p className="text-center mt-5">
            {t('loginpage-clear-credentials-text')}{' '}
            <span className={css(styles.credentialLink)} onClick={onPressClearCredentials}>
              {t('loginpage-clear-credentials-link')}
            </span>
          </p>
        </div>
      </div>
    );
  }
}

export default withTranslation()(RegisterStartPage);

const styles = StyleSheet.create({
  wrap: {
    backgroundColor: Color.primary,
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '2em',
  },
  inner: {
    maxWidth: '25em',
    flex: '1 1 20em',
    textAlign: 'center',
    color: Color.white,
    marginTop: '-1em',
  },
  logoImage: {
    width: '238px',
    display: 'block',
    margin: '0 auto 1.5em',
  },
  title: {
    fontSize: '1.4em',
    margin: '0 0 .5em',
  },
  description: {
    margin: '0 auto 1em',
    maxWidth: '20em',
    fontSize: '1em',
  },
  buttonWrap: {
    maxWidth: '15em',
    margin: '1em auto',
  },
  credentialLink: {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
});

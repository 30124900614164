import moment from 'moment';
import { useEffect } from 'react';
import { Box, Datepicker, Select } from '@talentech/components';
import '@talentech/components/src/storybook-core/forms/Datepicker/talentech.css';
import { StyleSheet, css } from 'aphrodite';

import { getDateAndTimeFromTimestamp } from '../../helpers/date';
import { fetchFrequencyOptions, hourOptions, minuteOptions } from '../../helpers/communication';

const CommunicationEditScheduleFields = (props) => {
  const {
    t,
    preventCustomization,
    emailResponse,
    timestamp,
    emailState,
    emailAndPushType,
    setFormState,
    isInvitationEmail,
    isRecurringType,
  } = props;
  const formattedDate = getDateAndTimeFromTimestamp(timestamp) || {
    date: '',
    hours: '',
    minutes: '',
  };
  useEffect(() => {
    let isDataSet = false;

    if (Object.keys(emailResponse).length > 0 && !isDataSet) {
      setFormState((prev) => ({
        ...prev,
        emailState: {
          ...prev.emailState,
          date: formattedDate.date,
          hours: formattedDate.hours,
          minutes: formattedDate.minutes,
          interval: emailResponse.interval,
        },
      }));
      isDataSet = true;
    }

    return () => {
      isDataSet = false;
    };
  }, [emailResponse, formattedDate.date, formattedDate.hours, formattedDate.minutes, setFormState]);

  const onChangeDate = (e) => {
    const originalDate = new Date(e[0]);

    // Format the date as "dd MMM yyyy"
    const formattedDate = originalDate.toLocaleDateString('en-US', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    });
    setFormState((prev) => ({
      ...prev,
      emailState: {
        ...prev.emailState,
        date: formattedDate,
      },
    }));
  };

  const fetchSelectedFrequencyOption = () => {
    const frequencyOptions = fetchFrequencyOptions(t);
    return frequencyOptions.find((option) => option.value === emailState.interval);
  };

  return (
    <>
      {preventCustomization ? (
        <>
          <Box className="col p-0">
            <p className={css(styles.emailPropertiesHeader)}>{t('communication-edit-send-date')}</p>
            <p className={css(styles.emailPropertiesDateText)}>{formattedDate.date}</p>
          </Box>
          <Box className="col">
            <p className={css(styles.emailPropertiesHeader)}>{t('communication-edit-send-time')}</p>
            <p className={css(styles.emailPropertiesDateText)}>
              {isInvitationEmail
                ? '08:45 (CET)'
                : formattedDate.hours + ':' + formattedDate.minutes}
            </p>
          </Box>
        </>
      ) : (
        <>
          <Box className="row my-0 p-0">
            <Box className="col p-0">
              <label className={css(styles.emailPropertiesHeader)} htmlFor="start-date">
                {t('competition-text-start-date').toUpperCase()}
              </label>
              <Datepicker
                options={{
                  onChange: onChangeDate,
                  onClose: function noRefCheck() {},
                  onOpen: function noRefCheck() {},
                }}
                disabled={emailAndPushType}
                value={moment(formattedDate?.date).format('MM/DD/YYYY')}
              />
            </Box>
          </Box>
          <Box className="row p-0">
            <Box className="col p-0">
              <label className={css(styles.emailPropertiesHeader)} htmlFor="text">
                {t('communication-edit-send-time').toUpperCase()}
              </label>
            </Box>
          </Box>
          <Box className="row mt-0 p-0">
            <Box className="col col-md-6 p-0 pe-2">
              <label className={css(styles.emailPropertiesHeader)} htmlFor="hours">
                {t('communication-edit-hours').toUpperCase()}
              </label>
              <Select
                getOptionValue={(option) => option.value}
                onChange={(option) =>
                  setFormState((prev) => ({
                    ...prev,
                    emailState: { ...prev.emailState, hours: option.value },
                  }))
                }
                options={hourOptions}
                defaultValue={hourOptions.find((option) => option.value === emailState.hours)}
                value={hourOptions.find((option) => option.value === emailState.hours)}
              />
            </Box>
            <Box className="col col-md-6 p-0 ps-2">
              <label className={css(styles.emailPropertiesHeader)} htmlFor="minutes">
                {t('communication-edit-minutes').toUpperCase()}
              </label>
              <Select
                getOptionValue={(option) => option.value}
                onChange={(option) =>
                  setFormState((prev) => ({
                    ...prev,
                    emailState: { ...prev.emailState, minutes: option.value },
                  }))
                }
                options={minuteOptions}
                value={minuteOptions.find((option) => option.value === emailState.minutes)}
              />
            </Box>
          </Box>
          {isRecurringType && (
            <Box className="row mt-4 p-0">
              <Box className="col p-0">
                <label className={css(styles.emailPropertiesHeader)} htmlFor="text">
                  {t('communication-edit-frequency').toUpperCase()}
                </label>
                <Select
                  getOptionValue={(option) => option.value}
                  onChange={(option) =>
                    setFormState((prev) => ({
                      ...prev,
                      emailState: { ...prev.emailState, interval: option.value },
                    }))
                  }
                  options={fetchFrequencyOptions(t)}
                  value={fetchSelectedFrequencyOption()}
                />
              </Box>
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default CommunicationEditScheduleFields;

const styles = StyleSheet.create({
  emailPropertiesHeader: {
    fontWeight: 600,
    fontSize: '0.75rem',
    color: '#60606C',
    margin: 0,
    lineHeight: '2rem',
  },
  emailPropertiesText: {
    fontWeight: 'bold',
    margin: 0,
  },
  emailPropertiesDateText: {
    fontWeight: 600,
    margin: 0,
  },
  emailFrequencyDescription: {
    fontWeight: 'lighter',
    fontSize: '0.75rem',
    color: '#60606C',
    margin: 0,
    lineHeight: '2rem',
  },
});

import React from 'react';
import { useState } from 'react';
import { css, StyleSheet } from 'aphrodite';

const ProfileSettingsPicture = (props) => {
  const { picture, name, size } = props;
  const [state, setState] = useState({
    imageError: false,
    uploaded: picture ? true : false,
    profilePictureURL: '',
  });

  if (!picture || state.imageError) {
    let initials = name
      ? name
          .split(' ')
          .map((n) => n[0])
          .join('')
          .toUpperCase()
      : 'N/A';
    initials = initials.substr(0, 2); // max two chars
    return (
      <div className={css([styles.wrapper, size === 'large' ? styles.large : styles.defaultSize])}>
        <span className={css([styles.text])}>{initials !== 'UU' && initials}</span>
      </div>
    );
  }
  return (
    <div className={css([styles.wrapper, size === 'large' ? styles.large : styles.defaultSize])}>
      <img
        onError={(error) => {
          setState({ imageError: true });
        }}
        src={picture}
        alt=""
        className={css([styles.image, size === 'large' ? styles.large : styles.defaultSize])}
      />
    </div>
  );
};
export default ProfileSettingsPicture;

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: '#fff',
    borderRadius: '50%',
    border: '1px solid #26253E',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  defaultSize: {
    width: 50,
    height: 50,
  },
  large: {
    width: 70,
    height: 70,
  },
  image: {
    width: '100%',
    height: '100%',
    borderRadius: 35,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  text: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate3d(-50%, -50%, 0)',
    fontSize: '1.3rem',
  },
});

import Button from '../components/Button';
import Input from '../components/Input';
import Loader from '../components/Loader';
import LogoLinkedIn from '../components/icons/LogoLinkedIn';
import MainWrapper from '../components/MainWrapper';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Color, Variables, Font } from '../config/styles';
import { connect } from 'react-redux';
import { css, StyleSheet } from 'aphrodite';

import Utils from '../irecommend-lib/utils';

class InterestedPage extends Component {
  static propTypes = {
    /*
     * Status of waiting for recommendationState
     */
    isLoading: PropTypes.bool.isRequired,
    /*
     * Button: Handles sending interest request
     */
    onPressSubmit: PropTypes.func.isRequired,
    /*
     * Button: Handles sending interest request with LinkedIn-profile
     */
    onPressSubmitLinkedIn: PropTypes.func.isRequired,
    /*
     * Button: Handles reading more about what happens when sending the declaration of interest
     */
    onReadMore: PropTypes.func.isRequired,
    /*
     * Button: Handles going to the Terms page
     */
    onTerms: PropTypes.func.isRequired,
    /*
     * State of the recommendation
     */
    recommendation: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      name: '',
      email: '',
      phone: '',
      comment: '',
      visible: true,
      allowPUL: false,
      showPULHighlight: false,
      showEmailHighlight: false,

      didSubmit: false, // Display a confirmation after the form has been sent
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.recommendation !== nextProps.recommendation &&
      nextProps.recommendation.referral
    ) {
      this.setState({
        name: nextProps.recommendation.referral.name,
        email: nextProps.recommendation.referral.mail,
        phone: nextProps.recommendation.referral.telephone,
        visible: true,
      });
    }
  }

  componentDidMount() {
    if (this.props.recommendation.referral) {
      this.setState({
        name: this.props.recommendation.referral.name,
        email: this.props.recommendation.referral.mail,
        phone: this.props.recommendation.referral.telephone,
        visible: true,
      });
    }
  }

  render() {
    const { t, isLoading, onReadMore, onTerms, recommendation } = this.props;
    const referrer =
      recommendation.user && recommendation.user.givenname !== 'External'
        ? t('interestedpage-input-visibleToPerson', { givenname: recommendation.user.givenname })
        : t('interestedpage-input-visible');

    const emailHighlight =
      this.state.showEmailHighlight && !Utils.validateEmail(this.state.email)
        ? styles.EmailHiglight
        : null;

    return (
      <Loader isLoading={isLoading}>
        <MainWrapper>
          <div className={css(styles.container)}>
            <div className={css(styles.responsiveWrap)}>
              <h1 className={css(styles.h1)}>{t('interestedpage-title')}</h1>
              <p>{t('interestedpage-lead')}</p>
              <form onSubmit={this.onSubmitForm}>
                <Input
                  value={this.state.name}
                  type="text"
                  name="name"
                  placeholder={t('generic-input-Name')}
                  onChange={(event) => this.setState({ name: event.target.value })}
                />
                <Input
                  value={this.state.email}
                  type="email"
                  name="email"
                  placeholder={t('generic-input-Mail')}
                  onChange={(event) => this.setState({ email: event.target.value })}
                  inputStyle={emailHighlight}
                  required
                />
                <Input
                  value={this.state.phone}
                  type="tel"
                  name="phone"
                  placeholder={t('generic-input-Telephone')}
                  onChange={(event) => this.setState({ phone: event.target.value })}
                />
                <Input
                  value={this.state.comment}
                  type="text"
                  name="comment"
                  placeholder={t('interestedpage-input-comment', {
                    companyname: recommendation.companyname,
                  })}
                  onChange={(event) => this.setState({ comment: event.target.value })}
                />
                <Input
                  style={styles.checkbox}
                  checked={this.state.visible}
                  type="checkbox"
                  name="visible"
                  placeholder={<span className={css(styles.small)}>{referrer}</span>}
                  onChange={(event) => this.setState({ visible: event.target.checked })}
                />
                <Input
                  checked={this.state.allowPUL}
                  type="checkbox"
                  name="allowPUL"
                  placeholder={
                    <span
                      className={css(
                        styles.small,
                        this.state.showPULHighlight && !this.state.allowPUL
                          ? styles.PULHighlight
                          : '',
                      )}
                    >
                      {t('interestedpage-input-allowPUL-intl', {
                        companyname: recommendation.companyname,
                      })}
                      <Button
                        title={t('interestedpage-input-allowPUL-link')}
                        type="linkInline"
                        onPress={onTerms}
                      />
                    </span>
                  }
                  onChange={(event) => this.setState({ allowPUL: event.target.checked })}
                />

                <h2 className={css(styles.h2)}>{t('interestedpage-submit-header')}</h2>
                <div className={css(styles.buttonWrap)}>
                  {(!this.state.allowPUL || !Utils.validateEmail(this.state.email)) && (
                    <div
                      className={css(styles.clickButtonDisabled)}
                      onClick={() => {
                        const states = {};
                        const invertedStates = {};

                        if (!this.state.allowPUL) {
                          states.showPULHighlight = false;
                          invertedStates.showPULHighlight = true;
                        }

                        if (!Utils.validateEmail(this.state.email)) {
                          states.showEmailHighlight = false;
                          invertedStates.showEmailHighlight = true;
                        }

                        this.setState(states);
                        setTimeout(() => {
                          this.setState(invertedStates);
                        }, 100);
                      }}
                    />
                  )}
                  <Button
                    type="primary"
                    disabled={this.submitDisabled()}
                    icon={<LogoLinkedIn />}
                    iconStyle={styles.iconLinkedIn}
                    title={t('interestedpage-button-onPressSubmitFormLinkedIn')}
                    onPress={this.onPressSubmitFormLinkedIn}
                  />
                  <Button
                    type="link"
                    disabled={this.submitDisabled()}
                    isLoading={isLoading}
                    title={t('interestedpage-button-onPressSubmitForm')}
                    onPress={this.onPressSubmitForm}
                  />
                </div>

                <p className={css(styles.small, styles.readMoreButton)}>
                  {t('interestedpage-readMore')}{' '}
                  <Button
                    title={t('interestedpage-readMore-link')}
                    type="linkInline"
                    onPress={onReadMore}
                  />
                </p>
              </form>
            </div>
          </div>
        </MainWrapper>
      </Loader>
    );
  }

  submitDisabled() {
    return !this.state.allowPUL || !Utils.validateEmail(this.state.email);
  }

  /**
   * Prevent form submission. Handle on button press instead.
   */
  onSubmitForm = (e) => {
    e.preventDefault();
    return false;
  };

  onPressSubmitForm = () => {
    if (this.props.isLoading || this.submitDisabled()) {
      // Disable button while loading
      return;
    }

    if (this.props.onPressSubmit(this.state) === true) {
      // Keeps track of when the user has submitted the recommendation. Used to present errors.
      this.setState({
        didSubmit: true,
      });
    }
  };

  onPressSubmitFormLinkedIn = () => {
    if (this.props.isLoading || this.submitDisabled()) {
      // Disable button while loading.
      return;
    }

    if (this.props.onPressSubmitLinkedIn(this.state) === true) {
      // Keeps track of when the user has submitted the recommendation. Used to present errors.
      this.setState({
        didSubmit: true,
      });
    }
  };
}

function mapStateToProps(state) {
  return {
    // recommendation: state.recommendationState.recommendation,
  };
}

export default connect(mapStateToProps)(InterestedPage);

const jumpAnimation = {
  '0%': {
    transform: 'scale(1) translateY(0)',
  },

  '50%': {
    transform: 'scale(1) translateY(-8px)',
  },

  '100%': {
    transform: 'scale(1) translateY(0)',
  },
};

const PULHighlight = {
  animationName: jumpAnimation,
  animationDuration: '.4s',
  animationIterationCount: '1',
  animationFillMode: 'forwards',
  color: Color.warning,
};

const getPlaceholderStyles = (obj) => ({
  '::-webkit-input-placeholder': obj,
  '::-moz-placeholder': obj,
  ':-ms-input-placeholder': obj,
  ':-moz-placeholder': obj,
  ':placeholder': obj,
});

const styles = StyleSheet.create({
  readMoreButton: {
    marginTop: 40,
  },
  checkbox: {
    display: 'block',
  },
  container: {
    minHeight: Variables.MainWrapperHeight,
    padding: Variables.BubbleSectionPadding,
    backgroundColor: Color.white,
  },
  h1: {
    fontFamily: Font.defaultFont,
    fontWeight: 600,
    fontSize: '1.5em',
    lineHeight: '30px',
    color: Color.dark,
  },
  h2: {
    margin: '2em 0 0',
    textAlign: 'center',
    fontSize: '.875em',
    lineHeight: '30px',
    color: Color.dark,
  },
  iconLinkedIn: {
    color: Color.white,
    fill: Color.white,
  },
  responsiveWrap: {
    maxWidth: 700,
    margin: '0 auto',
  },
  small: {
    fontSize: '.75rem',
    display: 'inline-block',
  },
  PULHighlight,
  EmailHiglight: {
    ...PULHighlight,
    ...getPlaceholderStyles({ color: Color.warning }),
    borderColor: Color.warning,
  },
  buttonWrap: {
    position: 'relative',
  },
  clickButtonDisabled: {
    background: 'transparent',
    right: 0,
    left: 0,
    bottom: 0,
    top: 0,
    position: 'absolute',
    zIndex: 1,
  },
});

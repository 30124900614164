import React, { Component } from 'react';
import { css, StyleSheet } from 'aphrodite';

import { IconX } from '../Icons';
import moment from 'moment';

class AddedComment extends Component {
  render() {
    const { comment, deleteComment, date, commenter } = this.props;

    const formattedDate = moment(date).format('YYYY-MM-DD');

    return (
      <li className={css(styles.comment, styles.lineButtom)}>
        <div className={css(styles.comments)}>
          <span className={css(styles.textStyle1)}>
            {formattedDate}, {commenter}:
          </span>
          <span className={css(styles.textStyle2)}>{comment}</span>
        </div>
        <a href="#remove-comment" onClick={deleteComment} className={css(styles.iconBackground)}>
          <IconX className={css(styles.icon)} />
        </a>
      </li>
    );
  }
}

export default AddedComment;

const styles = StyleSheet.create({
  icon: {
    width: '.75em',
    height: '.75em',
    position: 'absolute',
    left: '4.5px',
    top: '4.5px',
  },
  comment: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '15px',
  },
  textStyle1: {
    fontSize: '.875rem',
    color: '#777',
    marginRight: '5px',
  },
  textStyle2: {
    fontSize: '.875rem',
    fontStyle: 'italic',
  },
  comments: {
    marginRight: '20px',
  },

  lineButtom: {
    paddingBottom: '5px',
    borderBottom: '1px solid #45bcb74a',
  },
  iconBackground: {
    backgroundColor: '#eee',
    borderRadius: '50%',
    width: '20px',
    height: '20px',
    paddingRight: '20px',
    position: 'relative',
  },
});

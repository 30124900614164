import { CALL_API } from '../middleware/api';
import { getLanguage } from '../../api';

/**
 * GET rules
 */
export const GET_RULES_REQUEST = 'GET_RULES_REQUEST';
export const GET_RULES_SUCCESS = 'GET_RULES_SUCCESS';
export const GET_RULES_FAILURE = 'GET_RULES_FAILURE';
export const GET_RULES_INVALIDATE = 'GET_RULES_INVALIDATE';

export function getRules() {
  let language = getLanguage();

  return {
    [CALL_API]: {
      endpoint: `rules?language=${language}`,
      authenticated: true,
      types: [GET_RULES_REQUEST, GET_RULES_SUCCESS, GET_RULES_FAILURE],
    },
  };
}

/**
 *  Invalidate rules content to make sure it get fetched again when the view appears
 */
export const getRulesInvalidate = () => {
  return {
    type: GET_RULES_INVALIDATE,
    payload: {},
  };
};

/**
 *  Only dispatches getRules if data is invalidated or empty
 */
const shoudGetRules = (state) => {
  const rules = state.ruleState;

  if (!rules.response || rules.response.length === 0) {
    return true;
  }

  return rules.didInvalidate;
};

export const getRulesIfNeeded = () => (dispatch, getState) => {
  if (shoudGetRules(getState())) {
    return dispatch(getRules());
  }
};

import React, { Component } from 'react';
import { css, StyleSheet } from 'aphrodite';
import PropTypes from 'prop-types';

import { Color } from '../config/styles';

import Badge from '../components/Badge';
import ProfilePicture from '../components/ProfilePicture';

/**
 * Displays top three users with ProfilePicture
 *
 * Example usage:
 *
 * ```
 * <TopThree
 *      users={[
 *           {name: "John Doe", picture: "url"},
 *           {name: Michael Jackson, picture: "url"}},
 *           {name: Barack Obama, picture: "url"}
 *      ]}
 * />
 * ```
 *
 */
export default class TopThree extends Component {
  static propTypes = {
    /**
     * Custom styles to be applied to the component.
     */
    style: PropTypes.number,
    /**
     * Users to show in top three. Object with name, number and picture expected.
     */
    users: PropTypes.array.isRequired,
  };

  render() {
    let { style, users } = this.props;

    const badgeStyles = [styles.badge2, styles.badge1, styles.badge3];

    // Move no. 1 user to the middle
    if (users.length === 3) {
      users.splice(1, 0, users.splice(0, 1)[0]);
    }

    const pictures = users.map((user, key) => {
      const size = key === 1 ? 'xlarge' : 'large';
      const style = key === 1 ? styles.middle : styles.picture;

      return (
        <ProfilePicture key={key} name={user.name} picture={user.picture} size={size} style={style}>
          <Badge text={user.number} style={[styles.badge, badgeStyles[key]]} />
        </ProfilePicture>
      );
    });

    return <div className={css(styles.wrap, style)}>{pictures}</div>;
  }
}

const styles = StyleSheet.create({
  badge: {
    bottom: 8,
    position: 'absolute',
    right: -8,
  },
  badge1: {
    backgroundColor: Color.badge1,
  },
  badge2: {
    backgroundColor: Color.badge2,
  },
  badge3: {
    backgroundColor: Color.badge3,
  },
  middle: {
    position: 'relative',
    marginLeft: 25,
    marginRight: 25,
  },
  picture: {
    position: 'relative',
  },
  wrap: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingBottom: 25,
    paddingTop: 25,
  },
});

import { FETCH_TEAMSCORE } from '../actions';

const reducerTeamScore = (state = [], action) => {
  switch (action.type) {
    case FETCH_TEAMSCORE:
      const data = action.payload.data;
      const team = data.map((team) => {
        return {
          name: team.team.name,
          picture: team.team.picture,
          href: team.team.href,
          points: team.points.Int64,
        };
      });
      return team;

    default:
      return state;
  }
};

export default reducerTeamScore;

import React from 'react';
import { Button } from '@talentech/components';

import { css, StyleSheet } from 'aphrodite';
import { TALENTECH_ICONS } from './Icons';
import { useTranslation } from 'react-i18next';

const CategorySection = (props) => {
  const { state, handleSetFilter } = props;
  const { t } = useTranslation();

  return (
    <>
      <p className={css(styles.subHeader)}>
        {t('profileeditpage-settings-positions').toUpperCase()}{' '}
      </p>
      <div className={css(styles.categoryWrapper)}>
        {state.categories?.map((category) => (
          <Button
            key={category.idcategories}
            onClick={() => handleSetFilter(category)}
            size="sm"
            icon={
              state.selectedCategories.filter((cat) => cat.idcategories === category.idcategories)
                .length > 0 ? (
                <i className={TALENTECH_ICONS.checkMark}></i>
              ) : (
                ''
              )
            }
            label={category.name}
            color="primary"
            outline={
              state.selectedCategories.filter((cat) => cat.idcategories === category.idcategories)
                .length > 0
                ? false
                : true
            }
          />
        ))}
      </div>
      <p className={css(styles.categoryDescription)}>
        {t('profileeditpage-settings-positions-text')}
      </p>
    </>
  );
};

export default CategorySection;

const styles = StyleSheet.create({
  subHeader: {
    fontSize: 15,
    color: 'rgba(38, 37, 62, 1)',
    letterSpacing: 0.3,
    marginTop: 10,
    marginBottom: 20,
    textTransform: 'uppercase',
  },
  categoryWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 8,
    marginBottom: '1rem',
  },
  categoryDescription: {
    marginBottom: '2rem',
  },
});

import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite';

import { Box, Select } from '@talentech/components';

const ManualRecommendationFields = (props) => {
  const { users, state, setState, t } = props;
  const registeredUsers = users.filter((option) => option.registered === true);
  const options = registeredUsers.map((user) => ({
    value: user.iduser,
    label: `${user.givenname} ${user.familyname} - ${user.mail}`,
  }));

  return (
    <>
      <Box className="row mt-0">
        <label>{t('candidate-manual-form-description')}</label>
      </Box>
      <Box className="row">
        <Box className="col">
          <label className={css(styles.label)} htmlFor="referrer">
            {t('candidate-manual-form-referrer-title').toUpperCase()}
            <span className={css(styles.required)}>*</span>
          </label>
          <Select
            id="referrer"
            className="pb-3"
            placeholder={t('candidate-manual-form-referrer-placeholder')}
            onChange={(data) =>
              setState((prev) => ({ ...prev, formData: { ...state.formData, userid: data.value } }))
            }
            options={options}
          />
          <hr />
        </Box>
      </Box>
      <Box className="row mt-2">
        <Box className="col">
          <label className={css(styles.label)} htmlFor="candidateName">
            {t('generic-text-name').toUpperCase()}
            <span className={css(styles.required)}>*</span>
          </label>
          <input
            type="text"
            className="form-control"
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                formData: { ...state.formData, candidatename: e.target.value },
              }))
            }
            placeholder={t('candidate-manual-form-name-placeholder')}
            id="candidateName"
          />
        </Box>
      </Box>
      <Box className="row">
        <Box className="col">
          <label className={css(styles.label)} htmlFor="email">
            {t('candidate-manual-form-email-title').toUpperCase()}
          </label>
          <input
            type="email"
            className="form-control"
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                formData: { ...state.formData, email: e.target.value },
              }))
            }
            placeholder={t('candidate-manual-form-email-placeholder')}
            id="email"
          />
        </Box>
      </Box>
      <Box className="row">
        <Box className="col">
          <label className={css(styles.label)} htmlFor="phone">
            {t('candidate-manual-form-phone-title').toUpperCase()}
          </label>
          <input
            type="text"
            className="form-control"
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                formData: { ...state.formData, phone: e.target.value },
              }))
            }
            placeholder={t('candidate-manual-form-phone-placeholder')}
            id="phone"
          />
        </Box>
      </Box>
      <Box className="row">
        <Box className="col">
          <label className={css(styles.label)} htmlFor="info">
            {t('candidate-manual-form-info-title').toUpperCase()}
          </label>
          <textarea
            rows="4"
            max-rows="10"
            className="form-control"
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                formData: { ...state.formData, commentfromreferrer: e.target.value },
              }))
            }
            placeholder={t('candidate-manual-form-info-placeholder')}
            id="info"
          />
        </Box>
      </Box>
    </>
  );
};

export default ManualRecommendationFields;

ManualRecommendationFields.propTypes = {
  /**
   * Users (active/registered) to display in this section
   */
  users: PropTypes.array.isRequired,
};
const styles = StyleSheet.create({
  required: {
    color: 'red',
  },
  label: {
    marginBottom: '8px',
    fontSize: '.75rem',
    fontWeight: 600,
  },
});

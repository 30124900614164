import React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Color } from '../../irecommend-lib/styles';

import AdminImage from './AdminImage';
import DataPoint from './DataPoint';

function CompanyCompetitionDetails(props) {
  const { competition, competitionType, companyPoints, t } = props;

  const goalProgress = `${companyPoints}/${competition.points}`;

  return (
    <div>
      <div className={css(styles.container)}>
        <div className={css(styles.heading)}>
          <AdminImage picture={competition.image} src={competition.image} />
          <div>
            <h1 className={css(styles.title)}>{competition.title}</h1>
            <h3 className={css(styles.title, styles.subtitle)}>{competitionType}</h3>
          </div>
        </div>
      </div>
      <div className={css(styles.container)}>
        <div className={css(styles.description)}>
          <DataPoint data={goalProgress} unit={t('generic-text-points')} />
        </div>
      </div>
    </div>
  );
}

export default CompanyCompetitionDetails;

const styles = StyleSheet.create({
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 2fr 1fr',
    gridGap: '2em 2em',
    marginBottom: '1rem',
  },
  heading: {
    display: 'flex',
    alignItems: 'center',
    gridColumn: '1 / span 2',
  },
  title: {
    margin: '0 0 0 0.875rem',
  },
  subtitle: {
    marginTop: '0.375rem',
  },
  boldFont: {
    fontSize: '1.0625rem',
    fontWeight: '600',
    lineHeight: '1.352941176',
    flexBasis: '100%',
  },
  boldHeading: {
    paddingBottom: '0.5em',
    display: 'block',
  },
  description: {
    gridColumnStart: '1',
    gridColumnEnd: '3',
    borderRadius: '2px',
    border: '0',
    boxShadow: '0 5px 17px 0 rgba(0, 0, 0, .03)',
    backgroundColor: Color.white,
    padding: '1.5em 2.5em 2em',
  },
  box: {
    borderRadius: '2px',
    border: '0',
    boxShadow: '0 5px 17px 0 rgba(0, 0, 0, .03)',
    backgroundColor: Color.white,
    padding: '1.5em 2.5em 2em',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'left',
    alignItems: 'center',
  },
});

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { css, StyleSheet } from 'aphrodite';
import { getStatus } from '../config/styles';

/**
 * A basic indicator that displays status colors.
 *
 * Example usage:
 *
 * ```
 * <StatusIndicator
 *   status=1
 * />
 * ```
 *
 */
export default class StatusIndicator extends Component {
  static propTypes = {
    /**
     * Referral status
     */
    status: PropTypes.number,
    /**
     * Custom styles to be applied to the component.
     */
    style: PropTypes.object,
  };

  static defaultProps = {};

  render() {
    const { style } = this.props;

    return <div className={css([styles.status, style, this.applyStatusColor()])} />;
  }

  applyStatusColor = () => {
    return StyleSheet.create({
      statusColor: {
        backgroundColor: getStatus(this.props.status).color,
      },
    }).statusColor;
  };
}

const styles = StyleSheet.create({
  status: {
    backgroundColor: 'lightgray',
    borderRadius: 5,
    height: 10,
    width: 10,
  },
});

import React from 'react';
import { Box } from '@talentech/components';
import { css, StyleSheet } from 'aphrodite';

import { useEffect } from 'react';

const IntegrationATSFields = (props) => {
  const { state, setState, recommendation, t } = props;

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      formData: { ...prev.formData, email: recommendation.referral.mail },
    }));
  }, [setState, recommendation.referral.mail]);

  return (
    <>
      <Box className="row mt-0">
        <p className="text-center">{t('candidate-integration-description')}</p>
      </Box>
      <Box className="row mt-4">
        <Box className="col-3 text-center">
          <label className={css(styles.label)} htmlFor="email">
            {t('candidate-manual-form-email-placeholder')}
          </label>
        </Box>
        <Box className="col-9 ps-0">
          <input
            type="email"
            readOnly={recommendation.referral.mail && true}
            className="form-control"
            onChange={(e) => {
              setState((prev) => ({
                ...prev,
                formData: { ...prev.formData, email: e.target.value },
              }));
            }}
            value={state.formData.email}
            id="candidateName"
          />
        </Box>
      </Box>
    </>
  );
};

export default IntegrationATSFields;

const styles = StyleSheet.create({
  label: {
    marginBottom: '8px',
    fontSize: '.95rem',
    lineHeight: 2.5,
  },
});

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { css, StyleSheet } from 'aphrodite';
import { getCustomerType } from '../api';

import { logoutUser } from '../irecommend-lib/actions/authActions';
import { getUser } from '../irecommend-lib/actions/userActions';

import HeaderWrapper from '../components/HeaderWrapper';
import ListItem from '../components/ListItem';
import List from '../components/List';
import ProfileLink from '../components/ProfileLink';
import MainWrapper from '../components/MainWrapper';
import { useTranslation } from 'react-i18next';

const ProfilePage = (props) => {
  const { onPressProfileLink, isLoading } = props;
  const user = useSelector((state) => state.userState.user.response);
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const keyExtractor = React.useCallback((item, index) => item.id, []);

  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);

  const data = [
    { id: 1, title: t('profilepage-editprofile') },
    { id: 4, title: t('profilepage-logout') },
  ];

  let splicePosition = 2;

  // Show highscore
  if (user.customertype !== 'Network') {
    data.splice(splicePosition, 0, {
      id: 3,
      title: t('profilepage-rules'),
    });
    splicePosition++;
  }

  // Superuser
  if (user.user?.usertype === 2) {
    data.splice(splicePosition, 0, { id: 6, title: 'Change customer' });
  }

  const onPressListItem = (id) => {
    switch (id) {
      case 1:
        history.push(`/profile/edit`);
        break;
      case 2:
        history.push(`/profile/settings`);
        break;
      case 3:
        history.push(`/profile/rules`);
        break;
      case 4:
        dispatch(logoutUser());
        setTimeout(() => {
          history.push(`/login`);
        }, 600);
        break;
      case 6:
        history.push(`/admin/customerselect`);
        break;
      default:
        // ID not defined
        break;
    }
  };

  return (
    <MainWrapper lightBg>
      <List
        data={data}
        keyExtractor={keyExtractor}
        ListHeaderComponent={() => (
          <HeaderWrapper>
            {!isLoading && user.user !== undefined && (
              <div className={css(styles.innerWrap)}>
                <ProfileLink
                  level={user.user.level}
                  name={user.user.givenname + ' ' + user.user.familyname}
                  onPress={onPressProfileLink}
                  picture={user.user.picture}
                  points={user.points.Int64}
                  size="large"
                  style={styles.profileLink}
                  showPoints={getCustomerType() !== 'Network'}
                />
              </div>
            )}
          </HeaderWrapper>
        )}
        renderItem={(item) => (
          <ListItem onPress={() => onPressListItem(item.id)} id={item.id} title={item.title} />
        )}
        useItemSeparator={false}
        style={styles.list}
      />
    </MainWrapper>
  );
};

export default ProfilePage;

const styles = StyleSheet.create({
  innerWrap: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
  },
  list: {
    paddingTop: 25,
  },
  loader: {
    flex: 1,
    justifyContent: 'center',
  },
});

import { fetchMailVariableList, fetchSmsVariableList } from '../../helpers/communication';

import { StyleSheet, css } from 'aphrodite';
import { Badge } from '@talentech/components';
import { useTranslation } from 'react-i18next';

const CommunicationVariables = (props) => {
  const { onInsertTextToDraft, type } = props;
  const { t } = useTranslation();

  const variablesList = type === 'sms' ? fetchSmsVariableList(t) : fetchMailVariableList(t);
  const variables = variablesList.map((item, i) => (
    <button
      key={i}
      onClick={onInsertTextToDraft}
      name={item.text}
      className={css(styles.variableButton)}
    >
      <Badge label={item.title} size="lg" />
    </button>
  ));

  return (
    <>
      {type !== 'sms' && (
        <p className={css(styles.description)}>{t('communication-edit-variable-description')}</p>
      )}
      <div className={css(styles.variableList)}>{variables}</div>
    </>
  );
};

export default CommunicationVariables;

const styles = StyleSheet.create({
  description: {
    marginBottom: '1.5rem',
  },
  variableList: {
    display: 'flex',
    gap: '8px',
    flexWrap: 'wrap',
  },
  variableButton: {
    border: 'none',
    backgroundColor: 'transparent',
    padding: 0,
    margin: 0,
    cursor: 'pointer',
  },
});

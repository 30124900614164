import React, { Component } from 'react';
import { connect } from 'react-redux';

import NotInterestedPage from '../pages/NotInterestedPage';
import {
  getRecommendation,
  postRecommendationInterest,
} from '../irecommend-lib/actions/recommendationActions';

class NotInterestedContainer extends Component {
  componentDidMount() {
    if (!this.props.recommendation.response.companyimage) {
      this.props.getRecommendation(this.props.match.params.id);
    }
  }

  /**
   * Handle successful API response. We need to verify that the comment was successfully saved before redirecting.
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.recommendationInterest !== nextProps.recommendationInterest) {
      if (
        nextProps.recommendationInterest.request &&
        nextProps.recommendationInterest.request.status === 200
      ) {
        this.props.history.push(
          `/recommended/${this.props.match.params.id}/not-interested/confirmation`,
        );
      }
    }
  }

  /**
   * Want to be contacted
   */
  _onSendFeedback = (e, comment = null) => {
    e.preventDefault();

    if (comment === null || comment.length === 0) {
      return false;
    }

    // Sending interest with comment

    const data = {
      Comment: comment,
    };

    this.props.postRecommendationInterest(this.props.match.params.id, 5, data);

    return false;
  };

  render() {
    const { recommendationInterest } = this.props;

    return (
      <NotInterestedPage
        isLoading={recommendationInterest.isLoading}
        onSendFeedback={this._onSendFeedback}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    recommendation: state.recommendationState.recommendation,
    recommendationInterest: state.recommendationState.recommendationInterest,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getRecommendation: (recommendation) => dispatch(getRecommendation(recommendation)),
    postRecommendationInterest: (id, interested, data) =>
      dispatch(postRecommendationInterest(id, interested, data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NotInterestedContainer);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import MainWrapper from '../components/MainWrapper';
import { getUser } from '../irecommend-lib/actions/userActions';
import { postRecommendationConfirm } from '../irecommend-lib/actions/recommendationActions';
import {
  authenticateTidUser,
  logoutUser,
  storeCredentials,
} from '../irecommend-lib/actions/authActions';
import { storeCredentials as apiStoreCredentials } from '../api';
import RegisterStartPage from '../pages/RegisterStartPage';

import TransitionScreen from '../components/TransitionScreen';

class AuthenticationContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      idToken: '',
      login: null,
      recommended: null,
      registration: null,
      recommendationId: null,
      isLoading: true,
    };

    this.login = this.login.bind(this);
    this.onPressContinue = this.onPressContinue.bind(this);
    this.onPressClearCredentials = this.onPressClearCredentials.bind(this);
  }

  componentDidMount() {
    // Authenticate with Auth0
    if (this.props.match.path === '/login') {
      // This will automatically trigger Auth0 authentication
      // Disabled upon request by Linus
      // this.login();
      this.setState({ ...this.state, isLoading: false });
      return;
    }

    const { getUser, postRecommendationConfirm, storeCredentials } = this.props;

    this.setState({
      login: this.props.match.path.indexOf('login') !== -1,
      recommended: this.props.match.path.indexOf('recommended') !== -1,
    });

    // Fetch and store the credentials from Auth0
    storeCredentials().then(() => {
      const payload = this.props.authState.authenticate.response.idTokenPayload;
      // Token not set in hash URL
      // if (payload === undefined) {
      //   this.setState({ isLoading: true });
      //   return;
      // }
      if (this.state.login) {
        // Get user to if they are activated or not
        getUser();
      } else if (this.state.recommended) {
        // Store token
        let recommendationId = JSON.parse(payload['http://irecommend.se/meta']).recommendationId;
        // Update the recommendation with Linkedin data
        this.setState({ ...this.state, recommendationId: recommendationId });
        apiStoreCredentials({ source: 'linkedin' }, true);
        postRecommendationConfirm(recommendationId, {
          Headline: payload['http://irecommend.se/headline'],
          Picture: payload.picture,
          ProfileURL: payload['http://irecommend.se/publicProfileUrl'],
          UserID: payload.sub,
        });
      }
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.recommendationConfirm !== nextProps.recommendationConfirm &&
      nextProps.recommendationConfirm.request &&
      nextProps.recommendationConfirm.request.status === 200
    ) {
      this.props.history.push(
        `/recommended/${this.state.recommendationId}/interested/confirmation`,
      );
    }
  }

  render() {
    const isLoading =
      this.props.authState.authenticate.isLoading ||
      this.props.recommendationConfirm.isLoading ||
      this.state.isLoading;
    // This container should render a page but will for brevity and test-purposes render DOM right away
    return (
      <>
        {isLoading ? (
          <TransitionScreen />
        ) : (
          <MainWrapper>
            <RegisterStartPage
              onPressContinue={this.onPressContinue}
              onPressClearCredentials={this.onPressClearCredentials}
            />
          </MainWrapper>
        )}
      </>
    );
  }

  /**
   * On continuing
   */
  onPressContinue() {
    // Authenticate with TID
    if (this.props.match.path === '/login') {
      this.login();
      return;
    }
  }

  /**
   * Authenticate with TID
   */
  login() {
    const credentials = JSON.parse(localStorage.getItem('@irecommend:credentials'));
    if (
      credentials &&
      credentials.idTokenPayload &&
      credentials.activated &&
      credentials.expiresAt > Date.now()
    ) {
      this.props.history.push('/');
      return;
    }
    this.props.history.push(`/${process.env.REACT_APP_TID_LOGIN_PATH}`);
    return;
  }

  /**
   * Clearing credentials
   */
  onPressClearCredentials() {
    window.location = `${window.origin}/auth/logout-oidc`;
  }
}

function mapStateToProps(state) {
  return {
    authState: state.authState,
    recommendationConfirm: state.recommendationState.recommendationConfirm,
    user: state.userState.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    authenticateTidUser: () => dispatch(authenticateTidUser()),
    storeCredentials: () => dispatch(storeCredentials()),
    postRecommendationConfirm: (id, data) => dispatch(postRecommendationConfirm(id, data)),
    getUser: () => dispatch(getUser()),
    logoutUser: () => dispatch(logoutUser()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticationContainer);

import { CALL_API } from '../middleware/api';

/**
 * POST invite
 */
export const POST_INVITE_REQUEST = 'POST_INVITE_REQUEST';
export const POST_INVITE_SUCCESS = 'POST_INVITE_SUCCESS';
export const POST_INVITE_FAILURE = 'POST_INVITE_FAILURE';
export const POST_INVITE_RESET = 'POST_INVITE_RESET';

export function postInvite(data, id) {
  return {
    [CALL_API]: {
      endpoint: 'users/invite',
      authenticated: true,
      types: [POST_INVITE_REQUEST, POST_INVITE_SUCCESS, POST_INVITE_FAILURE, POST_INVITE_RESET],
      method: 'POST',
      data: data,
      sendJSON: true,
    },
  };
}
/**
 * POST bulk invite
 */
export const POST_BULK_INVITE_REQUEST = 'POST_BULK_INVITE_REQUEST';
export const POST_BULK_INVITE_SUCCESS = 'POST_BULK_INVITE_SUCCESS';
export const POST_BULK_INVITE_FAILURE = 'POST_BULK_INVITE_FAILURE';
export const POST_BULK_INVITE_RESET = 'POST_BULK_INVITE_RESET';

export function postBulkInvite(data, id) {
  return {
    [CALL_API]: {
      endpoint: 'users/invite/upload',
      authenticated: true,
      types: [
        POST_BULK_INVITE_REQUEST,
        POST_BULK_INVITE_SUCCESS,
        POST_BULK_INVITE_FAILURE,
        POST_BULK_INVITE_RESET,
      ],
      method: 'POST',
      data: data,
      sendJSON: true,
    },
  };
}

/**
 * DELETE invite
 */
export const DELETE_INVITE_REQUEST = 'DELETE_INVITE_REQUEST';
export const DELETE_INVITE_SUCCESS = 'DELETE_INVITE_SUCCESS';
export const DELETE_INVITE_FAILURE = 'DELETE_INVITE_FAILURE';

export function deleteInvite(id) {
  return {
    [CALL_API]: {
      endpoint: `invites/single/${id}`,
      authenticated: true,
      types: [DELETE_INVITE_REQUEST, DELETE_INVITE_SUCCESS, DELETE_INVITE_FAILURE],
      method: 'DELETE',
    },
  };
}

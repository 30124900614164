import React, { Component } from 'react';
import { css, StyleSheet } from 'aphrodite';
import PropTypes from 'prop-types';

import { Color, Font } from '../config/styles';

/**
 * A tab bar.
 *
 * Example usage:
 *
 * ```
 * <TabBar
 *      active="tab-2"
 *      items={[
 *          {
 *              key: 'tab-1',
 *              title: 'Information',
 *          },
 *          {
 *              key: 'tab-2',
 *              title: 'Search on Linkedin',
 *          }
 *      ]}
 *      onChange={onChangeTab}
 * />
 * ```
 *
 */
export default class TabBar extends Component {
  static propTypes = {
    /**
     * Set an active tab on init by providing it's key
     */
    active: PropTypes.string,
    /**
     * Disable the tab bar
     */
    disabled: PropTypes.bool,
    /**
     * An array of items to populate the tab bar
     */
    items: PropTypes.array.isRequired,
    /**
     * Handler to be called when the active tab changes
     */
    onChange: PropTypes.func.isRequired,
    /**
     * Custom styles to be applied to the button.
     */
    style: PropTypes.object,
    /**
     * Sets the tab bar's appearance. Supported values are 'dark' and 'light'
     */
    theme: PropTypes.string,
  };

  static defaultProps = {
    disabled: false,
    theme: 'light',
  };

  constructor(props) {
    super(props);

    this.state = {
      active: this.props.active,
    };
  }

  render() {
    const { disabled, items, style, theme } = this.props;

    const darkTheme = theme === 'dark';

    const buttons = items.map((item) => {
      const active = this.state.active === item.key;

      return (
        <a
          href={'#tab-' + item.key}
          disabled={disabled}
          key={item.key}
          onClick={(e) => this.onPress(e, item)}
          className={css(styles.link)}
        >
          <span
            className={css(
              styles.buttonWrap,
              active && styles.buttonWrapActive,
              darkTheme && active && styles.buttonWrapDarkActive,
            )}
          >
            <span
              className={css(
                styles.button,
                darkTheme && styles.buttonDark,
                active && styles.buttonActive,
                darkTheme && active && styles.buttonDarkActive,
              )}
            >
              {item.title.toUpperCase()}
            </span>
          </span>
        </a>
      );
    });

    return <div className={css(styles.container, style)}>{buttons}</div>;
  }

  /**
   * Update active tab on press and notify parent component
   */
  onPress = (e, item) => {
    e.preventDefault();

    this.setState((prevState) => ({
      active: item.key,
    }));

    this.props.onChange(item);
  };
}

const styles = StyleSheet.create({
  button: {
    backgroundColor: 'transparent',
    color: Color.primary,
    fontFamily: Font.defaultFont,
    fontWeight: 600,
    fontSize: 13,
    letterSpacing: 0.56,
    lineHeight: 1.38,
  },
  buttonActive: {
    display: 'block',
    color: Color.primary,
  },
  buttonDark: {
    color: `${Color.primary}99`,
  },
  buttonDarkActive: {
    color: Color.primary,
  },
  buttonWrapActive: {
    borderBottomColor: Color.primary,
  },
  buttonWrapDarkActive: {
    borderBottomColor: Color.primary,
  },
  buttonWrap: {
    display: 'block',
    borderBottomColor: 'transparent',
    borderBottomWidth: 3,
    borderBottomStyle: 'solid',
    marginRight: 20,
    paddingBottom: 16,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  link: {
    textDecoration: 'none',
  },
});

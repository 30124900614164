import React, { Component } from 'react';

import { connect } from 'react-redux';
import SettingsPage from '../../pages/admin/SettingsPage';
import { uploadPicture } from '../../irecommend-lib/actions/mediaActions';
import { postCustomerInfo, updateLaunchDate } from '../../irecommend-lib/actions/companyActions';
import { getUser, getUserTenants } from '../../irecommend-lib/actions/userActions';
import { getUserflowSignatures } from '../../irecommend-lib/actions/userflowActions';
import {
  postLaunchDate,
  postCustomer,
  getCustomer,
  updateProgress,
} from '../../irecommend-lib/actions/communicationActions';
import {
  deleteCustomerCategories,
  getCustomerCategories,
  getUserCustomer,
  postCustomerCategories as postCategories,
  updateCustomerCategories,
} from '../../irecommend-lib/actions/customerActions';
import { UTCToDate } from '../../helpers/date';
import { withTranslation } from 'react-i18next';

class SettingsContainer extends Component {
  componentDidMount() {
    this.props.getUser();
    this.props.getCustomer();
    this.props.getUserTenants();
    this.props.getUserCustomer();
    this.props.getUserflowSignatures();
    this.props.getCustomerCategories();

    //this.props.getCompany();
    // TODO: display customer data from the sql database, figure out how to fetch
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.postCustomerCategories !== nextProps.postCustomerCategories &&
      nextProps.postCustomerCategories.isLoading === false
    ) {
      if (nextProps.postCustomerCategories.error) {
        const { t } = this.props;
        alert(t('generic-error-default-message'));

        return;
      }

      this.props.getCustomerCategories();
    }
  }
  render() {
    const { response } = this.props.customer;
    const isLoading =
      this.props.customer.isLoading ||
      this.props.uploadPicture.isLoading ||
      this.props.company.isLoading ||
      this.props.customerCategories.isLoading ||
      this.props.postCustomerCategories.isLoading;
    const progress = response.customer ? response.customer.progress : 0;
    const error = this.props.customer.error || this.props.company.error;
    const userCustomer = this.props.userCustomer.response;
    const customer = response.customer ? response.customer : {};
    const user = this.props.user.response;
    const launchDate = customer.launch_date ? UTCToDate(customer.launch_date) : '';
    const intervals = response.intervals ? response.intervals : [];
    const userflowSignatures = this.props.userflowSignatures.response;
    const userTenants = this.props.userTenants.response;
    const company = this.props.company;
    const customerCategories = Array.isArray(this.props.customerCategories.response)
      ? this.props.customerCategories.response
      : [];
    const postCustomerCategories = this.props.postCustomerCategories;

    if (error) {
      alert(this.props.t('expire-invite-failure'));
    }

    return (
      <div>
        <SettingsPage
          userCustomer={userCustomer}
          user={user}
          userTenants={userTenants}
          customer={customer}
          customerCategories={customerCategories}
          postCustomerCategories={postCustomerCategories}
          company={company}
          isLoading={isLoading}
          intervals={intervals}
          launchDate={launchDate}
          progress={progress}
          userflowSignatures={userflowSignatures}
          onStartGuide={this.onStartGuide}
          onSetLaunchDate={this.onSetLaunchDate}
          onUpdateProgress={this.onUpdateProgress}
          onStartDateChange={this.onStartDateChange}
          onSubmitLogo={this.onSubmitLogo}
          onSubmitSender={this.onSubmitSender}
          onEditCareerSite={this.onEditCareerSite}
          onEditCommunication={this.onEditCommunication}
          onSubmitCreateCategory={this.onSubmitCreateCategory}
          onSubmitUpdateCategory={this.onSubmitUpdateCategory}
          onSubmitDeleteCategory={this.onSubmitDeleteCategory}
          t={this.props.t}
        />
      </div>
    );
  }

  onStartGuide = (e) => {
    e.preventDefault();
    // No need for this when customer object is created already
    //this.props.postCustomer();
  };

  // Updates GetStarted progress for customer
  onUpdateProgress = (progress) => {
    const postData = {
      progress: progress,
      idcustomer: this.props.customer.response.customer.idcustomer,
    };

    this.props.updateProgress(postData);
  };

  onSubmitLogo = (data) => {
    const { uploadPicture } = this.props;
    const { t } = this.props;

    // Form validation
    if (data.Picture === '') {
      alert(t('competition-fields-not-validated'));
      return false;
    }

    // Upload image to Cloudinary
    uploadPicture(data.PictureRaw)
      .then(() => {
        data.Picture = this.props.picture.response.data.secure_url;
        this.postData(data);
      })
      .catch(() => {
        return false;
      });

    return true;
  };

  onEditCareerSite = (data, e) => {
    const { postCustomerInfo, t } = this.props;

    // Form validation
    if (data['HomePage'] === '') {
      alert(t('competition-fields-not-validated'));
      return false;
    }

    // SQL
    postCustomerInfo(data);
    return true;
  };

  onEditCommunication = (data, e) => {
    const { postCustomerInfo, t } = this.props;

    // Form validation
    if (data['default_sender'] === '' || data['shortname'] === '' || data['language'] === '') {
      alert(t('competition-fields-not-validated'));
      return false;
    }
    const mongoData = {
      default_sender: data['default_sender'],
    };

    const sqlData = {
      ShortName: data['shortname'],
      Language: data['language'],
    };

    //TODO: handle this async and in a better way to update both databases...
    // MongoDB
    this.props.updateProgress(mongoData);
    // SQL
    postCustomerInfo(sqlData);

    return true;
  };

  onSetLaunchDate = (data, method) => {
    const { t } = this.props;
    // Form validation
    if (data.LaunchDate === '') {
      alert(t('competition-fields-not-validated'));
      return false;
    }

    // Convert launch date to epoch timestamp for Go API
    data.Golivedate = new Date(data.LaunchDate).getTime() / 1000;

    // TODO: Still need to make this async to ensure both databases update.
    this.props.postLaunchDate(data, method);
    this.props.updateLaunchDate(data);
    return true;
  };

  postData = (data) => {
    const { postCustomerInfo } = this.props;
    delete data.ImageRaw; // No need for the presentational version
    const imageData = {
      Image: data.Picture,
    };

    // Post customer logo and career site
    // TODO: THIS SHOULD BE ASYNC SO THAT WE DON'T UPDATE COMM SERVICE PROGRESS WITHOUT KNOWING IF THIS WAS OK
    postCustomerInfo(imageData);
  };

  onSubmitCreateCategory = (data) => {
    this.props.postCategories(data);
  };

  onSubmitUpdateCategory = (id, data) => {
    this.props.updateCategories(id, data);
  };

  onSubmitDeleteCategory = (id) => {
    this.props.deleteCategories(id);
  };
}

function mapStateToProps(state) {
  return {
    userCustomer: state.customerState.userCustomer,
    userTenants: state.userState.userTenants,
    customer: state.communicationState.customer,
    picture: state.mediaState.uploadPicture,
    company: state.companyState.company,
    user: state.userState.user,
    userflowSignatures: state.userflowState.userflowSignatures,
    customerCategories: state.customerState.customerCategories,
    postCustomerCategories: state.customerState.postCustomerCategories,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getUserflowSignatures: () => dispatch(getUserflowSignatures()),
    getUserTenants: () => dispatch(getUserTenants()),
    getUser: () => dispatch(getUser()),
    //getCompany: () => dispatch(getCompany()),
    getUserCustomer: () => dispatch(getUserCustomer()),
    getCustomer: () => dispatch(getCustomer()),
    postLaunchDate: (data, method) => dispatch(postLaunchDate(data, method)),
    postCustomer: () => dispatch(postCustomer()),
    postCustomerInfo: (data) => dispatch(postCustomerInfo(data)),
    updateLaunchDate: (data) => dispatch(updateLaunchDate(data)),
    updateProgress: (data) => dispatch(updateProgress(data)),
    uploadPicture: (file) => dispatch(uploadPicture(file)),
    getCustomerCategories: (data) => dispatch(getCustomerCategories(data)),
    postCategories: (data) => dispatch(postCategories(data)),
    updateCategories: (id, data) => dispatch(updateCustomerCategories(id, data)),
    deleteCategories: (id) => dispatch(deleteCustomerCategories(id)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SettingsContainer));

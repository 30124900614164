import React, { PureComponent } from 'react';
import { css, StyleSheet } from 'aphrodite';
import PropTypes from 'prop-types';

import ProfilePicture from '../components/ProfilePicture';

import { Color } from '../config/styles';

/**
 * Custom FlatList item specific for displaying highscore lists.
 *
 * Example usage:
 *
 * ```
 * <HighscoreGoalListItem
 *   id=1
 *   onPress={onPressLearnMore}
 *   name="John Doe"
 * />
 * ```
 *
 */
class HighscoreGoalListItem extends PureComponent {
  static propTypes = {
    /**
     * Goal achieved or not. Used to set the color of the progress bar
     */
    achieved: PropTypes.bool.isRequired,
    /**
     * Goal description
     */
    description: PropTypes.string.isRequired,
    /**
     * Wether or not the goal has been fulfilled. Used for styling the list item
     */
    fulfilled: PropTypes.bool.isRequired,
    /**
     * Number of points for this goal
     */
    goal: PropTypes.number,
    /**
     * Unique identifier for item
     */
    id: PropTypes.string.isRequired,
    /**
     * Handler to be called when the user taps the button
     */
    onPress: PropTypes.func,
    /**
     * Goal picture
     */
    picture: PropTypes.string,
    /**
     * Goal title. Only used as a placeholder when there is no image.
     */
    title: PropTypes.string.isRequired,
  };

  render() {
    const { achieved, fulfilled, goal, t, picture, title } = this.props;

    const formattedGoal = new Intl.NumberFormat().format(goal);
    const wrapStyles = [styles.wrap, fulfilled ? styles.fulfilled : null];

    return (
      <div onClick={this._onPressItem} className={css(wrapStyles)}>
        <span className={css([styles.line, achieved ? styles.lineAchieved : null])} />

        <ProfilePicture picture={picture} name={title} size="large" style={styles.picture} />

        <div>
          <h2 className={css([styles.header, achieved ? styles.headerAchieved : null])}>
            {t('highscore-goal-list-item-header-intl', { goal: formattedGoal })}
          </h2>
          <p className={css(styles.description)}>{title}</p>
        </div>
      </div>
    );
  }

  onPressItem = () => {
    if (this.props.onPress) this.props.onPress(this.props.id);
  };
}

export default HighscoreGoalListItem;

const styles = StyleSheet.create({
  level: {
    display: 'block',
    fontWeight: 500,
    fontSize: '.8125em',
    color: Color.gray,
  },
  line: {
    position: 'absolute',
    height: 2,
    width: '.8125em',
    left: 25,
    backgroundColor: Color.placeholderGray,
  },
  lineAchieved: {
    backgroundColor: Color.primary,
  },
  name: {
    display: 'block',
    fontSize: 17,
    color: Color.dark,
    marginBottom: 3,
  },
  header: {
    fontSize: '1.0625em',
    fontWeight: 500,
    margin: '0 0 .25rem 0',
    lineHeight: '1.5rem',
  },
  headerAchieved: {
    color: Color.primary,
  },
  description: {
    margin: 0,
    fontSize: '.8125em',
    lineHeight: '1.1875rem',
    color: Color.dark,
  },
  middleColumn: {
    flex: 1,
    maxWidth: 200,
  },
  points: {
    fontWeight: 500,
    fontSize: '.8125em',
    color: Color.dark,
  },
  picture: {
    marginLeft: 16,
    marginRight: 16,
    flex: '0 0 auto',
  },
  position: {
    fontSize: '1.0625em',
    color: Color.dark,
  },
  fulfilled: {
    backgroundColor: Color.turquoise,
  },
  wrap: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    padding: 18,
    paddingLeft: 33,
    paddingRight: 25,
    position: 'relative',
  },
});

import React, { PureComponent } from 'react';
import { css, StyleSheet } from 'aphrodite';

import PropTypes from 'prop-types';

import ArrowIndicator from '../components/ArrowIndicator';
import ProfilePicture from '../components/ProfilePicture';
import RecommendationStatus from '../components/RecommendationStatus';
import StatusIndicator from '../components/StatusIndicator';

import { Color, Font, getStatus } from '../config/styles';
import { withTranslation } from 'react-i18next';

/**
 * Custom FlatList item specific for displaying recommendations.
 *
 * Example usage:
 *
 * ```
 * <RecommendationsListItem
 *   id=1
 *   onPress={onPressLearnMore}
 *   title="John Doe"
 * />
 * ```
 *
 */
class RecommendationsListItem extends PureComponent {
  static propTypes = {
    /**
     * Used to check if the user has been anonymized by the system
     */
    anonymized: PropTypes.bool.isRequired,
    /**
     * Expand row by default
     */
    expanded: PropTypes.bool,
    /**
     * Unique identifier for item
     */
    id: PropTypes.string.isRequired,
    /**
     * Referral profile image. If no image provided we will use initials from full name (title).
     */
    image: PropTypes.string,
    /**
     * Referral full name
     */
    name: PropTypes.string.isRequired,
    /**
     * Handler to be called when the user taps the button
     */
    onPress: PropTypes.func.isRequired,
    /**
     * Referral picture
     */
    picture: PropTypes.string,
    /**
     * How many points the user has earned for this referral
     */
    points: PropTypes.number.isRequired,
    /**
     * How far the referral has made it in the recruitment process
     */
    status: PropTypes.number.isRequired,
    /**
     * The role the candidate is recommended for
     */
    role: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
    };
  }

  componentDidMount() {
    /**
     * Expand row by default
     */
    if (this.props.expanded === true) {
      this.setState({
        expanded: true,
      });
    }
  }

  render() {
    const { anonymized, id, t, picture, points, status, name, role } = this.props;

    const { expanded } = this.state;

    // const formattedPoints = new Intl.NumberFormat().format(points);
    const arrowDirection = expanded ? 'down' : 'up';

    let anonymizedMessage = null;

    if (status === 20 || status === 30) {
      anonymizedMessage = t('generic-anonymized-not-interested');
    } else if (status === 10) {
      anonymizedMessage = t('generic-anonymized-sent');
    } else {
      anonymizedMessage = t('generic-anonymized-not-in-question');
    }

    return (
      <div className={css(styles.wrap)}>
        {anonymized && <div className={css(styles.anonymousLabel)}>{anonymizedMessage}</div>}

        <span
          href="#recommendations-list-item"
          id={id}
          onClick={this.onPressItem}
          className={css(styles.link)}
        >
          <div className={css(styles.innerWrap, anonymized ? styles.anonymous : null)}>
            <ProfilePicture picture={picture} name={name} style={styles.image} />

            <div className={css(styles.textWrap)}>
              <span className={css(styles.role)}>{role}</span>
              <span className={css(styles.title)}>{name}</span>

              <div className={css(styles.statusWrap)}>
                <StatusIndicator status={status} style={styles.statusIndicator} />
                <span className={css(styles.statusTitle)}>{t(getStatus(status).label)}</span>
              </div>
            </div>

            <ArrowIndicator direction={arrowDirection} style={styles.arrowIndicator} />
          </div>
        </span>

        <RecommendationStatus direction={arrowDirection} points={points} status={status} />
      </div>
    );
  }

  onPressItem = (e) => {
    e.preventDefault();

    // Shouldn't accept any input when the recommended user has been anonymized
    if (this.props.anonymized) {
      return;
    }

    this.setState(
      {
        expanded: !this.state.expanded,
      },
      () => {
        this.props.onPress(this.props.id);
      },
    );
  };
}

export default withTranslation()(RecommendationsListItem);

const styles = StyleSheet.create({
  anonymous: {
    filter: 'blur(5px)',
  },
  anonymousLabel: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate3d(-50%, -50%, 0)',
    zIndex: 10,
    padding: '0 2em',
    textAlign: 'center',
    width: '100%',
    lineHeight: 1.5,
  },
  arrowIndicator: {
    marginLeft: 'auto',
    alignSelf: 'center',
  },
  expandWrap: {
    display: 'flex',
    backgroundColor: '#FF0000',
  },
  image: {
    marginRight: 16,
  },
  innerWrap: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 15,
    paddingRight: 58,
    paddingBottom: 15,
    paddingLeft: 32,
    alignItems: 'center',
  },
  link: {
    textDecoration: 'none',
  },
  statusIndicator: {
    marginRight: 4,
  },
  statusTitle: {
    color: Color.dark,
    fontFamily: Font.defaultFont,
    fontWeight: 600,
    fontSize: 13,
  },
  statusWrap: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  role: {
    color: '#747270',
    display: 'block',
    fontSize: '.9em',
    marginBottom: '.5em',
  },
  title: {
    color: Color.dark,
    display: 'block',
    fontFamily: Font.defaultFont,
    fontSize: 17,
    marginBottom: 7,
    maxWidth: 175,
  },
  wrap: {
    position: 'relative',
  },
});

import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import AdminProtected from '../components/admin/AdminProtected';

class RouterHandlerContainer extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      // Use setTimeout to make sure this runs after React Router's own listener
      setTimeout(() => {
        // Keep default behavior of restoring scroll position when user:
        // - clicked back button
        // - clicked on a link that programmatically calls `history.goBack()`
        // - manually changed the URL in the address bar (here we might want
        // to scroll to top, but we can't differentiate it from the others)
        if (this.props.location.action === 'POP') {
          return;
        }
        // In all other cases, check fragment/scroll to top
        var hash = window.location.hash;
        if (hash) {
          var element = document.querySelector(hash);
          if (element) {
            element.scrollIntoView({ block: 'start', behavior: 'smooth' });
          }
        } else {
          window.scrollTo(0, 0);
        }
      });
    }
  }

  render() {
    // Check if credentials exists
    const credentials = JSON.parse(localStorage.getItem('@irecommend:credentials'));
    // Check if user shows interest via Linkedin as well. This will set credentials and let them be inside app
    const isAuthenticated =
      credentials !== null && credentials.idTokenPayload.sub.match(new RegExp('linkedin')) === null;
    const isRegistered = credentials?.registered
      ? credentials.registered
      : this.props.user.response.user?.registered;
    const isActivated = isAuthenticated && credentials.activated && isRegistered;
    const user = this.props.user.response;
    // Check if the current path is protected or not
    const unprotectedPaths = [
      '^/404',
      '^/callback',
      '^/login',
      '^/profile-settings',
      '^/recommended',
      '^/register-privacy-policy',
      '^/externalreferral',
      '/recommended/:id/externalreferral',
      '/auth/signin-oidc',
      '/auth/logout-oidc',
      '/auth/renew-tid-token',
      `/${process.env.REACT_APP_TID_LOGIN_PATH}`,
    ];

    const isProtectedPath =
      unprotectedPaths.filter((item) => this.props.location.pathname.match(new RegExp(item)))
        .length === 0;
    const isAdminPage = this.props.location.pathname.match(new RegExp('/admin')) !== null;

    // Handle users that aren't activated. Happends when the user aborts in the middle of registration.
    if (credentials !== null && !isActivated && isProtectedPath && isRegistered !== undefined) {
      return <Redirect to={{ pathname: '/register-privacy-policy' }} />;
    }

    // Check that an access token is present in localstorage and if the user is trying to access a protected path
    // Redirect to login otherwise
    if (!isAuthenticated && isProtectedPath) {
      localStorage.setItem('@irecommend:redirectTo', window.location.pathname);
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: this.props.location },
          }}
        />
      );
    }
    // Else, if path is admin protected and user is not admnin
    else if (isAuthenticated && user.user && isAdminPage && user.user.usertype === 0) {
      return <AdminProtected />;
    } else {
      return <div>{this.props.children}</div>;
    }
  }
}

function mapStateToProps(state) {
  return {
    user: state.userState.user,
  };
}

export default withRouter(connect(mapStateToProps)(RouterHandlerContainer));

import ReactHtmlParser from 'react-html-parser';
import { css, StyleSheet } from 'aphrodite';

import { Box } from '@talentech/components';

const CommunicationItemfields = (props) => {
  const { interval, t } = props;
  return (
    <>
      <Box className="row px-0">
        <h5 className={css(styles.sectionHeader)}>{t('communication-item-channel-email')}</h5>
        <div className={css(styles.sectionWrapper)}>
          <div className={css(styles.fieldWrapper)}>
            <span className={css(styles.fieldLabel)}>{t('generic-text-subject')}</span>
            <p className={css(styles.fieldDescription)}>{interval.subject}</p>
          </div>
          <div className={css(styles.fieldWrapper)}>
            <span className={css(styles.fieldLabel)}>{t('communication-preview-message')}</span>
            <p className={css(styles.fieldDescription)}>
              {ReactHtmlParser(interval.template_html)}
            </p>
          </div>
        </div>
      </Box>
    </>
  );
};

export default CommunicationItemfields;

const styles = StyleSheet.create({
  sectionHeader: {
    fontWeight: 700,
  },
  sectionWrapper: {
    border: 'rgba(214, 214, 220, 1) solid 1px',
    borderBottom: 'none',
    padding: 0,

    ':nth-last-child(even)': {
      borderBottom: 'none',
    },
  },
  fieldWrapper: {
    display: 'flex',
    flexDirection: 'column',
    borderBottom: 'rgba(214, 214, 220, 1) solid 1px',
    padding: '0.75rem 1rem',
  },
  fieldLabel: {
    fontSize: '0.8rem',
    fontWeight: 400,
    color: '#60606C',
    paddingBottom: 4,
  },
  fieldDescription: {
    fontWeight: 600,
  },
});

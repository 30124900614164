import React, { Component } from 'react';
import { css, StyleSheet } from 'aphrodite';

import Button from '../../components/Button';
import { withTranslation } from 'react-i18next';

class CommentField extends Component {
  render() {
    const { t, handleSubmit, newComment, handlerOnChange } = this.props;

    return (
      <div>
        <form>
          <label className={css(styles.label)}>
            <span className="form-label">{t('comment-add-text')}</span>
            <textarea
              type="text"
              className="form-input"
              value={newComment}
              onChange={handlerOnChange}
            />
          </label>
          <div className={css(styles.buttonHolder)}>
            <Button title={t('comment-add-text')} onPress={handleSubmit} />
          </div>
        </form>
      </div>
    );
  }
}

export default withTranslation()(CommentField);

const styles = StyleSheet.create({
  buttonHolder: {
    maxWidth: '10em',
  },
  label: {
    display: 'block',
  },
});

import jwtDecode from 'jwt-decode';
import { createUserManager } from 'redux-oidc';

export const userManagerConfig = {
  authority: process.env.REACT_APP_TID_AUTHORITY,
  client_id: process.env.REACT_APP_TID_CLIENT_ID,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: false,
  response_type: 'code',
  monitorSession: false,
  redirect_uri: `${window.origin}/auth/signin-oidc`,
  silent_redirect_uri: `${window.origin}/auth/renew-tid-token`,
  post_logout_redirect_uri: `${window.origin}/auth/logout-oidc`,
  acr_values: 'userType:Customer',
  scope: 'openid profile irecommend-bff',
};

const userManager = createUserManager(userManagerConfig);

export const getEndSessionUrl = () => {
  const credentials = localStorage.getItem('@irecommend:credentials');
  const idToken = credentials ? JSON.parse(credentials)?.idToken : null;
  return `${process.env.REACT_APP_TID_AUTHORITY}/connect/endsession?id_token_hint=${idToken}`;
};

export default userManager;

export const processTidUserResponse = (user) => {
  const idTokenPayload = jwtDecode(user.id_token);
  const { id_token: idToken, access_token: accessToken, expires_at, ...rest } = user;
  return {
    ...rest,
    expiresAt: expires_at * 1000,
    idToken,
    accessToken,
    idTokenPayload,
    activated: true,
  };
};

export const renewAppToken = async () => {
  return processTidUserResponse(await userManager.signinSilent());
};

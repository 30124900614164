import { css, StyleSheet } from 'aphrodite';
import { Button } from '@talentech/components';

import CategorySection from '../components/CategorySection';
import ProfileSettingsFields from '../components/ProfileSettingsFields';
import Loader from '../components/Loader';

import useFetchProfileSettings from '../hooks/authentication/useFetchProfileSettings';
import useSubmitProfileSettings from '../hooks/authentication/useSubmitProfileSettings';

import { Color } from '../irecommend-lib/styles';
import { validateImage } from '../helpers/file';
import { useTranslation } from 'react-i18next';

const ProfileSettingsPage = () => {
  const { state, setState, setButtonDisabled, teamOptions, isFetchSettingsLoading, user } =
    useFetchProfileSettings();
  const { isPostDataLoading, postData } = useSubmitProfileSettings(state, setState, user);
  const { t } = useTranslation();
  const isLoading = isFetchSettingsLoading || isPostDataLoading;

  const onPressStart = (e) => {
    e.preventDefault();
    postData();
  };

  const onChangeFiles = (image) => {
    const { valid, errorKeys } = validateImage(image);
    if (!valid && errorKeys.length === 0) return;
    if (!valid && errorKeys.length > 0) return alert(t(errorKeys[0], errorKeys[1]));
    const reader = new FileReader();

    reader.onload = (e) => {
      setState((prev) => ({
        ...prev,
        formData: {
          ...prev.formData,
          Picture: e.target.result, // Loaded image for presentation
          PictureRaw: image[0], // input File object
        },
      }));
    };

    reader.readAsDataURL(image[0]);
  };

  const handleSetFilter = (category) => {
    const categoryClicked = category.idcategories;
    const checkIfCategorySelected =
      state.selectedCategories.filter((item) => item.idcategories === categoryClicked).length > 0;

    if (checkIfCategorySelected) {
      setState((prev) => ({
        ...prev,
        selectedCategories: prev.selectedCategories.filter(
          (item) => item.idcategories !== categoryClicked,
        ),
      }));
    } else {
      setState((prev) => ({
        ...prev,
        selectedCategories: [...prev.selectedCategories, category],
      }));
    }
  };

  return (
    <Loader isLoading={isLoading}>
      <div className={css(styles.wrap)}>
        <div className={css(styles.inner)}>
          <div className={css(styles.content)}>
            <ProfileSettingsFields
              state={state}
              setState={setState}
              user={user}
              teamOptions={teamOptions}
              onChangeFiles={onChangeFiles}
            />

            {state.categories.length > 0 && (
              <CategorySection state={state} handleSetFilter={handleSetFilter} />
            )}

            {state.error && <p className={css(styles.errorMessage)}>{t('register-user-error')}</p>}

            <div className="d-grid gap-2 mt-2">
              <Button
                disabled={setButtonDisabled}
                onClick={onPressStart}
                size="lg"
                label={t('register-profile-button-text')}
                color="primary"
              />
            </div>
          </div>
        </div>
      </div>
    </Loader>
  );
};

export default ProfileSettingsPage;

const styles = StyleSheet.create({
  wrap: {
    backgroundColor: Color.backgroundDark,
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: '1rem',
  },
  inner: {
    maxWidth: '55vw',
    flex: '1',
    color: Color.white,

    '@media (max-width: 768px)': {
      maxWidth: '80vw',
    },

    '@media (max-width: 376px)': {
      maxWidth: '90vw',
    },
  },
  content: {
    color: Color.bgBlue,
    backgroundColor: Color.white,
    padding: 40,
    paddingBottom: 30,
    borderRadius: '8px',
    boxShadow: ' 0 1px 2px rgb(0 0 0 / 0.2)',
  },
  introLink: {
    textDecorationLine: 'underline',
    textDecorationColor: 'rgba(38, 37, 62, 1)',
    textDecorationStyle: 'solid',
  },
  continueButton: {
    marginBottom: '5em',
  },
  errorMessage: {
    textAlign: 'center',
    marginBottom: '1em',
    color: Color.warning,
  },
});

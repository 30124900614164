/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  getSMSNotifications,
  getSingleSMSNotification,
} from '../../irecommend-lib/actions/communicationActions';

const useFetchSmsNotification = (templateType) => {
  const { smsNotifications, singleSmsNotification } = useSelector(
    (state) => state.communicationState,
    shallowEqual,
  );
  const dispatch = useDispatch();
  const idSmsNotification = smsNotifications.response
    ? smsNotifications.response.find((notification) => notification.template_type === templateType)
        ?._id
    : '';

  const isLoading = smsNotifications.isLoading || singleSmsNotification.isLoading;
  const error = smsNotifications.error || singleSmsNotification.error;
  const smsNotification = singleSmsNotification.response;

  useEffect(() => {
    if (smsNotifications.response?.length === 0) dispatch(getSMSNotifications());
  }, [smsNotifications.response?.length]);

  useEffect(() => {
    if (idSmsNotification) dispatch(getSingleSMSNotification(idSmsNotification));
  }, [idSmsNotification]);

  return { smsNotification, isSmsLoading: isLoading, error };
};

export default useFetchSmsNotification;

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Color, Font } from '../config/styles';
import { css, StyleSheet } from 'aphrodite';

import checkboxBackgroundImage from '../assets/images/checkmark.svg';

/**
 * A basic button component with specific appearance for iRecommend.
 *
 * Example usage:
 *
 * ```
 * <Input
 *   onPress={onPressLearnMore}
 *   placeholder="First- and lastname"
 *   type="text"
 * />
 * ```
 *
 */
export default class Input extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.value || '',
    };
  }
  static propTypes = {
    /*
     * Content for labeling - children
     */
    placeholder: PropTypes.node,
    /**
     * Custom styles from parent.
     */
    style: PropTypes.object,
    /**
     * Theme: light or dark. default: dark
     */
    theme: PropTypes.string,
    /**
     * Custom input styles from parent.
     */
    inputStyle: PropTypes.object,
    /**
     * Controls the input appearance. Valid values: Standard HTML input (not all are handled)
     */
    type: PropTypes.string,
    required: PropTypes.bool,
  };

  static defaultProps = {
    type: 'text',
    required: false,
  };

  render() {
    const { disabled, placeholder, style, theme, inputStyle, type, required } = this.props;

    const props = {
      ...(({ inputStyle, ...i }) => i)(this.props),
      placeholder: required ? `${placeholder}*` : placeholder,
    };
    switch (type) {
      case 'checkbox':
        return (
          <label className={css(styles.checkboxWrapper, style)}>
            <input {...props} className={css(styles.text, styles.checkbox)} />
            <span className={css(styles.checkboxText)}>{placeholder}</span>
          </label>
        );
      default:
        return (
          <label
            className={css(
              styles.wrap,
              styles.label,
              theme === 'light' ? styles.labelLight : null,
              style,
            )}
          >
            <input
              {...props}
              className={css(
                styles.text,
                styles.input,
                inputStyle,
                disabled ? styles.disabled : null,
                theme === 'light' ? styles.inputLight : null,
              )}
            />
          </label>
        );
    }
  }
}

const styles = StyleSheet.create({
  label: {
    display: 'block !important',
  },
  disabled: {
    opacity: 0.6,
  },
  input: {
    width: '100%',
    padding: '22px 17px 17px 4px',
    borderTop: 'none',
    borderRight: 'none',
    borderLeft: 'none',
    borderBottom: '1px solid rgba(0,0,0,0.3)',
    color: Color.dark,
    fontFamily: Font.defaultFont,
    borderRadius: 0,
  },
  inputLight: {
    background: 'none',
    color: Color.white,
  },
  checkboxWrapper: {
    margin: '1.25em 0',
    ':hover': {
      cursor: 'pointer',
    },
  },
  checkbox: {
    appearance: 'none',
    width: 0,
    height: 0,
    position: 'absolute',
    clip: 'rect(0, 0, 0, 0)',
    background: 'none',
    border: `1px solid ${Color.dark}`,
    borderRadius: '2px',
    ':checked + span:before': {
      backgroundColor: 'rgba(0, 0, 0, .75)',
      backgroundImage: `url(${checkboxBackgroundImage})`,
    },
  },
  checkboxText: {
    display: 'block',
    position: 'relative',
    margin: 0,
    padding: '.3125rem 0 0 2.25rem',
    fontSize: '.9375rem',
    color: Color.primary,
    ':before': {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      content: "''",
      width: '1.5rem',
      height: '1.5rem',
      borderRadius: '2px',
      backgroundSize: '12px',
      backgroundPosition: '50%',
      backgroundRepeat: 'no-repeat',
      boxShadow: `inset 0 0 0 1px ${Color.primary}`,
    },
  },
});

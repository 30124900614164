import {
  GET_CUSTOMERS_REQUEST,
  GET_CUSTOMERS_SUCCESS,
  GET_CUSTOMERS_FAILURE,
  GET_CUSTOMERS_INVALIDATE,
  GET_USER_CUSTOMER_REQUEST,
  GET_USER_CUSTOMER_SUCCESS,
  GET_USER_CUSTOMER_FAILURE,
  GET_USER_CUSTOMER_INVALIDATE,
  GET_CUSTOMER_CATEGORIES_FAILURE,
  GET_CUSTOMER_CATEGORIES_REQUEST,
  GET_CUSTOMER_CATEGORIES_SUCCESS,
  UPDATE_CUSTOMER_FOR_USER_REQUEST,
  UPDATE_CUSTOMER_FOR_USER_SUCCESS,
  UPDATE_CUSTOMER_FOR_USER_FAILURE,
  POST_CUSTOMER_CATEGORIES_REQUEST,
  POST_CUSTOMER_CATEGORIES_SUCCESS,
  POST_CUSTOMER_CATEGORIES_FAILURE,
  UPDATE_CUSTOMER_CATEGORIES_REQUEST,
  UPDATE_CUSTOMER_CATEGORIES_SUCCESS,
  UPDATE_CUSTOMER_CATEGORIES_FAILURE,
  DELETE_CUSTOMER_CATEGORIES_REQUEST,
  DELETE_CUSTOMER_CATEGORIES_SUCCESS,
  DELETE_CUSTOMER_CATEGORIES_FAILURE,
} from '../actions/customerActions';

const initialState = {
  customers: {
    didInvalidate: false,
    error: false,
    isLoading: false,
    response: {},
    request: null, // axios request response object
  },
  userCustomer: {
    didInvalidate: false,
    error: false,
    isLoading: false,
    response: {},
    request: null, // axios request response object
  },
  customerCategories: {
    error: false,
    isLoading: false,
    response: {},
    request: null, // axios request response object
  },

  postCustomerCategories: {
    error: false,
    isLoading: false,
    response: {},
    request: null, // axios request response object
  },
};

export default function customerState(state = initialState, { error, response, type, request }) {
  switch (type) {
    case GET_CUSTOMERS_REQUEST:
      return {
        ...state,
        customers: {
          ...state.customers,
          isLoading: true,
        },
      };
    case GET_CUSTOMERS_SUCCESS:
      return {
        ...state,
        customers: {
          ...state.customers,
          isLoading: false,
          response: response,
          request: request,
        },
      };
    case GET_CUSTOMERS_FAILURE:
      return {
        ...state,
        customers: {
          ...state.customers,
          isLoading: false,
          error: true,
          response: error,
          request: request,
        },
      };
    case GET_CUSTOMERS_INVALIDATE:
      return {
        ...state,
        customers: {
          ...state.customers,
          didInvalidate: true,
        },
      };
    case GET_USER_CUSTOMER_REQUEST:
      return {
        ...state,
        userCustomer: {
          ...state.userCustomer,
          isLoading: true,
        },
      };
    case GET_USER_CUSTOMER_SUCCESS:
      return {
        ...state,
        userCustomer: {
          ...state.userCustomer,
          isLoading: false,
          response: response,
          request: request,
        },
      };
    case GET_USER_CUSTOMER_FAILURE:
      return {
        ...state,
        userCustomer: {
          ...state.userCustomer,
          isLoading: false,
          error: true,
          response: error,
          request: request,
        },
      };
    case GET_USER_CUSTOMER_INVALIDATE:
      return {
        ...state,
        userCustomer: {
          ...state.userCustomer,
          didInvalidate: true,
        },
      };

    case UPDATE_CUSTOMER_FOR_USER_REQUEST:
      return {
        ...state,
        customersPost: {
          ...state.customersPost,
          error: false,
          isLoading: true,
        },
      };
    case UPDATE_CUSTOMER_FOR_USER_SUCCESS:
      return {
        ...state,
        customersPost: {
          ...state.customersPost,
          isLoading: false,
          response: response,
          request: request,
        },
      };
    case UPDATE_CUSTOMER_FOR_USER_FAILURE:
      return {
        ...state,
        customersPost: {
          ...state.customersPost,
          isLoading: false,
          error: true,
          response: error,
        },
      };
    case GET_CUSTOMER_CATEGORIES_REQUEST:
      return {
        ...state,
        customerCategories: {
          ...state.customerCategories,
          isLoading: true,
        },
      };
    case GET_CUSTOMER_CATEGORIES_SUCCESS:
      return {
        ...state,
        customerCategories: {
          ...state.customerCategories,
          isLoading: false,
          error: false,
          response: response,
          request: request,
        },
      };
    case GET_CUSTOMER_CATEGORIES_FAILURE:
      return {
        ...state,
        customerCategories: {
          ...state.customerCategories,
          isLoading: false,
          error: true,
          response: error,
        },
      };
    case POST_CUSTOMER_CATEGORIES_REQUEST:
      return {
        ...state,
        postCustomerCategories: {
          ...state.postCustomerCategories,
          isLoading: true,
        },
      };
    case POST_CUSTOMER_CATEGORIES_SUCCESS:
      return {
        ...state,
        postCustomerCategories: {
          ...state.postCustomerCategories,
          isLoading: false,
          error: false,
          response: response,
          request: request,
        },
      };
    case POST_CUSTOMER_CATEGORIES_FAILURE:
      return {
        ...state,
        postCustomerCategories: {
          ...state.postCustomerCategories,
          isLoading: false,
          error: true,
          response: error,
        },
      };
    case UPDATE_CUSTOMER_CATEGORIES_REQUEST:
      return {
        ...state,
        postCustomerCategories: {
          ...state.postCustomerCategories,
          isLoading: true,
        },
      };
    case UPDATE_CUSTOMER_CATEGORIES_SUCCESS:
      return {
        ...state,
        postCustomerCategories: {
          ...state.postCustomerCategories,
          isLoading: false,
          error: false,
          response: response,
          request: request,
        },
      };
    case UPDATE_CUSTOMER_CATEGORIES_FAILURE:
      return {
        ...state,
        postCustomerCategories: {
          ...state.postCustomerCategories,
          isLoading: false,
          error: true,
          response: error,
        },
      };
    case DELETE_CUSTOMER_CATEGORIES_REQUEST:
      return {
        ...state,
        postCustomerCategories: {
          ...state.postCustomerCategories,
          isLoading: true,
        },
      };
    case DELETE_CUSTOMER_CATEGORIES_SUCCESS:
      return {
        ...state,
        postCustomerCategories: {
          ...state.postCustomerCategories,
          isLoading: false,
          error: false,
          response: response,
          request: request,
        },
      };
    case DELETE_CUSTOMER_CATEGORIES_FAILURE:
      return {
        ...state,
        postCustomerCategories: {
          ...state.postCustomerCategories,
          isLoading: false,
          error: true,
          response: error,
        },
      };

    default:
      return state;
  }
}

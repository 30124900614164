import React, { Component } from 'react';
import { css, StyleSheet } from 'aphrodite';
import PropTypes from 'prop-types';

import { Color } from '../config/styles';

// import ProfilePicture from '../components/ProfilePicture';

/**
 * Small badge used in conjuction with other components
 *
 * Example usage:
 *
 * ```
 * <Badge text="1" />
 * ```
 *
 */
export default class Badge extends Component {
  static propTypes = {
    /**
     * Custom styles to be applied to the component. Using any to handle both array and number.
     */
    style: PropTypes.any,
    /**
     * Text in badge
     */
    text: PropTypes.number,
  };

  render() {
    let { style, text } = this.props;

    return (
      <div className={css(styles.wrap, style)}>
        <span className={css(styles.text)}>{text}</span>
      </div>
    );
  }
}

const styles = StyleSheet.create({
  text: {
    backgroundColor: 'transparent',
    fontWeight: 500,
    fontSize: 12,
    color: Color.white,
    textAlign: 'center',
    display: 'block',
  },
  wrap: {
    backgroundColor: Color.gray,
    borderRadius: 25,
    borderWidth: 0,
    height: 25,
    paddingTop: 7,
    width: 25,
  },
});

import { Button } from '@talentech/components';
import { css, StyleSheet } from 'aphrodite';
import React from 'react';

import { useDispatch } from 'react-redux';

import { logoutUser } from '../irecommend-lib/actions/authActions';
import { useTranslation } from 'react-i18next';

const AccessDeniedPage = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return (
    <div className="d-flex flex-column align-items-center py-5 px-2 splash-screen vh-10">
      <img
        src="https://storybook.talentech.io/images/svg/notifications/information.svg"
        width="170"
        alt=""
      />
      <h1 className="text-center">
        <b>{t('access-denied-heading')}</b>
      </h1>
      <br />
      <span className="text-center mb-5">{t('access-denied-text')}</span>
      <br />
      <Button
        label="GO TO TALENTECH START PAGE"
        color="primary"
        onClick={() => {
          window.location = process.env.REACT_APP_TID_START_PAGE;
        }}
      />
      <p className="text-center mt-5">
        {t('loginpage-clear-credentials-text')}{' '}
        <span className={css(styles.credentialLink)} onClick={() => dispatch(logoutUser())}>
          {t('loginpage-clear-credentials-link')}
        </span>
      </p>
    </div>
  );
};

export default AccessDeniedPage;

const styles = StyleSheet.create({
  credentialLink: {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
});

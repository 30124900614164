import { useDispatch, useSelector } from 'react-redux';
import { getExternalPositions } from '../../irecommend-lib/actions/positionActions';

const useFetchExternalPositions = () => {
  const { isLoading: isExternalPositionsLoading, response: externalPositions } = useSelector(
    (state) => state.positionState.externalPositions,
  );
  const dispatch = useDispatch();

  const onPressFetchJobs = () => dispatch(getExternalPositions());

  return { externalPositions, isExternalPositionsLoading, onPressFetchJobs };
};
export default useFetchExternalPositions;

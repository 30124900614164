import { CALL_API } from '../middleware/api';
import { getCredentials } from '../../api';

/**
 * GET all customers
 */
export const GET_CUSTOMERS_REQUEST = 'GET_CUSTOMERS_REQUEST';
export const GET_CUSTOMERS_SUCCESS = 'GET_CUSTOMERS_SUCCESS';
export const GET_CUSTOMERS_FAILURE = 'GET_CUSTOMERS_FAILURE';
export const GET_CUSTOMERS_INVALIDATE = 'GET_CUSTOMERS_INVALIDATE';

export function getCustomers() {
  return {
    [CALL_API]: {
      endpoint: `customers`,
      authenticated: true,
      types: [GET_CUSTOMERS_REQUEST, GET_CUSTOMERS_SUCCESS, GET_CUSTOMERS_FAILURE],
      method: 'GET',
    },
  };
}

/**
 * GET all customers
 */
export const GET_USER_CUSTOMER_REQUEST = 'GET_USER_CUSTOMER_REQUEST';
export const GET_USER_CUSTOMER_SUCCESS = 'GET_USER_CUSTOMER_SUCCESS';
export const GET_USER_CUSTOMER_FAILURE = 'GET_USER_CUSTOMER_FAILURE';
export const GET_USER_CUSTOMER_INVALIDATE = 'GET_USER_CUSTOMER_INVALIDATE';

export const getUserCustomer = () => async (dispatch) => {
  let credentials = await getCredentials();
  const user = credentials.idTokenPayload.sub;

  dispatch({
    [CALL_API]: {
      endpoint: `customers/single/${user}`,
      authenticated: true,
      types: [GET_USER_CUSTOMER_REQUEST, GET_USER_CUSTOMER_SUCCESS, GET_USER_CUSTOMER_FAILURE],
      method: 'GET',
    },
  });
};

/**
 *  Invalidate customers content to make sure it get fetched again when the view appears
 */
export const getCustomersInvalidate = () => {
  return {
    type: GET_CUSTOMERS_INVALIDATE,
    payload: {},
  };
};

/**
 *  Only dispatches getCustomers if data is invalidated or empty
 */
const shouldGetCustomers = (state) => {
  const customers = state.customerState;

  if (!customers.response || customers.response.length === 0) {
    return true;
  }

  return customers.didInvalidate;
};

export const getCustomersIfNeeded = () => (dispatch, getState) => {
  if (shouldGetCustomers(getState())) {
    return dispatch(getCustomers());
  }
};

export const UPDATE_CUSTOMER_FOR_USER_REQUEST = 'UPDATE_CUSTOMER_FOR_USER_REQUEST';
export const UPDATE_CUSTOMER_FOR_USER_SUCCESS = 'UPDATE_CUSTOMER_FOR_USER_SUCCESS';
export const UPDATE_CUSTOMER_FOR_USER_FAILURE = 'UPDATE_CUSTOMER_FOR_USER_FAILURE';

export const updateCustomerForUser = (data) => async (dispatch) => {
  let credentials = await getCredentials();

  const user = credentials.idTokenPayload.sub;

  dispatch({
    [CALL_API]: {
      endpoint: `users/customer/${user}?customer=${data.customer}`,
      authenticated: true,
      types: [
        UPDATE_CUSTOMER_FOR_USER_REQUEST,
        UPDATE_CUSTOMER_FOR_USER_SUCCESS,
        UPDATE_CUSTOMER_FOR_USER_FAILURE,
      ],
      method: 'POST',
    },
  });
};

/**
 * GET customer categories
 */
export const GET_CUSTOMER_CATEGORIES_REQUEST = 'GET_CUSTOMER_CATEGORIES_REQUEST';
export const GET_CUSTOMER_CATEGORIES_SUCCESS = 'GET_CUSTOMER_CATEGORIES_SUCCESS';
export const GET_CUSTOMER_CATEGORIES_FAILURE = 'GET_CUSTOMER_CATEGORIES_FAILURE';

export const getCustomerCategories = () => {
  return {
    [CALL_API]: {
      endpoint: `categories`,
      authenticated: true,
      types: [
        GET_CUSTOMER_CATEGORIES_REQUEST,
        GET_CUSTOMER_CATEGORIES_SUCCESS,
        GET_CUSTOMER_CATEGORIES_FAILURE,
      ],
    },
  };
};

/**
 * CREATE customer categories
 */
export const POST_CUSTOMER_CATEGORIES_REQUEST = 'POST_CUSTOMER_CATEGORIES_REQUEST';
export const POST_CUSTOMER_CATEGORIES_SUCCESS = 'POST_CUSTOMER_CATEGORIES_SUCCESS';
export const POST_CUSTOMER_CATEGORIES_FAILURE = 'POST_CUSTOMER_CATEGORIES_FAILURE';

export const postCustomerCategories = (data) => {
  return {
    [CALL_API]: {
      endpoint: `categories`,
      authenticated: true,
      types: [
        POST_CUSTOMER_CATEGORIES_REQUEST,
        POST_CUSTOMER_CATEGORIES_SUCCESS,
        POST_CUSTOMER_CATEGORIES_FAILURE,
      ],
      method: 'POST',
      data: data,
      sendJSON: true,
    },
  };
};

/**
 * UPDATE customer categories
 */
export const UPDATE_CUSTOMER_CATEGORIES_REQUEST = 'UPDATE_CUSTOMER_CATEGORIES_REQUEST';
export const UPDATE_CUSTOMER_CATEGORIES_SUCCESS = 'UPDATE_CUSTOMER_CATEGORIES_SUCCESS';
export const UPDATE_CUSTOMER_CATEGORIES_FAILURE = 'UPDATE_CUSTOMER_CATEGORIES_FAILURE';

export const updateCustomerCategories = (id, data) => {
  return {
    [CALL_API]: {
      endpoint: `categories/${id}`,
      authenticated: true,
      types: [
        UPDATE_CUSTOMER_CATEGORIES_REQUEST,
        UPDATE_CUSTOMER_CATEGORIES_SUCCESS,
        UPDATE_CUSTOMER_CATEGORIES_FAILURE,
      ],
      method: 'PUT',
      data: data,
      sendJSON: true,
    },
  };
};

/**
 * DELETE customer categories
 */
export const DELETE_CUSTOMER_CATEGORIES_REQUEST = 'DELETE_CUSTOMER_CATEGORIES_REQUEST';
export const DELETE_CUSTOMER_CATEGORIES_SUCCESS = 'DELETE_CUSTOMER_CATEGORIES_SUCCESS';
export const DELETE_CUSTOMER_CATEGORIES_FAILURE = 'DELETE_CUSTOMER_CATEGORIES_FAILURE';

export const deleteCustomerCategories = (id) => {
  return {
    [CALL_API]: {
      endpoint: `categories/${id}`,
      authenticated: true,
      types: [
        DELETE_CUSTOMER_CATEGORIES_REQUEST,
        DELETE_CUSTOMER_CATEGORIES_SUCCESS,
        DELETE_CUSTOMER_CATEGORIES_FAILURE,
      ],
      method: 'DELETE',
    },
  };
};

import { applyMiddleware, compose, combineReducers, legacy_createStore } from 'redux';

import thunk from 'redux-thunk';

import api from './irecommend-lib/middleware/api';

import reducers from './irecommend-lib/reducers';
import BIDashboardReducer from './bi-dashboard/reducers/index';
import { loadUser } from 'redux-oidc';
import userManager from './config/tidUserManager';

export default function configureStore(initialState = {}) {
  const enhancer = compose(
    applyMiddleware(api, thunk),
    // window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f,
    // autoRehydrate(),
  );

  const rootReducer = combineReducers({
    ...reducers,
    BIDashboardState: BIDashboardReducer,
  });

  const store = legacy_createStore(rootReducer, initialState, enhancer);

  // TODO: Can be used when migrating to redux-toolkit
  // const store = configureReduxStore({
  //   // Automatically calls `combineReducers`
  //   reducer: {
  //     ...reducers,
  //     BIDashboardState: BIDashboardReducer,
  //   },
  //   middleware: (getDefaultMiddleware) =>
  //     getDefaultMiddleware({
  //       serializableCheck: {
  //         ignoredPaths: ['oidc.user', ...Object.keys(reducers)],
  //         ignoredActions: ['redux-oidc/USER_FOUND'],
  //       },
  //     }).concat(api),
  // });
  loadUser(store, userManager);

  return store;
}

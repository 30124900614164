import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Variables, Color } from '../../config/styles';

export default class AdminWrapper extends Component {
  static propTypes = {
    /*
     * What ever wanted to be wrapped. Used as HTML <main>
     */
    children: PropTypes.node.isRequired,
    /*
     * Custom styling
     */
    style: PropTypes.object,
    disableInnerWrap: PropTypes.bool,
  };

  static defaultProps = {
    disableInnerWrap: false,
  };

  render() {
    const { children, style, disableInnerWrap } = this.props;
    const innerClassName = disableInnerWrap ? null : css(styles.inner);

    return (
      <main role="main" className={css(style, styles.container)}>
        <div className="admin-wrap">
          <div className={innerClassName}>{children}</div>
        </div>
      </main>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    minHeight: Variables.MainWrapperHeight,
    position: 'relative',
    backgroundColor: Color.baseGray,
    width: '60vw',
    margin: '0 auto',
    '@media (max-width: 1920px)': {
      width: '80vw',
    },
    '@media (max-width: 1440px)': {
      width: '80vw',
    },
  },
  inner: {
    maxWidth: '100%',
    padding: '2em',
    position: 'relative',
    zIndex: 2,
  },
});

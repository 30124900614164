import { css, StyleSheet } from 'aphrodite';

import { Box } from '@talentech/components';
import { useTranslation } from 'react-i18next';

const CategoryFormFields = (props) => {
  const { state, setState } = props;
  const { t } = useTranslation();

  return (
    <>
      <Box className="row mt-0">
        <Box className="col">
          {state.updateCategoryForm ? (
            ''
          ) : state.deleteCategoryForm ? (
            <>
              <p className={css(styles.text)}>{t('settings-category-delete-text-first')}</p>
              <p className={css(styles.text)}>{t('settings-category-delete-text-second')}</p>
            </>
          ) : (
            <p className={css(styles.text)}>{t('settings-category-new-description')}</p>
          )}
          {!state.deleteCategoryForm && (
            <>
              <label className={css(styles.label)} htmlFor="name">
                {t('generic-text-name').toUpperCase()}
              </label>
              <input
                type="text"
                className="form-control"
                onChange={(e) =>
                  setState({ ...state, category: { ...state.category, name: e.target.value } })
                }
                placeholder={t('settings-category-type-here')}
                id="name"
                value={state.category.name}
              />
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default CategoryFormFields;

const styles = StyleSheet.create({
  required: {
    color: 'red',
  },
  text: {
    marginBottom: '1.5rem',
    fontSize: '.95rem',
  },
  label: {
    fontSize: '.9rem',
    fontWeight: '600',
    marginBottom: '.5rem',
  },
});

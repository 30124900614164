import { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  getSinglePushNotification,
  updatePushNotification,
} from '../../irecommend-lib/actions/communicationActions';

const useUpdatePushNotification = (id, setIsEditPush) => {
  const { customPushNotification } = useSelector((state) => state.communicationState, shallowEqual);
  const isLoading = customPushNotification.isLoading;
  const dispatch = useDispatch();

  useEffect(() => {
    if (Object.keys(customPushNotification.response).length > 0) {
      dispatch(getSinglePushNotification(id));
      setIsEditPush(false);
    }
  }, [customPushNotification.response, id, setIsEditPush, dispatch]);

  const updatePush = (data) => {
    dispatch(updatePushNotification(id, data));
  };

  return { updatePush, updatePushIsLoading: isLoading };
};

export default useUpdatePushNotification;

import React from 'react';

import { StyleSheet } from 'aphrodite';
import { useHistory } from 'react-router-dom';
import { Icon } from '@talentech/components';

import { TALENTECH_ICONS } from '../Icons';
import Card from './Card';
import { useTranslation } from 'react-i18next';

const FetchedCardItem = (props) => {
  const { position, onDeleteExternalPosition } = props;
  const history = useHistory();
  const { t } = useTranslation();

  const actions = [
    {
      icon: <Icon iconSize="small" icon={TALENTECH_ICONS.editPencil} />,
      label: t('positionpage-edit-publish'),
      onClick: (e) => {
        e.stopPropagation();
        e.preventDefault();
        onEditPress(position.ID);
      },
    },
    {
      icon: <Icon iconSize="small" icon={TALENTECH_ICONS.delete} />,
      label: t('generic-text-remove'),
      onClick: (e) => {
        e.stopPropagation();
        onDeleteExternalPosition(position.ID);
      },
    },
  ];

  const onEditPress = (id) => history.push(`/admin/jobs/publish-external/${id}`);

  return (
    <Card
      navigateTo={`/admin/jobs/publish-external/${position.ID}`}
      actions={actions}
      title={position.title}
      badgeColor="secondary"
      badgeLabel={t('positionpage-draft')}
      badgeSize="md"
      style={styles.secondParentCardStyle}
      showStats={false}
    />
  );
};

export default FetchedCardItem;

const styles = StyleSheet.create({
  secondParentCardStyle: {
    minHeight: '78px',
  },
});

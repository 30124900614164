import React, { Component } from 'react';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { calculateTeamPointsHandler } from './CalculateTeamPointsHandler';

import UserPanel from './Leaderboard/UserLeaderboard/UserPanel';
import TeamPanel from './Leaderboard/TeamLeaderboard/TeamPanel';
import { withTranslation } from 'react-i18next';

class Leaderboard extends Component {
  render() {
    const { t, user, team } = this.props;
    let teamData = calculateTeamPointsHandler(user, team);

    // Saving the number of teamMembers from teamData into each object in the array team
    // Sets totalMembers to 0 if teamData has not yet been loaded.
    for (let i = 0; i < team.length; i++) {
      team[i].totalMembers = teamData.length !== 0 ? teamData[i].totalMembers : 0;
    }

    return (
      <div className="Leaderboard">
        <h2 className="highscore-title">{t('highscorepage-title')}</h2>
        <Tabs>
          <TabList>
            <Tab>{t('leaderboard-tab')}</Tab>
            <Tab>{t('leaderboard-tab-team')}</Tab>
          </TabList>
          <div className="leaderboard-content" style={{ borderRadius: 3 }}>
            <TabPanel>
              <UserPanel data={user} />
            </TabPanel>
            <TabPanel>
              <TeamPanel data={team} />
            </TabPanel>
          </div>
        </Tabs>
      </div>
    );
  }
}

export default withTranslation()(Leaderboard);

import { Component } from 'react';
import { Editor, RichUtils } from 'draft-js';
import { css, StyleSheet } from 'aphrodite';
import { Color } from '../../irecommend-lib/styles';

import DraftFormControlButton from './DraftFormControlButton';

class DraftForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blockStyles: [
        { type: 'unordered-list-item', label: 'Bullet list', clicked: false },
        { type: 'ordered-list-item', label: 'Ordered list', clicked: false },
        { type: 'header-one', label: 'H1', clicked: false },
        { type: 'header-two', label: 'H2', clicked: false },
        { type: 'header-three', label: 'H3', clicked: false },
      ],
      inlineStyles: [
        { type: 'UNDERLINE', label: 'U', clicked: false },
        { type: 'BOLD', label: 'B', clicked: false },
        { type: 'ITALIC', label: 'I', clicked: false },
      ],
    };
  }

  onChange = (editorState) => {
    const inlineStyle = editorState.getCurrentInlineStyle();
    if (inlineStyle.size > 0) {
      this.setCurrentInlineStyles(inlineStyle);
    } else {
      this.removeClickedInlineStyles();
    }

    this.props.change(editorState);
  };

  setCurrentInlineStyles(styles) {
    styles.has('BOLD') ? this.setClicked('BOLD', true) : this.setClicked('BOLD', false);
    styles.has('ITALIC') ? this.setClicked('ITALIC', true) : this.setClicked('ITALIC', false);
    styles.has('UNDERLINE')
      ? this.setClicked('UNDERLINE', true)
      : this.setClicked('UNDERLINE', false);
  }

  removeClickedInlineStyles() {
    let inline = this.state.inlineStyles;
    let newLine = inline.map((o) => Object.assign(o, { clicked: (o.clicked = false) }));
    this.setState({
      inlineStyles: newLine,
    });
  }

  handleKeyCommand = (command) => {
    const newState = RichUtils.handleKeyCommand(this.props.editorState, command);
    if (newState) {
      this.onChange(newState);
      return 'handled';
    }
    return 'not handled';
  };

  _onInlineStyleClick = (e, elem) => {
    e.preventDefault();
    this.setClicked(elem.name, !elem.clicked);
    this.onChange(RichUtils.toggleInlineStyle(this.props.editorState, elem.name));
  };

  setClicked = (type, clicked) => {
    let inline = this.state.inlineStyles;

    let st = inline.find((s) => s.type === type);
    st.clicked = clicked;

    const index = inline.findIndex((s) => s.type === type);
    inline[index] = st;
    this.setState({
      inlineStyles: inline,
    });
  };

  _onBlockStyleClick = (e, elem) => {
    e.preventDefault();
    let block = this.state.blockStyles;

    let bl = block.find((s) => s.type === elem.name);
    bl.clicked = !bl.clicked;

    const index = block.findIndex((s) => s.type === elem.name);
    block[index] = bl;
    this.setState({
      blockStyles: block,
    });

    this.onChange(RichUtils.toggleBlockType(this.props.editorState, elem.name));
  };

  render() {
    const { inlineStyles, blockStyles } = this.state;
    const { readOnly, placeholder, toolboxHidden } = this.props;

    const inlineStyleButtons = inlineStyles.map((style, index) => {
      return (
        <DraftFormControlButton
          changeInline={this._onInlineStyleClick}
          name={style.type}
          label={style.label}
          key={index}
          clicked={style.clicked}
        />
      );
    });

    const blockStyleButtons = blockStyles.map((style, index) => {
      return (
        <DraftFormControlButton
          changeInline={this._onBlockStyleClick}
          name={style.type}
          label={style.label}
          key={index}
          clicked={style.clicked}
        />
      );
    });

    const controlButtons = (
      <>
        {!toolboxHidden && (
          <div className={css(styles.styleButtonsWrapper)}>
            {inlineStyleButtons}
            {blockStyleButtons}
          </div>
        )}
      </>
    );

    return (
      <div>
        {!readOnly && controlButtons}
        <div className={css(styles.editors, toolboxHidden && styles.editorNoToolbox)}>
          <Editor
            editorState={this.props.editorState}
            handleKeyCommand={this.handleKeyCommand}
            onChange={this.onChange}
            readOnly={readOnly ? readOnly : false}
            placeholder={placeholder ? placeholder : ''}
          />
        </div>
      </div>
    );
  }
}

export default DraftForm;

const styles = StyleSheet.create({
  editors: {
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '5px',
    padding: '15px',
    backgroundColor: Color.white,
    maxWidth: '100%',
    boxShadow: '0 0.125rem 0.125rem rgba(21,21,23,.24)',
  },
  editorNoToolbox: {
    boxShadow: 'rgba(21, 21, 23, 0.24) 0px 0px 0.125rem !important',
  },
  p: {
    whiteSpace: 'pre-wrap',
  },
  styleButtonsWrapper: {
    backgroundColor: Color.baseGray,
    padding: '0.8rem',
  },
});

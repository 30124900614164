import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Color } from '../../config/styles';
import { css, StyleSheet } from 'aphrodite';
import Button from '../Button';
import ProfilePicture from '../ProfilePicture';

class CompetitionItem extends Component {
  static propTypes = {
    /**
     * Title of competition
     */
    name: PropTypes.string.isRequired,
    /**
     * Image for competition
     */
    picture: PropTypes.string,
    /**
     * End date for competition
     */
    endDate: PropTypes.string.isRequired,
    /**
     * Start date for competition
     */
    startDate: PropTypes.string.isRequired,
    /**
     * Function for deleting a competition
     */
    onPressDelete: PropTypes.func.isRequired,
    /**
     * Function for editing a competition
     */
    onPressEdit: PropTypes.func.isRequired,
    /**
     * Function for accessing competition details
     */
    onPressCompetitionDetails: PropTypes.func.isRequired,
  };

  render() {
    const {
      id,
      name,
      picture,
      startDate,
      endDate,
      t,
      onPressDelete,
      onPressEdit,
      onPressCompetitionDetails,
    } = this.props;

    const competitionStarted = Math.round((Date.parse(startDate) - new Date()) / 86400000) <= 0;
    const competitionFinished = Math.round(new Date() - Date.parse(endDate)) > 0;
    const endDateFormatted = endDate ? this.formatDate(new Date(Date.parse(endDate))) : '';
    const startDateFormatted = startDate ? this.formatDate(new Date(Date.parse(startDate))) : '';
    const daysLeft =
      competitionStarted && !competitionFinished
        ? Math.round((Date.parse(endDate) - new Date()) / 86400000)
        : '';

    // Lists days left, that competition is not started, or that competition has finished.
    let status;

    if (competitionFinished) {
      status = (
        <div className={css(styles.goalItemCounter, styles.listSection, styles.columnStatus)}>
          <span className={css(styles.listItemSubtitle)}>{t('competition-finished')}</span>
        </div>
      );
    } else if (!competitionStarted) {
      status = (
        <div className={css(styles.goalItemCounter, styles.listSection, styles.columnStatus)}>
          <span className={css(styles.listItemSubtitle)}>{t('competition-not-started')}</span>
        </div>
      );
    } else {
      status = (
        <div className={css(styles.goalItemCounter, styles.listSection, styles.columnStatus)}>
          <em className={css(styles.counter, styles.counterBlue)}>{daysLeft}</em>
          <span className={css(styles.listItemSubtitle)}>{t('competition-days-left')}</span>
        </div>
      );
    }

    return (
      <li className={css(styles.competitionItem)}>
        <div className={css(styles.listItem)}>
          <div className={css(styles.listSection)}>
            <ProfilePicture name={name} picture={picture} style={styles.picture} />
          </div>
          <a className={css([styles.listSection, styles.columnName])}>
            <h2 onClick={() => onPressCompetitionDetails(id)} className={css(styles.h2)}>
              {name}
            </h2>
          </a>
          {status}
          <div className={css([styles.text, styles.listSection, styles.columnStartDate])}>
            <span className={css(styles.listItemSubtitle)}>
              {t('competition-text-start-date')}:
            </span>
            <span className={css(styles.endDate)}>{startDateFormatted}</span>
          </div>
          <div className={css([styles.text, styles.listSection, styles.columnEndDate])}>
            <span className={css(styles.listItemSubtitle)}>{t('competition-text-end-date')}:</span>
            <span className={css(styles.endDate)}>{endDateFormatted}</span>
          </div>
          <div className={css([styles.listSection, styles.columnButtons])}>
            <div className={css(styles.btnHolder)}>
              <Button type="secondary" title={t('generic-text-remove')} onPress={onPressDelete} />
            </div>
            <div className={css(styles.btnHolder)}>
              <Button type="secondary" title={t('generic-text-edit')} onPress={onPressEdit} />
            </div>
          </div>
        </div>
      </li>
    );
  }

  formatDate = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();

    return `${year}-${month}-${day}`;
  };
}

export default CompetitionItem;

const styles = StyleSheet.create({
  listItem: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  competitionItem: {
    backgroundColor: Color.white,
    boxShadow: '0 5px 17px 0 rgba(0, 0, 0, .03)',
  },
  text: {
    flex: '0 1 calc(100% - 40px - .75em)',
  },
  listSection: {
    padding: '0 1em',

    ':first-child': {
      padding: '1em 1.5em',
      justifyContent: 'flex-start',
    },
  },
  title: {
    margin: '0 0 .2em',
    marginRight: '50px',
    fontSize: '1.3em',
    fontWeight: 'bold',
    color: Color.primary,
  },
  columnName: {
    flex: '2.5',
    minWidth: 0,
  },
  h2: {
    fontSize: '1.0625rem',
    fontWeight: '600',
    lineHeight: '1.352941176',
    cursor: 'pointer',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  btnHolder: {
    display: 'inline-block',
    float: 'right',
    width: '41%',
    margin: '-0.5em 0.5em 0 0.5em',
  },
  sectionValue: {
    fontSize: '.95em',
    lineHeight: '1.6',
  },
  sectionTitle: {
    fontSize: '.85em',
    textAlign: 'start',
    lineHeight: '1.2',
    fontWeight: 'bold',
  },
  blue: {
    color: Color.clearBlue,
  },
  endDate: {
    display: 'block',
    fontStyle: 'normal',
    fontWeight: 600,
    textAlign: 'center',
  },
  listItemSubtitle: {
    color: Color.dark,
    letterSpacing: '.045454545em',
    fontSize: '.6875rem',
    lineHeight: 2,
    textTransform: 'uppercase',
    fontWeight: 600,
    display: 'block',
    textAlign: 'center',
  },
  counter: {
    fontStyle: 'normal',
    fontWeight: 600,
    display: 'block',
  },
  counterBig: {
    fontSize: '1.0625rem',
    fontStyle: 'normal',
    fontWeight: 600,
  },
  counterBlue: {
    color: Color.primary,
  },
  counterGreen: {
    color: Color.success,
  },
  goalItemCounter: {
    flexDirection: 'column',
    textAlign: 'center',
  },
  columnStartDate: {
    flex: '1',
  },
  columnEndDate: {
    flex: '1',
  },
  columnButtons: {
    flex: '2.5',
  },
  columnStatus: {
    flex: '1',
  },
});

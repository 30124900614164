import React, { Component } from 'react';

import { connect } from 'react-redux';

import { getGoalsIfNeeded } from '../irecommend-lib/actions/goalActions';
import {
  getTransactionsSumTeamsIfNeeded,
  getTransactionsSumUsersIfNeeded,
} from '../irecommend-lib/actions/transactionActions';
import { getUserIfNeeded } from '../irecommend-lib/actions/userActions';

import HighscorePage from '../pages/HighscorePage';
import { IntlNetwork } from '../helpers/language';
import { withTranslation } from 'react-i18next';

class HighscoreContainer extends Component {
  constructor(props) {
    super(props);

    const { user } = this.props;

    this.state = {
      tab: `tab-${user.customertype !== 'Network' ? 1 : 3}`, // Individual highscore
    };
  }

  /**
   * Get the highscore list on viewWillAppear. Update if necessary.
   * @param {object} event
   */
  componentDidMount() {
    this.props.getGoalsIfNeeded();
    this.props.getUserIfNeeded();
    this.props.getTransactionsSumUsersIfNeeded();
    this.props.getTransactionsSumTeamsIfNeeded();

    const tab = `tab-${this.props.user.response.customertype !== 'Network' ? 1 : 3}`;

    this.setState({ tab });
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;

    if (user.response.customertype !== prevProps.user.response.customertype) {
      this.setState({
        tab: `tab-${user.response.customertype !== 'Network' ? 1 : 3}`,
      });
    }
  }

  render() {
    const goalsData = this.props.goals.response;
    const user = this.props.user.response;
    const isLoading =
      this.props.user.isLoading ||
      this.props.highscore.individual.isLoading ||
      this.props.highscore.team.isLoading;
    const data = isLoading ? [] : this.getData();
    const isGoal = this.state.tab === 'tab-3';
    const pointsCurrent = this.props.goals.response.totalpoints
      ? this.props.goals.response.totalpoints.Int64
      : 0;

    // let pointsGoal = 0;

    // // Used to calculate percentage of total points
    // if (goalsData && goalsData.goals && goalsData.goals.length > 0) {
    // 	// Remove the first item if it's the goal tip
    // 	// Calulate the sum for all goals
    // 	pointsGoal = goalsData.goals.filter(goal => !goal.tip).reduce((accumulator, goal) => accumulator + +goal.points, 0);
    // }

    let pointsGoal = 0;

    // Used to calculate percentage of total points
    if (goalsData && goalsData.goals && goalsData.goals.length > 0) {
      // Remove the first item if it's the goal tip
      // Get the highest goal points

      pointsGoal = Math.max.apply(
        Math,
        goalsData.goals.filter((goal) => !goal.tip).map((g) => +g.points),
      );
    }

    return (
      <HighscorePage
        data={data}
        isGoal={isGoal}
        isLoading={isLoading}
        onChangeTab={this.onChangeTab}
        onPressInfoButton={this.onPressInfoButton}
        onPressListItem={() => {
          return;
        }}
        onPressProfileLink={() => {
          return;
        }}
        pointsCurrent={pointsCurrent}
        pointsGoal={pointsGoal}
        user={user}
      />
    );
  }

  getData = () => {
    if (this.state.tab === 'tab-3') {
      // Add the goal tips form as first item in the list
      let goals = this.props.goals.response.goals ? this.props.goals.response.goals : [];
      const totalPoints = this.props.goals.response.Int64;

      if (goals.length > 0 /*&& !goals[0].tip*/) {
        // Used to set the color of the progress bar
        for (let goal of goals) {
          goal.achieved = totalPoints >= +goal.points;
        }

        // goals.unshift({
        // 	idgoal: 'tiplistitem',
        // 	tip: true,
        // 	onPressSubmitForm: this.onPressSubmitForm
        // });
      }

      return goals;
    }

    const user = this.props.user.response;
    const isIndividual = this.state.tab === 'tab-1';
    const d = isIndividual
      ? this.props.highscore.individual.response
      : this.props.highscore.team.response;

    // Prepare data
    const data = (!d || d.constructor !== Array ? [] : d).map((item, key) => {
      // Individual
      if (isIndividual) {
        return {
          id: item.user.iduser,
          level: item.user.level,
          name: item.user.givenname + ' ' + item.user.familyname,
          number: key + 1,
          picture: item.user.picture,
          points: item.points.Int64,
          selected: user.user.iduser === item.user.iduser,
        };
      }

      // Team
      return {
        id: item.team.idteam,
        level: null,
        name: item.team.name,
        number: key + 1,
        picture: item.team.picture,
        points: item.points.Int64,
        selected: false,
      };
    });

    return data;
  };

  /**
   * Show Alert with info about the highscore list
   */
  onPressInfoButton = (e) => {
    e.preventDefault();
    const { t } = this.props;

    alert(IntlNetwork(t, 'highscorecontainer-alert-info-message'));
  };

  /**
   * User submitted goal tip form
   */
  onPressSubmitForm = (goal) => {};

  /**
   * Load new data when active tab changes
   */
  onChangeTab = (tab) => {
    this.setState({
      tab: tab.key,
    });
  };
}

function mapStateToProps(state) {
  return {
    goals: state.goalState.goals,
    user: state.userState.user,
    highscore: {
      individual: state.transactionState.sumUsers,
      team: state.transactionState.sumTeams,
    },
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getGoalsIfNeeded: () => dispatch(getGoalsIfNeeded()),
    getUserIfNeeded: () => dispatch(getUserIfNeeded()),
    getTransactionsSumTeamsIfNeeded: () => dispatch(getTransactionsSumTeamsIfNeeded()),
    getTransactionsSumUsersIfNeeded: () => dispatch(getTransactionsSumUsersIfNeeded()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(HighscoreContainer));

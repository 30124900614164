import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getRecommendation } from '../irecommend-lib/actions/recommendationActions';
import { postReferralDocument } from '../irecommend-lib/actions/referralActions';

import InterestedSupplementPage from '../pages/InterestedSupplementPage';
import { withTranslation } from 'react-i18next';

class InterestedSupplementContainer extends Component {
  componentDidMount() {
    if (!this.props.recommendation.response.referral) {
      this.props.getRecommendation(this.props.match.params.id);
    }
  }

  /**
   * Send users to the right place after updating referral
   */
  componentDidUpdate(prevProps) {
    const { referralDocument } = this.props;

    if (
      referralDocument.isLoading !== prevProps.referralDocument.isLoading &&
      !referralDocument.isLoading
    ) {
      if (referralDocument.error) {
        const { t } = this.props;
        alert(t('generic-error-default-fileformat'));

        return;
      }

      this.props.history.push(`/recommended/${this.props.match.params.id}/interested/confirmation`);
    }
  }

  render() {
    const isLoading = this.props.referralDocument.isLoading || this.props.recommendation.isLoading;
    const company = this.props.recommendation.response.companyname;

    return (
      <InterestedSupplementPage
        isLoading={isLoading}
        onPressSubmit={this.onPressSubmit}
        company={company}
      />
    );
  }

  /**
   * Submit résumé and comment
   */
  onPressSubmit = (currentState) => {
    const { t } = this.props;
    if (!this.props.recommendation.response.referral) {
      alert(t('generic-error-default'));

      return;
    }

    const idreferral = this.props.recommendation.response.referral.idreferral;

    this.props.postReferralDocument(idreferral, currentState);
  };
}

function mapStateToProps(state) {
  return {
    recommendation: state.recommendationState.recommendation,
    referralDocument: state.referralState.referralDocument,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getRecommendation: (recommendation) => dispatch(getRecommendation(recommendation)),
    postReferralDocument: (id, data) => dispatch(postReferralDocument(id, data)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(InterestedSupplementContainer));

import {
  GET_COMMENTS_REQUEST,
  GET_COMMENTS_SUCCESS,
  GET_COMMENTS_FAILURE,
  POST_COMMENT_REQUEST,
  POST_COMMENT_SUCCESS,
  POST_COMMENT_FAILURE,
  DELETE_COMMENT_REQUEST,
  DELETE_COMMENT_SUCCESS,
  DELETE_COMMENT_FAILURE,
} from '../actions/commentsActions.js';

const initialState = {
  comments: {
    didInvalidate: false,
    error: false,
    isLoading: false,
    response: [],
  },
  commentPost: {
    didInvalidate: false,
    error: false,
    isLoading: false,
    response: {},
  },
  commentDelete: {
    didInvalidate: false,
    error: false,
    isLoading: false,
    response: {},
  },
};

export default function commentsState(
  state = initialState,
  { authenticated, error, response, type, request },
) {
  switch (type) {
    case GET_COMMENTS_REQUEST:
      return {
        ...state,
        comments: {
          ...state.comments,
          isLoading: true,
        },
      };
    case GET_COMMENTS_SUCCESS:
      return {
        ...state,
        comments: {
          ...state.comments,
          isLoading: false,
          response: response,
        },
      };
    case GET_COMMENTS_FAILURE:
      return {
        ...state,
        comments: {
          ...state.comments,
          isLoading: false,
          error: true,
          response: error,
        },
      };
    case POST_COMMENT_REQUEST:
      return {
        ...state,
        commentPost: {
          ...state.commentPost,
          isLoading: true,
        },
      };
    case POST_COMMENT_SUCCESS:
      return {
        ...state,
        commentPost: {
          ...state.commentPost,
          isLoading: false,
          response: response,
        },
      };
    case POST_COMMENT_FAILURE:
      return {
        ...state,
        commentPost: {
          ...state.commentPost,
          isLoading: false,
          error: true,
          response: error,
        },
      };
    case DELETE_COMMENT_REQUEST:
      return {
        ...state,
        commentDelete: {
          ...state.commentDelete,
          isLoading: true,
        },
      };
    case DELETE_COMMENT_SUCCESS:
      return {
        ...state,
        commentDelete: {
          ...state.commentDelete,
          isLoading: false,
          response: response,
        },
      };
    case DELETE_COMMENT_FAILURE:
      return {
        ...state,
        commentDelete: {
          ...state.commentDelete,
          isLoading: false,
          error: true,
          response: error,
        },
      };
    default:
      return state;
  }
}

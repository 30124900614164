import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getCompetition } from '../../irecommend-lib/actions/competitionActions';
import {
  getTransactionsSumTeamsIfNeeded,
  getTransactionsSumUsersIfNeeded,
} from '../../irecommend-lib/actions/transactionActions';
import { getGoalsIfNeeded } from '../../irecommend-lib/actions/goalActions';

import CompetitionDetailPage from '../../pages/admin/CompetitionDetailPage';
import { getUser } from '../../irecommend-lib/actions/userActions';
import { withTranslation } from 'react-i18next';

class CompetitionContainer extends Component {
  componentDidMount() {
    this.props.getUser();
    if (this.props.match.params.id) {
      this.props.getGoalsIfNeeded();
      this.props.getCompetition(this.props.match.params.id);
      this.props.getTransactionsSumUsersIfNeeded();
      this.props.getTransactionsSumTeamsIfNeeded();
    }
  }

  render() {
    const competition = this.props.competition.response;
    const isLoading = this.props.competition.isLoading;
    const { t } = this.props;

    // To display if it is a team or individual competition.
    const leaderboardUsers = isLoading ? [] : this.getData();

    // To display if it is a company goal.
    const pointsCurrent = this.props.goals.response.totalpoints
      ? this.props.goals.response.totalpoints.Int64
      : 0;

    return (
      <div>
        <CompetitionDetailPage
          competition={competition}
          leaderboardUsers={leaderboardUsers}
          isLoading={isLoading}
          t={t}
          companyPoints={pointsCurrent}
        />
      </div>
    );
  }

  getData = () => {
    const isIndividual = this.props.competition.response.type === 1;
    let data = isIndividual
      ? this.props.highscore.individual.response
      : this.props.highscore.team.response;

    if (!data) {
      return [];
    }

    // Prepare data
    data = data.map((item, key) => {
      // Individual
      if (isIndividual) {
        return {
          id: item.user.iduser,
          level: item.user.level,
          name: item.user.givenname + ' ' + item.user.familyname,
          number: key + 1,
          picture: item.user.picture,
          points: item.points.Int64,
        };
      }

      // Team
      return {
        id: item.team.idteam,
        level: null,
        name: item.team.name,
        number: key + 1,
        picture: item.team.picture,
        points: item.points.Int64,
        selected: false,
      };
    });

    return data;
  };
}

function mapStateToProps(state) {
  return {
    competition: state.competitionState.competition,
    goals: state.goalState.goals,
    highscore: {
      individual: state.transactionState.sumUsers,
      team: state.transactionState.sumTeams,
    },
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getUser: () => dispatch(getUser()),
    getCompetition: (id) => dispatch(getCompetition(id)),
    getGoalsIfNeeded: () => dispatch(getGoalsIfNeeded()),
    getTransactionsSumTeamsIfNeeded: () => dispatch(getTransactionsSumTeamsIfNeeded()),
    getTransactionsSumUsersIfNeeded: () => dispatch(getTransactionsSumUsersIfNeeded()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(CompetitionContainer));

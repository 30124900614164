import React, { Component } from 'react';

export default class TaskCompleted extends Component {
  render() {
    return (
      <svg viewBox="0 0 79 78" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fill-rule="evenodd">
          <g id="taskCompleted" transform="translate(1.000000, 1.000000)">
            <path
              d="M38.5,66.0474138 C53.9584138,66.0474138 66.4899425,53.5158851 66.4899425,38.0574713 C66.4899425,22.5990575 53.9584138,10.0675287 38.5,10.0675287 C23.0415862,10.0675287 10.5100575,22.5990575 10.5100575,38.0574713 C10.5100575,53.5158851 23.0415862,66.0474138 38.5,66.0474138 Z"
              id="Path"
              fill="#D6D6E4"
              fill-rule="nonzero"
            ></path>
            <path
              d="M38.5,0.331896552 C31.0386115,0.331896552 23.7447644,2.54446057 17.540777,6.68980425 C11.3368782,10.8351391 6.50149057,16.7270552 3.64612701,23.6205023 C0.790772299,30.5139494 0.0436775862,38.0993345 1.49933161,45.4173437 C2.95497678,52.7354414 6.54799149,59.4574529 11.8240138,64.7334575 C17.1000184,70.0094621 23.8221184,73.6025299 31.1401276,75.0581839 C38.4581368,76.5138379 46.0435218,75.7667609 52.936969,72.9113885 C59.8304161,70.0560161 65.7223322,65.2205931 69.8676759,59.0166943 C74.0130195,52.8127069 76.2255747,45.5188598 76.2255747,38.0574713 C76.2255747,28.0519851 72.2509586,18.456369 65.1759862,11.3814851 C58.1011023,4.30654805 48.5054862,0.331896552 38.5,0.331896552 Z M38.5,70.9152299 C32.001377,70.9152299 25.6486115,68.9881943 20.2452471,65.3776908 C14.8417943,61.7672759 10.6303368,56.6355356 8.14341379,50.6315713 C5.65648195,44.6276069 5.0057877,38.0210069 6.27361483,31.6472655 C7.5414331,25.2734356 10.6708724,19.4187805 15.2660908,14.8235621 C19.8613092,10.2282552 25.7159644,7.09888667 32.0897943,5.83106839 C38.4635356,4.56324126 45.0701356,5.21393552 51.0741,7.70085851 C57.0780644,10.187808 62.2098046,14.3992655 65.8202195,19.8027184 C69.430723,25.2060828 71.3577586,31.5588483 71.3577586,38.0574713 C71.3577586,46.7719241 67.8960333,55.1294333 61.7339977,61.2913805 C55.5719621,67.4534161 47.2144529,70.9152299 38.5,70.9152299 Z"
              id="Shape"
              fill="#D6D6E4"
              fill-rule="nonzero"
            ></path>
            <polygon
              id="Path"
              fill="#26253E"
              fill-rule="nonzero"
              points="34.9172874 42.5431195 25.9130667 32.9304218 20.2456897 39.2062759 34.9172874 55.7033046 59.5837506 25.2915793 53.215408 19.5414494"
            ></polygon>
            <path
              d="M38.5,75.783046 C59.3352264,75.783046 76.2255747,58.8926977 76.2255747,38.0574713 C76.2255747,17.2222448 59.3352264,0.331896552 38.5,0.331896552 C17.6647736,0.331896552 0.774425287,17.2222448 0.774425287,38.0574713 C0.774425287,58.8926977 17.6647736,75.783046 38.5,75.783046 Z"
              id="Path"
              stroke="#26253E"
              strokeWidth="2"
            ></path>
            <path
              d="M38.5,70.9152299 C56.6468644,70.9152299 71.3577586,56.2043356 71.3577586,38.0574713 C71.3577586,19.9106069 56.6468644,5.19971264 38.5,5.19971264 C20.3531356,5.19971264 5.64224138,19.9106069 5.64224138,38.0574713 C5.64224138,56.2043356 20.3531356,70.9152299 38.5,70.9152299 Z"
              id="Path"
              stroke="#26253E"
              strokeWidth="2"
            ></path>
            <polygon
              id="Path"
              stroke="#26253E"
              strokeWidth="2"
              points="53.215408 19.5414494 34.9172874 42.5431195 25.9130667 32.9304218 20.2456897 39.2062759 34.9172874 55.7033046 59.5837506 25.2915793"
            ></polygon>
            <line
              x1="38.5"
              y1="11.2844828"
              x2="38.5"
              y2="13.7183908"
              id="Path"
              stroke="#26253E"
              strokeWidth="2"
            ></line>
            <line
              x1="11.7270115"
              y1="38.0574713"
              x2="14.1609195"
              y2="38.0574713"
              id="Path"
              stroke="#26253E"
              strokeWidth="2"
            ></line>
            <line
              x1="65.2729885"
              y1="38.0574713"
              x2="62.8390805"
              y2="38.0574713"
              id="Path"
              stroke="#26253E"
              strokeWidth="2"
            ></line>
            <line
              x1="38.5"
              y1="64.8304598"
              x2="38.5"
              y2="62.3965517"
              id="Path"
              stroke="#26253E"
              strokeWidth="2"
            ></line>
          </g>
        </g>
      </svg>
    );
  }
}

import { Box, Toast } from '@talentech/components';

const ToastMessage = (props) => {
  const { isShowToast, message, closeToast, type } = props;
  return (
    <Box className="d-flex justify-content-center w-75 position-fixed bottom-0 p-3 z-index-3">
      <Box className="w-75">
        <Toast
          showToast={isShowToast}
          onClose={() => closeToast(true)}
          small
          color={type ? type : 'info'}
        >
          {message}
        </Toast>
      </Box>
    </Box>
  );
};

export default ToastMessage;

import { CALL_API } from '../middleware/api';

import {
  storeReadPosition as apiStoreReadPosition,
  getReadPositions as apiGetReadPositions,
} from '../../api';

/**
 * GET position
 */
export const GET_POSITION_REQUEST = 'GET_POSITION_REQUEST';
export const GET_POSITION_SUCCESS = 'GET_POSITION_SUCCESS';
export const GET_POSITION_FAILURE = 'GET_POSITION_FAILURE';
export const GET_POSITION_INVALIDATE = 'GET_POSITION_INVALIDATE';

export function getPosition(id) {
  return {
    [CALL_API]: {
      endpoint: `positions/single/${id}`,
      authenticated: true,
      types: [GET_POSITION_REQUEST, GET_POSITION_SUCCESS, GET_POSITION_FAILURE],
    },
  };
}

/**
 *  Invalidate read positions to make sure they get fetched again when the view appears
 */
export const getPositionInvalidate = () => {
  return {
    type: GET_POSITION_INVALIDATE,
    payload: {},
  };
};

/**
 *  Only dispatches getPosition if data is invalidated or empty
 */
const shouldGetPosition = (state) => {
  const position = state.positionState.position;

  if (!Object.keys(position.response).length) {
    return true;
  }

  return position.didInvalidate;
};

export const getPositionIfNeeded = (id) => (dispatch, getState) => {
  if (shouldGetPosition(getState())) {
    return dispatch(getPosition(id));
  }
};

/**
 * GET positions
 */
export const GET_POSITIONS_REQUEST = 'GET_POSITIONS_REQUEST';
export const GET_POSITIONS_SUCCESS = 'GET_POSITIONS_SUCCESS';
export const GET_POSITIONS_FAILURE = 'GET_POSITIONS_FAILURE';

export function getPositions(filter = '') {
  let endpoint = `positions`;
  if (filter) {
    endpoint += `?status=${filter}`;
  }
  return {
    [CALL_API]: {
      endpoint: endpoint,
      authenticated: true,
      types: [GET_POSITIONS_REQUEST, GET_POSITIONS_SUCCESS, GET_POSITIONS_FAILURE],
    },
  };
}

/**
 * GET admin positions
 */
export const GET_POSITIONS_ADMIN_REQUEST = 'GET_POSITIONS_ADMIN_REQUEST';
export const GET_POSITIONS_ADMIN_SUCCESS = 'GET_POSITIONS_ADMIN_SUCCESS';
export const GET_POSITIONS_ADMIN_FAILURE = 'GET_POSITIONS_ADMIN_FAILURE';

export function getPositionsAdmin() {
  return {
    [CALL_API]: {
      endpoint: `positions?categories=override`,
      authenticated: true,
      types: [
        GET_POSITIONS_ADMIN_REQUEST,
        GET_POSITIONS_ADMIN_SUCCESS,
        GET_POSITIONS_ADMIN_FAILURE,
      ],
    },
  };
}

/**
 * GET external positions
 */
export const GET_EXTERNAL_POSITIONS_REQUEST = 'GET_EXTERNAL_POSITIONS_REQUEST';
export const GET_EXTERNAL_POSITIONS_SUCCESS = 'GET_EXTERNAL_POSITIONS_SUCCESS';
export const GET_EXTERNAL_POSITIONS_FAILURE = 'GET_EXTERNAL_POSITIONS_FAILURE';

export function getExternalPositions() {
  return {
    [CALL_API]: {
      endpoint: `positions/external`,
      authenticated: true,
      types: [
        GET_EXTERNAL_POSITIONS_REQUEST,
        GET_EXTERNAL_POSITIONS_SUCCESS,
        GET_EXTERNAL_POSITIONS_FAILURE,
      ],
    },
  };
}

/**
 *  Get all read positions
 */
export const GET_READ_POSITIONS = 'GET_READ_POSITIONS';
export const getReadPositions = () => async (dispatch) => {
  try {
    let data = await apiGetReadPositions();

    dispatch({
      type: GET_READ_POSITIONS,
      response: data,
    });
  } catch (error) {
    console.log('Error', error);
  }
};

/**
 *  Mark position as read
 */
export const SET_POSITION_READ = 'SET_POSITION_READ';
export const setPositionRead = (id) => {
  apiStoreReadPosition(id);

  return {
    type: SET_POSITION_READ,
  };
};

/**
 *  Invalidate read positions to make sure they get fetched again when the view appears
 */
export const READ_POSITIONS_INVALIDATE = 'READ_POSITIONS_INVALIDATE';
export const readPositionsInvalidate = () => {
  return {
    type: READ_POSITIONS_INVALIDATE,
  };
};

/**
 *  Only dispatches getReadPositions if data is invalidated or empty
 */
const shouldGetReadPositions = (state) => {
  const readPositions = state.positionState.readPositions;

  if (!readPositions.response || readPositions.response.length === 0) {
    return true;
  }

  return readPositions.didInvalidate;
};

export const getReadPositionsIfNeeded = () => (dispatch, getState) => {
  if (shouldGetReadPositions(getState())) {
    return dispatch(getReadPositions());
  }
};

export const POST_POSITION_REQUEST = 'POST_POSITION_REQUEST';
export const POST_POSITION_SUCCESS = 'POST_POSITION_SUCCESS';
export const POST_POSITION_FAILURE = 'POST_POSITION_FAILURE';

/**
 * Create position
 */
export function postPosition(data, id = null) {
  let endpoint = 'positions';

  if (id !== null) {
    endpoint += `/single/${id}`;
  }

  return {
    [CALL_API]: {
      endpoint: endpoint,
      authenticated: true,
      types: [POST_POSITION_REQUEST, POST_POSITION_SUCCESS, POST_POSITION_FAILURE],
      method: 'POST',
      data: data,
    },
  };
}

export const DELETE_POSITION_REQUEST = 'DELETE_POSITION_REQUEST';
export const DELETE_POSITION_SUCCESS = 'DELETE_POSITION_SUCCESS';
export const DELETE_POSITION_FAILURE = 'DELETE_POSITION_FAILURE';

/**
 * Delete Position
 */
export function deletePosition(id) {
  const endpoint = `positions/single/${id}`;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      authenticated: true,
      types: [DELETE_POSITION_REQUEST, DELETE_POSITION_SUCCESS, DELETE_POSITION_FAILURE],
      method: 'DELETE',
    },
  };
}

export const REMOVE_EXTERNAL_POSITION = 'REMOVE_EXTERNAL_POSITION';

/**
 * Delete External Position
 */
export function removeExternalPosition(positions) {
  return {
    type: REMOVE_EXTERNAL_POSITION,
    response: positions,
  };
}

export const PUSH_POSITION_REQUEST = 'PUSH_POSITION_REQUEST';
export const PUSH_POSITION_SUCCESS = 'PUSH_POSITION_SUCCESS';
export const PUSH_POSITION_FAILURE = 'PUSH_POSITION_FAILURE';

/**
 * Push position
 */
export function pushPosition(id, pushTypes) {
  const endpoint = `positions/single/${id}/push`;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      authenticated: true,
      types: [PUSH_POSITION_REQUEST, PUSH_POSITION_SUCCESS, PUSH_POSITION_FAILURE],
      method: 'POST',
      data: pushTypes,
    },
  };
}

export const POST_POSITION_ARCHIVE_REQUEST = 'POST_POSITION_ARCHIVE_REQUEST';
export const POST_POSITION_ARCHIVE_SUCCESS = 'POST_POSITION_ARCHIVE_SUCCESS';
export const POST_POSITION_ARCHIVE_FAILURE = 'POST_POSITION_ARCHIVE_FAILURE';
export const POST_POSITION_ARCHIVE_RESET = 'POST_POSITION_ARCHIVE_RESET';

/**
 * Create position
 */
export function postPositionArchive(id) {
  let endpoint = `positions/archive/${id}`;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      authenticated: true,
      types: [
        POST_POSITION_ARCHIVE_REQUEST,
        POST_POSITION_ARCHIVE_SUCCESS,
        POST_POSITION_ARCHIVE_FAILURE,
        POST_POSITION_ARCHIVE_RESET,
      ],
      method: 'POST',
    },
  };
}

export const GET_POSITION_CATEGORIES_REQUEST = 'GET_POSITION_CATEGORIES_REQUEST';
export const GET_POSITION_CATEGORIES_SUCCESS = 'GET_POSITION_CATEGORIES_SUCCESS';
export const GET_POSITION_CATEGORIES_FAILURE = 'GET_POSITION_CATEGORIES_FAILURE';

/**
 * Get position categories
 */
export function getPositionCategories(id) {
  let endpoint = `/positions/${id}/categories`;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      authenticated: true,
      types: [
        GET_POSITION_CATEGORIES_REQUEST,
        GET_POSITION_CATEGORIES_SUCCESS,
        GET_POSITION_CATEGORIES_FAILURE,
      ],
    },
  };
}

export const POST_POSITION_CATEGORIES_REQUEST = 'POST_POSITION_CATEGORIES_REQUEST';
export const POST_POSITION_CATEGORIES_SUCCESS = 'POST_POSITION_CATEGORIES_SUCCESS';
export const POST_POSITION_CATEGORIES_FAILURE = 'POST_POSITION_CATEGORIES_FAILURE';

/**
 * Post position categories
 */
export function postPositionCategories(id, data) {
  let endpoint = `/positions/${id}/categories`;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      authenticated: true,
      types: [
        POST_POSITION_CATEGORIES_REQUEST,
        POST_POSITION_CATEGORIES_SUCCESS,
        POST_POSITION_CATEGORIES_FAILURE,
      ],
      method: 'PUT',
      data: data,
      sendJSON: true,
    },
  };
}

import {
  GET_TEAMS_REQUEST,
  GET_TEAMS_SUCCESS,
  GET_TEAMS_FAILURE,
  GET_TEAMS_INVALIDATE,
  GET_TEAM_REQUEST,
  GET_TEAM_SUCCESS,
  GET_TEAM_FAILURE,
  GET_TEAM_MEMBERS_REQUEST,
  GET_TEAM_MEMBERS_SUCCESS,
  GET_TEAM_MEMBERS_FAILURE,
  DELETE_TEAM_REQUEST,
  DELETE_TEAM_SUCCESS,
  DELETE_TEAM_FAILURE,
  POST_TEAM_REQUEST,
  POST_TEAM_SUCCESS,
  POST_TEAM_FAILURE,
} from '../actions/teamActions';

const initialState = {
  teams: {
    didInvalidate: false,
    error: false,
    isLoading: false,
    response: [],
  },
  team: {
    didInvalidate: false,
    error: false,
    isLoading: false,
    response: {},
  },
  teamDelete: {
    didInvalidate: false,
    error: false,
    isLoading: false,
    response: {},
  },
  teamMembers: {
    didInvalidate: false,
    error: false,
    isLoading: false,
    response: [],
  },
};

export default function teamState(
  state = initialState,
  { authenticated, error, response, type, request },
) {
  switch (type) {
    case GET_TEAMS_REQUEST:
      return {
        ...state,
        teams: {
          ...state.teams,
          isLoading: true,
        },
      };
    case GET_TEAMS_SUCCESS:
      return {
        ...state,
        teams: {
          ...state.teams,
          isLoading: false,
          response: response,
        },
      };
    case GET_TEAMS_FAILURE:
      return {
        ...state,
        teams: {
          ...state.teams,
          isLoading: false,
          error: true,
          response: error,
        },
      };
    case GET_TEAMS_INVALIDATE:
      return {
        ...state,
        teams: {
          ...state.teams,
          didInvalidate: true,
        },
      };

    case GET_TEAM_REQUEST:
      return {
        ...state,
        team: {
          ...state.team,
          isLoading: true,
        },
      };
    case GET_TEAM_SUCCESS:
      return {
        ...state,
        team: {
          ...state.team,
          isLoading: false,
          response: response,
        },
      };
    case GET_TEAM_FAILURE:
      return {
        ...state,
        team: {
          ...state.team,
          isLoading: false,
          error: true,
          response: error,
        },
      };
    case GET_TEAM_MEMBERS_REQUEST:
      return {
        ...state,
        teamMembers: {
          ...state.teamMembers,
          isLoading: true,
        },
      };
    case GET_TEAM_MEMBERS_SUCCESS:
      return {
        ...state,
        teamMembers: {
          ...state.teamMembers,
          isLoading: false,
          response: response,
        },
      };
    case GET_TEAM_MEMBERS_FAILURE:
      return {
        ...state,
        teamMembers: {
          ...state.teamMembers,
          isLoading: false,
          error: true,
          response: error,
        },
      };
    case DELETE_TEAM_REQUEST:
      return {
        ...state,
        teamDelete: {
          ...state.teamDelete,
          isLoading: true,
        },
      };
    case DELETE_TEAM_SUCCESS:
      return {
        ...state,
        teamDelete: {
          ...state.teamDelete,
          isLoading: false,
          response: response,
        },
      };
    case DELETE_TEAM_FAILURE:
      return {
        ...state,
        teamDelete: {
          ...state.teamDelete,
          isLoading: false,
          error: true,
          response: error,
        },
      };

    case POST_TEAM_REQUEST:
      return {
        ...state,
        team: {
          ...state.team,
          isLoading: true,
        },
      };
    case POST_TEAM_SUCCESS:
      return {
        ...state,
        team: {
          ...state.team,
          isLoading: false,
          // response: response,
          request: request,
        },
      };
    case POST_TEAM_FAILURE:
      return {
        ...state,
        team: {
          ...state.team,
          isLoading: false,
          error: true,
          // response: error,
          request: request,
        },
      };
    default:
      return state;
  }
}

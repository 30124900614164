export const validateColumnIndexes = (file) => {
  //Check if fields exists in file's columns
  const firstNameColumn = Object.keys(file).findIndex((item) =>
    item.match(new RegExp(/firstname/gi)),
  );
  const lastNameColumn = Object.keys(file).findIndex((item) =>
    item.match(new RegExp(/lastname/gi)),
  );
  const emailColumn = Object.keys(file).findIndex((item) => item.match(new RegExp(/email/gi)));
  const adminColumn = Object.keys(file).findIndex((item) => item.match(new RegExp(/admin/gi)));

  if (emailColumn === -1 || firstNameColumn === -1 || lastNameColumn === -1 || adminColumn === -1) {
    return false;
  }
  return { emailColumn, firstNameColumn, lastNameColumn, adminColumn };
};

export const isValidFileFormat = (file, setState) => {
  if (file.length === 0) {
    console.log('No files selected');
    setState((prev) => ({ ...prev, file: [] }));
    return false;
  }
  if (
    file.length > 0 &&
    file[0].size > 0 &&
    file[0].type !== 'application/vnd.ms-excel' &&
    file[0].type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' &&
    file[0].type !== 'text/csv'
  ) {
    return false;
  }

  return true;
};

export const validateImage = (imageFile) => {
  if (imageFile.length === 0) {
    console.log('No files selected');
    return { valid: false, errorKeys: [] };
  }
  const img = imageFile[0];
  const size = img.size / 1024; // KB size
  const type = img.type; // image/png

  if (size > 3000)
    return { valid: false, errorKeys: [{ id: 'generic-error-image-size' }, { maxSize: '3MB' }] };

  if (!(type === 'image/png' || type === 'image/jpeg'))
    return {
      valid: false,
      errorKeys: [{ id: 'generic-error-image-size' }, { imgType: type.replace('image/', '') }],
    };

  return { valid: true, errorKeys: [] };
};

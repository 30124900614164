export const UTCToDate = (date) => {
  const d = new Date(0);
  d.setUTCSeconds(date);

  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) {
    month = '0' + month;
  }

  if (day.length < 2) {
    day = '0' + day;
  }

  return [year, month, day].join('-');
};

export const GMTToLocaleString = (date) => {
  return new Date(date).toLocaleDateString().split('/').reverse().join('-');
};

export const getDateAndTimeFromTimestamp = (timestamp) => {
  //Get date in this format: 17 jul 2023
  const date = new Date(timestamp * 1000);
  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'short' });
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = '0' + date.getMinutes();

  // Check if valid date
  if (isNaN(day) || month === 'Invalid Date' || isNaN(year) || isNaN(hours) || minutes === '0NaN')
    return null;

  return { date: `${month} ${day}, ${year}`, hours, minutes: Number(minutes.slice(-2)) };
};

import { StyleSheet, css } from 'aphrodite';
import { Alert } from '@talentech/components';

import EmptyState from '../EmptyState';
import FetchedCardItem from './FetchedCardItem';
import { useTranslation } from 'react-i18next';
import useFetchExternalPositions from '../../hooks/position/useFetchExternalPositions';

const FetchedPositionsList = (props) => {
  const { displayedExternalPositions, onPressFetchJobs, onDeleteExternalPosition } = props;
  const { t } = useTranslation();
  const { isExternalPositionsLoading } = useFetchExternalPositions();
  return (
    <>
      {displayedExternalPositions.length === 0 ? (
        <EmptyState
          img="https://storybook.talentech.io/images/svg/logistics/package-open.svg"
          alt="Empty box"
          width="128"
          heading={t('settings-category-nothing')}
          description={t('positionpage-empty-state-ats')}
          callToActionType="button"
          buttonLabel={t('admin-get-external-jobs').toUpperCase()}
          onSubmit={onPressFetchJobs}
          isLoading={isExternalPositionsLoading}
        />
      ) : (
        <>
          <div className={css(styles.customAlert)}>
            <Alert
              color="primary"
              isOpen
              message={t('positionpage-alert-subtitle')}
              title={t('positionpage-alert-title')}
            />
          </div>
          <div className={css(styles.cardContainer)}>
            {displayedExternalPositions.map((externalPosition) => (
              <FetchedCardItem
                key={externalPosition.ID}
                position={externalPosition}
                onDeleteExternalPosition={onDeleteExternalPosition}
              />
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default FetchedPositionsList;

const styles = StyleSheet.create({
  cardContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '20px',
    padding: '20px',
    alignItems: 'flex-start',
  },
  customAlert: {
    padding: '20px 20px 0px',
  },
});

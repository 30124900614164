import { CALL_API } from '../middleware/api';
import { getCredentials } from '../../api';

export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';
export const GET_USER_INVALIDATE = 'GET_USER_INVALIDATE';

export const getUser =
  (id = null) =>
  async (dispatch) => {
    // Use token user ID if not provided
    if (id === null) {
      let credentials = await getCredentials();
      id = credentials.idTokenPayload.sub;
    }

    dispatch({
      [CALL_API]: {
        endpoint: `users/single/${id}`,
        authenticated: true,
        types: [GET_USER_REQUEST, GET_USER_SUCCESS, GET_USER_FAILURE],
      },
    });
  };

export const getUserInvalidate = () => {
  return {
    type: GET_USER_INVALIDATE,
    payload: {},
  };
};

/**
 *  Only dispatches getUser if data is invalidated or empty
 */
const shouldGetUser = (state) => {
  const user = state.userState.user;

  if (!Object.keys(user.response).length) {
    return true;
  }

  if (user.isLoading) {
    return false;
  }

  return user.didInvalidate;
};

export const getUserIfNeeded = () => (dispatch, getState) => {
  if (shouldGetUser(getState())) {
    return dispatch(getUser());
  }
};

export const POST_USER_REQUEST = 'POST_USER_REQUEST';
export const POST_USER_SUCCESS = 'POST_USER_SUCCESS';
export const POST_USER_FAILURE = 'POST_USER_FAILURE';
export const POST_USER_RESET = 'POST_USER_RESET';

/**
 * Create or update user
 */

const ucfirst = (string) => {
  return `${string[0].toUpperCase()}${string.slice(1)}`;
};

const getNameFromEmail = (email) => {
  let Familyname = '';
  let Givenname = '';

  const parts = email.split('@');
  const domain = parts[1].split('.')[1];
  const name = parts[0].split('.');

  if (name.length > 1) {
    Givenname = ucfirst(name[0]);
    Familyname = ucfirst(name[1]);
  } else {
    Givenname = ucfirst(name[0]);
    Familyname = ucfirst(domain);
  }

  return {
    Familyname,
    Givenname,
  };
};

export function postUser(userData, id = null, authenticated = false) {
  let endpoint = 'users';
  if (userData.Familyname === undefined || userData.Givenname === undefined) {
    userData = {
      ...userData,
      ...getNameFromEmail(userData.Mail),
    };
  }

  // Update user
  if (id) {
    endpoint += `/single/${id}`;
  }

  return {
    [CALL_API]: {
      endpoint: endpoint,
      authenticated: authenticated,
      types: [POST_USER_REQUEST, POST_USER_SUCCESS, POST_USER_FAILURE, POST_USER_RESET],
      method: 'POST',
      data: userData,
    },
  };
}

export const POST_USER_DEVICETOKEN_REQUEST = 'POST_USER_DEVICETOKEN_REQUEST';
export const POST_USER_DEVICETOKEN_SUCCESS = 'POST_USER_DEVICETOKEN_SUCCESS';
export const POST_USER_DEVICETOKEN_FAILURE = 'POST_USER_DEVICETOKEN_FAILURE';

/**
 * Send push notification token to API
 * @param {string} id - User ID
 * @param {string} token - Firebase token
 */
export function postUserDevicetoken(id = null, token) {
  return {
    [CALL_API]: {
      endpoint: `users/devicetoken/${id}`,
      authenticated: true,
      types: [
        POST_USER_DEVICETOKEN_REQUEST,
        POST_USER_DEVICETOKEN_SUCCESS,
        POST_USER_DEVICETOKEN_FAILURE,
      ],
      method: 'POST',
      data: {
        Token: token,
      },
    },
  };
}

/**
 * GET active users
 */
export const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE';

export function getUsers() {
  return {
    [CALL_API]: {
      endpoint: `activeusers`,
      authenticated: true,
      types: [GET_USERS_REQUEST, GET_USERS_SUCCESS, GET_USERS_FAILURE],
    },
  };
}

/**
 * Toggle admin role
 */
export const MAKE_USER_ADMIN_REQUEST = 'MAKE_USER_ADMIN_REQUEST';
export const MAKE_USER_ADMIN_SUCCESS = 'MAKE_USER_ADMIN_SUCCESS';
export const MAKE_USER_ADMIN_FAILURE = 'MAKE_USER_ADMIN_FAILURE';
export const MAKE_USER_ADMIN_RESET = 'MAKE_USER_ADMIN_RESET';

export function toggleUserAdmin(user) {
  return {
    [CALL_API]: {
      endpoint: `/users/type/${user.iduser}?type=${user.usertype === 1 ? '0' : '1'}`,
      authenticated: true,
      method: 'POST',
      types: [
        MAKE_USER_ADMIN_REQUEST,
        MAKE_USER_ADMIN_SUCCESS,
        MAKE_USER_ADMIN_FAILURE,
        MAKE_USER_ADMIN_RESET,
      ],
    },
  };
}

/**
 * Get user apps
 */
export const GET_USER_APPS_REQUEST = 'GET_USER_APPS_REQUEST';
export const GET_USER_APPS_SUCCESS = 'GET_USER_APPS_SUCCESS';
export const GET_USER_APPS_FAILURE = 'GET_USER_APPS_FAILURE';

export function getUserApps() {
  return {
    [CALL_API]: {
      endpoint: `users/apps`,
      authenticated: true,
      types: [GET_USER_APPS_REQUEST, GET_USER_APPS_SUCCESS, GET_USER_APPS_FAILURE],
    },
  };
}

/**
 * Get user tenants
 */
export const GET_USER_TENANTS_REQUEST = 'GET_USER_TENANTS_REQUEST';
export const GET_USER_TENANTS_SUCCESS = 'GET_USER_TENANTS_SUCCESS';
export const GET_USER_TENANTS_FAILURE = 'GET_USER_TENANTS_FAILURE';

export function getUserTenants() {
  return {
    [CALL_API]: {
      endpoint: `users/tenants`,
      authenticated: true,
      types: [GET_USER_TENANTS_REQUEST, GET_USER_TENANTS_SUCCESS, GET_USER_TENANTS_FAILURE],
    },
  };
}

export const GET_USER_CATEGORIES_REQUEST = 'GET_USER_CATEGORIES_REQUEST';
export const GET_USER_CATEGORIES_SUCCESS = 'GET_USER_CATEGORIES_SUCCESS';
export const GET_USER_CATEGORIES_FAILURE = 'GET_USER_CATEGORIES_FAILURE';

export function getUserCategories(id) {
  return {
    [CALL_API]: {
      endpoint: `users/${id}/categories`,
      authenticated: true,
      types: [
        GET_USER_CATEGORIES_REQUEST,
        GET_USER_CATEGORIES_SUCCESS,
        GET_USER_CATEGORIES_FAILURE,
      ],
      method: 'GET',
    },
  };
}

export const POST_USER_CATEGORIES_REQUEST = 'POST_USER_CATEGORIES_REQUEST';
export const POST_USER_CATEGORIES_SUCCESS = 'POST_USER_CATEGORIES_SUCCESS';
export const POST_USER_CATEGORIES_FAILURE = 'POST_USER_CATEGORIES_FAILURE';

export function postUserCategories(id, data) {
  return {
    [CALL_API]: {
      endpoint: `users/${id}/categories`,
      authenticated: true,
      types: [
        POST_USER_CATEGORIES_REQUEST,
        POST_USER_CATEGORIES_SUCCESS,
        POST_USER_CATEGORIES_FAILURE,
      ],
      method: 'PUT',
      data: data,
      sendJSON: true,
    },
  };
}

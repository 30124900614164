import {
  GET_GOALS_REQUEST,
  GET_GOALS_SUCCESS,
  GET_GOALS_FAILURE,
  GET_GOALS_INVALIDATE,
  GET_GOAL_REQUEST,
  GET_GOAL_SUCCESS,
  GET_GOAL_FAILURE,
  DELETE_GOAL_REQUEST,
  DELETE_GOAL_SUCCESS,
  DELETE_GOAL_FAILURE,
  POST_GOAL_REQUEST,
  POST_GOAL_SUCCESS,
  POST_GOAL_FAILURE,
} from '../actions/goalActions';

const initialState = {
  goals: {
    didInvalidate: false,
    error: false,
    isLoading: false,
    response: [],
  },
  goal: {
    didInvalidate: false,
    error: false,
    isLoading: false,
    response: {},
  },
  goalDelete: {
    didInvalidate: false,
    error: false,
    isLoading: false,
    response: {},
  },
};

export default function goalState(
  state = initialState,
  { authenticated, error, response, type, request },
) {
  switch (type) {
    case GET_GOALS_REQUEST:
      return {
        ...state,
        goals: {
          ...state.goals,
          isLoading: true,
        },
      };
    case GET_GOALS_SUCCESS:
      return {
        ...state,
        goals: {
          ...state.goals,
          isLoading: false,
          response: response,
        },
      };
    case GET_GOALS_FAILURE:
      return {
        ...state,
        goals: {
          ...state.goals,
          isLoading: false,
          error: true,
          response: error,
        },
      };
    case GET_GOALS_INVALIDATE:
      return {
        ...state,
        goals: {
          ...state.goals,
          didInvalidate: true,
        },
      };

    case GET_GOAL_REQUEST:
      return {
        ...state,
        goal: {
          ...state.goal,
          isLoading: true,
        },
      };
    case GET_GOAL_SUCCESS:
      return {
        ...state,
        goal: {
          ...state.goal,
          isLoading: false,
          response: response,
        },
      };
    case GET_GOAL_FAILURE:
      return {
        ...state,
        goal: {
          ...state.goal,
          isLoading: false,
          error: true,
          response: error,
        },
      };

    case DELETE_GOAL_REQUEST:
      return {
        ...state,
        goalDelete: {
          ...state.goalDelete,
          isLoading: true,
        },
      };
    case DELETE_GOAL_SUCCESS:
      return {
        ...state,
        goalDelete: {
          ...state.goalDelete,
          isLoading: false,
          response: response,
        },
      };
    case DELETE_GOAL_FAILURE:
      return {
        ...state,
        goalDelete: {
          ...state.goalDelete,
          isLoading: false,
          error: true,
          response: error,
        },
      };

    case POST_GOAL_REQUEST:
      return {
        ...state,
        goal: {
          ...state.goal,
          isLoading: true,
        },
      };
    case POST_GOAL_SUCCESS:
      return {
        ...state,
        goal: {
          ...state.goal,
          isLoading: false,
          // response: response,
          request: request,
        },
      };
    case POST_GOAL_FAILURE:
      return {
        ...state,
        goal: {
          ...state.goal,
          isLoading: false,
          error: true,
          // response: error,
          request: request,
        },
      };
    default:
      return state;
  }
}

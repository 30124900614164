import { getCredentials, redirectToLoginPage, storeCustomerType } from '../../api';
import axios from 'axios';
import { renewAuth, addCallQueue, clearCallQueue } from '../actions/authActions';
import { tokenExists, tokenExpired } from '../api';

var qs = require('qs');

const callApi = (
  endpoint,
  credentials,
  authenticated,
  method = 'GET',
  data = null,
  apiType = 'main',
) => {
  // To indicate which API to go to.
  const baseURL =
    apiType === 'main'
      ? `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_ENVIRONMENT}${process.env.REACT_APP_API_BASE_URL}/`
      : `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_COMMUNICATION_API_ENVIRONMENT}/`;

  let headers = {};

  if (authenticated) {
    if (tokenExists(credentials)) {
      headers = {
        Authorization: `Bearer ${credentials.accessToken}`,
      };
    } else {
      console.log('No token saved!');
    }
  }

  return axios({
    url: endpoint,
    method: method,
    baseURL: baseURL,
    headers: headers,
    data: data,
  });
};

export const CALL_API = Symbol('Call API');

// eslint-disable-next-line import/no-anonymous-default-export
export default (store) => (next) => (action) => {
  const callAPI = action[CALL_API];

  // So the middleware doesn't get applied to every single action
  if (typeof callAPI === 'undefined') {
    return next(action);
  }

  let { authenticated } = callAPI;

  getCredentials().then((credentials) => {
    let renewAuthState = store.getState().authState.renewAuth; // This is an API call and it requires authentication
    // Check if the access token needs refreshing
    if (authenticated && tokenExpired(credentials)) {
      console.log('token expired, queue actions');

      store.dispatch(addCallQueue(callAPI));

      // Try to renew auth (only run once)
      if (renewAuthState.isLoading === false) {
        console.log('dispatch renewAuth');

        store.dispatch(renewAuth()).then(() => {
          renewAuthState = store.getState().authState.renewAuth;
          // Could not renew token. The user need to login again
          if (renewAuthState.error === true) {
            console.log(
              'Could not renew token. The user need to login again',
              renewAuthState.response,
            );

            redirectToLoginPage(store);
            return;
          } else {
            let newCredentials = renewAuthState.response;
            console.log('renew success');
            // Inject the new token into the current credentials
            credentials.accessToken = newCredentials.accessToken;

            // get all queued calls and dispatch them
            const queue = store.getState().authState.queue;
            console.log('queue', queue);
            for (let call of queue) {
              runApiCall(next, credentials, call, store);
            }

            store.dispatch(clearCallQueue(store.dispatch));

            return;
          }
        });
      }

      return;
    }

    // Prepare and run callAPI
    return runApiCall(next, credentials, callAPI, store);
  });
};

const runApiCall = (next, credentials, callAPI, store) => {
  let { endpoint, types, authenticated, method, data, meta, apiType, sendJSON } = callAPI;

  const [requestType, successType, errorType] = types;

  next({
    type: requestType,
  });

  // Default values
  method = method ? method : 'GET';
  data = data && !sendJSON ? qs.stringify(data) : data && sendJSON ? data : null;
  // Make the api call
  return callApi(endpoint, credentials, authenticated, method, data, apiType)
    .then((response) => {
      if (response.data && response.data.customertype) {
        storeCustomerType(response.data.customertype);
      }

      return next({
        response: response.data,
        authenticated,
        type: successType,
        request: response,
        meta: meta,
      });
    })
    .catch((error) => {
      console.log(error);
      if (error.response.status === 401 || error.response.status === 404) {
        redirectToLoginPage(store, true, error.response.status);
        return;
      }
      return next({
        error: error.message || 'There was an error.',
        type: errorType,
        request: error.response,
        meta: meta,
      });
    });
};

import React, { Component } from 'react';
import { css, StyleSheet } from 'aphrodite';
import { withRouter } from 'react-router';
import { IntlNetwork } from '../../../helpers/language';
import PositionCategoryList from '../../../components/admin/PositionCategoryList';
import EmptyState from '../../../components/EmptyState';

class PositionEditContactFields extends Component {
  render() {
    const {
      customerCategories,
      state,
      setState,
      t,
      inheritedStyles,
      Interval,
      Push,
      ContactTelephone,
      ContactMail,
      ContactName,
      onInputChange,
      onChangeCheckbox,
      onChangeInterval,
    } = this.props;
    return (
      <div>
        <fieldset className={css(inheritedStyles.formFieldset)}>
          <legend className={css(styles.formLegend)}>{t('generic-text-contact-person')}</legend>
          <label className={css(inheritedStyles.formControl)}>
            <span className="form-label">{t('generic-input-Name')} *</span>
            <input
              type="text"
              maxLength="45"
              value={ContactName}
              className={css(inheritedStyles.formInput)}
              onChange={(e) => onInputChange(e, 'ContactName')}
            />
          </label>
          <label className={css(inheritedStyles.formControl)}>
            <span className="form-label">{t('generic-input-Mail')} *</span>
            <input
              type="email"
              maxLength="45"
              value={ContactMail}
              className={css(inheritedStyles.formInput)}
              onChange={(e) => onInputChange(e, 'ContactMail')}
            />
          </label>
          <label className={css(inheritedStyles.formControl)}>
            <span className="form-label">{t('generic-input-Telephone')}</span>
            <input
              type="tel"
              maxLength="45"
              value={ContactTelephone}
              className={css(inheritedStyles.formInput)}
              onChange={(e) => onInputChange(e, 'ContactTelephone')}
            />
          </label>
        </fieldset>
        <fieldset className={css(inheritedStyles.formFieldset)}>
          <legend className={css(styles.formLegend)}>{t('admin-add-job-send-to-employees')}</legend>

          <label className={css(inheritedStyles.formControl)}>
            <input
              checked={Push.indexOf('push') !== -1}
              type="checkbox"
              name="c1"
              className={css(styles.formCheckpoint)}
              onChange={(e) => onChangeCheckbox('push', e.target.checked)}
            />
            {t('generic-text-push-via-app')}
          </label>

          <label className={css(inheritedStyles.formControl)}>
            <input
              checked={Push.includes('email')}
              type="checkbox"
              name="c3"
              className={css(styles.formCheckpoint)}
              onChange={(e) => onChangeCheckbox('email', e.target.checked)}
            />
            {t('generic-input-Mail')}
          </label>
        </fieldset>
        <fieldset className={css(inheritedStyles.formFieldset)}>
          {customerCategories !== '' ? (
            <>
              <legend className={css(styles.formLegend)}>
                {t('settings-category-list-header')}
              </legend>

              <p>{t('positioneditpage-category-description')}</p>
              <PositionCategoryList state={state} setState={setState} />
            </>
          ) : (
            <EmptyState
              img="https://storybook.talentech.io/images/svg/logistics/package-attention.svg"
              width="80"
              heading={t('positioneditpage-no-category-title')}
              size="sm"
              callToActionType="link"
              description={t('settings-category-nothing-description')}
              buttonLabel={t('positioneditpage-go-to-settings').toUpperCase()}
              onSubmit={() =>
                this.props.history.push({
                  pathname: '/admin/settings',
                  hash: 'categorySection',
                })
              }
            />
          )}
        </fieldset>
        <fieldset className={css(inheritedStyles.formFieldset)}>
          <legend className={css(styles.formLegend)}>{t('generic-intervall')}</legend>
          <label className={css(inheritedStyles.formControl)}>
            <input
              checked={Interval === 1}
              value="1"
              type="radio"
              name="interval"
              className={css(styles.formCheckpoint)}
              onChange={onChangeInterval}
            />
            {t('generic-intervall-number1')}
          </label>
          <label className={css(inheritedStyles.formControl)}>
            <input
              checked={Interval === 2}
              value="2"
              type="radio"
              name="interval"
              className={css(styles.formCheckpoint)}
              onChange={onChangeInterval}
            />
            {t('generic-intervall-number2')}
          </label>
          <label className={css(inheritedStyles.formControl)}>
            <input
              checked={Interval === 3}
              value="3"
              type="radio"
              name="interval"
              className={css(styles.formCheckpoint)}
              onChange={onChangeInterval}
            />
            {t('generic-intervall-number3')}
          </label>
          <label className={css(inheritedStyles.formControl)}>
            <input
              checked={Interval === 4}
              value="4"
              type="radio"
              name="interval"
              className={css(styles.formCheckpoint)}
              onChange={onChangeInterval}
            />
            {t('generic-intervall-number4')}
          </label>
          <label className={css(inheritedStyles.formControl)}>
            <input
              checked={Interval === 5}
              value="5"
              type="radio"
              name="interval"
              className={css(styles.formCheckpoint)}
              onChange={onChangeInterval}
            />
            {t('generic-intervall-number5')}
          </label>
          <label className={css(inheritedStyles.formControl)}>
            <input
              checked={Interval === 6}
              value="6"
              type="radio"
              name="interval"
              className={css(styles.formCheckpoint)}
              onChange={onChangeInterval}
            />
            {t('generic-intervall-number6')}
          </label>
          <label className={css(inheritedStyles.formControl)}>
            <input
              checked={Interval === 8}
              value="8"
              type="radio"
              name="interval"
              className={css(styles.formCheckpoint)}
              onChange={onChangeInterval}
            />
            {t('generic-intervall-number8')}
          </label>
          <label className={css(inheritedStyles.formControl)}>
            <input
              checked={Interval === 12}
              value="12"
              type="radio"
              name="interval"
              className={css(styles.formCheckpoint)}
              onChange={onChangeInterval}
            />
            {t('generic-intervall-number12')}
          </label>
          <label className={css(inheritedStyles.formControl)}>
            <input
              checked={Interval === 16}
              value="16"
              type="radio"
              name="interval"
              className={css(styles.formCheckpoint)}
              onChange={onChangeInterval}
            />
            {t('generic-intervall-number16')}
          </label>
          <label className={css(inheritedStyles.formControl)}>
            <input
              checked={Interval === 0}
              value="0"
              type="radio"
              name="interval"
              className={css(styles.formCheckpoint)}
              onChange={onChangeInterval}
            />
            {t('generic-intervall-number0')}
          </label>
        </fieldset>
      </div>
    );
  }
}

const styles = StyleSheet.create({
  formCheckpoint: {
    marginRight: '.5em',
  },
  formLegend: {
    fontSize: '.75em',
    borderRadius: '2px',
    margin: '0',
    textTransform: 'uppercase',
    border: '1px solid #ddd',
    backgroundColor: '#fff',
    padding: '.75em 1em',
    transform: 'translateX(-20px)',
    float: 'none',
  },
});

export default withRouter(PositionEditContactFields);

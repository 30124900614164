import { CALL_API } from '../middleware/api';
import { getCredentials } from '../../api';

/**
 * GET recommendation
 */
export const GET_RECOMMENDATION_REQUEST = 'GET_RECOMMENDATION_REQUEST';
export const GET_RECOMMENDATION_SUCCESS = 'GET_RECOMMENDATION_SUCCESS';
export const GET_RECOMMENDATION_FAILURE = 'GET_RECOMMENDATION_FAILURE';
export const GET_RECOMMENDATION_INVALIDATE = 'GET_RECOMMENDATION_INVALIDATE';

export function getRecommendation(id) {
  return {
    [CALL_API]: {
      endpoint: `recommendations/single/${id}?expand=all`,
      authenticated: false,
      types: [GET_RECOMMENDATION_REQUEST, GET_RECOMMENDATION_SUCCESS, GET_RECOMMENDATION_FAILURE],
    },
  };
}

export const getRecommendationInvalidate = () => {
  return {
    type: GET_RECOMMENDATION_INVALIDATE,
    payload: {},
  };
};

/**
 *  Only dispatches getRecommendation if data is invalidated or empty
 */
const shouldGetRecommendation = (state) => {
  const recommendation = state.recommendationState.recommendation;

  if (!Object.keys(recommendation.response).length) {
    return true;
  }

  if (recommendation.isLoading) {
    return false;
  }

  return recommendation.didInvalidate;
};

export const getRecommendationIfNeeded = (id) => (dispatch, getState) => {
  if (shouldGetRecommendation(getState())) {
    return dispatch(getRecommendation(id));
  }
};

/**
 * GET recommendations
 */
export const GET_RECOMMENDATIONS_REQUEST = 'GET_RECOMMENDATIONS_REQUEST';
export const GET_RECOMMENDATIONS_SUCCESS = 'GET_RECOMMENDATIONS_SUCCESS';
export const GET_RECOMMENDATIONS_FAILURE = 'GET_RECOMMENDATIONS_FAILURE';
export const GET_RECOMMENDATIONS_INVALIDATE = 'GET_RECOMMENDATIONS_INVALIDATE';

export function getRecommendations() {
  return {
    [CALL_API]: {
      endpoint: `recommendations?expand=all`,
      authenticated: true,
      types: [
        GET_RECOMMENDATIONS_REQUEST,
        GET_RECOMMENDATIONS_SUCCESS,
        GET_RECOMMENDATIONS_FAILURE,
      ],
    },
  };
}

export const getRecommendationsInvalidate = () => {
  return {
    type: GET_RECOMMENDATIONS_INVALIDATE,
    payload: {},
  };
};

/**
 * GET recommendations/user
 */
export const GET_RECOMMENDATIONS_USER_REQUEST = 'GET_RECOMMENDATIONS_USER_REQUEST';
export const GET_RECOMMENDATIONS_USER_SUCCESS = 'GET_RECOMMENDATIONS_USER_SUCCESS';
export const GET_RECOMMENDATIONS_USER_FAILURE = 'GET_RECOMMENDATIONS_USER_FAILURE';
export const GET_RECOMMENDATIONS_USER_INVALIDATE = 'GET_RECOMMENDATIONS_USER_INVALIDATE';

export const getRecommendationsUser =
  (id = null) =>
  async (dispatch) => {
    // Use token user ID if not provided
    if (id === null) {
      let credentials = await getCredentials();
      id = credentials.idTokenPayload.sub;
    }

    dispatch({
      [CALL_API]: {
        endpoint: `recommendations/user/${id}?expand=all`,
        authenticated: true,
        types: [
          GET_RECOMMENDATIONS_USER_REQUEST,
          GET_RECOMMENDATIONS_USER_SUCCESS,
          GET_RECOMMENDATIONS_USER_FAILURE,
        ],
      },
    });
  };

export const getRecommendationsUserInvalidate = () => {
  return {
    type: GET_RECOMMENDATIONS_USER_INVALIDATE,
    payload: {},
  };
};

/**
 *  Only dispatches getReadPositions if data is invalidated or empty
 */
const shouldGetRecommendationsUser = (state) => {
  const recommendations = state.recommendationState.recommendations;

  if (!recommendations.response || recommendations.response.length === 0) {
    return true;
  }

  if (recommendations.isLoading) {
    return false;
  }

  return recommendations.didInvalidate;
};

export const getRecommendationsUserIfNeeded = () => (dispatch, getState) => {
  if (shouldGetRecommendationsUser(getState())) {
    return dispatch(getRecommendationsUser());
  }
};

/**
 * POST recommendation/position
 */
export const POST_RECOMMENDATION_POSITION_REQUEST = 'POST_RECOMMENDATION_POSITION_REQUEST';
export const POST_RECOMMENDATION_POSITION_SUCCESS = 'POST_RECOMMENDATION_POSITION_SUCCESS';
export const POST_RECOMMENDATION_POSITION_FAILURE = 'POST_RECOMMENDATION_POSITION_FAILURE';

export function postRecommendationPosition(id, recommendation) {
  return {
    [CALL_API]: {
      endpoint: `recommendations/position/${id}`,
      authenticated: true,
      types: [
        POST_RECOMMENDATION_POSITION_REQUEST,
        POST_RECOMMENDATION_POSITION_SUCCESS,
        POST_RECOMMENDATION_POSITION_FAILURE,
      ],
      method: 'POST',
      data: recommendation,
    },
  };
}

/**
 * POST recommendation/manual
 */
export const POST_RECOMMENDATION_MANUAL_REQUEST = 'POST_RECOMMENDATION_MANUAL_REQUEST';
export const POST_RECOMMENDATION_MANUAL_SUCCESS = 'POST_RECOMMENDATION_MANUAL_SUCCESS';
export const POST_RECOMMENDATION_MANUAL_FAILURE = 'POST_RECOMMENDATION_MANUAL_FAILURE';

export function postRecommendationManual(id, recommendation) {
  return {
    [CALL_API]: {
      endpoint: `recommendations/manual/${id}`,
      authenticated: true,
      types: [
        POST_RECOMMENDATION_MANUAL_REQUEST,
        POST_RECOMMENDATION_MANUAL_SUCCESS,
        POST_RECOMMENDATION_MANUAL_FAILURE,
      ],
      method: 'POST',
      data: recommendation,
      sendJSON: true,
    },
  };
}

/**
 * POST recommendation/position when a referred candidate refers someone in turn
 */
export const POST_REFERRAL_RECOMMENDATION_REQUEST = 'POST_REFERRAL_RECOMMENDATION_REQUEST';
export const POST_REFERRAL_RECOMMENDATION_SUCCESS = 'POST_REFERRAL_RECOMMENDATION_SUCCESS';
export const POST_REFERRAL_RECOMMENDATION_FAILURE = 'POST_REFERRAL_RECOMMENDATION_FAILURE';

export function postReferralRecommendationPosition(id, recommendation) {
  return {
    [CALL_API]: {
      endpoint: `recommendations/externalrecommendation/${id}`,
      authenticated: false,
      types: [
        POST_REFERRAL_RECOMMENDATION_REQUEST,
        POST_REFERRAL_RECOMMENDATION_SUCCESS,
        POST_REFERRAL_RECOMMENDATION_FAILURE,
      ],
      method: 'POST',
      data: recommendation,
    },
  };
}

/**
 * POST recommendation/interest
 */
export const POST_RECOMMENDATION_INTEREST_REQUEST = 'POST_RECOMMENDATION_INTEREST_REQUEST';
export const POST_RECOMMENDATION_INTEREST_SUCCESS = 'POST_RECOMMENDATION_INTEREST_SUCCESS';
export const POST_RECOMMENDATION_INTEREST_FAILURE = 'POST_RECOMMENDATION_INTEREST_FAILURE';

export function postRecommendationInterest(id, interested, data = null) {
  return {
    [CALL_API]: {
      endpoint: `recommendations/interested/${id}?interested=${interested}`,
      authenticated: false,
      types: [
        POST_RECOMMENDATION_INTEREST_REQUEST,
        POST_RECOMMENDATION_INTEREST_SUCCESS,
        POST_RECOMMENDATION_INTEREST_FAILURE,
      ],
      method: 'POST',
      data: data,
    },
  };
}

/**
 * POST recommendation/confirm
 */
export const POST_RECOMMENDATION_CONFIRM_REQUEST = 'POST_RECOMMENDATION_CONFIRM_REQUEST';
export const POST_RECOMMENDATION_CONFIRM_SUCCESS = 'POST_RECOMMENDATION_CONFIRM_SUCCESS';
export const POST_RECOMMENDATION_CONFIRM_FAILURE = 'POST_RECOMMENDATION_CONFIRM_FAILURE';

export function postRecommendationConfirm(id, userData) {
  return {
    [CALL_API]: {
      endpoint: `recommendations/confirm/${id}`,
      authenticated: true,
      types: [
        POST_RECOMMENDATION_CONFIRM_REQUEST,
        POST_RECOMMENDATION_CONFIRM_SUCCESS,
        POST_RECOMMENDATION_CONFIRM_FAILURE,
      ],
      method: 'POST',
      data: userData,
    },
  };
}

/**
 * GET recommendation for position
 */
export const GET_RECOMMENDATION_FOR_POSITION_REQUEST = 'GET_RECOMMENDATION_FOR_POSITION_REQUEST';
export const GET_RECOMMENDATION_FOR_POSITION_SUCCESS = 'GET_RECOMMENDATION_FOR_POSITION_SUCCESS';
export const GET_RECOMMENDATION_FOR_POSITION_FAILURE = 'GET_RECOMMENDATION_FOR_POSITION_FAILURE';

export function getRecommendationsForPosition(id) {
  return {
    [CALL_API]: {
      endpoint: `recommendations/position/${id}?expand=all`,
      authenticated: true,
      types: [
        GET_RECOMMENDATION_FOR_POSITION_REQUEST,
        GET_RECOMMENDATION_FOR_POSITION_SUCCESS,
        GET_RECOMMENDATION_FOR_POSITION_FAILURE,
      ],
      meta: { id: id },
    },
  };
}

/**
 * GET recommendation for position
 */
export const GET_MULTIPLE_RECOMMENDATION_FOR_POSITION_REQUEST =
  'GET_MULTIPLE_RECOMMENDATION_FOR_POSITION_REQUEST';
export const GET_MULTIPLE_RECOMMENDATION_FOR_POSITION_SUCCESS =
  'GET_MULTIPLE_RECOMMENDATION_FOR_POSITION_SUCCESS';
export const GET_MULTIPLE_RECOMMENDATION_FOR_POSITION_FAILURE =
  'GET_MULTIPLE_RECOMMENDATION_FOR_POSITION_FAILURE';

export function getMultipleRecommendationsForPosition(id) {
  return {
    [CALL_API]: {
      endpoint: `recommendations/position/${id}?expand=all`,
      authenticated: true,
      types: [
        GET_MULTIPLE_RECOMMENDATION_FOR_POSITION_REQUEST,
        GET_MULTIPLE_RECOMMENDATION_FOR_POSITION_SUCCESS,
        GET_MULTIPLE_RECOMMENDATION_FOR_POSITION_FAILURE,
      ],
      meta: { id: id },
    },
  };
}

/**
 * Update recommendation for position
 */
export const UPDATE_RECOMMENDATION_FOR_POSITION_REQUEST =
  'UPDATE_RECOMMENDATION_FOR_POSITION_REQUEST';
export const UPDATE_RECOMMENDATION_FOR_POSITION_SUCCESS =
  'UPDATE_RECOMMENDATION_FOR_POSITION_SUCCESS';
export const UPDATE_RECOMMENDATION_FOR_POSITION_FAILURE =
  'UPDATE_RECOMMENDATION_FOR_POSITION_FAILURE';

export function updateRecommendationForPosition(id, state, data = null) {
  return {
    [CALL_API]: {
      endpoint: `recommendations/single/${id}?state=${state}`,
      authenticated: true,
      types: [
        UPDATE_RECOMMENDATION_FOR_POSITION_REQUEST,
        UPDATE_RECOMMENDATION_FOR_POSITION_SUCCESS,
        UPDATE_RECOMMENDATION_FOR_POSITION_FAILURE,
      ],
      method: 'POST',
      data: data,
    },
  };
}

/**
 * Empty recommendation for position
 */
export const EMPTY_RECOMMENDATION_FOR_POSITION = 'EMPTY_RECOMMENDATION_FOR_POSITION';
export function emptyRecommendationsForPosition(id) {
  return {
    type: EMPTY_RECOMMENDATION_FOR_POSITION,
    response: [],
  };
}

/**
 * GET Stages for the recommendations for a specific position
 */

export const GET_RECOMMENDATION_STAGES_FOR_POSITION_REQUEST =
  'GET_RECOMMENDATION_STAGES_FOR_POSITION_REQUEST';
export const GET_RECOMMENDATION_STAGES_FOR_POSITION_SUCCESS =
  'GET_RECOMMENDATION_STAGES_FOR_POSITION_SUCCESS';
export const GET_RECOMMENDATION_STAGES_FOR_POSITION_FAILURE =
  'GET_RECOMMENDATION_STAGES_FOR_POSITION_FAILURE';

export function getRecommendationStagesForPosition(id) {
  return {
    [CALL_API]: {
      endpoint: `recommendations/position/${id}/stages`,
      authenticated: true,
      types: [
        GET_RECOMMENDATION_STAGES_FOR_POSITION_REQUEST,
        GET_RECOMMENDATION_STAGES_FOR_POSITION_SUCCESS,
        GET_RECOMMENDATION_STAGES_FOR_POSITION_FAILURE,
      ],
      method: 'GET',
    },
  };
}

import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import OptionalInvite from '../../components/admin/OptionalInvite';
import Loader from '../../components/Loader';
import PageHeader from '../../components/admin/PageHeader';
import CommunicationItem from '../../components/admin/CommunicationItem';
import OtherCommunication from '../../components/admin/OtherCommunication';
import LaunchCommunication from '../../components/admin/LaunchCommunication';
import ArchivedLaunch from '../../components/admin/ArchivedLaunch';
import CommunicationModal from '../../components/CommunicationModal';

import useFetchBreadcrumb from '../../hooks/helper/useFetchBreadcrumb.js';
import useFetchCommunications from '../../hooks/communication/useFetchCommunications.js';

import {
  getIntervals,
  getOnDemandEmails,
  getPushNotifications,
  toggleActive,
  toggleOnDemandEmail,
  togglePushNotification,
} from '../../irecommend-lib/actions/communicationActions';

import { fetchCustomerLanguage } from '../../helpers/language.js';
import {
  addLaunchTimestampForSorting,
  extractTemplateType,
  fetchAdminInvitation,
  fetchArchivedLaunchIntervals,
  fetchEmailsInvitesExcluded,
  fetchTeaserAndFollowup,
  fetchOnlyPushNotificationTypes,
  fetchRecurringIntervals,
  fetchUserInvitation,
  getSortedOtherCommunication,
  isCustomerLaunched,
  isOnlyPushType,
} from '../../helpers/communication.js';
import { useTranslation } from 'react-i18next';

const CommunicationPage = (props) => {
  const [modalState, setModalState] = useState({
    showModalActivate: false,
    intervallToToggleActiveId: '',
  });
  const [communications, setCommunications] = useState({
    sortedLaunch: [],
    sortedOther: [],
    pushNotifications: [],
    sortedArchived: [],
  });
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { customer, intervalsResp, onDemandEmailsResp, pushNotificationsResp, isLoading } =
    useFetchCommunications();
  const breadcrumbs = useFetchBreadcrumb('communication', t);

  const isLaunched = isCustomerLaunched(customer.response.customer);
  const isLaunchDateSet = customer.response.customer?.launch_date_set;

  const optionalInviteContent = [
    {
      content: <OptionalInvite t={t} />,
      id: 'optional-invite',
      title: t('communication-optional-invite-title'),
    },
  ];

  /**
   ** Launch Section
   **/
  const sortLaunchCommunicationSection = useCallback(() => {
    const userInvitation = !isLaunched ? fetchUserInvitation(onDemandEmailsResp) : null;
    const notSentLaunchIntervals = fetchTeaserAndFollowup(intervalsResp);
    let sortedLaunch = [];

    if (userInvitation) {
      if (!isLaunchDateSet) {
        sortedLaunch = [notSentLaunchIntervals[0], userInvitation, notSentLaunchIntervals[1]];
      } else {
        const userInvitationWithTimestamp = addLaunchTimestampForSorting(
          customer.response?.customer,
          userInvitation,
        );
        notSentLaunchIntervals.push(userInvitationWithTimestamp);
        sortedLaunch = notSentLaunchIntervals.sort((x, y) => x.timestamp - y.timestamp);
      }
    } else {
      sortedLaunch = notSentLaunchIntervals.sort((x, y) => x.timestamp - y.timestamp);
    }
    setCommunications((communications) => ({ ...communications, sortedLaunch }));
  }, [isLaunched, onDemandEmailsResp, intervalsResp, customer.response?.customer, isLaunchDateSet]);

  const sortedLaunchCommunications = communications.sortedLaunch.map((commItem) => {
    return (
      <CommunicationItem
        key={commItem._id}
        id={commItem._id}
        communicationItem={commItem}
        isLaunchDateSet={isLaunchDateSet}
        isLaunched={isLaunched}
        type={commItem.on_demand && 'ondemand'}
        handleToggleActive={handleToggleActive}
      />
    );
  });

  /**
   ** Other Communication Section
   **/
  const sortOtherCommunicationSection = useCallback(() => {
    const onDemandEmailsInvitesExcluded = fetchEmailsInvitesExcluded(onDemandEmailsResp); // We need to sort them separately
    const recurringIntervals = fetchRecurringIntervals(intervalsResp);
    const pushNotifications = fetchOnlyPushNotificationTypes(pushNotificationsResp);
    const userInvitation = isLaunched ? fetchUserInvitation(onDemandEmailsResp) : null;
    const adminInvitationEmail = fetchAdminInvitation(onDemandEmailsResp);
    const customerLang = fetchCustomerLanguage(customer);

    const otherCommunications = [
      ...recurringIntervals,
      ...onDemandEmailsInvitesExcluded,
      ...pushNotifications,
      adminInvitationEmail,
    ];

    if (userInvitation) otherCommunications.push(userInvitation);

    const sortedOther = getSortedOtherCommunication(otherCommunications, customerLang);

    setCommunications((communications) => ({ ...communications, sortedOther }));
  }, [customer, onDemandEmailsResp, intervalsResp, pushNotificationsResp, isLaunched]);

  const sortedOtherCommunications = communications.sortedOther.map((commItem) => {
    return (
      <CommunicationItem
        key={commItem._id}
        id={commItem._id}
        isLaunchDateSet={isLaunchDateSet}
        communicationItem={commItem}
        isLaunched={isLaunched}
        type={commItem.on_demand && 'ondemand'}
        handleToggleActive={handleToggleActive}
      />
    );
  });

  /**
   ** Archived Communication Section
   **/
  const sortArchivedCommunicationSection = useCallback(() => {
    const archivedLaunchIntervals = fetchArchivedLaunchIntervals(intervalsResp);

    setCommunications((communications) => ({
      ...communications,
      sortedArchived: archivedLaunchIntervals,
    }));
  }, [intervalsResp]);

  const archivedLaunchIntervals = communications.sortedArchived.map((commItem) => (
    <CommunicationItem key={commItem._id} communicationItem={commItem} isLaunched={isLaunched} />
  ));

  useEffect(() => {
    if (
      intervalsResp.length > 0 &&
      onDemandEmailsResp.length > 0 &&
      pushNotificationsResp.length > 0
    ) {
      sortLaunchCommunicationSection();
      sortOtherCommunicationSection();
      sortArchivedCommunicationSection();
    }
  }, [
    intervalsResp,
    onDemandEmailsResp,
    pushNotificationsResp,
    sortLaunchCommunicationSection,
    sortOtherCommunicationSection,
    sortArchivedCommunicationSection,
  ]);

  function handleToggleActive(commItem, type) {
    if (isOnlyPushType(extractTemplateType(commItem.template_unique_name))) {
      dispatch(togglePushNotification(commItem._id));
      dispatch(getPushNotifications());
    } else if (type === 'ondemand') {
      dispatch(toggleOnDemandEmail(commItem._id));
      dispatch(getOnDemandEmails());
    } else {
      const todayTimestamp = Math.floor(Date.now() / 1000);
      if (commItem.timestamp < todayTimestamp && !commItem.active) {
        setModalState({
          ...modalState,
          showModalActivate: true,
          intervallToToggleActiveId: commItem._id,
        });
      } else {
        dispatch(toggleActive(commItem._id));
        dispatch(getIntervals());
      }
    }
  }

  const onConfirmToggleActive = (id) => {
    dispatch(toggleActive(id));
    dispatch(getIntervals());
  };

  const onPressGoToEdit = (id) => history.push(`/admin/communication/edit/${id}`);

  return (
    <Loader isLoading={isLoading}>
      <PageHeader title={t('admin-header-communication')} breadcrumbs={breadcrumbs} />
      {modalState.showModalActivate && (
        <CommunicationModal
          title={t('generic-intervall-activate')}
          description={t('generic-intervall-active-description')}
          type="Modal"
          confirmCallback={(e) => {
            e.preventDefault();
            onConfirmToggleActive(modalState.intervallToToggleActiveId);
            setModalState({ ...modalState, showModalActivate: false });
          }}
          confirmChangeDate={(e) => {
            e.preventDefault();
            onPressGoToEdit(modalState.intervallToToggleActiveId);
            setModalState({ ...modalState, showModalActivate: false });
          }}
          toggleActive={true}
          cancelCallback={() => setModalState({ ...modalState, showModalActivate: false })}
        />
      )}
      <main aria-label="Main content">
        {sortedLaunchCommunications.length > 0 && (
          <LaunchCommunication
            t={t}
            sortedLaunchCommunications={sortedLaunchCommunications}
            optionalInviteContent={optionalInviteContent}
          />
        )}

        {sortedOtherCommunications.length > 0 && (
          <OtherCommunication
            t={t}
            sortedLaunchCommunications={sortedLaunchCommunications}
            optionalInviteContent={optionalInviteContent}
            sortedOtherCommunications={sortedOtherCommunications}
          />
        )}

        {archivedLaunchIntervals.length > 0 && (
          <ArchivedLaunch t={t} archivedLaunchIntervals={archivedLaunchIntervals} />
        )}
      </main>
    </Loader>
  );
};
export default CommunicationPage;

import React, { Component } from 'react';
import { authenticateUser } from '../irecommend-lib/actions/authActions';
import { connect } from 'react-redux';

import { getRecommendation } from '../irecommend-lib/actions/recommendationActions';
import { postRecommendationInterest } from '../irecommend-lib/actions/recommendationActions';
import { updateReferral } from '../irecommend-lib/actions/referralActions';
import { IntlNetwork } from '../helpers/language';

import ArticlePage from '../pages/ArticlePage';
import InterestedPage from '../pages/InterestedPage';
import { withTranslation } from 'react-i18next';

class InterestedContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      type: null, // How we know where to send users after we've updated referral
    };
  }

  componentDidMount() {
    if (!this.props.recommendation.response.referral) {
      this.props.getRecommendation(this.props.match.params.id);
    }
  }

  /**
   * Send users to the right place after updating referral
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.referral !== nextProps.referral && nextProps.referral.response === '') {
      if (this.state.type === 'social') {
        // Make sure the referral has been updated successfully before trying to authenticate
        // Pass the recommended ID through Auth0 process for callback
        this.props.authenticateUser(
          window.location.origin + '/callback/recommended',
          'openid email profile',
          'linkedin',
          { recommendationId: this.props.match.params.id },
        );
      } else {
        this.props.history.push(`/recommended/${this.props.match.params.id}/interested/supplement`);
      }
    }
  }

  render() {
    const recommendation = this.props.recommendation.response;
    const isLoading = recommendation.isLoading || !recommendation.referral;
    const { t } = this.props;

    // Read more about what happends after the form has been sent
    if (this.props.match.path.indexOf('read-more') !== -1) {
      return (
        <ArticlePage
          content={IntlNetwork(t, 'interestedcontainer-read-more-content-intl', {
            companyname: recommendation.companyname,
          })}
          header={t('interestedcontainer-read-more-header')}
          isLoading={isLoading}
        />
      );
    }

    return (
      <InterestedPage
        isLoading={isLoading}
        onPressSubmit={this.onPressSubmit}
        onPressSubmitLinkedIn={this.onPressSubmitLinkedIn}
        onReadMore={this.onReadMore}
        onTerms={this.onTerms}
        recommendation={recommendation}
        t={t}
      />
    );
  }

  /**
   * Submit without linkedin
   */
  onPressSubmit = (currentState) => {
    let data = {
      Name: currentState.name,
      Mail: currentState.email,
      Telephone: currentState.phone,
      Comment: currentState.comment,
      Visible: currentState.visible,
      Linkedin: false,
    };

    this.setState((prevState) => ({
      confirmation: true,
    }));

    const idreferral = this.props.recommendation.response.referral.idreferral;

    this.setState(
      {
        type: 'vanilla',
      },
      () => {
        this.props.updateReferral(idreferral, data);
      },
    );
  };

  /**
   * Submit with linkedin
   */
  onPressSubmitLinkedIn = (currentState) => {
    let data = {
      Name: currentState.name,
      Mail: currentState.email,
      Telephone: currentState.phone,
      Comment: currentState.comment,
      Visible: currentState.visible,
      Linkedin: true,
    };

    this.setState((prevState) => ({
      confirmation: true,
    }));

    const idreferral = this.props.recommendation.response.referral.idreferral;

    // If the user cancels the login we need to know where to redirect them
    localStorage.setItem('@irecommend:currentPath', window.location.pathname);

    // Update referral contact information
    this.setState(
      {
        type: 'social',
      },
      () => {
        this.props.updateReferral(idreferral, data);
      },
    );
  };

  /**
   * Read more about Terms and conditions
   */
  onReadMore = (e) => {
    e.preventDefault();
    window.open(`/recommended/${this.props.match.params.id}/interested/read-more`);
  };

  /**
   * Read more about Terms and conditions
   */
  onTerms = (e) => {
    e.preventDefault();
    window.open(`/recommended/${this.props.match.params.id}/interested/terms`);
    // this.props.history.push(`/recommended/${this.props.match.params.id}/interested/terms`);
  };
}

function mapStateToProps(state) {
  return {
    recommendation: state.recommendationState.recommendation,
    referral: state.referralState.referral,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    authenticateUser: (callbackURL, scope, connection, state) =>
      dispatch(authenticateUser(callbackURL, scope, connection, state)),
    getRecommendation: (recommendation) => dispatch(getRecommendation(recommendation)),
    postRecommendationInterest: (id, data) => dispatch(postRecommendationInterest(id, data)),
    updateReferral: (id, data) => dispatch(updateReferral(id, data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(InterestedContainer));

import { CALL_API } from '../middleware/api';

export const POST_TOKEN_REQUEST = 'POST_TOKEN_REQUEST';
export const POST_TOKEN_SUCCESS = 'POST_TOKEN_SUCCESS';
export const POST_TOKEN_FAILURE = 'POST_TOKEN_FAILURE';

/**
 * Post integration token
 */
export function postToken(data) {
  let endpoint = 'customers/token';

  return {
    [CALL_API]: {
      endpoint: endpoint,
      authenticated: true,
      types: [POST_TOKEN_REQUEST, POST_TOKEN_SUCCESS, POST_TOKEN_FAILURE],
      method: 'POST',
      data: data,
    },
  };
}

export const GET_TOKEN_REQUEST = 'GET_TOKEN_REQUEST';
export const GET_TOKEN_SUCCESS = 'GET_TOKEN_SUCCESS';
export const GET_TOKEN_FAILURE = 'GET_TOKEN_FAILURE';

/**
 * Check if token exist
 */
export function getToken() {
  let endpoint = 'customers/token';

  return {
    [CALL_API]: {
      endpoint: endpoint,
      authenticated: true,
      types: [GET_TOKEN_REQUEST, GET_TOKEN_SUCCESS, GET_TOKEN_FAILURE],
      method: 'GET',
    },
  };
}

export const VERIFY_TOKEN_REQUEST = 'VERIFY_TOKEN_REQUEST';
export const VERIFY_TOKEN_SUCCESS = 'VERIFY_TOKEN_SUCCESS';
export const VERIFY_TOKEN_FAILURE = 'VERIFY_TOKEN_FAILURE';
/**
 * GET validity status of existing token
 */
export function getTokenVerification(data = null) {
  let endpoint = 'customers/token/verification';

  return {
    [CALL_API]: {
      endpoint: endpoint,
      authenticated: true,
      types: [VERIFY_TOKEN_REQUEST, VERIFY_TOKEN_SUCCESS, VERIFY_TOKEN_FAILURE],
      method: 'POST',
      data: data,
    },
  };
}

export const GET_NEW_TOKEN_VALIDTY_REQUEST = 'GET_NEW_TOKEN_VALIDITY_REQUEST';
export const GET_NEW_TOKEN_VALIDTY_SUCCESS = 'GET_NEW_TOKEN_VALIDITY_SUCCESS';
export const GET_NEW_TOKEN_VALIDTY_FAILURE = 'GET_NEW_TOKEN_VALIDITY_FAILURE';
/**
 * GET validity status of new token
 */
export function getNewTokenValidity(data) {}

export const GET_INTEGRATIONTYPE_REQUEST = 'GET_INTEGRATIONTYPE_REQUEST';
export const GET_INTEGRATIONTYPE_SUCCESS = 'GET_INTEGRATIONTYPE_SUCCESS';
export const GET_INTEGRATIONTYPE_FAILURE = 'GET_INTEGRATIONTYPE_FAILURE';

export function getIntegrationType() {
  let endpoint = 'customers/integrationtype';

  return {
    [CALL_API]: {
      endpoint: endpoint,
      authenticated: true,
      types: [
        GET_INTEGRATIONTYPE_REQUEST,
        GET_INTEGRATIONTYPE_SUCCESS,
        GET_INTEGRATIONTYPE_FAILURE,
      ],
      method: 'GET',
      sendJSON: true,
    },
  };
}

import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { Color } from '../../config/styles';
import { Link } from 'react-router-dom';
import { css, StyleSheet } from 'aphrodite';
import Button from '../../components/Button';
import moment from 'moment';
import ProfilePicture from '../ProfilePicture';
import { withTranslation } from 'react-i18next';
// import Modal from '../Modal';

class TeamItem extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    picture: PropTypes.string,
    created: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
    meta: PropTypes.string,
    onPressDeleteItem: PropTypes.func.isRequired,
  };

  render() {
    const { name, picture, created, href, t } = this.props;

    const date = moment(created).format('YYYY-MM-DD');

    return (
      <div className={css(styles.listItem)}>
        <div className={css(styles.listSection)}>
          <Link to={href} className={css(styles.title)} aria-label="Team image">
            <ProfilePicture name={name} picture={picture} />
          </Link>
          <div>
            <Link to={href} className={css(styles.title)}>
              {name}
            </Link>
            <span className={css(styles.date)}>
              <strong>{t('generic-text-created-on')}:</strong> {date}
            </span>
          </div>
        </div>

        <div className={css(styles.listSection)}>
          <div className={css(styles.btnHolder)}>
            <Button
              type="cleanActions"
              title={t('generic-text-edit')}
              onPress={() => {
                this.props.onPressGoToEdit(this.props.id);
              }}
            />
          </div>
          <div className={css(styles.btnHolder)}>
            <Button
              type="cleanActions"
              title={t('generic-text-remove')}
              onPress={(e) => {
                this.props.onPressDeleteItem(e, this.props.id, this.props.name);
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(TeamItem);

const styles = StyleSheet.create({
  listItem: {
    borderRadius: '2px',
    boxShadow: '0 5px 17px 0 rgba(0, 0, 0, .03)',
    backgroundColor: Color.white,
    margin: '0 0 1.5625rem',
    // display: 'flex',
    justifyContent: 'space-between',
    display: 'grid',
    gridTemplateColumns: '2fr 1fr',
  },
  listSection: {
    display: 'flex',
    alignItems: 'center',
    padding: '0',

    ':first-child': {
      padding: '.75em 1.5em',
    },

    ':last-child': {
      justifyContent: 'flex-end',
    },
  },
  title: {
    margin: '0 0 .2em',
    fontSize: '1.05em',
    fontWeight: 'bold',
    display: 'block',
    color: Color.primary,
    textDecoration: 'none',
    marginRight: '.75em',
  },
  btnHolder: {
    display: 'inline-block',
    padding: '0',
    alingSelf: 'flex-end',
  },
});

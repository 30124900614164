/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  getPushNotifications,
  getSinglePushNotification,
} from '../../irecommend-lib/actions/communicationActions';

const useFetchPushNotification = (templateType) => {
  const { pushNotifications, singlePushNotification } = useSelector(
    (state) => state.communicationState,
    shallowEqual,
  );
  const dispatch = useDispatch();
  const idPushNotification = pushNotifications.response
    ? pushNotifications.response.find((notification) => notification.template_type === templateType)
        ?._id
    : '';

  const isLoading = pushNotifications.isLoading || singlePushNotification.isLoading;
  const error = pushNotifications.error || singlePushNotification.error;
  const pushNotification = singlePushNotification.response;

  useEffect(() => {
    if (pushNotifications.response?.length === 0) dispatch(getPushNotifications());
  }, [pushNotifications.response?.length]);

  useEffect(() => {
    if (idPushNotification) dispatch(getSinglePushNotification(idPushNotification));
  }, [idPushNotification]);

  return { pushNotification, isPushLoading: isLoading, error };
};

export default useFetchPushNotification;

import React, { Component } from 'react';
import { css, StyleSheet } from 'aphrodite';
import PropTypes from 'prop-types';
import { Color } from '../config/styles';
import { IconX, IconCheckmark } from './Icons';

class Notification extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    type: PropTypes.string,
  };

  renderType(type) {
    switch (type) {
      case 'success':
        return styles.notificationWrapperSuccess;
      case 'error':
        return styles.notificationWrapperError;
      default:
        return styles.notificationWrapperError;
    }
  }

  renderIcon(icon) {
    switch (icon) {
      case 'success':
        return <IconCheckmark />;
      case 'error':
        return <IconX />;
      default:
        return <IconX />;
    }
  }

  render() {
    const { title, type } = this.props;

    return (
      <div className={css(styles.notificationWrapper, this.renderType(type))}>
        <div className={css(styles.notificationInner)}>
          <span className={css(styles.notificationIcon)}>{this.renderIcon(type)}</span> {title}
        </div>
      </div>
    );
  }
}

export default Notification;

const styles = StyleSheet.create({
  notificationWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    top: '1em',
    left: '1em',
    right: '1em',
    backgroundColor: '#fff',
    boxShadow: '0 5px 17px 0 rgba(0, 0, 0, .03)',
    zIndex: '2',
    borderLeft: '8px solid #fff',
    animationName: {
      '90%': {
        top: '1em',
      },
      '100%': {
        top: '-5em',
      },
    },
    animationDuration: '6s',
    animationFillMode: 'forwards',
  },
  notificationWrapperSuccess: {
    borderColor: Color.primary,
    fill: Color.primary,
  },
  notificationWrapperError: {
    borderColor: Color.warning,
    fill: Color.warning,
  },
  notificationIcon: {
    width: '.8em',
    height: '.8em',
    display: 'inline-block',
    marginRight: '.5em',
  },
  notificationInner: {
    padding: '2em 1em',
  },
});

import {
  RESET_INDIVIDUALPOINTS_REQUEST,
  RESET_INDIVIDUALPOINTS_SUCCESS,
  RESET_INDIVIDUALPOINTS_FAILURE,
  RESET_TEAMPOINTS_REQUEST,
  RESET_TEAMPOINTS_SUCCESS,
  RESET_TEAMPOINTS_FAILURE,
  RESET_COMPANYPOINTS_REQUEST,
  RESET_COMPANYPOINTS_SUCCESS,
  RESET_COMPANYPOINTS_FAILURE,
} from '../actions/pointsActions';

const initialState = {
  piontsIndividual: {
    didInvalidate: false,
    error: false,
    isLoading: false,
    response: {},
  },
  piontsTeam: {
    didInvalidate: false,
    error: false,
    isLoading: false,
    response: {},
  },
  piontsCompany: {
    didInvalidate: false,
    error: false,
    isLoading: false,
    response: {},
  },
};

export default function pointsState(
  state = initialState,
  { authenticated, error, response, type, request },
) {
  switch (type) {
    case RESET_INDIVIDUALPOINTS_REQUEST:
      return {
        ...state,
        piontsIndividual: {
          ...state.piontsIndividual,
          isLoading: true,
        },
      };
    case RESET_INDIVIDUALPOINTS_SUCCESS:
      return {
        ...state,
        piontsIndividual: {
          ...state.piontsIndividual,
          isLoading: false,
          response: response,
        },
      };
    case RESET_INDIVIDUALPOINTS_FAILURE:
      return {
        ...state,
        piontsIndividual: {
          ...state.piontsIndividual,
          isLoading: false,
          error: true,
          response: error,
        },
      };

    case RESET_TEAMPOINTS_REQUEST:
      return {
        ...state,
        piontsTeam: {
          ...state.piontsTeam,
          isLoading: true,
        },
      };
    case RESET_TEAMPOINTS_SUCCESS:
      return {
        ...state,
        piontsTeam: {
          ...state.piontsTeam,
          isLoading: false,
          response: response,
        },
      };
    case RESET_TEAMPOINTS_FAILURE:
      return {
        ...state,
        piontsTeam: {
          ...state.piontsTeam,
          isLoading: false,
          error: true,
          response: error,
        },
      };

    case RESET_COMPANYPOINTS_REQUEST:
      return {
        ...state,
        piontsCompany: {
          ...state.piontsCompany,
          isLoading: true,
        },
      };
    case RESET_COMPANYPOINTS_SUCCESS:
      return {
        ...state,
        piontsCompany: {
          ...state.piontsCompany,
          isLoading: false,
          response: response,
        },
      };
    case RESET_COMPANYPOINTS_FAILURE:
      return {
        ...state,
        piontsCompany: {
          ...state.piontsCompany,
          isLoading: false,
          error: true,
          response: error,
        },
      };

    default:
      return state;
  }
}

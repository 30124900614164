import React from 'react';
import { css } from 'aphrodite';

export default function IrecDashboardItem(props) {
  const {
    customername,
    customerimage,
    invitedusers,
    activeusers,
    totalRecommendations,
    totalhires,
    totalinterviews,
    uniquerecommendations,
  } = props.insight;
  const { styles } = props;

  return (
    <div>
      <h2>{customername}</h2>
      <img className={css(styles.logo)} src={customerimage} alt="company" />
      <p>Invited users: {invitedusers}</p>
      <p>Active users: {activeusers}</p>
      <p>Total recommendations: {totalRecommendations}</p>
      <p>Total hires: {totalhires}</p>
      <p>Total interviews: {totalinterviews}</p>
      <p>Unique recommenders: {uniquerecommendations}</p>
    </div>
  );
}

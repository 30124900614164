import React, { useEffect, useState } from 'react';
import { Sidebar } from '@talentech/components';

import { connect } from 'react-redux';
import { sidebarFallbackApps, sidebarMenuLinks } from '../helpers/data';
import { getUserApps, getUserIfNeeded } from '../irecommend-lib/actions/userActions';
import { getCredentials } from '../irecommend-lib/actions/authActions';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

const AppSidebar = (props) => {
  const [formattedMenuLinks, setFormattedMenuLinks] = useState([]);
  const { t } = useTranslation();
  const location = window.location.pathname;
  const { user } = props;
  const userApps =
    Object.keys(props.userApps.response).length > 0 && Array.isArray(props.userApps.response)
      ? props.userApps.response
      : [];
  const isAdmin = user.response.user?.usertype === 1 || user.response.user?.usertype === 2;
  const showFallbackApps =
    props.userApps.request && !props.userApps.isLoading && props.userApps.request?.status === 500;

  useEffect(() => {
    formatMenuLinks();
  }, [location]);

  useEffect(() => {
    if (Object.keys(props.userApps?.response).length === 0) {
      props.getUserApps();
    }
  }, [props.userApps?.response]);

  useEffect(() => {
    if (Object.keys(user?.response).length === 0) {
      props.getUserIfNeeded();
    }
  }, [user?.response]);

  const formatMenuLinks = () => {
    //Asign onClick and translations to menu links
    let menuLinks = sidebarMenuLinks.map((section, index) => {
      const items = section.menuItems.map((link, idx) => {
        return {
          ...link,
          id: link.label,
          onClick: (e) => props.onPressMenuItem(e, link),
          label: t(link.label),
          isActive: link.link === location,
        };
      });

      return items;
    });

    setFormattedMenuLinks([
      { sectionName: '', menuItems: [...menuLinks[0].slice(0, 4)] },
      {
        sectionName: t('generic-input-Admin').toUpperCase(),
        menuItems: [...menuLinks[0].slice(4)],
      },
    ]);
  };

  const getIrecommendApp = () => {
    let activeApp;
    // We may get different results from navigation api with or without start page added
    if (props.userApps.request?.status !== 500) {
      activeApp = userApps
        ?.find((item) => item.hasOwnProperty('apps'))
        ?.apps?.filter(
          (item) => Object.values(item).indexOf(process.env.REACT_APP_TALENTECH_IRECOMMEND_ID) > -1,
        )[0];
    } else {
      activeApp = sidebarFallbackApps[0].apps[0];
    }

    return { ...activeApp, name: 'irecommend', disclaimer: 'Referrals by Talentech' };
  };

  return (
    <>
      {formattedMenuLinks.length > 0 ? (
        <Sidebar
          menu={isAdmin ? formattedMenuLinks : formattedMenuLinks?.slice(0, 1)}
          appSwitcher={!showFallbackApps ? userApps : sidebarFallbackApps}
          activeLanguage={i18next.language}
          activeApp={getIrecommendApp()}
          {...props}
        />
      ) : null}
    </>
  );
};
function mapStateToProps(state) {
  return {
    user: state.userState.user,
    userApps: state.userState.userApps,
    credentials: state.authState.authenticate,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    getUserApps: () => dispatch(getUserApps()),
    getCredentials: () => dispatch(getCredentials()),
    getUserIfNeeded: () => dispatch(getUserIfNeeded()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AppSidebar);

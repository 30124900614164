import {
  GET_COMPANY_REQUEST,
  GET_COMPANY_SUCCESS,
  GET_COMPANY_FAILURE,
  GET_COMPANY_INVALIDATE,
  POST_CUSTOMER_INFO_REQUEST,
  POST_CUSTOMER_INFO_SUCCESS,
  POST_CUSTOMER_INFO_FAILURE,
  UPDATE_CUSTOMER_LAUNCH_DATE_REQUEST,
  UPDATE_CUSTOMER_LAUNCH_DATE_SUCCESS,
  UPDATE_CUSTOMER_LAUNCH_DATE_FAILURE,
  GET_CUSTOMER_INSIGHTS_REQUEST,
  GET_CUSTOMER_INSIGHTS_SUCCESS,
  GET_CUSTOMER_INSIGHTS_FAILURE,
} from '../actions/companyActions';

const initialState = {
  company: {
    didInvalidate: false,
    error: false,
    isLoading: false,
    response: {},
    request: null,
  },
  insights: {
    error: false,
    isLoading: false,
    response: [],
  },
};

export default function companyState(
  state = initialState,
  { authenticated, request, error, response, type },
) {
  switch (type) {
    case GET_COMPANY_REQUEST:
      return {
        ...state,
        company: {
          ...state.company,
          isLoading: true,
        },
      };
    case GET_COMPANY_SUCCESS:
      return {
        ...state,
        company: {
          ...state.company,
          isLoading: false,
          response: response,
        },
      };
    case GET_COMPANY_FAILURE:
      return {
        ...state,
        company: {
          ...state.company,
          isLoading: false,
          error: true,
          response: error,
        },
      };
    case GET_COMPANY_INVALIDATE:
      return {
        ...state,
        company: {
          ...state.company,
          didInvalidate: true,
        },
      };
    case POST_CUSTOMER_INFO_REQUEST:
      return {
        ...state,
        company: {
          ...state.company,
          isLoading: true,
          error: false,
        },
      };
    case POST_CUSTOMER_INFO_SUCCESS:
      return {
        ...state,
        company: {
          ...state.company,
          isLoading: false,
          response: request.data,
          request: request,
        },
      };
    case POST_CUSTOMER_INFO_FAILURE:
      return {
        ...state,
        company: {
          ...state.company,
          isLoading: false,
          error: true,
          response: response ? response : {},
        },
      };
    case UPDATE_CUSTOMER_LAUNCH_DATE_REQUEST:
      return {
        ...state,
        company: {
          ...state.company,
          isLoading: true,
          error: false,
        },
      };
    case UPDATE_CUSTOMER_LAUNCH_DATE_SUCCESS:
      return {
        ...state,
        company: {
          ...state.company,
          isLoading: false,
          response: request.data,
          request: request,
        },
      };
    case UPDATE_CUSTOMER_LAUNCH_DATE_FAILURE:
      return {
        ...state,
        company: {
          isLoading: false,
          error: true,
          response: 'Failed to updload launch date',
        },
      };
    case GET_CUSTOMER_INSIGHTS_REQUEST:
      return {
        ...state,
        insights: {
          ...state.insights,
          isLoading: true,
          error: false,
        },
      };
    case GET_CUSTOMER_INSIGHTS_SUCCESS:
      return {
        ...state,
        insights: {
          isLoading: false,
          response: response ? response : [],
        },
      };
    case GET_CUSTOMER_INSIGHTS_FAILURE:
      return {
        ...state,
        insights: {
          isLoading: false,
          error: true,
          response: 'Failed to fetch insights',
        },
      };
    default:
      return state;
  }
}

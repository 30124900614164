import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite';

import { withRouter } from 'react-router';

import { defaultText, Font } from '../config/styles';
import Button from './Button';
import ProfilePicture from './ProfilePicture';
import { withTranslation } from 'react-i18next';

/**
 * Generates user profile information. Possible to choose appearance with ´size´ prop.
 *
 * Example usage:
 *
 * ```
 * <ProfileLink name="John Doe" picture={require('path/to/image.png') size="standard"} />
 * ```
 *
 */
class ProfileLink extends Component {
  constructor(props) {
    super(props);
    this.onPressProfilePicture = this.onPressProfilePicture.bind(this);
  }
  static propTypes = {
    /**
     * Used to show current level (with size=large & tiny)
     */
    level: PropTypes.number,

    /**
     * User name
     */
    name: PropTypes.string.isRequired,

    /**
     * Handler to be called when the user taps the profile button
     */
    onPress: PropTypes.func,

    /**
     * Source of image
     */
    picture: PropTypes.string.isRequired,

    /**
     * Used to show current points (with size=large & tiny)
     */
    points: PropTypes.number,

    /**
     * Sets ProfileLink component appearance and size. Valid values: tiny, standard, large
     */
    size: PropTypes.string,
    /**
     * Custom styles to be applied to the component.
     */
    style: PropTypes.object,
    /**
     * Wether to show points or not according to customertype
     */
    showPoints: PropTypes.bool,
  };

  static defaultProps = {
    size: 'standard',
    showPoints: true,
  };

  render() {
    const { t, level, name, onPress, picture, points, size, style, showPoints } = this.props;

    const formattedPoints = points ? new Intl.NumberFormat().format(points) : 0;

    return (
      <div className={css(styles.wrap, style)}>
        <span
          onClick={this.onPressProfilePicture}
          style={{ display: 'block', marginRight: 10, cursor: 'pointer' }}
        >
          <ProfilePicture
            name={name}
            picture={picture}
            size={size === 'large' ? 'xlarge' : size === 'tiny' ? 'small' : 'medium'}
          />
        </span>
        {size === 'large' ? (
          <div className={css(styles.largeInnerWrap)}>
            <h1 className={css(defaultText.h1, styles.largeName)}>{name}</h1>
            {showPoints && (
              <div className={css(defaultText.h3)}>
                {t('profilelink-subtitle-intl', { points: formattedPoints, level: level })}
              </div>
            )}
          </div>
        ) : size === 'tiny' ? (
          showPoints && (
            <div className={css(styles.tinyName)}>
              {t('profilelink-subtitle-uppercase-intl', { points: formattedPoints, level: level })}
            </div>
          )
        ) : (
          <div className={css(styles.innerWrap)}>
            <div className={css(styles.name)}>{name}</div>
            <Button
              title={t('profilelink-button')}
              onPress={onPress}
              type="linkUnderline"
              textAlign="left"
            />
          </div>
        )}
      </div>
    );
  }

  onPressProfilePicture = () => {
    this.props.history.push('/profile');
  };
}

export default withRouter(withTranslation()(ProfileLink));

const styles = StyleSheet.create({
  image: {
    marginRight: 10,
  },
  largeInnerWrap: {
    marginRight: 36,
    paddingTop: 6,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  largeName: {
    marginBottom: 10,
    marginTop: 0,
  },
  name: {
    backgroundColor: 'transparent',
    fontFamily: Font.defaultFont,
    fontWeight: 600,
    fontSize: 22,
    marginBottom: 2,
  },
  innerWrap: {
    paddingTop: 4,
  },
  tinyName: {
    backgroundColor: 'transparent',
    fontFamily: Font.defaultFont,
    fontWeight: 600,
    fontSize: 11,
    marginRight: 10,
    paddingTop: 8,
  },
  wrap: {
    display: 'flex',
    flexDirection: 'row',
  },
  noDecoration: {
    textDecoration: 'none',
  },
});

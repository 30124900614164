import { ActionButton, Box, Icon } from '@talentech/components';
import { TALENTECH_ICONS } from '../Icons';

const InvitationImportResults = (props) => {
  const { fileName, isUploading, isShowLog, invites, t, handleShowLog } = props;

  return (
    <>
      <Box className="row d-flex justify-content-between align-items-center border border-priamry py-3 rounded">
        <Box className="col-md-1 text-center">
          <Icon icon={TALENTECH_ICONS.file} iconSize="sm" />
        </Box>
        <Box className="col-md-9 text-center">
          <Box className="text-start fw-bold">{fileName} </Box>
          <Box className={`text-start ${!isUploading && 'text-success'}`}>
            {isUploading
              ? `${t('invitationpage-bulk-saving')}...`
              : `${t('invitationpage-bulk-finished')}!`}
          </Box>
        </Box>
        <Box className="col-md-2 text-center">
          <Box onClick={handleShowLog} style={{ cursor: 'pointer' }} className="list-group">
            <ActionButton
              label={
                isShowLog
                  ? `${t('invitationpage-bulk-hide-log')}`
                  : `${t('invitationpage-bulk-show-log')}`
              }
              variant="horizontal"
              size="md"
            />
          </Box>
        </Box>
      </Box>
      {isShowLog && !isUploading && (
        <>
          {invites.failedInvites.length > 0 && (
            <Box className="row">
              <Box className="col">
                <p className="fw-bold text-danger">
                  {t('invitationpage-bulk-internal-error-list')}
                </p>
                {invites.failedInvites.map((email, idx) => (
                  <p key={idx}>{email} </p>
                ))}
              </Box>
            </Box>
          )}

          {invites.invalidInvites.length > 0 && (
            <Box className="row">
              <Box className="col">
                {' '}
                <p className="fw-bold text-danger">{t('invitationpage-bulk-invalid-data')}</p>
                {invites.invalidInvites.map((invite, idx) => (
                  <p key={idx}>
                    {t('generic-input-Givenname')}:
                    {invite.Firstname ? (
                      <span className="mx-2">{invite.Firstname}</span>
                    ) : (
                      <span className="text-danger fw-bold mx-2">X</span>
                    )}
                    / {t('generic-input-Lastname')}:
                    {invite.Lastname ? (
                      <span className="mx-2">{invite.Lastname}</span>
                    ) : (
                      <span className="text-danger fw-bold mx-2">X</span>
                    )}
                    / {t('generic-input-Mail')}:{' '}
                    {invite.Email ? (
                      <span className="mx-2">{invite.Email} </span>
                    ) : (
                      <span className="text-danger fw-bold mx-2">X</span>
                    )}
                    / {t('generic-input-Admin')}:
                    {invite.Admin ? (
                      <span className="mx-2">{invite.Admin} </span>
                    ) : (
                      <span className="text-danger fw-bold mx-2">X</span>
                    )}
                  </p>
                ))}
              </Box>
            </Box>
          )}
          {invites.invalidEmails.length > 0 && (
            <Box className="row">
              <Box className="col">
                {' '}
                <p className="fw-bold text-danger">{t('invitationpage-bulk-invalid-email')}</p>
                {invites.invalidEmails.map((email, idx) => (
                  <p key={idx}>{email} </p>
                ))}
              </Box>
            </Box>
          )}
          {invites.duplicateInvites.length > 0 && (
            <Box className="row">
              <Box className="col">
                <p className="fw-bold text-info">{t('invitationpage-bulk-duplicate-error')}</p>
                {invites.duplicateInvites.map((email, idx) => (
                  <p key={idx}>{email} </p>
                ))}
              </Box>
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default InvitationImportResults;

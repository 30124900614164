import { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import useFetchPushNotification from './useFetchPushNotification';
import {
  getCustomer,
  getInterval,
  getSingleOnDemandEmail,
} from '../../irecommend-lib/actions/communicationActions';
import {
  extractTemplateType,
  fetchActionKey,
  fetchTargetKey,
  isCustomerLaunched,
  isEmailAndPushType,
  isEmailAndSmsType,
  isLaunchCommunicationType,
  isOnlyPushType,
  isRecurringInterval,
} from '../../helpers/communication';
import useFetchSmsNotification from './useFetchSmsCommunication';

const useFetchCommunication = (match) => {
  const { singleEmailTemplate, interval, singlePushNotification, customer } = useSelector(
    (state) => state.communicationState,
    shallowEqual,
  );

  const dispatch = useDispatch();
  const location = useLocation();
  const isOnDemand = location.state?.type === 'ondemand';
  const templateType = location.state?.templateType;

  const emailResponse =
    Object.keys(singleEmailTemplate.response).length > 0
      ? singleEmailTemplate.response
      : Object.keys(interval.response).length > 0
      ? interval.response
      : {};

  const { pushNotification, isPushLoading } = useFetchPushNotification(templateType);
  const { smsNotification, isSmsLoading } = useFetchSmsNotification(templateType);

  const onlyPushType = isOnlyPushType(templateType);
  const emailAndPushType = isEmailAndPushType(templateType);
  const emailAndSmsType = isEmailAndSmsType(templateType);
  const onlyEmailType = !onlyPushType && !emailAndPushType && !emailAndSmsType;
  const isLaunchType = isLaunchCommunicationType(templateType);
  const isInvitationEmail = templateType === 'invite-user';
  const isRecurringType = isRecurringInterval(
    extractTemplateType(emailResponse.template_unique_name),
  );

  const isLaunchDateSet = customer.response.customer?.launch_date_set;
  const launchTimeStamp = customer.response.customer?.launch_date;
  const isLaunched = isCustomerLaunched(customer.response.customer);

  const isLoading =
    singleEmailTemplate.isLoading ||
    interval.isLoading ||
    singlePushNotification.isLoading ||
    isPushLoading ||
    isSmsLoading;

  // invite-user template has no timestamp by default since it's a ondemand email
  // so we need to add it manually to show send time
  if (templateType === 'invite-user') emailResponse.timestamp = launchTimeStamp;

  useEffect(() => {
    Object.keys(customer.response).length === 0 && dispatch(getCustomer());
  }, [customer.response, dispatch]);

  useEffect(() => {
    if (!onlyPushType && isOnDemand) dispatch(getSingleOnDemandEmail(match.params.id));
    if (!onlyPushType && !isOnDemand) dispatch(getInterval(match.params.id));

    return () => {
      dispatch({ type: 'GET_INTERVAL_RESET' });
      dispatch({ type: 'GET_SINGLE_EMAIL_RESET' });
      dispatch({ type: 'GET_SINGLE_PUSH_NOTIFICATION_RESET' });
      dispatch({ type: 'GET_PUSH_NOTIFICATIONS_RESET' });
      dispatch({ type: 'PUT_SINGLE_PUSH_NOTIFICATION_RESET' });
    };
  }, [isOnDemand, onlyPushType, match.params.id, dispatch]);

  const communicationData = {
    emailResponse,
    pushNotificationResponse: pushNotification,
    smsNotificationResponse: smsNotification,
    isLaunchDateSet,
    titleKey: `communication-title-${templateType}`,
    recipientKey: fetchTargetKey(templateType),
    sendTimeKey: fetchActionKey(templateType, isLaunchType, isLaunchDateSet),
    isLaunchType,
    isInvitationEmail,
    isRecurringType,
    isLaunched,
    onlyPushType,
    onlyEmailType,
    emailAndPushType,
    emailAndSmsType,
  };

  return { communicationData, isLoading, isOnDemand };
};

export default useFetchCommunication;

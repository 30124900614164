import 'babel-polyfill';
import 'normalize.css';

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

import * as Sentry from '@sentry/browser';

import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { talentechTheme } from '@talentech/components';

import reportWebVitals from './reportWebVitals';
import { unregister } from './registerServiceWorker';

import configureStore from './store';
import App from './app';
import './index.css';

import userManager from './config/tidUserManager';
import { OidcProvider } from 'redux-oidc';

import './i18n';
import Loader from './components/Loader';
// If Sentry.io is setup, handle promise rejections
if (window.Raven) {
  window.addEventListener('unhandledrejection', (event) =>
    window.Raven.captureException(event.reason),
  );
}

const store = configureStore();

// @todo move to helper
if (!window.location.origin) {
  window.location.origin =
    window.location.protocol +
    '//' +
    window.location.hostname +
    (window.location.port ? ':' + window.location.port : '');
}

const init = () => {
  if (process.env.REACT_APP_SENTRY_DSN) {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
    });
  }

  document.title = 'irecommend';

  ReactDOM.render(
    <Provider store={store}>
      <OidcProvider store={store} userManager={userManager}>
        <ThemeProvider theme={talentechTheme}>
          <BrowserRouter>
            <Suspense fallback={<Loader isLoading={true} />}>
              <App />
            </Suspense>
          </BrowserRouter>
        </ThemeProvider>
      </OidcProvider>
    </Provider>,
    document.getElementById('root'),
  );
};

if (!global.Intl) {
  import('intl').then(() => {
    init();
  });
} else {
  init();
}

reportWebVitals();
// Unregister any previously set service workers
unregister();

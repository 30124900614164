import {
  POST_LAUNCH_DATE_REQUEST,
  POST_LAUNCH_DATE_SUCCESS,
  POST_LAUNCH_DATE_FAILURE,
  POST_CUSTOMER_REQUEST,
  POST_CUSTOMER_SUCCESS,
  POST_CUSTOMER_FAILURE,
  GET_CUSTOMER_REQUEST,
  GET_CUSTOMER_SUCCESS,
  GET_CUSTOMER_FAILURE,
  PUT_CUSTOMER_PROGRESS_REQUEST,
  PUT_CUSTOMER_PROGRESS_SUCCESS,
  PUT_CUSTOMER_PROGRESS_FAILURE,
  GET_INTERVALS_REQUEST,
  GET_INTERVALS_SUCCESS,
  GET_INTERVALS_FAILURE,
  GET_INTERVAL_REQUEST,
  GET_INTERVAL_SUCCESS,
  GET_INTERVAL_FAILURE,
  GET_INTERVAL_RESET,
  PUT_ACTIVE_REQUEST,
  PUT_ACTIVE_SUCCESS,
  PUT_ACTIVE_FAILURE,
  PUT_PREVIEW_EMAIL_REQUEST,
  PUT_PREVIEW_EMAIL_SUCCESS,
  PUT_PREVIEW_EMAIL_FAILURE,
  PUT_PREVIEW_EMAIL_RESET,
  PUT_INTERVAL_REQUEST, // Update
  PUT_INTERVAL_SUCCESS,
  PUT_INTERVAL_FAILURE,
  POST_INTERVAL_REQUEST, //Create
  POST_INTERVAL_SUCCESS,
  POST_INTERVAL_FAILURE,
  GET_INTERVAL_BY_ATT_REQUEST,
  GET_INTERVAL_BY_ATT_SUCCESS,
  GET_INTERVAL_BY_ATT_FAILURE,
  GET_PREMADE_TEMPLATES_REQUEST,
  GET_PREMADE_TEMPLATES_SUCCESS,
  GET_PREMADE_TEMPLATES_FAILURE,
  GET_SINGLE_TEMPLATE_REQUEST,
  GET_SINGLE_TEMPLATE_SUCCESS,
  GET_SINGLE_TEMPLATE_FAILURE,
  DELETE_COMPETITION_INTERVALS_REQUEST,
  DELETE_COMPETITION_INTERVALS_SUCCESS,
  DELETE_COMPETITION_INTERVALS_FAILURE,
  GET_EMAILS_REQUEST,
  GET_EMAILS_SUCCESS,
  GET_EMAILS_FAILURE,
  GET_SINGLE_EMAIL_REQUEST,
  GET_SINGLE_EMAIL_SUCCESS,
  GET_SINGLE_EMAIL_FAILURE,
  GET_SINGLE_EMAIL_RESET,
  PUT_SINGLE_EMAIL_REQUEST,
  PUT_SINGLE_EMAIL_SUCCESS,
  PUT_SINGLE_EMAIL_FAILURE,
  PUT_SINGLE_EMAIL_ACTIVE_REQUEST,
  PUT_SINGLE_EMAIL_ACTIVE_SUCCESS,
  PUT_SINGLE_EMAIL_ACTIVE_FAILURE,
  GET_SINGLE_PUSH_NOTIFICATION_REQUEST,
  GET_SINGLE_PUSH_NOTIFICATION_SUCCESS,
  GET_SINGLE_PUSH_NOTIFICATION_FAILURE,
  GET_SINGLE_PUSH_NOTIFICATION_RESET,
  GET_PUSH_NOTIFICATIONS_REQUEST,
  GET_PUSH_NOTIFICATIONS_SUCCESS,
  GET_PUSH_NOTIFICATIONS_FAILURE,
  GET_PUSH_NOTIFICATIONS_RESET,
  PUT_SINGLE_PUSH_NOTIFICATION_ACTIVE_REQUEST,
  PUT_SINGLE_PUSH_NOTIFICATION_ACTIVE_SUCCESS,
  PUT_SINGLE_PUSH_NOTIFICATION_ACTIVE_FAILURE,
  PUT_SINGLE_PUSH_NOTIFICATION_ACTIVE_RESET,
  PUT_SINGLE_PUSH_NOTIFICATION_REQUEST,
  PUT_SINGLE_PUSH_NOTIFICATION_SUCCESS,
  PUT_SINGLE_PUSH_NOTIFICATION_FAILURE,
  PUT_SINGLE_PUSH_NOTIFICATION_RESET,
  GET_SMS_NOTIFICATIONS_REQUEST,
  GET_SMS_NOTIFICATIONS_SUCCESS,
  GET_SMS_NOTIFICATIONS_FAILURE,
  GET_SMS_NOTIFICATIONS_RESET,
  GET_SINGLE_SMS_NOTIFICATION_REQUEST,
  GET_SINGLE_SMS_NOTIFICATION_SUCCESS,
  GET_SINGLE_SMS_NOTIFICATION_FAILURE,
  GET_SINGLE_SMS_NOTIFICATION_RESET,
  PUT_SINGLE_SMS_NOTIFICATION_REQUEST,
  PUT_SINGLE_SMS_NOTIFICATION_SUCCESS,
  PUT_SINGLE_SMS_NOTIFICATION_FAILURE,
  PUT_SINGLE_SMS_NOTIFICATION_RESET,
} from '../actions/communicationActions';

const initialState = {
  customer: {
    error: false,
    isLoading: false,
    response: {},
  },
  intervals: {
    didInvalidate: false,
    error: false,
    isLoading: false,
    response: [],
  },
  emailTemplates: {
    didInvalidate: false,
    error: false,
    isLoading: false,
    response: [],
  },
  singleEmailTemplate: {
    didInvalidate: false,
    error: false,
    isLoading: false,
    response: {},
  },
  emailTemplateActiveState: {
    didInvalidate: false,
    error: false,
    isLoading: false,
    response: {},
  },
  customEmailTemplate: {
    didInvalidate: false,
    error: false,
    isLoading: false,
    response: [],
  },
  interval: {
    didInvalidate: false,
    error: false,
    isLoading: false,
    response: {},
  },
  activeState: {
    didInvalidate: false,
    error: false,
    isLoading: false,
    response: {},
  },
  preview: {
    error: false,
    isLoading: false,
    response: {},
  },
  customInterval: {
    error: false,
    isLoading: false,
    response: {},
  },
  createInterval: {
    error: false,
    isLoading: false,
    response: {},
  },
  preMadeTemplates: {
    didInvalidate: false,
    error: false,
    isLoading: false,
    response: [],
  },
  singlePremadeTemplate: {
    isLoading: false,
    error: false,
    response: {},
  },

  templateDelete: {
    error: false,
    isLoading: false,
    response: {},
    request: null, // axios request response object
  },
  competitionOnDemands: {
    error: false,
    isLoading: false,
    response: [],
    requestSuccess: false,
  },
  pushNotifications: {
    didInvalidate: false,
    error: false,
    isLoading: false,
    response: [],
  },
  singlePushNotification: {
    didInvalidate: false,
    error: false,
    isLoading: false,
    response: {},
  },
  pushNotificationActiveState: {
    didInvalidate: false,
    error: false,
    isLoading: false,
    response: {},
  },
  customPushNotification: {
    didInvalidate: false,
    error: false,
    isLoading: false,
    response: {},
  },
  smsNotifications: {
    didInvalidate: false,
    error: false,
    isLoading: false,
    response: [],
  },
  singleSmsNotification: {
    didInvalidate: false,
    error: false,
    isLoading: false,
    response: {},
  },
  customSmsNotification: {
    didInvalidate: false,
    error: false,
    isLoading: false,
    response: {},
  },
};

export default function companyState(
  state = initialState,
  { authenticated, error, response, type },
) {
  switch (type) {
    case POST_LAUNCH_DATE_REQUEST:
      return {
        ...state,
        customer: {
          ...state.customer,
          isLoading: true,
          error: false,
        },
      };
    case POST_LAUNCH_DATE_SUCCESS:
      return {
        ...state,
        customer: {
          ...state.customer,
          isLoading: false,
          response: response,
        },
      };
    case POST_LAUNCH_DATE_FAILURE:
      return {
        ...state,
        customer: {
          ...state.customer,
          isLoading: false,
          error: true,
        },
      };
    case POST_CUSTOMER_REQUEST:
      return {
        ...state,
        customer: {
          ...state.customer,
          isLoading: true,
          error: false,
        },
      };
    case POST_CUSTOMER_SUCCESS:
      return {
        ...state,
        customer: {
          ...state.customer,
          isLoading: false,
          response: response,
        },
      };
    case POST_CUSTOMER_FAILURE:
      return {
        ...state,
        customer: {
          ...state.customer,
          isLoading: false,
          error: true,
        },
      };
    case GET_CUSTOMER_REQUEST:
      return {
        ...state,
        customer: {
          ...state.customer,
          isLoading: true,
          error: false,
        },
      };
    case GET_CUSTOMER_SUCCESS:
      return {
        ...state,
        customer: {
          ...state.customer,
          isLoading: false,
          response: response,
        },
      };
    case GET_CUSTOMER_FAILURE:
      return {
        ...state,
        customer: {
          ...state.customer,
          isLoading: false,
          error: true,
          response: response ? response : {},
        },
      };
    case PUT_CUSTOMER_PROGRESS_REQUEST:
      return {
        ...state,
        customer: {
          ...state.customer,
          isLoading: true,
          error: false,
        },
      };
    case PUT_CUSTOMER_PROGRESS_SUCCESS:
      return {
        ...state,
        customer: {
          ...state.customer,
          isLoading: false,
          response: response,
        },
      };
    case PUT_CUSTOMER_PROGRESS_FAILURE:
      return {
        ...state,
        customer: {
          ...state.customer,
          isLoading: false,
          error: true,
          response: response ? response : {},
        },
      };

    case GET_INTERVALS_REQUEST:
      return {
        ...state,
        intervals: {
          ...state.intervals,
          isLoading: true,
        },
      };
    case GET_INTERVALS_SUCCESS:
      return {
        ...state,
        intervals: {
          ...state.intervals,
          isLoading: false,
          response: response,
        },
      };
    case GET_INTERVALS_FAILURE:
      return {
        ...state,
        intervals: {
          ...state.intervals,
          isLoading: false,
          response: error,
          error: true,
        },
      };
    case GET_INTERVAL_REQUEST:
      return {
        ...state,
        interval: {
          ...state.interval,
          isLoading: true,
        },
      };
    case GET_INTERVAL_SUCCESS:
      return {
        ...state,
        interval: {
          ...state.interval,
          isLoading: false,
          response: response,
        },
      };
    case GET_INTERVAL_FAILURE:
      return {
        ...state,
        interval: {
          ...state.interval,
          isLoading: false,
          response: error,
          error: true,
        },
      };
    case GET_INTERVAL_RESET:
      return {
        ...state,
        interval: {
          ...state.interval,
          isLoading: false,
          response: {},
          error: false,
        },
      };

    case PUT_ACTIVE_REQUEST:
      return {
        ...state,
        activeState: {
          ...state.activeState,
          isLoading: true,
        },
      };
    case PUT_ACTIVE_SUCCESS:
      return {
        ...state,
        activeState: {
          ...state.activeState,
          isLoading: false,
          response: response,
        },
      };
    case PUT_ACTIVE_FAILURE:
      return {
        ...state,
        activeState: {
          ...state.activeState,
          isLoading: false,
          response: error,
          error: true,
        },
      };
    case PUT_PREVIEW_EMAIL_REQUEST:
      return {
        ...state,
        preview: {
          ...state.preview,
          isLoading: true,
        },
      };
    case PUT_PREVIEW_EMAIL_SUCCESS:
      return {
        ...state,
        preview: {
          ...state.preview,
          isLoading: false,
          response: response,
        },
      };
    case PUT_PREVIEW_EMAIL_FAILURE:
      return {
        ...state,
        preview: {
          ...state.preview,
          isLoading: false,
          response: error,
          error: true,
        },
      };
    case PUT_PREVIEW_EMAIL_RESET:
      return {
        ...state,
        preview: {
          ...state.preview,
          isLoading: false,
          response: {},
          error: false,
        },
      };
    case PUT_INTERVAL_REQUEST:
      return {
        ...state,
        customInterval: {
          ...state.customInterval,
          isLoading: true,
        },
      };
    case PUT_INTERVAL_SUCCESS:
      return {
        ...state,
        customInterval: {
          ...state.customInterval,
          isLoading: false,
          response: response,
        },
      };
    case PUT_INTERVAL_FAILURE:
      return {
        ...state,
        customInterval: {
          ...state.customInterval,
          isLoading: false,
          response: error,
          error: true,
        },
      };

    case POST_INTERVAL_REQUEST:
      return {
        ...state,
        createInterval: {
          ...state.createInterval,
          isLoading: false,
        },
      };
    case POST_INTERVAL_SUCCESS:
      return {
        ...state,
        createInterval: {
          ...state.createInterval,
          isLoading: false,
          response: response,
        },
      };
    case POST_INTERVAL_FAILURE:
      return {
        ...state,
        createInterval: {
          ...state.createInterval,
          response: error,
          error: true,
        },
      };
    // New intervals
    case GET_INTERVAL_BY_ATT_REQUEST:
      return {
        ...state,
        interval: {
          ...state.interval,
          isLoading: true,
        },
      };
    case GET_INTERVAL_BY_ATT_SUCCESS:
      return {
        ...state,
        interval: {
          ...state.interval,
          isLoading: false,
          response: response,
        },
      };
    case GET_INTERVAL_BY_ATT_FAILURE:
      return {
        ...state,
        interval: {
          ...state.interval,
          isLoading: false,
          response: error,
          error: true,
        },
      };

    case GET_PREMADE_TEMPLATES_REQUEST:
      return {
        ...state,
        preMadeTemplates: {
          ...state.preMadeTemplates,
          isLoading: true,
        },
      };
    case GET_PREMADE_TEMPLATES_SUCCESS:
      return {
        ...state,
        preMadeTemplates: {
          ...state.preMadeTemplates,
          isLoading: false,
          response: response,
        },
      };
    case GET_PREMADE_TEMPLATES_FAILURE:
      return {
        ...state,
        preMadeTemplates: {
          ...state.preMadeTemplates,
          isLoading: false,
          response: error,
          error: true,
        },
      };
    case GET_SINGLE_TEMPLATE_REQUEST:
      return {
        ...state,
        singlePremadeTemplate: {
          ...state.singlePremadeTemplate,
          isLoading: true,
          error: false,
        },
      };
    case GET_SINGLE_TEMPLATE_SUCCESS:
      return {
        ...state,
        singlePremadeTemplate: {
          ...state.singlePremadeTemplate,
          isLoading: false,
          response: response,
        },
      };
    case GET_SINGLE_TEMPLATE_FAILURE:
      return {
        ...state,
        singlePremadeTemplate: {
          ...state.singlePremadeTemplate,
          isLoading: false,
          error: true,
          response: error,
        },
      };

    case DELETE_COMPETITION_INTERVALS_REQUEST:
      return {
        ...state,
        competitionOnDemands: {
          ...state.competitionOnDemands,
          isLoading: true,
          error: false,
        },
      };
    case DELETE_COMPETITION_INTERVALS_SUCCESS:
      return {
        ...state,
        competitionOnDemands: {
          ...state.competitionOnDemands,
          isLoading: false,
          response: response,
        },
      };
    case DELETE_COMPETITION_INTERVALS_FAILURE:
      return {
        ...state,
        competitionOnDemands: {
          ...state.competitionOnDemands,
          isLoading: false,
          error: true,
          response: [],
        },
      };
    case GET_EMAILS_REQUEST:
      return {
        ...state,
        emailTemplates: {
          ...state.emailTemplates,
          isLoading: true,
          error: false,
        },
      };
    case GET_EMAILS_SUCCESS:
      return {
        ...state,
        emailTemplates: {
          ...state.emailTemplates,
          isLoading: false,
          response: response,
        },
      };
    case GET_EMAILS_FAILURE:
      return {
        ...state,
        emailTemplates: {
          ...state.emailTemplates,
          isLoading: false,
          error: true,
          response: [],
        },
      };
    case GET_SINGLE_EMAIL_REQUEST:
      return {
        ...state,
        singleEmailTemplate: {
          ...state.singleEmailTemplate,
          isLoading: true,
          error: false,
        },
      };
    case GET_SINGLE_EMAIL_SUCCESS:
      return {
        ...state,
        singleEmailTemplate: {
          ...state.singleEmailTemplate,
          isLoading: false,
          response: response,
        },
      };
    case GET_SINGLE_EMAIL_FAILURE:
      return {
        ...state,
        singleEmailTemplate: {
          ...state.singleEmailTemplate,
          isLoading: false,
          error: true,
          response: [],
        },
      };
    case GET_SINGLE_EMAIL_RESET:
      return {
        ...state,
        singleEmailTemplate: {
          ...state.singleEmailTemplate,
          isLoading: false,
          error: false,
          response: {},
        },
      };
    case PUT_SINGLE_EMAIL_ACTIVE_REQUEST:
      return {
        ...state,
        emailTemplateActiveState: {
          ...state.emailTemplateActiveState,
          isLoading: true,
        },
      };
    case PUT_SINGLE_EMAIL_ACTIVE_SUCCESS:
      return {
        ...state,
        emailTemplateActiveState: {
          ...state.emailTemplateActiveState,
          isLoading: false,
          response: response,
        },
      };
    case PUT_SINGLE_EMAIL_ACTIVE_FAILURE:
      return {
        ...state,
        emailTemplateActiveState: {
          ...state.emailTemplateActiveState,
          isLoading: false,
          response: error,
          error: true,
        },
      };
    case PUT_SINGLE_EMAIL_REQUEST:
      return {
        ...state,
        customEmailTemplate: {
          ...state.customEmailTemplate,
          isLoading: true,
        },
      };
    case PUT_SINGLE_EMAIL_SUCCESS:
      return {
        ...state,
        customEmailTemplate: {
          ...state.customEmailTemplate,
          isLoading: false,
          response: response,
        },
      };
    case PUT_SINGLE_EMAIL_FAILURE:
      return {
        ...state,
        customEmailTemplate: {
          ...state.customEmailTemplate,
          isLoading: false,
          response: error,
          error: true,
        },
      };

    case GET_SINGLE_PUSH_NOTIFICATION_REQUEST:
      return {
        ...state,
        singlePushNotification: {
          ...state.singlePushNotification,
          isLoading: true,
          error: false,
        },
      };
    case GET_SINGLE_PUSH_NOTIFICATION_SUCCESS:
      return {
        ...state,
        singlePushNotification: {
          ...state.singlePushNotification,
          isLoading: false,
          response: response,
        },
      };
    case GET_SINGLE_PUSH_NOTIFICATION_FAILURE:
      return {
        ...state,
        singlePushNotification: {
          ...state.singlePushNotification,
          isLoading: false,
          error: true,
          response: error,
        },
      };
    case GET_SINGLE_PUSH_NOTIFICATION_RESET:
      return {
        ...state,
        singlePushNotification: {
          ...state.singlePushNotification,
          isLoading: false,
          error: false,
          response: {},
        },
      };
    case GET_PUSH_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        pushNotifications: {
          ...state.pushNotifications,
          isLoading: true,
          error: false,
        },
      };
    case GET_PUSH_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        pushNotifications: {
          ...state.pushNotifications,
          isLoading: false,
          response: response,
        },
      };
    case GET_PUSH_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        pushNotifications: {
          ...state.pushNotifications,
          isLoading: false,
          error: true,
          response: response,
        },
      };
    case GET_PUSH_NOTIFICATIONS_RESET:
      return {
        ...state,
        pushNotifications: {
          ...state.pushNotifications,
          isLoading: false,
          error: false,
          response: [],
        },
      };
    case PUT_SINGLE_PUSH_NOTIFICATION_ACTIVE_REQUEST:
      return {
        ...state,
        pushNotificationActiveState: {
          ...state.pushNotificationActiveState,
          isLoading: true,
        },
      };
    case PUT_SINGLE_PUSH_NOTIFICATION_ACTIVE_SUCCESS:
      return {
        ...state,
        pushNotificationActiveState: {
          ...state.pushNotificationActiveState,
          isLoading: false,
          response: response,
        },
      };
    case PUT_SINGLE_PUSH_NOTIFICATION_ACTIVE_FAILURE:
      return {
        ...state,
        pushNotificationActiveState: {
          ...state.pushNotificationActiveState,
          isLoading: false,
          error: true,
          response: response,
        },
      };
    case PUT_SINGLE_PUSH_NOTIFICATION_ACTIVE_RESET:
      return {
        ...state,
        pushNotificationActiveState: {
          ...state.pushNotificationActiveState,
          isLoading: false,
          error: false,
          response: {},
        },
      };
    case PUT_SINGLE_PUSH_NOTIFICATION_REQUEST:
      return {
        ...state,
        customPushNotification: {
          ...state.customPushNotification,
          isLoading: true,
        },
      };
    case PUT_SINGLE_PUSH_NOTIFICATION_SUCCESS:
      return {
        ...state,
        customPushNotification: {
          ...state.customPushNotification,
          isLoading: false,
          response: response,
        },
      };
    case PUT_SINGLE_PUSH_NOTIFICATION_FAILURE:
      return {
        ...state,
        customPushNotification: {
          ...state.customPushNotification,
          isLoading: false,
          error: true,
          response: response,
        },
      };
    case PUT_SINGLE_PUSH_NOTIFICATION_RESET:
      return {
        ...state,
        customPushNotification: {
          ...state.customPushNotification,
          isLoading: false,
          error: false,
          response: {},
        },
      };
    case GET_SMS_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        smsNotifications: {
          ...state.smsNotifications,
          isLoading: true,
          error: false,
        },
      };
    case GET_SMS_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        smsNotifications: {
          ...state.smsNotifications,
          isLoading: false,
          response: response,
        },
      };
    case GET_SMS_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        smsNotifications: {
          ...state.smsNotifications,
          isLoading: false,
          error: true,
          response: response,
        },
      };
    case GET_SMS_NOTIFICATIONS_RESET:
      return {
        ...state,
        smsNotifications: {
          ...state.smsNotifications,
          isLoading: false,
          error: false,
          response: [],
        },
      };
    case GET_SINGLE_SMS_NOTIFICATION_REQUEST:
      return {
        ...state,
        singleSmsNotification: {
          ...state.singleSmsNotification,
          isLoading: true,
          error: false,
        },
      };
    case GET_SINGLE_SMS_NOTIFICATION_SUCCESS:
      return {
        ...state,
        singleSmsNotification: {
          ...state.singleSmsNotification,
          isLoading: false,
          response: response,
        },
      };
    case GET_SINGLE_SMS_NOTIFICATION_FAILURE:
      return {
        ...state,
        singleSmsNotification: {
          ...state.singleSmsNotification,
          isLoading: false,
          error: true,
          response: response,
        },
      };
    case GET_SINGLE_SMS_NOTIFICATION_RESET:
      return {
        ...state,
        singleSmsNotification: {
          ...state.singleSmsNotification,
          isLoading: false,
          error: false,
          response: {},
        },
      };
    case PUT_SINGLE_SMS_NOTIFICATION_REQUEST:
      return {
        ...state,
        customSmsNotification: {
          ...state.customSmsNotification,
          isLoading: true,
        },
      };
    case PUT_SINGLE_SMS_NOTIFICATION_SUCCESS:
      return {
        ...state,
        customSmsNotification: {
          ...state.customSmsNotification,
          isLoading: false,
          response: response,
        },
      };
    case PUT_SINGLE_SMS_NOTIFICATION_FAILURE:
      return {
        ...state,
        customSmsNotification: {
          ...state.customSmsNotification,
          isLoading: false,
          error: true,
          response: response,
        },
      };
    case PUT_SINGLE_SMS_NOTIFICATION_RESET:
      return {
        ...state,
        customSmsNotification: {
          ...state.customSmsNotification,
          isLoading: false,
          error: false,
          response: {},
        },
      };

    default:
      return state;
  }
}

import React from 'react';
import CountUp from 'react-countup';
import { useTranslation } from 'react-i18next';

const NumberViewBox = ({ number, nOfUsers }) => {
  // HACK: should be able to do this calculation earlier, not very reausable at all
  const isTipsPerUser = number.stylingclass === 'number-balls grayball';
  const data = isTipsPerUser ? (number.data / nOfUsers || 1).toFixed(1) : number.data;
  const decimal = isTipsPerUser ? 1 : 0;
  const { t } = useTranslation();
  return (
    <div className="number-view-box">
      <div
        className="number-view-bow-wrapper"
        style={{ padding: '10px 0 0 10px', borderRadius: 3 }}
      >
        <h3>{t(number.title)}</h3>

        <p>{t(number.desc)}</p>
      </div>
      <div>
        <h1>
          <CountUp start={0} end={data} decimals={decimal} duration={1} />
        </h1>
      </div>
    </div>
  );
};

export default NumberViewBox;

import React, { Component } from 'react';
import { css, StyleSheet } from 'aphrodite';
import PropTypes from 'prop-types';

import AdminWrapper from '../../components/admin/AdminWrapper';
import CompetitionIndividual from '../../components/admin/CompetitionIndividual';
import CompetitionTeam from '../../components/admin/CompetitionTeam';
import CompetitionCompany from '../../components/admin/CompetitionCompany';
import Modal from '../../components/Modal';
import Loader from '../../components/Loader';
import Button from '../../components/Button';
import PageHeader from '../../components/admin/PageHeader';
import { TALENTECH_ICONS } from '../../components/Icons';

import userflow from 'userflow.js';

import { setupUserflow } from '../../helpers/userflow';
import { withTranslation } from 'react-i18next';

class CompetitionPage extends Component {
  static propTypes = {
    /**
     * Array of competitions to be rendered
     */
    competitions: PropTypes.array.isRequired,
    /**
     * Language handler
     */
    isLoading: PropTypes.bool.isRequired,
    onPressConfirmDelete: PropTypes.func.isRequired,
    onPressEdit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    //Initiate Userflow
    userflow.init(process.env.REACT_APP_USERFLOW_TOKEN);

    this.state = {
      category: null,
      pointsID: null,
      showModalPonits: false,
      goalName: null,
      deleteIDGoal: null,
      showModalGoal: false,
    };
  }

  createPageHeaderButtons = () => {
    const { t, onPressAddCompetition } = this.props;
    return [
      {
        label: t('competition-add'),
        icon: TALENTECH_ICONS.plusCircled,
        onclick: () => onPressAddCompetition(),
      },
    ];
  };

  createBreadcrumb = () => {
    const { t, navigateToAdmin } = this.props;
    return [
      {
        label: t('admin-header-dashboard'),
        onClick: () => navigateToAdmin(),
      },
      {
        label: t('admin-header-competitions'),
        isActive: true,
      },
    ];
  };

  render() {
    const { competitions, t, isLoading, onPressEdit, onPressCompetitionDetails } = this.props;
    const indiComp = competitions
      .filter((competition) => competition.type === 1)
      .sort((a, b) => Date.parse(a.startdate) - Date.parse(b.startdate));

    const teamComp = competitions
      .filter((competition) => competition.type === 2)
      .sort((a, b) => Date.parse(a.startdate) - Date.parse(b.startdate));

    const compGoal = competitions
      .filter((competition) => competition.type === 3)
      .sort((a, b) => Date.parse(a.startdate) - Date.parse(b.startdate));

    return (
      <Loader isLoading={isLoading}>
        <PageHeader
          title={t('admin-header-competitions')}
          buttons={this.createPageHeaderButtons()}
          breadcrumbs={this.createBreadcrumb()}
        />
        <AdminWrapper>
          {this.state.showModalPonits && (
            <Modal
              title={`${t('competition-text-reset')} ${this.state.category} ${t(
                'competition-text-reset-points',
              )}?`}
              description={`${t('competition-reset-points-confirm')} ${this.state.category} ${t(
                'competition-text-reset-points',
              )}?`}
              type="remove"
              confirmCallback={(e) => {
                this.props.onPressConfirmResetPoints(this.state.pointsID);
                this.onPressCancelModal();
              }}
              cancelCallback={this.onPressCancelModal}
            />
          )}

          {/* Show modal for confirming deleting a goal */}
          {this.state.showModalGoal && (
            <Modal
              title={`${t('generic-text-remove')} ${this.state.goalName}?`}
              description={t('generic-text-remove-goal-description')}
              type="remove"
              confirmCallback={(e) => {
                this.props.onPressConfirmDelete(this.state.deleteIDGoal);
                this.onPressCancelModal();
              }}
              cancelCallback={this.onPressCancelModal}
            />
          )}

          <div className={css(styles.container)}>
            <div className={css(styles.titleContainer)}>
              <h1 className={css(styles.h1)}>{t('competition-individual')}</h1>
              <div className={css(styles.btnHolder)}>
                <Button
                  type="third"
                  title={t('competition-reset-points')}
                  onPress={() => this.onPressResetPoints(t('competition-individual'), 1)}
                />
              </div>
            </div>
            <CompetitionIndividual
              t={t}
              onPressResetPoints={this.onPressResetPoints}
              id={1}
              competitions={indiComp}
              onPressEdit={onPressEdit}
              onPressCompetitionDetails={onPressCompetitionDetails}
              onPressDeleteCompetition={this.onPressDelete}
            />

            <div className={css(styles.titleContainer)}>
              <h1 className={css(styles.h1)}>{t('competition-team')}</h1>
              <div className={css(styles.btnHolder)}>
                <Button
                  type="third"
                  title={t('competition-reset-points')}
                  onPress={() => this.onPressResetPoints(t('competition-team'), 2)}
                />
              </div>
            </div>
            <CompetitionTeam
              t={t}
              onPressResetPoints={this.onPressResetPoints}
              id={2}
              competitions={teamComp}
              onPressEdit={onPressEdit}
              onPressCompetitionDetails={onPressCompetitionDetails}
              onPressDeleteCompetition={this.onPressDelete}
            />

            <div className={css(styles.titleContainer)}>
              <h1 className={css(styles.h1)}>{t('competition-goals')}</h1>
              <div className={css(styles.btnHolder)}>
                <Button
                  type="third"
                  title={t('competition-reset-points')}
                  onPress={() => this.onPressResetPoints(t('competition-goals'), 3)}
                />
              </div>
            </div>
            <CompetitionCompany
              t={t}
              onPressResetPoints={this.onPressResetPoints}
              id={3}
              competitions={compGoal}
              onPressEdit={onPressEdit}
              onPressCompetitionDetails={onPressCompetitionDetails}
              onPressDeleteCompetition={this.onPressDelete}
            />
          </div>
        </AdminWrapper>
      </Loader>
    );
  }

  componentDidUpdate() {
    //Userflow setup
    setupUserflow(
      this.props.customer,
      this.props.user,
      this.props.userTenants,
      this.props.userflowSignatures,
    );
  }

  onPressDelete = (id, name) => {
    this.setState({
      goalName: name,
      deleteIDGoal: id,
      showModalGoal: true,
    });
  };

  onPressResetPoints = (name, id) => {
    this.setState({
      category: name,
      showModalPonits: true,
      pointsID: id,
    });
  };

  onPressCancelModal = () => {
    if (this.state.category != null) {
      this.setState({
        category: null,
        showModalPonits: false,
        pointsID: null,
      });
    } else if (this.state.goalName != null) {
      this.setState({
        goalName: null,
        deleteIDGoal: null,
        showModalGoal: false,
      });
    }
  };
}

export default withTranslation()(CompetitionPage);

const styles = StyleSheet.create({
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.67em 0',
    marginBottom: '5px',
  },
  h1: {
    display: 'inline-block',
    margin: '0 0.6em 0 0',
  },
  btnHolder: {
    display: 'inline-block',
    margin: '-0.5em 0.5em 0 0.5em',
    maxWidth: '180px',
  },
  span: {
    fontSize: '1.5em',
    fontWeight: '600',
  },
});

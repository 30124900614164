import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Redirect } from 'react-router';

import CreateCompetitionPage from '../../pages/admin/CreateCompetitionPage';

import { uploadPicture } from '../../irecommend-lib/actions/mediaActions';
import { getUser } from '../../irecommend-lib/actions/userActions';
import {
  postCompetition,
  getCompetition,
  updateCompetition,
} from '../../irecommend-lib/actions/competitionActions';
import { withTranslation } from 'react-i18next';

class CreateCompetitionContainer extends Component {
  state = {
    isSubmit: false,
    compType: '',
    competitionData: {},
  };

  componentDidMount() {
    this.props.getUser();
    if (this.props.match.params.id !== undefined) {
      this.props.getCompetition(this.props.match.params.id);
      try {
        this.props.getIntervalByAtt({
          idcompetition: this.props.match.params.id,
        });
      } catch (e) {}
    }
  }

  componentDidUpdate() {
    // Alert if there is an error in the request and reset the errors.
    if (
      (this.props.competitionPost.error || this.props.competitionUpdate.error) &&
      this.state.isSubmit
    ) {
      alert(this.props.t('competition-error-message'));
      this.props.competitionPost.error = false;
      this.props.competitionUpdate.error = false;
      this.setState({ isSubmit: false });
    }
    if (
      (this.props.competitionPost.requestSuccess || this.props.competitionUpdate.requestSuccess) &&
      this.state.isSubmit
    ) {
      this.props.competitionUpdate.requestSuccess = false;
      this.props.competitionPost.requestSuccess = false;
      this.props.history.go(-1);
    }
  }

  render() {
    const isLoading =
      this.props.competition.isLoading ||
      this.props.competitionPost.isLoading ||
      this.props.competitionUpdate.isLoading ||
      this.props.picture.isLoading;
    const isEdit = this.props.match.params.id !== undefined;
    const competition = this.props.competition.response;
    const { t } = this.props;

    const isError = this.props.competitionPost.error || this.props.competitionUpdate.error;
    if (isEdit && this.props.competition.error) {
      alert(t('competition-cannot-load'));
      return <Redirect to="/admin/competitions" />;
    }

    return (
      <div>
        <CreateCompetitionPage
          competition={competition}
          onPressSubmitForm={this.onPressSubmitForm}
          isLoading={isLoading}
          isEdit={isEdit}
          isError={isError}
        />
      </div>
    );
  }
  onPressSubmitForm = (data) => {
    const { uploadPicture } = this.props;

    if (data.ImageRaw) {
      // Upload image to Cloudinary
      uploadPicture(data.ImageRaw).then(() => {
        data.Image = this.props.picture.response.data.secure_url;
        this.postData(data);
      });
    } else {
      // Create/edit without uploading a picture
      this.postData(data);
    }
  };

  postData = (data) => {
    delete data.ImageRaw; // No need for the presentational version
    // Dates are required however company goals has no dates and these are therefore placeholder values.
    // TODO: When/if this date is changed, make sure to change validation in compomentDidUpdate
    if (data.Type === 3) {
      data.StartDate = '1970-01-01';
      data.EndDate = '1970-01-01';
    }

    const competitionData = {
      startdate: data.StartDate,
      enddate: data.EndDate,
      title: data.Title,
      ctype: data.Type,
    };
    // Create new or update existing competition
    if (this.props.match.params.id === undefined) {
      let id = null;
      this.setState(
        {
          isSubmit: true,
          competitionData: competitionData,
          compType: data.Type,
        },
        this.props.postCompetition(data, id),
      );
    } else {
      competitionData['idcompetition'] = this.props.match.params.id;
      this.setState(
        {
          isSubmit: true,
          competitionData: competitionData,
          compType: data.Type,
        },
        this.props.updateCompetition(data, this.props.match.params.id),
      );
    }
  };
}

function mapStateToProps(state) {
  return {
    competition: state.competitionState.competition,
    competitionPost: state.competitionState.competitionPost,
    competitionUpdate: state.competitionState.competitionUpdate,
    picture: state.mediaState.uploadPicture,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getUser: () => dispatch(getUser()),
    getCompetition: (id) => dispatch(getCompetition(id)),
    postCompetition: (data, id) => dispatch(postCompetition(data, id)),
    updateCompetition: (data, id) => dispatch(updateCompetition(data, id)),
    uploadPicture: (file) => dispatch(uploadPicture(file)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(CreateCompetitionContainer));

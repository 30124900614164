import { Box, Button } from '@talentech/components';
import { StyleSheet, css } from 'aphrodite';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const TabContentEditPush = (props) => {
  const { pushNotification, handleOnClickCancelEditPush, handleOnSubmitPush } = props;
  const { t } = useTranslation();
  const [formState, setFormState] = useState({
    dataChanged: false,
    subjectChanged: false,
    templateBodyChanged: false,
    initialState: { template_body: '', subject: '' },
    currentState: { template_body: '', subject: '' },
  });

  const maxCharacters = 150;
  const currentCharacterCount = formState.currentState.template_body.length;

  const setInitialData = useCallback(() => {
    if (pushNotification.template_body) {
      setFormState((prev) => ({
        ...prev,
        initialState: {
          ...prev.initialState,
          template_body: pushNotification.template_body,
          subject: pushNotification.subject,
        },
        currentState: {
          ...prev.currentState,
          template_body: pushNotification.template_body,
          subject: pushNotification.subject,
        },
      }));
    }
  }, [pushNotification.template_body, pushNotification.subject]);

  useEffect(() => {
    setInitialData();
  }, [setInitialData]);

  const handleOnChangeSubject = (e) => {
    setFormState((prev) => ({
      ...prev,
      subjectChanged: true,
      currentState: { ...prev.currentState, subject: e.target.value },
    }));
  };

  const handleOnChangeContent = (e) => {
    setFormState((prev) => ({
      ...prev,
      templateBodyChanged: true,
      currentState: { ...prev.currentState, template_body: e.target.value },
    }));
  };

  const handleOnSubmit = () => {
    setFormState((prev) => ({
      ...prev,
      subjectChanged: false,
      templateBodyChanged: false,
    }));

    let postData = formState.currentState;
    postData.template_id = pushNotification.template_id;
    postData.active = true;
    postData.template_body = formState.currentState.template_body;
    postData.template_changed = formState.templateBodyChanged;
    postData.isOnDemand = true;

    handleOnSubmitPush(formState.currentState);
  };

  return (
    <>
      <Box className="row m-0">
        <Box className="col ps-4 pe-4">
          <div className={css(styles.tabContentHeader)}>{t('communication-item-channel-push')}</div>
        </Box>
      </Box>
      <Box className="row mt-0">
        <Box className="col ps-4 pe-4">
          <label className={css(styles.label)} htmlFor="text">
            {t('generic-text-subject').toUpperCase()}
          </label>
          <input
            type="text"
            maxLength="50"
            className="form-control"
            name="subject"
            onChange={handleOnChangeSubject}
            value={formState.currentState.subject}
            id="text"
          />
        </Box>
      </Box>
      <Box className="row">
        <Box className="col ps-4 pe-4">
          <label className={css(styles.label)} htmlFor="text">
            {t('communication-preview-message').toUpperCase()}
          </label>
          <textarea
            className="form-control"
            name="template_body"
            maxLength={maxCharacters}
            id="text"
            rows="3"
            defaultValue={formState.currentState.template_body}
            onChange={handleOnChangeContent}
          />
          <p className={css(styles.characterCount)}>
            {t('communication-edit-character-count', { currentCharacterCount, maxCharacters })}
          </p>
        </Box>
      </Box>
      <Box className="row">
        <Box className="col ps-4 pb-3">
          <Button
            className={css(styles.editButton)}
            label={t('generic-text-save')}
            color="primary"
            size="sm"
            onClick={handleOnSubmit}
          />
          <Button
            className={css(styles.cancelButton)}
            label={t('generic-button-cancel')}
            color="primary"
            size="sm"
            outline
            onClick={handleOnClickCancelEditPush}
          />
        </Box>
      </Box>
    </>
  );
};

export default TabContentEditPush;

const styles = StyleSheet.create({
  tabContentHeader: {
    fontWeight: 'bold',
    fontSize: '1.5rem',
    lineHeight: '1.5rem',
    margin: '1.5rem 0',
  },
  label: {
    marginBottom: '8px',
    fontWeight: 600,
    fontSize: '0.75rem',
  },
  characterCount: {
    marginTop: '0.5rem',
    fontSize: '0.625rem',
  },
  cancelButton: {
    marginLeft: '0.5rem',
  },
});

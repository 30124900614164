import React, { Component } from 'react';
import { connect } from 'react-redux';
import { StyleSheet } from 'aphrodite';
import { getInsights } from '../../irecommend-lib/actions/companyActions';

import AdminWrapper from '../../components/admin/AdminWrapper';
import IrecDashboardItem from '../../components/admin/IrecDashboardItem';

class IrecDashboardContainer extends Component {
  componentDidMount() {
    this.props.getInsights();
  }

  render() {
    const insights = this.props.insights.response;

    const insitem = insights.map((item, i) => (
      <IrecDashboardItem styles={styles} insight={item} key={i} />
    ));
    return (
      <div>
        <AdminWrapper>
          <div>{insitem}</div>
        </AdminWrapper>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    insights: state.companyState.insights,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getInsights: () => dispatch(getInsights()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(IrecDashboardContainer);

const styles = StyleSheet.create({
  logo: {
    width: '50px',
  },
});

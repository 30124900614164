import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPositions } from '../../irecommend-lib/actions/positionActions';
import { FILTER_TYPES } from '../../helpers/position';

const useFetchPositionsByFilter = (selectedFilter) => {
  const { isLoading: isPositionsLoading, response: positions } = useSelector(
    (state) => state.positionState.positions,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPositions(selectedFilter === FILTER_TYPES.all ? '' : selectedFilter));
  }, [dispatch, selectedFilter]);

  return { isPositionsLoading, positions };
};

export default useFetchPositionsByFilter;

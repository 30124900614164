import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';
import { withTranslation } from 'react-i18next';

class BarDiagram extends Component {
  render() {
    if (!this.props.data) {
      return <div>Loading</div>;
    }

    return (
      <Bar
        data={{
          labels: this.props.data.labels,
          datasets: [
            {
              label: this.props.t(this.props.data.datasets[0].label),
              backgroundColor: this.props.data.datasets[0].backgroundColor,
              data: this.props.data.datasets[0].data,
            },
          ],
        }}
        height={350}
        options={{
          maintainAspectRatio: false,
          barValueSpacing: 60,
          title: {
            display: true,
            text: this.props.t('line-chart-total-header'),
            fontSize: 25,
          },
          legend: {
            display: true,
            position: 'top',
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        }}
      />
    );
  }
}

export default withTranslation()(BarDiagram);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import NumberViewBox from '../NumberViewBox';

class NumberView extends Component {
  // TODO: need to fill all the boxes with real data
  state = {
    hardcode: [
      {
        title: 'number-widget-total-registerd',
        desc: 'number-widget-total-registerd-desc',
        data: 999,
      },
      {
        title: 'number-widget-total-points',
        desc: 'number-widget-total-points-desc',
        data: this.props.points.totalpoints?.Int64,
      },
      {
        title: 'number-widget-total-teams',
        desc: 'number-widget-total-teams-desc',
        data: this.props.nOfteams,
      },
      {
        title: 'number-widget-total-positions',
        desc: 'number-widget-total-positions-desc',
        data: this.props.availablePositions,
      },
      {
        title: 'number-widget-total-points-left',
        desc: 'number-widget-total-points-left-desc',
        data: this.point(),
      },
      //the two last boxes dont have ther endpoint data
      // {
      //   title: 'Total mängd reward',
      //   desc: 'BLING BLING',
      //   data: 999,
      // },
      // {
      //   title: 'Vinnare',
      //   desc: 'Totalt Antal Vinnare',
      //   data: 0,
      // },
    ],
  };
  // HACK: will be fixed when all the data comes from redux
  componentDidMount() {
    let updateStateArray = this.state.hardcode.slice();
    updateStateArray[0] = {
      ...updateStateArray[0],
      data: this.props.userscore.length,
    };
    this.setState({ hardcode: updateStateArray });
  }
  // HACK: should probably add application state to the component state
  // using the spread operator
  renderReduxData() {
    const data = this.props.numbers;
    return data.map((numb) => (
      <NumberViewBox key={numb.title} number={numb} nOfUsers={this.props.userscore.length} />
    ));
  }
  renderHardData() {
    const hardcode = this.state.hardcode;
    return hardcode.map((hard) => <NumberViewBox key={hard.title} number={hard} />);
  }

  point() {
    //calculate how many points left to next goal
    let pointsLeft = 0;

    if (!this.props.points.goals) {
      return pointsLeft;
    }

    const answer = this.props.points.goals.filter(
      (goal) => goal.points >= this.props.points.totalpoints.Int64,
    );
    if (answer[0]) {
      pointsLeft = answer[0].points - this.props.points.totalpoints.Int64;
    }

    return pointsLeft;
  }

  render() {
    return (
      <div className="NumberView">
        {this.renderReduxData()}
        {this.renderHardData()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    numbers: state.BIDashboardState.number,
    userscore: state.BIDashboardState.userscore,
    availablePositions: state.BIDashboardState.positions.labels.length,
    points: state.BIDashboardState.teamgoals[0].data,
    nOfteams: state.BIDashboardState.teamscore.length,
  };
}

export default connect(mapStateToProps)(NumberView);

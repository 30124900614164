import { useState } from 'react';

import { ActionButton, Avatar, Box, Icon } from '@talentech/components';
import { StyleSheet, css } from 'aphrodite';
import moment from 'moment';

import { TALENTECH_ICONS } from '../Icons';
import DropdownMenu from './DropdownMenu';

import { Color } from '../../irecommend-lib/styles';
import { splitString } from '../../helpers/utils';
import { useTranslation } from 'react-i18next';

const InvitedMemberList = (props) => {
  const [toggleState, setToggleState] = useState({});
  const { user, isCustomerLaunched, handleDeleteInvite, handleRemoveUser, handleToggleUserAdmin } =
    props;
  const { t } = useTranslation();
  const userType = user?.usertype;
  const isUserRegistered = user?.registered;
  const isUserDirectorySynced = user?.isDirectorySynced;

  const avatarNameInitials =
    Object.keys(user).length > 0
      ? `${splitString(user.givenname, ' ')}${splitString(user.familyname, ' ')}`
      : '';

  const handleOpenDropdown = (id) => {
    setToggleState((open) => ({
      ...open,
      [id]: !open[id],
    }));
  };

  const fetchDropdownMenuList = () => {
    const dropdownMenuList = [
      {
        title:
          userType !== 0 && isUserRegistered
            ? t('admin-role-toggle-remove')
            : userType === 0 && isUserRegistered
            ? t('admin-role-toggle-add')
            : '',
        icon:
          userType !== 0 && isUserRegistered
            ? TALENTECH_ICONS.remove
            : userType === 0 && isUserRegistered
            ? TALENTECH_ICONS.multipleKey
            : '',
        onClick: () => handleToggleUserAdmin(user),
      },
      {
        title:
          !isUserRegistered && !isUserDirectorySynced
            ? t('generic-text-remove-invitation')
            : isUserRegistered && !isUserDirectorySynced
            ? t('invitationpage-disable-user')
            : null,
        icon:
          !isUserRegistered && !isUserDirectorySynced
            ? TALENTECH_ICONS.remove
            : isUserRegistered && !isUserDirectorySynced
            ? TALENTECH_ICONS.multipleRemove
            : null,
        onClick: () =>
          !isUserRegistered && !isUserDirectorySynced
            ? handleDeleteInvite(user, user?.iduser)
            : isUserRegistered && !isUserDirectorySynced
            ? handleRemoveUser(user, user.iduser)
            : null,
      },
    ];

    if (!isUserRegistered && !isUserDirectorySynced) {
      delete dropdownMenuList[0];
    }
    if (isUserRegistered && isUserDirectorySynced) {
      delete dropdownMenuList[1];
    }

    return dropdownMenuList;
  };

  return (
    <Box className=" d-flex my-2 px-3">
      <Box className="d-flex flex-fill align-items-center justify-content-between col-sm-7 col-md-5 py-1 px-3 bg-gray-50 border-end border-2">
        <Box className="col-7 d-flex align-items-center flex-lg-row">
          <Avatar size="lg">
            <span className="fw-bold">{avatarNameInitials}</span>
          </Avatar>
          <Box className="d-flex mx-1 py-1 ps-lg-3 flex-column">
            <span className="text-decoration-underline text-start">
              {user.givenname} {user.familyname}
            </span>
            <span className={css(styles.smallText)}>{user.mail}</span>
          </Box>
        </Box>
        <Box className="col-5 bg-gray-50 text-end ">
          {isUserDirectorySynced && (
            <span className="badge bg-info me-1">{t('invitationpage-user-synchronized')}</span>
          )}
          {!isUserRegistered && (
            <span className="badge bg-warning me-1">
              {isCustomerLaunched || userType !== 0
                ? t('generic-text-invited')
                : t('invitationpage-invitation-on-launch')}
            </span>
          )}
        </Box>
      </Box>
      <Box className="d-none d-md-flex align-items-center py-1 px-3 col-md-3 bg-gray-50 ">
        <span>{moment(user.created).format('YYYY-MM-DD')}</span>
      </Box>
      <Box className="d-none d-sm-flex align-items-center py-1 px-3 col-sm-3 col-md-3 bg-gray-50  border-end border-2">
        {userType !== 0 && <Icon icon={TALENTECH_ICONS.checkMarkWithCircle} />}
      </Box>
      <Box className="d-flex align-items-center justify-content-center py-1 px-3 col-1 col-md-1 bg-gray-50 rounded-end position-relative">
        {!isUserRegistered && isUserDirectorySynced ? (
          ''
        ) : (
          <>
            <ActionButton
              icon={TALENTECH_ICONS.more}
              variant="vertical"
              size="lg"
              className={css(styles.userOptionsButton)}
              onClick={() => handleOpenDropdown(user.iduser)}
              aria-label="Options"
            />

            {toggleState[user.iduser] && (
              <DropdownMenu
                toggleState={toggleState.toggleDropdown}
                setToggleState={setToggleState}
                dropdownMenuList={fetchDropdownMenuList()}
              />
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default InvitedMemberList;

const styles = StyleSheet.create({
  checkbox: {
    display: 'inline',
    position: 'absolute',
  },
  smallText: {
    fontSize: '.8rem',
    color: Color.gray,
    wordBreak: 'break-word',
  },
  dropdown: {
    position: 'absolute',
    width: '500px',
  },
  userOptionsButton: {
    padding: 0,
    paddingTop: '1rem',
    paddingBottom: '.55rem',
    paddingLeft: '.45rem',
    paddingRight: '.45rem',
    height: '45px',
    width: '45px',
    borderRadius: '50%',
  },
  userOptionsDropdown: {
    position: 'absolute',
    transform: 'translateX(-80px) translateY(40px)',
    width: '200px',
    zIndex: '1',
    cursor: 'pointer',
  },
});

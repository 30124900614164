import React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Color, Font } from '../../config/styles';

export default function Selector(props) {
  const numOfCategories = props.categories.length;
  const activeCategory = props.activeCategory;
  const categories = props.categories.map((category, index) => {
    if (index === 0) {
      return (
        <button
          key={index}
          className={css(
            styles.button,
            styles.leftButton,
            index === activeCategory - 1 ? styles.activeButton : styles.inactiveButton,
          )}
          onClick={() => props.changeCategory(index + 1)}
        >
          {category.text}
        </button>
      );
    } else if (index === numOfCategories - 1) {
      return (
        <button
          key={index}
          className={css(
            styles.button,
            styles.rightButton,
            index === activeCategory - 1 ? styles.activeButton : styles.inactiveButton,
          )}
          onClick={() => props.changeCategory(index + 1)}
        >
          {category.text}
        </button>
      );
    } else {
      return (
        <button
          key={index}
          className={css(
            styles.button,
            styles.middleButton,
            index === activeCategory - 1 ? styles.activeButton : styles.inactiveButton,
          )}
          onClick={() => props.changeCategory(index + 1)}
        >
          {category.text}
        </button>
      );
    }
  });
  return <div>{categories}</div>;
}

const styles = StyleSheet.create({
  button: {
    padding: '0.8em 2.4em',
    fontSize: '1,0625em',
    ':focus': { outline: '0' },
    borderTop: `2px solid ${Color.primary}`,
    borderBottom: `2px solid ${Color.primary}`,
    fontFamily: Font.defaultFont,
    fontWeight: 600,
  },
  activeButton: {
    backgroundColor: Color.primary,
    color: Color.baseGray,
  },
  inactiveButton: {
    backgroundColor: Color.baseGray,
    color: Color.primary,
  },
  middleButton: {
    borderRight: `1px solid ${Color.primary}`,
    borderLeft: `1px solid ${Color.primary}`,
  },
  leftButton: {
    borderLeft: `2px solid ${Color.primary}`,
    borderRight: `1px solid ${Color.primary}`,
    borderRadius: '25px 0 0 25px',
  },
  rightButton: {
    borderLeft: `1px solid ${Color.primary}`,
    borderRight: `2px solid ${Color.primary}`,
    borderRadius: '0 25px 25px 0',
  },
});

import React from 'react';
import { css } from 'aphrodite';

export default function FaqItem(props) {
  const { styles } = props;
  return (
    <div className={css(styles.wrap)} id={props.tag}>
      <h2 className={css(styles.h2)}>{props.title}</h2>
      <div>{props.children}</div>
    </div>
  );
}

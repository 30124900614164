import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Color } from '../config/styles';

/**
 * Wrapper for content placed in the header. Sets a background and padding.
 *
 * Example usage:
 *
 * ```
 * <HeaderWrapper>
 *  <Text>Content</Text>
 * </HeaderWrapper>
 * ```
 *
 */
export default class HeaderWrapper extends Component {
  static propTypes = {
    /**
     * Child components will be passed through ImageBackground
     */
    children: PropTypes.node,
    /**
     * Custom styles to be applied to the component.
     */
    style: PropTypes.object,
  };

  render() {
    const { children, style } = this.props;

    return <div className={css(styles.background, style)}>{children}</div>;
  }
}

const styles = StyleSheet.create({
  background: {
    alignSelf: 'stretch',
    minHeight: 180,
    padding: 22,
    paddingTop: 30,
    position: 'relative',
    backgroundColor: Color.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
});

import { Component } from 'react';
import { css, StyleSheet } from 'aphrodite';
// import PropTypes from 'prop-types';

import ButtonIcon from '../components/ButtonIcon';
import Loader from '../components/Loader';
import TabRecommendExternal from '../components/TabRecommendExternal';

import { Color } from '../config/styles';
import { withTranslation } from 'react-i18next';

class ExternalReferralPage extends Component {
  render() {
    const { t, isLoading, onPressInfoButton, onPressSubmit, status } = this.props;

    return (
      <Loader isLoading={isLoading}>
        <div className={css(styles.container)}>
          <div className={css(styles.innerWrap)}>
            <div className={css(styles.titleWrap)}>
              <span className={css(styles.title)}>{t('recommendpage-header')}</span>
              <ButtonIcon icon="info" onPress={onPressInfoButton} style={styles.infoButton} />
            </div>
          </div>

          <div className={css(styles.scrollView)}>
            <TabRecommendExternal
              isLoading={isLoading}
              onPressSubmit={onPressSubmit}
              status={status}
            />
          </div>
        </div>
      </Loader>
    );
  }
}

export default withTranslation()(ExternalReferralPage);

const styles = StyleSheet.create({
  container: {
    marginTop: 5,
  },
  close: {
    position: 'absolute',
    right: 0,
    top: 0,
    padding: 20,
  },
  // errorMessage: {
  // 	paddingTop: 5,
  // },
  innerWrap: {
    padding: 20,
  },
  infoButton: {
    paddingTop: 2,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 0,
  },
  scrollView: {
    padding: 20,
    paddingTop: 0,
  },
  tabBar: {
    borderBottomColor: 'rgba(0, 0, 0, .10)',
    borderBottomWidth: 1,
    paddingLeft: 20,
    paddingRight: 20,
    marginBottom: 12,
  },
  title: {
    fontWeight: 500,
    fontSize: 20,
    color: Color.dark,
  },
  titleWrap: {
    flexDirection: 'row',
    marginBottom: 4,
  },
});

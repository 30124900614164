import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Font } from '../config/styles';
import { css, StyleSheet } from 'aphrodite';

/**
 * Component for displaying statistics or other similar data.
 *
 * Example usage:
 *
 * ```
 * <QuickStats title="My recommendatons:" subtitle="people" value="4" />
 * ```
 *
 */
export default class QuickStats extends Component {
  static propTypes = {
    /**
     * Main title
     */
    title: PropTypes.string.isRequired,
    /**
     * Subtitle displayed after value
     */
    subtitle: PropTypes.string.isRequired,

    /**
     * Value to display. Could be anything.
     */
    value: PropTypes.string.isRequired,

    /**
     * Link for where to go when clicking the item.
     */
    link: PropTypes.string.isRequired,
  };

  render() {
    const { title, subtitle, value, link } = this.props;

    return (
      <div className={css(styles.wrap)}>
        <span className={css(styles.titleText)}>{title.toUpperCase()}</span>
        <Link to={link} className={css(styles.textWrap)}>
          <span className={css(styles.valueText)}>{value}</span>
          <span className={css(styles.subtitleText)}>{subtitle}</span>
        </Link>
      </div>
    );
  }
}

const styles = StyleSheet.create({
  wrap: {
    flex: 1,
    backgroundColor: 'transparent',
    marginBottom: '1.6rem',
  },
  textWrap: {
    display: 'flex',
    textDecoration: 'none',
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row',
  },
  titleText: {
    fontFamily: Font.defaultFont,
    fontSize: '.8rem',
    // letterSpacing: 0.8,
    fontWeight: 600,
  },
  valueText: {
    fontFamily: Font.defaultFont,
    fontWeight: 700,
    fontSize: '1.6rem',
    marginRight: 5,
  },
  subtitleText: {
    // paddingTop: 12,
    fontFamily: Font.defaultFont,
    fontSize: '1rem',
    fontWeight: 700,
  },
});

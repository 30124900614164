import React, { Component } from 'react';
import { connect } from 'react-redux';
import NumberWidget from '../NumberWidget';

import Leaderboard from '../Leaderboard';
import LineCharts from '../LineCharts';

//Main route
class DefaultView extends Component {
  render() {
    // const test = false;
    const { numbers, userscore, teamscore, teamgoals, lineChartDate } = this.props;
    let dataLoading = lineChartDate.weekChart.datasets[0].data.length > 1;
    return (
      <div className="default-view">
        <NumberWidget data={numbers} users={userscore} />
        {/* make prettier before launch */}

        <div className="wrapper">
          {/* <div className="position-graph">
            <BarDiagram data={positions}/>
          </div> */}
          <Leaderboard user={userscore} team={teamscore} comp={teamgoals} />
        </div>

        <div style={{ marginBottom: 100 }}>
          {dataLoading && <LineCharts lineChartDate={lineChartDate} />}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    numbers: state.BIDashboardState.number,
    userscore: state.BIDashboardState.userscore,
    teamscore: state.BIDashboardState.teamscore,
    positions: state.BIDashboardState.positions,
    teamgoals: state.BIDashboardState.teamgoals,
  };
}

export default connect(mapStateToProps)(DefaultView);

import { FETCH_WEEK, FETCH_TIME } from '../actions';
import moment from 'moment';

const daylabels = [];
const weeklabels = [];
for (let i = 24; i >= 1; i--) {
  daylabels.push(moment().add(-i, 'hours').format('HH'));
}
for (let j = 7; j >= 1; j--) {
  weeklabels.push(moment().add(-j, 'days').format('Do'));
}

// HACK: essential props for Chartjs but should maybe not be initialized here
// TODO: Answers per day and per week not working since no endpoint for that data
const defaultState = {
  chartData: {
    labels: daylabels,
    datasets: [
      {
        label: 'line-chart-total-tips',
        data: [],
        lineTension: 0.3,
        fill: true,
        borderColor: 'rgb(98, 58, 162)',
        backgroundColor: 'rgba(98, 58, 165, 0.2)',
        pointBorderColor: 'rgb(98, 58, 162, 0.2)',
        pointBackgroundColor: 'rgba(98, 58, 162)',
        pointRadius: 5,
        pointHoverRadius: 15,
        pointHitRadius: 30,
        pointBorderWidth: 2,
        pointStyle: 'circle',
      },
    ],
  },
  weekChart: {
    labels: weeklabels,
    datasets: [
      {
        label: 'line-chart-total-tips',
        data: [],
        lineTension: 0.3,
        fill: true,
        borderColor: 'rgb(98, 58, 162)',
        backgroundColor: 'rgba(98, 58, 165, 0.2)',
        pointBorderColor: 'rgb(98, 58, 162, 0.2)',
        pointBackgroundColor: 'rgba(98, 58, 162)',
        pointRadius: 5,
        pointHoverRadius: 15,
        pointHitRadius: 30,
        pointBorderWidth: 2,
        pointStyle: 'circle',
      },
    ],
  },
  options: {
    title: {
      display: true,
      text: 'line-chart-tab-hourly',
      fontSize: 25,
    },
    legend: {
      display: true,
      position: 'top',
    },
  },
  weekOptions: {
    title: {
      display: true,
      text: 'line-chart-tab-weekly',
      fontSize: 25,
    },
    legend: {
      display: true,
      position: 'top',
    },
  },
  size: {
    width: 400,
    height: 250,
  },
};
const reducerTime = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_WEEK:
      if (action.payload.data !== '') {
        const sortedByDate = action.payload.data.sort((a, b) => {
          return a.created < b.created ? 1 : -1;
        });
        const last7DayStart = moment().startOf('day').subtract(1, 'week');
        const yesterdayEndOfRange = moment().endOf('day').subtract(1, 'day');

        const weekHits = new Array(6);
        for (let i = 0; i < 7; i++) {
          weekHits[i] = 0;
        }

        sortedByDate.forEach((recommendation) => {
          if (moment(recommendation.created).isBetween(last7DayStart, yesterdayEndOfRange)) {
            for (let j = 0; j < 7; j++) {
              if (
                moment(recommendation.created).format('Do') ===
                moment()
                  .add(-(j + 1), 'days')
                  .format('Do')
              ) {
                weekHits[j]++;
                break;
              }
            }
          }
        });

        return {
          ...state,
          weekChart: {
            ...state.weekChart,
            datasets: state.weekChart.datasets.map((datasets, i) =>
              i === 1 ? datasets : { ...datasets, data: weekHits.reverse() },
            ),
          },
        };
      } else {
        return { ...state };
      }

    case FETCH_TIME:
      if (action.payload.data !== '') {
        const sortedByDate2 = action.payload.data.sort((a, b) => {
          return a.created < b.created ? 1 : -1;
        });

        const hourHits = new Array(24);
        for (var i = 0; i < 24; i++) {
          hourHits[i] = 0;
        }

        const todayStart = moment().subtract(24, 'hours').format();
        const todayEndOfRange = moment().format();

        sortedByDate2.forEach((recommendation) => {
          // HACK: Need to format timestamp from DB to Local timezone
          if (
            moment(recommendation.created).add(-2, 'hours').isBetween(todayStart, todayEndOfRange)
          ) {
            for (let j = 0; j < 24; j++) {
              if (
                moment(recommendation.created).format('HH') ===
                moment()
                  .add(-(j + 1), 'hours')
                  .format('HH')
              ) {
                hourHits[j]++;
                break;
              }
            }
          }
        });

        return {
          ...state,
          chartData: {
            ...state.chartData,
            datasets: state.chartData.datasets.map((datasets, i) =>
              i === 1 ? datasets : { ...datasets, data: hourHits.reverse() },
            ),
          },
        };
      } else {
        return { ...state };
      }

    default:
      return state;
  }
};

export default reducerTime;

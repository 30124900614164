import React, { Component } from 'react';

export default class LogoLinkedIn extends Component {
  render() {
    return (
      <svg
        version="1.1"
        id="icon--linkedin"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 96 96"
      >
        <g>
          <path
            d="M91.8,0.1H4c-2.2,0-4,1.8-4,4V92c0,2.1,1.8,3.9,4,3.9H92c2.2,0,4-1.8,4-4V4.1C95.9,1.9,94.1,0.1,91.8,0.1z M28.5,81.7H14.2
			        V36h14.2V81.7z M21.3,29.7c-4.5,0-8.2-3.7-8.2-8.2s3.7-8.2,8.2-8.2s8.2,3.7,8.2,8.2C29.5,26.1,25.9,29.7,21.3,29.7z M81.6,81.7
			        H67.4V59.5c0-5.2-0.1-12.1-7.3-12.1c-7.3,0-8.5,5.8-8.5,11.7v22.6H37.3V36h13.6v6.3h0.1C53,38.7,57.7,35,64.5,35
			        c14.4,0,17.1,9.4,17.1,21.7V81.7z"
          />
        </g>
      </svg>
    );
  }
}

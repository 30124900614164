import { authenticateUserSuccess } from './authActions';

import { getCredentials, openAdmin as apiOpenAdmin } from '../../api';
import { tokenExists } from '../api';

export const INITIALIZE_APP = 'INITIALIZE_APP';
export const initializeApp = () => async (dispatch) => {
  try {
    const credentials = await getCredentials();
    const isAuthenticated = tokenExists(credentials);

    // Credentials exist and user is activated
    if (isAuthenticated && credentials.activated && credentials.registered) {
      // User is authenticated. Initialize tab based views.
      dispatch(changeAppRoot('after-login'));
      dispatch(authenticateUserSuccess(credentials));
    } else {
      // User not authenticated. Go to login.
      dispatch(changeAppRoot('login'));
    }

    return {
      type: INITIALIZE_APP,
      payload: {
        initialized: true,
      },
    };
  } catch (error) {
    console.log('as error', error);
  }
};

export const CHANGE_APP_ROOT = 'CHANGE_APP_ROOT';
export function changeAppRoot(root) {
  return {
    type: CHANGE_APP_ROOT,
    payload: {
      root: root,
    },
  };
}

/**
 * Open admin link in Safari
 */
export const OPEN_ADMIN = 'OPEN_ADMIN';
export function openAdmin() {
  apiOpenAdmin();

  return {
    type: OPEN_ADMIN,
    payload: {},
  };
}

import React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Color } from '../../config/styles';

import ProfilePicture from '../ProfilePicture';

export default function CompetitionLeaderboard(props) {
  return (
    <div className={css(styles.wrap)}>
      <ProfilePicture
        picture={props.picture}
        name={props.name}
        size="medium"
        style={styles.picture}
      />

      <div className={css(styles.middleColumn)}>
        <span className={css(styles.name)}>{props.name}</span>
      </div>

      <span className={css(styles.points)}>{`${props.points}p.`}</span>
    </div>
  );
}

const styles = StyleSheet.create({
  name: {
    fontSize: '1.0625rem',
    fontWeight: '600',
    color: Color.dark,
    marginBottom: 3,
  },
  middleColumn: {
    flex: 1,
    maxWidth: 200,
  },
  points: {
    fontWeight: 500,
    fontSize: 13,
    color: Color.dark,
  },
  picture: {
    marginRight: 16,
  },
  wrap: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    padding: 20,
    paddingLeft: 25,
    paddingRight: 25,
    width: '100%',
  },
});

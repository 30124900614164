import React from 'react';
import { Box } from '@talentech/components';
import { css, StyleSheet } from 'aphrodite';
import { useTranslation } from 'react-i18next';

const ArchivedLaunch = (props) => {
  const { archivedLaunchIntervals } = props;
  const { t } = useTranslation();
  return (
    <Box className="col bg-gray-50 shadow-sm m-4 pb-4 rounded-3">
      <div className={css(styles.archivedHeadingWrapper)}>
        <h2 className={css(styles.archivedHeading)}>{t('communication-archived-title')}</h2>
        <p className={css(styles.archivedText)}>{t('communication-archived-desc')}</p>
      </div>

      <div className={css(styles.communicationItemsWrapper)}>{archivedLaunchIntervals}</div>
    </Box>
  );
};

export default ArchivedLaunch;

const styles = StyleSheet.create({
  archivedHeadingWrapper: {
    padding: '1.5rem',
    marginBottom: '1.5rem',
    borderBottom: '1px solid rgba(214, 214, 220, 1)',
  },
  archivedHeading: {
    fontWeight: 'bold',
    fontSize: '1.75rem',
    letterSpacing: 0,
  },
  archivedText: {
    lineHeight: '1.5rem',
    margin: 0,
  },
  communicationItemsWrapper: {
    border: '1px solid rgba(214, 214, 220, 1)',
    borderBottom: 'none',
    borderRadius: '0.25rem',
    margin: '0 1.5rem',
  },
});

import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Box, Toast } from '@talentech/components';

import { Color } from '../../config/styles';
import { css, StyleSheet } from 'aphrodite';

import Button from '../../components/Button';
import { IconCaretDown } from '../../components/Icons';
import AdminWrapper from '../../components/admin/AdminWrapper';
import CandidateItem from '../../components/admin/CandidateItem';
import JobsListNav from '../../components/JobsListNav';
import Modal from '../../components/Modal';
import Loader from '../../components/Loader';
import { ModalForm } from '../../components/admin/ModalForm';
import ManualRecommendationFields from '../../components/admin/ManualRecommendationFields';
import IntegrationATSFields from '../../components/admin/IntegrationATSFields';
import Utils from '../../irecommend-lib/utils';
import { withTranslation } from 'react-i18next';

class AdminPositionPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showActionNav: false,
      showModal: false,
      showModalForm: false,
      formType: '',
      modalTitle: '',
      modalDesc: '',
      meta: {},
      itemID: '',
      type: '',
      recommendation: {},
      recommendationState: '',
      formData: {
        userid: '',
        candidatename: '',
        email: '',
        phone: '',
        commentfromreferrer: '',
      },
    };

    this.setState = this.setState.bind(this);
  }

  render() {
    const {
      showToast,
      setState,
      message,
      t,
      position,
      users,
      recommendations,
      isLoading,
      onPressGetDocument,
    } = this.props;

    const numberOfRecommendations = recommendations.length;
    // Get numbers of interested based on state
    const interested = (recommendations || []).filter((recommendation) => {
      return (
        recommendation.state === 50 ||
        recommendation.state === 70 ||
        recommendation.state === 100 ||
        recommendation.state === 130
      );
    }).length;

    let recommendationItems = null;
    if (recommendations.length === 0) {
      recommendationItems = (
        <h2 className={css(styles.emptyCandidates)}>{t('generic-text-no-candidates')}</h2>
      );
    } else {
      recommendationItems = recommendations.map((item, index) => {
        return (
          <CandidateItem
            key={index}
            position={position}
            candidate={item.referral}
            recommendation={item}
            user={item.user}
            commentFromCandidate={item.comment}
            commentToHR={item.motivation}
            expired={item.expired}
            onChangeStatus={this.onChangeStatus}
            onPressShowComment={this.onPressShowComment}
            onPressGetDocument={onPressGetDocument}
            onPressVerifyContact={this.onPressVerifyContact}
            onPressVerifyInterview={this.onPressVerifyInterview}
            onPressVerifyHire={this.onPressVerifyHire}
            onPressFailedContact={this.onPressFailedContact}
            onPressNotInterested={this.onPressNotInterested}
            onPressFailedAfterInterview={this.onPressFailedAfterInterview}
            onPressFailedInterview={this.onPressFailedInterview}
            onPressFailedHire={this.onPressFailedHire}
          />
        );
      });
    }

    return (
      <Loader isLoading={isLoading}>
        <AdminWrapper>
          {this.state.showModal && (
            <Modal
              title={this.state.modalTitle}
              description={this.state.modalDesc}
              cancelCallback={this.onPressHideModal}
              type={this.state.type}
              confirmCallback={(e) => {
                e.preventDefault();
                const { recommendation } = this.state;
                switch (this.state.type) {
                  case 'remove':
                    this.props.onPressConfirmDelete(this.state.itemID);
                    break;
                  case 'archive':
                    this.props.onPressConfirmArchiving(this.state.meta);
                    break;
                  case 'push':
                    this.props.onPressConfirmPush(this.state.meta);
                    break;
                  case 'contact':
                    if (recommendation.externalid === null && position.externalid) {
                      this.handleShowForm('integration', recommendation, 70);
                      return;
                    }
                    this.props.onPressConfirmContact(
                      this.state.itemID,
                      this.state.recommendationState,
                    );
                    break;
                  case 'interview':
                    if (recommendation.externalid === null && position.externalid) {
                      this.handleShowForm('integration', recommendation, 100);
                      return;
                    }
                    this.props.onPressConfirmInterview(
                      this.state.itemID,
                      this.state.recommendationState,
                    );
                    break;
                  case 'hire':
                    if (recommendation.externalid === null && position.externalid) {
                      this.handleShowForm('integration', recommendation, 130);
                      return;
                    }
                    this.props.onPressConfirmHire(
                      this.state.itemID,
                      this.state.recommendationState,
                    );
                    break;
                  case 'contactFailed':
                    this.props.onPressFailedContact(
                      this.state.itemID,
                      this.state.recommendationState,
                    );
                    break;
                  case 'notInterested':
                    this.props.onPressNotInterested(
                      this.state.itemID,
                      this.state.recommendationState,
                    );
                    break;
                  case 'interviewFailed':
                    this.props.onPressFailedInterview(
                      this.state.itemID,
                      this.state.recommendationState,
                    );
                    break;
                  case 'afterInterviewFailed':
                    this.props.onPressFailedAfterInterview(
                      this.state.itemID,
                      this.state.recommendationState,
                    );
                    break;
                  case 'hireFailed':
                    this.props.onPressFailedHire(this.state.itemID, this.state.recommendationState);
                    break;
                  default:
                    break;
                }
                this.onPressHideModal();
              }}
            />
          )}
          {this.state.showModalForm && (
            <ModalForm
              showModalForm={this.state.showModalForm}
              title={this.state.formType === 'manual' && t('candidate-manual-form-title')}
              content={
                this.state.formType === 'manual' ? (
                  <ManualRecommendationFields
                    users={users}
                    state={this.state}
                    setState={this.setState}
                    t={t}
                  />
                ) : this.state.formType === 'integration' ? (
                  <IntegrationATSFields
                    state={this.state}
                    setState={this.setState}
                    recommendation={this.state.recommendation}
                    t={t}
                  />
                ) : null
              }
              labelCancel={t('generic-button-cancel')}
              labelSubmit={
                this.state.formType === 'manual'
                  ? t('generic-text-save')
                  : t('candidate-integration-form-connect')
              }
              handleCancel={
                this.state.formType === 'manual'
                  ? this.onPressHideModalForm
                  : this.onPressHandleCancel
              }
              handleSubmit={this.onPressHandleSubmit}
              state={this.state}
              setState={this.setState}
            />
          )}
          <Box
            className="d-flex justify-content-center w-75 position-fixed bottom-0 p-3"
            style={{ zIndex: 5 }}
          >
            <Box className="w-50">
              <Toast
                showToast={showToast}
                small
                style={{ zIndex: 20 }}
                onClose={() => {
                  setState({ showToast: false, message: '' });
                }}
                color={this.state.error ? 'danger' : 'success'}
              >
                {message}
              </Toast>
            </Box>
          </Box>
          <div className={css(styles.container)}>
            <header className={css(styles.positionHeader)}>
              <nav className={css(styles.breadcrumbs)}>
                <ul>
                  <li className={css(styles.breadcrumbsItem)}>
                    <Link to="/admin/" className={css(styles.breadcrumbsLink)}>
                      {t('generic-text-jobs')}
                    </Link>
                  </li>
                  <li className={css(styles.breadcrumbsItem)}>
                    <span className={css(styles.breadcrumbsLink, styles.breadCrumbsTitle)}>
                      {position.title}
                    </span>
                  </li>
                </ul>
              </nav>
              <div className={css(styles.headerSection)}>
                <span className={css(styles.sectionValue, styles.blue)}>
                  {numberOfRecommendations}
                </span>
                <span className={css(styles.sectionTitle)}>
                  {t('generic-text-recommendations')}
                </span>
              </div>
              <div className={css(styles.headerSection)}>
                <span className={css(styles.sectionValue, styles.green)}>{interested}</span>
                <span className={css(styles.sectionTitle)}>{t('generic-text-interested')}</span>
              </div>

              <div className={css(styles.headerSection)}>
                <Button
                  title={t('generic-text-actions')}
                  type="actions"
                  icon={<IconCaretDown />}
                  onPress={() => {
                    this.setState({ showActionNav: !this.state.showActionNav });
                  }}
                />
                <JobsListNav
                  menu={position.archived ? 'archived' : 'default'}
                  position={position}
                  showActionNav={this.state.showActionNav}
                  onPressDeleteItem={this.onPressDeletePosition}
                  onPressArchiveItem={this.onPressArchivePosition}
                  onPressPushPosition={this.onPressPushPosition}
                  handleShowForm={this.handleShowForm}
                  onPressToggleNav={this.toggleNav}
                />
              </div>
            </header>

            <div className={css(styles.candidateList)}>
              <div>
                <h2 className={css(styles.listTitle)}>
                  {t('generic-text-candidates')}
                  <span className={css(styles.titleNumber)}>{numberOfRecommendations}</span>
                </h2>
                {position.source === 'TeamTailor' && (
                  <div className={css(styles.btn_container)}>
                    <Button
                      title={t('generic-text-update-candidates')}
                      iconAlt=""
                      type="primary"
                      onPress={(e) => this.onPressGetStages(e)}
                      style={styles.btnWidth}
                    />
                  </div>
                )}
              </div>

              {recommendationItems}
            </div>
          </div>
        </AdminWrapper>
      </Loader>
    );
  }

  onPressShowComment = (title, comment) => {
    this.setState({
      showModal: true,
      modalTitle: title,
      modalDesc: comment,
      type: 'default',
    });
  };

  onPressHideModal = () => {
    this.setState({
      showModal: false,
    });
  };

  onPressHideModalForm = () => {
    this.setState({
      showModalForm: false,
    });
  };

  onPressDeletePosition = (position) => {
    const { t } = this.props;
    this.setState({
      meta: position,
      itemID: position.idpositions,
      showModal: true,
      modalTitle: `${t('generic-text-remove')} ${position.title}?`,
      modalDesc: t('generic-text-remove-job-description'),
      type: 'remove',
    });
  };

  onPressArchivePosition = (position) => {
    const { t } = this.props;
    this.setState({
      meta: position,
      itemID: position.idpositions,
      showModal: true,
      modalTitle: `${t('generic-text-archive')} ${position.title}?`,
      modalDesc: t('generic-text-archive-job-description'),
      type: 'archive',
    });
  };

  handleShowForm = (type, recommendation = null, recommendationState = null) => {
    switch (type) {
      case 'manual':
        this.onPressOpenManualForm();
        break;
      case 'integration':
        this.setState({ ...this.state, showModal: false });
        this.onPressOpenATSForm(recommendation, recommendationState);
        break;
      default:
        break;
    }
  };

  onPressOpenManualForm = () => {
    this.setState({
      showModalForm: true,
      formType: 'manual',
    });
  };

  onPressOpenATSForm = (recommendation, recommendationState) => {
    this.setState({
      showModalForm: true,
      formType: 'integration',
      recommendation: recommendation,
      recommendationState: recommendationState,
      formData: { ...this.state.formData, email: recommendation.referral.mail },
    });
  };

  onPressHandleCancel = () => {
    const recommendation = this.state.recommendation;

    this.setState({ ...this.state, showModalForm: false }, () =>
      this.props.onPressHandleIntegration(
        recommendation.idrecommendations,
        this.state.recommendationState,
        this.state.formData.email,
        false,
      ),
    );
  };

  onPressHandleSubmit = () => {
    const { t } = this.props;
    const recommendation = this.state.recommendation;

    if (this.state.formData.email && !Utils.validateEmail(this.state.formData.email)) {
      alert(t('generic-validation-email'));
      return;
    }

    if (this.state.formType === 'manual') {
      if (!this.state.formData.userid || !this.state.formData.candidatename) {
        alert(t('candidate-manual-fields-not-validated'));
        return;
      }

      this.setState({ ...this.state, showModalForm: false }, () =>
        this.props.onPressRecommendManual(this.state.formData),
      );
    } else {
      if (!this.state.formData.email) {
        alert(t('candidate-integration-field-validation'));
        return;
      }
      this.setState({ ...this.state, showModalForm: false }, () =>
        this.props.onPressHandleIntegration(
          recommendation.idrecommendations,
          this.state.recommendationState,
          this.state.formData.email,
          true,
        ),
      );
    }
  };

  onPressPushPosition = (position) => {
    const { t } = this.props;
    this.setState({
      meta: position,
      itemID: position.idpositions,
      showModal: true,
      modalTitle: `${t('generic-text-push')} ${position.title}?`,
      modalDesc: t('generic-text-push-job-again'),
      type: 'push',
    });
  };

  onPressVerifyContact = (recommendation) => {
    const { t } = this.props;
    this.setState({
      showModal: true,
      itemID: recommendation.idrecommendations,
      modalTitle: `${t('modal-title-contacted-confirm', { name: recommendation.referral.name })}`,
      modalDesc: t('modal-desc-contacted-confirm'),
      type: 'contact',
      recommendationState: 70,
      recommendation: recommendation,
    });
  };

  onPressFailedContact = (recommendation) => {
    const { t, position } = this.props;
    if (recommendation.externalid === null && position.externalid) {
      this.handleShowForm('integration', recommendation, 80);
      return;
    }
    this.setState({
      showModal: true,
      itemID: recommendation.idrecommendations,
      modalTitle: `${t('modal-title-contacted-failed', {
        name: recommendation.referral.name,
      })}`,
      modalDesc: `${t('modal-desc-contacted-failed')}`,
      type: 'contactFailed',
      recommendationState: recommendation.state,
    });
  };

  onPressNotInterested = (recommendation) => {
    const { t } = this.props;
    this.setState({
      showModal: true,
      itemID: recommendation.idrecommendations,
      modalTitle: `${t('modal-title-not-interested', {
        name: recommendation.referral.name,
      })}`,
      modalDesc: `${t('modal-desc-not-interested')}`,
      type: 'notInterested',
      recommendationState: recommendation.state,
    });
  };

  onPressVerifyInterview = (recommendation) => {
    const { t } = this.props;
    this.setState({
      showModal: true,
      itemID: recommendation.idrecommendations,
      modalTitle: `${t('modal-title-interview-confirm', { name: recommendation.referral.name })}`,
      modalDesc: `${t('modal-desc-interview-confirm', { name: recommendation.referral.name })}`,
      type: 'interview',
      recommendationState: 100,
      recommendation: recommendation,
    });
  };

  onPressFailedInterview = (recommendation) => {
    const { t, position } = this.props;
    if (recommendation.externalid === null && position.externalid) {
      this.handleShowForm('integration', recommendation, 80);
      return;
    }
    this.setState({
      showModal: true,
      itemID: recommendation.idrecommendations,
      modalTitle: `${t('modal-title-interview-failed', { name: recommendation.referral.name })}`,
      modalDesc: `${t('modal-desc-interview-failed')}`,
      type: 'interviewFailed',
      recommendationState: 80,
    });
  };

  onPressFailedAfterInterview = (recommendation) => {
    const { t, position } = this.props;
    if (recommendation.externalid === null && position.externalid) {
      this.handleShowForm('integration', recommendation, 110);
      return;
    }
    this.setState({
      showModal: true,
      itemID: recommendation.idrecommendations,
      modalTitle: `${t('modal-title-interview-failed', { name: recommendation.referral.name })}`,
      modalDesc: `${t('modal-desc-interview-failed')}`,
      type: 'afterInterviewFailed',
      recommendationState: 110,
    });
  };

  onPressVerifyHire = (recommendation) => {
    const { t } = this.props;
    this.setState({
      showModal: true,
      itemID: recommendation.idrecommendations,
      modalTitle: `${t('modal-title-hire-confirm', { name: recommendation.referral.name })}`,
      modalDesc: `${t('modal-desc-hire-confirm', { name: recommendation.referral.name })}`,
      type: 'hire',
      recommendationState: 130,
      recommendation: recommendation,
    });
  };

  onPressFailedHire = (recommendation) => {
    const { t, position } = this.props;
    if (recommendation.externalid === null && position.externalid) {
      this.handleShowForm('integration', recommendation, 110);
      return;
    }
    this.setState({
      showModal: true,
      itemID: recommendation.idrecommendations,
      modalTitle: `${t('modal-title-hire-failed', { name: recommendation.referral.name })}`,
      modalDesc: `${t('modal-desc-hire-failed')}`,
      type: 'hireFailed',
      recommendationState: 110,
    });
  };

  toggleNav = (e) => {
    e.preventDefault();
    this.setState({
      showActionNav: !this.state.showActionNav,
    });
  };

  onPressGetStages = (e) => {
    e.preventDefault();

    this.props.getStages();
  };

  onChangeStatus = (id, status, recommendation) => {
    const { position } = this.props;
    if (recommendation.externalid === null && position.externalid && status >= 50) {
      this.handleShowForm('integration', recommendation, status);
      return;
    }
    this.props.onChangeStatus(id, status);
  };
}

export default withTranslation()(AdminPositionPage);

const styles = StyleSheet.create({
  container: {
    maxWidth: '85rem',
    margin: '0 auto',
  },
  positionHeader: {
    backgroundColor: '#fff',
    display: 'grid',
    gridTemplateColumns: '1fr 10em 10em 12em',
    alignItems: 'center',
  },
  headerSection: {
    flex: '0 0 11em',
    display: 'flex',
    alignItems: 'flex-end',
    margin: '0 1em 0 0',
    padding: '0 .5em',
    justifyContent: 'center',
    position: 'relative',

    ':last-child': {
      marginRight: '0',
      paddingRight: '0',
    },
  },
  sectionValue: {
    fontSize: '1.0625em',
    margin: '0 .4em',
  },
  sectionTitle: {
    textTransform: 'uppercase',
    fontSize: '.6875em',
    color: '#b7b7b7',
  },
  breadcrumbs: {
    flexBasis: '50%',
    display: 'flex',
    alignItems: 'center',
  },
  breadcrumbsItem: {
    display: 'inline-block',
    marginBottom: '0',
  },
  breadcrumbsLink: {
    display: 'inline-block',
    fontSize: '1.7em',
    opacity: '.5',
    textDecoration: 'none',
    padding: '.5em .25em .5em 0',
    ':after': {
      content: '">"',
      display: 'inline-block',
      marginLeft: '.25em',
    },
  },
  breadCrumbsTitle: {
    opacity: '1',
    ':after': {
      content: '',
    },
  },
  candidateList: {
    padding: '2em 0',
  },
  listTitle: {
    fontWeight: 'bold',
    display: 'inline-block',
  },
  titleNumber: {
    color: '#b2b2b2',
    marginLeft: '.5em',
  },
  emptyCandidates: {
    boxShadow: '0 5px 17px 0 rgba(0, 0, 0, .03)',
    backgroundColor: '#fff',
    padding: '6em 3em',
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: '1em',
    letterSpacing: '.05em',
    color: '#999',
  },
  blue: {
    color: Color.primary,
  },
  green: {
    color: Color.success,
  },
  btn_container: {
    marginLeft: '2em',
    display: 'inline-block',
  },
  btnWidth: {
    width: '200px',
  },
});

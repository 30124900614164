import React, { Component } from 'react';
import TeamListItem from './TeamListItem';
import { withTranslation } from 'react-i18next';

class TeamPanel extends Component {
  render() {
    const { t } = this.props;
    return (
      <table className="team-panel">
        <thead>
          <tr>
            <th>{t('leaderboard-rank')}</th>
            <th>{t('leaderboard-team')}</th>
            <th>{t('leaderboard-user')}</th>
            <th>{t('leaderboard-score')}</th>
          </tr>
        </thead>
        <tbody>
          <TeamListItem data={this.props.data} />
        </tbody>
      </table>
    );
  }
}

export default withTranslation()(TeamPanel);

import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getUser } from '../../irecommend-lib/actions/userActions';
import { getCustomerCategories } from '../../irecommend-lib/actions/customerActions';
import { getTeams } from '../../irecommend-lib/actions/teamActions';

const useFetchProfileSettings = () => {
  const { user, teams, customerCategories } = useSelector((state) => ({
    user: state.userState.user,
    teams: state.teamState.teams,
    customerCategories: state.customerState.customerCategories,
  }));

  const [state, setState] = useState({
    error: false,
    categories: [],
    selectedCategories: [],
    formData: {
      Familyname: '',
      Givenname: '',
      Team: '',
      Picture: '', // Loaded image for presentation
      PictureRaw: '', // input File object
    },
  });
  const dispatch = useDispatch();
  const isUserDataSet = useRef(false);
  const setButtonDisabled = Boolean(
    !state.formData.Familyname ||
      !state.formData.Givenname ||
      (teams.response.length > 0 &&
        (!state.formData.Team || state.formData.Team === 'placeholder')) ||
      (customerCategories.response !== '' && state.selectedCategories.length === 0),
  );

  const teamOptions =
    teams.response?.length > 0
      ? teams.response.map((item) => ({
          title: item.name,
          value: item.idteam,
        }))
      : [];

  const isFetchSettingsLoading = Boolean(teams.isLoading || user.isLoading);

  useEffect(() => {
    dispatch(getUser());
    dispatch(getCustomerCategories());
    dispatch(getTeams());
  }, [dispatch]);

  // Set name fields from user data
  useEffect(() => {
    if (
      Object.keys(user.response).length !== 0 &&
      user.request.status === 200 &&
      !isUserDataSet.current
    ) {
      setState((prev) => ({
        ...prev,
        formData: {
          ...prev.formData,
          Familyname: user.response.user.familyname,
          Givenname: user.response.user.givenname,
        },
      }));
      isUserDataSet.current = true;
    }
  }, [user.response, user.request]);

  // Set categories from customer data
  useEffect(() => {
    const customerCategoriesOptions =
      customerCategories.response?.length > 0 ? customerCategories.response : [];

    customerCategoriesOptions.length > 0 &&
      setState((prev) => ({
        ...prev,
        categories: customerCategoriesOptions,
        selectedCategories: customerCategoriesOptions,
      }));
  }, [customerCategories.response]);

  return {
    state,
    setState,
    setButtonDisabled,
    teamOptions,
    isFetchSettingsLoading,
    user,
  };
};

export default useFetchProfileSettings;

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { postInvite } from '../../irecommend-lib/actions/inviteActions';
import { getUsers } from '../../irecommend-lib/actions/userActions';
import useShowToast from '../helper/useShowToast';

const useCreateInvite = (setState, t) => {
  const { request, error, isLoading } = useSelector((state) => state.inviteState.invite);
  const dispatch = useDispatch();
  const { showToastMessage } = useShowToast(setState, t, 'POST_INVITE_RESET');

  useEffect(() => {
    handleResponse(request);
  }, [request]);

  const createInvite = (formData) => dispatch(postInvite(formData));

  const handleResponse = (response) => {
    if (response && response.status === 201) {
      showToastMessage('invitationpage-user-created');
      dispatch(getUsers());
    }
    if (response && error && response.status === 409)
      showToastMessage('invitationpage-user-exists', true);

    if (response && error && response.status === 500)
      showToastMessage('generic-error-default-message', true);
  };

  return { createInvite, inviteIsLoading: isLoading };
};

export default useCreateInvite;

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deletePosition,
  getPositions,
  removeExternalPosition,
} from '../../irecommend-lib/actions/positionActions';
import { FILTER_TYPES } from '../../helpers/position';

const useRemovePosition = (selectedFilter) => {
  const { externalPositions, positionDelete } = useSelector((state) => state.positionState);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!positionDelete.error && Object.keys(positionDelete.response).length > 0)
      dispatch(getPositions(selectedFilter === FILTER_TYPES.all ? '' : selectedFilter));
  }, [dispatch, positionDelete.error, positionDelete.response, selectedFilter]);

  const onDeleteExternalPosition = (id) => {
    const updatedExternalPositions = externalPositions.response.filter(
      (position) => position.ID !== id,
    );
    dispatch(removeExternalPosition(updatedExternalPositions));
  };
  const onDeletePosition = (id) => {
    dispatch(deletePosition(id));
  };

  return { onDeleteExternalPosition, onDeletePosition };
};

export default useRemovePosition;

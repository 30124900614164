import {
  AUTHENTICATE_USER_REQUEST,
  AUTHENTICATE_USER_FAILURE,
  AUTHENTICATE_USER_SUCCESS,
  RENEW_AUTH_REQUEST,
  RENEW_AUTH_FAILURE,
  RENEW_AUTH_SUCCESS,
  FETCH_TALENT_AI_TOKEN_REQUEST,
  FETCH_TALENT_AI_TOKEN_SUCCESS,
  FETCH_TALENT_AI_TOKEN_FAILURE,
  LOGOUT_USER,
  STORE_CREDENTIALS,
  GET_CREDENTIALS,
  ADD_CALL_QUEUE,
  CLEAR_CALL_QUEUE,
} from '../actions/authActions';

const initialState = {
  authenticate: {
    error: false,
    isLoading: false,
    response: {},
  },
  renewAuth: {
    error: false,
    isLoading: false,
    response: {},
  },
  talentAIToken: {
    error: false,
    isLoading: false,
    response: {},
  },
  queue: [],
};

export default function authState(state = initialState, { payload, request, response, type }) {
  switch (type) {
    case AUTHENTICATE_USER_REQUEST:
      return {
        ...state,
        authenticate: {
          ...state.authenticate,
          error: false,
          isLoading: true,
        },
      };
    case AUTHENTICATE_USER_FAILURE:
      return {
        ...state,
        authenticate: {
          ...state.authenticate,
          error: true,
          isLoading: false,
          response: payload.error,
        },
      };
    case AUTHENTICATE_USER_SUCCESS:
      return {
        ...state,
        authenticate: {
          ...state.authenticate,
          error: false,
          isLoading: false,
          response: payload.response,
        },
      };
    case RENEW_AUTH_REQUEST:
      return {
        ...state,
        renewAuth: {
          ...state.renewAuth,
          error: false,
          isLoading: true,
        },
      };
    case RENEW_AUTH_FAILURE:
      return {
        ...state,
        renewAuth: {
          ...state.renewAuth,
          error: true,
          isLoading: false,
          response: payload.error,
        },
      };
    case RENEW_AUTH_SUCCESS:
      return {
        ...state,
        renewAuth: {
          ...state.renewAuth,
          error: false,
          isLoading: false,
          response: payload.response,
        },
      };
    case FETCH_TALENT_AI_TOKEN_REQUEST:
      return {
        ...state,
        talentAIToken: {
          ...state.talentAIToken,
          error: false,
          isLoading: true,
        },
      };
    case FETCH_TALENT_AI_TOKEN_FAILURE:
      return {
        ...state,
        talentAIToken: {
          ...state.talentAIToken,
          error: true,
          isLoading: false,
          response: response,
        },
      };
    case FETCH_TALENT_AI_TOKEN_SUCCESS:
      return {
        ...state,
        talentAIToken: {
          ...state.talentAIToken,
          error: false,
          isLoading: false,
          response: response,
        },
      };
    case LOGOUT_USER:
      return {
        ...state,
        authenticate: {
          ...state.authenticate,
          error: false,
          isLoading: false,
          response: null,
        },
      };
    case STORE_CREDENTIALS:
      return {
        ...state,
        authenticate: {
          ...state.authenticate,
          error: false,
          isLoading: false,
          response: payload.response,
        },
      };
    case GET_CREDENTIALS:
      return {
        ...state,
        authenticate: {
          ...state.authenticate,
          error: false,
          isLoading: false,
          response: payload.response,
        },
      };
    case ADD_CALL_QUEUE:
      return {
        ...state,
        queue: [...state.queue, payload.call],
      };
    case CLEAR_CALL_QUEUE:
      return {
        ...state,
        queue: [],
      };
    default:
      return state;
  }
}

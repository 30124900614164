import React from 'react';
import { css, StyleSheet } from 'aphrodite';

import { Color } from '../irecommend-lib/styles';
import ProfileSettingsPicture from './ProfileSettingsPicture';
import Select from './Select';
import { useTranslation } from 'react-i18next';

const ProfileSettingsFields = (props) => {
  const { state, setState, user, teamOptions, onChangeFiles } = props;
  const { t } = useTranslation();

  return (
    <>
      <h1 className={css(styles.header)}>{t('profileeditpage-settings-header')}</h1>
      <p className={css(styles.subHeader)}>{t('profileeditpage-settings-upload-header')}</p>
      <label htmlFor="image-upload" className={css(styles.userWrapper)}>
        <ProfileSettingsPicture
          picture={state.formData?.Picture}
          name={`${user.response.user?.givenname} ${user.response.user?.familyname}`}
        />
        <div className={css(styles.userInfo)}>
          <p className={css(styles.nameHeading)}>
            {user.response.user?.givenname}{' '}
            <span className={css(styles.familyname)}>{user.response.user?.familyname}</span>
          </p>
          <div className={css(styles.email)}>{user.response.user?.mail}</div>
        </div>
      </label>
      <input
        id="image-upload"
        type="file"
        name="pic"
        accept="image/*"
        onChange={(e) => onChangeFiles(e.target.files)}
        className={css(styles.imageInput)}
      />
      <label htmlFor="image-upload" className={css(styles.uploadText)}>
        {t('profileeditpage-settings-upload-text')}
      </label>
      <p className={css(styles.subHeader)}>{t('generic-input-Givenname')}</p>
      <input
        className={css(styles.input)}
        type="text"
        value={state.formData?.Givenname}
        onChange={(e) =>
          setState({ ...state, formData: { ...state.formData, Givenname: e.target.value } })
        }
      />
      <p className={css(styles.subHeader)}>{t('generic-input-Familyname')}</p>
      <input
        className={css(styles.input)}
        type="text"
        value={state.formData?.Familyname}
        onChange={(e) =>
          setState({
            ...state,
            formData: { ...state.formData, Familyname: e.target.value },
          })
        }
      />
      <p className={css(styles.subHeader)}>{t('leaderboard-team')}</p>
      <Select
        style={window.innerWidth > 500 ? styles.selectDesktop : null}
        optionsData={teamOptions}
        selected={state.formData?.Team}
        theme="light"
        onChange={(value) => setState({ ...state, formData: { ...state.formData, Team: value } })}
        placeholder={t('profileeditpage-settings-select-team')}
      />
    </>
  );
};

export default ProfileSettingsFields;

const styles = StyleSheet.create({
  header: {
    margin: 0,
    fontWeight: 600,
    letterSpacing: 0.8,
    '@media (max-width: 768px)': {
      fontSize: '1.5rem',
    },
  },
  headerMobile: {
    fontSize: 24,
  },
  subHeader: {
    fontSize: 15,
    color: 'rgba(38, 37, 62, 1)',
    letterSpacing: 0.3,
    marginTop: 10,
    marginBottom: 20,
    textTransform: 'uppercase',
  },
  userWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#EFEFF3',
    border: '1px solid rgba(117, 117, 129, 1)',
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
    paddingBottom: 10,
    borderRadius: 3,
    cursor: 'pointer',
  },
  userInfo: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    paddingLeft: 20,
    justifyContent: 'flex-end',
  },
  nameHeading: {
    fontSize: '1.3rem',
    fontWeight: '600',
    marginBottom: 3,
    marginTop: 15,
    color: Color.primary,
    '@media (max-width: 768px)': {
      fontSize: '1rem',
    },
  },
  familyname: {
    fontWeight: '300',
  },
  email: {
    fontSize: '1rem',
    color: Color.dark,
    marginBottom: 10,
    letterSpacing: 0.6,
    wordBreak: 'break-all',
    '@media (max-width: 768px)': {
      fontSize: '0.8rem',
    },
  },
  paragraphMobile: {
    fontSize: 10,
  },
  input: {
    width: '100%',
    padding: 10,
    marginBottom: '1rem',
  },
  imageInput: {
    display: 'none',
  },
  uploadText: {
    fontSize: 15,
    color: 'rgba(38, 37, 62, 1)',
    letterSpacing: 0.3,
    marginTop: 10,
    marginBottom: 20,
    cursor: 'pointer',
  },
  selectDesktop: {
    padding: 10,
    marginBottom: '1rem',
  },
});

import {
  GET_COMPETITION_REQUEST,
  GET_COMPETITION_SUCCESS,
  GET_COMPETITION_FAILURE,
  POST_COMPETITION_REQUEST,
  POST_COMPETITION_SUCCESS,
  POST_COMPETITION_FAILURE,
  DELETE_COMPETITION_REQUEST,
  DELETE_COMPETITION_SUCCESS,
  DELETE_COMPETITION_FAILURE,
  UPDATE_COMPETITION_REQUEST,
  UPDATE_COMPETITION_SUCCESS,
  UPDATE_COMPETITION_FAILURE,
  GET_COMPETITIONS_REQUEST,
  GET_COMPETITIONS_SUCCESS,
  GET_COMPETITIONS_FAILURE,
  DELETE_COMPETITION_FROM_STATE,
} from '../actions/competitionActions';

const initialState = {
  competition: {
    didInvalidate: false,
    error: false,
    isLoading: false,
    response: {},
  },
  competitions: {
    error: false,
    isLoading: false,
    response: [],
  },
  competitionDelete: {
    didInvalidate: false,
    error: false,
    isLoading: false,
    response: {},
  },
  competitionPost: {
    didInvalidate: false,
    error: false,
    isLoading: false,
    requestSuccess: false,
    response: {},
  },
  competitionUpdate: {
    didInvalidate: false,
    error: false,
    isLoading: false,
    requestSuccess: false,
  },
};

export default function competitionState(
  state = initialState,
  { authenticated, error, response, type, request },
) {
  switch (type) {
    case GET_COMPETITION_REQUEST:
      return {
        ...state,
        competition: {
          ...state.competition,
          isLoading: true,
          error: false,
        },
      };
    case GET_COMPETITION_SUCCESS:
      return {
        ...state,
        competition: {
          ...state.competition,
          isLoading: false,
          response: response,
        },
      };
    case GET_COMPETITION_FAILURE:
      return {
        ...state,
        competition: {
          ...state.competition,
          isLoading: false,
          error: true,
          response: error,
        },
      };

    case POST_COMPETITION_REQUEST:
      return {
        ...state,
        competitionPost: {
          ...state.competitionPost,
          isLoading: true,
          error: false,
        },
      };
    case POST_COMPETITION_SUCCESS:
      return {
        ...state,
        competitionPost: {
          ...state.competitionPost,
          isLoading: false,
          response: response,
          requestSuccess: true,
          //request: request,
        },
      };
    case POST_COMPETITION_FAILURE:
      return {
        ...state,
        competitionPost: {
          ...state.competitionPost,
          isLoading: false,
          error: true,
          response: error,
          //request: request,
        },
      };

    case DELETE_COMPETITION_REQUEST:
      return {
        ...state,
        competitionDelete: {
          ...state.competitionDelete,
          isLoading: true,
        },
      };
    case DELETE_COMPETITION_SUCCESS:
      return {
        ...state,
        competitionDelete: {
          ...state.competitionDelete,
          isLoading: false,
          response: response,
        },
      };
    case DELETE_COMPETITION_FAILURE:
      return {
        ...state,
        competitionDelete: {
          ...state.competitionDelete,
          isLoading: false,
          error: true,
          response: error,
        },
      };

    case GET_COMPETITIONS_REQUEST:
      return {
        ...state,
        competitions: {
          ...state.competitions,
          isLoading: true,
        },
      };
    case GET_COMPETITIONS_SUCCESS:
      return {
        ...state,
        competitions: {
          ...state.competitions,
          isLoading: false,
          response: response,
        },
      };
    case GET_COMPETITIONS_FAILURE:
      return {
        ...state,
        competitions: {
          ...state.competitions,
          isLoading: false,
          error: true,
          response: [],
        },
      };
    case UPDATE_COMPETITION_REQUEST:
      return {
        ...state,
        competitionUpdate: {
          ...state.competitionUpdate,
          isLoading: true,
        },
      };
    case UPDATE_COMPETITION_SUCCESS:
      return {
        ...state,
        competitionUpdate: {
          ...state.competitionUpdate,
          isLoading: false,
          requestSuccess: true,
        },
      };
    case UPDATE_COMPETITION_FAILURE:
      return {
        ...state,
        competitionUpdate: {
          ...state.competitionUpdate,
          isLoading: false,
          error: true,
        },
      };
    case DELETE_COMPETITION_FROM_STATE:
      return {
        ...state,
        competitions: {
          ...state.competitions,
          response,
        },
      };
    default:
      return state;
  }
}

import { FETCH_TIPS } from '../actions';

const defaultTipsState = [];

const reducerTips = (state = defaultTipsState, action) => {
  switch (action.type) {
    case FETCH_TIPS:
      return [...state, action.payload];
    default:
      return state;
  }
};

export default reducerTips;

import { Box, Button } from '@talentech/components';
import { StyleSheet, css } from 'aphrodite';
import { useTranslation } from 'react-i18next';

const TabContentSms = (props) => {
  const { handleOnClickEditSms, smsNotification } = props;
  const { t } = useTranslation();

  const formatNewLineCharacter = (text) => {
    const paragraphs = text ? text.split('\n\n') : [];
    return paragraphs.map((paragraph, index) => (
      <>
        <p dangerouslySetInnerHTML={{ __html: paragraph }} />
        <br />
      </>
    ));
  };

  return (
    <>
      <Box className="row m-0">
        <Box className="col ps-4 pe-4 d-flex justify-content-between align-items-center">
          <div className={css(styles.tabContentHeader)}>SMS</div>
        </Box>
      </Box>
      <Box className="row m-0">
        <Box className="col ps-4 pe-4">
          <div className={css(styles.tabContentBodyWrapper)}>
            <div className={css(styles.tabContentContentWrapper)}>
              {formatNewLineCharacter(smsNotification?.template_body)}
            </div>
          </div>
        </Box>
      </Box>
      <Box className="row">
        <Box className="col ps-4 pb-3">
          <Button
            className={css(styles.editButton)}
            label={t('generic-text-edit')}
            color="primary"
            size="sm"
            onClick={handleOnClickEditSms}
          />
        </Box>
      </Box>
    </>
  );
};

export default TabContentSms;

const styles = StyleSheet.create({
  tabContentHeader: {
    fontWeight: 'bold',
    fontSize: '1.5rem',
    lineHeight: '1.5rem',
    margin: '1.5rem 0',
  },
  tabContentBodyWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    backgroundColor: 'rgba(241, 241, 243, 0.50)',
    borderRadius: '0.25rem',
    border: `1px solid #D6D6DC`,
  },
  tabContentContentWrapper: {
    padding: '1rem',
  },
  tabContentToggle: {
    display: 'flex',
    alignItems: 'center',
  },
  tabContentToggleText: {
    fontSize: '0.75rem',
    color: '#60606C',
    fontWeight: 'lighter',
    marginRight: '0.5rem',
  },
});

import { uploadFile } from '../api';

/**
 * Upload image to Cloudinary
 */
export const UPLOAD_PICTURE_REQUEST = 'UPLOAD_PICTURE_REQUEST';
export const UPLOAD_PICTURE_SUCCESS = 'UPLOAD_PICTURE_SUCCESS';
export const UPLOAD_PICTURE_FAILURE = 'UPLOAD_PICTURE_FAILURE';

export const uploadPicture = (file) => async (dispatch) => {
  dispatch({ type: UPLOAD_PICTURE_REQUEST });

  try {
    const response = await uploadFile(file);

    dispatch(uploadPictureSuccess(response));
  } catch (error) {
    dispatch(uploadPictureFailure(error));
  }
};

export function uploadPictureSuccess(response) {
  return {
    type: UPLOAD_PICTURE_SUCCESS,
    payload: {
      response: response,
    },
  };
}

export function uploadPictureFailure(error) {
  return {
    type: UPLOAD_PICTURE_FAILURE,
    error: true,
    payload: {
      error: error,
    },
  };
}

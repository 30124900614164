import React, { Component } from 'react';

import DefaultView from './Views/DefaultView';
import NumberView from './Views/NumberView';
import GraphView from './Views/GraphView';
import LeaderboardView from './Views/LeaderboardView';
import '../styles/styles.css';
export default class ClientContent extends Component {
  render() {
    const { route } = this.props;
    return (
      <div className="client-content">
        {route === 'home' ? (
          <DefaultView lineChartDate={this.props.lineChartDate} />
        ) : route === 'numbers' ? (
          <NumberView />
        ) : route === 'time' ? (
          <GraphView />
        ) : route === 'highscores' ? (
          <LeaderboardView />
        ) : null}
      </div>
    );
  }
}

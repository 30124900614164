import { CALL_API } from '../middleware/api';

/**
 * POST launch date
 */
export const POST_LAUNCH_DATE_REQUEST = 'POST_LAUNCH_DATE_REQUEST';
export const POST_LAUNCH_DATE_SUCCESS = 'POST_LAUNCH_DATE_SUCCESS';
export const POST_LAUNCH_DATE_FAILURE = 'POST_LAUNCH_DATE_FAILURE';

export function postLaunchDate(data, method = 'POST') {
  return {
    [CALL_API]: {
      endpoint: '/launch',
      method: method,
      authenticated: true,
      types: [POST_LAUNCH_DATE_REQUEST, POST_LAUNCH_DATE_SUCCESS, POST_LAUNCH_DATE_FAILURE],
      apiType: 'comm',
      data: data,
    },
  };
}

/**
 * POST a new customer on starting guide
 */

export const POST_CUSTOMER_REQUEST = 'POST_CUSTOMER_REQUEST';
export const POST_CUSTOMER_SUCCESS = 'POST_CUSTOMER_SUCCESS';
export const POST_CUSTOMER_FAILURE = 'POST_CUSTOMER_FAILURE';

export function postCustomer() {
  return {
    [CALL_API]: {
      endpoint: '/customer',
      method: 'POST',
      authenticated: true,
      types: [POST_CUSTOMER_REQUEST, POST_CUSTOMER_SUCCESS, POST_CUSTOMER_FAILURE],
      apiType: 'comm',
    },
  };
}

/**
 * GET a customer on loading get started site
 */

export const GET_CUSTOMER_REQUEST = 'GET_CUSTOMER_REQUEST';
export const GET_CUSTOMER_SUCCESS = 'GET_CUSTOMER_SUCCESS';
export const GET_CUSTOMER_FAILURE = 'GET_CUSTOMER_FAILURE';

export function getCustomer() {
  return {
    [CALL_API]: {
      endpoint: '/customer',
      authenticated: true,
      types: [GET_CUSTOMER_REQUEST, GET_CUSTOMER_SUCCESS, GET_CUSTOMER_FAILURE],
      apiType: 'comm',
    },
  };
}

/**
 * PUT customer progress in get started guide
 */

export const PUT_CUSTOMER_PROGRESS_REQUEST = 'PUT_CUSTOMER_PROGRESS_REQUEST';
export const PUT_CUSTOMER_PROGRESS_SUCCESS = 'PUT_CUSTOMER_PROGRESS_SUCCESS';
export const PUT_CUSTOMER_PROGRESS_FAILURE = 'PUT_CUSTOMER_PROGRESS_FAILURE';

export function updateProgress(data) {
  return {
    [CALL_API]: {
      endpoint: '/customer',
      method: 'PUT',
      authenticated: true,
      types: [
        PUT_CUSTOMER_PROGRESS_REQUEST,
        PUT_CUSTOMER_PROGRESS_SUCCESS,
        PUT_CUSTOMER_PROGRESS_FAILURE,
      ],
      apiType: 'comm',
      data: data,
    },
  };
}

export const GET_INTERVALS_REQUEST = 'GET_INTERVALS_REQUEST';
export const GET_INTERVALS_SUCCESS = 'GET_INTERVALS_SUCCESS';
export const GET_INTERVALS_FAILURE = 'GET_INTERVALS_FAILURE';

export function getIntervals() {
  return {
    [CALL_API]: {
      endpoint: '/intervals',
      authenticated: true,
      types: [GET_INTERVALS_REQUEST, GET_INTERVALS_SUCCESS, GET_INTERVALS_FAILURE],
      apiType: 'comm',
    },
  };
}

export const GET_INTERVAL_REQUEST = 'GET_INTERVAL_REQUEST';
export const GET_INTERVAL_SUCCESS = 'GET_INTERVAL_SUCCESS';
export const GET_INTERVAL_FAILURE = 'GET_INTERVAL_FAILURE';
export const GET_INTERVAL_RESET = 'GET_INTERVAL_RESET';

export function getInterval(id) {
  return {
    [CALL_API]: {
      endpoint: `/intervals/single/edit/${id}`,
      authenticated: true,
      types: [GET_INTERVAL_REQUEST, GET_INTERVAL_SUCCESS, GET_INTERVAL_FAILURE, GET_INTERVAL_RESET],
      apiType: 'comm',
    },
  };
}

export const GET_INTERVAL_BY_ATT_REQUEST = 'GET_INTERVAL_BY_ATT_REQUEST';
export const GET_INTERVAL_BY_ATT_SUCCESS = 'GET_INTERVAL_BY_ATT_SUCCESS';
export const GET_INTERVAL_BY_ATT_FAILURE = 'GET_INTERVAL_BY_ATT_FAILURE';

export function getIntervalByAtt(data) {
  var str = ['?'];
  for (var p in data)
    if (data.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(data[p]));
    }
  const qString = str.join('&');

  return {
    [CALL_API]: {
      endpoint: `/intervals/single${qString}`,
      authenticated: true,
      types: [
        GET_INTERVAL_BY_ATT_REQUEST,
        GET_INTERVAL_BY_ATT_SUCCESS,
        GET_INTERVAL_BY_ATT_FAILURE,
      ],
      apiType: 'comm',
      data: data,
    },
  };
}

export const PUT_ACTIVE_REQUEST = 'PUT_ACTIVE_REQUEST';
export const PUT_ACTIVE_SUCCESS = 'PUT_ACTIVE_SUCCESS';
export const PUT_ACTIVE_FAILURE = 'PUT_ACTIVE_FAILURE';

export function toggleActive(id) {
  return {
    [CALL_API]: {
      endpoint: `/interval/single/toggle/${id}`,
      method: 'PUT',
      authenticated: true,
      types: [PUT_ACTIVE_REQUEST, PUT_ACTIVE_SUCCESS, PUT_ACTIVE_FAILURE],
      apiType: 'comm',
    },
  };
}
export const PUT_PREVIEW_EMAIL_REQUEST = 'PUT_PREVIEW_EMAIL_REQUEST';
export const PUT_PREVIEW_EMAIL_SUCCESS = 'PUT_PREVIEW_EMAIL_SUCCESS';
export const PUT_PREVIEW_EMAIL_FAILURE = 'PUT_PREVIEW_EMAIL_FAILURE';
export const PUT_PREVIEW_EMAIL_RESET = 'PUT_PREVIEW_EMAIL_RESET';

export function previewEmail(data) {
  return {
    [CALL_API]: {
      endpoint: '/previewemail',
      method: 'PUT',
      authenticated: true,
      types: [
        PUT_PREVIEW_EMAIL_REQUEST,
        PUT_PREVIEW_EMAIL_SUCCESS,
        PUT_PREVIEW_EMAIL_FAILURE,
        PUT_PREVIEW_EMAIL_RESET,
      ],
      apiType: 'comm',
      data: data,
    },
  };
}

export const PUT_INTERVAL_REQUEST = 'PUT_INTERVAL_REQUEST';
export const PUT_INTERVAL_SUCCESS = 'PUT_INTERVAL_SUCCESS';
export const PUT_INTERVAL_FAILURE = 'PUT_INTERVAL_FAILURE';

export function updateCustomInterval(id, data) {
  return {
    [CALL_API]: {
      endpoint: `/intervals/single/${id}`,
      method: 'PUT',
      authenticated: true,
      types: [PUT_INTERVAL_REQUEST, PUT_INTERVAL_SUCCESS, PUT_INTERVAL_FAILURE],
      apiType: 'comm',
      data: data,
    },
  };
}

export const POST_INTERVAL_REQUEST = 'POST_INTERVAL_REQUEST';
export const POST_INTERVAL_SUCCESS = 'POST_INTERVAL_SUCCESS';
export const POST_INTERVAL_FAILURE = 'POST_INTERVAL_FAILURE';

export function createCustomInterval(data) {
  return {
    [CALL_API]: {
      endpoint: `/interval/create`,
      method: 'POST',
      authenticated: true,
      types: [POST_INTERVAL_REQUEST, POST_INTERVAL_SUCCESS, POST_INTERVAL_FAILURE],
      apiType: 'comm',
      data: data,
    },
  };
}

export const DELETE_COMPETITION_INTERVALS_REQUEST = 'DELETE_COMPETITION_INTERVALS_REQUEST';
export const DELETE_COMPETITION_INTERVALS_SUCCESS = 'DELETE_COMPETITION_INTERVALS_SUCCESS';
export const DELETE_COMPETITION_INTERVALS_FAILURE = 'DELETE_COMPETITION_INTERVALS_FAILURE';

export function deleteCompetitionIntervals(id) {
  return {
    [CALL_API]: {
      endpoint: `/interval/competition/${id}`,
      method: 'DELETE',
      authenticated: true,
      types: [
        DELETE_COMPETITION_INTERVALS_REQUEST,
        DELETE_COMPETITION_INTERVALS_SUCCESS,
        DELETE_COMPETITION_INTERVALS_FAILURE,
      ],
      apiType: 'comm',
    },
  };
}

export const GET_PREMADE_TEMPLATES_REQUEST = 'GET_PREMADE_TEMPLATES_REQUEST';
export const GET_PREMADE_TEMPLATES_SUCCESS = 'GET_PREMADE_TEMPLATES_SUCCESS';
export const GET_PREMADE_TEMPLATES_FAILURE = 'GET_PREMADE_TEMPLATES_FAILURE';

export function getPreMadeTemplates(language) {
  return {
    [CALL_API]: {
      endpoint: `/templates/premade/${language}`,
      authenticated: true,
      types: [
        GET_PREMADE_TEMPLATES_REQUEST,
        GET_PREMADE_TEMPLATES_SUCCESS,
        GET_PREMADE_TEMPLATES_FAILURE,
      ],
      apiType: 'comm',
    },
  };
}

export const GET_SINGLE_TEMPLATE_REQUEST = 'GET_SINGLE_TEMPLATE_REQUEST';
export const GET_SINGLE_TEMPLATE_SUCCESS = 'GET_SINGLE_TEMPLATE_SUCCESS';
export const GET_SINGLE_TEMPLATE_FAILURE = 'GET_SINGLE_TEMPLATE_FAILURE';

export function getSinglePremadeTemplate(data) {
  return {
    [CALL_API]: {
      endpoint: 'templates/premade/default',
      method: 'POST', //Needs to be POST
      authenticated: true,
      types: [
        GET_SINGLE_TEMPLATE_REQUEST,
        GET_SINGLE_TEMPLATE_SUCCESS,
        GET_SINGLE_TEMPLATE_FAILURE,
      ],
      apiType: 'comm',
      data: data,
      sendJSON: true,
    },
  };
}

export const GET_EMAILS_REQUEST = 'GET_EMAILS_REQUEST';
export const GET_EMAILS_SUCCESS = 'GET_EMAILS_SUCCESS';
export const GET_EMAILS_FAILURE = 'GET_EMAILS_FAILURE';

export function getOnDemandEmails() {
  return {
    [CALL_API]: {
      endpoint: `/emails`,
      authenticated: true,
      types: [GET_EMAILS_REQUEST, GET_EMAILS_SUCCESS, GET_EMAILS_FAILURE],
      apiType: 'comm',
    },
  };
}

export const GET_SINGLE_EMAIL_REQUEST = 'GET_SINGLE_EMAIL_REQUEST';
export const GET_SINGLE_EMAIL_SUCCESS = 'GET_SINGLE_EMAIL_SUCCESS';
export const GET_SINGLE_EMAIL_FAILURE = 'GET_SINGLE_EMAIL_FAILURE';
export const GET_SINGLE_EMAIL_RESET = 'GET_SINGLE_EMAIL_RESET';

export function getSingleOnDemandEmail(id) {
  return {
    [CALL_API]: {
      endpoint: `/emails/single/${id}`,
      authenticated: true,
      types: [
        GET_SINGLE_EMAIL_REQUEST,
        GET_SINGLE_EMAIL_SUCCESS,
        GET_SINGLE_EMAIL_FAILURE,
        GET_SINGLE_EMAIL_RESET,
      ],
      apiType: 'comm',
    },
  };
}

export const PUT_SINGLE_EMAIL_REQUEST = 'PUT_SINGLE_EMAIL_REQUEST';
export const PUT_SINGLE_EMAIL_SUCCESS = 'PUT_SINGLE_EMAIL_SUCCESS';
export const PUT_SINGLE_EMAIL_FAILURE = 'PUT_SINGLE_EMAIL_FAILURE';

export function updateOnDemandEmail(id, data) {
  return {
    [CALL_API]: {
      endpoint: `/emails/single/${id}`,
      method: 'PUT',
      authenticated: true,
      types: [PUT_SINGLE_EMAIL_REQUEST, PUT_SINGLE_EMAIL_SUCCESS, PUT_SINGLE_EMAIL_FAILURE],
      apiType: 'comm',
      data: data,
      sendJSON: true,
    },
  };
}

export const PUT_SINGLE_EMAIL_ACTIVE_REQUEST = 'PUT_SINGLE_EMAIL_ACTIVE_REQUEST';
export const PUT_SINGLE_EMAIL_ACTIVE_SUCCESS = 'PUT_SINGLE_EMAIL_ACTIVE_SUCCESS';
export const PUT_SINGLE_EMAIL_ACTIVE_FAILURE = 'PUT_SINGLE_EMAIL_ACTIVE_FAILURE';

export function toggleOnDemandEmail(id) {
  return {
    [CALL_API]: {
      endpoint: `/emails/single/toggle/${id}`,
      method: 'PUT',
      authenticated: true,
      types: [
        PUT_SINGLE_EMAIL_ACTIVE_REQUEST,
        PUT_SINGLE_EMAIL_ACTIVE_SUCCESS,
        PUT_SINGLE_EMAIL_ACTIVE_FAILURE,
      ],
      apiType: 'comm',
    },
  };
}

export const GET_PUSH_NOTIFICATIONS_REQUEST = 'GET_PUSH_NOTIFICATIONS_REQUEST';
export const GET_PUSH_NOTIFICATIONS_SUCCESS = 'GET_PUSH_NOTIFICATIONS_SUCCESS';
export const GET_PUSH_NOTIFICATIONS_FAILURE = 'GET_PUSH_NOTIFICATIONS_FAILURE';
export const GET_PUSH_NOTIFICATIONS_RESET = 'GET_PUSH_NOTIFICATIONS_RESET';

export function getPushNotifications() {
  return {
    [CALL_API]: {
      endpoint: `/pushnotifications`,
      authenticated: true,
      types: [
        GET_PUSH_NOTIFICATIONS_REQUEST,
        GET_PUSH_NOTIFICATIONS_SUCCESS,
        GET_PUSH_NOTIFICATIONS_FAILURE,
        GET_PUSH_NOTIFICATIONS_RESET,
      ],
      apiType: 'comm',
    },
  };
}

export const GET_SINGLE_PUSH_NOTIFICATION_REQUEST = 'GET_SINGLE_PUSH_NOTIFICATION_REQUEST';
export const GET_SINGLE_PUSH_NOTIFICATION_SUCCESS = 'GET_SINGLE_PUSH_NOTIFICATION_SUCCESS';
export const GET_SINGLE_PUSH_NOTIFICATION_FAILURE = 'GET_SINGLE_PUSH_NOTIFICATION_FAILURE';
export const GET_SINGLE_PUSH_NOTIFICATION_RESET = 'GET_SINGLE_PUSH_NOTIFICATION_RESET';

export function getSinglePushNotification(id) {
  return {
    [CALL_API]: {
      endpoint: `/pushnotifications/single/${id}`,
      authenticated: true,
      types: [
        GET_SINGLE_PUSH_NOTIFICATION_REQUEST,
        GET_SINGLE_PUSH_NOTIFICATION_SUCCESS,
        GET_SINGLE_PUSH_NOTIFICATION_FAILURE,
        GET_SINGLE_PUSH_NOTIFICATION_RESET,
      ],
      apiType: 'comm',
    },
  };
}

export const PUT_SINGLE_PUSH_NOTIFICATION_ACTIVE_REQUEST =
  'PUT_SINGLE_PUSH_NOTIFICATION_ACTIVE_REQUEST';
export const PUT_SINGLE_PUSH_NOTIFICATION_ACTIVE_SUCCESS =
  'PUT_SINGLE_PUSH_NOTIFICATION_ACTIVE_SUCCESS';
export const PUT_SINGLE_PUSH_NOTIFICATION_ACTIVE_FAILURE =
  'PUT_SINGLE_PUSH_NOTIFICATION_ACTIVE_FAILURE';
export const PUT_SINGLE_PUSH_NOTIFICATION_ACTIVE_RESET =
  'PUT_SINGLE_PUSH_NOTIFICATION_ACTIVE_RESET';

export function togglePushNotification(id) {
  return {
    [CALL_API]: {
      endpoint: `/pushnotifications/single/toggle/${id}`,
      method: 'PUT',
      authenticated: true,
      types: [
        PUT_SINGLE_PUSH_NOTIFICATION_ACTIVE_REQUEST,
        PUT_SINGLE_PUSH_NOTIFICATION_ACTIVE_SUCCESS,
        PUT_SINGLE_PUSH_NOTIFICATION_ACTIVE_FAILURE,
        PUT_SINGLE_PUSH_NOTIFICATION_ACTIVE_RESET,
      ],
      apiType: 'comm',
    },
  };
}

export const PUT_SINGLE_PUSH_NOTIFICATION_REQUEST = 'PUT_SINGLE_PUSH_NOTIFICATION_REQUEST';
export const PUT_SINGLE_PUSH_NOTIFICATION_SUCCESS = 'PUT_SINGLE_PUSH_NOTIFICATION_SUCCESS';
export const PUT_SINGLE_PUSH_NOTIFICATION_FAILURE = 'PUT_SINGLE_PUSH_NOTIFICATION_FAILURE';
export const PUT_SINGLE_PUSH_NOTIFICATION_RESET = 'PUT_SINGLE_PUSH_NOTIFICATION_RESET';

export function updatePushNotification(id, data) {
  return {
    [CALL_API]: {
      endpoint: `/pushnotifications/single/${id}`,
      method: 'PUT',
      authenticated: true,
      types: [
        PUT_SINGLE_PUSH_NOTIFICATION_REQUEST,
        PUT_SINGLE_PUSH_NOTIFICATION_SUCCESS,
        PUT_SINGLE_PUSH_NOTIFICATION_FAILURE,
        PUT_SINGLE_PUSH_NOTIFICATION_RESET,
      ],
      apiType: 'comm',
      data: data,
      sendJSON: true,
    },
  };
}

export const GET_SMS_NOTIFICATIONS_REQUEST = 'GET_SMS_NOTIFICATIONS_REQUEST';
export const GET_SMS_NOTIFICATIONS_SUCCESS = 'GET_SMS_NOTIFICATIONS_SUCCESS';
export const GET_SMS_NOTIFICATIONS_FAILURE = 'GET_SMS_NOTIFICATIONS_FAILURE';
export const GET_SMS_NOTIFICATIONS_RESET = 'GET_SMS_NOTIFICATIONS_RESET';

export function getSMSNotifications() {
  return {
    [CALL_API]: {
      endpoint: `/smsnotifications`,
      authenticated: true,
      types: [
        GET_SMS_NOTIFICATIONS_REQUEST,
        GET_SMS_NOTIFICATIONS_SUCCESS,
        GET_SMS_NOTIFICATIONS_FAILURE,
        GET_SMS_NOTIFICATIONS_RESET,
      ],
      apiType: 'comm',
    },
  };
}

export const GET_SINGLE_SMS_NOTIFICATION_REQUEST = 'GET_SINGLE_SMS_NOTIFICATION_REQUEST';
export const GET_SINGLE_SMS_NOTIFICATION_SUCCESS = 'GET_SINGLE_SMS_NOTIFICATION_SUCCESS';
export const GET_SINGLE_SMS_NOTIFICATION_FAILURE = 'GET_SINGLE_SMS_NOTIFICATION_FAILURE';
export const GET_SINGLE_SMS_NOTIFICATION_RESET = 'GET_SINGLE_SMS_NOTIFICATION_RESET';

export function getSingleSMSNotification(id) {
  return {
    [CALL_API]: {
      endpoint: `/smsnotifications/single/${id}`,
      authenticated: true,
      types: [
        GET_SINGLE_SMS_NOTIFICATION_REQUEST,
        GET_SINGLE_SMS_NOTIFICATION_SUCCESS,
        GET_SINGLE_SMS_NOTIFICATION_FAILURE,
        GET_SINGLE_SMS_NOTIFICATION_RESET,
      ],
      apiType: 'comm',
    },
  };
}

export const PUT_SINGLE_SMS_NOTIFICATION_REQUEST = 'PUT_SINGLE_SMS_NOTIFICATION_REQUEST';
export const PUT_SINGLE_SMS_NOTIFICATION_SUCCESS = 'PUT_SINGLE_SMS_NOTIFICATION_SUCCESS';
export const PUT_SINGLE_SMS_NOTIFICATION_FAILURE = 'PUT_SINGLE_SMS_NOTIFICATION_FAILURE';
export const PUT_SINGLE_SMS_NOTIFICATION_RESET = 'PUT_SINGLE_SMS_NOTIFICATION_RESET';

export function updateSmsNotification(id, data) {
  return {
    [CALL_API]: {
      endpoint: `/smsnotifications/single/${id}`,
      method: 'PUT',
      authenticated: true,
      types: [
        PUT_SINGLE_SMS_NOTIFICATION_REQUEST,
        PUT_SINGLE_SMS_NOTIFICATION_SUCCESS,
        PUT_SINGLE_SMS_NOTIFICATION_FAILURE,
        PUT_SINGLE_SMS_NOTIFICATION_RESET,
      ],
      apiType: 'comm',
      data: data,
      sendJSON: true,
    },
  };
}

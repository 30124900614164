import React from 'react';
import { Accordion, Box } from '@talentech/components';
import { css, StyleSheet } from 'aphrodite';

const OtherCommunication = (props) => {
  const { t, sortedLaunchCommunications, optionalInviteContent, sortedOtherCommunications } = props;

  return (
    <Box className="col bg-gray-50 shadow-sm m-4 pb-4 rounded-3">
      <div className={css(styles.otherHeadingWrapper)}>
        <h2 className={css(styles.otherHeading)}>
          {sortedLaunchCommunications.length === 0
            ? t('communication-templates-title')
            : t('communication-other-title')}
        </h2>
        <p className={css(styles.otherSectionText)}>{t('communication-other-desc')}</p>
      </div>

      {sortedLaunchCommunications.length === 0 && (
        <div className={css(styles.optionalInvite)}>
          <Accordion accordionItems={optionalInviteContent} key="optional-invite" />
        </div>
      )}

      <div className={css(styles.communicationItemsWrapper)}>{sortedOtherCommunications}</div>
    </Box>
  );
};

export default OtherCommunication;

const styles = StyleSheet.create({
  otherHeadingWrapper: {
    padding: '1.5rem',
    marginBottom: '1.5rem',
    borderBottom: '1px solid rgba(214, 214, 220, 1)',
  },
  otherHeading: {
    fontWeight: 'bold',
    fontSize: '1.75rem',
    letterSpacing: 0,
  },
  optionalInvite: {
    margin: '1.5rem',
  },
  communicationItemsWrapper: {
    border: '1px solid rgba(214, 214, 220, 1)',
    borderBottom: 'none',
    borderRadius: '0.25rem',
    margin: '0 1.5rem',
  },
});

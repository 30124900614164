import React from 'react';
import CountUp from 'react-countup';

const UserListItem = (props) => {
  return props.data.map((row, index) => {
    return (
      <tr key={index}>
        <td>{index + 1}</td>
        <td className="team-panel2">
          {/* <img src={!row.picture ? 'https://res.cloudinary.com/divprvxbc/image/upload/v1533900785/app-icon-round_bn9zp6.png' :row.picture } draggable="false" alt="user"/> */}
          {`${row.firstname} ${row.lastname}`}
        </td>
        <td>
          <CountUp start={0} end={row.recommendations} duration={1} />
        </td>
        <td>
          <CountUp start={0} end={row.points} duration={1} />
        </td>
      </tr>
    );
  });
};

export default UserListItem;

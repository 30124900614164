import { css, StyleSheet } from 'aphrodite';
import React from 'react';

import { getCustomerType } from '../api';
import { Color } from '../irecommend-lib/styles';
import QuickStats from './QuickStats';
import { useTranslation } from 'react-i18next';

const RecommendationStats = (props) => {
  const { user } = props;
  const { t } = useTranslation();
  return (
    <div className={css([styles.statsWrapper, window.innerWidth < 660 && styles.flexWrap1])}>
      <img
        src={user?.companyimage}
        className={css(styles.companyLogotype)}
        alt="Company logo"
        data-testid="customer-logo-home"
      />
      <div
        className={css([
          window.innerWidth < 660 && styles.flexWrap1
            ? styles.statsNumbersVertical
            : styles.statsNumbersInline,
        ])}
      >
        <QuickStats
          title={t('homepage-quickstats-recommendations-title')}
          subtitle={t('homepage-quickstats-recommendations-subtitle')}
          value={user.recommendations?.toString()}
          link="/recommendations"
        />
        {getCustomerType() !== 'Network' && (
          <QuickStats
            title={t('homepage-quickstats-highscore-title') + ':'}
            subtitle={
              '(' +
              t('homepage-quickstats-highscore-subtitle-intl', { points: user.points?.Int64 }) +
              ')'
            }
            value={user.placing?.toString()}
            link="/highscore"
          />
        )}
      </div>
    </div>
  );
};

export default RecommendationStats;

const styles = StyleSheet.create({
  statsWrapper: {
    backgroundColor: Color.white,
    borderRadius: '4px',
    margin: '1rem',
    padding: '1rem',
    flex: 1,
    boxShadow: '0px 2px 2px rgba(21, 21, 23, 0.04), 0px 0px 2px rgba(21, 21, 23, 0.04)',
  },
  companyLogotype: {
    //height: 22,
    marginLeft: 'auto',
    maxWidth: 100,
    marginBottom: '1rem',
  },
  flexWrap1: {
    minWidth: '400px',
  },
  statsNumbersInline: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
  },
  statsNumbersVertical: {
    display: 'flex',
  },
});

import DropdownMenu from './admin/DropdownMenu';
import { css, StyleSheet } from 'aphrodite';
import { useState } from 'react';

const Filter = (props) => {
  const [toggleState, setToggleState] = useState({ toggleDropdown: false });
  const { dropdownMenuList, currentFilterName } = props;

  return (
    <div className={css(styles.wrapper)}>
      <button
        className="btn btn-outline-primary btn-sm w-100 d-flex justify-content-between align-items-center"
        onClick={() => setToggleState({ toggleDropdown: true })}
        aria-label="Drop down menu"
      >
        <div>
          <i className="t-icon regular-01_interface_essentials-filter-filter_2"></i>{' '}
          <span className={css(styles.filterTagName)}>{currentFilterName}</span>
        </div>
        <i className="t-icon light-01_interface_essentials-navigate-carrot_down me-0"></i>
      </button>
      {toggleState.toggleDropdown && (
        <DropdownMenu
          toggleState={toggleState.toggleDropdown}
          style={styles.dropdownWrapper}
          setToggleState={setToggleState}
          dropdownMenuList={dropdownMenuList}
        />
      )}
    </div>
  );
};

export default Filter;

const styles = StyleSheet.create({
  wrapper: {
    position: 'relative',

    '@media (max-width: 580px)': {
      width: '100%',
    },
  },
  dropdownWrapper: {
    left: 0,
    transform: 'translateY(10px)',
  },
  filterTagName: {
    padding: '2px 8px 2px 0',
  },
});

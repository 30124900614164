import { Box } from '@talentech/components';

import { css, StyleSheet } from 'aphrodite';
import ReactHtmlParser from 'react-html-parser';

const CommunicationPreviewFields = (props) => {
  const { defaultContent, defaultSubject, t } = props;
  const addLineBreaks = (html) => {
    return html ? html.replace(/<\/p>/g, '<br></br>') : null;
  };
  return (
    <Box className="row mt-0">
      <div className={css(styles.sectionWrapper)}>
        <div className={css(styles.fieldWrapper)}>
          <span className={css(styles.subject)}>{t('generic-text-subject')}</span>
          {defaultSubject}
        </div>
        <div className={css(styles.content)}>{ReactHtmlParser(addLineBreaks(defaultContent))}</div>
      </div>
    </Box>
  );
};
export default CommunicationPreviewFields;

const styles = StyleSheet.create({
  sectionWrapper: {
    border: '1px solid rgba(214, 214, 220, 1)',
    background: 'rgba(241, 241, 243, 0.50)',
    borderRadius: '5px',
    padding: 0,
  },
  fieldWrapper: {
    display: 'flex',
    flexDirection: 'column',
    borderBottom: 'rgba(214, 214, 220, 1) solid 1px',
    padding: '16px',
    fontSize: '16px',
    fontWeight: 'bold',
  },
  subject: {
    fontSize: '12px;',
    fontWeight: 'normal',
  },
  content: {
    fontSize: '16px',
    padding: '16px',
  },
});

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite';

import { Button, Icon } from '@talentech/components';
import { Color, Font } from '../config/styles';
import Loader from '../components/Loader';
import PageHeader from '../components/admin/PageHeader';
import { ModalForm } from '../components/admin/ModalForm';
import { TALENTECH_ICONS } from '../components/Icons';
import CategoryFilterFields from '../components/CategoryFilterFields';
import RecommendationStats from '../components/RecommendationStats';
import PositionsList from '../components/PositionsList';
import EmptyState from '../components/EmptyState';
import { withTranslation } from 'react-i18next';

class HomePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showCategoryModal: false,
      selectedCategories: [],
    };

    this.setState = this.setState.bind(this);
    this.handleCancelForm = this.handleCancelForm.bind(this);
    this.handleSubmitForm = this.handleSubmitForm.bind(this);
  }
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    onPressListItem: PropTypes.func.isRequired,
    onPressProfileLink: PropTypes.func.isRequired,
    positions: PropTypes.array.isRequired,
    readPositions: PropTypes.array,
    user: PropTypes.object.isRequired,
  };

  render() {
    const { positions, positionsAdmin, user, customerCategories, isLoading, t } = this.props;

    return (
      <Loader isLoading={isLoading}>
        <PageHeader title={t('recommendpage-header')} />
        <main aria-label="Main content">
          <ModalForm
            showModalForm={this.state.showCategoryModal}
            title={t('homepage-positions-header')}
            subtitle={t('homepage-categories-modal-description')}
            content={<CategoryFilterFields state={this.state} setState={this.setState} />}
            labelCancel={t('generic-button-cancel')}
            labelSubmit={t('generic-text-save')}
            handleCancel={this.handleCancelForm}
            handleSubmit={this.handleSubmitForm}
          />
          <div className={css([styles.container, window.innerWidth < 660 && styles.flexWrap])}>
            {user.user ? <RecommendationStats user={user} /> : <div />}

            <div className={css(styles.positionsWrapper)}>
              <div className={css(styles.positionsHeaderWrapper)}>
                <span className={css(styles.positionsTitle)}>{t('homepage-positions-header')}</span>
                {customerCategories.length !== 0 && (
                  <Button
                    label={t('generic-text-edit')}
                    outline={true}
                    color="primary"
                    size="md"
                    icon={<Icon icon={TALENTECH_ICONS.editRegular} iconSize="sm" />}
                    onClick={() => this.setState({ showCategoryModal: true })}
                  />
                )}
              </div>
              {positions.length > 0 && positionsAdmin.length > 0 ? (
                <PositionsList onPressListItem={this.props.onPressListItem} positions={positions} />
              ) : positions.length === 0 && positionsAdmin.length > 0 ? (
                <EmptyState
                  img="https://storybook.talentech.io/images/svg/marketing-advertising/view-count-magnify.svg"
                  width="100"
                  alt="Magnifying glass"
                  heading={t('homepage-filter-no-jobs-title')}
                  description={t('homepage-filter-no-jobs-description')}
                />
              ) : positionsAdmin.length === 0 && customerCategories.length === 0 ? (
                <EmptyState
                  img="https://storybook.talentech.io/images/svg/logistics/package-attention.svg"
                  width="170"
                  alt="Empty box"
                  heading={t('homepage-no-jobs-no-categories-title')}
                  description={t('homepage-no-jobs-no-categories-description')}
                />
              ) : (
                <EmptyState
                  img="https://storybook.talentech.io/images/svg/logistics/package-attention.svg"
                  width="170"
                  alt="Empty box"
                  heading={t('homepage-no-jobs-no-categories-title')}
                  description={t('homepage-no-jobs-for-categories-description')}
                />
              )}
            </div>
          </div>
        </main>
      </Loader>
    );
  }

  handleSubmitForm() {
    const { t, user, postCategories } = this.props;
    if (this.state.selectedCategories.length === 0) {
      alert(t('category-page-alert-description'));
      return;
    }
    postCategories(user.user.iduser, this.state.selectedCategories);
    this.setState({ ...this.state, showCategoryModal: false });
  }

  handleCancelForm() {
    this.setState({ ...this.state, showCategoryModal: false });
  }
}

export default withTranslation()(HomePage);

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginTop: '1.5rem',
  },
  flexWrap: {
    flexWrap: 'wrap',
  },
  positionsWrapper: {
    flex: 3.5,
    backgroundColor: Color.white,
    margin: '1rem',
    boxShadow: '0px 2px 2px rgba(21, 21, 23, 0.04), 0px 0px 2px rgba(21, 21, 23, 0.04)',
  },

  positionsHeaderWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1.2rem',
    borderBottom: '1px rgba(214, 214, 220, 1) solid',
  },
  positionsTitle: {
    fontSize: '1.6em',
    fontWeight: 'bold',
    textAlign: 'left',
  },

  background: {
    alignSelf: 'stretch',
    padding: 7,
    paddingTop: 25,
  },
  companyHeader: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: 25,
    paddingRight: 25,
    paddingTop: 16,
    //height: 40,
    alignItems: 'center',
  },
  companyLabel: {
    alignSelf: 'center',
    color: Color.gray,
    fontFamily: Font.defaultFont,
    fontWeight: 600,
    fontSize: 11,
  },
  innerWrap: {
    flex: 1,
  },
  loader: {
    flex: 1,
    justifyContent: 'center',
  },
  statsWrap: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 15,
  },
  quickStats: {
    flex: 1,
  },
});
